import { Grid } from '@material-ui/core'
import {
  ApiKey,
  FormSection,
  FormSectionTitle,
  Typography,
  Spinner,
  Button,
  Checkbox,
  FormControlLabel
} from '@ui/stickybid'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import type { ClientMetaDocument, OptionsDocument } from 'paintscout'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { useGetApiKeysQuery, useGenerateApiKeyMutation } from '@paintscout/api'

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {},
    rowGrid: {
      display: 'flex',
      flexDirection: 'row'
    },
    generateButton: {
      marginRight: theme.spacing(3),
      height: '41px'
    },
    keyContainer: {
      padding: theme.spacing()
    }
  }),
  { name: 'ClientApiKeys' }
)

export interface ClientApiKeysFormProps {
  classes?: StyleClasses<typeof useStyles>
}

const ClientApiKeys = (props: ClientApiKeysFormProps) => {
  const classes = useStyles(props)
  const [showArchived, setShowArchived] = useState(false)
  const {
    values: { meta }
  } = useFormikContext<{ meta: ClientMetaDocument; options: OptionsDocument }>()
  const [generateApiKey] = useGenerateApiKeyMutation()
  const [changeMade, setChangeMade] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: apiKeysData,
    loading,
    error,
    refetch
  } = useGetApiKeysQuery({
    variables: {
      companyId: meta._id
    },
    fetchPolicy: changeMade ? 'network-only' : 'cache-and-network'
  })

  const activeApiKeys = apiKeysData?.getApiKeys?.filter((key) => !key.archived)
  const inactiveApiKeys = apiKeysData?.getApiKeys?.filter((key) => key.archived)

  const handleNewKey = async () => {
    try {
      await generateApiKey({
        variables: {
          companyId: meta._id
        }
      })
      setChangeMade(true)
      refetch()
      enqueueSnackbar(`Successfully generated new apiKey`, { variant: 'success' })
    } catch (error) {
      console.log('error', error)
      enqueueSnackbar(`Error generating new apiKey ${error}`, { variant: 'error' })
    }
  }

  return (
    <>
      <FormSectionTitle title="Api Keys" />
      <FormSection>
        <Grid container spacing={3}>
          {(loading || !apiKeysData?.getApiKeys) && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <Typography variant="h6">Error retrieving client Api Keys.</Typography>
            </Grid>
          )}
          {apiKeysData && apiKeysData?.getApiKeys && (
            <Grid item xs={12}>
              {activeApiKeys?.length === 0 && !showArchived ? (
                <Grid item xs={12}>
                  <Typography variant="h6">Client has no active API Keys</Typography>
                </Grid>
              ) : (
                <Grid className={classes.keyContainer} container spacing={2} xs={12}>
                  {apiKeysData?.getApiKeys
                    .reduce((prev, current) => {
                      // List active keys first
                      if (current.archived && !showArchived) {
                        return prev
                      } else if (current.archived) {
                        prev.push(current)
                      } else {
                        prev.unshift(current)
                      }
                      return prev
                    }, [])
                    .map((apiKey) => {
                      return <ApiKey key={apiKey._id} apiKey={apiKey} />
                    })}
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button className={classes.generateButton} onClick={handleNewKey}>
              Generate Key
            </Button>
            {inactiveApiKeys?.length > 0 && (
              <FormControlLabel
                control={
                  <Checkbox value={showArchived} onClick={() => setShowArchived((showArchived) => !showArchived)} />
                }
                label={'Show Inactive Keys'}
              />
            )}
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientApiKeys
