import type { OverridableComponent } from '@material-ui/core/OverridableComponent'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { TabsTypeMap } from '@material-ui/core/Tabs'
import MuiTabs from '@material-ui/core/Tabs'
import React from 'react'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography

  return createStyles({
    root: {},
    indicator: {
      backgroundColor: theme.palette.primary.main
    },
    scrollButtons: {
      color: theme.palette.primary.main
    },
    flexContainer: {}
  })
}

const Tabs: OverridableComponent<TabsTypeMap> = (props: any) => <MuiTabs {...props} />

export default withStyles(styles)(Tabs)
