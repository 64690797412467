import { useMutation, useQuery } from '@apollo/react-hooks'
import { Fab, Grow, Hidden, makeStyles, styled } from '@material-ui/core'
import CompanyIcon from '@material-ui/icons/LocationCity'
import SaveIcon from '@material-ui/icons/Save'
import PasswordIcon from '@material-ui/icons/VpnKey'
import type { Auth0UserWithClaims, OptionsDocument } from 'paintscout'
import type { ProfileFormikValues } from '@ui/stickybid'
import {
  Button,
  ClientOptionsProvider,
  ConfirmationDialog,
  DialogStackContext,
  DialogStackPortal,
  PopupMenuItem,
  ProfileFormik,
  Spinner,
  Tab,
  Tabs,
  Typography
} from '@ui/stickybid'
import { gql } from 'apollo-boost'
import { Form } from 'formik'
import { useSnackbar } from 'notistack'
import type { UserPreferencesDocument } from 'paintscout'
import React, { useContext, useState } from 'react'
import type { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import ErrorMessage from '../../components/ErrorMessage'
import NavHeader from '../../components/NavDrawer/NavHeader'
import UserAdministrationForm from '../../components/UserAdministrationForm'
import UserProfileForm from '../../components/UserProfileForm'
import { removeTypenames } from '../../util/removeTypenames'
import ResetPasswordDialog from '../../components/ResetPasswordDialog'
import { GET_USER } from 'src/graphql/queries/GET_USER'
import { UPDATE_USER } from 'src/graphql/queries/UPDATE_USER'

const CompanyLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  fontWeight: 'inherit',
  color: 'inherit'
}))

const useStyles = makeStyles({
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mobileSaveButton: {
    position: 'fixed',
    bottom: 16,
    right: 16
  },
  title: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  },
  chip: {}
})

export default function EditUser({ match }: RouteComponentProps<{ id: string; section: string }>) {
  const classes = useStyles({})

  const id = decodeURIComponent(match.params.id)
  const { enqueueSnackbar } = useSnackbar()
  const [section, setSection] = useState(match.params.section || 'administration')

  const [updateUser] = useMutation(UPDATE_USER)

  const { data, loading, error } = useQuery(GET_USER, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

  const user = data?.user
  const preferences = { ...user?.preferences, presentation: user?.preferences?.presentation || { description: '' } }
  const clientMeta = data?.user?.client?.meta
  const options = data?.user?.client?.options

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (loading || !user) {
    return <Spinner fullWidth fullHeight />
  }

  async function handleSave({
    user,
    preferences,
    options
  }: {
    user: Auth0UserWithClaims
    preferences: UserPreferencesDocument
    options: OptionsDocument
  }) {
    try {
      await updateUser({
        variables: {
          input: removeTypenames({
            user_id: user.user_id,
            email: user.email,
            user_metadata: user.user_metadata,
            app_metadata: user.app_metadata,
            preferences: preferences,
            reactivate: true
          }),
          options
        },
        refetchQueries: [{ query: GET_USER, variables: { id } }]
      })

      enqueueSnackbar('Saved', { variant: 'success' })
    } catch (e) {
      console.error(e)

      enqueueSnackbar('Unable to save user', { variant: 'error' })
    }
  }

  return (
    <ClientOptionsProvider options={options}>
      <DialogStackPortal />
      <ProfileFormik
        user={user}
        preferences={preferences}
        options={options}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSave(values)
          actions.resetForm({ values })
        }}
      >
        {({ isSubmitting, values, dirty }) => {
          return (
            <Form>
              <UserNavHeader values={values} />
              <Typography variant={'h3'} gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                <CompanyLink to={`/clients/${clientMeta._id}`}>
                  <CompanyIcon style={{ marginBottom: 5, marginRight: 8 }} />
                  <span>{options.options.companyName.value}</span>
                </CompanyLink>
              </Typography>
              <div className={classes.tabsWrapper}>
                <Tabs value={section} onChange={(ev, value) => setSection(value)} style={{ marginBottom: 20 }}>
                  <Tab label="Administration" value="administration" />
                  <Tab label="Profile" value="profile" />
                </Tabs>
                <Hidden mdDown>
                  <Grow in={dirty}>
                    <Button prominence={1} loading={isSubmitting} icon={SaveIcon} type="submit">
                      Save
                    </Button>
                  </Grow>
                </Hidden>
              </div>

              {section === 'profile' && <UserProfileForm />}
              {section === 'administration' && <UserAdministrationForm />}
              <Hidden lgUp>
                <div className={classes.mobileSaveButton}>
                  <Grow in={dirty}>
                    <Fab disabled={!dirty || isSubmitting} color="primary" type="submit">
                      <SaveIcon />
                    </Fab>
                  </Grow>
                </div>
              </Hidden>
            </Form>
          )
        }}
      </ProfileFormik>
    </ClientOptionsProvider>
  )
}

function UserNavHeader({ values }: { values: ProfileFormikValues }) {
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog, dismissAllDialogs } = useContext(DialogStackContext)
  const classes = useStyles({})

  const [requestPassword] = useMutation(gql`
    mutation resetPassword($user_id: String!) {
      resetPassword(user_id: $user_id)
    }
  `)

  async function handleResetPasswordEmail() {
    openDialog(ConfirmationDialog, {
      message: `This well send an email to "${values.user.email}" to reset their password.`,
      onConfirm: async () => {
        try {
          await requestPassword({ variables: { user_id: values.user.user_id } })

          enqueueSnackbar('Sent Password Reset email', { variant: 'success' })
        } catch (e) {
          console.error(e)
          enqueueSnackbar('Failed to send Password Reset email', { variant: 'error' })
        }
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  const [updatePassword] = useMutation(gql`
    mutation updatePassword($user_id: String!, $password: String!) {
      updatePassword(user_id: $user_id, password: $password)
    }
  `)

  const handleResetPasswordDialog = () => {
    openDialog(ResetPasswordDialog, {
      onConfirm: (password: string) => {
        openDialog(ConfirmationDialog, {
          message: `Are you sure you want to reset your password?`,
          onConfirm: async () => {
            try {
              await updatePassword({ variables: { user_id: values.user.user_id, password } })
              enqueueSnackbar('Password updated', { variant: 'success' })
              dismissAllDialogs()
            } catch (error) {
              console.error(error)
              enqueueSnackbar('Failed to reset password', { variant: 'error' })
              dismissDialog()
            }
          },
          onCancel: dismissDialog
        })
      },
      onCancel: dismissDialog
    })
  }

  const menuItems = (
    <>
      <PopupMenuItem onClick={handleResetPasswordEmail} icon={PasswordIcon}>
        Send Password Reset Email
      </PopupMenuItem>
      <PopupMenuItem onClick={handleResetPasswordDialog} icon={PasswordIcon}>
        Manually Reset Password
      </PopupMenuItem>
    </>
  )

  return (
    <NavHeader
      title={
        <div className={classes.title}>
          <Typography
            variant={'h1'}
          >{`${values.user.user_metadata.firstName} ${values.user.user_metadata.lastName}`}</Typography>
        </div>
      }
      showBackButton
      menuItems={menuItems}
    />
  )
}
