import React from 'react'

import CoreSignatureCanvas from '@ui/core/SignatureCanvas'
import type { SignatureCanvasProps as CoreSignatureCanvasProps } from '@ui/core/SignatureCanvas'

import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'

import Button from '../Button'

export interface SignatureCanvasProps extends Omit<CoreSignatureCanvasProps, 'Button'> {}

const useStyles = makeStyles<Theme, SignatureCanvasProps>(
  (theme) => ({
    canvas: (props) => ({
      backgroundColor: theme.palette.grey['200'],
      borderRadius: theme.borderRadius.md,
      ...(props.disabled && {
        backgroundColor: theme.palette.grey['100']
      })
    }),
    captionRow: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5)
    },
    caption: {
      ...theme.typography.button,
      textTransform: 'uppercase',
      color: theme.palette.grey.A200,
      fontWeight: theme.typography.fontWeightBold
    }
  }),
  { name: 'SignatureCanvas' }
)

function SignatureCanvas(props: SignatureCanvasProps) {
  const classes = useStyles(props)
  return <CoreSignatureCanvas Button={Button} {...props} classes={classes} />
}

export default SignatureCanvas
