import type { QuoteDocument, OptionsDocument } from 'paintscout'
import { getLineItem, updateLineItem } from '../../line-items'
import { moveItems } from '../../items'

/**
 * Reverts all accepted options on a quote back to their original state
 * i.e., moves them back to the options section and sets the acceptedOption flag to false
 */

export const revertQuoteOptions = (args: { quote: QuoteDocument; options: OptionsDocument }): QuoteDocument => {
  const { quote: _quote, options } = args
  let quote = _quote

  const lineItemKeys = Object.keys(quote.lineItems)
  const acceptedOptionKeys = []

  lineItemKeys.forEach((key) => {
    const lineItem = quote.lineItems[key]
    const updateableLineItem = getLineItem({ quote, key })
    if (lineItem.acceptedOption) {
      acceptedOptionKeys.push(key)
      quote = updateLineItem({ quote, item: { ...updateableLineItem, acceptedOption: false }, options })
    }
  })

  quote = moveItems({ quote, keys: acceptedOptionKeys, section: 'options', previousSection: 'bid', options })

  return quote
}
