/**
 * @module builder
 */
import type { OptionsDocument, PresentationOption } from 'paintscout'

import get from 'lodash/get'

export function updatePresentationOption(args: {
  presentationOption: PresentationOption
  options: OptionsDocument
}): OptionsDocument {
  const isSB = process.env.PRODUCT === 'sb'
  const { options, presentationOption } = args

  const existingPresentationOptions = get(options.options, 'presentationOptions', {
    order: [],
    quoteTypeOrder: {},
    values: {}
  } as OptionsDocument['options']['presentationOptions'])
  const { order = [], quoteTypeOrder = {} } = existingPresentationOptions

  const { quoteTypes } = presentationOption

  const orderIndex = order.indexOf(presentationOption.key)

  if (orderIndex === -1) {
    if (isSB) {
      order.splice(0, 0, presentationOption.key)
    } else {
      order.push(presentationOption.key)
    }
  }

  quoteTypes.forEach((quoteType: string) => {
    if (quoteTypeOrder[quoteType]) {
      const quoteTypeOrderIndex = quoteTypeOrder[quoteType].indexOf(presentationOption.key)
      if (quoteTypeOrderIndex === -1) {
        quoteTypeOrder[quoteType].push(presentationOption.key)
      }
    }
  })

  const now = Date.now()
  return {
    ...options,
    options: {
      ...options.options,
      presentationOptions: {
        ...existingPresentationOptions,
        values: {
          ...existingPresentationOptions?.values,
          [presentationOption.key]: {
            active: true,
            ...existingPresentationOptions?.values?.[presentationOption.key],
            ...presentationOption,
            dates: {
              ...existingPresentationOptions?.values?.[presentationOption.key]?.dates,
              updated: now
            }
          }
        },
        order,
        quoteTypeOrder
      }
    }
  }
}
