/**
 * @module builder
 */
import _get from 'lodash/get'
import type { AreaDimensions, OptionsDocument, QuoteArea, QuoteDocument } from 'paintscout'
import type { QuoteItemSection, UpdateableArea, UpdateableAreaDimensions, UpdateableAreaTotals } from '../../index'
import { trimAreaBySection } from '../../index'
import { getItemSection } from '../../util/get-item-section'

const fakeDimensions = (fullArea: QuoteArea) => {
  return convertDimensions({
    width: 0,
    length: 0,
    height: 0,
    override_dimensions: false,
    override_lnft: false,
    calculated_lnft: 0,
    override_cuft: false,
    calculated_cuft: 0,
    override_sqft: false,
    calculated_sqft: 0,
    override_sqft_ceiling: false,
    calculated_sqft_ceiling: 0,
    override_sqft_floor: false,
    calculated_sqft_floor: 0,
    override_sqft_walls: false,
    calculated_sqft_walls: 0
  })
}

export function getArea(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  trimBySubstrateSection?: QuoteItemSection
}): UpdateableArea {
  const { quote, options, key, trimBySubstrateSection } = args
  const fullArea = trimBySubstrateSection
    ? trimAreaBySection({ quote, options, areaKey: key, section: trimBySubstrateSection })
    : quote.areas[key]

  const areaType = _get(fullArea, 'area_type.value', 'room')
  const quoteType = _get(fullArea, 'int_ext.value', 'all')
  const label = _get(fullArea, 'area_label.value', '')
  const dimensions = fullArea?.dimensions ? convertDimensions(fullArea.dimensions) : { ...fakeDimensions(fullArea) }

  const totals = convertTotals(fullArea.totals)
  const notes = convertNotes(fullArea)

  const section = getItemSection(fullArea)
  const files = fullArea.files ? fullArea.files : []

  const quantity = _get(fullArea, 'area.quantity', 1)
  const description = fullArea.substrate_string
    ? {
        useCustom: fullArea.substrate_string.use_custom,
        custom: fullArea.substrate_string.custom,
        default: fullArea.substrate_string.default
      }
    : {}

  return {
    key,
    type: 'area',
    areaType,
    quoteType,
    label,
    dimensions,
    totals,
    section,
    files,
    quantity,
    description,
    ...notes
  }
}

function convertDimensions(dimensions: AreaDimensions): UpdateableAreaDimensions {
  return {
    width: Number(dimensions.width),
    length: Number(dimensions.length),
    height: Number(dimensions.height),
    override: !!dimensions.override_dimensions,
    sqft: {
      useCustom: !!dimensions.override_sqft,
      default: Number(dimensions.calculated_sqft ? dimensions.calculated_sqft : dimensions.sqft),
      custom: Number(dimensions.sqft)
    },
    lnft: {
      useCustom: !!dimensions.override_lnft,
      custom: Number(dimensions.lnft),
      default: Number(dimensions.calculated_lnft ? dimensions.calculated_lnft : dimensions.lnft)
    },
    cuft: {
      useCustom: !!dimensions.override_cuft,
      custom: Number(dimensions.cuft),
      default: Number(dimensions.calculated_cuft ? dimensions.calculated_cuft : dimensions.cuft)
    },
    sqftWalls: {
      useCustom: !!dimensions.override_sqft_walls,
      default: Number(dimensions.calculated_sqft_walls ? dimensions.calculated_sqft_walls : dimensions.sqft_walls),
      custom: Number(dimensions.sqft_walls)
    },
    sqftCeiling: {
      useCustom: !!dimensions.override_sqft_ceiling,
      custom: Number(dimensions.sqft_ceiling),
      default: Number(dimensions.calculated_sqft_ceiling ? dimensions.calculated_sqft_ceiling : dimensions.sqft_ceiling)
    },
    sqftFloor: {
      useCustom: !!dimensions.override_sqft_floor,
      custom: Number(dimensions.sqft_floor),
      default: Number(dimensions.calculated_sqft_floor ? dimensions.calculated_sqft_floor : dimensions.sqft_floor)
    }
  }
}

function convertTotals(totals: {
  hours?: number
  prep?: number
  price?: number
  materials?: number
  visiblePrice?: number
  override_price?: boolean
  override_price_value?: number
  override_hours?: boolean
  override_hours_value?: number
  override_prep?: boolean
  override_prep_value?: number
  override_materials?: boolean
  override_materials_value?: number
}): UpdateableAreaTotals {
  let priceValue = totals.override_price ? totals.override_price_value : totals.price
  if (!priceValue) {
    priceValue = 0
  }

  let materialsValue = totals.override_materials ? totals.override_materials_value : totals.materials
  if (!materialsValue) {
    materialsValue = 0
  }

  const r: UpdateableAreaTotals = {
    hours: {
      custom: totals.override_hours_value || totals.hours,
      useCustom: !!totals.override_hours,
      default: totals.hours
    },
    prep: {
      custom: totals.override_prep_value || totals.prep,
      useCustom: !!totals.override_prep,
      default: totals.prep
    },
    price: {
      custom: totals.override_price_value || totals.price,
      useCustom: !!totals.override_price,
      default: totals.price
    },
    materials: {
      custom: totals.override_materials_value,
      useCustom: totals.override_materials,
      default: totals.materials
    },
    totalPrice: priceValue + materialsValue
    // overrideHours: !!totals.override_hours
  }
  return r
}

function convertNotes(area: QuoteArea): { clientNote: string; internalNote: string } {
  return {
    clientNote: area.client_note && typeof area.client_note === 'string' ? area.client_note : '',
    internalNote: area.internal_note && typeof area.internal_note === 'string' ? area.internal_note : ''
  }
}
