import React, { useContext } from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import type { TooltipProps } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { DialogStackContext } from '../DialogStack'
import { useInView } from '@ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '../Typography'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, OnboardingPopperProps>(
  (theme) => ({
    root: {},
    arrow: {
      color: theme.palette.onboarding.main
    },
    tooltip: {
      ...theme.typography.h3,
      backgroundColor: theme.palette.onboarding.main,
      color: theme.palette.common.white,
      padding: theme.spacing(2, 3),
      animation: '$wiggle 3000ms linear infinite'
    },
    popper: (props) => ({
      ...((props.dialogCount === 0 || !props.dialogCount) && {
        zIndex: 1
      })
    }),
    tooltipPlacementTop: {
      margin: '14px 0'
    },
    tooltipPlacementBottom: {
      margin: '14px 0'
    },
    scrollIndicator: {
      pointerEvents: 'none',
      position: 'fixed',
      bottom: theme.spacing(2),
      zIndex: 200000,
      left: '50%',
      transform: 'translateX(-50%)',
      color: theme.palette.onboarding.main,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      background: 'rgba(255,255,255, .90)',
      padding: 12,
      borderRadius: theme.borderRadius['md'],
      animation: '$bounce 3000ms linear infinite',
      '& svg': {
        fontSize: '3rem'
      },
      '& span': {
        color: theme.palette.onboarding.main,
        fontWeight: 700,
        margin: '3px 0'
      }
    },
    flipIndicator: {
      flexDirection: 'column-reverse',
      '& svg': {
        transform: 'rotate(180deg)'
      }
    },
    '@keyframes bounce': {
      '0%': {
        bottom: theme.spacing(2)
      },
      '30%': {
        bottom: theme.spacing(3)
      },
      '100%': {
        bottom: theme.spacing(2)
      }
    },
    '@keyframes wiggle': {
      '0%, 7%': {
        transform: 'rotateZ(0)'
      },
      '15%': {
        transform: 'rotateZ(-10deg)'
      },
      '20%': {
        transform: 'rotateZ(8deg)'
      },
      '25%': {
        transform: 'rotateZ(-8deg)'
      },
      '30%': {
        transform: 'rotateZ(6deg)'
      },
      '35%': {
        transform: 'rotateZ(-4deg)'
      },
      '40%, 100%': {
        transform: 'rotateZ(0)'
      }
    }
  }),
  { name: 'OnboardingPopper' }
)

export interface OnboardingPopperProps extends Omit<TooltipProps, 'open'> {
  classes?: StyleClasses<typeof useStyles>
  title: string
  open: boolean
  dialogCount?: number
}

function OnboardingPopper(props: OnboardingPopperProps) {
  const classes = useStyles(props)
  const { children, open, title, dialogCount = 0 } = props
  const { stack } = useContext(DialogStackContext)
  const showPopper = open && stack.length <= dialogCount

  const { ref, inView, entry } = useInView({
    skip: !showPopper
  })
  const popperIsBelow = !inView && entry?.boundingClientRect.top > 0
  const popperIsAbove = !inView && entry?.boundingClientRect.top < 0

  return (
    <>
      {(popperIsBelow || popperIsAbove) && showPopper && (
        <div className={classnames(classes.scrollIndicator, { [classes.flipIndicator]: popperIsAbove })}>
          <Typography variant={'h3'} component={'span'}>
            Scroll
          </Typography>
          <ExpandMoreIcon style={{ margin: -10 }} />
        </div>
      )}
      <Tooltip
        arrow
        ref={ref}
        classes={classes}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={showPopper}
        title={title}
      >
        {children}
      </Tooltip>
    </>
  )
}

export default OnboardingPopper
