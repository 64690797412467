import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'
import CorePopupMenu from '@ui/core/PopupMenu'
import type { PopupMenuProps } from '@ui/core/PopupMenu'

export type { PopupMenuProps }

const useStyles = makeStyles<Theme, PopupMenuProps>(
  (theme: Theme) => ({
    // todo: duplicated to SplitMenuButton
    menu: (props) => ({
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.borderRadius['md'],
      // boxShadow: theme.boxShadow['1'],
      boxShadow: 'none',
      ...((!props.anchorOrigin || props.transformOrigin?.vertical === 'bottom') && {
        transform: `translateY(-${theme.spacing(1)}px) !important`
      }),
      ...(props.anchorOrigin?.vertical === 'top' && { transform: `translateY(-${theme.spacing(1)}px) !important` })
    })
  }),
  { name: 'PopupMenu' }
)

function PopupMenu(props: PopupMenuProps) {
  const classes = useStyles(props)
  return (
    <CorePopupMenu
      getContentAnchorEl={undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
      classes={classes}
    />
  )
}

export default PopupMenu
