import React from 'react'

import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import DefaultIcon from '@material-ui/icons/Description'

import type { AlertProps } from './Alert'

export interface AlertIconProps {
  severity?: AlertProps['severity']
}

function AlertIcon({ severity, ...props }: AlertIconProps) {
  switch (severity) {
    case 'warning':
      return <WarningIcon />
    case 'error':
      return <ErrorIcon />
    case 'success':
      return <SuccessIcon />
    case 'info':
      return <InfoIcon />
    default:
      return <DefaultIcon />
  }
}

export default AlertIcon
