import React, { useState, useEffect } from 'react'
import type { EditableLogo, PresentationOption, PresentationSection, QuoteFile } from 'paintscout'
import type { Theme, PopperProps } from '@material-ui/core'
import { makeStyles, Popper } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import type { RequireAtLeastOne } from 'type-fest'

import { usePresentation } from '../../context'
import sectionComponents from '../sections'
import { useFormikContext } from 'formik'
import { Button, Tabs, Tab, ButtonSelectOption, ButtonOption, useFileDropArea } from '@ui/stickybid'
import GeneralOptions from './GeneralOptions'

import OpenWithIcon from '@material-ui/icons/OpenWith'
import CropLandscapeIcon from '@material-ui/icons/CropLandscape'
import CropPortraitIcon from '@material-ui/icons/CropPortrait'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ControlCameraIcon from '@material-ui/icons/ControlCamera'
import { ArchIcon, ChevronIcon, DropIcon, LeafIcon, ParallelogramIcon, ScoopIcon } from '@ui/core/icons'
import Draggable from 'react-draggable'
import classnames from 'classnames'

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

type SectionOption = { Component: React.ReactElement<any>; tab: string }

interface BaseSectionOptionsPopupProps extends Omit<PopperProps, 'children'> {
  onClose?: (ev?: React.MouseEvent<any, MouseEvent>) => void
  classes?: StyleClasses<typeof useStyles>

  // for use with mediaOptions to avoid collage overlap
  hasRightSideMenus?: boolean
  sectionKey?: string
  logoOptionsProps?: {
    logo: EditableLogo
    sectionPrefix?: string
    fieldSuffix?: string
  }
  mediaOptionsProps?: {
    file?: QuoteFile
    sectionPrefix?: string
    fieldSuffix?: string
    hideAspectOptions?: boolean
  }
  menuAnchorEl?: HTMLElement
}

export type SectionOptionsPopupProps = RequireAtLeastOne<
  BaseSectionOptionsPopupProps,
  'sectionKey' | 'mediaOptionsProps' | 'logoOptionsProps'
>

const useStyles = makeStyles<Theme, SectionOptionsPopupProps | ContentProps>(
  (theme: Theme) => {
    return {
      paper: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.borderRadius['md'],
        boxShadow: theme.boxShadow[1],
        zIndex: 6
      },
      draggablePopper: {
        zIndex: 6
      },
      pseudoPopper: {
        position: 'fixed',
        right: 0,
        top: 0
      },
      content: {
        margin: theme.spacing(0, 1),
        minWidth: 275,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
      handleIcon: {
        // needed for below
      },
      tabsRow: {
        display: 'flex',
        '& > $handleIcon': {
          cursor: 'grab',
          color: theme.palette.secondary.main,
          marginLeft: 'auto',
          marginTop: theme.spacing(1),
          '&:active': {
            cursor: 'grabbing'
          },
          '&:hover': {
            color: theme.palette.primary.main
          }
        }
      },
      tabs: {
        margin: theme.spacing(0, 0, 1, -1),
        minHeight: 0
      },
      tab: {
        margin: theme.spacing(0, 1),
        padding: theme.spacing(1, 0),
        textTransform: 'none',
        letterSpacing: 0,
        minHeight: 0,
        fontSize: '1rem'
      },
      options: {
        marginBottom: 'auto'
      },
      buttonHolder: {
        display: 'flex',
        padding: `${theme.spacing(2)}px 0`,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      colorHolder: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between'
      },
      color: {
        marginBottom: theme.spacing(2)
      },
      PopperHeader: {
        marginBottom: theme.spacing()
      },
      optionsRoot: {
        width: '100%'
      },
      marginBottom: {
        marginBottom: theme.spacing(2)
      },
      // use fixed with for media options to help with pseudo popper positioning
      // not a big deal since all media has the same options
      mediaOptionsPopper: {
        width: 300
      }
    }
  },
  { name: 'SectionOptionsPopup' }
)

export function SectionOptionsPopup(props: SectionOptionsPopupProps) {
  const classes = useStyles(props)
  const { hasRightSideMenus, mediaOptionsProps, menuAnchorEl, onClose, open, sectionKey, logoOptionsProps } = props
  // a little bit hacky but this state will ensure the popper is repainted on a tab change so that its fieldSuffix is updated to account for changing content size
  const [tab, setTab] = useState('')
  const { autosaveDisabled, focusedSection } = usePresentation()
  const sectionAnchorEl = document.getElementById(focusedSection)
  const isMediaOrLogo = !!mediaOptionsProps || !!logoOptionsProps

  useEffect(() => {
    const onScroll = () => {
      const rect = sectionAnchorEl.getBoundingClientRect()
      const { top, bottom } = rect
      if (bottom < 0 || top > window.innerHeight) {
        onClose()
      }
    }

    if (open && focusedSection === sectionKey) {
      window.addEventListener('scroll', onScroll)
    }
    if (open && isMediaOrLogo) {
      window.addEventListener('scroll', onScroll)
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // need the popper here to attach to body (container prop) for z-index stacking context purposes
  if (open) {
    const boundingRect = menuAnchorEl.getBoundingClientRect()
    const defaultPosition = isMediaOrLogo
      ? !hasRightSideMenus
        ? { x: -(window.innerWidth - boundingRect.left - 331), y: boundingRect.bottom - 54 }
        : { x: -(window.innerWidth - boundingRect.right), y: boundingRect.bottom - 54 }
      : { x: -(window.innerWidth - boundingRect.left - 309), y: boundingRect.y }
    return (
      <Popper open className={classes.draggablePopper} container={document.body}>
        <Draggable defaultPosition={defaultPosition} handle={'.handle'}>
          <div
            className={classnames(classes.paper, classes.pseudoPopper, {
              [classes.mediaOptionsPopper]: isMediaOrLogo
            })}
          >
            <Content
              autosaveDisabled={autosaveDisabled}
              sectionKey={sectionKey}
              logoOptionsProps={logoOptionsProps}
              mediaOptionsProps={mediaOptionsProps}
              onClose={onClose}
              setParentTab={setTab}
            />
          </div>
        </Draggable>
      </Popper>
    )
  } else return null
}

export interface ContentProps {
  autosaveDisabled: boolean
  onClose: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void
  setParentTab: (tab: string) => void
  sectionKey?: string
  logoOptionsProps?: {
    logo: EditableLogo
    sectionPrefix?: string
    fieldSuffix?: string
  }
  mediaOptionsProps?: {
    file?: QuoteFile
    sectionPrefix?: string
    fieldSuffix?: string
    hideAspectOptions?: boolean
  }
}

function Content(props: ContentProps) {
  const classes = useStyles(props)

  const handleChange = (event: React.ChangeEvent<any>, newTab: string) => {
    setTab(newTab)
    props.setParentTab(newTab)
  }

  const { autosaveDisabled, sectionKey, logoOptionsProps, mediaOptionsProps, onClose } = props
  const { file, sectionPrefix, fieldSuffix, hideAspectOptions } = mediaOptionsProps || {}
  const { isSaving, isDirty, setIsDirty, isEditable, presentationStyle } = usePresentation()
  const { values, setFieldValue, submitForm, setValues, resetForm } = useFormikContext<PresentationOption>()
  const { palette } = presentationStyle

  const presetColors = palette?.title ? Object.values(palette.colors) : undefined

  const handleClose = (ev, args: { save: boolean }) => {
    if (onClose) onClose(ev, 'escapeKeyDown')
    const { save } = args
    if (isDirty && isEditable) {
      if (save) {
        submitForm()
        setIsDirty(false)
      } else {
        resetForm()
        setIsDirty(false)
      }
    }
  }

  let allOptions: SectionOption[] = []

  const { open: openDropArea, getInputProps } = useFileDropArea({
    multiple: false,
    maxWidth: 1280,
    maxHeight: 1600,
    accept: 'image/*',
    onUploadStart: () => {
      setFieldValue(`${sectionPrefix}.fields.${fieldSuffix}`, {
        ...file,
        isUploading: true
      })
    },
    onUpload: (files: QuoteFile[]) => {
      const newFile = files[0]
      newFile.presentationOptions = file.presentationOptions
      newFile.isUploading = false
      setFieldValue(`${sectionPrefix}.fields.${fieldSuffix}`, newFile)
      submitForm()
      setIsDirty(false)
    }
  })

  if (file) {
    // media options
    const handleMediaOptionChange = (target: string, value: string | number | boolean) => {
      const newFile = {
        ...file,
        presentationOptions: { ...file.presentationOptions, [target]: value }
      }

      if (!isDirty) {
        setIsDirty(true)
      }
      setFieldValue(`${sectionPrefix}.fields.${fieldSuffix}`, newFile)
    }

    const isVideo = (file?.type ?? '').startsWith('video') || ['mp4', 'mov', 'youtube'].includes(file.format)
    const isPdf = file.format === 'pdf'

    const options = file?.presentationOptions ?? {}
    const aspectRatio = (options.aspectRatio as string) || 'unset'
    const defaultImageOrientation = file?.width < file?.height ? 'portrait' : 'landscape'
    const imageOrientation = options.imageOrientation || defaultImageOrientation
    const objectPositionX = options.objectPositionX ?? 50
    const objectPositionY = options.objectPositionY ?? 50
    const clipPath = options.clipPath || 'none'
    let clipPathOrientation = options.clipPathOrientation || 'top'
    if (clipPath === 'leaf') {
      if (clipPathOrientation === 'top') clipPathOrientation = 'right'
      if (clipPathOrientation === 'bottom') clipPathOrientation = 'left'
    }
    const diagonalOrientations = ['scoop', 'drop'].includes(clipPath)
    const horizontalAlign = options.horizontalAlign || 'center'
    const parentSection = get(values, sectionPrefix)
    const fullWidthImage2Col = parentSection.type === '2-column' && parentSection.fields.fullWidthImage

    const nativeAspectRatio = Math.max(file?.width, file?.height) / Math.min(file?.width, file?.height)
    const splitAspectRatio = aspectRatio.split('/')
    const parsedAspectRatio = parseInt(splitAspectRatio[0], 10) / parseInt(splitAspectRatio[1], 10)
    const isNativeAspectRatio =
      Math.abs(nativeAspectRatio - parsedAspectRatio) < 0.02 && imageOrientation === defaultImageOrientation

    let hasXAnchor = true
    let hasYAnchor = true

    if (defaultImageOrientation === 'landscape') {
      if (aspectRatio === '1/1' || imageOrientation === 'portrait') {
        hasXAnchor = true
        hasYAnchor = false
      } else {
        if (isNativeAspectRatio) {
          hasXAnchor = false
          hasYAnchor = false
        } else if (parsedAspectRatio < nativeAspectRatio) {
          hasXAnchor = true
          hasYAnchor = false
        } else if (parsedAspectRatio > nativeAspectRatio) {
          hasXAnchor = false
          hasYAnchor = true
        }
      }
    }

    if (defaultImageOrientation === 'portrait') {
      if (aspectRatio === '1/1' || imageOrientation === 'landscape') {
        hasXAnchor = false
        hasYAnchor = true
      } else {
        if (isNativeAspectRatio) {
          hasXAnchor = false
          hasYAnchor = false
        } else if (parsedAspectRatio < nativeAspectRatio) {
          hasXAnchor = false
          hasYAnchor = true
        } else if (parsedAspectRatio > nativeAspectRatio) {
          hasXAnchor = true
          hasYAnchor = false
        }
      }
    }

    const mediaOptions = [
      {
        Component: (
          <>
            <input {...getInputProps()} />
            <ButtonOption label={'Replace Image'} buttons={[{ icon: <CloudUploadIcon />, onClick: openDropArea }]} />
          </>
        ),
        tab: 'content'
      },
      {
        Component: (
          <ButtonSelectOption
            label={'Content Alignment (H)'}
            value={horizontalAlign}
            buttons={[
              { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'flex-start' },
              { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
              { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'flex-end' }
            ]}
            onChange={(value) => {
              handleMediaOptionChange('horizontalAlign', value)
            }}
          />
        ),
        tab: 'format'
      },
      ...(!isVideo && !hideAspectOptions
        ? [
            {
              Component: (
                <ButtonSelectOption
                  label={'Aspect Ratio'}
                  value={aspectRatio}
                  buttons={[
                    { icon: '1:1', value: '1/1' },
                    { icon: '5:4', value: '5/4' },
                    { icon: '4:3', value: '4/3' },
                    { icon: '3:2', value: '3/2' },
                    { icon: '16:9', value: '16/9' },
                    { icon: '3:1', value: '3/1' },
                    { icon: <OpenWithIcon />, value: 'unset' }
                  ]}
                  onChange={(value) => {
                    handleMediaOptionChange('aspectRatio', value)
                  }}
                />
              ),
              tab: 'content'
            }
          ]
        : []),
      ...(aspectRatio !== 'unset' && aspectRatio !== '1/1'
        ? [
            {
              Component: (
                <ButtonSelectOption
                  label={'Orientation'}
                  value={imageOrientation}
                  buttons={[
                    { icon: <CropLandscapeIcon />, value: 'landscape' },
                    { icon: <CropPortraitIcon />, value: 'portrait' }
                  ]}
                  onChange={(value) => {
                    handleMediaOptionChange('imageOrientation', value)
                  }}
                />
              ),
              tab: 'content'
            }
          ]
        : []),
      ...((aspectRatio !== 'unset' || fullWidthImage2Col) && hasXAnchor && !isPdf
        ? [
            {
              Component: (
                <ButtonSelectOption
                  label={'Anchor Position (X)'}
                  value={objectPositionX}
                  buttons={[
                    { icon: 0, value: 0 },
                    { icon: 25, value: 25 },
                    { icon: 50, value: 50 },
                    { icon: 75, value: 75 },
                    { icon: 100, value: 100 }
                  ]}
                  onChange={(value) => {
                    handleMediaOptionChange('objectPositionX', value)
                  }}
                />
              ),
              tab: 'content'
            }
          ]
        : []),
      ...((aspectRatio !== 'unset' || fullWidthImage2Col) && hasYAnchor && !isPdf
        ? [
            {
              Component: (
                <ButtonSelectOption
                  label={'Anchor Position (Y)'}
                  value={objectPositionY}
                  buttons={[
                    { icon: 0, value: 0 },
                    { icon: 25, value: 25 },
                    { icon: 50, value: 50 },
                    { icon: 75, value: 75 },
                    { icon: 100, value: 100 }
                  ]}
                  onChange={(value) => {
                    handleMediaOptionChange('objectPositionY', value)
                  }}
                />
              ),
              tab: 'content'
            }
          ]
        : []),
      {
        Component: (
          <ButtonSelectOption
            label={'Shape'}
            value={clipPath}
            buttons={[
              { icon: <OpenWithIcon />, value: 'none' },
              { icon: <FiberManualRecordIcon />, value: 'circle' },
              { icon: <ParallelogramIcon />, value: 'parallelogram' },
              { icon: <ChevronIcon />, value: 'chevron' },
              { icon: <ScoopIcon />, value: 'scoop' },
              { icon: <ArchIcon />, value: 'arch' },
              { icon: <LeafIcon />, value: 'leaf' },
              { icon: <DropIcon />, value: 'drop' }
            ]}
            onChange={(value) => {
              handleMediaOptionChange('clipPath', value)
            }}
          />
        ),
        tab: 'content'
      },
      ...(!['none', 'circle'].includes(clipPath)
        ? [
            {
              Component: (
                <ButtonSelectOption
                  label={'Shape Orientation'}
                  value={clipPathOrientation}
                  buttons={[
                    ...(clipPath !== 'leaf'
                      ? [
                          {
                            icon: (
                              <ArrowRightAltIcon
                                style={{ transform: diagonalOrientations ? 'rotate(-135deg)' : 'rotate(-90deg)' }}
                              />
                            ),
                            value: 'top'
                          }
                        ]
                      : []),
                    {
                      icon: (
                        <ArrowRightAltIcon
                          style={{ transform: diagonalOrientations ? 'rotate(-45deg)' : 'rotate(0deg)' }}
                        />
                      ),
                      value: 'right'
                    },
                    ...(clipPath !== 'leaf'
                      ? [
                          {
                            icon: (
                              <ArrowRightAltIcon
                                style={{ transform: diagonalOrientations ? 'rotate(45deg)' : 'rotate(90deg)' }}
                              />
                            ),
                            value: 'bottom'
                          }
                        ]
                      : []),
                    {
                      icon: (
                        <ArrowRightAltIcon
                          style={{ transform: diagonalOrientations ? 'rotate(135deg)' : 'rotate(180deg)' }}
                        />
                      ),
                      value: 'left'
                    }
                  ]}
                  onChange={(value) => {
                    handleMediaOptionChange('clipPathOrientation', value)
                  }}
                />
              ),
              tab: 'content'
            }
          ]
        : [])
    ]

    allOptions = mediaOptions
  } else if (sectionKey) {
    // section options
    let pageKey = ''
    const sections = values.pages
      .map((page) => {
        page.sections.forEach((section) => {
          if (section.key === sectionKey) pageKey = page.key
        })
        return page.sections
      })
      .flat()

    let section = sections.find((section) => section.key === sectionKey)
    const pageIndex = values.pages.findIndex((page) => page.key === pageKey)
    const sectionIndex = values.pages[pageIndex].sections.findIndex((section) => section.key === sectionKey)

    const handleOptionFieldChange = (field: string, value: any) => {
      // update a field within a section
      const fullField = `pages[${pageIndex}].sections[${sectionIndex}].${field}`
      if (!isDirty) {
        setIsDirty(true)
      }
      setFieldValue(fullField, value)
    }

    const handleOptionSectionChange = (section: PresentationSection) => {
      // copy and update a section and then replace the entire presentation
      const newPresentation = cloneDeep(values)
      newPresentation.pages[pageIndex].sections[sectionIndex] = section
      if (!isDirty) {
        setIsDirty(true)
      }
      setValues(newPresentation)
    }

    let sectionOptions = []

    if (sectionComponents[section.type]?.Options) {
      sectionOptions = sectionComponents[section.type]?.Options({
        section,
        presetColors,
        handleOptionFieldChange,
        handleOptionSectionChange
      })
    }

    allOptions = sectionOptions.concat(
      GeneralOptions({
        section,
        palette,
        handleOptionFieldChange,
        handleOptionSectionChange
      })
    )
  } else if (logoOptionsProps) {
    // logo options
    const { logo, sectionPrefix, fieldSuffix } = logoOptionsProps
    const { horizontalAlign = 'center', size = 'md' } = logo || {}
    const handleLogoOptionChange = (target: string, value: string | number | boolean) => {
      const newLogo = {
        ...logo,
        [target]: value
      }

      if (!isDirty) {
        setIsDirty(true)
      }
      setFieldValue(`${sectionPrefix}.fields.${fieldSuffix}`, newLogo)
    }
    const logoOptions = [
      {
        Component: (
          <ButtonSelectOption
            label={'Size'}
            value={size}
            buttons={[
              { icon: 'XS', value: 'xs' },
              { icon: 'S', value: 'sm' },
              { icon: 'M', value: 'md' },
              { icon: 'L', value: 'lg' },
              { icon: 'XL', value: 'xl' }
            ]}
            onChange={(value) => {
              handleLogoOptionChange('size', value)
            }}
          />
        ),
        tab: 'format'
      },
      {
        Component: (
          <ButtonSelectOption
            label={'Content Alignment (H)'}
            value={horizontalAlign}
            buttons={[
              { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'flex-start' },
              { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
              { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'flex-end' }
            ]}
            onChange={(value) => {
              handleLogoOptionChange('horizontalAlign', value)
            }}
          />
        ),
        tab: 'format'
      }
    ]
    allOptions = logoOptions
  }

  const contentOptions = allOptions.filter((option) => option.tab === 'content')
  const formatOptions = allOptions.filter((option) => option.tab === 'format')
  const backgroundOptions = allOptions.filter((option) => option.tab === 'background')
  const colorOptions = allOptions.filter((option) => option.tab === 'color')

  let defaultTab = ''
  if (contentOptions.length) {
    defaultTab = 'content'
  } else if (formatOptions.length) {
    defaultTab = 'format'
  } else if (backgroundOptions.length) {
    defaultTab = 'background'
  } else if (colorOptions.length) {
    defaultTab = 'color'
  }

  const [tab, setTab] = useState(defaultTab)

  const renderTabContent = (tab: 'content' | 'format' | 'background' | 'color') => {
    const tabOptions = allOptions.filter((option) => option.tab === tab)
    return tabOptions.map((option, index) => {
      if (option.tab === tab)
        return (
          <div key={index}>
            {option.Component}
            {index + 1 !== tabOptions.length && <hr />}
          </div>
        )
    })
  }

  const maxOptionLength = Math.max(
    contentOptions.length,
    formatOptions.length,
    backgroundOptions.length,
    colorOptions.length
  )

  const height = !autosaveDisabled
    ? `${125.4 + 27 * maxOptionLength + 14.8 * (maxOptionLength - 1)}px`
    : `${53.4 + 27 * maxOptionLength + 14.8 * (maxOptionLength - 1)}px`

  return (
    <div className={classes.content} id={'section-options-menu'} style={{ height: height }}>
      <div className={`handle ${classes.tabsRow}`}>
        <Tabs className={classes.tabs} value={tab} onChange={handleChange}>
          {contentOptions.length && <Tab className={classes.tab} value="content" label="Content" />}
          {formatOptions.length && <Tab className={classes.tab} value="format" label="Format" />}
          {backgroundOptions.length && <Tab className={classes.tab} value="background" label="Background" />}
          {colorOptions.length && <Tab className={classes.tab} value="color" label="Color" />}
        </Tabs>
        <ControlCameraIcon className={classes.handleIcon} />
      </div>
      <div className={classes.options}>
        {tab === 'content' && renderTabContent('content')}
        {tab === 'format' && renderTabContent('format')}
        {tab === 'background' && renderTabContent('background')}
        {tab === 'color' && renderTabContent('color')}
      </div>
      {!autosaveDisabled && (
        <div className={classes.buttonHolder}>
          <Button
            loading={isSaving}
            style={{ paddingLeft: 0 }}
            prominence={3}
            onClick={(ev) => handleClose(ev, { save: false })}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            disabled={isSaving || !isEditable || !isDirty}
            loading={isSaving}
            prominence={1}
            onClick={(ev) => handleClose(ev, { save: true })}
            fullWidth
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}
