import React, { useState, useRef, useEffect } from 'react'
import type { Theme } from '@material-ui/core'
import type { Variant } from '@material-ui/core/styles/createTypography'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { HtmlContent, Input, Placeholder, PresEditorTooltip, Typography } from '@ui/stickybid'
import CodeIcon from '@material-ui/icons/Code'
import CreateIcon from '@material-ui/icons/Create'
import classnames from 'classnames'

interface InlineEditableTextProps {
  children: string
  classes?: StyleClasses<typeof useStyles>
  handleEdit: (content: string) => void
  hasIcon?: boolean
  hasPreventEmpty?: boolean
  // the height prop ensures Input and Typography have the same height for isInSitu, assumes multiline is false
  height?: number
  isInSitu?: boolean
  isSubmitOnEnter?: boolean
  maxLength?: number
  multiline?: boolean
  placeholderButtonText?: string
  renderHtml?: boolean
  tooltipTitle?: string
  variant?: Variant
}

const useStyles = makeStyles<Theme, InlineEditableTextProps>(
  (theme: Theme) => {
    return {
      root: {},
      editableWrapper: {
        cursor: 'pointer'
      },
      editableBlock: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          '& $editableIcon': {
            fill: theme.palette.primary.main
          }
        }
      },
      editableIcon: {
        marginLeft: theme.spacing(1),
        fill: theme.palette.secondary.main,
        fontSize: '1rem'
      },
      inputRoot: (props) => ({
        ...(props.isInSitu && {
          border: 'none',
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.borderRadius['sm']
        })
      }),
      input: (props) => ({
        ...(props.isInSitu && {
          ...theme.typography[props.variant],
          padding: 0,
          minHeight: 'auto'
        })
      }),
      height: (props) => ({
        height: props.height
      }),
      hasError: (props) => ({
        outline: `1px solid ${theme.palette.error.main}`
      }),
      typogText: {}
    }
  },
  { name: 'InlineEditableText' }
)

export default function InlineEditableText(props: InlineEditableTextProps) {
  const classes = useStyles(props)
  const {
    children,
    handleEdit,
    hasIcon,
    hasPreventEmpty,
    height,
    isInSitu,
    isSubmitOnEnter,
    maxLength,
    multiline,
    placeholderButtonText,
    renderHtml,
    tooltipTitle,
    variant
  } = props
  const [editing, setEditing] = useState(false)
  const [content, setContent] = useState(children)
  useEffect(() => {
    setContent(children)
  }, [children])
  const [hasError, setHasError] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContent(ev.target.value)
    if (ev.target.value.length > 0 && hasError) {
      setHasError(false)
    }
  }

  const handleBlur = (ev?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!content && hasPreventEmpty) {
      setHasError(true)
      return (inputRef.current.firstChild as HTMLElement)?.focus()
    }
    if (ev) ev.stopPropagation()
    setEditing(false)
    handleEdit(content)
  }

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (isSubmitOnEnter && ev.key === 'Enter') {
      ev.preventDefault()
      handleBlur()
    }
  }

  return (
    <>
      <div
        onClick={() => setEditing(true)}
        className={classnames(classes.root, { [classes.editableWrapper]: !editing })}
      >
        {editing && (
          <>
            <Input
              autoFocus
              placeholder={hasError ? 'Please enter a title' : ''}
              classes={{
                root: classnames(classes.inputRoot, { [classes.height]: height, [classes.hasError]: hasError }),
                input: classes.input
              }}
              inputProps={{ maxLength }}
              multiline={isInSitu ? false : multiline}
              onBlur={(ev) => handleBlur(ev)}
              onChange={(ev) => handleChange(ev)}
              onKeyPress={isSubmitOnEnter ? (ev) => handleKeyPress(ev) : undefined}
              ref={inputRef}
              value={content}
            />
          </>
        )}
        {!editing && !renderHtml && (
          <PresEditorTooltip title={tooltipTitle ? tooltipTitle : ''}>
            <div className={classnames(classes.editableBlock, { [classes.height]: height })}>
              <Typography className={classes.typogText} variant={variant}>
                {content}
              </Typography>
              {hasIcon && <CreateIcon className={classes.editableIcon} />}
            </div>
          </PresEditorTooltip>
        )}
        {!editing && renderHtml && <HtmlContent content={content} />}
        {!editing && !content && <Placeholder Icon={CodeIcon} buttonText={placeholderButtonText} />}
      </div>
    </>
  )
}
