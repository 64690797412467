/**
 * @module builder
 */

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getQuoteTypeOption } from '../../options/get-quote-type-options'
import { getQuoteType } from '../../quote/util/get-quote-type'
import get from 'lodash/get'
import endsWith from 'lodash/endsWith'

export interface LabelsOption {
  app: { value: string; plural: string }
  quote: { value: string; plural: string }
  option: { value: string; plural: string }
  workOrder: { value: string; plural: string }
  application: { value: string; plural: string }
  additional: { value: string; plural: string }
  additionalWork: { value: string; plural: string }
  labour: { value: string; plural: string }
  prep: { value: string; plural: string }
  volume: { value: string; plural: string }
  substrate: { value: string; plural: string }
  productionRate: { value: string; plural: string }
  acceptButton: { value: string; plural: string }
  declineButton: { value: string; plural: string }
}

export function getObjectLabels(
  args: { options?: OptionsDocument; quote?: QuoteDocument; invoice?: boolean } = {}
): LabelsOption {
  const { invoice } = args

  const options = args.options && args.options.options ? args.options.options : args.options
  const quote = args.quote

  const quoteType = getQuoteType({ quote })
  const quoteTypeOption = getQuoteTypeOption({ options: args.options, quoteType })

  const labels = {
    quote: 'Quote',
    option: 'Option',
    workOrder: 'Work Order',
    application: 'Coat',
    additional: 'Additional Item',
    additionalWork: 'Additional Work',
    labour: 'Painting',
    prep: 'Prep',
    color: 'Color',
    volume: 'Volume',
    substrate: 'Substrate',
    productionRate: 'Production Rate',
    ...get(options, 'labels', {}),
    ...(quoteTypeOption?.objectLabels ?? {})
  }

  if (invoice) {
    labels.quote = 'Invoice'
  }

  const r = Object.keys(labels).reduce((acc: any, key: string) => {
    acc[key] = {
      value: labels[key],
      plural: pluralize(labels[key])
    }
    return acc
  }, {})

  return {
    app: {
      value: args.options?.options?.product === 'sb' ? 'StickyBid' : 'PaintScout'
    },
    ...r
  }
}

function pluralize(input: string): string {
  if (input === 'Additional Work') {
    return 'Additional Work'
  }
  if (endsWith(input, 's')) {
    return `${input}es`
  }
  return `${input}s`
}
