import { Grid } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { DialogProps } from '@ui/stickybid'
import { CloseButton } from '../common'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  HtmlContent,
  HtmlEditor,
  InputField,
  Switch,
  Typography
} from '@ui/stickybid'
import type { TermsOption } from '@paintscout/util/builder'
import { getObjectLabels, updateTermsOption } from '@paintscout/util/builder'
import clone from 'lodash/clone'
import type { OptionsDocument } from 'paintscout'
import React from 'react'

export interface EditTermsOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  item: TermsOption
  options: OptionsDocument
  onConfirm?: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel?: () => void
}

export interface EditTermsOptionDialogState {
  item: TermsOption
  isDirty?: boolean
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    editor: {
      '& .ProseMirror': {
        height: '40vh',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
          height: 'calc(100dvh - 350px)'
        }
      }
    },
    divider: {},
    form: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(2)
    },
    htmlContent: {
      padding: theme.spacing(),
      border: '2px solid #000'
    },
    disabledMessage: {
      marginBottom: theme.spacing(2)
    },
    switch: {
      marginLeft: 0,
      marginBottom: 0
    }
  })
}

class EditTermsOptionDialog extends React.PureComponent<EditTermsOptionDialogProps, EditTermsOptionDialogState> {
  private signatureData?: string

  constructor(props: EditTermsOptionDialogProps) {
    super(props)

    const { item } = this.props

    this.state = {
      item: clone(item),
      isDirty: false
    }
  }

  public render() {
    const { loading, classes, options, onConfirm, onCancel, ...otherProps } = this.props
    const { item } = this.state
    const objectLabels = getObjectLabels({ options })

    return (
      <Dialog className={classes.root} maxWidth={'md'} fullWidth={true} {...otherProps}>
        <DialogTitle loading={loading} rightContent={<CloseButton disabled={loading} onCancel={onCancel} />}>
          <Typography variant={'h2'}>Edit Terms</Typography>
        </DialogTitle>
        <DialogContent>
          <form
            id="terms-option-form"
            onSubmit={(e) => {
              e.preventDefault()
              this.handleConfirm(e)
            }}
          >
            <Grid container={true} spacing={2}>
              <Grid item={true} xs>
                <InputField
                  label={'Label'}
                  name={'label'}
                  value={item.label}
                  fullWidth={true}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item={true} xs={'auto'}>
                <Typography variant={'overline'}>Active</Typography>
                <br />
                <Switch
                  onChange={this.handleToggle}
                  checked={typeof item.active !== 'undefined' ? !!item.active : true}
                  className={classes.switch}
                />
              </Grid>
              {/* <Grid item={true} xs={12} sm={12} md={6}>
                <QuoteTypeSelect
                  label={`${objectLabels.quote.value} Types`}
                  multiSelect
                  onChange={this.handleQuoteTypeChange}
                  value={item.quoteTypes}
                />
              </Grid> */}
              {/* </Grid> */}
              {/* <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <FormControlLabel
                  label={'Show Table of Contents'}
                  control={<Checkbox onChange={this.handleCheckboxChange('showToc')} checked={item.showToc} />}
                ></FormControlLabel>
              </Grid>
            </Grid> */}
              {/* <Grid container={true} spacing={3}> */}
              <Grid item={true} xs={12}>
                <div className={classes.wrapper}>
                  {item.disabled && (
                    <>
                      <Typography variant={'body2'} classes={{ root: classes.disabledMessage }}>
                        These terms have been customized to include images, videos, or other content not editable here.
                        Please contact us for assistance editing these terms.
                      </Typography>
                      <HtmlContent classes={{ root: classes.htmlContent }} content={item.content} />
                    </>
                  )}
                  {!item.disabled && (
                    <HtmlEditor
                      classes={{
                        root: classes.editor
                      }}
                      key={item.name}
                      disabled={item.disabled}
                      label={'Terms Content'}
                      sublabel={'Make changes this terms template.'}
                      value={item.content}
                      fullWidth={true}
                      onChange={this.handleContentChange}
                      bubbleMenuDisabled
                      floatingMenuDisabled
                      menuBarEnabled
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions showBorder={true}>
          <Button type="submit" form="terms-option-form" disabled={loading} prominence={1}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target

    this.setState({
      item: {
        ...this.state.item,
        [name]: value
      },
      isDirty: true
    })
  }

  public handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    this.setState({
      item: {
        ...this.state.item,
        [name]: checked
      },
      isDirty: true
    })
  }

  public handleContentChange = (content: string) => {
    this.setState({
      item: {
        ...this.state.item,
        content
      },
      isDirty: true
    })
  }

  public handleConfirm = (event: any) => {
    if (this.props.onConfirm) {
      const { options } = this.props
      const updatedOptions = updateTermsOption({ options, termsOption: this.state.item })
      this.props.onConfirm(updatedOptions, this.state.isDirty)
    }
  }

  public handleQuoteTypeChange = (values: string[]) => {
    this.setState({
      item: {
        ...this.state.item,
        quoteTypes: values
      },
      isDirty: true
    })
  }
  public handleToggle = (event: React.ChangeEvent<HTMLElement>, checked: boolean) => {
    this.setState({
      item: {
        ...this.state.item,
        active: !this.state.item.active
      },
      isDirty: true
    })
  }
}

export default withStyles(styles)(EditTermsOptionDialog)
