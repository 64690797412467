/**
 * @module builder
 */

import type { QuoteDocument, Payment, PublicQuote, PublicQuotePayment, QuoteMetaDocument } from 'paintscout'

type PaymentType<T> = T extends QuoteDocument
  ? Payment
  : T extends PublicQuote
  ? PublicQuotePayment
  : T extends QuoteMetaDocument
  ? Payment
  : never

export function getActivePayments<T extends QuoteDocument | PublicQuote | QuoteMetaDocument>(args: {
  quote: T
}): PaymentType<T>[] {
  const { quote } = args

  const activePayments = quote?.payments?.filter((payment) => !payment.deleted) as PaymentType<T>[]

  return activePayments ? [...activePayments] : []
}
