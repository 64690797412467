export default function getHighestRole(roles: string[]) {
  if (roles.includes('superadmin')) {
    return 'superadmin'
  } else if (roles.includes('admin')) {
    return 'admin'
  } else if (roles.includes('sales')) {
    return 'sales'
  } else if (roles.includes('crew')) {
    return 'crew'
  } else if (roles.includes('viewer')) {
    return 'viewer'
  }
}
