import type { Theme } from '@material-ui/core/styles'
import { useMediaQuery as muiUseMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { useFrame } from 'react-frame-component'

export function useMediaQuery(
  mediaQuery: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'print',
  options?: { up?: boolean; noSsr?: boolean }
) {
  const noSsr = options?.noSsr ?? true
  const up = options?.up ?? false
  const { window: win } = useFrame()

  const theme = useTheme<Theme>()
  const isPrint = muiUseMediaQuery('print', { noSsr })

  const breakpointName = mediaQuery === 'print' ? 'lg' : mediaQuery

  const breakpoint = up ? theme.breakpoints.up(breakpointName) : theme.breakpoints.down(breakpointName)

  // @ts-ignore
  const queryResult = muiUseMediaQuery(breakpoint, {
    noSsr,
    ...(win && { matchMedia: win.matchMedia })
  })

  if (mediaQuery === 'print') {
    return isPrint
  }

  return !isPrint && queryResult
}
