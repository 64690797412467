import React, { useState } from 'react'
import type { DialogProps } from '../Dialog'
import Dialog from '../Dialog'
import { DialogTitle, DialogActions, DialogContent } from '../Dialog'
import Button from '../Button'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'
import Alert from '../Alert'

export interface ConfirmationDialogProps extends DialogProps {
  title?: React.ReactNode
  message?: React.ReactNode
  yesLabel?: React.ReactNode
  noLabel?: React.ReactNode
  onConfirm?: (event: React.MouseEvent<HTMLElement>) => void
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void
  scary?: boolean
}

const useStyles = makeStyles((theme) => ({
  content: {
    // minHeight: 200,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  },
  scaryButton: {
    background: theme.palette.error.main
  }
}))
function ConfirmationDialog({
  onCancel,
  onConfirm,
  title,
  message,
  yesLabel,
  noLabel,
  scary,
  ...otherProps
}: ConfirmationDialogProps) {
  const [loading, setLoading] = useState(false)
  const classes = useStyles(otherProps)
  async function handleConfirm(ev: any) {
    // set loading is the onConfirm is an async function
    const loadingTimeout = setTimeout(() => setLoading(true), 1)
    await onConfirm(ev)

    setLoading(false)
    // cancel the timeout if the async finished quickly / wasn't async
    clearTimeout(loadingTimeout)
  }

  return (
    <Dialog onClose={onCancel} {...otherProps}>
      <DialogTitle loading={loading}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        {scary ? (
          <Alert content={message} severity={'error'} variant="default" />
        ) : (
          <Typography variant="body1" component="div" align="center">
            {message}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        leftButton={
          <Button prominence={3} disabled={loading} onClick={onCancel} edge={'start'}>
            {noLabel ? noLabel : `Cancel`}
          </Button>
        }
      >
        <Button
          prominence={1}
          disabled={loading}
          onClick={handleConfirm}
          className={scary ? classes.scaryButton : null}
        >
          {yesLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.defaultProps = {
  title: 'Are you sure?',
  yesLabel: 'OK'
}
export default ConfirmationDialog
