import React, { useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { MenuItem, PopupMenu, useClientOptions, useUser } from '@ui/stickybid'
import QuoteHeaderCard from '../QuoteHeaderCard'
import type { Contact } from '@paintscout/api'
import { useQuote } from '../../../context/useQuote'
import { formatPhoneNumber, isUserAssigned, isUserOwner } from '@paintscout/util/builder'

import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import MapIcon from '@material-ui/icons/MapOutlined'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/EditOutlined'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'

const addressFormat = require('address-format')

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    contents: {},
    hoverMenu: {
      display: 'flex'
    },
    menuItemDivider: {
      padding: 0,
      height: 0,
      minHeight: 0,
      paddingBottom: theme.spacing(),
      marginBottom: theme.spacing()
    }
  })

export interface AddressCardProps extends WithStyles<typeof styles> {
  type?: 'shippingAddress' | 'billingAddress'
  address?: any
  contact?: Partial<Contact>
}

function AddressCard(props: AddressCardProps) {
  const { classes, type } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const { options } = useClientOptions()
  const { preferences, user, hasPermissions } = useUser()
  const { quote, isEditable, onEditContact, onCreateContact, onSelectContact, onEditJobAddress } = useQuote()
  const [deletedContact, setDeletedContact] = useState(false)
  const contact = props?.contact ?? quote.contact

  const isAssigned = user ? (contact ? isUserAssigned(contact, user.user_id) : true) : false
  const isOwner = user ? (contact ? isUserOwner(contact, user.user_id) : true) : false
  const canEdit = user
    ? isOwner || (isAssigned && hasPermissions(['write:assigned-contacts'])) || hasPermissions(['write:all-contacts'])
    : false
  const phoneNumber = formatPhoneNumber({ options, phoneNumber: contact?.phone_number })

  let address

  if (props?.address) {
    address = props.address
  } else if (type.toString() === 'shippingAddress') {
    address = quote?.contact?.shippingAddress
  } else {
    address = {
      address: contact?.address,
      city: contact?.city,
      province: contact?.province,
      zip: contact?.postal
    }
  }

  let text = ''

  const fullAddress = addressFormat({
    address: address?.address ?? '',
    city: address?.city ?? '',
    postalCode: address?.zip ?? '',
    subdivision: address?.province ?? '',
    countryCode: 'US'
  })
  if (fullAddress && fullAddress.length > 0) {
    text += fullAddress.join('\n')
  }

  // We're going to hide the billing/shipping address cards entirely
  // if there's no address going into them.
  if (!text) {
    return null
  }

  let title = 'Address'
  if (type) {
    title = type === 'shippingAddress' ? 'Shipping Address' : 'Address'
  }

  const handleClick = contact
    ? (event) => {
        setAnchorEl(event.currentTarget)
      }
    : preferences?.addContact === 'addFirst'
    ? handleCreateContact
    : handleSelectContact

  return (
    <PopupMenu
      id={'address-menu'}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      component={
        <QuoteHeaderCard
          classes={{
            root: classes.root,
            contents: classes.contents
          }}
          onClick={handleClick}
          title={title}
          text={text}
          clickable={isEditable}
        />
      }
    >
      {canEdit && !deletedContact && contact && (
        <MenuItem icon={EditIcon} onClick={handleEditContact}>
          Edit Contact
        </MenuItem>
      )}
      <MenuItem icon={PeopleIcon} onClick={handleSelectContact}>
        Select Existing Contact
      </MenuItem>
      {(user ? hasPermissions(['create:contacts']) : false) && (
        <MenuItem icon={AddIcon} onClick={handleCreateContact}>
          Create & Use New Contact
        </MenuItem>
      )}
      {(!!phoneNumber || !!contact?.email || !!contact?.address) && (
        <MenuItem disabled classes={{ root: classes.menuItemDivider }} />
      )}
      {phoneNumber && (
        <MenuItem onClick={handleOpenCall} icon={PhoneIcon}>
          {formatPhoneNumber({ options, phoneNumber })}
        </MenuItem>
      )}
      {contact?.email && (
        <MenuItem onClick={handleOpenEmail} icon={EmailIcon}>
          {contact.email}
        </MenuItem>
      )}
      {contact?.address && (
        <MenuItem onClick={handleOpenMap} icon={MapIcon}>
          Open in Google Maps
        </MenuItem>
      )}
    </PopupMenu>
  )

  function handleOpenCall() {
    window.open(`tel:${contact.phone_number}`)
  }
  function handleOpenEmail() {
    window.open(`mailto:${contact.email}`, '_blank')
  }
  function handleOpenMap() {
    const searchAddress = `${address?.address} ${address?.city} ${address?.province}`
    window.open(`http://maps.google.com/?q=${searchAddress}`, '_blank')
  }

  function handleSelectContact() {
    onSelectContact()
    setAnchorEl(null)
  }

  function handleCreateContact() {
    onCreateContact()
    setAnchorEl(null)
  }

  function handleEditContact() {
    onEditContact()
    setAnchorEl(null)
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(null)
  }
}

export default withStyles(styles)(AddressCard)
