import type { QuoteOptions } from 'paintscout'
import type { QuoteItemSection, RenderableItemSubstrate } from '../../../..'
import { formatCurrency } from '../../../../util/format-currency'

/**
 * @description Build label for renderableSubItem
 * @returns Label for item <string>
 */
export const getItemLabel = ({
  item,
  quoteOptions,
  section,
  totalPrice
}: {
  item: RenderableItemSubstrate
  quoteOptions: QuoteOptions
  section: QuoteItemSection
  totalPrice: number
}): string => {
  // Construct label elements to combine
  const labelElements = []

  if (item.rateType === 'quantity' && !item.hideQuantity) {
    labelElements.push(item.quantity)
  }

  const inlineAreaOptions = section === 'options' || section === 'pending' || section === 'additional'
  if (!quoteOptions.showDetailedBreakdown && !quoteOptions.stackSubstrates && item.showPrice && inlineAreaOptions) {
    labelElements.push(formatCurrency({ value: totalPrice }))
  }
  if (quoteOptions.stackSubstrates && item.showPrice) {
    labelElements.push(formatCurrency({ value: totalPrice }))
  }

  if (item.showCoats && item.useCoats) {
    labelElements.push(`${item.coats} Coat${item.coats === 1 ? '' : 's'}`)
  }

  if (item.showPrep && item.prep) {
    labelElements.push(`${item.prep} hr${item.prep === 1 ? '' : 's'} prep`)
  }

  // Include product details if present
  if (item.showProduct && item.product) {
    const { product } = item
    const productString = [product.label, product.color].filter((item) => item).join(' - ')
    if (productString) {
      labelElements.push(productString)
    }
  }

  // Combine elements into full description
  const labelString = labelElements.length ? ` (${labelElements.join(', ')})` : ''

  return labelString
}
