import React from 'react'
import type { Theme } from '@material-ui/core'
import type { IconButtonProps } from '../IconButton'
import { default as IconButton } from '../IconButton'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

export interface EyeballToggleProps extends Omit<IconButtonProps, 'onClick' | 'classes'> {
  checked: boolean
  onClick: (event: React.MouseEvent<any, MouseEvent>) => void
  classes?: StyleClasses<typeof useStyles>

  label?: string
}

const useStyles = makeStyles<Theme, EyeballToggleProps>(
  (theme) => ({
    toggleRoot: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        '& $toggleButton, & $toggleLabel,': {
          color: theme.palette.primary.main
        }
      }
    },
    toggleButton: (props) => ({
      color: props.checked ? theme.palette.primary.main : theme.palette.text.secondary
    }),
    toggleLabel: (props) => ({
      color: props.checked ? theme.palette.primary.main : theme.palette.text.secondary
    })
  }),
  { name: 'EyeballToggle' }
)

function EyeballToggle(props: EyeballToggleProps) {
  const classes = useStyles(props)
  const color = props.variant === 'default' ? 'default' : 'primary'
  const { checked, onClick, label, ...rest } = props
  return (
    <div className={classes.toggleRoot} onClick={(ev) => onClick(ev)}>
      <IconButton {...rest} color={color} classes={classes} className={classes.toggleButton}>
        {checked ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
      {label && <span className={classes.toggleLabel}>{label}</span>}
    </div>
  )
}

export default EyeballToggle
