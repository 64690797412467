import type { AreaDimensions, OptionsDocument, QuoteArea, QuoteDocument } from 'paintscout'
import type { QuoteItemConsumer, RenderableItem } from '../../../index'
import { generateAreaDescription } from '../../util'
import type { QuoteItemSection } from '../../../index'
import { getItemSection } from '../../util'

export function trimArea(args: {
  item: QuoteArea
  quote: QuoteDocument
  options: OptionsDocument
  consumer: QuoteItemConsumer
  section?: QuoteItemSection
  areaSubstratesLength?: number
}): RenderableItem {
  const { item, quote, options, consumer, section, areaSubstratesLength } = args
  const areaSection = getItemSection(item)

  const description = {
    useCustom:
      areaSection === section || areaSubstratesLength === item.substrateOrder.length
        ? !!item.substrate_string.use_custom
        : false,
    custom: item.substrate_string.custom,
    default: generateAreaDescription({ quote, options, areaKey: item.key, section: section ? section : 'bid' })
  }

  const hours = item.totals.override_hours ? item.totals.override_hours_value : item.totals.hours
  const prep = item.totals.override_prep ? item.totals.override_prep_value : item.totals.prep
  const totalHours = hours + prep

  let price = 0
  let materials = 0

  price = item.totals.override_price ? item.totals.override_price_value : item.totals.price
  materials = item.totals.override_materials ? item.totals.override_materials_value : item.totals.materials

  if (section === 'additional' || section === 'pending') {
    const sectionName = section === 'additional' ? 'additionalWork' : 'pending'
    if (areaSection === section) {
      if (item.totals.override_price) {
        price = item.totals.override_price_value
      } else {
        const areaPrice = item.totals.price ? item.totals.price : 0
        const substratesPrice = item.totals[sectionName]?.price ? item.totals[sectionName]?.price : 0
        price = areaPrice + substratesPrice
      }

      if (item.totals.override_materials) {
        materials = item.totals.override_materials_value
      } else {
        const areaMaterials = item.totals.price ? item.totals.materials : 0
        const substratesMaterials = item.totals[sectionName]?.materials ? item.totals[sectionName]?.materials : 0
        materials = areaMaterials + substratesMaterials
      }
    } else {
      price = item.totals[sectionName]?.price
      materials = item.totals[sectionName]?.materials
    }
  } else {
    price = item.totals.override_price ? item.totals.override_price_value : item.totals?.price
    materials = item.totals.override_materials ? item.totals.override_materials_value : item.totals.materials
  }

  if (!price) {
    price = 0
  }
  if (!materials) {
    materials = 0
  }

  const totalPrice = materials + price

  const name = item.area_label.value
  let dimensionsString = ''
  if (consumer === 'crew') {
    const dimensions = item.dimensions as AreaDimensions
    let dimensionsItems = []

    if (item.area_type?.value === 'room') {
      dimensionsItems = [dimensions.length, dimensions.width, dimensions.height]
    } else {
      dimensionsItems = [dimensions.length, dimensions.height]
    }

    dimensionsString = dimensionsItems
      .filter((item) => item)
      .map((item) => `${item}'`)
      .join('x')

    materials = 0
  }

  return {
    key: item.key,
    type: 'area',
    name,
    subtitle: dimensionsString,
    files: item.files,
    description,
    value: consumer === 'crew' ? totalHours : totalPrice,
    additionalFields: {
      prep,
      painting: hours,
      price,
      crewNote: item.internal_note,
      clientNote: item.client_note,
      materials: consumer === 'crew' ? materials || [] : materials || 0
    }
  }
}
