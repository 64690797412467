import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'
import type { QuoteStatus } from 'paintscout'
import { Grid, Typography } from '@ui/stickybid'
import { getStatus } from '@paintscout/util/builder'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles<Theme, StatusStampProps>(
  (theme) => ({
    root: {
      marginTop: theme.spacing(0.5),
      textAlign: 'right'
    },
    color: {
      color: (props) => {
        return theme.palette.status[props.status]
      }
    },
    checkIcon: {
      fontSize: '1.2rem',
      marginRight: theme.spacing(0.75),
      position: 'relative',
      top: 5,
      color: (props) => {
        return theme.palette.status[props.status]
      }
    },
    editIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.grey[400],
      fontSize: '0.7rem',
      '@media print': {
        display: 'none'
      }
    }
  }),
  { name: 'StatusStamp' }
)

export interface StatusStampProps {
  status: QuoteStatus
  isEditable: boolean
}

function StatusStamp(props: StatusStampProps) {
  const classes = useStyles(props)
  const { status: _status } = props
  const showCheck = _status === 'accepted' || _status === 'invoiced' || _status === 'paid'
  const status = getStatus({ status: _status })

  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="flex-end">
      <Typography component={'div'} variant="overline" data-testid="quote-status" className={classes.color}>
        {showCheck && <CheckIcon className={classes.checkIcon} />}
        {status.label}
      </Typography>
    </Grid>
  )
}

export default StatusStamp
