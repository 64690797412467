import React from 'react'
import type { PresentationSection, PresentationPalette } from 'paintscout'
import { ColorOption, ButtonSelectOption } from '@ui/stickybid'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
import { usePresentation } from '../../context'

interface GeneralOptionsProps {
  section: PresentationSection
  palette: PresentationPalette
  handleOptionFieldChange: (field: string, value: any) => void
  handleOptionSectionChange: (section: PresentationSection) => void
}

function GeneralOptions(props: GeneralOptionsProps) {
  const { section, palette, handleOptionFieldChange, handleOptionSectionChange } = props
  const { background = {}, fields = {}, type } = section
  const { color: customBgColor, useTheme } = background
  const { horizontalAlign = 'center', verticalAlign = 'center', sectionHeight = 'sm', contentWidth = 'full' } = fields
  const { useCart } = usePresentation()
  const isEmbeddedQuote = type === 'quote' && !useCart
  // const isQuoteLink = type === 'quote' && useCart
  const fullWidthImage2Col = type === '2-column' && fields.fullWidthImage

  if (isEmbeddedQuote) return []

  const backgroundColor = customBgColor ? customBgColor : useTheme ? palette?.colors.background : ''

  const presetColors = palette?.title
    ? Object.values(palette.colors)
    : ['#FFFFFF', '#F0F0F0', '#E5E4E2', '#FAF9F6', '#F9F6EE', '#FCF5E5', '#EDEADE', '#FFFFF0']

  const hasBackgroundColor = backgroundColor && !['#fff', '#ffffff'].includes(backgroundColor.toLowerCase())

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Use Background Color'}
          value={background.useTheme}
          buttons={[
            {
              icon: <FormatColorFillIcon />,
              value: true,
              disabled: !!customBgColor
            }
          ]}
          onChange={(value) => handleOptionFieldChange('background.useTheme', !background.useTheme)}
        />
      ),
      tab: 'background'
    },
    {
      Component: (
        <ColorOption
          label={'Custom Background Color'}
          value={customBgColor}
          useClearOption={true}
          helpTextForClear={'Remove Background Color'}
          defaultColor={''}
          clearColor={''}
          presetColors={presetColors}
          onChange={(value) => {
            handleOptionFieldChange('background.color', value)
          }}
        />
      ),
      tab: 'background'
    },
    ...(!fullWidthImage2Col
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Section Height'}
                value={sectionHeight}
                buttons={[
                  { icon: 'XS', value: 'x-sm' },
                  { icon: 'S', value: 'sm' },
                  { icon: 'M', value: 'md' },
                  { icon: 'L', value: 'lg' }
                ]}
                onChange={(value) => {
                  handleOptionFieldChange('fields.sectionHeight', value)
                }}
              />
            ),
            tab: 'format'
          }
        ]
      : []),
    ...(!['spacer'].includes(type) && !fullWidthImage2Col
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Content Width'}
                value={contentWidth}
                buttons={[
                  // { icon: 'XS', value: 'x-sm' },
                  // { icon: 'S', value: 'sm' },
                  {
                    icon: 'M',
                    value: 'md',
                    forceSelected: contentWidth === 'full' && hasBackgroundColor && !['banner'].includes(type),
                    disabled: hasBackgroundColor && !['banner'].includes(type)
                  },
                  {
                    icon: 'L',
                    value: 'full',
                    disabled: hasBackgroundColor && !['banner'].includes(type)
                  }
                ]}
                infoText={
                  contentWidth === 'full' && hasBackgroundColor && !['banner'].includes(type)
                    ? 'Sections with background colors must be medium width'
                    : ''
                }
                onChange={(value) => {
                  handleOptionFieldChange('fields.contentWidth', value)
                }}
              />
            ),
            tab: 'format'
          },
          {
            Component: (
              <ButtonSelectOption
                label={'Content Alignment'}
                value={verticalAlign}
                buttons={[
                  { icon: <VerticalAlignTopIcon />, value: 'start' },
                  { icon: <ArrowRightAltIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'top' },
                  { icon: <VerticalAlignCenterIcon />, value: 'center' },
                  { icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} />, value: 'bottom' },
                  { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(180deg)' }} />, value: 'end' }
                ]}
                onChange={(value) => {
                  handleOptionFieldChange('fields.verticalAlign', value)
                }}
              />
            ),
            tab: 'format'
          }
        ]
      : [])
    // ...(contentWidth !== 'full' || ['button'].includes(type) || isQuoteLink
    //   ? [
    //       {
    //         Component: (
    //           <ButtonSelectOption
    //             label={'Content Alignment (H)'}
    //             value={horizontalAlign}
    //             buttons={[
    //               { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'left' },
    //               { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
    //               { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'right' }
    //             ]}
    //             onChange={(value) => {
    //               handleOptionFieldChange('fields.horizontalAlign', value)
    //             }}
    //           />
    //         ),
    //         tab: 'format'
    //       }
    //     ]
    //   : [])
  ]

  return options
}

export default GeneralOptions
