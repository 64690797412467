import React from 'react'
import type { Theme, WithStyles, WithTheme } from '@material-ui/core/styles'
import { createStyles, withStyles, withTheme } from '@material-ui/core/styles'
import { Hidden, MenuList, Card } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PopupMenu from '../PopupMenu'
import Button from '../Button'
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import PopupMenuItem from '../PopupMenuItem'

export interface FloatingMenuItem {
  key: any
  label: string
}

export interface FloatingMenuProps {
  items: FloatingMenuItem[]

  value: any
  defaultValue?: any
  onItemClick: (menuItem: FloatingMenuItem) => any

  collapseOn?: Breakpoint

  children?: React.ReactNode
}

export interface SettingsState {}

const styles = (theme: Theme) =>
  createStyles({
    list: {
      padding: 0
    },
    floatingMenu: {
      borderRadius: 2,
      boxShadow: theme.shadows[1]
    },
    menuItem: {},
    itemSelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.contrastText
    },
    popup: {
      width: '100%'
    }
  })

type FloatingMenuPropsWithStyles = FloatingMenuProps & WithStyles<typeof styles> & WithTheme

function FloatingMenu({
  items,
  value,
  onItemClick,
  children,
  classes,
  defaultValue,
  theme,
  collapseOn = 'md',
  ...other
}: FloatingMenuPropsWithStyles) {
  const currentItem = items.find((menu) => menu.key === value) || defaultValue

  const menuItems = items.map((item) => (
    <PopupMenuItem
      key={item.key}
      selected={item.key === value}
      onClick={() => onItemClick(item)}
      classes={{ root: classes.menuItem, selected: classes.itemSelected }}
    >
      {item.label}
    </PopupMenuItem>
  ))

  // get all breakpoint key values
  const breakpoints = theme.breakpoints.keys

  // slice keys for collapsed/expanded based on collapseOn value
  const collapseBreakpoints = breakpoints.slice(breakpoints.indexOf(collapseOn) + 1)
  const expandedBreakpoints = breakpoints.slice(0, breakpoints.indexOf(collapseOn) + 1)

  return (
    <div {...other}>
      <Hidden only={expandedBreakpoints}>
        <Card classes={{ root: classes.floatingMenu }}>
          <MenuList className={classes.list}>{menuItems}</MenuList>
        </Card>
      </Hidden>
      <Hidden only={collapseBreakpoints}>
        <PopupMenu
          className={classes.popup}
          classes={{
            menu: classes.list
          }}
          MenuListProps={{
            classes: {
              root: classes.list
            }
          }}
          component={
            <Button fullWidth icon={ExpandMoreIcon} aria-haspopup>
              {currentItem && currentItem.label}
            </Button>
          }
          id="floating-menu-popup"
        >
          {menuItems}
        </PopupMenu>
      </Hidden>
    </div>
  )
}

export default withStyles(styles)(withTheme(FloatingMenu))
