import { Grid, styled } from '@material-ui/core'
import {
  Collapse,
  FormikCheckbox,
  FormikFormSectionTitle,
  FormikInputField,
  FormSection,
  FormSectionTitle,
  Typography
} from '@ui/stickybid'
import { Field, useFormikContext } from 'formik'
import type { OptionsDocument } from 'paintscout'
import React from 'react'

const ToggleableSection = styled('div')({
  paddingLeft: 20,
  paddingRight: 20
})

const ClientFeaturesForm = () => {
  const { values } = useFormikContext<{ options: OptionsDocument }>()
  const { options } = values
  return (
    <>
      <FormSectionTitle title="Features" />
      <FormSection>
        <Grid container spacing={3}>
          {/* Quotes  */}
          <Grid item xs={12}>
            <Field title="Quotes" name="options.options.features.quotes.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features?.quotes?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  {/* Quote views */}
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Views</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Area"
                          name="options.options.features.quotes.views.area"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label={`${objectLabels.substrate.plural}`}
                          name="options.options.features.quotes.views.substrate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Work Order"
                          name="options.options.features.quotes.views.workOrder"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}

                  {/* Quote emails */}
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Emails</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="To Staff"
                          name="options.options.features.quotes.emails.toStaff"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Reminders"
                          name="options.options.features.quotes.emails.reminders"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Attach PDF"
                          name="options.options.features.quotes.emails.attachPdf"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Thank You"
                          name="options.options.features.quotes.emails.acceptThankYou"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Enable 'To:' field on sent email templates"
                          name="options.options.features.quotes.emails.allowToField"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}

                  {/* Quote sidebar */}
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Sidebar</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Activity"
                          name="options.options.features.quotes.sidebar.activity.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Details"
                          name="options.options.features.quotes.sidebar.details.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Calculation"
                          name="options.options.features.quotes.sidebar.calculation.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Presentation"
                          name="options.options.features.quotes.sidebar.presentation.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Terms"
                          name="options.options.features.quotes.sidebar.terms.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Advanced"
                          name="options.options.features.quotes.sidebar.advanced.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          label="Source/Deal"
                          name="options.options.features.quotes.sidebar.advanced.source"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* Quote sidebar advanced */}
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Sidebar - Advanced</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Show Detailed Breakdown"
                          name="options.options.features.quotes.sidebar.advanced.displayOptions.showDetailedBreakdown"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label={`Stack Area ${objectLabels.substrate.plural}`}
                          name="options.options.features.quotes.sidebar.advanced.displayOptions.stackSubstrates"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide Work Order Material Cost"
                          name="options.options.features.quotes.sidebar.advanced.displayOptions.hideWorkOrderMaterialCost"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Contacts</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Address Suggestions"
                          name="options.options.features.quotes.addressValidation.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Misc.</Typography>
                    <Grid container spacing={3}>
                      {/* <Grid item>
                        <Field
                          label="Additional Work"
                          name="options.options.features.quotes.additionalWork"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      <Grid item>
                        <Field
                          label="Optional Items (Includes Customer View Option Acceptance)"
                          name="options.options.features.quotes.optionalItems"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Field
                          label="Material Cost"
                          name="options.options.features.quotes.materialCost.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Split Tax Rate"
                          name="options.options.features.quotes.splitTaxRate.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Groups"
                          name="options.options.features.quotes.groups.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Templates"
                          name="options.options.features.quotes.templates.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Acceptance Date Change"
                          name="options.options.features.quotes.changeAcceptanceDate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Scheduled Date"
                          name="options.options.features.quotes.scheduledDate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Secondary Contact"
                          name="options.options.features.quotes.secondaryContact.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Contact Sources"
                          name="options.options.features.contactLeadSources.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Request Google Review (Beta)"
                          name="options.options.features.requestGoogleReview.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Product Order Form"
                          name="options.options.features.quotes.productOrderForm"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Tags"
                          name="options.options.features.quotes.tags.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Convert to Line Item"
                          name="options.options.features.quotes.convertToLineItem"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Multiple Invoices"
                          name="options.options.features.quotes.multipleInvoices"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Bulk Materials Editing"
                          name="options.options.features.quotes.bulkMaterialsEditing"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Editable Area Totals"
                          name="options.options.features.quotes.editableAreaTotals"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Advanced Rate Options</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Enabled"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Work Order Only"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.workOrderOnly"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide Quantities (Estimate)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.hideQuantity"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide Quantities (Work Order)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.hideQuantityOnWorkOrder"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Coats"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showCoats"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Product"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showProduct"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Price"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showPrice"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Prep Hours"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showPrep"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Exclude Tax"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.excludeTax"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Exclude Discount"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.excludeDiscount"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hourly Rate"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.hourlyRate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Use Inches (Beta)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.useInches"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Cubic Feet (Beta)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.cubicFeet"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          {/* <Grid item xs={12}>
            <Field
              title="Markup Materials(Option Tile Multi-select)"
              name="options.options.features.markupMaterials.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Field
              title="Invoices"
              name="options.options.features.invoices.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Reports (Beta)"
              name="options.options.features.reports.enabled"
              component={FormikFormSectionTitle}
            />
            {/* <Collapse show={options.options.features?.reports?.enabled}>
              <ToggleableSection>
                <Grid item xs={12}>
                  <Field label="Payments" name="options.options.features.reports.payments" component={FormikCheckbox} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Financing"
                    name="options.options.features.reports.financing"
                    component={FormikCheckbox}
                  />
                </Grid>
              </ToggleableSection>
            </Collapse> */}
          </Grid>
          {/* <Grid item xs={12}>
            <Field title="Media" name="options.options.features.media.enabled" component={FormikFormSectionTitle} />
          </Grid> */}
          <Grid item xs={12}>
            <Field
              title="Import Quote Image (Beta)"
              name="options.options.features.importQuoteImage.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.importQuoteImage?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      <Grid item>
                        <Field
                          label="OCR Imported Images"
                          name="options.options.features.importQuoteImage.ocr.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Import Image Only Option"
                          name="options.options.features.importQuoteImage.importOnly.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Presentations"
              name="options.options.features.presentation.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.presentation?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <Field
                          label="Stock Photos"
                          name="options.options.features.presentation.stockPhotos"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Smart Content (Beta)"
                          name="options.options.features.presentation.generateContent"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Custom Logo"
                          name="options.options.features.presentation.customLogo"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Custom Presentation Allowance"
                          name="options.options.features.presentation.customAllowance"
                          component={FormikInputField}
                          type={'number'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          {/* <Grid item xs={12}>
            <Field
              title="Show Data Rows Presentation Section (Beta)"
              name="options.options.features.showDataRowsSection"
              component={FormikFormSectionTitle}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Field
              title="Payment Options (Beta)"
              name="options.options.features.paymentOptions.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Field
              title="Conversations (WIP)"
              name="options.options.features.quotes.conversations.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Follow-Ups"
              name="options.options.features.quotes.followUps.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.quotes?.followUps?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          label="SMS Follow-Ups"
                          name="options.options.features.quotes.followUps.sendText"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          label="Custom User Follow-Ups"
                          name="options.options.features.quotes.followUps.userFollowUps.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          {/* <Grid item xs={12}>
            <Field
              title="Notifications"
              name="options.options.features.notifications.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.notifications?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Statistical Digest"
                          name="options.options.features.notifications.statsDigest"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Quote Viewed"
                          name="options.options.features.notifications.quoteViewed"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid> */}
          <Grid item xs={12}>
            <Field
              title="Integrations"
              name="options.options.features.integrations.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.integrations?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  {/* Quote views */}
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      {/* <Grid item>
                        <Field
                          label={'CompanyCam'}
                          name="options.options.features.integrations.providers.companycam"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={'Dropbox'}
                          name="options.options.features.integrations.providers.dropbox"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      <Grid item>
                        <Field
                          label={'QuickBooks Online (Beta)'}
                          name="options.options.features.integrations.providers.intuit"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label={'Stripe'}
                          name="options.options.features.integrations.providers.stripe"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography>Freshbooks</Typography>
                              <Typography variant="body2">(Connection Only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.freshbooks"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography>Hubspot</Typography>
                              <Typography variant="body2">(Connection Only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.hubspot"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/** 
                       * Not quite ready for prime-time (ie: still using legacy version,
                       * needs some work to figure out how the transition is going to work)
                      <Grid item>
                        <Field
                          label="Pipeline CRM"
                          name="options.options.features.integrations.providers.pld"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      **/}
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography>Sage</Typography>
                              <Typography variant="body2">(Connection only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.sage"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={'Xero'}
                          name="options.options.features.integrations.providers.xero"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      <Grid item>
                        <Field
                          label={'Zapier'}
                          name="options.options.features.integrations.providers.zapier"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Field
                          label={'ZenDesk Sell'}
                          name="options.options.features.integrations.providers.getbase"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={'WorkGlue'}
                          name="options.options.features.integrations.providers.workglue"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography>Wave</Typography>
                              <Typography variant="body2">(Connection Only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.wave"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={'Wisetack'}
                          name="options.options.features.integrations.providers.wisetack"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={'Paintscout Api Keys'}
                          name="options.options.features.integrations.providers.paintscoutApiKeys"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Dashboard"
              name="options.options.features.dashboard.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.dashboard?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  {/* Quote views */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Sections</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Statistics"
                          name="options.options.features.dashboard.statistics"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field title="SMS" name="options.options.features.sms.enabled" component={FormikFormSectionTitle} />
          </Grid>
          {/* <Grid item xs={12}>
            <Field
              title="Templates"
              name="options.options.features.templates.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Field
              title="LogRocket"
              name="options.options.features.logRocket.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field title="Billing" name="options.options.features.billing.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features?.billing?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Disable Self-Service Billing"
                          name="options.options.features.billing.disableSelfService"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field title="Team" name="options.options.features.team.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features?.team?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Sections</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field label="Crew" name="options.options.features.team.crew" component={FormikCheckbox} />
                      </Grid>
                      <Grid item>
                        <Field label="Viewer" name="options.options.features.team.viewer" component={FormikCheckbox} />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <Typography variant="h5">Permissions</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Custom Permissions"
                          name="options.options.features.team.permissions.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Users</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Custom User Seat Allowance"
                          name="options.options.features.team.customAllowance"
                          component={FormikInputField}
                          type={'number'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="White Labelling"
              name="options.options.features.whiteLabelling.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.whiteLabelling?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      label="Url White Labelling (TBD)"
                      name="options.options.features.whiteLabelling.urlWhiteLabelling"
                      component={FormikCheckbox}
                      disabled
                    />
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          {/* <Grid item xs={12}>
            <Field
              title="Font Scaling"
              name="options.options.features.fontScaling.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Field
              title="ElasticSearch"
              name="options.options.features.database.elasticsearch.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientFeaturesForm
