import React, { useRef, useContext } from 'react'
import type { QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogStackContext,
  DialogTitle,
  FilePreview,
  Grid,
  InputField,
  Typography,
  ViewMediaDialog
} from '@ui/stickybid'
import type { DialogProps } from '@ui/stickybid'
import { useQuote } from '../../context'
import { CloseButton } from '../'

export interface EditMediaDialogProps extends DialogProps {
  file: QuoteFile
  index?: number
  onConfirm?: (file: QuoteFile) => void
  onCancel?: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  file: {},
  mediaTitleDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}))

function EditMediaDialog({ onCancel, onConfirm, index, file, ...props }: EditMediaDialogProps) {
  const classes = useStyles(props)
  const inputRef = useRef<HTMLInputElement>()
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { quote } = useQuote()

  return (
    <Dialog {...props}>
      <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>
        <Typography variant={'h2'}>Edit Media</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div className={classes.mediaTitleDiv}>
              <FilePreview onClick={() => handleClickFile(index)} file={file} square showFirstPageOnly />
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputField
              name={'caption'}
              placeholder={'A brief description of the media'}
              label={'Caption'}
              defaultValue={file?.caption ?? ''}
              multiline
              inputRef={inputRef}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button prominence={1} onClick={handleConfirm}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleClickFile(index: number) {
    const currentFiles = quote.galleryFiles.map((file) => quote.files?.[file])
    openDialog(ViewMediaDialog, {
      title: 'Gallery',
      files: currentFiles,
      index,
      onClose: () => {
        dismissDialog()
      }
    })
  }

  function handleConfirm() {
    onConfirm({
      ...file,
      caption: inputRef?.current?.value ?? ''
    })
  }
}

export default EditMediaDialog
