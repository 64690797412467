import React from 'react'

import { useMediaQuery } from '@ui/stickybid'

import QuoteHeaderMobile from './QuoteHeaderMobile'
import QuoteHeaderFull from './QuoteHeaderFull'

export interface QuoteHeaderProps {
  isEditable?: boolean
  workOrder?: boolean
  className?: string
}

function QuoteHeader(props: QuoteHeaderProps) {
  const mdDown = useMediaQuery('md')

  if (mdDown) {
    return <QuoteHeaderMobile {...props} />
  } else {
    return <QuoteHeaderFull {...props} />
  }
}

export default QuoteHeader
