import type { AreaSubstrate, QuoteArea, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { add } from '../util/add'
import isPlainObject from 'lodash/isPlainObject'
import { addDimensions } from './add-dimensions'
import { getItemSection } from '../../builder/quote/util/get-item-section/get-item-section'

export function subTotal(quote: QuoteDocument) {
  if (!quote.areas) {
    quote.areas = {}
  }
  if (!quote.totals) {
    quote.totals = {
      show_total: true,
      price: 0,
      hours: 0,
      prep: 0,
      additionalWork: { prep: 0, hours: 0, price: 0, materials: 0 },
      pending: { prep: 0, hours: 0, price: 0, materials: 0 }
    }
  }

  let updatedTotals = { ...quote.totals, prep: 0, hours: 0, price: 0 }

  if (!quote.order) {
    return updatedTotals
  }

  updatedTotals.dimensions = {
    lnft: 0,
    sqft: 0,
    sqft_walls: 0,
    sqft_ceiling: 0,
    sqft_floor: 0
  }

  quote.order.area.forEach((item) => {
    if (item._deleted) {
      return
    }
    if (item.type !== 'area') {
      return
    }

    if (!quote.areas[item.key]) {
      return
    }

    const area = quote.areas[item.key]

    if (!isPlainObject(area)) {
      return
    }

    if (area._deleted) {
      return
    }

    let group
    if (area.int_ext && area.int_ext.value) {
      group = area.int_ext.value
    } else if (quote.int_ext && quote.int_ext.value) {
      group = quote.int_ext.value
    }

    if (!group || group === 'all') {
      group = 'int'
    }

    if (!updatedTotals?.groups[group]) {
      updatedTotals.groups[group] = {
        hours: 0,
        prep: 0,
        price: 0
      }
    }

    let hours = 0
    if (area.totals.override_hours) {
      hours = parseNumber(area.totals.override_hours_value)
    } else {
      hours = parseNumber(area.totals.hours)
    }

    let prep = 0
    if (area.totals.override_prep) {
      prep = parseNumber(area.totals.override_prep_value)
    } else {
      prep = parseNumber(area.totals.prep)
    }

    let price = 0
    if (area.totals.override_price) {
      price = parseNumber(area.totals.override_price_value)
    } else {
      price = parseNumber(area.totals.price)
    }

    let materials = 0
    if (area.totals.override_materials) {
      materials = parseNumber(area.totals.override_materials_value)
    } else {
      materials = parseNumber(area.totals.materials)
    }

    if (area.totals.pending) {
      updatedTotals.pending = add(updatedTotals.pending, area.totals.pending, quote)
    }
    if (area.totals.additionalWork) {
      updatedTotals.additionalWork = add(updatedTotals.additionalWork, area.totals.additionalWork, quote)
    }

    if (area.use_total) {
      if (!updatedTotals.materials) {
        updatedTotals.materials = item.parent ? 0 : materials
      } else {
        updatedTotals.materials += item.parent ? 0 : materials
      }

      updatedTotals = add(
        updatedTotals,
        {
          hours,
          prep,
          price: item.parent ? 0 : price
        },
        quote
      )

      updatedTotals.groups[group] = add(
        updatedTotals.groups[group],
        {
          hours,
          prep,
          price: item.parent ? 0 : price
        },
        quote
      )

      if (quote.options?.useAllAreasForTotalDimensions) {
        const areaDimensions =
          area.area_type?.value === 'surface'
            ? {
                sqft: area.dimensions?.sqft ?? 0,
                lnft: area.dimensions?.lnft ?? 0,
                sqft_walls: 0,
                sqft_ceiling: 0,
                sqft_floor: 0
              }
            : {
                sqft_walls: area.dimensions?.sqft_walls ?? 0,
                sqft_ceiling: area.dimensions?.sqft_ceiling ?? 0,
                sqft_floor: area.dimensions?.sqft_floor ?? 0,
                lnft: area.dimensions?.lnft ?? 0,
                sqft: 0
              }

        updatedTotals.dimensions = addDimensions({ total: updatedTotals.dimensions, area: areaDimensions })
      } else {
        const tempSubs = []
        Object.keys(area.substrates).forEach((key) => {
          tempSubs.push(area.substrates[key])
        })
        const substrates = tempSubs.flat() as AreaSubstrate[]
        const workedAreaDimensions = {
          sqft: [],
          lnft: [],
          sqft_walls: [],
          sqft_ceiling: [],
          sqft_floor: []
        }

        const getDimensions = (sub: AreaSubstrate, area: QuoteArea) => {
          const rateType = sub.rate.rate_type
          let activeDimension = 'sqft'
          if (area.area_type.value === 'room') {
            activeDimension = rateType
          } else if (rateType === 'lnft') {
            activeDimension = 'lnft'
          }
          if (workedAreaDimensions[rateType] && ['bid', 'additional'].includes(getItemSection(sub))) {
            workedAreaDimensions[rateType].push(area.dimensions?.[activeDimension])
          }
        }

        substrates.forEach((sub) => {
          getDimensions(sub, area)
        })

        const getMax = (arr: number[]) => {
          if (!arr.length) return 0
          else return arr.reduce((a, b) => Math.max(a, b))
        }

        let largestWorkedDimensions = {}
        Object.keys(workedAreaDimensions).forEach(
          (sub) => (largestWorkedDimensions[sub] = getMax(workedAreaDimensions[sub]))
        )

        updatedTotals.dimensions = addDimensions({ total: updatedTotals.dimensions, area: largestWorkedDimensions })
      }
    } else if (area?.pending && !(quote?.version >= 3)) {
      updatedTotals.pending = add(
        updatedTotals.pending,
        {
          hours,
          prep,
          price: item.parent ? 0 : price,
          materials
        },
        quote
      )
    } else if (area?.additionalWork && !(quote?.version >= 3)) {
      updatedTotals.additionalWork = add(
        updatedTotals.additionalWork,
        {
          hours,
          prep,
          price: item.parent ? 0 : price,
          materials
        },
        quote
      )
    }
  })

  return updatedTotals
}
