import React from 'react'
import { default as MuiCard } from '@material-ui/core/Card'
import { default as MuiCardActionArea } from '@material-ui/core/CardActionArea'
import { default as MuiCardActions } from '@material-ui/core/CardActions'
import { default as MuiCardContent } from '@material-ui/core/CardContent'
import { default as MuiCardMedia } from '@material-ui/core/CardMedia'
import type {
  CardProps as MuiCardProps,
  CardActionAreaProps as MuiCardActionAreaProps,
  CardActionsProps as MuiCardActionsProps,
  CardContentProps as MuiCardContentProps,
  CardMediaProps as MuiCardMediaProps
} from '@material-ui/core'

export interface CardProps extends MuiCardProps {
  CardActionAreaProps?: MuiCardActionAreaProps
  CardActionsProps?: MuiCardActionsProps
  CardContentProps?: MuiCardContentProps
  CardMediaProps?: MuiCardMediaProps
  contentChildren?: React.ReactNode
  actionsChildren?: React.ReactNode
}

function Card(props: CardProps) {
  const { CardActionAreaProps, CardActionsProps, CardContentProps, CardMediaProps, contentChildren, actionsChildren } =
    props

  const cardBody = (
    <>
      {CardMediaProps && <MuiCardMedia {...CardMediaProps} />}
      {contentChildren && <MuiCardContent {...CardContentProps}>{contentChildren}</MuiCardContent>}
      {actionsChildren && <MuiCardActions {...CardActionsProps}>{actionsChildren}</MuiCardActions>}
    </>
  )
  return (
    <MuiCard {...props}>
      {CardActionAreaProps && <MuiCardActionArea {...CardActionAreaProps}>{cardBody}</MuiCardActionArea>}
      {!CardActionAreaProps && cardBody}
    </MuiCard>
  )
}

export default Card
