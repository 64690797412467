import type { QuoteDocument } from 'paintscout'
import type { QuoteItemSection } from '..'

export function isInSubstrateGroup({
  quote,
  rateKey,
  section
}: {
  quote: QuoteDocument
  rateKey: string
  section?: QuoteItemSection
}): boolean {
  if (!quote || !rateKey) {
    return false
  }

  for (const groupKey in quote.groups || []) {
    if (quote.groups[groupKey].children.includes(rateKey)) {
      if (!section || quote.groups[groupKey].section === section) {
        return true
      }
    }
  }

  return false
}
