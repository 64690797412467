import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import type { SwitchProps as CoreSwitchProps } from '@ui/core/Switch'
import { default as CoreSwitch } from '@ui/core/Switch'

const useStyles = makeStyles<Theme, SwitchProps>(
  (theme) => ({
    root: (props) => ({
      position: 'relative',
      width: props.sizeSmall ? 34 : 42,
      height: props.sizeSmall ? 18 : 26,
      padding: 2,
      margin: theme.spacing(),
      background: 'transparent',
      '@media print': {
        display: 'none'
      }
    }),
    input: {
      background: 'transparent'
    },
    switchBase: (props) => ({
      padding: 2,
      background: 'transparent'
    }),
    thumb: (props) => ({
      width: props.sizeSmall ? 10 : 14,
      height: props.sizeSmall ? 10 : 14,
      marginTop: props.sizeSmall ? 2 : 4,
      marginLeft: props.checked && props.sizeSmall ? -2 : 2,
      color: props.checked && !props.disabled ? theme.palette.primary.dark : theme.palette.secondary.main,
      margin: 1,
      boxShadow: 'none'
    }),
    track: (props) => ({
      border: `1px solid ${
        props.checked && !props.disabled ? theme.palette.primary.dark : theme.palette.secondary.light
      }`,
      borderRadius: 13,
      opacity: '1 !important',
      backgroundColor: 'transparent !important',
      background: 'transparent !important'
    }),
    checked: {},
    disabled: {}
  }),
  { name: 'Switch' }
)

export interface SwitchProps extends Omit<CoreSwitchProps, 'inputProps'> {
  classes?: CoreSwitchProps['classes'] & StyleClasses<typeof useStyles>
  overlay?: string
  inputProps?: CoreSwitchProps & { 'data-testid'?: string }
  sizeSmall?: boolean
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(function Switch(props: SwitchProps, ref) {
  const classes = useStyles(props)
  return (
    <CoreSwitch
      {...(props as any)}
      disableRipple
      ref={ref}
      classNames={{
        root: classes.root,
        switch: classes.switchBase
      }}
      classes={classes}
    />
  )
})

export default Switch
