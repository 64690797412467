import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import AlertIcon from './AlertIcon'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '../IconButton'
import classnames from 'classnames'
import type { StyleClasses } from '../theme'

export interface AlertProps {
  Typography: React.ComponentType<any>
  children?: React.ReactNode
  className?: string
  classes?: StyleClasses<typeof useStyles>
  content?: React.ReactNode
  title?: string
  tooltip?: string
  noMargin?: boolean
  icon?: React.ReactNode
  severity?: 'warning' | 'error' | 'info' | 'success' | 'default'
  variant?: 'default' | 'outlined' | 'filled' | 'text'
  onClose?: () => void
}

function getSeverityColor(severity: AlertProps['severity'], theme: Theme) {
  switch (severity) {
    case 'warning':
      return theme.palette.warning
    case 'error':
      return theme.palette.error
    case 'success':
      return theme.palette.success
    case 'info':
      return theme.palette.info
    default:
      return {
        light: theme.palette.grey[200],
        main: theme.palette.grey[500],
        dark: theme.palette.grey[900],
        contrastText: '#fff'
      }
  }
}

const useStyles = makeStyles<Theme, AlertProps>(
  (theme) => ({
    root: (props) => {
      const { severity, variant, noMargin } = props
      const severityColor = getSeverityColor(severity, theme)
      let background = 'transparent'
      if (variant === 'default') {
        background = severityColor.light
      } else if (variant === 'filled') {
        background = severityColor.main
      }

      const borderColor = variant === 'default' ? severityColor.light : severityColor.main

      const color = variant === 'filled' ? severityColor.contrastText : severityColor.dark

      const borderStyle = variant === 'text' ? 'none' : 'solid'
      const padding = variant === 'text' ? 0 : theme.spacing(2)

      return {
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: theme.shape.borderRadius,
        padding,
        borderWidth: 1,
        borderStyle,
        borderColor,
        background,
        color,
        position: 'relative',
        marginBottom: noMargin ? 0 : theme.spacing(2)
      }
    },
    contentWrapper: {
      marginTop: 2,
      width: '100%'
    },
    iconWrapper: (props) => {
      const { severity, variant, noMargin } = props
      const severityColor = getSeverityColor(severity, theme)
      return {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        color: variant === 'filled' ? '#fff' : severityColor.main
      }
    },
    closeWrapper: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    title: {
      marginBottom: theme.spacing(0.5),
      color: 'inherit'
    },
    content: {}
  }),
  {
    name: 'Alert'
  }
)

export default function Alert(props: AlertProps) {
  const {
    children,
    content,
    title,
    tooltip,
    severity = 'default',
    variant = 'default',
    className,
    onClose,
    icon: iconProp,
    Typography
  } = props
  const classes = useStyles(props)
  const icon = typeof iconProp === 'undefined' ? <AlertIcon severity={severity} /> : iconProp

  return (
    <div className={classnames(classes.root, className)}>
      {icon && <div className={classes.iconWrapper}>{icon}</div>}
      <div className={classes.contentWrapper}>
        {title && (
          <Typography classes={{ root: classes.title }} variant={'h4'} component="p">
            {title}
          </Typography>
        )}
        {children ? <>{children}</> : <AlertContent />}
      </div>
      {onClose && (
        <div className={classes.closeWrapper}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </div>
  )

  function AlertContent() {
    return <>{content && <Typography classes={{ root: classes.content }}>{content}</Typography>}</>
  }
}
