import React from 'react'
import type { FieldProps } from 'formik'
import type { Moment } from 'moment'
import moment from 'moment'
import type { DatePickerProps } from '../DatePicker'
import DatePicker from '../DatePicker'

export interface FormikDatePickerProps extends FieldProps<any>, Omit<DatePickerProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikDatePicker = ({ field, form, ...props }: FormikDatePickerProps) => {
  const onChange = (date: Moment) => {
    form.handleChange({ target: { name: field.name, value: date ? date.unix() * 1000 : null } })
  }

  return <DatePicker {...field} {...props} onChange={onChange} value={field.value ? moment(field.value) : null} />
}

export default FormikDatePicker
