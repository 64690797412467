import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import type { TooltipProps } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles<Theme, PresEditorTooltipProps>(
  (theme) => ({
    tooltip: {
      ...theme.typography.subtitle2,
      color: theme.palette.common.white,
      fontSize: '0.8rem'
    }
  }),
  { name: 'PresEditorTooltip' }
)

export interface PresEditorTooltipProps extends TooltipProps {
  classes?: StyleClasses<typeof useStyles>
}

function PresEditorTooltip(props: PresEditorTooltipProps) {
  const classes = useStyles(props)
  const { children, ...rest } = props

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = (ev) => {
    if (ev.target.className.includes && ev.target.className.includes('MuiPopover')) {
      return null
    } else setOpen(true)
  }

  return (
    <Tooltip
      enterDelay={800}
      enterNextDelay={400}
      onClose={handleClose}
      onMouseDown={handleClose}
      onOpen={handleOpen}
      open={open}
      placement={'bottom'}
      {...rest}
      classes={{ tooltip: classes.tooltip, ...rest.classes }}
    >
      <span>{children}</span>
    </Tooltip>
  )
}

export default PresEditorTooltip
