import React, { useCallback, useEffect, useState } from 'react'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import CoreInputField from '@ui/core/InputField'
import type { InputProps } from '../Input'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import Input from '../Input'
import FormattedInput from '@ui/core/FormattedInput'
import classnames from 'classnames'
import { default as MuiInputAdornment } from '@material-ui/core/InputAdornment'
import Typography from '../Typography'
import debounce from 'lodash/debounce'

const useStyles = makeStyles<Theme, InputFieldProps>(
  (theme) => ({
    root: {
      '& .MuiInputBase-inputAdornedStart': {
        paddingLeft: 0
      }
    },
    inputRoot: {},
    inputInput: {},
    adornment: {
      marginLeft: theme.typography.pxToRem(7),
      marginRight: theme.typography.pxToRem(7),
      color: theme.palette.primary.main[500],
      cursor: 'default'
    },
    inputFocused: {
      boxShadow: `0 0 0 1px ${theme.palette.primary.main[700]}`,
      borderColor: theme.palette.primary.dark,
      '& $adornment': {
        color: theme.palette.primary.main
      }
    },
    clickableIcon: {
      cursor: 'pointer'
    },
    input: {},
    icon: {},
    hasLabel: {},
    hasSublabel: {}
  }),
  { name: 'InputField' }
)

export type InputFieldFormat = 'price' | 'quantity' | 'hours' | 'dimension' | 'rate' | 'taxRate'

export interface InputFieldProps extends InputProps {
  classes?: InputProps['classes'] & StyleClasses<typeof useStyles>
  autoSelect?: boolean
  icon?: React.ComponentType<SvgIconProps>

  format?: InputFieldFormat
  suffix?: string
  prefix?: string
  pattern?: { expression: string; title: string }
  onIconClick?: (ev: React.MouseEvent) => void
}

function InputField(props: InputFieldProps) {
  const classes = useStyles(props)
  const inputRef = React.createRef<any>()

  const {
    icon: Icon,
    label: labelText,
    sublabel: sublabelText,
    inlineLabel,
    margin,
    required,
    pattern,
    autoSelect,
    error,
    className,
    style,
    startAdornment,
    endAdornment,
    onChange,
    value: propsValue,
    ...baseInputProps
  } = props

  const [value, setValue] = useState(propsValue)

  useEffect(() => {
    if (value !== propsValue) setValue(propsValue)
  }, [propsValue])

  const debounceOnChange = useCallback(
    debounce((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      ev.currentTarget = ev.currentTarget ?? ev.target
      if (onChange) onChange(ev)
    }, 400),
    [onChange]
  )

  const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(ev.target.value)
    debounceOnChange(ev)
  }

  return (
    <CoreInputField
      Typography={Typography}
      classes={classes}
      {...baseInputProps}
      inputComponent={
        <Input
          inputRef={inputRef}
          label={labelText}
          inputProps={pattern ? { pattern: pattern.expression, title: pattern.title } : {}}
          inlineLabel={inlineLabel}
          sublabel={sublabelText}
          startAdornment={
            (Icon || startAdornment) && (
              <MuiInputAdornment position="start" classes={{ root: classes.adornment }}>
                {Icon && (
                  <Icon
                    className={classnames(classes.icon, { [classes.clickableIcon]: props.onIconClick })}
                    onClick={props.onIconClick}
                  />
                )}
                {startAdornment}
              </MuiInputAdornment>
            )
          }
          endAdornment={
            endAdornment && (
              <MuiInputAdornment position="end" classes={{ root: classes.adornment }}>
                {endAdornment}
              </MuiInputAdornment>
            )
          }
          classes={{
            focused: classes.inputFocused
          }}
          required={required}
          {...getFormatProps(props)}
          {...baseInputProps}
          onChange={handleOnChange}
          value={value}
        />
      }
    />
  )
}

// Helper for formatting input
const getFormatProps = (props) => {
  const { format, name, prefix, suffix } = props
  if (format) {
    return {
      inputComponent: FormattedInput,
      inputProps: { format, name, prefix, suffix }
    } as any
  }
  return {}
}

export default InputField
