import type { QuoteArea } from 'paintscout'
import type { UpdateableAreaSubstrate } from '../../../../index'

// Get dimensions for the UpdateableAreaSubstrate to be displayed to crew
export default function getDimensions({
  rateType,
  areaType,
  item,
  area
}: {
  rateType: string
  areaType: string
  item: UpdateableAreaSubstrate
  area: QuoteArea
}) {
  let label = ''
  let value = 0

  if (rateType === 'quantity') {
    value = Number(item.quantity)
  } else {
    if (areaType === 'surface') {
      const length = item.length?.useCustom ? item.length.custom : item.length?.default
      const height = item.height?.useCustom ? item.height.custom : item.height?.default
      const customSubstrateDimensions = item.length?.useCustom || item.height?.useCustom

      const areaLnft = area.dimensions.override_lnft ? area.dimensions.lnft : area.dimensions.calculated_lnft
      const areaSqft = area.dimensions.override_sqft ? area.dimensions.sqft : area.dimensions.calculated_sqft

      const lnft = customSubstrateDimensions ? length : areaLnft
      const sqft = customSubstrateDimensions ? length * height : areaSqft

      if (rateType === 'lnft') {
        label = 'lnft'
        const dimension = lnft
        value = typeof dimension === 'number' ? dimension : parseFloat(dimension)
      } else {
        label = 'sqft'
        const dimension = sqft
        value = typeof dimension === 'number' ? dimension : parseFloat(dimension)
      }
    } else {
      if (rateType === 'lnft') {
        const dimension = area.dimensions.override_lnft ? area.dimensions.lnft : area.dimensions.calculated_lnft
        value = typeof dimension === 'number' ? dimension : parseFloat(dimension)
        label = 'lnft'
      } else if (['sqft_walls', 'sqftWalls'].includes(rateType)) {
        const dimension = area.dimensions.override_sqft_walls
          ? area.dimensions.sqft_walls
          : area.dimensions.calculated_sqft_walls
        value = typeof dimension === 'number' ? dimension : parseFloat(dimension)

        label = 'sqft'
      } else if (['sqft_ceiling', 'sqftCeiling'].includes(rateType)) {
        label = 'sqft'

        const dimension = area.dimensions.override_sqft_ceiling
          ? area.dimensions.sqft_ceiling
          : area.dimensions.calculated_sqft_ceiling
        value = typeof dimension === 'number' ? dimension : parseFloat(dimension)
      } else if (['sqft_floor', 'sqftFloor'].includes(rateType)) {
        const dimension = area.dimensions.override_sqft_floor
          ? area.dimensions.sqft_floor
          : area.dimensions.calculated_sqft_floor
        value = typeof dimension === 'number' ? dimension : parseFloat(dimension)
        label = 'sqft'
      }
    }
  }
  return { label, value }
}
