import React, { useState } from 'react'
import type { QuoteFile } from 'paintscout'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import { usePresentation } from '../../../context'
import { Carousel, Grid, useDialogs, useMediaQuery, ViewMediaDialog } from '@ui/stickybid'
import { InlineEditableMedia } from '../../../inlineEditable'

import FilesGrid from '../../../FilesGrid'
import { useInView } from '@ui/core'

function GallerySection(props: PresentationSectionProps) {
  const { openDialog, dismissDialog } = useDialogs()
  const {
    isEditable,
    isSettings,
    presentationIndustry,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    WrapperComponent
  } = props
  const { fields, title } = section
  const { files, columns, showBorder } = fields
  const { setIsDirty, focusedSection, setFocusedSection } = usePresentation()
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px'
  })
  const [isSwitching, setIsSwitching] = useState(false)
  const [index, setIndex] = useState(0)
  const isMobile = useMediaQuery('xs')
  const { presentationLibrary } = usePresentation()

  const handleSwitching = () => {
    if (!isSwitching) setIsSwitching(true)
  }

  const handleTransitionEnd = () => {
    setIsSwitching(false)
  }

  const handleClick = (index: number) => {
    return isSettings || isSwitching ? null : handleViewFiles(index)
  }

  if (!isEditable && !isSettings && isMobile) {
    return (
      <WrapperComponent {...props}>
        <div ref={ref}>
          <Carousel handleSwitching={handleSwitching} handleTransitionEnd={handleTransitionEnd} index={index}>
            {files.map((file, index) => (
              <div key={file?.src || index} onClick={() => handleClick(index)}>
                <InlineEditableMedia
                  file={file?.src ? file : null}
                  handleEdit={() => null}
                  isAnimated={inView}
                  showAllPages
                  isEditable={false}
                  isSettings={false}
                  presentationIndustry={presentationIndustry}
                  presentationLibrary={presentationLibrary}
                  filePreviewProps={{ showFirstPageOnly: true }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </WrapperComponent>
    )
  } else
    return (
      <WrapperComponent {...props}>
        <Grid container spacing={3} justifyContent={'center'} alignItems={'flex-start'}>
          {/* {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )} */}
          <Grid item xs={12}>
            <FilesGrid
              mediaOptionsProps={{
                sectionPrefix: sectionPrefix
              }}
              isEditable={isEditable}
              isSettings={isSettings}
              inPresentation={true}
              onDelete={isEditable ? handleDeleteFile : null}
              editable={isEditable}
              onReorder={handleReorder}
              justify="space-around"
              files={files}
              columns={columns}
              showBorder={showBorder}
              presentationIndustry={presentationIndustry}
              presentationLibrary={presentationLibrary}
              showDetails={false}
              onFileClick={isSettings ? null : handleViewFiles}
              handleEditFile={handleEditFile}
              fullItemHeight={true}
              filePreviewProps={{ showFirstPageOnly: true }}
            />
          </Grid>
        </Grid>
      </WrapperComponent>
    )

  function handleDeleteFile(index: number) {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFieldValue(`${sectionPrefix}.fields.files`, newFiles)
    setIsDirty(true)
  }

  function handleEditFile(field: string, content: any) {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  function handleReorder(reorderedFiles: QuoteFile[]) {
    if (focusedSection !== section.key) setFocusedSection(section.key)
    setFieldValue(`${sectionPrefix}.fields.files`, reorderedFiles)
    setIsDirty(true)
  }

  function handleViewFiles(index: number) {
    openDialog(ViewMediaDialog, {
      files: files.filter((file) => !!file?.src),
      index,
      onIndexChange: setIndex,
      enableDownload: !isSettings,
      onClose: dismissDialog
    })
  }
}

export default React.memo(GallerySection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
