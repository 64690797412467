import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import { usePresentationNav } from '../../PresentationNav'

import QuoteComponent from '../../../Quote/Quote'
import QuotePlaceholder from './QuotePlaceholder'
import AnimationWrapper from '../../util/AnimationWrapper'

import ButtonSection from '../Button/ButtonSection'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {},
  acceptDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end'
  },
  acceptPrice: {
    fontSize: '55px',
    fontWeight: 'lighter'
  }
}))

function QuoteSection(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { isQuoteEmpty } = usePresentationNav()
  if (isQuoteEmpty) return null
  const { WrapperComponent, isSettings, section, useCart, objectLabels } = props

  const animation = section.fields?.animation

  if (useCart) {
    return <ButtonSection cartLink={true} {...props} />
  } else
    return (
      <>
        <WrapperComponent {...props}>
          <AnimationWrapper animation={animation} index={0} direction={'top'}>
            <div>
              {isSettings ? <QuotePlaceholder objectLabels={objectLabels} /> : <QuoteComponent inPresentation={true} />}
            </div>
          </AnimationWrapper>
        </WrapperComponent>
      </>
    )
}

export default React.memo(QuoteSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
