import type { ClientMeta, OptionsDocument } from 'paintscout'
/**
 * This is the same logic that Update app metadata and add to token.js uses in auth0
 */
export function getIsTrial(args: { meta: ClientMeta; options: OptionsDocument }) {
  const { meta, options } = args
  const trialExpired = meta.trialExpiry && meta.trialExpiry < Date.now()

  return meta.trialExpiry && (trialExpired || !meta.nonBilling) && options.options.features?.billing?.enabled
}
