import React, { useState } from 'react'
import type { Theme, GridSize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Carousel, Grid, useDialogs, useMediaQuery, ViewMediaDialog } from '@ui/stickybid'
import type { PresentationSectionProps } from '../..'
import compareSectionProps from '../../util/compareSectionProps'
import arrayMove from 'array-move'
import Card from './Card'
import { uuid } from '@paintscout/util/builder'
import AnimationWrapper from '../../util/AnimationWrapper'
import { useInView } from '@ui/core'
import classnames from 'classnames'
import { usePresentation } from '../../../context'

const useStyles = makeStyles<Theme, CardsSectionProps>(
  (theme: Theme) => {
    return {
      root: {},
      contentRoot: {},
      swipeContainer: {
        '& $contentRoot': {
          overflow: 'hidden'
        }
      },
      mobileStepper: {
        background: 'none',
        margin: theme.spacing(1, 0, -2, 0)
      },
      animationWrapper: {},
      testimonialGridItem: {
        display: 'flex',
        '& $animationWrapper, & $root': {
          display: 'flex',
          flexGrow: 1
        },
        '& $contentRoot': {
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-between'
        }
      },
      hideTopOverflow: {
        clipPath: 'inset(0 -2px -50px -2px)'
      }
    }
  },
  { name: 'CardsSection' }
)

interface CardsSectionProps extends PresentationSectionProps {
  type?: 'testimonials'
}

function CardsSection(props: CardsSectionProps) {
  const {
    isEditable,
    isSettings,
    presentationIndustry,
    presetColors,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    type,
    WrapperComponent
  } = props
  const classes = useStyles(props)
  const { presentationLibrary } = usePresentation()

  const { fields = {} } = section
  const isAnimated = ['slide', 'fade', 'zoom'].includes(fields.animation)
  const { animation, cards, columns, useCarousel, imageOnly } = fields
  const [isSwitching, setIsSwitching] = useState(false)
  const { openDialog, dismissDialog } = useDialogs()

  const isCarouselSize = useMediaQuery('xs')

  const handleEditField = (field: string, content: any) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  const handleDeleteCard = (index: number) => {
    const newCards = [...cards]
    newCards.splice(index, 1)
    handleEditField('fields.cards', newCards)
  }

  const handleReorderCard = (oldIndex: number, direction: 'left' | 'right') => {
    let newIndex = direction === 'left' ? oldIndex - 1 : oldIndex + 1
    if (newIndex < 0) newIndex = cards.length - 1
    if (newIndex > cards.length - 1) newIndex = 0
    const newCards = arrayMove(cards, oldIndex, newIndex)
    setFieldValue(`${sectionPrefix}.fields.cards`, newCards)
  }

  const handleCopyCard = (index: number) => {
    const newCards = [...cards]
    const newCard = { ...cards[index] }
    newCard.key = uuid()
    newCards.splice(index + 1, 0, newCard)
    handleEditField('fields.cards', newCards)
  }

  function getColumnSize(cols) {
    switch (cols) {
      case 1:
        return { xs: 12, sm: 12, md: 12 }
      case 2:
        return { xs: 12, sm: 6, md: 6 }
      case 3:
        return { xs: 12, sm: 4, md: 4 }
      case 4:
        return { xs: 6, sm: 6, md: 3 }
      case 6:
        return { xs: 4, sm: 4, md: 2 }
    }
  }

  const twoCol = useMediaQuery('sm')
  const columnSize = getColumnSize(twoCol ? Math.min(2, columns) : columns)
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px'
  })

  const handleSwitching = () => {
    if (!isSwitching) setIsSwitching(true)
  }

  const handleTransitionEnd = () => {
    setIsSwitching(false)
  }

  const handleClick = (index: number) => {
    if (type === 'testimonials') return
    return isSettings || isSwitching ? null : handleViewFiles(index)
  }

  if (isCarouselSize && useCarousel)
    return (
      <WrapperComponent {...props}>
        <AnimationWrapper animation={animation} index={0} direction={'top'}>
          <div ref={ref}>
            <Carousel
              classes={{ swipeContainer: classes.swipeContainer, mobileStepper: classes.mobileStepper }}
              handleSwitching={handleSwitching}
              handleTransitionEnd={handleTransitionEnd}
            >
              {cards.map((card, index) => (
                <Card
                  card={{ ...card, imageOnly: imageOnly }}
                  classes={classes}
                  handleCopyCard={handleCopyCard}
                  handleDeleteCard={handleDeleteCard}
                  handleEditField={handleEditField}
                  handleMediaClick={() => handleClick(index)}
                  handleReorderCard={handleReorderCard}
                  index={index}
                  isAnimated={isAnimated || inView}
                  isEditable={isEditable}
                  isSettings={isSettings}
                  key={card.key}
                  presentationIndustry={presentationIndustry}
                  presentationLibrary={presentationLibrary}
                  presetColors={presetColors}
                  sectionPrefix={sectionPrefix}
                  type={type || 'presentation'}
                />
              ))}
            </Carousel>
          </div>
        </AnimationWrapper>
      </WrapperComponent>
    )
  else
    return (
      <WrapperComponent {...props}>
        <div className={classes.hideTopOverflow}>
          <Grid container spacing={6} justifyContent={columns > cards.length ? 'space-between' : 'center'}>
            {cards.map((card, index) => {
              const { key, color, backgroundColor } = card
              return (
                <Grid
                  item
                  xs={columnSize.xs as GridSize}
                  sm={columnSize.sm as GridSize}
                  md={columnSize.md as GridSize}
                  style={{ backgroundColor }}
                  key={key}
                  className={classnames({
                    [classes.testimonialGridItem]: type === 'testimonials'
                  })}
                >
                  <AnimationWrapper
                    className={classes.animationWrapper}
                    animation={animation}
                    index={cards.length > 4 ? 0 : index}
                    direction={'top'}
                  >
                    <Card
                      card={{ ...card, imageOnly: imageOnly }}
                      classes={classes}
                      handleCopyCard={handleCopyCard}
                      handleDeleteCard={handleDeleteCard}
                      handleEditField={handleEditField}
                      handleMediaClick={() => handleClick(index)}
                      handleReorderCard={handleReorderCard}
                      index={index}
                      isAnimated={isAnimated}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      presentationIndustry={presentationIndustry}
                      presentationLibrary={presentationLibrary}
                      presetColors={presetColors}
                      sectionPrefix={sectionPrefix}
                      type={type || 'presentation'}
                    />
                  </AnimationWrapper>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </WrapperComponent>
    )

  function handleViewFiles(index: number) {
    const files = [cards[index].file]
    openDialog(ViewMediaDialog, {
      files: files.filter((file) => !!file?.src),
      enableDownload: !isSettings,
      onClose: dismissDialog
    })
  }
}

export default React.memo(CardsSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
