import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import QuoteTerms from '../../../Quote/QuoteTerms'
import TermsPlaceholder from './TermsPlaceholder'

const useStyles = makeStyles(
  (theme: Theme) => ({
    quotePaper: {
      // sames styles as $paper in Quote
      background: theme.palette.common.white,
      border: `1px solid #888`,
      borderRadius: theme.borderRadius.md,
      boxShadow: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      padding: theme.spacing(8),
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        width: '100%'
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 2),
        marginBottom: 0
      }
    }
  }),
  { name: 'TermsSection' }
)

function TermsSection(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { WrapperComponent, isSettings, isInvoice } = props

  return (
    <WrapperComponent {...props}>
      {isSettings ? <TermsPlaceholder /> : <QuoteTerms classes={isInvoice ? { root: classes.quotePaper } : {}} />}
    </WrapperComponent>
  )
}

export default React.memo(TermsSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
