export interface UploadOptions {
  cloudName: string
  uploadPreset: string
  tags?: string[]
  folder?: string

  /**
   * If provided, tags and folder will be setup accordingly
   */
  companyId?: string

  /**
   * If provided, a tag of `doc-<id>` will be added to tags
   */
  docId?: string
}

export interface ApiUploadOptions extends Partial<UploadOptions> {
  overwrite?: boolean
  unique_filename?: boolean
  public_id?: string
  invalidate?: boolean
}

export interface CloudinaryUploadResponse {
  public_id?: string
  version?: number
  signature?: string
  width?: number
  height?: number
  format?: string
  resource_type?: string
  created_at?: string
  tags?: string[]
  bytes?: number
  pages?: number
  type?: string
  etag?: string
  placeholder?: boolean
  url?: string
  secure_url?: string
  overwritten?: boolean
  original_filename?: string
  original_extension?: string
}

export async function uploadFile(file: File | string, opts: UploadOptions): Promise<CloudinaryUploadResponse> {
  const formData = new FormData()
  const { cloudName, uploadPreset, companyId, docId, folder = companyId, tags = [] } = opts

  formData.append('file', file)
  formData.append('upload_preset', uploadPreset)
  formData.append('folder', folder)

  formData.append(
    'tags',
    [companyId && `company-${companyId}`, docId && `doc-${docId}`, ...tags].filter(Boolean).join(',')
  )

  const data = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`, {
    method: 'POST',
    body: formData
  }).then((res) => res.json())

  return data
}
