import { createTheme } from './'
import type { PresentationStyle } from 'paintscout'
import Color from 'color'

export function getPresentationTheme(presentationStyle: PresentationStyle) {
  const {
    accentColor: accentColorLegacy,
    backgroundColor: backgroundColorLegacy,
    buttonColor: buttonColorLegacy,
    textColor: textColorLegacy,
    fontFamily = 'roboto, sans-serif',
    heading1 = {},
    heading2 = {},
    heading3 = {},
    body = {},
    palette
  } = presentationStyle

  const checkDefault = (font: string) => {
    if (font === 'default') return fontFamily
    else return font
  }

  const accentColor = palette?.colors?.accent || accentColorLegacy
  const textColor = palette?.colors?.text || textColorLegacy
  const accentTextColor = palette?.colors?.accentText || '#444'
  const accentBackgroundColor = palette?.colors?.background || '#f0f0f0'

  const getTypogColor = (typog: string) => {
    if (palette?.typogMapping?.[typog] === 'accentText') return accentTextColor
    else return textColor
  }

  return createTheme({
    palette: {
      primary: { main: accentColor },
      secondary: { main: accentColor },
      text: { primary: textColor, secondary: accentTextColor },
      background: { default: '#fff' },
      accentBackground: { default: accentBackgroundColor }
    },
    typography: {
      fontFamily: fontFamily,
      h1: {
        color: heading1.color || getTypogColor('h1'),
        ...(heading1.fontFamily && { fontFamily: checkDefault(heading1.fontFamily) }),
        ...(heading1.fontSize && { fontSize: heading1.fontSize }),
        ...(heading1.fontStyle && { fontStyle: heading1.fontStyle }),
        ...(heading1.fontWeight && { fontWeight: heading1.fontWeight }),
        ...(heading1.letterSpacing && { letterSpacing: heading1.letterSpacing }),
        ...(heading1.lineHeight && { lineHeight: heading1.lineHeight }),
        ...(heading1.textTransform && { textTransform: heading1.textTransform })
      },
      h2: {
        color: heading2.color || getTypogColor('h2'),
        ...(heading2.fontFamily && { fontFamily: checkDefault(heading2.fontFamily) }),
        ...(heading2.fontSize && { fontSize: heading2.fontSize }),
        ...(heading2.fontStyle && { fontStyle: heading2.fontStyle }),
        ...(heading2.fontWeight && { fontWeight: heading2.fontWeight }),
        ...(heading2.letterSpacing && { letterSpacing: heading2.letterSpacing }),
        ...(heading2.lineHeight && { lineHeight: heading2.lineHeight }),
        ...(heading2.textTransform && { textTransform: heading2.textTransform })
      },
      h3: {
        color: heading3.color || getTypogColor('h3'),
        ...(heading3.fontFamily && { fontFamily: checkDefault(heading3.fontFamily) }),
        ...(heading3.fontSize && { fontSize: heading3.fontSize }),
        ...(heading3.fontStyle && { fontStyle: heading3.fontStyle }),
        ...(heading3.fontWeight && { fontWeight: heading3.fontWeight }),
        ...(heading3.letterSpacing && { letterSpacing: heading3.letterSpacing }),
        ...(heading3.lineHeight && { lineHeight: heading3.lineHeight }),
        ...(heading3.textTransform && { textTransform: heading3.textTransform })
      },
      h4: { color: textColor },
      h5: { color: textColor },
      h6: { color: accentTextColor },
      subtitle1: { color: textColor },
      subtitle2: { color: accentTextColor },
      body1: {
        color: body.color || getTypogColor('body'),
        ...(body.fontFamily && { fontFamily: checkDefault(body.fontFamily) }),
        ...(body.fontSize && { fontSize: body.fontSize }),
        ...(body.letterSpacing && { letterSpacing: body.letterSpacing }),
        ...(body.lineHeight && { lineHeight: body.lineHeight }),
        ...(body.textTransform && { textTransform: body.textTransform })
      },
      body2: {
        color: body.color ?? textColor,
        ...(body.fontFamily && { fontFamily: body.fontFamily }),
        ...(body.fontSize && { fontSize: body.fontSize }),
        ...(body.letterSpacing && { letterSpacing: body.letterSpacing }),
        ...(body.lineHeight && { lineHeight: body.lineHeight }),
        ...(body.textTransform && { textTransform: body.textTransform })
      },
      button: { color: Color(accentColor).isLight() ? '#000' : '#FFF' },
      caption: { color: textColor },
      overline: { color: textColor }
    }
  })
}
