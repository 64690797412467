/**
 * @module builder
 */
import type { QuoteDocument, Payment, QuoteStatus, OptionsDocument } from 'paintscout'
import { calculateQuote } from '../../util'
import { setStatus } from '../../status'

export function setPayments(args: {
  quote: QuoteDocument
  options: OptionsDocument
  payments: Payment[]
  calculate?: boolean
}): QuoteDocument {
  const { quote, options, payments, calculate } = args

  const updatedQuote = {
    ...quote,
    payments
  }

  const calculatedQuote = calculate === false ? updatedQuote : calculateQuote({ quote: updatedQuote, options })

  let newStatus
  // there's no reason to change the status if it's an estimate
  // the extra logic here is so that if you were to *remove* a payment from an invoice,
  // the result would be the quote having the same status as it started with
  if (calculatedQuote.is_invoice) {
    if (calculatedQuote.totals.balance_due <= 0) {
      newStatus = 'paid'
    } else if (
      calculatedQuote.is_invoice &&
      calculatedQuote.totals.balance_due > 0 &&
      calculatedQuote.totals.amount_paid > 0
    ) {
      newStatus = 'partial'
    } else if (calculatedQuote.dates?.client?.viewed) {
      newStatus = 'viewed'
    } else if (calculatedQuote.dates?.client?.sent) {
      newStatus = 'sent'
    } else {
      newStatus = 'draft'
    }
  }

  const withStatus = newStatus
    ? setStatus({ quote: calculatedQuote, status: newStatus as QuoteStatus, options })
    : calculatedQuote

  return withStatus
}
