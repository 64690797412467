import type { DialogProps } from './Dialog'
import Dialog from './Dialog'
import DialogTitle, { EditableDialogTitle } from './DialogTitle'

export { DialogTitle, EditableDialogTitle }
export type { DialogProps }

export { default as Backdrop } from './Backdrop'
export { default as DialogActions, DialogActionButton } from './DialogActions'
export { default as DialogToggles } from './DialogToggles'
export type { DialogTogglesProps, DialogToggleItem } from './DialogToggles'
export { default as DialogContent } from './DialogContent'
export { default as DialogAfterContent } from './DialogAfterContent'
export { default as SplitDialog } from './SplitDialog'

export default Dialog
