import React, { useEffect, useState } from 'react'
import { makeStyles, CssBaseline, useTheme } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { OptionsDocument, PresentationOption, PresentationPreview, QuoteDocument } from 'paintscout'
import classnames from 'classnames'
import { QuoteContextProvider, useQuote } from '../../context'
import { calculateQuote, getPresentationLogo, getPresentationPreviewQuote } from '@paintscout/util/builder'
import { CustomerViewContents } from '../../presentation'
import {
  ClientOptionsProvider,
  createTheme,
  CVLogo,
  DialogStackProvider,
  SpinningLogo,
  ThemeProvider,
  useClientOptions,
  useUser
} from '@ui/stickybid'
import { create } from 'jss'
import { CircularProgress, jssPreset } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'
import { CLOSED_STATUSES } from '@paintscout/util/builder'
import Frame, { FrameContextConsumer } from 'react-frame-component'
import isEqual from 'lodash/isEqual'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative'
    },
    desktopPreviewRoot: {
      flexGrow: 1
    },
    deviceWrapper: {
      // this is used to create the pseudo element for the notch since frames cannot have PEs
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '&::before': {
        zIndex: 4,
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        top: 10,
        width: 40,
        height: 4,
        backgroundColor: theme.palette.common.white
      }
    },
    deviceFrame: {
      border: '8px solid',
      borderTop: '24px solid',
      borderColor: theme.palette.grey[200],
      borderRadius: theme.borderRadius['lg']
    },
    minimalWrapper: {
      width: '100%',
      textAlign: 'center'
    },
    minimalFrame: {
      border: `1px solid ${theme.palette.grey[400]}`
    },
    noFrame: {
      border: 'none'
    },
    loadingScreen: {
      alignItems: 'center',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      transition: 'top 1000ms cubic-bezier(0.76, 0, 0.24, 1), opacity 1000ms cubic-bezier(0.32, 0, 0.67, 0)',
      top: 0,
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
      opacity: 1
    },
    appLoadingDone: {
      opacity: 0
    },
    cvLoadingDone: {
      top: '-100%'
    }
  }),
  { name: 'PresentationImbedPreview' }
)

export interface PresentationPreviewProps {
  border: 'minimal' | 'device' | 'none'
  loadingStyle: 'app' | 'cv' | 'none'
  classes?: any
  presentation?: PresentationOption
  preview?: PresentationPreview
  quoteId?: string
  logoSrc?: string
  handleAcceptClick?: (ev: React.MouseEvent<HTMLElement, MouseEvent>, selectedOptionalItems?: string[]) => void
  isPreviewAccept?: boolean
  onDismissDialog?: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void
  autoHeight?: boolean
  autoHeightCallback?: React.Dispatch<React.SetStateAction<number>>
  hideFooter?: boolean
  hideNav?: boolean
  preventScroll?: boolean
  usePresentationFromProp?: boolean
  teaserProps?: {
    options: OptionsDocument
    quote: QuoteDocument
    loading?: boolean
    banner?: React.ReactNode
  }
}

const PresentationImbedPreview = (props: PresentationPreviewProps) => {
  const classes = useStyles(props)
  const {
    autoHeight: useAutoHeight,
    autoHeightCallback,
    border,
    handleAcceptClick,
    hideFooter,
    hideNav,
    isPreviewAccept,
    loadingStyle,
    logoSrc,
    onDismissDialog,
    presentation,
    preventScroll,
    preview,
    quoteId,
    usePresentationFromProp,
    teaserProps
  } = props
  // have two quote options so it works w/ and w/o context
  const { quote: contextQuote, estimator } = useQuote()
  const initQuote = teaserProps?.quote ?? contextQuote ?? getPresentationPreviewQuote()
  const [quote, setQuote] = useState(initQuote)
  const [selectedOptionalItems, setSelectedOptionalItems] = useState<string[]>([])
  const { options: contextOptions } = useClientOptions()
  const options = teaserProps?.options ?? contextOptions
  const { isCustom } = getPresentationLogo({ options, quote })
  const { user } = useUser()
  const companyId = user?.app_metadata?.companyId
  const isCustomLogo = isCustom && companyId

  const baseTheme = createTheme()

  const [navOpen, setNavOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState<string>(presentation.pages[0].key)
  const [autoHeight, setAutoHeight] = useState(0)
  const [loading, setLoading] = useState(true)
  const [minDurationReached, setMinDurationReached] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setMinDurationReached(true)
    }, 2600)
  }, [])
  const showLoading = loadingStyle === 'cv' ? !minDurationReached || loading || teaserProps?.loading : loading

  let width: number | string, height: number | string

  switch (preview) {
    case 'mobile':
      width = 391
      height = 622
      break

    case 'tablet':
      width = 799
      height = 1024
      break

    case 'desktop':
      width = '100%'
      height = '100%'
      break

    default:
      width = '100%'
      height = '100%'
      break
  }

  const theme = useTheme()

  function getHeight(height: number) {
    if (!useAutoHeight) return null
    if (autoHeightCallback) autoHeightCallback(height)
    setAutoHeight(height)
  }

  let interval
  let attempts = 0
  const checkFont = (window: Window) => {
    attempts++
    if (!window.onload) {
      window.onload = () => console.log('window loaded')
    }
    const fontsLoaded = window.sessionStorage?.getItem('fontsLoaded')
    if (fontsLoaded || attempts > 99) {
      setLoading(false)
      clearInterval(interval)
    }
  }

  const deviceWidth = width === '100%' ? width : parseInt(width.toString()) + 16
  const deviceHeight = height === '100%' ? height : parseInt(height.toString()) + 32

  function getLoadingIcon() {
    if (loadingStyle === 'app') {
      return <SpinningLogo scale={0.3} />
    } else if (loadingStyle === 'cv') {
      return isCustomLogo ? (
        <CircularProgress size={50} />
      ) : (
        <CVLogo
          customLogoSrc={logoSrc || `https://res.cloudinary.com/stickybid/image/upload/_companyLogos/${companyId}`}
          scale={0.3}
          fallback={<SpinningLogo scale={0.3} />}
          label={'Loading...'}
        />
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    // reset quote when status changes from closed to open, e.g., quote is accepted
    if (!CLOSED_STATUSES.includes(quote.status.value) && CLOSED_STATUSES.includes(initQuote.status.value)) {
      setSelectedOptionalItems([])
      setQuote(initQuote)
    }
  }, [initQuote.status.value])

  const onSelectOptionalItems = (keys: string[]) => {
    const newQuote = calculateQuote({ quote, options, selectedOptionalItems: keys })
    if (!isEqual(newQuote.totals, quote.totals)) {
      setSelectedOptionalItems(keys)
      setQuote(newQuote)
    }
  }

  const handleAcceptWithOptionalItems = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleAcceptClick(ev, selectedOptionalItems)
  }

  return (
    <div className={classnames(classes.root, { [classes.desktopPreviewRoot]: preview === 'desktop' })}>
      <div
        className={classnames({
          [classes.deviceWrapper]: border === 'device',
          [classes.minimalWrapper]: border === 'minimal' || border === 'none'
        })}
      >
        <div
          style={{
            width: deviceWidth,
            height: useAutoHeight ? autoHeight : deviceHeight,
            position: 'relative',
            overflow: 'hidden'
          }}
          className={classnames({
            [classes.deviceFrame]: border === 'device',
            [classes.minimalFrame]: border === 'minimal',
            [classes.noFrame]: border === 'none'
          })}
        >
          {loadingStyle !== 'none' && (
            <div
              className={classnames(classes.loadingScreen, {
                [classes.appLoadingDone]: !showLoading && loadingStyle === 'app',
                [classes.cvLoadingDone]: !showLoading && loadingStyle === 'cv'
              })}
            >
              {getLoadingIcon()}
            </div>
          )}
          <Frame
            id={'component-frame'}
            style={{ border: 'none' }}
            width={width}
            height={useAutoHeight ? autoHeight : height}
          >
            <FrameContextConsumer>
              {({ document, window }) => {
                if (!interval) {
                  interval = setInterval(checkFont, 100, window as any)
                }

                const jss = create({
                  plugins: [...jssPreset().plugins],
                  insertionPoint: document.head
                })

                const styleEl = document.getElementById('appStyles')
                if (!styleEl) {
                  const style = document.createElement('style')
                  style.id = 'appStyles'
                  style.appendChild(document.createTextNode(appStyles()))
                  document.head.appendChild(style)
                }

                if (preventScroll) {
                  const body = document.getElementsByTagName('body')[0]
                  body.style.overflow = 'hidden'
                }

                function handlePageClick(ev: React.MouseEvent, key: string) {
                  setCurrentPage(key)
                  setNavOpen(false)
                  const infiniteScroll = true
                  if (infiniteScroll) {
                    const firstSection = document.getElementById(key)
                    if (firstSection) {
                      const sectionOffset = firstSection?.offsetTop ?? 0
                      const top = sectionOffset + 24
                      window.scrollTo({ top, behavior: 'smooth' })
                    }
                  } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                }

                return (
                  <StylesProvider jss={jss}>
                    <ThemeProvider theme={baseTheme}>
                      <DialogStackProvider>
                        <ClientOptionsProvider options={options}>
                          <CssBaseline />
                          <QuoteContextProvider
                            quote={{ ...quote, presentationTemplate: presentation }}
                            estimator={estimator}
                            handleOptionalItems={{ onSelectOptionalItems, selectedOptionalItems }}
                          >
                            <CustomerViewContents
                              allowResponse={!!handleAcceptClick}
                              banner={teaserProps?.banner}
                              currentPage={currentPage}
                              getHeight={getHeight}
                              handleAcceptClick={handleAcceptClick ? handleAcceptWithOptionalItems : null}
                              handlePageClick={(ev, key) => handlePageClick(ev, key)}
                              handleSectionClick={() => null}
                              hideFooter={hideFooter}
                              hideNav={hideNav}
                              isInvoice={quote?.is_invoice}
                              isPreview={true}
                              isPreviewAccept={isPreviewAccept}
                              loadAllFonts={true}
                              navOpen={navOpen}
                              onDismissDialog={onDismissDialog}
                              presentation={presentation}
                              preview={preview}
                              setNavOpen={setNavOpen}
                              usePresentationFromProp={usePresentationFromProp}
                              view={'quote'}
                            />
                          </QuoteContextProvider>
                        </ClientOptionsProvider>
                      </DialogStackProvider>
                    </ThemeProvider>
                  </StylesProvider>
                )
              }}
            </FrameContextConsumer>
          </Frame>
        </div>
      </div>
    </div>
  )
}

export default PresentationImbedPreview

function appStyles() {
  // duplicate of apps/stickybid/src/index.css
  return `
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/degular-300.woff2') format('woff2'), url('/fonts/degular-300.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: italic;
    font-weight: 300;
    src: url('/fonts/degular-300-italic.woff2') format('woff2'),
      url('/fonts/degular-300-italic.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/degular-400.woff2') format('woff2'), url('/fonts/degular-400.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/degular-400-italic.woff2') format('woff2'),
      url('/fonts/degular-400-italic.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/degular-500.woff2') format('woff2'), url('/fonts/degular-500.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/degular-500-italic.woff2') format('woff2'),
      url('/fonts/degular-500-italic.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/degular-600.woff2') format('woff2'), url('/fonts/degular-600.woff') format('woff');
  }
  
  @font-face {
    font-family: 'degular';
    font-display: auto;
    font-style: italic;
    font-weight: 600;
    src: url('/fonts/degular-600-italic.woff2') format('woff2'),
      url('/fonts/degular-600-italic.woff') format('woff');
  }
  
  html {
    font-size: 16px;
  
    /* font-family: 'Roboto', Arial, Helvetica, sans-serif; */
    font-family: 'degular', sans-serif;
  }
  
  body {
    line-height: 1.42857143;
  }
  
  /* :focus-visible polyfill to remove hide focus when interacted with mouse */
  button:focus:not(.focus-visible) {
    outline: none;
  }
  
  @media print {
    body {
      min-width: 1024px;
      border-bottom: 1px solid transparent;
    }
  }
  
  strong {
    font-weight: 500;
  }
  
  fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
  }
  
 `
}
