import type { OrderItem } from 'paintscout'

// Check item to see if it should be filtered from section
export const filterOrderItems = ({
  orderItem,
  showLineItems,
  keys,
  workOrder
}: {
  orderItem: OrderItem
  showLineItems: boolean
  keys?: string[]
  workOrder?: boolean
}): boolean => {
  // If keys are specified, only return those keys
  if (keys && !keys.includes(orderItem.key)) {
    return true
  }
  // Excluded deleted items
  if (orderItem._deleted) {
    return true
  }

  if (workOrder) {
    // No groups on Work Orders
    if (orderItem.type === 'group') {
      return true
    }
  } else {
    // Exclude group items
    if (!keys && orderItem.parent) {
      return true
    }
  }
  // Exclude line items if specified
  if (!showLineItems && orderItem.type === 'line_item') {
    return true
  }

  return false
}
