import Banner from './Banner'
import Button from './Button'
import Cards from './Cards'
import Divider from './Divider'
import Gallery from './Gallery'
import Image from './Image'
import Logo from './Logo'
import Quote from './Quote'
import SocialLinks from './SocialLinks'
import Spacer from './Spacer'
import Terms from './Terms'
import Testimonials from './Testimonials'
import Text from './Text'
import ThreeColumn from './ThreeColumn'
import TwoColumn from './TwoColumn'

export default {
  '2-column': TwoColumn,
  '3-column': ThreeColumn,
  banner: Banner,
  logo: Logo,
  button: Button,
  cards: Cards,
  divider: Divider,
  image: Image,
  gallery: Gallery,
  quote: Quote,
  'social-links': SocialLinks,
  terms: Terms,
  testimonials: Testimonials,
  text: Text,
  spacer: Spacer
}

import BannerSection from './Banner/BannerSection'
import ButtonSection from './Button/ButtonSection'
import CardsSection from './Cards/CardsSection'
import DividerSection from './Divider/DividerSection'
import GallerySection from './Gallery/GallerySection'
import ImageSection from './Image/ImageSection'
import LogoSection from './Logo/LogoSection'
import QuoteSection from './Quote/QuoteSection'
import SocialLinksSection from './SocialLinks/SocialLinksSection'
import SpacerSection from './Spacer/SpacerSection'
import TermsSection from './Terms/TermsSection'
import TestimonialsSection from './Testimonials/TestimonialsSection'
import TextSection from './Text/TextSection'
import ThreeColumnSection from './ThreeColumn/ThreeColumnSection'
import TwoColumnSection from './TwoColumn/TwoColumnSection'

export {
  BannerSection,
  ButtonSection,
  CardsSection,
  DividerSection,
  GallerySection,
  ImageSection,
  LogoSection,
  QuoteSection,
  SocialLinksSection,
  SpacerSection,
  TermsSection,
  TestimonialsSection,
  TextSection,
  ThreeColumnSection,
  TwoColumnSection
}
