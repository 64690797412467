import type { QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import get from 'lodash/get'
import clone from 'lodash/clone'
import { add } from '../util/add'
export function lineItems(quote: QuoteDocument, selectedOptionalItems?: string[]) {
  const totals = { groups: {}, ...quote.totals }

  if (!quote.order) {
    return totals
  }

  if (!quote.totals) {
    quote.totals = {
      show_total: true,
      groups: {},
      hours: 0,
      price: 0,
      prep: 0,
      additionalWork: { prep: 0, hours: 0, price: 0, materials: 0 },
      pending: { prep: 0, hours: 0, price: 0, materials: 0 }
    }
  }

  if (!quote.lineItems) {
    quote.lineItems = {}
  }

  const lineItemTotals = quote.order.area.reduce(
    (total, item) => {
      if (item._deleted) {
        return total
      }
      if (item.type !== 'line_item') {
        return total
      }

      if (!quote.lineItems[item.key]) {
        return total
      }

      const lineItem = quote.lineItems[item.key]

      if (lineItem._deleted) {
        return total
      }

      const hours = lineItem.show_crew ? parseNumber(lineItem.hours) : 0
      const price = parseNumber(lineItem.price)
      const materials = get(lineItem, 'totals.materials') || 0

      let updatedTotal = clone(total)

      if (lineItem.use_total || (selectedOptionalItems ?? []).includes(lineItem.key)) {
        updatedTotal = add(
          updatedTotal,
          {
            hours,
            price: item.parent ? 0 : price,
            materials: item.parent ? 0 : materials
          },
          quote
        )

        const group = lineItem.quoteType ? lineItem.quoteType : 'all'
        if (!totals.groups[group]) {
          totals.groups[group] = {
            hours: 0,
            prep: 0,
            price: 0,
            materials: 0
          }
        }
        totals.groups[group] = add(
          totals.groups[group],
          {
            hours,
            prep: 0,
            price: item.parent ? 0 : price,
            materials: item.parent ? 0 : materials
          },
          quote
        )
      } else if (lineItem.pending) {
        updatedTotal.pending = add(
          updatedTotal.pending,
          {
            hours,
            prep: 0,
            price: item.parent ? 0 : price,
            materials: item.parent ? 0 : materials
          },
          quote
        )
      } else if (lineItem.additionalWork) {
        updatedTotal.additionalWork = add(
          updatedTotal.additionalWork,
          {
            hours,
            prep: 0,
            price: item.parent ? 0 : price,
            materials: item.parent ? 0 : materials
          },
          quote
        )
      }

      return updatedTotal
    },
    {
      price: 0,
      hours: 0,
      materials: 0,
      pending: { hours: 0, prep: 0, price: 0, materials: 0 },
      additionalWork: { hours: 0, prep: 0, price: 0, materials: 0 }
    }
  )

  totals.line_items = {
    price: lineItemTotals.price,
    hours: lineItemTotals.hours,
    materials: lineItemTotals.materials,
    prep: 0
  }
  totals.additionalWork = lineItemTotals.additionalWork
  totals.pending = lineItemTotals.pending

  return totals
}
