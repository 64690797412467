import type { QuoteFile, PresentationLibrary } from 'paintscout'
import union from 'lodash/union'

export const addFilesToLibrary = (args: { files: QuoteFile[]; library: PresentationLibrary }) => {
  const { files, library } = args
  const keys = files.map((file) => file.cloudinaryPublicId).filter(Boolean) // We use cloudinaryPublicId as the key since we don't want the same picture in the library twice. The actual key is unique to each instance of the picture

  if (files.length !== keys.length) {
    console.log('Missing Key: Library is for cloudinary items only')
    return null
  }

  let updatedValues = { ...library?.values }
  keys.forEach((key) => {
    updatedValues[key] = { file: files.find((file) => file.cloudinaryPublicId === key) }
  })
  const updatedLibrary: PresentationLibrary = {
    values: updatedValues,
    order: union(keys, library?.order)
  }
  return updatedLibrary
}
