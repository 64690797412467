/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { QuoteDetails } from '../../index'
import { generateProductDescription } from '../../util'
import { fixNumber } from '../../../../calculator'

export function getDetails(args: { quote: QuoteDocument; options?: OptionsDocument }): QuoteDetails {
  const { quote, options } = args

  const afterAdjustment =
    typeof quote.totals.after_adjustment === 'undefined' ? quote.totals.price : quote.totals.after_adjustment

  const hours = {
    useCustom: !!quote.totals.override_hours,
    default:
      quote.totals.hours +
      quote.totals.prep +
      (quote.totals.additionalWork?.prep ?? 0) +
      (quote.totals.additionalWork?.hours ?? 0),
    custom:
      typeof quote.totals.override_hours_value === 'string'
        ? parseFloat(quote.totals.override_hours_value)
        : quote.totals.override_hours_value
  }

  const hoursValue = hours.useCustom ? hours.custom : hours.default
  const itemCount = Object.keys(quote.lineItems).length

  const itemRate = fixNumber(afterAdjustment / itemCount)
  const calculateSalesRateAfterDiscount = options
    ? options.options?.quotes?.afterDiscountSalesRate
      ? true
      : false
    : quote?.options?.afterDiscountSalesRate ?? false

  const salesRate = calculateSalesRateAfterDiscount
    ? quote.totals.after_discount / hoursValue
    : afterAdjustment / hoursValue

  const showTotal = typeof quote.totals.show_total === 'undefined' ? true : quote.totals.show_total

  const afterTax = quote.totals.after_tax
  const amountPaid = quote.totals?.amount_paid ?? 0

  const balanceDue = quote.totals?.balance_due ?? afterTax - amountPaid
  const showBalanceDue = quote?.totals?.showBalanceDue ?? true

  const source = {
    ...(quote?.source ?? {}),
    id: quote?.source?.id ?? '',
    provider: quote?.source?.provider ?? ''
  }

  return {
    hourlyRate: quote.hourly_rate,
    clientVisible: !!(quote.clientVisible ?? true),
    crewVisible: !!(quote.crewVisible ?? true),
    notes: {
      client: quote.client_note ? quote.client_note : '',
      internal: quote.internal_note ? quote.internal_note : '',
      products: quote.productString
        ? quote.productString
        : { default: generateProductDescription({ quote }), useCustom: false, custom: '' }
    },
    tax: {
      rate: quote.tax_rate ? quote.tax_rate : 0,
      total: quote.totals.total_tax ? quote.totals.total_tax : 0,
      type: 'percentage',
      description: quote.tax_description,
      details: quote?.tax_details ? quote.tax_details : []
    },
    totalAdjustment: {
      rate: quote.totalAdjustment?.rate ? quote.totalAdjustment.rate : 0,
      type: quote.totalAdjustment?.type ?? 'value',
      description: quote.totalAdjustment?.description ?? '',

      isShown: false,
      total: quote.totals.total_adjustment
    },
    discount: {
      rate: quote.discount ? quote.discount : 0,
      type: quote.discount_type && quote.discount_type.value ? quote.discount_type.value : 'percentage',
      description: quote.discount_description,
      // isShown: typeof quote.discount_isShown !== 'undefined' ? quote.discount_isShown : true,
      isShown: true,
      total: quote.totals.total_discount
    },
    revision: quote.revision,
    source,
    totals: {
      show: showTotal,
      showBalanceDue,
      price: quote.totals.price,
      afterAdjustment,
      afterDiscount: quote.totals.after_discount,
      afterTax,
      hours,

      labour: quote.totals.materials ? quote.totals.price - quote.totals.materials : quote.totals.price,
      materials: quote.totals.materials ? quote.totals.materials : 0,
      additionalWork:
        quote.totals.additionalWork && quote.totals.additionalWork.price ? quote.totals.additionalWork.price : 0,
      additionalMaterials:
        quote.totals?.additionalWork && quote.totals.additionalWork?.materials
          ? quote.totals.additionalWork?.materials
          : 0,
      pending: quote.totals.pending && quote.totals.pending.price ? quote.totals.pending.price : 0,
      salesRate,
      itemRate,
      amountPaid,
      balanceDue
    }
  }
}

/*
  quote.totals = {
    after_discount
    after_tax,
    amount_paid,
    balance_due,
    price,
    total_discount,
    total_tax
  }

 */
