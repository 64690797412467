import type { QuoteDocument, AreaSubstrate, OptionsDocument } from 'paintscout'
import type { UpdateableAreaSubstrate } from '../../../index'
import { isInSubstrateGroup } from '../../is-in-substrate-group'
import { isInAreaGroup } from '../../is-in-area-group'
import { getAreaSubstrateKey } from '../../get-area-substrate-key'
import { getItemSection } from '../../../util/get-item-section'
import { getArea } from '../../../areas/get-area'
import {
  convertDimensions,
  convertHours,
  convertMaterials,
  convertPrep,
  convertPrice,
  convertProduct,
  convertRate,
  convertRateValue
} from '../util'

interface toUpdateableAreaSubstrateProps {
  areaSubstrate: AreaSubstrate
  areaKey: string
  options: OptionsDocument
  substrateKey: string
  quote: QuoteDocument
}

/**
 * Convert an AreaSubstrate to an UpdateableAreaSubstrate to be edited.
 */
export default function toUpdateableAreaSubstrate({
  areaSubstrate,
  areaKey,
  options,
  substrateKey,
  quote
}: toUpdateableAreaSubstrateProps): UpdateableAreaSubstrate {
  const area = getArea({ quote, options, key: areaKey })
  const quoteSection = getItemSection(areaSubstrate)

  const price = convertPrice(areaSubstrate)
  const product = convertProduct(areaSubstrate)
  const materials = convertMaterials(product)
  const prep = convertPrep(areaSubstrate)
  const { length, width, height } = convertDimensions({ areaSubstrate, area })

  const rateKey = getAreaSubstrateKey(quote, areaSubstrate)

  const inSubstrateGroup = isInSubstrateGroup({ quote, rateKey })
  const inAreaGroup = isInAreaGroup({ quote, areaKey })
  const hours = convertHours(areaSubstrate)
  const rate = convertRate(areaSubstrate)
  const rateValue = convertRateValue(areaSubstrate)

  return {
    key: substrateKey,
    type: 'areaSubstrate' as const,
    name: areaSubstrate.rate.label,
    section: areaSubstrate.rate.section,
    clientLabel: areaSubstrate.rate.client_label,

    workOrderOnly: areaSubstrate.workOrderOnly,

    showPrice: areaSubstrate.showPrice,
    showProduct: areaSubstrate.showProduct,
    showCoats: areaSubstrate.showCoats,
    showPrep: areaSubstrate.showPrep,
    hideQuantity: areaSubstrate.hideQuantity,
    hideQuantityOnWorkOrder: areaSubstrate.hideQuantityOnWorkOrder,
    excludeDiscount: areaSubstrate.excludeDiscount,
    excludeTax: areaSubstrate.excludeTax,

    inSubstrateGroup,
    inAreaGroup,

    hourlyRate: areaSubstrate.hourlyRate,
    overrideHourlyRate: areaSubstrate.overrideHourlyRate,
    useInches: areaSubstrate.useInches,

    rate,

    product,

    prep,

    notes: areaSubstrate.notes,

    hours,

    price,

    materials,

    length,
    height,
    width,

    rateValue,
    use_total: areaSubstrate.use_total,
    quantity: Number(areaSubstrate.quantity),
    coats: Number(areaSubstrate.coats),
    quoteSection
  }
}
