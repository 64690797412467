import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { QuoteFile } from 'paintscout'
import { getObjectLabels } from '@paintscout/util/builder'
import {
  FilePreview,
  Image,
  PdfViewer,
  Typography,
  useClientOptions,
  useDialogs,
  ViewMediaDialog,
  ViewPdfDialog
} from '@ui/stickybid'

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {
      position: 'relative'
    },
    overlay: {
      cursor: 'pointer',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      transition: 'background .3s ease',
      '&:hover': {
        background: '#ffffffcc',
        '& > $overlayText': {
          background: theme.palette.grey[300]
        }
      }
    },
    overlayText: {
      transition: 'background .3s ease',
      background: theme.palette.grey[200],
      padding: theme.spacing(2, 3),
      letterSpacing: '0.15em',
      lineHeight: 1,
      borderRadius: theme.borderRadius.sm
    },
    filePreview: {
      padding: `2px !important`,
      margin: `0 !important`,
      border: 'none !important',
      boxShadow: 'none !important'
    },
    swipeContainer: {
      width: '100%'
    },
    image: {
      width: '100%'
    },
    carouselImage: {
      userDrag: 'none',
      userSelect: 'none',
      width: '100%',
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing'
      }
    },
    mobileStepper: {
      padding: theme.spacing(0, '44px', 1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, '5px', 1)
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0, 1)
      }
    },
    pdfDocPage: {
      '&:first-child': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      },
      [theme.breakpoints.down('xs')]: {
        '&:first-child': {
          marginTop: 0
        },
        '&:last-child': {
          marginBottom: 0
        }
      }
    }
  }),
  { name: 'ImportedQuoteView' }
)

export interface ImportedQuoteViewProps {
  file: QuoteFile
  preview?: boolean
  forceImageRender?: boolean
}

/** For viewing imported (pdf) quotes */
function ImportedQuoteView({ file, preview, forceImageRender, ...props }: ImportedQuoteViewProps) {
  const classes = useStyles(props)
  const selectedPages = file.selectedPages || []
  const singlePage = selectedPages.length <= 1
  const { openDialog, dismissDialog } = useDialogs()
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  function handleViewFile(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation()
    if (file.format === 'pdf') {
      openDialog(ViewPdfDialog, {
        file,
        title: `Full ${objectLabels.quote.value}`,
        onCancel: dismissDialog
      })
    } else {
      openDialog(ViewMediaDialog, {
        files: [file],
        title: `Full ${objectLabels.quote.value}`,
        startFullscreen: true,
        onClose: dismissDialog
      })
    }
  }

  const displayFull = !preview
  const isPdf = file.format === 'pdf'

  if (displayFull && (!isPdf || forceImageRender))
    return <FilePreview classes={{ paper: classes.filePreview }} file={file} />
  else if (displayFull && isPdf && !forceImageRender)
    return <PdfViewer file={file} classes={{ pdfDocPage: classes.pdfDocPage }} />
  else
    return (
      <div onClick={handleViewFile} className={classes.root}>
        <div className={classes.overlay}>
          <Typography variant={'overline'} className={classes.overlayText}>
            {`See Full ${objectLabels.quote.value}`}
          </Typography>
        </div>
        <Image
          classes={{ root: classes.image }}
          page={1}
          publicId={`${file.cloudinaryPublicId}.png`}
          resourceType="image"
        />
      </div>
    )
  // return (
  //   <div onClick={(ev) => ev.stopPropagation()} className={classes.root}>
  //     <Carousel
  //       index={0}
  //       classes={{
  //         swipeContainer: classes.swipeContainer,
  //         mobileStepper: classes.mobileStepper
  //       }}
  //     >
  //       {pages.map((page) => (
  //         <Image
  //           classes={{ root: classes.carouselImage }}
  //           page={page + 1}
  //           publicId={`${file.cloudinaryPublicId}.png`}
  //           resourceType="image"
  //         />
  //       ))}
  //     </Carousel>
  //   </div>
  // )
}

export default ImportedQuoteView
