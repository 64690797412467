import React, { useEffect, useMemo, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { uuid } from '@paintscout/util/builder'
import { getScrollParent } from '../util'

export interface CollapseProps {
  /**
   * Animates in/out
   */
  show?: boolean

  /**
   * The key passed in for the inner motion.div
   */
  animKey?: string

  /**
   * Whether or not the collapse animate plays for the initial render
   */
  initial?: boolean

  /**
   * Scrolls to the element when toggled on
   */
  scroll?: boolean
}

const Collapse: React.FC<CollapseProps> = ({ show, children, initial = false, scroll, ...props }) => {
  const ref = useRef<HTMLElement>(null)
  const isFirstRender = useRef(true)
  const animKey = useMemo(() => props.animKey ?? uuid(), [])
  const canScroll = useRef(false)

  useEffect(() => {
    if (!isFirstRender.current) {
      canScroll.current = true
    }

    isFirstRender.current = false
  }, [show])

  return (
    <AnimatePresence initial={initial}>
      {show && (
        <motion.div
          ref={ref as any}
          key={animKey}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: 'auto',
              transitionEnd: {
                overflow: null
              }
            },
            collapsed: { opacity: 0, height: 0, overflow: 'hidden' }
          }}
          onAnimationComplete={() => {
            const parentScrollContainer = getScrollParent(ref.current)

            if (show && scroll && canScroll.current) {
              parentScrollContainer?.scrollTo({ top: ref.current?.offsetTop, behavior: 'smooth' })
            }
          }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Collapse
