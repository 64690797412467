import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import type { LinearProgressProps as MuiLinearProgressProps } from '@material-ui/core/LinearProgress'
import MuiLinearProgress from '@material-ui/core/LinearProgress'

const styles = (theme: Theme) => {
  return createStyles({
    root: {}
  })
}

export interface LinearProgressProps extends WithStyles<typeof styles>, Omit<MuiLinearProgressProps, 'classes'> {}

class LinearProgress extends React.PureComponent<LinearProgressProps> {
  public static defaultProps: Partial<LinearProgressProps> = {
    color: 'primary'
  }

  public render() {
    const { ...baseClasses } = this.props.classes
    return <MuiLinearProgress {...this.props} classes={baseClasses} />
  }
}

export default withStyles(styles)(LinearProgress)
