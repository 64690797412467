import type { AreaSubstrate } from 'paintscout'
import type { UpdateableProduct } from '../..'
import get from 'lodash/get'

// Converts an areaSubstrates QuoteProduct to an UpdateableProduct to go with UpdateableAreaSubstrate
// null if no product found on areaSubstrate
export default function convertProduct(areaSubstrate: AreaSubstrate): UpdateableProduct {
  return areaSubstrate.product && (areaSubstrate.product.custom || get(areaSubstrate, 'product.product.label'))
    ? {
        key: get(areaSubstrate, 'product.product.name'),
        label: get(areaSubstrate, 'product.product.label'),
        calculate: get(areaSubstrate, 'product.calculate'),
        color: get(areaSubstrate, 'product.product.color'),
        unitLabel: get(areaSubstrate, 'product.product.unitLabel'),
        coverage: {
          default: get(areaSubstrate, 'product.coverage_rate_value'),
          useCustom: get(areaSubstrate, 'product.coverage_rate_override'),
          custom: get(areaSubstrate, 'product.coverage_rate_override_value')
        },
        lnftCoverage: {
          default: get(areaSubstrate, 'product.lnft_coverage_rate_value'),
          useCustom: get(areaSubstrate, 'product.lnft_coverage_rate_override'),
          custom: get(areaSubstrate, 'product.lnft_coverage_rate_override_value')
        },
        quantityCoverage: {
          default: get(areaSubstrate, 'product.quantity_coverage_rate_value'),
          useCustom: get(areaSubstrate, 'product.quantity_coverage_rate_override'),
          custom: get(areaSubstrate, 'product.quantity_coverage_rate_override_value')
        },
        volume: {
          default: get(areaSubstrate, 'product.volume'),
          useCustom: get(areaSubstrate, 'product.volume_override'),
          custom: get(areaSubstrate, 'product.volume_override_value')
        },
        price: {
          default: get(areaSubstrate, 'product.price'),
          useCustom: get(areaSubstrate, 'product.price_override'),
          custom: get(areaSubstrate, 'product.price_override_value')
        },
        totalPrice: {
          default: get(areaSubstrate, 'product.totalPrice'),
          useCustom: get(areaSubstrate, 'product.totalPrice_override'),
          custom: get(areaSubstrate, 'product.totalPrice_override_value')
        },
        quoteTypes: get(areaSubstrate, 'product.quoteTypes'),
        custom: get(areaSubstrate, 'product.custom', false)
      }
    : null
}
