import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { InputField, Tooltip } from '../'
import { InlineColorPicker as CoreInlineColorPicker } from '@ui/core/ColorPicker'
import type { InlineColorPickerProps as CoreInlineColorPickerProps } from '@ui/core/ColorPicker'

export interface InlineColorPickerProps
  extends Omit<CoreInlineColorPickerProps, 'InputField' | 'Tooltip' | 'Typography'> {}

const useStyles = makeStyles<Theme, InlineColorPickerProps>(
  (theme) => ({
    root: {},
    input: {
      // overflow: 'hidden',
      color: `${theme.palette.text.primary} !important`,
      border: 'none',
      '& input': {
        ...theme.typography.h5,
        padding: theme.spacing(0.75, 0)
      }
    },
    inputAdornment: {
      margin: 0
    },
    popover: {
      position: 'absolute',
      zIndex: 2
    },
    swatch: {
      height: 30,
      width: 30,
      borderRadius: theme.borderRadius['sm'],
      cursor: 'pointer'
      // marginRight: `-${theme.typography.pxToRem(7)}` // opposite of InputField adornment marginRight
    },
    // same as ColorPicker
    popoverPaper: {
      borderRadius: theme.borderRadius['md'],
      boxShadow: theme.boxShadow['1'],
      fontFamily: theme.typography.fontFamily,
      marginTop: theme.spacing(1),
      '& .sketch-picker': {
        background: `${theme.palette.grey[100]} !important`
      }
    }
  }),
  { name: 'InlineColorPicker' }
)

function InlineColorPicker(props: InlineColorPickerProps) {
  const classes = useStyles(props)

  return (
    <CoreInlineColorPicker InputField={InputField} Tooltip={Tooltip} labelAsValue={true} {...props} classes={classes} />
  )
}

export default InlineColorPicker
