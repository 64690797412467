import type { InputBaseComponentProps } from '@material-ui/core/InputBase'
import React from 'react'
import type { MaskedInputProps } from 'react-text-mask'
import MaskedInput from 'react-text-mask'

export type PhoneNumberInputProps = InputBaseComponentProps & MaskedInputProps & { separator?: '-' | '.' }

// use as `inputComponent` prop in InputField component
// ex: <InputField inputComponent={PhoneNumberInput} />
const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const { inputRef, ...other } = props
  const separator = props.separator ?? '.'

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        // inputRef(ref ? ref.inputElement : null)
      }}
      showMask={false}
      mask={[/[1-9]/, /\d/, /\d/, separator, /\d/, /\d/, /\d/, separator, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

export default PhoneNumberInput
