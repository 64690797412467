import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const Filter = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 17 12'} {...props}>
    <svg>
      <path d="M0 3.37199C0 3.09585 0.223858 2.87199 0.5 2.87199H15.878C16.1541 2.87199 16.378 3.09585 16.378 3.37199C16.378 3.64814 16.1541 3.87199 15.878 3.87199H0.5C0.223858 3.87199 0 3.64814 0 3.37199Z" />
      <path
        d="M10.186 5.228C11.2314 5.228 12.079 4.38048 12.079 3.335C12.079 2.28953 11.2314 1.442 10.186 1.442C9.14049 1.442 8.29297 2.28953 8.29297 3.335C8.29297 4.38048 9.14049 5.228 10.186 5.228Z"
        fill="white"
      />
      <path d="M10.186 1.942C9.41664 1.942 8.79297 2.56567 8.79297 3.335C8.79297 4.10433 9.41664 4.728 10.186 4.728C10.9553 4.728 11.579 4.10433 11.579 3.335C11.579 2.56567 10.9553 1.942 10.186 1.942ZM7.79297 3.335C7.79297 2.01338 8.86435 0.942001 10.186 0.942001C11.5076 0.942001 12.579 2.01338 12.579 3.335C12.579 4.65662 11.5076 5.728 10.186 5.728C8.86435 5.728 7.79297 4.65662 7.79297 3.335Z" />
      <path d="M0 9.37199C0 9.09585 0.223858 8.87199 0.5 8.87199H15.878C16.1541 8.87199 16.378 9.09585 16.378 9.37199C16.378 9.64814 16.1541 9.87199 15.878 9.87199H0.5C0.223858 9.87199 0 9.64814 0 9.37199Z" />
      <path
        d="M6.18597 11.228C7.23144 11.228 8.07897 10.3805 8.07897 9.335C8.07897 8.28953 7.23144 7.442 6.18597 7.442C5.14049 7.442 4.29297 8.28953 4.29297 9.335C4.29297 10.3805 5.14049 11.228 6.18597 11.228Z"
        fill="white"
      />
      <path d="M6.18597 7.942C5.41664 7.942 4.79297 8.56567 4.79297 9.335C4.79297 10.1043 5.41664 10.728 6.18597 10.728C6.9553 10.728 7.57897 10.1043 7.57897 9.335C7.57897 8.56567 6.9553 7.942 6.18597 7.942ZM3.79297 9.335C3.79297 8.01338 4.86435 6.942 6.18597 6.942C7.50759 6.942 8.57897 8.01338 8.57897 9.335C8.57897 10.6566 7.50759 11.728 6.18597 11.728C4.86435 11.728 3.79297 10.6566 3.79297 9.335Z" />
    </svg>
  </SvgIcon>
)
export default Filter
