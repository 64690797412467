import React from 'react'
import NumberFormat from 'react-number-format'
import type { InputFieldProps } from '../InputField'

export type InputFieldFormat = 'price' | 'quantity' | 'hours' | 'dimension' | 'rate' | 'taxRate'

// Helper for formatting input
const FormattedInput = (props: InputFieldProps & { format: InputFieldFormat }) => {
  const { inputRef, onChange, format, name, prefix, suffix, value: rawValue, ...other } = props

  const thousandSeparator = true
  const fixedDecimalScale = false // format === 'price'
  const decimalScale = format === 'taxRate' ? 3 : 2

  const value = ['dimension', 'hours'].includes(format) && rawValue === 0 ? '' : rawValue

  return (
    <NumberFormat
      {...(other as any)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...event,
          target: {
            ...event.target,
            name,
            value: event.target.value.replace(/\$|,|/g, '')
          }
        })
      }}
      name={name}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      suffix={suffix}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      value={value as any}
    />
  )
}

export default FormattedInput
