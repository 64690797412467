import type { OptionsDocument, QuoteDocument } from 'paintscout'
import clone from 'lodash/clone'
import { add } from '../util/add'
import { parseNumber } from '../util/parse-number'
import { getItem } from '../../builder/quote/items/get-items'

export function groups(quote: QuoteDocument, options: OptionsDocument) {
  if (!quote.totals) {
    quote.totals = {
      show_total: true,
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0,
      additionalWork: { hours: 0, prep: 0, price: 0, materials: 0 }
    }
  }

  if (!quote.groups) {
    quote.groups = {}
  }

  if (!quote.order) {
    return {
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0,
      additionalWork: { hours: 0, prep: 0, price: 0, materials: 0 }
    }
  }

  const groupTotals = quote.order.area.reduce(
    (total, item) => {
      if (item._deleted) {
        return total
      }
      if (item.type !== 'group') {
        return total
      }

      const group = quote.groups[item.key]

      if (!group) {
        return total
      }

      if (group._deleted) {
        return total
      }

      if (group.section !== 'bid' && group.section !== 'additional') {
        return total
      }

      const price = group.price.useCustom ? group.price.custom : group.price.default
      const materials = group.children?.reduce((sum, childKey) => {
        const item = getItem({ quote, options, key: childKey })

        if (!(item as any)?.totals?.materials) {
          return sum
        }

        if ('totals' in item && 'materials' in item.totals) {
          const materialCost =
            typeof item.totals.materials === 'number'
              ? item.totals.materials
              : item.totals.materials.useCustom
              ? item.totals.materials.custom
              : item.totals.materials.default

          return sum + parseNumber(materialCost)
        }
      }, 0)

      const priceValue = parseNumber(price) - parseNumber(materials)

      let updatedTotal = clone(total)
      if (group.section === 'additional') {
        updatedTotal.additionalWork = add(
          updatedTotal.additionalWork,
          {
            hours: 0,
            prep: 0,
            price
          },
          quote
        )
      } else {
        updatedTotal = add(updatedTotal, { price: priceValue }, quote)
        updatedTotal = add(updatedTotal, { materials }, quote)
      }
      return updatedTotal
    },
    { hours: 0, prep: 0, price: 0, materials: 0, additionalWork: { hours: 0, prep: 0, price: 0, materials: 0 } }
  )

  return groupTotals
}
