import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import type { DialogProps } from '../Dialog'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog'
import Button from '../Button'
import Grid from '../Grid'
import InputField from '../InputField'
import { CloseButton } from '../dialogs'

import { useSnackbar } from 'notistack'

export interface ImportJsonDialogProps extends DialogProps {
  onClose?: () => void
  queueDocUpdates?: (json: any) => void
  onImport?: (json: JSON) => void
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    input: {
      width: '100%',
      height: '100%'
    },
    content: {
      maxHeight: '100%'
    }
  }),
  { name: 'ImportJsonDialog' }
)

function ImportJsonDialog(props: ImportJsonDialogProps) {
  const classes = useStyles(props)
  const { onClose, queueDocUpdates, onImport, ...otherProps } = props
  const { enqueueSnackbar } = useSnackbar()

  const [item, setItem] = useState<string>('')

  async function handleImport() {
    let json = {} as any
    try {
      json = JSON.parse(item)
    } catch (err) {
      enqueueSnackbar(`Unable to parse JSON!`, { variant: 'error' })
      return
    }

    if (queueDocUpdates) {
      queueDocUpdates(json)
      enqueueSnackbar('Documents ready to save!', { variant: 'success' })
      onClose()
      return
    }

    onImport(json)
  }

  return (
    <Dialog {...otherProps} fullScreen={true}>
      <DialogTitle rightContent={<CloseButton onCancel={onClose} />}>{'Import JSON'}</DialogTitle>
      <DialogContent className={classes.content}>
        <InputField
          classes={{ input: classes.input }}
          multiline
          value={item}
          onChange={(e) => {
            setItem(e.target.value)
          }}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <Button onClick={handleImport} prominence={1}>
              Import
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ImportJsonDialog
