/**
 * Get area totals
 * @param  {GenerateAreaDescription}    args
 * @return {string}
 */

import type { QuoteDocument, OrderItem, OptionsDocument, QuoteArea } from 'paintscout'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import find from 'lodash/find'

import type { UpdateableAreaSubstrate } from '../../area-substrates'

import { getAreaSubstrate, isInSection } from '../../area-substrates'
import { getQuoteOptions } from '../get-options'
import { makeHtml } from '../../../../util'
import { getItemSection } from '../get-item-section'
import type { QuoteItemSection } from '../..'
import mergeLabels from '../merge-labels'

export function generateAreaDescription(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  plainText?: boolean
  filterBySection?: QuoteItemSection
  section?: QuoteItemSection
  inGroup?: boolean
}): string {
  const { quote, areaKey, plainText, filterBySection, options, section: argsSection, inGroup } = args
  const { hideSubstrateListDescription } = options?.options ?? {}

  const quoteOptions = getQuoteOptions({ quote })

  const area = quote.areas[areaKey]
  const quantities: any = {}
  const prices: any = {}
  const coats: any = {}
  const products: any = {}
  const prep: any = {}
  const areaReportText: string[] = []

  if (
    !area ||
    !area.substrateOrder ||
    ((quoteOptions.stackSubstrates || quoteOptions.showDetailedBreakdown) && !inGroup)
  ) {
    return '<p></p>'
  }

  const areaSection = getItemSection(area)

  // if no section is given - assume the section the area is in.
  const section = argsSection ?? areaSection

  const labels = area.substrateOrder
    .map((orderItem) => {
      const areaSubstrate = getAreaSubstrate({
        quote,
        areaKey,
        options,
        key: orderItem.key,
        full: false
      }) as UpdateableAreaSubstrate
      if (!areaSubstrate || areaSubstrate.workOrderOnly) {
        return null
      }

      const areaSubstrateSection = areaSubstrate.quoteSection

      if (!isInSection({ areaSubstrateSection, areaSection, section })) {
        return null
      }

      const label = areaSubstrate.clientLabel ? areaSubstrate.clientLabel : areaSubstrate.name
      const rateType = get(areaSubstrate, 'rate.rateType')

      // This substrate (label) has been grouped in the substrate view, so it shouldn't be included here.
      const sanitizedlabel = label.trim().toLowerCase()
      const substrateOrderItem = find(quote.order.substrate, { key: sanitizedlabel }) as OrderItem

      // This substrate (label) has been grouped in the substrate view, so it shouldn't be included here.
      if (substrateOrderItem && substrateOrderItem.parent) {
        return null
      }

      // Handle quantities on areaSubstrate
      if (!quantities[label]) {
        quantities[label] = 0
      }
      const quantity =
        typeof areaSubstrate.quantity === 'number' ? areaSubstrate.quantity : parseFloat(areaSubstrate.quantity)
      if (rateType === 'quantity' && !areaSubstrate.hideQuantity) {
        quantities[label] += quantity
      }

      // Handle prices & materials on areaSubstrate
      if (!prices[label]) {
        prices[label] = 0
      }
      const price = areaSubstrate.price.useCustom ? areaSubstrate.price.custom : areaSubstrate.price.default
      const materials = areaSubstrate.materials.useCustom
        ? areaSubstrate.materials.custom
        : areaSubstrate.materials.default

      if (areaSubstrate.showPrice) {
        prices[label] += price + materials
      }

      // Handle products on areaSubstrate
      if (!products[label]) {
        products[label] = []
      }
      const { product } = areaSubstrate
      if (product) {
        const productString = [product.label, product.color].filter((item) => item).join(' - ')
        if (productString && areaSubstrate.showProduct) {
          products[label].push(productString)
        }
      }

      // Handle coats on areaSubstrate
      if (areaSubstrate.showCoats && areaSubstrate.rate?.useCoats) {
        coats[label] = `${areaSubstrate.coats} Coat${areaSubstrate.coats === 1 ? '' : 's'}`
      }

      // Handle prep on areaSubstrate
      if (areaSubstrate.showPrep && areaSubstrate.prep) {
        prep[label] = `${areaSubstrate.prep} hr${areaSubstrate.prep === 1 ? '' : 's'} prep`
      }

      if (areaSubstrate.rate.areaReportText) {
        areaReportText.push(areaSubstrate.rate.areaReportText)
      }

      // Hide the "substrate list" part of the description if the option is set
      // but still check if there is an area view description
      if (hideSubstrateListDescription) {
        return null
      }

      return label
    })
    .filter((item) => item)

  // Combine labels to create description
  const descriptionElements = mergeLabels(labels, quantities, prices, coats, prep, products)

  const descriptionFirstLine = uniq(descriptionElements)
    .map((item) => item.trim())
    .join(', ')
  const firstLine = makeHtml(descriptionFirstLine)

  const reportText = uniq(areaReportText.map((item) => makeHtml(item)))
    .filter((item) => item)
    .join('')

  if (!firstLine && !reportText) {
    return '<p></p>'
  }

  return `${firstLine}${reportText}`
}

export function getAreaSection(area: QuoteArea): QuoteItemSection {
  const areaSubstrateSections = area.substrates
  const substrateKeys = Object.keys(areaSubstrateSections)
  if (substrateKeys.length === 0) {
    // Empty area, just default to bid, else find section
    return 'bid'
  }

  // Find section for area based on all the substrates,
  // if they all match, then the area is in the same section, else default bid
  let section = getItemSection(areaSubstrateSections[substrateKeys[0]])
  Object.keys(areaSubstrateSections).forEach((substrateSectionKey) => {
    const substrateSection = areaSubstrateSections[substrateSectionKey]
    substrateSection.forEach((substrate) => {
      const substrateSection = getItemSection(substrate)
      if (substrateSection !== section) {
        section = 'bid'
      }
    })
  })
  return section
}
