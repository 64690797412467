import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { fixNumber } from './fix-number'

export function roundPriceQuoteOptions(value: number, quote: QuoteDocument, options?: OptionsDocument) {
  const roundPrices = quote?.options?.roundPrices ?? options?.options?.quotes?.roundPrices ?? null

  if (roundPrices === 'round') return fixNumber(value, 0)
  if (roundPrices === 'up') return Math.ceil(value)
  else return value
}
