import React, { useState, useEffect } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '../theme'
import { makeStyles } from '@material-ui/core'
import { useFileDropArea } from '../files'
import type { QuoteFile } from 'paintscout'
import { useIsMobile } from '../hooks'

const useStyles = makeStyles<Theme, SignatureCanvasProps>(
  (theme) => ({
    root: {
      display: 'inline-block',
      width: '65%',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 450
      }
    },
    canvas: {
      width: '100%',
      height: 150
    },
    canvasWrapper: {
      position: 'relative'
    },
    buttonRow: {
      marginTop: theme.spacing(2),
      width: '100%',
      display: 'flex'
    },
    caption: {
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)'
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  }),
  { name: 'SignatureCanvas' }
)

export interface SignatureCanvasProps {
  Button: React.ComponentType<any>
  classes?: StyleClasses<typeof useStyles>
  disabled?: boolean
  caption?: string
  onChange?: (event: any, data?: string) => void
  onSignStart?: (value: boolean) => void
  onUploadError?: () => void
  value?: string
  allowUpload?: boolean
}

function SignatureCanvas(props: SignatureCanvasProps) {
  const classes = useStyles(props)
  const { caption, disabled, value, onChange, onSignStart, Button, onUploadError, allowUpload } = props
  const isMobile = useIsMobile({ xs: true })

  const canvasRef = React.createRef<HTMLElement>() as any
  const [empty, setEmpty] = useState(!value)
  const [initialValue, setInitialValue] = useState(value)

  useEffect(() => {
    if (disabled) canvasRef.current.off()
    else canvasRef.current.on()
  }, [disabled])

  const clear = () => {
    canvasRef.current.clear()
    setEmpty(true)
    setInitialValue(null)
    handleOnEnd(null)
  }

  const handleOnEnd = (event: any) => {
    if (canvasRef.current.isEmpty()) {
      setEmpty(true)
      onChange(event, '')
    } else if (onChange) {
      onChange(event, canvasRef.current.getCanvas().toDataURL())
    }
    setTimeout(() => {
      if (onSignStart) onSignStart(false)
    }, 250)
  }

  const handleOnBegin = (event: any) => {
    if (onSignStart) onSignStart(true)
    if (!disabled) {
      setEmpty(false)
      setInitialValue(null)
    }
  }

  function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  const { open: openDropArea, getInputProps } = useFileDropArea({
    maxHeight: 300,
    maxWidth: 300,
    onUpload: async (files: QuoteFile[]) => {
      if (files[0]) {
        toDataURL(files[0].src, (data: string) => {
          setInitialValue(data)
          setEmpty(false)
          onChange(null, data)
        })
      } else if (onUploadError) {
        onUploadError()
      }
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.canvasWrapper}>
        <ReactSignatureCanvas
          canvasProps={{ className: classes.canvas }}
          ref={canvasRef}
          onEnd={handleOnEnd}
          onBegin={handleOnBegin}
          clearOnResize={!isMobile}
        />
        {empty && !initialValue && !disabled && <div className={classes.caption}>{caption ?? 'Sign Here'}</div>}
        {!empty && initialValue && <img className={classes.image} src={initialValue} />}
      </div>
      <div className={classes.buttonRow} style={{ justifyContent: allowUpload ? 'space-between' : 'flex-start' }}>
        <Button
          disabled={disabled || empty}
          variant={'text'}
          color={'secondary'}
          prominence={3}
          edge={'start'}
          onClick={clear}
        >
          Clear Signature
        </Button>
        {allowUpload && (
          <Button variant="text" color="primary" prominence={2} onClick={openDropArea}>
            <input {...getInputProps()} />
            Upload
          </Button>
        )}
      </div>
    </div>
  )
}

export default SignatureCanvas
