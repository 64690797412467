import {
  Button,
  Grid,
  DialogStackContext,
  ExportJsonDialog,
  FormSection,
  FormSectionTitle,
  FormControlLabel,
  ImportJsonDialog,
  Checkbox,
  SplitMenuButton,
  MenuItem
} from '@ui/stickybid'
import { useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument, ClientMetaDocument, Auth0User } from 'paintscout'
import ImportAccountDialog from '../ImportAccountDialog'
import React, { useContext, useState, useEffect } from 'react'
import { cleanOptions, cleanRates } from '@paintscout/util/builder'
import { downloadCSVTemplate } from '@paintscout/util'
import { useCSVImport } from 'shared/hooks'
import { SEARCH_USERS } from 'src/graphql/queries/SEARCH_USERS'
import { useSaveClientQuoteMutation } from '@paintscout/api'
import { useQuery } from '@apollo/react-hooks'
import { jsonToLucene } from 'json-to-lucene'

export interface ClientImportExportProps {
  areaLabels: boolean
  lineItems: boolean
  products: boolean
  rates: boolean
  presentations: boolean
  terms: boolean
  disclaimers: boolean
}

const ClientImportExport = () => {
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { values, setFieldValue } =
    useFormikContext<{ options: OptionsDocument; rates: RatesDocument; meta: ClientMetaDocument }>()
  const { options, rates, meta } = values
  const defaultExportOptions = {
    areaLabels: false,
    lineItems: false,
    products: false,
    rates: false,
    presentations: false,
    terms: false,
    disclaimers: false
  } as ClientImportExportProps

  const [exportOptions, setExportOptions] = useState(defaultExportOptions)
  const [isImporting, setIsImporting] = useState<boolean>(false)
  const { handleImportCSV, isDirty, setIsDirty } = useCSVImport()
  const [updateQuote] = useSaveClientQuoteMutation()

  function handleExport() {
    openDialog(ExportJsonDialog, {
      item: {
        options: {
          ...(exportOptions.areaLabels && { areaTemplates: options.options.areaTemplates }),
          ...(exportOptions.lineItems && { lineItems: options.options.lineItems }),
          ...(exportOptions.products && { products: options.options.products }),
          ...(exportOptions.rates && { rateSections: options.options.rateSections }),
          ...(exportOptions.presentations && { presentationOptions: options.options.presentationOptions }),
          ...(exportOptions.terms && { terms: options.options.terms }),
          ...(exportOptions.disclaimers && {
            acceptDisclaimer: options?.options?.acceptDisclaimer ?? '',
            acceptAdditionalDisclaimer: options?.options?.acceptAdditionalDisclaimer ?? '',
            declineDisclaimer: options?.options?.declineDisclaimer ?? ''
          })
        },
        ...(exportOptions.rates && { rates: rates.rates })
      },
      onConfirm: dismissDialog
    })
  }

  const handleExportCheckboxChange = (target: string) => {
    return function () {
      setExportOptions({ ...exportOptions, [target]: !exportOptions[target] })
    }
  }

  const checkForSelected = () => {
    for (const x in exportOptions) {
      if (exportOptions[x]) return false
    }
    return true
  }

  const queueDocUpdates = (json: any, selectedOptions?: ClientImportExportProps) => {
    const { options: newOptions, rates: newRates } = json

    if (newOptions) {
      const updatedOptions: OptionsDocument = {
        ...options,
        options: {
          ...options.options,
          ...newOptions,
          ...(selectedOptions?.areaLabels && { areaTemplates: newOptions.options.areaTemplates }),
          ...(selectedOptions?.lineItems && { lineItems: newOptions.options.lineItems }),
          ...(selectedOptions?.products && { products: newOptions.options.products }),
          ...(selectedOptions?.rates && { rateSections: newOptions.options.rateSections }),
          ...(selectedOptions?.presentations && { presentationOptions: newOptions.options.presentationOptions }),
          ...(selectedOptions?.terms && { terms: newOptions.options.terms })
        }
      }

      const cleanedOptions = cleanOptions({ options: updatedOptions })

      setFieldValue('options', cleanedOptions)
    }

    if (newRates) {
      const updatedRates: RatesDocument = {
        ...rates,
        rates: newRates
      }

      const cleanedRates = cleanRates({ rates: updatedRates })

      setFieldValue('rates', cleanedRates)
    }
  }

  const handleImportJson = () => {
    openDialog(ImportJsonDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  const handleImportAccount = () => {
    openDialog(ImportAccountDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  const { data } = useQuery<{
    searchUsers: {
      rows: Auth0User[]
      total_rows: number
    }
  }>(SEARCH_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: jsonToLucene({
        filters: [
          { type: 'equal', id: 'app_metadata.companyId', value: meta._id },
          {
            type: 'in',
            id: 'app_metadata.roles',
            not: true,
            value: ['superadmin']
          }
        ]
      }),
      limit: 100
    }
  })

  const owners = []
  if (data) {
    data.searchUsers.rows.forEach((user) => {
      owners.push({
        value: user.user_id,
        label: user.name,
        email: user.email,
        companyId: meta._id
      })
    })
  }

  useEffect(() => {
    if (isDirty) {
      const timeout = setTimeout(() => {
        setIsImporting(false)
        setIsDirty(false)
      }, 1000 * 60 * 5) // 5 minutes

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isDirty, setIsDirty])

  return (
    <>
      <FormSectionTitle title="Import" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <SplitMenuButton onClick={handleImportJson} variant={'contained'} color={'primary'} label={'Import JSON'}>
              <MenuItem onClick={handleImportAccount}>Import From Account</MenuItem>
            </SplitMenuButton>
          </Grid>
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={() => handleImportCSV({ type: 'contacts', owners })}
              variant={'contained'}
              color={'primary'}
              label={'Import Contacts'}
              loading={isImporting}
              disabled={isImporting || isDirty}
            >
              <MenuItem onClick={() => downloadCSVTemplate('contacts')}>Download Contacts Template</MenuItem>
            </SplitMenuButton>
          </Grid>
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={() => handleImportCSV({ type: 'quotes', owners, updateQuote, companyId: meta._id })}
              variant={'contained'}
              color={'primary'}
              label={'Import Quotes'}
              loading={isImporting}
              disabled={isImporting || isDirty}
            >
              <MenuItem onClick={() => downloadCSVTemplate('quotes', 'default')}>
                Download Default Quotes Template
              </MenuItem>
              <MenuItem onClick={() => downloadCSVTemplate('quotes', 'er-quotes')}>
                Download ER Quotes Template
              </MenuItem>
            </SplitMenuButton>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Export" />
      <FormSection>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <FormControlLabel
              label={'Area Labels'}
              control={
                <Checkbox checked={exportOptions.areaLabels} onChange={handleExportCheckboxChange('areaLabels')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Line Items'}
              control={
                <Checkbox checked={exportOptions.lineItems} onChange={handleExportCheckboxChange('lineItems')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Products'}
              control={<Checkbox checked={exportOptions.products} onChange={handleExportCheckboxChange('products')} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Rates'}
              control={<Checkbox checked={exportOptions.rates} onChange={handleExportCheckboxChange('rates')} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Presentations'}
              control={
                <Checkbox
                  checked={exportOptions.presentations}
                  onChange={handleExportCheckboxChange('presentations')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Terms'}
              control={<Checkbox checked={exportOptions.terms} onChange={handleExportCheckboxChange('terms')} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Disclaimers'}
              control={
                <Checkbox checked={exportOptions.disclaimers} onChange={handleExportCheckboxChange('disclaimers')} />
              }
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <Button prominence={1} onClick={handleExport} disabled={checkForSelected()}>
              Export
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => setExportOptions(defaultExportOptions)}
              disabled={checkForSelected()}
              prominence={3}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientImportExport
