import round from 'lodash/round'

/** Rounds a number or string to a given number of decimal places or fewer and returns it as a number. Defaults to 2 decimals. */
export const fixNumber = (n: number | string, d: number = 2): number => {
  let sanitized: number

  if (typeof n === 'string') {
    sanitized = parseFloat(n)
  } else if (typeof n !== 'number') {
    sanitized = 0
  } else {
    sanitized = n
  }

  return round(sanitized, d)
}
