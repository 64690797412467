import React from 'react'
import type { SkeletonProps } from '@material-ui/lab/Skeleton'
import MuiSkeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  rect: {
    // todo: replace with theme.borderRadius when it exists
    borderRadius: '0.5rem'
  },
  text: {
    // todo: replace with theme.borderRadius when it exists
    borderRadius: '0.5rem'
  }
}))

export type { SkeletonProps }

export default function Skeleton(props: SkeletonProps) {
  const classes = useStyles(props)

  return <MuiSkeleton variant="rect" animation="wave" {...props} classes={classes} />
}
