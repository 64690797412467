import { ER_QUOTES_TEMPLATE, DEFAULT_QUOTES_TEMPLATE, CONTACTS_TEMPLATE } from './csv-templates'

/**
 * Get csv headers for specified template type and provider.
 * @param type type of template
 * @param provider if quote type, what provider
 * @returns template headers
 */
const selectTemplate = (type: string, provider?: string): string[] => {
  let template = []
  if (type === 'contacts') {
    template = CONTACTS_TEMPLATE
  } else if (type === 'quotes') {
    switch (provider) {
      case 'default':
        template = DEFAULT_QUOTES_TEMPLATE
        break
      case 'er-quotes':
        template = ER_QUOTES_TEMPLATE
        break
    }
  }

  return template
}

export default selectTemplate
