import type { OptionsDocument } from 'paintscout'

export function hasIntegrationInterface({
  options,
  integration
}: {
  options: OptionsDocument
  integration: string
}): boolean {
  return !!options.options.integrations?.[integration]?.enabled
}
