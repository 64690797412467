/**
 * @module builder
 */
import type { MoveableItem } from '../../index'

export function moveToOptions(item: MoveableItem): any {
  return {
    ...item,
    show: true,
    use_total: false,
    additionalWork: false,
    pending: false
  }
}
