/**
 * @module builder
 */
import type { OptionsDocument, PresentationPageTemplate, PresentationSectionOption } from 'paintscout'
import { uuid } from '../../../util'
import { getObjectLabels } from '../../../util/get-object-labels'

export const defaultPresentationHomePage = (quoteLabel: string) => {
  return {
    key: 'home-page',
    type: 'quote',
    label: quoteLabel,
    title: quoteLabel,
    description: 'Your quote and terms section will be attached to the presentation by default.',
    advanced: {
      useForNav: true,
      allowCustom: true,
      active: true
    },
    sections: [
      // {
      //   key: uuid(),
      //   stickybidOnly: true,
      //   type: 'banner',
      //   label: 'Banner',
      //   title: 'Banner',
      //   background: { noPadding: false, color: '#fff' },
      //   fields: {
      //     animation: 'none',
      //     content: `<h2 style="text-align: center">Hey! Aren't I Eye Catching?</h2><p style="text-align: center">Add a background image and change the font color to really capture your client's attention</p>`,
      //     image: sbPlaceholderImage,
      //     layout: 'poster',
      //     layoutOptions: {
      //       poster: {
      //         color: '#ffffff'
      //       },
      //       collage: {
      //         color: '#000000',
      //         textBackgroundColor: '',
      //         useGlassEffect: false,
      //         verticalAlign: 'start',
      //         imagePosition: 'left'
      //       }
      //     }
      //   },
      //   active: true,
      //   advanced: {
      //     useForNav: false,
      //     allowCustom: false
      //   }
      // },
      {
        key: uuid(),
        type: 'quote',
        label: quoteLabel,
        title: quoteLabel,
        fields: {
          asCartLink: false,
          label: `View ${quoteLabel}`,
          color: ''
        },
        background: {
          style: 'contained',
          color: '#fff'
        },
        active: true,
        advanced: {
          useForNav: true,
          allowCustom: false
        }
      },
      {
        key: uuid(),
        type: 'terms',
        label: 'Terms',
        title: 'Terms',
        fields: {},
        background: {
          style: 'contained',
          color: '#fff'
        },
        active: true,
        advanced: {
          allowCustom: false,
          useForNav: true
        }
      }
    ]
  }
}

export function getPresentationPageTemplates(args: {
  options?: OptionsDocument
  type?: string
}): PresentationPageTemplate[] {
  const { type, options } = args
  const objectLabels = getObjectLabels({ options })

  const hasSavedTemplates = options?.options?.presentationPageTemplates?.order?.length >= 1
  const defaultTemplateOptions = [
    {
      key: uuid(),
      type: 'empty',
      label: 'Empty Page',
      title: 'New Page',
      description: 'Empty page for you to add a custom mix of sections to.',
      advanced: {
        useForNav: true,
        allowCustom: true,
        active: true
      },
      sections: [] as PresentationSectionOption[]
    },
    defaultPresentationHomePage(objectLabels.quote.value)
  ] as unknown as PresentationPageTemplate[]

  if (hasSavedTemplates) {
    const savedTemplates = options.options.presentationPageTemplates.order.map((template) => {
      return options.options.presentationPageTemplates.values[template]
    })
    const response = [...savedTemplates, ...defaultTemplateOptions]

    if (type) {
      return response.filter((template) => template.type === type)
    }
    return response
  }

  if (type) {
    return defaultTemplateOptions.filter((template) => template.type === type)
  }

  return defaultTemplateOptions
}
