import React from 'react'
import type { StyleClasses } from '../theme'
import { makeStyles, FormControl } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { InputProps } from '../Input'

const useStyles = makeStyles<Theme, InputFieldProps>((theme) => ({
  root: {
    '& textarea': {
      minHeight: '38px'
    }
  },
  icon: {},
  label: {},
  inputFocused: {},
  input: {},
  hasLabel: {},
  hasSublabel: {},
  clickableIcon: {},
  adornment: {},
  inputRoot: {},
  inputInput: {},
  focused: {}
}))

export type InputFieldFormat = 'price' | 'quantity' | 'hours' | 'dimension' | 'rate' | 'taxRate'

export interface InputFieldProps extends Omit<InputProps, 'inputComponent'> {
  classes?: InputProps['classes'] & StyleClasses<typeof useStyles>
  inputComponent: React.ReactNode
  autoSelect?: boolean

  format?: InputFieldFormat
  suffix?: string
  prefix?: string
  pattern?: { expression: string; title: string }
  onIconClick?: (ev: React.MouseEvent) => void
}

/**
 * Renders a Input component from material-ui and provides extra props for adding an icon
 */
function InputField(props: InputFieldProps) {
  const classes = useStyles(props)

  const {
    className,
    required,
    margin,
    autoSelect,
    label,
    sublabel,
    startAdornment,
    endAdornment,
    style,
    error,
    classes: classesProps,
    ...baseInputProps
  } = props

  return (
    <FormControl
      className={className}
      classes={{ root: classes.root }}
      fullWidth={baseInputProps.fullWidth}
      required={required}
      margin={margin}
      error={error}
      style={style}
    >
      {props.inputComponent && props.inputComponent}
    </FormControl>
  )
}
InputField.defaultProps = {
  disableUnderline: true
}

export default InputField
