import React from 'react'
import Button from '../Button'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { SplitMenuButtonProps as CoreSplitMenuButtonProps } from '@ui/core/SplitMenuButton'
import CoreSplitMenuButton from '@ui/core/SplitMenuButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import type { StyleClasses } from '../styles'

export interface SplitMenuButtonProps
  extends Omit<CoreSplitMenuButtonProps, 'Button' | 'Icon' | 'PopupMenu' | 'MenuItem' | 'prominence'> {
  prominence?: 1 | 2
  leftWidth?: number
  classes?: StyleClasses<typeof useStyles>
}

const useStyles = makeStyles<Theme, SplitMenuButtonProps>((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    minWidth: '3rem'
  },
  groupedContainedPrimary: ({ prominence = 1, ...props }) => ({
    borderColor: props.leftHidden ? 'white' : theme.palette.primary.main,
    // left button - three border
    '&:not(:last-child)': {
      borderRight: 'none!important',
      ...((props.disabled || props.leftDisabled || props.loading) && {
        borderColor: `${theme.palette.action.disabledBackground}!important`
      }),
      ...(!props.disabled &&
        !props.leftDisabled &&
        !props.loading &&
        !props.leftHidden && {
          borderColor: `${theme.palette.primary.main}!important`
        })
    },
    // right button - four border
    '&:last-child': {
      ...(prominence === 1 &&
        !props.disabled && {
          borderLeft: `1px solid ${theme.palette.primary.contrastText}`
        }),
      ...(prominence === 2 &&
        !props.disabled &&
        !props.leftHidden && {
          borderLeft: `1px solid ${theme.palette.primary.main}`
        }),
      ...((props.disabled || props.loading) && {
        borderLeft: `1px solid ${theme.palette.action.disabledBackground}`
      }),
      ...((props.leftDisabled || props.rightDisabled) && {
        borderLeft: `1px solid ${theme.palette.primary.main}`
      }),
      paddingLeft: 12,
      paddingRight: 12
    }
  }),
  // todo: copied from PopupMenu
  menu: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.borderRadius['md'],
    // boxShadow: theme.boxShadow['1'],
    boxShadow: 'none',
    marginTop: theme.spacing(2)
  }
}))

function SplitMenuButton({
  disabled,
  prominence = 1,
  leftWidth,
  leftDisabled,
  leftHidden,
  rightDisabled,
  ...props
}: SplitMenuButtonProps) {
  const classes = useStyles({ disabled, prominence, leftHidden, leftDisabled, rightDisabled, ...props })
  const { menu, ...otherClasses } = classes
  return (
    <CoreSplitMenuButton
      Button={Button}
      leftWidth={leftWidth}
      leftHidden={leftHidden}
      prominence={prominence}
      Icon={ExpandMoreIcon}
      anchorToGroup={true}
      leftDisabled={leftDisabled}
      rightDisabled={rightDisabled}
      disabled={disabled || props.loading}
      {...props}
      classes={classes}
    />
  )
}
export default SplitMenuButton
