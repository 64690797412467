import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import type { DialogProps } from '../Dialog'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog'
import Button from '../Button'
import Grid from '../Grid'
import InputField from '../InputField'

import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CloseButton } from '../dialogs'

export interface ExportJsonDialogProps extends DialogProps {
  item: any
  onConfirm?: () => void
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    input: { width: '100%', height: '100%' },
    content: { maxHeight: '100%' }
  }),
  { name: 'ExportJsonDialog' }
)

function ExportJsonDialog(props: ExportJsonDialogProps) {
  const classes = useStyles(props)
  const { item, onConfirm, ...otherProps } = props
  const { enqueueSnackbar } = useSnackbar()

  const sanitizedItem = { ...item }
  delete sanitizedItem.__typename

  return (
    <Dialog {...otherProps} fullScreen={true}>
      <DialogTitle rightContent={<CloseButton onCancel={onConfirm} />}>{'Export JSON'}</DialogTitle>
      <DialogContent className={classes.content}>
        <CopyToClipboard
          text={JSON.stringify(sanitizedItem, null, 2)}
          onCopy={() => enqueueSnackbar(`Copied to clipboard!`, { variant: 'success' })}
        >
          <InputField
            className={classes.input}
            multiline
            value={JSON.stringify(sanitizedItem, null, 2)}
            fullWidth={true}
          />
        </CopyToClipboard>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Grid item>
            <Button onClick={onConfirm} variant={'contained'}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ExportJsonDialog
