/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import type { QuoteItemSection } from '../../index'
import { moveTo, calculateQuote } from '../../util'
import { moveAreaSubstrate } from '../../area-substrates/move-area-substrate'
import type { RenderableSubItem } from '../../items'
import getSubstrateAreas from '../../items/get-items/util/get-substrate-areas'

export function moveGroup(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  section: QuoteItemSection
  calculate?: boolean
}): QuoteDocument {
  const { key, section, options, calculate } = args
  const quote: any = { ...args.quote }

  if (!quote.groups[key]) {
    return { ...quote }
  }

  const group = { ...quote.groups[key] }

  /* We need to move all of the groups children as well... */

  let updatedQuote = {
    ...quote,
    groups: {
      ...quote.groups,
      [key]: {
        ...group,
        section
      }
    }
  }

  updatedQuote = group.children.reduce(
    (acc: QuoteDocument, child: string) => {
      if (quote.areas[child]) {
        return {
          ...acc,
          areas: {
            ...acc.areas,
            [child]: moveTo(quote.areas[child], section)
          }
        }
      } else if (quote.substrates[child]) {
        // Must move the areaSubstrates, not the substrate, it will get regen'd in calculateQuote
        const substrateAreas = getSubstrateAreas({
          quote: acc,
          options: quote.options,
          clientOptions: options,
          substrateKey: child,
          section: group.section,
          showValues: true,
          view: 'substrate'
        })
        acc = substrateAreas.reduce(
          (acc: QuoteDocument, child: RenderableSubItem) => {
            return moveAreaSubstrate({
              quote: acc,
              options,
              areaKey: child.additionalFields.areaKey,
              key: child.key,
              section
            })
          },
          { ...acc }
        )
        return acc
      } else if (quote.lineItems[child]) {
        return {
          ...acc,
          lineItems: {
            ...acc.lineItems,
            [child]: moveTo(quote.lineItems[child], section)
          }
        }
      }
      return acc
    },
    { ...updatedQuote }
  )

  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}
