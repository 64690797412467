import CheckIcon from '@material-ui/icons/Check'
import type { ResultsTableProps, TableCellProps } from '@ui/stickybid'
import { ResultsTable, TableCell, TableRow, Typography } from '@ui/stickybid'
import type { Auth0User } from 'paintscout'
import React, { useState } from 'react'
import getHighestRole from '../../util/getHighestRole'
import Link from '../Link'
import moment from 'moment'

export interface UserSearchTableProps extends Omit<ResultsTableProps, 'rows' | 'columns' | 'page' | 'renderRow'> {
  users: any[]
  onFetchNextPage?: (nextPage: number) => any
}

export default function UserSearchTable({ users, onFetchNextPage, onPageChange, ...other }: UserSearchTableProps) {
  const [page, setPage] = useState(1)

  // checkbox/actions are disabled until we figure out how we want to handle "deleting" users (deactivating)
  return (
    <>
      <ResultsTable
        rows={users || []}
        noResultsMessage="No results"
        columns={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Last Login</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        }
        renderRow={(row) => {
          const role = getHighestRole(row.app_metadata.roles)
          const lastLogin = row.last_login ? moment(row.last_login).format('YYYY-MM-DD') : ''

          return (
            <TableRow key={row.user_id}>
              <TableCellLink row={row} disabled={role === 'superadmin'}>
                <Typography variant={'body1'}>
                  {row.user_metadata.firstName} {row.user_metadata.lastName}
                </Typography>
                <Typography variant={'subtitle2'}>{row.email}</Typography>
              </TableCellLink>
              <TableCellLink row={row} style={{ textTransform: 'capitalize' }}>
                {role}
              </TableCellLink>
              <TableCellLink row={row}>{lastLogin}</TableCellLink>
              <TableCellLink row={row}>{row.app_metadata.active ? <CheckIcon /> : null}</TableCellLink>
            </TableRow>
          )
        }}
        onPageChange={(nextPage) => {
          if (onFetchNextPage && nextPage > page && users.length < other.rowsPerPage * nextPage) {
            onFetchNextPage(nextPage)
          }

          if (onPageChange) {
            onPageChange(nextPage)
          }

          setPage(nextPage)
        }}
        {...other}
      />
    </>
  )
}

function TableCellLink({ row, disabled, ...other }: { row: Auth0User; disabled?: boolean } & TableCellProps) {
  const linkComponent = disabled
    ? null
    : (cProps: any) => <Link to={{ pathname: `/users/${encodeURIComponent(row.user_id)}` }} {...cProps} />
  return <TableCell component={linkComponent} {...other} />
}
