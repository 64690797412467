import {
  Button,
  Checkbox,
  ConfirmationDialog,
  DialogStackContext,
  ExportJsonDialog,
  FormControlLabel,
  FormSection,
  FormSectionTitle,
  Grid,
  ImportJsonDialog,
  MenuItem,
  SplitMenuButton,
  Typography
} from '@ui/stickybid'
import { useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument, ClientMetaDocument } from 'paintscout'
import ImportUserDialog from '../ImportUserDialog'
import React, { useContext, useState } from 'react'
import type { User } from '@paintscout/api'
import { useTransferUserDocsMutation } from '@paintscout/api'
import SelectEstimatorDialog from '../SelectEstimatorDialog/SelectEstimatorDialog'
import { useSnackbar } from 'notistack'

export interface ClientImportExportProps {}

export interface UserImportExportProps {
  quoteEmails: boolean
  thankYouAcceptEmails: boolean
  thankYouDeclineEmails?: boolean
  followUps: boolean
}

const UserImportExport = () => {
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { values, setFieldValue } =
    useFormikContext<{ options: OptionsDocument; rates: RatesDocument; user: User; meta: ClientMetaDocument }>()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = values

  const defaultExportOptions = {
    quoteEmails: false,
    thankYouAcceptEmails: false,
    thankYouDeclineEmails: false,
    followUps: false
  } as UserImportExportProps
  const [exportOptions, setExportOptions] = useState(defaultExportOptions)
  const [newEstimator, setNewEstimator] = useState<User>(null)
  const [transferUserDocs] = useTransferUserDocsMutation()

  function handleExport() {
    openDialog(ExportJsonDialog, {
      item: {
        user: {
          preferences: {
            emails: {
              ...(exportOptions.followUps && { followUps: user.preferences.emails.followUps }),
              ...(exportOptions.thankYouAcceptEmails || exportOptions.thankYouDeclineEmails || exportOptions.quoteEmails
                ? {
                    templates: {
                      order: user.preferences.emails.templates.order,
                      values: {
                        ...(exportOptions.thankYouAcceptEmails && {
                          'accept-thank-you': user.preferences.emails.templates.values['accept-thank-you']
                        }),
                        ...(exportOptions.thankYouDeclineEmails && {
                          'decline-thank-you': user.preferences.emails.templates.values['decline-thank-you']
                        }),
                        ...(exportOptions.quoteEmails && {
                          'send-invoice-client': user.preferences.emails.templates.values['send-invoice-client'],
                          'send-quote-client': user.preferences.emails.templates.values['send-quote-client'],
                          'send-work-order-staff': user.preferences.emails.templates.values['send-work-order-staff']
                        })
                      }
                    }
                  }
                : null)
            }
          }
        }
      },
      onConfirm: dismissDialog
    })
  }

  function handleEstimatorSelect() {
    openDialog(SelectEstimatorDialog, {
      companyId: user.client.meta._id,
      currentUser: user,
      onConfirm: (newUser: User) => {
        dismissDialog()
        console.log('selected userv', newUser)
        if (newUser.user_id === user.user_id) {
          enqueueSnackbar('Must choose a different Estimator', { variant: 'warning' })
          setNewEstimator(null)
        } else {
          setNewEstimator(newUser)
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDocTransfer(type: string) {
    openDialog(ConfirmationDialog, {
      title: `Confrim ${type === 'both' ? 'Quote & Contact' : type} Transfer`,
      message: `Are you sure you want to transfer all owned ${
        type === 'both' ? 'Quotes & Contacts' : `${type}'s`
      } from ${user.name} to ${newEstimator.name}?`,
      scary: true,
      onConfirm: async () => {
        dismissDialog()
        const res = await transferUserDocs({
          variables: {
            id: user.user_id,
            transferTo: newEstimator.user_id,
            type
          }
        })
        if (res) {
          enqueueSnackbar('Transfer has been started, you will receive an email summary when complete', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Error starting transfer', { variant: 'error' })
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const handleExportCheckboxChange = (target: string) => {
    return function () {
      setExportOptions({ ...exportOptions, [target]: !exportOptions[target] })
    }
  }

  const checkForSelected = () => {
    for (const x in exportOptions) {
      if (exportOptions[x]) return false
    }
    return true
  }

  const queueDocUpdates = (json: any, selectedOptions?: UserImportExportProps) => {
    const { user: newUser } = json
    if (newUser && selectedOptions) {
      // Selected options user create, only copy selected
      const updatedUser: User = {
        ...user,
        preferences: {
          ...user.preferences,
          emails: {
            ...user.preferences.emails,
            followUps: {
              ...user.preferences.emails.followUps,
              ...(selectedOptions?.followUps && {
                values: newUser.preferences.emails.followUps.values,
                order: newUser.preferences.emails.followUps.order
              })
            },
            templates: {
              ...user.preferences.emails.templates,
              values: {
                ...user.preferences.emails.templates.values,
                ...(selectedOptions?.thankYouAcceptEmails && {
                  'accept-thank-you': newUser.preferences.emails.templates.values['accept-thank-you']
                }),
                ...(selectedOptions?.thankYouDeclineEmails && {
                  'decline-thank-you': newUser.preferences.emails.templates.values['decline-thank-you']
                }),
                ...(selectedOptions?.quoteEmails && {
                  'send-invoice-client': newUser.preferences.emails.templates.values['send-invoice-client'],
                  'send-quote-client': newUser.preferences.emails.templates.values['send-quote-client'],
                  'send-work-order-staff': newUser.preferences.emails.templates.values['send-work-order-staff']
                })
              }
            }
          }
        }
      }

      setFieldValue('preferences', updatedUser.preferences)
    } else if (newUser) {
      // Pure JSON with no selected options, copy all

      const updatedUser: User = {
        ...user,
        preferences: {
          ...user.preferences,
          emails: {
            ...user.preferences.emails,
            templates: {
              ...user.preferences.emails.templates,
              ...(newUser.preferences.emails?.templates?.values && {
                values: {
                  ...user.preferences.emails.templates.values,
                  ...newUser.preferences.emails.templates.values
                },
                order: newUser.preferences.emails.templates.order
              })
            },
            followUps: {
              ...user.preferences.emails.followUps,
              ...(newUser.preferences.emails.followUps && {
                values: {
                  ...user.preferences.emails.followUps?.values,
                  ...newUser.preferences.emails.followUps?.values
                },
                order: newUser.preferences.emails.followUps.order
              })
            }
          }
        }
      }

      setFieldValue('preferences', updatedUser.preferences)
    }
  }

  const handleImportJson = () => {
    openDialog(ImportJsonDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  const handleImportUser = () => {
    openDialog(ImportUserDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  return (
    <>
      <FormSectionTitle title="Import" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <SplitMenuButton onClick={handleImportJson} variant={'contained'} color={'primary'} label={'Import JSON'}>
              <MenuItem onClick={handleImportUser}>Import From User</MenuItem>
            </SplitMenuButton>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Export" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <FormControlLabel
              label={'Quote Emails'}
              control={
                <Checkbox checked={exportOptions.quoteEmails} onChange={handleExportCheckboxChange('quoteEmails')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Thank You Accept Email'}
              control={
                <Checkbox
                  checked={exportOptions.thankYouAcceptEmails}
                  onChange={handleExportCheckboxChange('thankYouAcceptEmails')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Thank You Decline Email'}
              control={
                <Checkbox
                  checked={exportOptions.thankYouDeclineEmails}
                  onChange={handleExportCheckboxChange('thankYouDeclineEmails')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={"Follow Up's"}
              control={
                <Checkbox checked={exportOptions.followUps} onChange={handleExportCheckboxChange('followUps')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button prominence={1} onClick={handleExport} disabled={checkForSelected()}>
              Export
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => setExportOptions(defaultExportOptions)}
              disabled={checkForSelected()}
              prominence={3}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Transfer Docs" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Button prominence={1} onClick={handleEstimatorSelect}>
              {newEstimator ? 'Update' : 'Select'} Receiver
            </Button>
          </Grid>
          {newEstimator && (
            <>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Selected Receiver</Typography>
                <Typography variant={'body1'}>
                  {newEstimator?.name} - ({newEstimator.app_metadata.roles})
                </Typography>
                <Typography variant={'body1'}>{newEstimator?.email}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button prominence={2} onClick={() => handleDocTransfer('quote')}>
                  Transfer Quotes
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button prominence={2} onClick={() => handleDocTransfer('contact')}>
                  Transfer Contacts
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button prominence={2} onClick={() => handleDocTransfer('both')}>
                  Transfer Quotes & Contacts
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </FormSection>
    </>
  )
}

export default UserImportExport
