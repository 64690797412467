import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { Typography, InlineColorPicker, Tooltip } from '../'
import CloseIcon from '@material-ui/icons/Close'

interface ColorOptionProps {
  label: string
  value: string
  useClearOption?: boolean
  defaultColor?: string
  helpTextForClear?: string
  clearColor?: string
  onChange: (hex: string) => void
  classes?: StyleClasses<typeof useStyles>
  presetColors?: string[]
}

const useStyles = makeStyles<Theme, ColorOptionProps>(
  (theme: Theme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      label: {
        fontSize: '.9rem'
      },
      swatch: {
        width: 25,
        height: 25,
        marginRight: 0
      },
      tooltipRoot: {
        marginLeft: 'auto',
        marginRight: theme.spacing(2)
      },
      tooltipBadge: {
        cursor: 'pointer',
        top: 0,
        right: 0,
        '& svg': {
          fontSize: '1rem'
        }
      }
    }
  },
  { name: 'ColorOption' }
)

export default function ColorOption(props: ColorOptionProps) {
  const classes = useStyles(props)
  const { label, value, useClearOption, helpTextForClear, defaultColor, clearColor, onChange, presetColors } = props

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant={'h5'}>
        {label}
      </Typography>
      {useClearOption && value !== defaultColor && (
        <div className={classes.tooltipRoot} onClick={() => onChange(clearColor)}>
          <Tooltip
            placement={'left'}
            content={helpTextForClear}
            icon={<CloseIcon />}
            classes={{ badge: classes.tooltipBadge }}
          />
        </div>
      )}
      <InlineColorPicker
        useResponsiveSwatchBorder={true}
        classes={{ swatch: classes.swatch }}
        swatchOnly={true}
        value={value}
        onChange={(hex) => onChange(hex)}
        presetColors={presetColors}
      />
    </div>
  )
}
