/**
 * @module builder
 */

import type { QuoteDocument, AreaSubstrate, OptionsDocument } from 'paintscout'
import { getAreaSubstrateKey } from '../index'
import type { QuoteItemSection } from '../index'
import { moveTo, generateAreaDescription, calculateQuote, moveToBid } from '../util'
import { generateSubstrateDescription } from '../util/generate-substrate-description/generate-substrate-description'

import findIndex from 'lodash/findIndex'

import { areaTotals } from '../../../calculator'

export function moveAreaSubstrate(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  key: string
  section: QuoteItemSection
  calculate?: boolean
}): QuoteDocument {
  const { quote, areaKey, key, section, calculate = true, options } = args

  // If area doesn't exist, return
  if (!quote.areas[areaKey]) {
    return { ...quote }
  }

  let foundRateSection: string
  let sectionSubstrates: AreaSubstrate[]
  let updatedAreaSubstrate: AreaSubstrate
  Object.keys(quote.areas[areaKey].substrates).forEach((rateSection: string) => {
    const index = findIndex(quote.areas[areaKey].substrates[rateSection], { key })
    if (index !== -1) {
      foundRateSection = rateSection
      sectionSubstrates = [...quote.areas[areaKey].substrates[rateSection]]
      updatedAreaSubstrate = moveTo(sectionSubstrates[index], section)
      sectionSubstrates[index] = updatedAreaSubstrate
    }
  })

  if (!foundRateSection) {
    return {
      ...quote
    }
  }

  const updatedArea = areaTotals(
    {
      ...quote.areas[areaKey],
      substrates: {
        ...quote.areas[areaKey].substrates,
        [foundRateSection]: sectionSubstrates
      },
      substrate_string: {
        ...quote.areas[areaKey].substrate_string
      }
    },
    quote
  )

  const updatedQuote = {
    ...quote,
    areas: {
      ...quote.areas,
      [areaKey]: moveToBid(updatedArea)
    }
  }

  updatedQuote.areas[areaKey].substrate_string.default = generateAreaDescription({
    options,
    quote: updatedQuote,
    areaKey
  })

  /*
    Need to update the substrateDescription of updatedQuote.substrates[areaSubstrate.name]
  */
  if (updatedAreaSubstrate) {
    const substrateKey = getAreaSubstrateKey(updatedQuote, updatedAreaSubstrate)
    if (updatedQuote.substrates[substrateKey]) {
      const defaultValue = generateSubstrateDescription({
        quote: updatedQuote,
        clientOptions: options,
        substrateKey: substrateKey.toString()
      })
      const areaString = updatedQuote.substrates[substrateKey].area_string
        ? { ...updatedQuote.substrates[substrateKey].area_string, default: defaultValue }
        : { use_custom: false, default: defaultValue }
      updatedQuote.substrates[substrateKey].area_string = areaString
    }
  }

  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}
