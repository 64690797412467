import type { Theme } from '@material-ui/core'
import Color from 'color'

export interface GetPresentationContentStylesOptions {
  noMargins?: string[]
}

export function getPresentationContentStyles(theme: Theme, options?: GetPresentationContentStylesOptions): any {
  const noMargins = options?.noMargins ?? []
  return {
    '& h1': {
      // fontSize: theme.typography.pxToRem(60),
      // letterSpacing: 1,
      letterSpacing: 'inherit',
      fontStyle: 'inherit',
      fontFamily: 'inherit',
      color: 'inherit',
      ...theme.typography.h1,
      margin: 0
    },
    '& h2': {
      // fontSize: theme.typography.pxToRem(36),
      // lineHeight: 1.1,
      // fontWeight: 'normal',
      letterSpacing: 'inherit',
      fontStyle: 'inherit',
      fontFamily: 'inherit',
      color: 'inherit',
      ...theme.typography.h2,
      margin: 0
    },
    '& h3': {
      // fontSize: theme.typography.pxToRem(36),
      // lineHeight: 1.3,
      // fontWeight: 'normal',
      // fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 'inherit',
      fontFamily: 'inherit',
      fontStyle: 'inherit',
      color: 'inherit',
      ...theme.typography.h3,
      margin: 0
    },
    '& h4': {
      // fontSize: theme.typography.pxToRem(26),
      // letterSpacing: 1,
      // lineHeight: 1.3,
      // fontWeight: 'normal',
      // fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 'inherit',
      fontStyle: 'inherit',
      fontFamily: 'inherit',
      color: 'inherit',
      ...theme.typography.h4,
      margin: 0
    },
    '& p, & ul, & ol': {
      // fontSize: theme.typography.pxToRem(16),
      // lineHeight: 1.75,
      fontFamily: 'inherit',
      color: 'inherit',
      letterSpacing: 'inherit',
      ...theme.typography.body1,
      margin: 0
    },
    '& p': {
      whiteSpace: 'normal'
    },
    '& ul li, & ol li': {
      marginBottom: noMargins.includes('li') ? 0 : `${(theme.typography.body1.lineHeight as number) / 2}rem`
    },
    // This is a bit of a hack to make empty tags still show up as a line-break.
    // We're doing this so that people can "control" margins just by adding line breaks
    '& p:after, & h1:after, & h2:after, & h3:after, & h4:after, & h5:after, & h6:after': {
      content: "''",
      display: 'inline-block',
      width: 0
    },
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.primary.main
      // color: Color(theme.palette.primary.main).lightness(40).hex()
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '& hr': {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderBottom: 'none'
    },
    '& .themeButton': {
      backgroundColor: theme.palette.primary.main,
      color: Color(theme.palette.primary.main).isLight() ? '#000' : '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    '& .themeBackground': {
      backgroundColor: theme.palette.accentBackground?.default
    },
    '& .themeAccent': {
      color: theme.palette.primary.main
    }
    // '@media print': {
    //   '& h1': {
    //     // fontSize: theme.typography.pxToRem(60)
    //   },
    //   '& h2': {
    //     // fontSize: theme.typography.pxToRem(36)
    //   },
    //   '& h3': {
    //     // fontSize: theme.typography.pxToRem(36)
    //   },
    //   '& h4': {
    //     // fontSize: theme.typography.pxToRem(26)
    //   },
    //   '& p, & ul, & ol': {
    //     // fontSize: theme.typography.pxToRem(16)
    //   }
    // },
    // [theme.breakpoints.down('md')]: {
    //   '& h1': {
    //     // fontSize: theme.typography.pxToRem(50)
    //   },
    //   '& h2': {
    //     // fontSize: theme.typography.pxToRem(30)
    //   },
    //   '& h3': {
    //     // fontSize: theme.typography.pxToRem(30)
    //   },
    //   '& h4': {
    //     // fontSize: theme.typography.pxToRem(20)
    //   },
    //   '& p, & ul, & ol': {
    //     // fontSize: theme.typography.pxToRem(14)
    //   }
    // },
    // [theme.breakpoints.down('sm')]: {
    //   '& h1': {
    //     // fontSize: theme.typography.pxToRem(30)
    //   },
    //   '& h2': {
    //     // fontSize: theme.typography.pxToRem(26)
    //   },
    //   '& h3': {
    //     // fontSize: theme.typography.pxToRem(22)
    //   },
    //   '& h4': {
    //     // fontSize: theme.typography.pxToRem(22)
    //   },
    //   '& p, & ul, & ol': {
    //     // fontSize: theme.typography.pxToRem(14)
    //   }
    // }
  }
}
