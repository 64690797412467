import type { OptionsDocument, OrderItem, QuoteArea, QuoteDocument, QuoteOptions } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import type { QuoteItemConsumer, QuoteItemSection } from '../../../..'
import { getAreaSubstrates, getSubstrateGroup } from '../../../../area-substrates'
import { getGroup } from '../../../../groups'
import { filterItem } from '../../../../util'
import toRenderableCrewAreaSubstrate from './to-renderable-crew-area-substrate'

export const getCrewAreaSubstrateValues = ({
  quote,
  quoteOptions,
  area,
  orderItem,
  section,
  options,
  consumer,
  showValues,
  hideDimensions
}: {
  quote: QuoteDocument
  quoteOptions: QuoteOptions
  area: QuoteArea
  orderItem: OrderItem
  section: QuoteItemSection
  options?: OptionsDocument
  consumer: QuoteItemConsumer
  showValues?: boolean
  hideDimensions?: boolean
}): { areaSubstrates: RenderableSubItem[]; value: number } => {
  const areaSubstrates = getAreaSubstrates({
    quote,
    options,
    areaKey: orderItem.key,
    section,
    consumer,
    overrideOptions: quoteOptions
  })
    .map((substrate) => {
      // Filter areaSubstrates that are a part of a group
      // that is not in this section
      if (substrate.inSubstrateGroup) {
        const rateKey = substrate.rateKey
        const substrateGroup = getSubstrateGroup({ quote, rateKey })

        if (substrateGroup && !filterItem(getGroup({ quote, key: substrateGroup }), section)) {
          return null
        }
      }

      // Clean areaSubstrate to be displayed as RenderableSubItem
      return toRenderableCrewAreaSubstrate({
        key: substrate.key,
        areaSubstrate: substrate,
        quote,
        area,

        showValues,
        hideDimensions
      })
    })
    .filter((item) => item)

  const value = areaSubstrates.reduce((acc: number, areaSubstrate: RenderableSubItem) => {
    return acc + Number(Number(areaSubstrate.value).toFixed(2))
  }, 0)

  return { areaSubstrates, value }
}
