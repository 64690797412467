import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import type { OptionsDocument } from 'paintscout'
import {
  // AreaOptions,
  AdminPresentationOptions,
  // ProductOptions,
  // QuoteTypeOptions,
  // RateOptions,
  CustomerViewOptions,
  LineItemOptions,
  PaymentInvoiceOptions,
  QuoteOptions,
  TermsOptions
} from '@ui/react-quote-stickybid'
import { ClientGeneralForm, FloatingMenu, FollowUpsForm } from '@ui/stickybid'
import { getObjectLabels } from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import { PRESENTATION_LABEL_PLURAL } from '@paintscout/util'
// import useRouter from 'use-react-router'
// import { setUrlQuery } from 'src/util/setUrlQuery'
// import { getUrlQuery } from 'src/util/getUrlQuery'

// interface UrlParams {
//   section: string
//   presentation: string
// }

export interface ClientFormProps {
  section?: string
  isSuperAdmin?: boolean
  onSectionChange?: (section: string) => any
}

export default function ClientSettingsForm({ section, isSuperAdmin, onSectionChange }: ClientFormProps) {
  // const { location } = useRouter()
  // const urlQuery = getUrlQuery<UrlParams>({ location })

  const {
    values: { options },
    handleSubmit
  } = useFormikContext<{ options: OptionsDocument }>()
  const [floatingMenuValue, setFloatingMenuValue] = useState(section || 'company')

  // update if url changes
  useEffect(() => {
    const defaultSection = isSuperAdmin ? 'quotes' : 'company'
    setFloatingMenuValue(section || defaultSection)
  }, [section, isSuperAdmin])

  function changeMenuValue(newValue: string) {
    if (onSectionChange) {
      onSectionChange(newValue)
    }
    setFloatingMenuValue(newValue)
  }

  const objectLabels = getObjectLabels({ options })
  const menuItems = [
    {
      key: 'company',
      label: 'Company'
    },
    {
      key: 'quotes',
      label: objectLabels.quote.plural
    },
    {
      key: 'customer-view',
      label: 'Customer View / Acceptance'
    },
    {
      key: 'follow-ups',
      label: 'Follow-Ups'
    },
    {
      key: 'line-items',
      label: 'Products & Services'
    },
    {
      key: 'payment',
      label: 'Invoices & Payments'
    },
    // {
    //   key: 'quote-types',
    //   label: `${objectLabels.quote.value} Types`
    // },
    // {
    //   key: 'rates',
    //   label: objectLabels.productionRate.plural
    // },
    // {
    //   key: 'areas',
    //   label: 'Areas'
    // },
    // {
    //   key: 'products',
    //   label: 'Products'
    // },
    {
      key: 'presentation',
      label: PRESENTATION_LABEL_PLURAL
    },
    {
      key: 'terms',
      label: 'Terms'
    }
  ].filter((item) => (isSuperAdmin ? ['quotes', 'payment'].includes(item.key) : item))

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={3}>
        <FloatingMenu
          value={floatingMenuValue}
          defaultValue="company"
          onItemClick={(item) => changeMenuValue(item.key)}
          items={menuItems}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={9}>
        <Card>
          <CardContent>
            {floatingMenuValue === 'company' && <ClientGeneralForm />}
            {floatingMenuValue === 'quotes' && (
              <QuoteOptions showSuperAdminSettings={isSuperAdmin} hideNonSuperAdminSettings={isSuperAdmin} />
            )}
            {floatingMenuValue === 'customer-view' && <CustomerViewOptions />}
            {floatingMenuValue === 'follow-ups' && <FollowUpsForm type={'client'} onDialogConfirm={handleSubmit} />}
            {floatingMenuValue === 'payment' && <PaymentInvoiceOptions showAdvanced showOnlyAdvanced={isSuperAdmin} />}
            {floatingMenuValue === 'line-items' && (
              <LineItemOptions onDialogConfirm={() => {}} allowCustomProduct={false} />
            )}
            {/* {floatingMenuValue === 'quote-types' && <QuoteTypeOptions onDialogConfirm={() => {}} />} */}
            {/* {floatingMenuValue === 'rates' && <RateOptions allowCustomProduct={false} />} */}
            {/* {floatingMenuValue === 'areas' && <AreaOptions onDialogConfirm={() => {}} />} */}
            {/* {floatingMenuValue === 'products' && <ProductOptions onDialogConfirm={() => {}} />} */}
            {floatingMenuValue === 'presentation' && <AdminPresentationOptions />}
            {floatingMenuValue === 'terms' && <TermsOptions onDialogConfirm={() => {}} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
