import React from 'react'
import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import { PresentationImbedPreview } from '../../presentation'
import { QuoteContextProvider, useQuote } from '../../context'
import type { AlertDialogProps, DialogProps } from '@ui/stickybid'
import { Dialog, useClientOptions } from '@ui/stickybid'
import type { PresentationOption, QuoteDocument } from 'paintscout'
import { getQuotePresentation } from '@paintscout/util/builder'

export interface QuotePreviewDialogProps extends Omit<AlertDialogProps, 'message' | 'title' | 'onConfirm'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  view: 'quote' | 'work-order' | 'archived-items' | 'activity' | 'details' | 'terms'
  onConfirm: (event: React.MouseEvent<HTMLElement>, selectedOptionalItems?: string[]) => void
  givenQuote?: QuoteDocument
  givenPresentation?: PresentationOption
  allowResponse?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      dialogPaper: {
        overflow: 'hidden'
      },
      paperScrollPaper: {
        display: 'block'
      },
      presPreview: {
        height: '100vh'
      }
    }
  },
  { name: 'QuotePreviewDialog' }
)

function QuotePreviewDialog(props: QuotePreviewDialogProps) {
  const classes = useStyles(props)
  const {
    allowResponse = true,
    givenPresentation,
    givenQuote,
    onClose,
    onConfirm,
    open,
    view,
    ...baseDialogProps
  } = props
  const { options } = useClientOptions()

  // have two quote options so it works w/ and w/o context
  const { quote: usedQuote, estimator } = useQuote()
  const quote = givenQuote ? givenQuote : usedQuote
  const presentation = givenPresentation ? givenPresentation : getQuotePresentation({ options, quote: usedQuote })

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.paperScrollPaper }}
      open={true}
      forceFullScreen={true}
      scroll="body"
      fullWidth={true}
      PaperProps={{
        id: 'quote-preview-dialog-paper'
      }}
      {...baseDialogProps}
    >
      <QuoteContextProvider quote={{ ...quote, presentationTemplate: presentation }} estimator={estimator}>
        <PresentationImbedPreview
          border={'none'}
          loadingStyle={'cv'}
          classes={{ root: classes.presPreview }}
          handleAcceptClick={onConfirm ? (ev, selectedOptionalItems) => onConfirm(ev, selectedOptionalItems) : null}
          isPreviewAccept={true}
          onDismissDialog={onClose}
          presentation={presentation}
          usePresentationFromProp={true}
          quoteId={quote?._id}
        />
      </QuoteContextProvider>
    </Dialog>
  )
}

export default QuotePreviewDialog
