import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

import * as React from 'react'

import type { QuoteHeaderCardProps } from '../QuoteHeaderCard'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { QuoteContext } from '../../../context/QuoteContext'
import { useUser, useClientOptions } from '@ui/stickybid'
import { formatPhoneNumber } from '@paintscout/util/builder'

const useStyles = makeStyles<Theme, EstimatorCardProps>(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2)
    },
    contents: {
      paddingTop: 0
    },
    buttonSpan: {
      display: 'block'
    }
  }),
  { name: 'EstimatorCard' }
)

export interface EstimatorCardProps extends Omit<QuoteHeaderCardProps, 'classes'> {
  classes?: StyleClasses<typeof useStyles>
}

const EstimatorCard = (props: EstimatorCardProps) => {
  const { quote, onSelectEstimator, isEditable } = React.useContext(QuoteContext)
  const { options } = useClientOptions()
  const { isAdmin } = useUser()
  const classes = useStyles(props)

  if (!quote.owner) {
    return null
  }

  const { firstName, lastName, phoneNumber, email, title } = quote.owner

  let text = ''

  if (title) {
    text += title + '\n'
  }

  if (phoneNumber) {
    text += formatPhoneNumber({ options, phoneNumber }) + '\n'
  }

  if (email) {
    text += email + '\n'
  }

  return (
    <QuoteHeaderCard
      subtitle={(firstName || lastName) && [firstName, lastName].filter((item) => item).join(' ')}
      classes={classes}
      text={text}
      {...props}
    />
  )
}

export default EstimatorCard
