import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import type { ButtonProps as CoreButtonProps } from '@ui/core/Button'
import CoreButton from '@ui/core/Button'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import { alpha, lighten } from '@material-ui/core/styles/colorManipulator'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

// TODO: hover styles for default and inherit
const useStyles = makeStyles<Theme, ButtonProps>(
  (theme) => ({
    root: (props) => ({
      ...(props.prominence < 3 && {
        padding: theme.spacing(1.25, 3),
        minHeight: theme.typography.body1.fontSize
      }),
      ...(props.prominence > 2 && !props.disabled && { textDecoration: 'underline' }),
      ...(props.prominence === 3 && {
        fontSize: theme.size['3'],
        minWidth: 0
      }),
      ...(props.prominence === 1 && {
        borderRadius: theme.borderRadius['sm']
      }),
      ...(props.prominence === 2 && {
        borderRadius: theme.borderRadius['sm']
      }),
      ...(props.prominence < 4 && {
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase'
      }),
      ...(props.prominence === 4 && {
        padding: theme.spacing(1.25, 3),
        borderColor: theme.palette.grey[500],
        textDecoration: 'none',
        color: theme.palette.grey[800]
      }),
      ...(props.prominence === 5 && {
        borderRadius: 0,
        textDecoration: 'none',
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(1, 2)
      }),
      // 8 is the padding on a mui text button
      ...(props.prominence > 2 && props.edge === 'start' && { marginLeft: -8 }),
      ...(props.prominence > 2 && props.edge === 'end' && { marginRight: -8 }),
      ...(props.prominence === 6 && {
        padding: theme.spacing(1.25, 3),
        textDecoration: 'none',
        fontWeight: 500,
        letterSpacing: '0.1rem',
        transition: '.2s all',
        width: 'auto',
        backgroundColor: `${theme.palette.stickybid.main} !important`,
        border: `2px solid black`,
        boxShadow: `8px 8px 0px -1px black`,
        color: 'black',
        '&:after': {
          background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.34), transparent)',
          content: '""',
          position: 'absolute',
          transform: 'translateX(-100%)',
          // transform: 'translateX(0%)',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0
        }
      }),
      '&:hover': {
        ...(props.prominence === 1 && {
          backgroundColor: alpha(theme.palette.primary.main, 0.8),
          textDecoration: 'none'
        }),
        ...(props.prominence === 2 && {
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
          textDecoration: 'none',
          color: theme.palette.primary.main
        }),
        ...(props.prominence === 3 && {
          backgroundColor: 'transparent',
          textDecoration: 'underline',

          color: alpha(theme.palette.primary.main, 0.8)
        }),
        ...(props.prominence === 4 && {
          backgroundColor: theme.palette.grey[300],
          textDecoration: 'none',
          color: theme.palette.grey[800]
        }),
        ...(props.prominence === 5 && {
          backgroundColor: theme.palette.grey[300],
          textDecoration: 'none'
        }),
        ...(props.prominence === 6 && {
          boxShadow: `11px 11px 0px -1px black`,
          border: `2px solid black`,
          marginBottom: '2px',
          marginLeft: '-2px',
          marginRight: '2px',
          marginTop: '-2px',
          '&:after': {
            transition: 'all .2s cubic-bezier(0.61, 1, 0.88, 1)',
            transform: 'translateX(100%)'
          }
        })
      },
      '&:active': {
        ...(props.prominence === 6 && {
          backgroundColor: `${lighten(theme.palette.stickybid.main, 0.5)} !important`,
          boxShadow: 'revert',
          marginBottom: '-2px',
          marginLeft: '2px',
          marginRight: '-2px',
          marginTop: '2px'
        })
      }
    }),
    label: (props) => ({
      ...(props.prominence < 3 && {
        lineHeight: '20px'
      }),
      ...(props.prominence === 6 && {
        lineHeight: '20px'
      }),
      '& svg': {
        width: '0.8em',
        height: '0.8em',
        position: 'relative',
        top: 1
      }
    }),
    startIcon: {
      marginLeft: 0
    },
    buttonSpan: {
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit'
    }
  }),
  { name: 'Button' }
)

export interface ButtonProps extends CoreButtonProps {
  classes?: StyleClasses<typeof useStyles>
  icon?: React.ComponentType<SvgIconProps> // to be removed?
  children?: React.ReactNode
  prominence?: 1 | 2 | 3 | 4 | 5 | 6
  backArrow?: boolean
  edge?: 'start' | 'end' | false
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, backArrow, startIcon, ...props },
  ref
) {
  const prominence = props.prominence ?? 1
  const classes = useStyles({ prominence, ...props })
  let variant: 'text' | 'outlined' | 'contained'
  switch (prominence) {
    case 1:
      variant = 'contained'
      break
    case 2:
      variant = 'outlined'
      break
    case 3:
      variant = 'text'
      break
    case 4:
      variant = 'outlined'
      break
    case 5:
      variant = 'contained'
      break
    case 6:
      variant = 'outlined'
      break
    default:
      variant = 'contained'
  }

  return (
    <CoreButton
      {...props}
      variant={variant}
      color={'primary'}
      startIcon={startIcon ? startIcon : backArrow ? <ArrowBackIosIcon /> : ''}
      disableTouchRipple
      disableElevation
      classes={classes}
      ref={ref}
    >
      {children}
    </CoreButton>
  )
})

export default Button
