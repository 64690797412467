import React from 'react'
import type { PresentationSection } from 'paintscout'

import { ColorOption, ButtonSelectOption } from '@ui/stickybid'

import { UTurnRightIcon } from '@ui/core/icons'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import OpacityIcon from '@material-ui/icons/Opacity'

import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit'
import AssessmentIcon from '@material-ui/icons/Assessment'

interface BannerOptionsProps {
  section: PresentationSection
  presetColors: string[]
  handleOptionFieldChange: (field: string, value: any) => void
}

function BannerOptions(props: BannerOptionsProps) {
  const { section, presetColors, handleOptionFieldChange } = props
  const { fields = {} } = section
  const { animation, layout = 'poster' } = fields
  const image = fields.image || {}
  const isVideo = (image.type ?? '').startsWith('video') || ['mp4', 'mov', 'youtube'].includes(image.format)

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(270deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Section Layout'}
          value={layout}
          infoText={isVideo ? 'Some Layouts are not available for videos' : undefined}
          buttons={[
            { icon: <AssessmentIcon style={{ transform: 'rotate(90deg)' }} />, value: 'poster', disabled: isVideo },
            { icon: <VerticalSplitIcon />, value: 'collage' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.layout', value)}
        />
      ),
      tab: 'content'
    },
    // {
    //   Component: (
    //     <ColorOption
    //       label={'Custom Text Color'}
    //       value={fields.layoutOptions?.[layout]?.color ?? ''}
    //       useClearOption={true}
    //       helpTextForClear={'Clear Text Color'}
    //       defaultColor={''}
    //       clearColor={''}
    //       presetColors={presetColors}
    //       onChange={(value) => {
    //         handleOptionFieldChange(`fields.layoutOptions.${layout}.color`, value)
    //       }}
    //     />
    //   ),
    //   tab: 'color'
    // },
    ...(layout === 'collage'
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Image Position'}
                value={fields.layoutOptions?.collage?.imagePosition ?? 'left'}
                infoText={isVideo ? 'Some Positions are not available for videos' : undefined}
                buttons={[
                  {
                    icon: <VerticalSplitIcon style={{ transform: 'rotate(180deg)' }} />,
                    value: 'left',
                    disabled: isVideo
                  },
                  { icon: <VerticalSplitIcon />, value: 'right', disabled: isVideo },
                  { icon: <HorizontalSplitIcon />, value: 'bottom' },
                  { icon: <HorizontalSplitIcon style={{ transform: 'rotate(180deg)' }} />, value: 'top' }
                ]}
                onChange={(value) => handleOptionFieldChange('fields.layoutOptions.collage.imagePosition', value)}
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ColorOption
                label={'Custom Content Background Color'}
                value={fields.layoutOptions?.collage?.textBackgroundColor ?? ''}
                useClearOption={true}
                helpTextForClear={'Clear Background Color'}
                defaultColor={''}
                presetColors={
                  presetColors || [
                    '#FFFFFF',
                    '#F0F0F0',
                    '#E5E4E2',
                    '#FAF9F6',
                    '#F9F6EE',
                    '#FCF5E5',
                    '#EDEADE',
                    '#FFFFF0'
                  ]
                }
                clearColor={''}
                onChange={(value) => {
                  handleOptionFieldChange(`fields.layoutOptions.collage.textBackgroundColor`, value)
                }}
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ButtonSelectOption
                label={'No Content Background'}
                value={fields.layoutOptions?.collage?.noBackground ?? ''}
                buttons={[
                  {
                    icon: <OpacityIcon />,
                    value: true,
                    disabled: fields.layoutOptions?.collage?.useGlassEffect ?? false
                  }
                ]}
                onChange={(value) =>
                  handleOptionFieldChange(
                    'fields.layoutOptions.collage.noBackground',
                    !fields.layoutOptions?.collage?.noBackground
                  )
                }
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ButtonSelectOption
                label={'Use Glass Effect'}
                value={fields.layoutOptions?.collage?.useGlassEffect ?? ''}
                buttons={[
                  {
                    icon: <PhotoFilterIcon />,
                    value: true,
                    disabled: fields.layoutOptions?.collage?.noBackground ?? false
                  }
                ]}
                infoText={'May not be available on all browsers. Fallback to Text Background Color.'}
                onChange={(value) =>
                  handleOptionFieldChange(
                    'fields.layoutOptions.collage.useGlassEffect',
                    !fields.layoutOptions?.collage?.useGlassEffect
                  )
                }
              />
            ),
            tab: 'content'
          }
        ]
      : []),
    ...(layout === 'collage' &&
    (fields.layoutOptions?.collage?.imagePosition === 'left' ||
      fields.layoutOptions?.collage?.imagePosition === 'right')
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Vertical Alignment'}
                value={fields.layoutOptions?.collage?.verticalAlign}
                buttons={[
                  { icon: <VerticalAlignTopIcon />, value: 'start' },
                  { icon: <VerticalAlignCenterIcon />, value: 'center' },
                  { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(180deg)' }} />, value: 'end' }
                ]}
                onChange={(value) => {
                  handleOptionFieldChange('fields.layoutOptions.collage.verticalAlign', value)
                }}
              />
            ),
            tab: 'content'
          }
        ]
      : [])
  ]

  return options
}

export default BannerOptions
