import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const ParallelogramIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} {...props}>
    <svg width={'24'} height={'24'}>
      <rect width={'14'} height={'14'} transform="matrix(1 0 -0.348081 0.937464 8 5)" />
    </svg>
  </SvgIcon>
)
export default ParallelogramIcon
