import React from 'react'
import type { FieldProps } from 'formik'
import type { SwitchProps } from '../Switch'
import Switch from '../Switch'

export interface FormikSwitchProps extends FieldProps<any>, Omit<SwitchProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikSwitch = ({ field, form, ...props }: FormikSwitchProps) => {
  return <Switch {...field} {...props} checked={field.value} />
}

export default FormikSwitch
