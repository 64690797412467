import type { LazyQueryHookOptions } from '@apollo/react-hooks'
import type { SearchClientQuotesQuery } from '@paintscout/api'
import { useSearchClientQuotesLazyQuery } from '@paintscout/api'
import { useState } from 'react'

/**
 * A helper hook to simplify managing quote search and pagination for client quotes
 */
export function useClientQuoteSearch(
  options: { query?: string; companyId?: string; fetchPolicy?: string; limit?: number; prevBookmark?: string } & Omit<
    LazyQueryHookOptions,
    'query'
  > = {}
) {
  const { limit, query, fetchPolicy = 'cache-first', prevBookmark, ...queryOptions } = options
  const [bookmark, setBookmark] = useState<string>(prevBookmark ?? undefined)
  const [lastQuery, setLastQuery] = useState(query)
  const [sort, setSort] = useState(undefined)

  const [searchClientQuotes, queryResults] = useSearchClientQuotesLazyQuery({
    fetchPolicy,
    ...(queryOptions as any), // ? this ts error is stupid
    onCompleted: (data) => {
      if (data?.searchClientQuotes) {
        setBookmark(data.searchClientQuotes.bookmark)
      }
      if (queryOptions.onCompleted) {
        queryOptions.onCompleted(data)
      }
    }
  })

  const search = ({ ...args }: { query: string; companyId: string; limit?: number; sort?: string[] }) => {
    setLastQuery(args.query)

    if (args.sort) {
      setSort(args.sort)
    }

    searchClientQuotes({
      variables: {
        limit,
        ...args,
        sort: args.sort || sort
      }
    })
  }

  const fetchMore = () => {
    return queryResults.fetchMore({
      variables: { query: lastQuery, sort, limit, bookmark },
      updateQuery: (previousResult: SearchClientQuotesQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.searchClientQuotes) {
          return previousResult
        }

        return {
          ...previousResult,
          ...fetchMoreResult,
          searchClientQuotes: {
            ...previousResult.searchClientQuotes,
            ...fetchMoreResult.searchClientQuotes,
            bookmark: fetchMoreResult.searchClientQuotes.bookmark,
            rawQuotes: [...previousResult.searchClientQuotes.rawQuotes, ...fetchMoreResult.searchClientQuotes.rawQuotes]
          }
        }
      }
    })
  }

  const newQueryResults = { ...queryResults, fetchMore }

  return [search, newQueryResults] as [typeof search, typeof newQueryResults]
}
