import uniqBy from 'lodash/uniqBy'
import type { QuoteDocument, OrderItem } from 'paintscout'
import type { QuoteItemSection, QuoteItemView } from '../../../index'

// Get order of items on quote for given view, and section.
// Maintain specific section order if present.
export const getItemOrder = (view: QuoteItemView, section: QuoteItemSection, quote: QuoteDocument): OrderItem[] => {
  const substrateOrder = quote.order[`${view}`]

  // Maintain order of options or archived sections
  let sectionOrder: OrderItem[]
  if (section === 'options') {
    sectionOrder = quote.order[`${view}Options`] ? quote.order[`${view}Options`] : []
  } else if (section === 'archived') {
    sectionOrder = quote.order[`${view}Archived`] ? quote.order[`${view}Archived`] : []
  } else {
    sectionOrder = []
  }

  // Remove duplicates before returning
  return uniqBy([...sectionOrder, ...substrateOrder], 'key')
}
