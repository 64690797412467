/**
 * @module builder
 */

import type { PresentationOption } from 'paintscout'

export function deletePresentationPage(args: {
  pageIndex: number
  presentation: PresentationOption
}): PresentationOption {
  const { pageIndex, presentation } = args

  const page = presentation?.pages?.[pageIndex]

  if (!page) {
    return presentation
  }

  const updatedPages = [...presentation.pages]
  updatedPages.splice(pageIndex, 1)

  return {
    ...presentation,
    pages: updatedPages
  }
}
