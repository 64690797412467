import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { IconButtonProps as MUIIconButtonProps } from '@material-ui/core/IconButton'
import { default as MUIIconButton } from '@material-ui/core/IconButton'
import Spinner from '../Spinner'

const useStyles = makeStyles<Theme, IconButtonProps>((theme) => ({
  spinnerRoot: {
    width: 24,
    height: 24
  },
  spinner: {
    borderTopColor: 'inherit',
    borderBottomColor: 'inherit'
  }
}))

export interface IconButtonProps extends MUIIconButtonProps {
  loading?: boolean
}

export function IconButton(props: IconButtonProps) {
  const classes = useStyles(props)
  const { children, loading, ...other } = props
  return (
    <MUIIconButton classes={classes} {...other}>
      {loading ? (
        <Spinner fullWidth fullHeight size={15} classes={{ root: classes.spinnerRoot, spinner: classes.spinner }} />
      ) : (
        children
      )}
    </MUIIconButton>
  )
}

export default IconButton
