/**
 * @module builder
 */
import type { RatesDocument } from 'paintscout'

// Clean Rates Document of PK and SK fields
export function cleanRates(args: { rates: RatesDocument }): RatesDocument {
  const { rates: dirtyRates } = args

  const cleanRates: RatesDocument = {
    ...dirtyRates
  }

  // holdover from dynamodb
  // @ts-ignore
  delete cleanRates.PK
  // @ts-ignore
  delete cleanRates.SK

  return cleanRates
}
