import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import type { WithClientOptions } from '@ui/stickybid'
import { withClientOptions } from '@ui/stickybid'
import { FormSectionTitle } from '@ui/stickybid'
import { Switch } from '@ui/stickybid'
import { Collapse } from '@ui/stickybid'
import { Typography } from '@ui/stickybid'

import classnames from 'classnames'

import type { QuoteItemSection, QuoteItemView, RenderableItem } from '@paintscout/util/builder'
import { getDetails, getItems, getTotals, getQuoteOptions } from '@paintscout/util/builder'
import type { WithQuoteContext } from '../../../../context/withQuoteContext'
import { withQuoteContext } from '../../../../context/withQuoteContext'
import WorkOrderCard from '../WorkOrderCard'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},

    readOnly: {},

    hoursToggle: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto auto',
      gridGap: theme.spacing()
    },

    hidePrint: {
      '@media print': {
        display: 'none'
      }
    },

    li: {
      '&::before': {
        content: "''",
        color: 'white'
      },
      '@media print': {
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
        breakInside: 'avoid'
      }
    },
    cards: {
      listStyleType: 'none',
      paddingLeft: 0,
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2)
      },
      '@media print': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2)
      }
    }
  })
}

export interface WorkOrderTableProps extends WithStyles<typeof styles>, WithQuoteContext, WithClientOptions {
  onReorder?: (args: { items: any[]; section: QuoteItemSection; view: QuoteItemView }) => void
  onAddClick?: (event: React.MouseEvent<HTMLElement>) => void
  onItemClick?: (event: React.MouseEvent<HTMLElement>, key: string) => void
  onToggle?: (ev: any) => void
  onViewChange?: (event: any, view: string) => void
  onToggleAmount?: (event: React.ChangeEvent<HTMLElement>) => void
  tableView?: QuoteItemView
  section?: QuoteItemSection
  showPrices?: boolean
  showHours?: boolean
  title?: string
  subTitle?: string
  collapsed?: boolean
  showAddButton?: boolean
  showEmpty?: boolean
  showLineItems?: boolean
  rightContent?: React.ReactNode
}

class WorkOrderTable extends React.Component<WorkOrderTableProps> {
  public render() {
    const {
      classes,
      section,
      title,
      subTitle,
      collapsed,
      tableView,
      showEmpty,
      onToggle,
      onToggleAmount,
      showHours,
      showPrices,
      showLineItems
    } = this.props
    const { quote, isEditable } = this.props.quoteContext
    const { options } = this.props.clientOptions

    const items = getItems({
      quote,
      section,
      view: tableView,
      consumer: 'crew',
      options,
      overrideOptions: { showQuantities: true },
      showLineItems,
      showValues: showHours
    })

    const totals = section === 'bid' ? getTotals({ quote, consumer: 'crew', options }) : null
    const details = getDetails({ quote, options })

    const quoteOptions = getQuoteOptions({ options, quote })

    if (!showEmpty && !items.length) {
      return null
    }

    if (collapsed && !isEditable) {
      return null
    }

    const allowShowHours = !details.totals.hours.useCustom || quoteOptions.allowShowCustomizedTotalHours

    const defaultRightContent =
      onToggleAmount && isEditable ? (
        <Typography classes={{ root: classnames(classes.hoursToggle, classes.hidePrint) }} variant={'overline'}>
          Show Hours{' '}
          <Tooltip title={!allowShowHours ? 'Hours cannot be shown because Total Hours have been customized' : ''}>
            <span>
              <Switch checked={showHours} disabled={!allowShowHours} onChange={onToggleAmount} />
            </span>
          </Tooltip>
        </Typography>
      ) : null

    const rightContent = this.props.rightContent ? this.props.rightContent : defaultRightContent

    return (
      <div
        className={classnames({
          [classes.root]: true,
          [classes.readOnly]: !isEditable,
          [classes.hidePrint]: !items.length
        })}
      >
        {title && (
          <FormSectionTitle
            title={title}
            subTitle={subTitle}
            showToggle={isEditable && !!onToggle}
            toggleValue={!collapsed}
            onToggle={onToggle}
            rightContent={rightContent}
          />
        )}

        <Collapse show={!collapsed}>
          <ul className={classes.cards}>
            {items.map((item: RenderableItem, index: number) => {
              return (
                <li className={classes.li} key={index}>
                  <WorkOrderCard
                    item={item}
                    showHours={showHours}
                    showPrices={showPrices && section === 'options'}
                    quote={quote}
                    section={section}
                    onClick={this.handleItemClick(item.key)}
                  />
                </li>
              )
            })}
          </ul>
        </Collapse>
      </div>
    )
  }

  public handleItemClick = (key: string) => (ev: React.MouseEvent<HTMLElement>) => {
    if (this.props.onItemClick) {
      this.props.onItemClick(ev, key)
    }
  }
}

export default withStyles(styles)(withQuoteContext(withClientOptions(WorkOrderTable)))
