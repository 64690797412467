import type { ChangeEvent } from 'react'
import React from 'react'
import type { NavbarElementProps, DayPickerProps } from 'react-day-picker'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import IconButton from '../IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import type { Moment } from 'moment'
import moment from 'moment'
import Color from 'color'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  monthYear: {},
  selectFormControl: {},
  select: {},
  selectGroup: {},
  rangePresets: {},
  selectLabel: {},
  iconButton: {
    color: Color(theme.palette.primary.main).isLight() ? '#000' : theme.palette.primary.main
  }
}))

export interface DatePickerNavBarProps extends Partial<Omit<NavbarElementProps, 'month'>> {
  month: Moment
  onMonthYearChange?: (month: Moment) => any
  LeftIcon?: React.ComponentType<any>
  RightIcon?: React.ComponentType<any>
  PickerProps?: DayPickerProps
  value?: Moment | Moment[]
}

function DatePickerNavBar(props: DatePickerNavBarProps) {
  const { month, onPreviousClick, onNextClick, onMonthYearChange, PickerProps, value } = props
  const classes = useStyles(props)
  const LeftIcon = props.LeftIcon
  const RightIcon = props.RightIcon
  const currentYear = new Date().getFullYear()

  const years = []
  const startYear = PickerProps?.fromMonth ? PickerProps.fromMonth.getFullYear() : 1970
  const endYear = PickerProps?.toMonth ? PickerProps.toMonth.getFullYear() : currentYear + 10
  const months = moment.months()
  const monthLabels = PickerProps?.numberOfMonths > 1 ? moment.monthsShort() : months

  for (let i = startYear; i <= endYear; i += 1) {
    years.push(i)
  }

  function handleChange(ev: ChangeEvent<HTMLFormElement>) {
    onMonthYearChange(moment(`${ev.target.form.month.value} ${ev.target.form.year.value}`, 'MMMM YYYY'))
  }

  return (
    <div className={classes.root}>
      <div className={classes.monthYear}>
        <IconButton className={classes.iconButton} onClick={() => onPreviousClick()}>
          {props.LeftIcon ? <LeftIcon /> : <KeyboardArrowLeftIcon />}
        </IconButton>
        <form id="month-year-selector" className={classes.selectGroup}>
          <select className={classes.select} name="month" value={month.format('MMMM')} onChange={handleChange as any}>
            {months.map((month, index) => (
              <option key={month} value={month}>
                {PickerProps?.numberOfMonths === 2
                  ? `${monthLabels[index]} - ${getNextMonth(monthLabels, index)}`
                  : month}
              </option>
            ))}
          </select>

          <select className={classes.select} name="year" value={month.format('YYYY')} onChange={handleChange as any}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </form>

        <IconButton className={classes.iconButton} onClick={() => onNextClick()}>
          {props.RightIcon ? <RightIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </div>
      {PickerProps?.numberOfMonths > 1 && <div className={classes.selectLabel}>{displayRangeSelection(value)}</div>}
    </div>
  )
}

export default DatePickerNavBar

function getNextMonth(months: string[], month: number) {
  // get the next month
  if (month === 11) return months[0]
  else return months[month + 1]
}

function displayRangeSelection(value: Moment | Moment[]) {
  const isArray = Array.isArray(value)
  if (isArray) {
    return `${value[0].format('MMM D, YYYY')} - ${value[1] ? value[1].format('MMM D, YYYY') : ''}`
  } else {
    return `${(value as Moment).format('MMM D, YYYY')} - `
  }
}
