import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import CoreSnackbarProvider from '@ui/core/SnackbarProvider'
import type { SnackbarProviderProps as NotiSnackbarProviderProps } from 'notistack'

// TODO: hover styles for default and inherit
export interface SnackbarProviderProps extends NotiSnackbarProviderProps {}
const useStyles = makeStyles<Theme, SnackbarProviderProps>((theme) => ({
  contentRoot: (props) => ({
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.boxShadow[1],
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[500]}`
  }),
  success: {
    '& > div > svg': {
      color: theme.palette.status?.accepted ?? '#caffbf'
    }
  },
  error: {
    '& > div > svg': {
      color: theme.palette.status?.declined ?? '#ffadad'
    }
  },
  warning: {
    '& > div > svg': {
      color: theme.palette.status?.sent ?? '#ffd6a5'
    }
  },
  info: {
    '& > div > svg': {
      color: theme.palette.status?.created ?? '#a9d6e5'
    }
  }
}))

const SnackbarProvider = (props: SnackbarProviderProps) => {
  const classes = useStyles(props)
  const { success, error, warning, info, ...remainingClasses } = classes

  return (
    <CoreSnackbarProvider
      {...props}
      classes={{
        variantSuccess: success,
        variantError: error,
        variantWarning: warning,
        variantInfo: info,
        ...remainingClasses
      }}
    />
  )
}

export default SnackbarProvider
