import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import Grid from '../Grid'
import Typography from '../Typography'
import { useMediaQuery } from '../styles'
import type { ApiKeyDocument } from '@paintscout/api/src/generated/types'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { useDeactivateApiKeyMutation } from '@paintscout/api'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles<Theme, ApiKeyProps>(
  (theme) => ({
    root: {},
    apiKey: {
      border: '1px solid black',
      borderRadius: '5em',
      margin: `${theme.spacing(0.5)}px 0px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'space-around'
      }
    },
    icon: {
      cursor: 'pointer',
      margin: '0.3em',
      '&:hover': {
        filter: 'opacity(0.6)'
      }
    },
    visIcon: {
      cursor: 'pointer',
      margin: '0.3em',
      '&:hover': {
        color: 'black'
      }
    },
    visIconHolder: {
      paddingLeft: theme.spacing()
    },
    archivedText: {
      color: 'red'
    },
    activeText: {
      color: 'green'
    },
    deleteHolder: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    },
    copyClickText: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }),
  { name: 'ClientApiKey' }
)

export interface ApiKeyProps {
  classes?: StyleClasses<typeof useStyles>
  apiKey: ApiKeyDocument
}

function ApiKey(props: ApiKeyProps) {
  const classes = useStyles(props)
  const { apiKey } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const smDown = useMediaQuery('sm')
  const [deactivateApiKey] = useDeactivateApiKeyMutation()
  const { enqueueSnackbar } = useSnackbar()

  const toggleVisible = (val: boolean) => {
    setVisible(val)
  }
  const toggleDeleting = (val: boolean) => {
    setDeleting(val)
  }

  const confirmDelete = async () => {
    const { data } = await deactivateApiKey({
      variables: {
        apiKey: apiKey._id
      }
    })
    if (data.deactivateApiKey.archived) {
      enqueueSnackbar(`Successfully deactivated apiKey`, { variant: 'success' })
    } else {
      enqueueSnackbar(`Error deactivating apiKey`, { variant: 'error' })
    }
  }

  const handleTextClick = () => {
    navigator.clipboard.writeText(apiKey._id)
    enqueueSnackbar(`Key Copied`, { variant: 'success' })
  }

  return (
    <Grid className={classes.apiKey} item container xs={12} key={apiKey._id}>
      <Grid className={classes.visIconHolder} item xs={1}>
        <Typography onClick={() => toggleVisible(!visible)} variant="h6" style={{ lineHeight: 1 }}>
          {visible ? <VisibilityOffIcon className={classes.visIcon} /> : <VisibilityIcon className={classes.visIcon} />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={visible ? classes.copyClickText : null}
          onClick={visible ? handleTextClick : null}
          variant="h6"
        >
          {visible ? apiKey._id : smDown ? '******************' : '************************************'}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={apiKey.archived ? classes.archivedText : classes.activeText} variant="h6">
          {apiKey.archived ? 'Archived' : 'Active'}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Hidden smDown>
          <Typography variant="h6">{new Date(parseInt(apiKey.created)).toLocaleDateString()}</Typography>
        </Hidden>
      </Grid>
      <Grid className={classes.deleteHolder} item xs={1}>
        {apiKey.archived ? (
          ''
        ) : deleting ? (
          <>
            <CheckIcon className={classnames(classes.activeText, classes.icon)} onClick={() => confirmDelete()} />
            <ClearIcon
              className={classnames(classes.archivedText, classes.icon)}
              onClick={() => toggleDeleting(!deleting)}
            />
          </>
        ) : (
          <DeleteIcon onClick={() => toggleDeleting(!deleting)} className={classnames(classes.icon)} />
        )}
      </Grid>
    </Grid>
  )
}

export default ApiKey
