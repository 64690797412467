import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const StickyBidLettermark = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 427.66 79.71'} {...props}>
    <svg>
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="32.27"
        y1="7.91"
        x2="32.27"
        y2="58.84"
      />
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="57.73"
        y1="33.37"
        x2="6.81"
        y2="33.37"
      />
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="50.28"
        y1="15.37"
        x2="14.27"
        y2="51.38"
      />
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="50.28"
        y1="51.38"
        x2="14.27"
        y2="15.37"
      />
      <path d="M86.08,45.24h9.68c0,4.76,4.45,8.33,10.16,8.33,5.4,0,9.6-3.09,9.6-7.37,0-4.45-5-7.07-12.06-8.65C94.5,35.48,87,30.64,87,21.44c0-9.44,8-16,18.56-16s18.73,6.9,18.73,16.11h-9.68c0-4.37-4-7.62-9.13-7.62-4.92,0-8.8,2.78-8.8,6.82,0,4.29,4.52,6.91,11.26,8.42,9.37,2.22,17.3,6.58,17.3,16.18,0,9.92-8.49,16.74-19.52,16.74C94.5,62.06,86.08,54.76,86.08,45.24Z" />
      <path d="M144.4,28.74V47.23c0,3.57,2.22,5.71,5.95,5.71h4.05v8.17h-5.32c-8.81,0-13.57-4.44-13.57-12.45V28.74h-6.82V20.65h4.13A2.85,2.85,0,0,0,136,17.47V9.7h8.41v11h10v8.09Z" />
      <path d="M159.4,8.19a5.67,5.67,0,0,1,11.34,0,5.67,5.67,0,0,1-11.34,0Zm1.19,12.46h8.88V61.11h-8.88Z" />
      <path d="M175.66,40.88c0-12.06,9.28-21.19,21.66-21.19,10.55,0,18.89,6.67,21,16.19h-9.44a12.57,12.57,0,0,0-24.13,5,12.58,12.58,0,0,0,24.13,5h9.44c-2.14,9.52-10.48,16.18-21,16.18C184.94,62.06,175.66,52.94,175.66,40.88Z" />
      <path d="M261,52.54H263v8.57h-3.1c-6.11,0-12.45-2-16-8.49L240,47.39a63.06,63.06,0,0,1-6.91.79V61.11h-8.88V4h8.88V27.87c4.92-5,11.27-8.18,17.54-8.18,7.46,0,12.38,4.37,12.38,10.87,0,6.19-4.76,11.27-13.25,14.37l2.85,3.33A10.73,10.73,0,0,0,261,52.54ZM233.1,40.8C246.12,39.53,254,36,254,31.52c0-2.23-1.9-3.65-5-3.65-5.15,0-11.74,3.72-15.87,9.2Z" />
      <path d="M304.51,20.65V59c0,11-7.85,18.17-18.72,18.17-9.21,0-15.87-5.16-18.25-13.49h9.12a9.88,9.88,0,0,0,9,5.32c5.71,0,10-3.89,10-10.08v-4.2A15.36,15.36,0,0,1,282.69,61c-9.28,0-15.47-6.19-15.47-15.63V20.65h8.89V42.78c0,5.8,3.73,9.68,9.44,9.68,6,0,10.08-4,10.08-9.84v-22Z" />
      <path d="M356.09,46.27c0,8.65-6.59,14.84-15.32,14.84H313.56V6.36h25.07c8.09,0,14.28,5.87,14.28,13.81A13.36,13.36,0,0,1,346.41,32C352.12,33.74,356.09,39.61,356.09,46.27ZM322.68,14.85v14h13.65a6.87,6.87,0,0,0,6.9-7,6.78,6.78,0,0,0-6.9-7Zm23.65,29.84a7.66,7.66,0,0,0-7.78-7.86H322.68V52.62h15.87A7.73,7.73,0,0,0,346.33,44.69Z" />
      <path d="M360.13,8.19a5.68,5.68,0,0,1,11.35,0,5.68,5.68,0,0,1-11.35,0Zm1.19,12.46h8.89V61.11h-8.89Z" />
      <path d="M424,52.54v8.57h-2.54c-5.71,0-9.28-2.38-10.39-6.82a17.39,17.39,0,0,1-14.6,7.77c-11.43,0-19.92-9.12-19.92-21.18S385,19.69,396.47,19.69a17.29,17.29,0,0,1,14.2,7.22V4h8.89V48.81a3.42,3.42,0,0,0,3.73,3.73ZM410.67,40.88a12.5,12.5,0,1,0-25,0,12.5,12.5,0,1,0,25,0Z" />
    </svg>
  </SvgIcon>
)
export default StickyBidLettermark
