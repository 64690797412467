import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'
import CoreAlert from '@ui/core/Alert'
import type { AlertProps as CoreAlertProps } from '@ui/core/Alert'
import AlertIcon from './AlertIcon'

export interface AlertProps extends Omit<CoreAlertProps, 'Typography'> {}

function getSeverityColor(severity: AlertProps['severity'], theme: Theme) {
  switch (severity) {
    case 'warning':
      return theme.palette.warning
    case 'error':
      return theme.palette.error
    case 'success':
      return theme.palette.success
    case 'info':
      return theme.palette.info
    default:
      return {
        light: theme.palette.grey[200],
        main: theme.palette.grey[500],
        dark: theme.palette.grey[900],
        contrastText: '#fff'
      }
  }
}

const useStyles = makeStyles<Theme, AlertProps>(
  (theme) => ({
    root: (props) => {
      const { severity, variant, noMargin } = props
      const severityColor = getSeverityColor(severity, theme)
      let background = 'transparent'
      if (variant === 'default') {
        background = severityColor.light
      } else if (variant === 'filled') {
        background = severityColor.main
      }

      const borderColor = variant === 'default' ? severityColor.light : severityColor.main

      // const color = variant === 'filled' ? severityColor.contrastText : theme.palette.common.black

      const borderStyle = variant === 'text' ? 'none' : 'solid'
      const padding = variant === 'text' ? 0 : theme.spacing(2)

      return {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        padding,
        borderWidth: 1,
        borderStyle,
        borderColor,
        background,
        // color,
        position: 'relative',
        marginBottom: noMargin ? 0 : theme.spacing(2)
      }
    },
    contentWrapper: (props) => {
      const severityColor = getSeverityColor(props.severity, theme)
      return {
        color: props.variant === 'filled' ? severityColor.contrastText : theme.palette.common.black,
        marginTop: 2,
        width: '100%',
        fontSize: theme.typography.pxToRem(16),
        [theme.breakpoints.down('xs')]: {
          width: '90%'
        }
      }
    },
    iconWrapper: (props) => {
      const { severity, variant, noMargin } = props
      const severityColor = getSeverityColor(severity, theme)
      return {
        display: 'flex',
        alignItems: 'center',
        color: variant === 'filled' ? '#fff' : severityColor.main
      }
    },
    closeWrapper: {
      position: 'static',
      marginRight: -16,
      '& svg': {
        fill: theme.palette.primary.main
      }
    },
    title: {
      marginBottom: theme.spacing(0.5),
      color: 'inherit'
    },
    content: {}
  }),
  {
    name: 'Alert_SB'
  }
)

export default function Alert(props: AlertProps) {
  const classes = useStyles(props)
  const { classes: propClasses, ...other } = props
  const { severity = 'default' } = props
  const Icon = <AlertIcon severity={severity} />
  return <CoreAlert classes={classes} Typography={Typography} icon={Icon} {...other} />
}
