import React from 'react'
import type { PresentationSection } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import { ButtonSelectOption, ButtonOption } from '@ui/stickybid'

import LooksOneIcon from '@material-ui/icons/LooksOne'
import LooksTwoIcon from '@material-ui/icons/LooksTwo'
import Looks3Icon from '@material-ui/icons/Looks3'
import Looks4Icon from '@material-ui/icons/Looks4'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles<Theme, GalleryOptionsProps>((theme: Theme) => {
  return {
    root: {}
  }
})

interface GalleryOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
}

function GalleryOptions(props: GalleryOptionsProps) {
  const classes = useStyles(props)
  const { section, handleOptionFieldChange } = props
  const { columns, files } = section.fields

  const handleNewFile = () => {
    const newFiles = [...files]
    newFiles.push({ tempKey: 'tempKey' + new Date().getTime(), type: 'upload' })
    handleOptionFieldChange('fields.files', newFiles)
  }

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Columns'}
          value={columns}
          buttons={[
            { icon: <LooksOneIcon />, value: 1 },
            { icon: <LooksTwoIcon />, value: 2 },
            { icon: <Looks3Icon />, value: 3 },
            { icon: <Looks4Icon />, value: 4 }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.columns', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: <ButtonOption label={'Add Item'} buttons={[{ icon: <AddIcon />, onClick: handleNewFile }]} />,
      tab: 'content'
    }
  ]

  return options
}

export default GalleryOptions
