import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { FilesGridProps } from '../../FilesGrid'
import FilesGrid from '../../FilesGrid'

export interface FileSectionProps extends WithStyles<typeof styles>, FilesGridProps {
  title: string
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    title: {
      marginBottom: theme.spacing()
    }
  })

function FileSection(props: FileSectionProps) {
  const { classes, title, ...filesGridProps } = props

  return (
    <div className={classes.root}>
      {/* <Typography variant="h4"  classes={{root:classes.title}}>
        {title}
      </Typography> */}
      <FilesGrid {...filesGridProps} />
    </div>
  )
}

export default withStyles(styles)(FileSection)
