import React from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import type { Theme } from '@material-ui/core/styles'
import {
  Button,
  CheckboxField,
  Collapse,
  FormikHtmlEditor,
  FormikInputField,
  FormSection,
  FormSectionTitle,
  Grid,
  PageHelpMessage,
  SwitchField,
  useDialogs
} from '@ui/stickybid'
import { getObjectLabels } from '@paintscout/util/builder'
import { FastField, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import { AcceptQuoteThankYouDialog } from '../../../'
import { useSnackbar } from 'notistack'
import { PRESENTATION_LABEL_PLURAL } from '@paintscout/util'

const useStyles = makeStyles<Theme, CustomerViewOptionsProps>((theme) => ({
  root: {}
}))

export interface CustomerViewOptionsProps {}

function CustomerViewOptions(props: CustomerViewOptionsProps) {
  const classes = useStyles(props)
  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })
  const { openDialog, dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()
  // const invoiceLabels = getObjectLabels({ options, invoice: true })

  // const showQuoteDisclaimer =
  //   options.options.quotes?.requireSignatureOnSpot || options.options.quotes?.requireSignatureSent

  const handlePreviewThankYouDialog = () => {
    const body = options.options?.acceptQuoteThankYouDialogBody
    const title = options.options?.acceptQuoteThankYouDialogTitle
    if (!body || !title || body === '<p></p>') {
      enqueueSnackbar(`Thank you dialog ${!title ? 'Title' : 'Body'} is required`, { variant: 'error' })
    } else
      openDialog(AcceptQuoteThankYouDialog, {
        body,
        title,
        logoSrc: options.options?.logo?.value,
        onCancel: () => {
          dismissDialog()
        }
      })
  }

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path={'settings-quote-settings'}
        openContent={
          <>
            <FormSectionTitle title={`Customer View & Acceptance Settings`} variant="h2" />
            <Typography>These options reflect how customers see & interact with {objectLabels.quote.plural}</Typography>
          </>
        }
        closedContent={
          <>
            <FormSectionTitle title={`Customer View & Acceptance Settings`} variant="h2" />
          </>
        }
      />
      <FormSection>
        <FormSectionTitle title={`Accepting ${objectLabels.quote.plural}`} variant={'h2'} />
        <Grid container spacing={3}>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography variant={'h3'}>Require Customer Signature</Typography>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <CheckboxField
                  data-testid={'settings-require-on-spot'}
                  name="options.options.quotes.requireSignatureOnSpot"
                  label={`When Accepting ${objectLabels.quote.plural.toLowerCase()} on the Spot`}
                />
              </Grid>
              <Grid item>
                <CheckboxField
                  data-testid={'settings-require-on-sent'}
                  name="options.options.quotes.requireSignatureSent"
                  label={`When Accepting Sent ${objectLabels.quote.plural}`}
                />
              </Grid>
              {/* <Grid item>
                <CheckboxField
                  data-testid={'settings-require-on-additional'}
                  name="options.options.quotes.requireSignatureAdditionalWork"
                  label={`When Accepting ${objectLabels.additionalWork.plural.toLowerCase()} on ${invoiceLabels.quote.plural.toLowerCase()}`}
                />
              </Grid> */}
            </Grid>
          </Grid>

          <Grid container item direction="column" spacing={3}>
            {/* {showQuoteDisclaimer && (
              <Grid item>
                <FastField
                  component={FormikHtmlEditor}
                  name="options.options.acceptDisclaimer"
                  label={'Acceptance Disclaimer'}
                  sublabel={`Shown to customers when they sign to accept a ${objectLabels.quote.value.toLowerCase()}`}
                  multiline
                  fullWidth
                  bubbleMenuDisabled
                  floatingMenuDisabled
                  menuBarEnabled
                />
              </Grid>
            )} */}
            <Grid item>
              <SwitchField
                name="options.options.useAcceptQuoteThankYouDialog"
                label={`Thank You for Accepting Dialog`}
                sublabel={`Shown to customers in your ${PRESENTATION_LABEL_PLURAL} after they accept a ${objectLabels.quote.value.toLowerCase()}`}
              />
            </Grid>
            <Grid item>
              <Collapse show={values.options.options?.useAcceptQuoteThankYouDialog}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FastField
                      component={FormikInputField}
                      fullWidth
                      label={'Thank You Dialog Title'}
                      margin={'dense'}
                      name={'options.options.acceptQuoteThankYouDialogTitle'}
                      required={values.options.options?.useAcceptQuoteThankYouDialog}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      component={FormikHtmlEditor}
                      fullWidth
                      label={'Thank You Dialog Body'}
                      multiline
                      name="options.options.acceptQuoteThankYouDialogBody"
                      required={values.options.options?.useAcceptQuoteThankYouDialog}
                      bubbleMenuDisabled
                      floatingMenuDisabled
                      menuBarEnabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button prominence={3} edge={'start'} onClick={handlePreviewThankYouDialog}>
                      Preview Thank You Dialog
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            {/* {options.options.quotes?.requireSignatureAdditionalWork && (
              <Grid item>
                <FastField
                  component={FormikHtmlEditor}
                  name="options.options.acceptAdditionalDisclaimer"
                  label={'Additional Work Disclaimer'}
                  sublabel={`Shown to customers when they sign to accept ${objectLabels.additionalWork.value.toLowerCase()} on ${objectLabels.additionalWork.plural.toLowerCase()}`}
                  multiline
                  fullWidth
                />
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </FormSection>{' '}
      <FormSection>
        <FormSectionTitle title={`Declining ${objectLabels.quote.plural}`} variant={'h2'} />
        <Grid item container spacing={3} direction="column">
          <Grid item container spacing={1}>
            <Grid item>
              <CheckboxField
                name="options.options.allowDecline"
                label={`Allow customers to decline ${objectLabels.quote.plural.toLowerCase()}`}
              />
            </Grid>
          </Grid>
          {options?.options?.allowDecline && (
            <Grid item>
              <FastField
                component={FormikHtmlEditor}
                name="options.options.declineDisclaimer"
                label={'Decline Disclaimer'}
                sublabel={`Shown to customers when they decline a ${objectLabels.quote.value.toLowerCase()}`}
                multiline
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
    </div>
  )
}

export default CustomerViewOptions
