import type { EventType, EventParams } from 'paintscout'
import { useMutation } from '@apollo/react-hooks'

import { gql } from 'apollo-boost'

export const PROCESS_EVENT = gql`
  mutation processEvent($type: String!, $provider: String, $async: Boolean, $params: JSON) {
    processEvent(type: $type, provider: $provider, async: $async, params: $params) {
      provider
      result
      error
    }
  }
`

export interface ProcessEventResponse {
  processEvent: {
    provider: string
    error: string
    result: any
  }
}

export function useProcessEvent() {
  const [processEventMutation] = useMutation<ProcessEventResponse>(PROCESS_EVENT)

  return {
    processEvent: async ({
      type,
      provider,
      async,
      params
    }: {
      type: EventType
      provider: string
      async?: boolean
      params: EventParams
    }) => {
      const { data } = await processEventMutation({
        variables: {
          type,
          provider,
          async,
          params
        }
      })

      const { processEvent: processEventResults } = data

      return processEventResults
    }
  }
}
