import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const ImageSmall = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 16 12'} {...props}>
    <svg>
      <path d="M3.30177 1.26996C2.25684 1.26996 1.40977 2.11704 1.40977 3.16196V8.90996C1.40977 9.95488 2.25684 10.802 3.30177 10.802H12.3758C13.4207 10.802 14.2678 9.95488 14.2678 8.90996V3.16196C14.2678 2.11704 13.4207 1.26996 12.3758 1.26996H3.30177ZM0.409767 3.16196C0.409767 1.56475 1.70456 0.269958 3.30177 0.269958H12.3758C13.973 0.269958 15.2678 1.56475 15.2678 3.16196V8.90996C15.2678 10.5072 13.973 11.802 12.3758 11.802H3.30177C1.70456 11.802 0.409767 10.5072 0.409767 8.90996V3.16196Z" />
      <path d="M4.68807 5.75431C4.68799 5.75437 4.68814 5.75426 4.68807 5.75431L1.30774 8.39407C1.0901 8.56404 0.775885 8.52538 0.605924 8.30774C0.435963 8.0901 0.474617 7.77588 0.692258 7.60592L4.07483 4.96441C4.27152 4.81171 4.51433 4.73671 4.75904 4.74755C5.00381 4.75839 5.2396 4.85487 5.42197 5.02432L5.4249 5.02703L8.41219 7.84736C8.41803 7.85287 8.4274 7.8573 8.43817 7.85755C8.44723 7.85776 8.45647 7.85459 8.46385 7.84825L8.46471 7.84751L9.66322 6.82273C9.6634 6.82257 9.66303 6.82289 9.66322 6.82273C9.86097 6.65324 10.1171 6.56293 10.3784 6.5726C10.6395 6.58225 10.887 6.68988 11.0721 6.87419L11.073 6.87506L14.3298 10.1338C14.525 10.3292 14.5249 10.6457 14.3296 10.841C14.1343 11.0362 13.8177 11.0361 13.6225 10.8407L10.3665 7.58282C10.3665 7.58275 10.3666 7.58289 10.3665 7.58282C10.3598 7.57619 10.3509 7.57226 10.3415 7.57191C10.3325 7.57158 10.3222 7.57497 10.3145 7.58155L9.11545 8.60681C9.11526 8.60697 9.11564 8.60664 9.11545 8.60681C8.92114 8.77348 8.67118 8.86333 8.41457 8.85727C8.15945 8.85125 7.91323 8.75159 7.7257 8.5745C7.72567 8.57447 7.72573 8.57453 7.7257 8.5745L4.74073 5.75639C4.73501 5.75133 4.72609 5.74707 4.71479 5.74657C4.70318 5.74606 4.69414 5.74968 4.68807 5.75431Z" />
      <path d="M8.8917 3.35199C8.42005 3.35199 8.0377 3.73434 8.0377 4.20599C8.0377 4.67764 8.42005 5.05999 8.8917 5.05999C9.36335 5.05999 9.7457 4.67764 9.7457 4.20599C9.7457 3.73434 9.36335 3.35199 8.8917 3.35199ZM7.0377 4.20599C7.0377 3.18205 7.86776 2.35199 8.8917 2.35199C9.91563 2.35199 10.7457 3.18205 10.7457 4.20599C10.7457 5.22993 9.91563 6.05999 8.8917 6.05999C7.86776 6.05999 7.0377 5.22993 7.0377 4.20599Z" />
    </svg>
  </SvgIcon>
)
export default ImageSmall
