import * as React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core'
import type { DropdownSelectProps } from '../../../DropdownSelect'
import DropdownSelect from '../../../DropdownSelect'
import * as moment from 'moment-timezone'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      ...theme.typography.body1,
      borderRadius: theme.typography.pxToRem(0)
    },
    ':hover': {
      borderColor: theme.palette.primary.main
    },
    title: {
      marginBottom: theme.typography.pxToRem(8)
    },
    select: {
      borderRadius: theme.typography.pxToRem(0),
      borderColor: theme.palette.primary.main
    }
  })

export interface TimezoneSelectProps
  extends WithStyles<typeof styles>,
    Omit<DropdownSelectProps, 'classes' | 'value' | 'onChange' | 'options'> {
  value: string
  onChange?: (timezone: any) => void
}

export interface TimezoneSelectState {
  value: string
  label: string
}

const timeZone = moment.tz.names()
const tzList = timeZone.map((tz) => ({
  value: tz,
  label: tz
}))

class TimezoneSelect extends React.PureComponent<TimezoneSelectProps, TimezoneSelectState> {
  constructor(props: TimezoneSelectProps) {
    super(props)

    this.state = {
      value: this.props.value,
      label: this.props.value
    }
  }

  public handleChange = (timezone: any) => {
    this.setState({ value: timezone.value, label: timezone.label })
    if (this.props.onChange) {
      this.props.onChange(timezone)
    }
  }

  public render() {
    const { classes, value, required, ...baseSelectProps } = this.props

    return (
      <DropdownSelect
        {...baseSelectProps}
        value={this.state}
        options={tzList}
        fullWidth={true}
        onChange={this.handleChange}
        searchable={true}
      />
    )
  }
}

export default withStyles(styles)(TimezoneSelect)
