import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  LinearProgress,
  SplitDialog,
  Typography
} from '@ui/stickybid'
import type { DialogProps } from '@ui/stickybid'
import { PRESENTATION_LABEL } from '@paintscout/util'

const useStyles = makeStyles<Theme, IntroToEditorDialogProps>((theme) => ({
  root: {},
  dialogPaper: {
    minHeight: 469 // make the new photos all the same height and slide it here
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      marginBottom: theme.spacing(1)
    }
  },
  bottomContent: {
    marginTop: 'auto'
  },
  stepCount: {
    marginLeft: 'auto'
  },
  progressBar: {
    height: '6px',
    margin: theme.spacing(1, 0)
  },
  title: {
    margin: theme.spacing(1, 0)
  }
}))

export interface IntroToEditorDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  hideDismissCheckbox?: boolean
  onCancel: () => void
}

function IntroToEditorDialog(props: IntroToEditorDialogProps) {
  const classes = useStyles(props)
  const { open, onCancel, hideDismissCheckbox, ...dialogProps } = props
  const [step, setStep] = useState(0)
  const [checked, setChecked] = useState(window.localStorage.getItem('isIntroToEditorDialogDismissed') === 'true')
  const totalSteps = 4

  const handleNext = () => {
    if (step + 1 === totalSteps) {
      onCancel()
    } else setStep(step + 1)
  }

  const imgSrc = () => {
    switch (step) {
      case 0:
        return 'https://tpr-sb-static.s3.amazonaws.com/Welcome+Dialog/Welcome.gif'
      case 1:
        return 'https://tpr-sb-static.s3.amazonaws.com/Welcome+Dialog/EditPages.gif'
      case 2:
        return 'https://tpr-sb-static.s3.amazonaws.com/Welcome+Dialog/AddPages.gif'
      case 3:
        return 'https://tpr-sb-static.s3.amazonaws.com/Welcome+Dialog/Style.gif'
    }
  }

  const rightContent = (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <img
        key={imgSrc()}
        src={imgSrc()}
        alt={'helpful gif'}
        style={{
          height: 'auto',
          maxHeight: '100%',
          maxWidth: '100%',
          verticalAlign: 'top',
          width: 'auto'
        }}
      />
    </div>
  )
  const handleCheckbox = () => {
    if (window.localStorage.getItem('isIntroToEditorDialogDismissed') === 'true' || checked) {
      window.localStorage.setItem('isIntroToEditorDialogDismissed', 'false')
      setChecked(false)
    } else {
      window.localStorage.setItem('isIntroToEditorDialogDismissed', 'true')
      setChecked(true)
    }
  }

  return (
    <SplitDialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      fullWidth
      maxWidth={'md'}
      rightContent={rightContent}
      onCancel={onCancel}
    >
      <DialogContent classes={{ root: classes.contentRoot }}>
        <LinearProgress
          id="content-top"
          className={classes.progressBar}
          variant={'determinate'}
          value={((step + 1) / totalSteps) * 100}
        />
        <Typography variant={'h2'} className={classes.title}>
          {handleDialogTitle({ step })}
        </Typography>
        {step === 0 && <Welcome classes={classes} />}
        {step === 1 && <EditBlocks classes={classes} />}
        {step === 2 && <AddBlocks classes={classes} />}
        {step === 3 && <Style classes={classes} />}
        <div className={classes.bottomContent}>
          {!hideDismissCheckbox && (
            <FormControlLabel
              label={'Don’t show this again'}
              control={<Checkbox checked={checked} onChange={handleCheckbox} />}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={() => setStep(step - 1)} prominence={3} disabled={step === 0}>
            {'< Back'}
          </Button>
        }
      >
        <Button onClick={handleNext} prominence={1}>
          {step === totalSteps - 1 ? 'Got It!' : 'Next'}
        </Button>
      </DialogActions>
    </SplitDialog>
  )
}

export default IntroToEditorDialog

function handleDialogTitle(props: { step: number }) {
  const { step } = props
  switch (step) {
    case 0:
      return `Welcome to Your ${PRESENTATION_LABEL}`
    case 1:
      return 'Edit Blocks & Sections'
    case 2:
      return 'Add Blocks'
    case 3:
      return `Style Your ${PRESENTATION_LABEL}`
  }
}

function Welcome(props: { classes: StyleClasses<typeof useStyles> }) {
  const { classes } = props
  return (
    <>
      <Typography variant={'body1'}>Here are a few tips to help you get started.</Typography>
      <Typography variant={'body1'}>
        Click on a section (look for the blue outline) and then click on text to make edits.
      </Typography>
    </>
  )
}

function EditBlocks(props: { classes: StyleClasses<typeof useStyles> }) {
  const { classes } = props
  return (
    <>
      <Typography variant={'body1'}>
        {`Your ${PRESENTATION_LABEL} is made up of blocks. Each block contains one or more sections.`}
      </Typography>
      <Typography variant={'body1'}>
        Click on the pencil icon in the top right corner of a section to access its options.
      </Typography>
    </>
  )
}

function AddBlocks(props: { classes: StyleClasses<typeof useStyles> }) {
  const { classes } = props
  return (
    <>
      <Typography variant={'body1'}>
        Add a new block by clicking on the plus icon, found on the outline of the first and last sections in a block.
      </Typography>
    </>
  )
}

function Style(props: { classes: StyleClasses<typeof useStyles> }) {
  const { classes } = props

  return (
    <>
      <Typography variant={'body1'}>
        {`Select Style in the sidebar to edit your ${PRESENTATION_LABEL}'s typography and palette.`}
      </Typography>
      <Typography variant={'body1'}>{`Changes made here will affect your entire ${PRESENTATION_LABEL}.`}</Typography>
    </>
  )
}
