import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { MenuProps as MuiMenuProps } from '@material-ui/core'
import { Menu as MuiMenu } from '@material-ui/core'

const useStyles = makeStyles<Theme, MenuProps>(
  (theme) => ({
    paper: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.borderRadius['md'],
      boxShadow: 'none'
    }
  }),
  { name: 'Menu' }
)

export interface MenuProps extends MuiMenuProps {
  classes?: StyleClasses<typeof useStyles>
}

function Menu(props: MenuProps) {
  const classes = useStyles(props)
  const { children, ...rest } = props

  return (
    <MuiMenu {...rest} classes={classes}>
      {children}
    </MuiMenu>
  )
}

export default Menu
