/**
 * @module builder
 */
import type { QuoteOptions, QuoteArea } from 'paintscout'
import type { RenderableSubItem, UpdateableAreaSubstrate } from '../../../../index'
import { getItemDetails } from '../../util/get-item-details'
import get from 'lodash/get'
import getDimensions from './get-dimensions'
import { getCrewDescription } from '../../util/get-crew-description'

/**
 * Convert a UpdateableAreaSubstrate and QuoteArea to a RenderableSubItem
 * to be viewed by crew within substrate on WorkOrders
 */
export default function toRenderableCrewSubstrateArea({
  key,
  area,
  item,
  showValues,
  options
}: {
  key: string
  area: QuoteArea
  item: UpdateableAreaSubstrate
  showValues: boolean
  options: QuoteOptions
}): RenderableSubItem {
  const { prep, hours, price, value, rateItemLabel, rateClientLabel } = getItemDetails({
    item
  })

  const { crewDescription } = getCrewDescription({
    prep,
    hours,
    itemName: rateItemLabel,
    clientLabel: rateClientLabel,
    showValues,
    product: item.product,
    options,
    substrate: true
  })

  let name = area?.area_label?.value ?? rateItemLabel
  const rateType = item?.rate?.rate_type ?? item?.rate?.rateType ?? ''
  const areaType = get(area, 'area_type.value', 'room')
  const reportText = item?.rate?.reportText ?? ''

  const { label: dimensionLabel, value: dimensionValue } = getDimensions({ rateType, areaType, item, area })

  const dimensionString = [dimensionValue, dimensionLabel].join('')
  if (dimensionString) {
    name = `${name}${dimensionString === '0' ? '' : ` (${dimensionString})`}`
  }

  return {
    key,
    name,
    description: crewDescription,
    dimension: {
      label: dimensionLabel,
      value: dimensionValue
    },
    reportText,
    crewNote: item.notes,
    value,
    additionalFields: {
      prep,
      painting: hours,
      price
    }
  }
}
