import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import ContactCard from '../ContactCard'
import AddressCard from '../AddressCard'
import QuoteDateCard from '../QuoteDateCard/QuoteDateCard'
import QuoteHeaderLogo from '../QuoteHeaderLogo'
import QuoteIDCard from '../QuoteIDCard'
import QuoteStatusCard from '../QuoteStatusCard'
import QuoteTotalCard from '../QuoteTotalCard'

import { Grid, Typography, useClientOptions } from '@ui/stickybid'
import EstimatorCard from '../EstimatorCard'
import CompanyCard from '../CompanyCard'
import EditIcon from '@material-ui/icons/Edit'
import { Hidden } from '@material-ui/core'

import { usePresentationNav } from '../../../presentation'
import { getPresentationLogo } from '@paintscout/util/builder'
import { useQuote } from '../../../context'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4)
    },
    companyBlock: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    editOcrBlock: {
      marginLeft: 'auto',
      position: 'relative'
    },
    editOcrBlockEditable: {
      cursor: 'pointer',
      '&:hover': {
        '& div': {
          color: `${theme.palette.common.black} !important`
        },
        '& $editIcon': {
          color: theme.palette.primary.main
        },
        '& $noImportedDataTitle': {
          color: theme.palette.primary.main
        }
      }
    },
    editIcon: {
      color: theme.palette.secondary.main,
      position: 'absolute',
      right: -36,
      bottom: 3,
      fontSize: '1rem'
    },
    noImportedDataTitle: {
      color: theme.palette.secondary.main,
      display: 'block',
      marginTop: theme.spacing(1)
    }
  })

export interface QuoteHeaderProps extends WithStyles<typeof styles> {
  isEditable?: boolean
  workOrder?: boolean
  className?: string
}

function QuoteHeaderMobile({ classes, workOrder, className, ...props }: QuoteHeaderProps) {
  const { quote, onImportQuoteImage, isEditable } = useQuote()
  const { options } = useClientOptions()
  const { presentation } = usePresentationNav()
  const { logo } = getPresentationLogo({ options, quote, presentation })
  const showEstimatorCard = quote.owner || (isEditable && !presentation)

  const isTemplate = quote.type === 'quoteTemplate'

  return (
    <div className={`${classes.root} ${className}`} id="quote-header-mobile">
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.companyBlock}>
          {logo && <QuoteHeaderLogo logo={logo} />}
        </Grid>
        <Grid item xs={6}>
          {!isTemplate && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <QuoteStatusCard workOrder={workOrder} />
              </Grid>
              <Grid item xs={12}>
                <QuoteIDCard />
              </Grid>
              <Grid item xs={12}>
                <QuoteDateCard />
              </Grid>
              {!!quote.importedContent?.primary?.src && (
                <div
                  className={classnames(classes.editOcrBlock, {
                    [classes.editOcrBlockEditable]: isEditable && !presentation
                  })}
                  onClick={isEditable && !presentation ? handleEditOcrFields : null}
                >
                  <Hidden smDown>{isEditable && !presentation && <EditIcon className={classes.editIcon} />}</Hidden>
                  {isEditable && !presentation && !quote.job_identifier && !quote.totalAdjustment?.rate && (
                    <Typography className={classes.noImportedDataTitle} variant={'overline'}>
                      Edit Imported Data
                    </Typography>
                  )}
                  <Grid item xs={12}>
                    <QuoteIDCard importedId />
                  </Grid>
                  <Grid item xs={12}>
                    <QuoteTotalCard />
                  </Grid>
                </div>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={6}>
          <CompanyCard />
        </Grid>
        {showEstimatorCard ? (
          <Grid item xs={6}>
            <EstimatorCard />
          </Grid>
        ) : (
          <Grid item xs={6}></Grid>
        )}

        <Grid item xs={6}>
          {!isTemplate && <ContactCard />}
        </Grid>
        <Grid item xs={6}>
          <AddressCard type="billingAddress" />
        </Grid>
      </Grid>
    </div>
  )

  function handleEditOcrFields(ev: React.MouseEvent) {
    onImportQuoteImage({ edit: true })
  }
}

export default withStyles(styles)(QuoteHeaderMobile)
