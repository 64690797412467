import React from 'react'
import {
  Alert,
  Collapse,
  FormikDropdownSelect,
  FormikInputField,
  FormSection,
  FormSectionTitle,
  Grid,
  HtmlEditor,
  PageHelpMessage,
  SwitchField,
  Typography
} from '@ui/stickybid'
import { getObjectLabels, hasIntegrationInterface } from '@paintscout/util/builder'
import { Field, useFormikContext } from 'formik'
import type { OptionsDocument, InternalOptionsDocument, RatesDocument } from 'paintscout'

interface PaymentInvoiceOptionsProps {
  showAdvanced?: boolean
  showOnlyAdvanced?: boolean
}

function PaymentInvoiceOptions(props: PaymentInvoiceOptionsProps) {
  const { showAdvanced, showOnlyAdvanced } = props
  const { values, setFieldValue, errors } = useFormikContext<{
    options: OptionsDocument
    internalOptions?: InternalOptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values

  const quotesLabel = getObjectLabels({ options, invoice: false }).quote
  const invoicesLabel = getObjectLabels({ options, invoice: true }).quote

  const hasPayments = hasIntegrationInterface({ options, integration: 'stripe' })

  if (showOnlyAdvanced) {
    if (values?.internalOptions) {
      return (
        <>
          <FormSectionTitle
            title={`Advanced Payment Settings`}
            subTitle={`Be careful changing these values!`}
            variant={'h2'}
          />
          <FormSection>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={FormikInputField}
                  label={'Payment Fee Rate (0.005 is 0.5%, 1 is 100%)'}
                  sublabel={'The fee we add to Stripe transactions (in addition to the fee Stripe charges).'}
                  name={'internalOptions.options.integrations.stripe.feeRate'}
                  type={'number'}
                  placeholder={0.005}
                  default={0.005}
                  error={errors?.internalOptions?.options?.integrations?.stripe?.feeRate}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikDropdownSelect}
                  label={'Currency Override'}
                  sublabel={`Unless set here, Stripe will use the currency of this company's country.`}
                  name={'internalOptions.options.integrations.stripe.currency'}
                  placeholder={'None'}
                  options={[
                    { label: 'None', value: '' },
                    { label: 'USD', value: 'USD' },
                    { label: 'CAD', value: 'CAD' },
                    { label: 'AUD', value: 'AUD' }
                  ]}
                />
              </Grid>
            </Grid>
          </FormSection>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <PageHelpMessage
        path={'settings-payment-settings'}
        openContent={
          <>
            <FormSectionTitle title={`Invoice & Payment Settings`} variant={'h2'} />
            <Typography>These options reflect how customers interact with the payment process.</Typography>
          </>
        }
        closedContent={
          <>
            <FormSectionTitle
              title={`Invoice & Payment Settings`}
              variant={'h2'}
              subTitle={'These options reflect how customers interact with the payment process.'}
            />
          </>
        }
      />
      <FormSectionTitle title={'Invoices'} variant={'h2'} />

      <FormSection>
        <FormSectionTitle title={'Automate Invoicing'} variant={'h3'} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SwitchField
              name={'options.options.quotes.createInvoiceOnQuoteAccept'}
              label={`Automatically create Invoices when ${quotesLabel.plural} are accepted`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle title={'Invoice Terms'} variant={'h3'} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HtmlEditor
              classes={{}}
              value={values.options?.options?.terms?.values?.invoice?.content}
              fullWidth={true}
              bubbleMenuDisabled
              floatingMenuDisabled
              menuBarEnabled
              onChange={(content: string) => {
                setFieldValue('options.options.terms.values.invoice.content', content)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert
              content={`Changes to Invoice terms will not affect existing ${invoicesLabel.plural}.`}
              severity={'warning'}
              variant={'outlined'}
            />
          </Grid>
        </Grid>
      </FormSection>

      {(hasPayments || showAdvanced) && (
        <>
          <FormSectionTitle title={'Payments'} variant={'h2'} />
          <FormSection>
            <FormSectionTitle title={`${quotesLabel.plural}`} variant={'h3'} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SwitchField
                  name={'options.options.quotes.allowPaymentsOnQuotes'}
                  label={`Allow deposits on ${quotesLabel.plural}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse show={values.options.options.quotes.allowPaymentsOnQuotes}>
                  <Grid container spacing={2}>
                    <Grid item container justifyContent={'flex-start'} alignItems={'flex-end'} xs={12} sm={8} md={6}>
                      <Grid item xs={7}>
                        <Field
                          component={FormikInputField}
                          label="Default Deposit Amount"
                          name="options.options.quotes.depositAmount.value"
                          fullWidth
                          type="number"
                          error={errors.options?.options?.quotes?.depositAmount}
                          disabled={!values.options.options.quotes.allowPaymentsOnQuotes}
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={4} style={{ maxWidth: 100 }}>
                        <Field
                          component={FormikDropdownSelect}
                          name="options.options.quotes.depositAmount.type"
                          fullWidth
                          placeholder="%"
                          options={[
                            { label: '%', value: 'percentage' },
                            { label: '$', value: 'amount' }
                          ]}
                          disabled={!values.options.options.quotes.allowPaymentsOnQuotes}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Collapse show={values?.options?.options?.quotes?.depositAmount?.type !== 'amount'}>
                        <CheckboxField
                          name="options.options.quotes.depositAmountBeforeTax"
                          label={'Use balance due before tax when calculating the deposit amount'}
                          sublabel={'By default, the deposit amount is a percentage of the balance due after tax'}
                        />
                      </Collapse>
                    </Grid> */}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <FormSectionTitle title={`${invoicesLabel.plural}`} variant={'h3'} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SwitchField
                  name="options.options.quotes.allowPaymentsOnInvoices"
                  label={`Allow payments on ${invoicesLabel.plural}`}
                />
              </Grid>
            </Grid>
          </FormSection>
        </>
      )}
    </>
  )
}

export default PaymentInvoiceOptions
