import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { OverridableComponent } from '@material-ui/core/OverridableComponent'
import type { TypographyTypeMap as MuiTypographyTypemap } from '@material-ui/core/Typography'
import MuiTypography from '@material-ui/core/Typography'
import classnames from 'classnames'
import NumberFormat from '@ui/core/NumberFormat'

const useStyles = makeStyles<Theme, any>((theme) => {
  return {
    root: (props) => ({
      lineHeight: props?.noStyles ? null : '1.4'
    }),
    error: {
      color: theme.palette.error.main
    },
    h1Shim: (props) => ({
      marginTop: props?.noStyles
        ? null
        : `${(-1 * parseInt((theme.typography.h1.fontSize as string).replace('rem', ''))) / 8}rem`
    }),
    h2Shim: (props) => ({
      marginTop: props?.noStyles
        ? null
        : `${(-1 * parseInt((theme.typography.h2.fontSize as string).replace('rem', ''))) / 4}rem`
    })
  }
})

export interface TypographyTypeMap<P = unknown, D extends React.ElementType = 'span'>
  extends MuiTypographyTypemap<P, D> {
  props: MuiTypographyTypemap<P, D>['props'] & {
    format?: string
    value?: number
    showUnits?: boolean
    showZeros?: boolean
    noStyles?: boolean
  }
}

/**
 * Extension of Material-UI's Typography component to add formatting helpers
 *
 */
const Typography: OverridableComponent<TypographyTypeMap> = (props: any) => {
  const classes = useStyles(props)
  const { format, showUnits, showZeros, value: propsValue, ...typographyProps } = props
  const value = props.value ?? 0
  const { error, h1Shim, h2Shim, ...typographyClasses } = classes
  const className = classnames({
    [classes.root]: !!classes.root,
    [props.className]: !!props.className,
    [classes.error]: props.color === 'error',
    [classes.h1Shim]: props.variant === 'h1',
    [classes.h2Shim]: props.variant === 'h2'
  })

  return (
    <MuiTypography
      {...typographyProps}
      classes={typographyClasses}
      className={className}
      color={props.color === 'error' ? 'inherit' : props.color} // keep material-ui prop validation happy
    >
      <NumberFormat format={format} showUnits={showUnits} showZeros={showZeros} value={value}>
        {props.children}
      </NumberFormat>
    </MuiTypography>
  )
}

export default Typography
