import BroadcastChannel from 'broadcast-channel'
import LeaderElection from 'broadcast-channel/leader-election'

const channel = new BroadcastChannel('paintscout', { webWorkerSupport: false })

// reload page if there was a purge
channel.onmessage = (msg) => {
  if (msg === 'DATABASE_PURGED') {
    window.location.reload()
  }
}

export const getBroadcastChannel = () => channel
export const elector = LeaderElection.create(channel)
