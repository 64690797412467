import type { QuoteDocument, OptionsDocument, PresentationOption } from 'paintscout'
import { getFeature } from '../../../options'

import { getDefaultPresentation } from '../../../options/presentation/get-default-presentation'

export function getQuotePresentation(args: {
  options: OptionsDocument
  quote: QuoteDocument
  inactive?: boolean // return inactive presentations
  current?: boolean // return current presentations from options(sb feature)
  view?:
    | 'work-order'
    | 'product-order-form'
    | 'quote'
    | 'details'
    | 'notes'
    | 'activity'
    | 'terms'
    | 'presentation'
    | 'archived-items'
}): PresentationOption {
  const { quote, options, view, inactive, current } = args
  const presentationsFeature = getFeature({ options, path: 'presentation.enabled' })
  const currentPresentation = options.options?.presentationOptions?.values?.[quote.presentationTemplate?.key]

  if (view === 'work-order' || view === 'product-order-form') {
    // down the road - we will probably beef-up the work-order
    // to have some presentation capabilities
    // ie: maps, etc
    return getDefaultPresentation({ options, view })
  } else if (!presentationsFeature) {
    // return invoice presentation as default
    return getDefaultPresentation({ options, invoice: quote.is_invoice, view })
  } else if (current && currentPresentation?.key) {
    return currentPresentation
  } else if (quote?.presentationTemplate && inactive) {
    return quote.presentationTemplate
  } else if (quote?.presentationTemplate?.active) {
    return quote.presentationTemplate
  } else {
    return getDefaultPresentation({ options, invoice: quote.is_invoice })
  }
}
