import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const Description = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 13 8'} {...props}>
    <svg>
      <path d="M2.91206 0.982971C3.18821 0.982971 3.41206 1.20683 3.41206 1.48297V6.94697C3.41206 7.22311 3.18821 7.44697 2.91206 7.44697C2.63592 7.44697 2.41206 7.22311 2.41206 6.94697V1.48297C2.41206 1.20683 2.63592 0.982971 2.91206 0.982971Z" />
      <path d="M2.54101 6.55418C2.62562 6.49005 2.73072 6.4527 2.84297 6.4527H2.86494C2.97298 6.4527 3.07444 6.48731 3.15739 6.54714C3.57286 6.72656 4.03453 6.77404 4.47958 6.68184C4.74998 6.62583 5.0146 6.79962 5.07061 7.07002C5.12663 7.34042 4.95283 7.60504 4.68243 7.66105C4.07085 7.78775 3.43735 7.73291 2.85929 7.50575C2.30648 7.73275 1.69587 7.78758 1.10807 7.66008C0.838207 7.60155 0.66689 7.33532 0.725426 7.06546C0.783963 6.79559 1.05019 6.62427 1.32005 6.68281C1.73138 6.77203 2.15929 6.72642 2.54101 6.55418Z" />
      <path d="M2.85929 1.04701C2.30709 0.81993 1.69677 0.763742 1.10856 0.89071C0.838639 0.948975 0.667054 1.21503 0.72532 1.48495C0.783585 1.75488 1.04964 1.92646 1.31956 1.8682C1.73059 1.77947 2.15862 1.82585 2.54085 1.99856C2.62549 2.06275 2.73065 2.10016 2.84297 2.10016H2.86494C2.97305 2.10016 3.07458 2.0655 3.15756 2.00559C3.57351 1.82573 4.03528 1.77744 4.48003 1.86915C4.75048 1.92492 5.01494 1.75088 5.0707 1.48043C5.12647 1.20997 4.95243 0.945521 4.68198 0.889755C4.07001 0.763568 3.43676 0.819735 2.85929 1.04701Z" />
      <path d="M11.0717 6.81696C11.0144 6.70237 10.9679 6.49827 10.9321 6.20465C10.4702 6.68447 9.91874 6.92438 9.27779 6.92438C8.70487 6.92438 8.234 6.76324 7.86519 6.44098C7.49995 6.11513 7.31734 5.70335 7.31734 5.20563C7.31734 4.60048 7.5465 4.13141 8.00484 3.7984C8.46675 3.46181 9.11486 3.29352 9.94917 3.29352H10.916V2.83698C10.916 2.48964 10.8121 2.21393 10.6044 2.00983C10.3968 1.80214 10.0906 1.6983 9.68599 1.6983C9.3315 1.6983 9.0343 1.78782 8.79439 1.96686C8.55448 2.14589 8.43452 2.36253 8.43452 2.61676H7.4355C7.4355 2.32672 7.53755 2.04742 7.74165 1.77887C7.94934 1.50673 8.22863 1.29189 8.57954 1.13434C8.93404 0.976786 9.32255 0.89801 9.74507 0.89801C10.4147 0.89801 10.9392 1.0663 11.3188 1.40289C11.6984 1.7359 11.8953 2.19602 11.9096 2.78326V5.45807C11.9096 5.9916 11.9777 6.41591 12.1137 6.73102V6.81696H11.0717ZM9.42281 6.05963C9.73433 6.05963 10.0297 5.97906 10.309 5.81793C10.5883 5.6568 10.7906 5.44733 10.916 5.18951V3.99713H10.1372C8.91971 3.99713 8.31099 4.35341 8.31099 5.06598C8.31099 5.3775 8.41483 5.62099 8.62251 5.79645C8.8302 5.9719 9.09696 6.05963 9.42281 6.05963Z" />
    </svg>
  </SvgIcon>
)
export default Description
