import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { getPresentationContentStyles } from '../styles'

import React from 'react'

export interface PageHelpMessageProps extends WithStyles<typeof styles> {
  openContent?: React.ReactNode | string
  closedContent?: React.ReactNode | string
  title?: string
  path: string
  align?: 'left' | 'center'
  hideClose?: boolean
  learnMoreLink?: string
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    content: {
      ...getPresentationContentStyles(theme),
      '& a': {
        ...theme.typography.button,
        fontSize: '0.9em',
        color: theme.palette.grey[700],
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        margin: `0 ${theme.spacing(1)}px`,
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    heading: {
      alignItems: 'flex-end',
      '& p': {
        // maxWidth: '35em'
      }
    }
  })

const PageHelpMessage = ({ closedContent, path, hideClose, learnMoreLink, ...props }: PageHelpMessageProps) => {
  // Disabled in StickyBid for MVP
  return <>{closedContent}</>

  // const { classes } = props

  // const { isTrial } = useUser()
  // const { options } = useClientOptions()

  // const localStoragePath = `hidePageMessage-${path}`

  // useLayoutEffect(() => {
  //   setHeadingOpen(!JSON.parse(window.localStorage.getItem(localStoragePath)))
  // }, [localStoragePath])

  // const [headingOpen, setHeadingOpen] = useState(false)

  // if (!isTrial) {
  //   return <>{closedContent}</>
  // }

  // const title = props.title ? (
  //   <Typography variant="h4" color="inherit">
  //     {props.title}
  //   </Typography>
  // ) : null

  // let openContent = null

  // if (typeof props.openContent === 'string') {
  //   const demoMessageLines = props.openContent.split('\n')

  //   openContent = (
  //     <>
  //       {demoMessageLines.map((line, index) => {
  //         return <HtmlContent content={line} key={index} />
  //       })}
  //     </>
  //   )
  // } else {
  //   openContent = props.openContent
  // }

  // if (headingOpen) {
  //   return (
  //     <Alert icon={null} severity={'info'} className={classes.heading} onClose={hideClose ? null : handleClose}>
  //       <div className={classes.content}>
  //         {title}
  //         {openContent}
  //         {learnMoreLink && <LearnMoreLink link={learnMoreLink} />}
  //       </div>
  //     </Alert>
  //   )
  // } else {
  //   return <>{closedContent}</>
  // }

  function handleClose() {
    // setHeadingOpen(false)
    // window.localStorage.setItem(localStoragePath, JSON.stringify(true))
  }
}

export default withStyles(styles)(PageHelpMessage)
