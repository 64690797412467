import { gql } from 'apollo-boost'
import type { ClientMetaDocument } from 'paintscout'

export const SEARCH_CLIENTS = gql`
  query searchClients($query: String!, $bookmark: String, $limit: Int, $sort: [String!]) {
    searchClients(query: $query, bookmark: $bookmark, limit: $limit, sort: $sort) {
      bookmark
      total_rows
      rows {
        _id
        name
        city
        province
        country
        email
        active
        phone
        address
        product
        parentCompanyId
        billingId
        nonBilling
        trialExpiry
        userCounts {
          all
        }
        paymentExpiryNotice
        subscription {
          plan_id
          plan_price_id
          current_term_start
          current_term_end
          status
        }
        aggregates {
          totalQuotes
          totalBidValue
          averageBidValue
          closeRate
          closedQuotes
          closedValue
          averageCloseValue
        }
        plan
        notes
        delete
      }
    }
  }
`

export interface SearchClientsResponse {
  searchClients: {
    total_rows: number
    rows: Partial<ClientMetaDocument>[]
    bookmark: string
  }
}
