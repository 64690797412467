import React from 'react'
import { default as MuiSlider } from '@material-ui/core/Slider'
import type { SliderProps as MuiSliderProps } from '@material-ui/core/Slider'

export interface SliderProps extends MuiSliderProps {}

function Slider(props: SliderProps) {
  return <MuiSlider {...props} />
}

export default Slider
