import React from 'react'
import { Redirect } from 'react-router'
import { AuthCallback } from 'react-auth0'
import AppLoading from '../components/AppLoading'

/**
 * After auth0 authenticates it will redirect here
 */
function LoginCallback() {
  return (
    <AuthCallback>
      {({ loading, error, previousUrl }) => {
        if (error) {
          console.error(error)
          return <Redirect to={'/logout'} />
        }

        if (loading) {
          return <AppLoading />
        }

        return <Redirect to={previousUrl || '/'} />
      }}
    </AuthCallback>
  )
}

export default LoginCallback
