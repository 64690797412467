import React from 'react'
import type { FieldProps } from 'formik'
import type { DropdownSelectProps, SingleSelectOption } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'

export interface FormikDropdownSelectProps extends FieldProps<any>, Omit<DropdownSelectProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikDropdownSelect = ({ field, form, ...props }: FormikDropdownSelectProps) => {
  const value = props.options.find((option: SingleSelectOption) => option.value === field.value) || props.options[0]

  const onChange = (option: SingleSelectOption) => {
    return field.onChange({ target: { name: field.name, value: option.value } })
  }

  return <DropdownSelect {...field} {...props} value={value} onChange={onChange} />
}

export default FormikDropdownSelect
