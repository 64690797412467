import type { PresentationIsEmptyProps } from '../../'
export default function isEmpty({ section }: PresentationIsEmptyProps) {
  type Link = { href: string; label: string }
  const links: Link[] = section?.fields?.links

  if (links == null || links.length === 0) {
    return true
  }

  let urlSuperString = ''

  links.forEach((link) => {
    urlSuperString += link.href
  })

  if (urlSuperString === '') {
    return true
  }

  return false
}
