import React from 'react'
import type { Theme } from '@material-ui/core/styles'

import { Fade, makeStyles } from '@material-ui/core'
import Grid from '../../Grid'
import Typography from '../../Typography'
import type { DialogProps } from '../Dialog'
import { Spinner } from '@ui/core'

import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles<Theme, DialogTitleProps>(
  (theme) => ({
    root: (props) => ({
      ...theme.typography.h2,
      position: 'relative' as const,
      height: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2, 4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 3, 0, 3)
      },
      color: props?.color && props?.color !== 'default' ? theme.palette[props?.color].main : null
    }),
    icon: {
      display: 'flex',
      alignItems: 'center'
    },
    loading: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 30
    },
    rightContentWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-start'
    },
    title: {
      paddingBottom: '0px'
    },
    titleItem: {}
  }),
  { name: 'DialogTitle' }
)

export interface DialogTitleProps extends Omit<DialogProps, 'open'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  children?: React.ReactNode
  rightContent?: React.ReactNode
  rightContentSecondary?: React.ReactNode
  loading?: boolean
  icon?: React.ReactNode
  color?: 'warning' | 'success' | 'error' | 'default'
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles(props)
  const { rightContent, rightContentSecondary } = props

  return (
    <div className={classes.root}>
      <Grid container spacing={0} alignItems={'center'}>
        {props.icon && (
          <Grid item className={classes.icon}>
            {props.icon}
          </Grid>
        )}
        <Grid item className={classes.titleItem}>
          <Typography variant="h2">{props.children}</Typography>
        </Grid>
      </Grid>
      <div className={classes.rightContentWrapper}>
        {rightContentSecondary && (
          <Fade
            in={!props?.loading}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '0.5em',
              transitionDelay: props.loading ? '800ms' : '0ms',
              visibility: !props.loading ? 'visible' : 'hidden'
            }}
            aria-hidden={props?.loading}
          >
            <div>{rightContentSecondary}</div>
          </Fade>
        )}
        {rightContent && (
          <Fade
            in={!props?.loading}
            style={{
              display: 'flex',
              transitionDelay: props?.loading ? '800ms' : '0ms',
              visibility: !props?.loading ? 'visible' : 'hidden'
            }}
            aria-hidden={props?.loading}
          >
            <div>{rightContent}</div>
          </Fade>
        )}
      </div>
      <div className={classes.loading}>
        <Fade
          in={props?.loading}
          style={{
            transitionDelay: props?.loading ? '800ms' : '0ms'
          }}
          unmountOnExit
        >
          <Spinner size={15} fullHeight fullWidth />
        </Fade>
      </div>
    </div>
  )
}

export default DialogTitle
