/**
 * @module builder
 */
import type { PresentationPage, PresentationOption } from 'paintscout'

export function updatePresentationPage(args: {
  newPage: PresentationPage
  presentation: PresentationOption
}): PresentationOption {
  const { presentation, newPage } = args
  const index = presentation.pages.findIndex((page) => page.key === newPage.key)
  presentation.pages.splice(index, 1, newPage)

  return presentation
}
