import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import Typography from '../Typography'

import Switch from '../Switch'

import Spinner from '../Spinner'

import { Divider } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: (props: FormSectionTitleProps) => {
        if (props.variant === 'h3') {
          return theme.spacing(3)
        } else if (props.variant === 'h2') {
          return theme.spacing(2.5)
        } else {
          return theme.spacing()
        }
      }
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'space-between',
      // alignItems: 'flex-end'
    },

    leftContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    firstRow: {
      display: 'flex',
      flexDirection: 'row'
    },
    rightContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing()
    },
    toggle: {
      '@media print': {
        display: 'none'
      }
      // transform: 'scaleX(0.75) scaleY(0.75) translateX(0.75em)',
      // marginLeft: '-0.75em'
    },
    title: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: theme.spacing(),
      alignItems: 'center'
    },
    subtitle: {
      // marginTop: theme.spacing(2.5)
    },
    pointer: {
      cursor: 'pointer'
    },
    divider: {
      height: 1,
      backgroundColor: '#000',
      marginTop: theme.spacing(2.5)
    }
  })

type DivProps = JSX.IntrinsicElements['div']
export interface FormSectionTitleProps extends DivProps {
  title?: string
  subTitle?: React.ReactNode
  showToggle?: boolean
  loading?: boolean
  toggleTestId?: string
  toggleValue?: boolean
  showDivider?: boolean
  onToggle?: (ev: React.MouseEvent, checked?: boolean) => void
  rightContent?: React.ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'overline'
}

type FormSectionTitlePropsWithInjections = FormSectionTitleProps & WithStyles<typeof styles>

/**
 * Renders a div with a Divider above it
 */
const FormSectionTitle = (props: FormSectionTitlePropsWithInjections) => {
  const {
    title,
    subTitle,
    showToggle,
    loading,
    toggleTestId,
    showDivider,
    onToggle,
    toggleValue,
    rightContent,
    classes,
    style
  } = props
  const variant = props.variant ? props.variant : 'h3'

  return (
    <div className={classes.root}>
      <div className={classes.leftContent}>
        <div className={classes.firstRow}>
          {title && (
            <>
              <Typography
                variant={variant}
                color="inherit"
                gutterBottom={false}
                onClick={(ev) => {
                  if (showToggle && onToggle) {
                    onToggle(ev, !toggleValue)
                  }
                }}
                style={style}
                classes={{
                  root: classnames({
                    [classes.title]: true,
                    [classes.pointer]: showToggle
                  })
                }}
              >
                {title}
                {showToggle && (
                  <Switch checked={toggleValue} classes={{ root: classes.toggle }} data-testid={toggleTestId} />
                )}
              </Typography>
              {loading && <Spinner size={15} />}
            </>
          )}
        </div>

        {subTitle && (
          <Typography variant="subtitle1" gutterBottom={false} className={classes.subtitle}>
            {subTitle}
          </Typography>
        )}
        {showDivider && <Divider className={classes.divider} />}
      </div>
      {rightContent && <div className={classes.rightContent}>{rightContent}</div>}
    </div>
  )
}

export default withStyles(styles)(FormSectionTitle)
