import { useEffect } from 'react'
import { useAuth } from 'react-auth0'

function Logout() {
  const { logout } = useAuth()

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: process.env.PS_ADMIN_URL
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Logout
