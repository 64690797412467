import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { ActionButtonProps } from '@ui/stickybid'
import { ActionButton } from '@ui/stickybid'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: -1 * theme.spacing(),
    color: theme.palette.secondary.main
  }
}))

/**
 * A styled ActionButton to be aligned properly with the table cell
 */
export default function ActionColumn(props: ActionButtonProps) {
  const classes = useStyles({})

  return (
    <ActionButton
      {...props}
      className={classes.root}
      onActionClick={(ev, action) => {
        props.onActionClick(ev, action)
      }}
    />
  )
}
