import React, { useState } from 'react'
import type { StyleClasses } from '@ui/core/theme'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'
import { ImageSmallIcon } from '@ui/core/icons'
import { useFormik } from 'formik'

import type { DialogProps } from '@ui/stickybid'
import { useClientOptions } from '@ui/stickybid'
import {
  Button,
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogStackContext,
  DialogTitle,
  EyeballToggle,
  FileDropArea,
  Grid,
  Spinner,
  Typography,
  useFileDropArea,
  useMediaQuery,
  ViewMediaDialog
} from '@ui/stickybid'

import { CloseButton } from '../common'
import FilesGrid from '../../FilesGrid'
// import EditItemFilesDialog from '../EditItemFilesDialog'

import { useSnackbar } from 'notistack'
import type { UpdateableLineItem, QuoteItemSection } from '@paintscout/util/builder'
import {
  createLineItem,
  formatCurrency,
  getFilesForItem,
  updateFilesForItem,
  updateLineItem
} from '@paintscout/util/builder'
import { fixToString } from '@paintscout/util/calculator'
import numeral from 'numeral'
import type { QuoteDocument, QuoteFile } from 'paintscout'
import { ItemForm } from '../../forms'
import classnames from 'classnames'

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {},
    loadingOverlay: {
      alignItems: 'center',
      background: '#ddd',
      border: '2px dashed #aaa',
      borderRadius: '3px',
      content: "' '",
      display: 'flex',
      height: 128,
      justifyContent: 'center',
      position: 'absolute',
      width: 128,
      zIndex: 50
    },
    filesGridLoading: {
      visibility: 'hidden'
    },
    toggleLabel: {
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '.9rem'
      }
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    footerRight: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center'
    },
    footerPrice: {
      margin: theme.spacing(0, 2),
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 1)
      }
    },
    totalLabel: {
      display: 'inline-block'
    },
    totalValue: {
      display: 'inline-block',
      fontWeight: 600,
      marginLeft: theme.spacing(1)
    },
    quantPricing: {
      display: 'inline-block',
      marginLeft: theme.spacing(1)
    },
    hideCloseButton: {
      display: 'none'
    }
  }),
  { name: 'EditItemDialog' }
)
export interface EditItemDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  quote?: QuoteDocument
  item?: UpdateableLineItem
  section?: QuoteItemSection

  onConfirm?: (updatedQuote?: QuoteDocument) => any
  onCancel?: (event: React.MouseEvent<HTMLElement>) => any
}

export const handleValidate = (values) => {
  const errors: Record<string, string> = {}

  if (values.label.length < 1) {
    errors.label = 'Name is required'
  }
  // const taxRateNumber = values.taxRate?.split(' ').pop()
  // if (isNaN(taxRateNumber)) {
  //   errors.taxRate = 'Must be a number, or follow the pattern "GST 5%"'
  // }

  return errors
}

/** Edit an existing item or create a new item on a quote. Custom tax rates are omitted for stickybid V1 */
function EditItemDialog({ quote, item, section, onCancel, onConfirm, ...props }: EditItemDialogProps) {
  const classes = useStyles(props)
  const [editableQuote, setEditableQuote] = useState<QuoteDocument>(quote)
  const [hideCloseButton, setHideCloseButton] = useState(false)
  const xsDown = useMediaQuery('xs')

  const { openDialog, dismissDialog } = React.useContext(DialogStackContext)
  const { enqueueSnackbar } = useSnackbar()
  const { options } = useClientOptions()

  const { values, setFieldValue, isSubmitting, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: item ?? createLineItem({ calculate: true, calculateBy: 'quantity' }),
    validate: handleValidate,
    onSubmit: (values) => {
      const updatedQuote = updateLineItem({
        quote: editableQuote,
        options,
        item: { ...values, section: values.section ?? 'bid' }
      })

      onConfirm(updatedQuote)
    }
  })

  // get the files (media) that are still on the quote
  // editableQuote.lineItems[editableItem.key].files is up to date with values from Formik below
  const files = getFilesForItem({ quote: editableQuote, options, itemKey: values.key })
    .map((file) => {
      if (editableQuote.lineItems[values.key]?.files.indexOf(file.key) > -1) return file
    })
    .filter((file) => !!file)

  const handleSwitch = (target: string) => {
    setFieldValue(target, !values[target])
  }
  const fromPdfImport = values.fromPdfImport
  const isPdf = files[0]?.format === 'pdf'

  const handleMediaDeleteClick = (
    values: UpdateableLineItem,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    fileIndex: number
  ) => {
    openDialog(ConfirmationDialog, {
      message: "Are you sure you'd like to remove media from this item?",
      noLabel: 'Cancel',
      title: 'Remove Media',
      onConfirm: () => {
        const newFiles = [...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]
        const newFileKeys = newFiles.map((file) => file.key)

        setEditableQuote(updateFilesForItem({ quote: editableQuote, options, itemKey: values.key, files: newFiles }))
        setFieldValue('files', newFileKeys)

        enqueueSnackbar('Successfully removed Media', { variant: 'success' })
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  // const handleEditMediaClick = (
  //   values: UpdateableLineItem,
  //   setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  //   files?: QuoteFile[],
  //   index?: number,
  //   uploadFiles?: File[]
  // ) => {
  //   openDialog(EditItemFilesDialog, {
  //     files,
  //     index,
  //     uploadFiles,
  //     onConfirm: (updatedFiles) => {
  //       // updateLineItem creates the lineItem in the quote, updateFilesForItem updates the quote's files prop
  //       const quoteWithNewItem = updateLineItem({ quote: editableQuote, options, item: values })
  //       setEditableQuote(
  //         updateFilesForItem({ quote: quoteWithNewItem, options, itemKey: values.key, files: updatedFiles })
  //       )
  //       setFieldValue(
  //         'files',
  //         updatedFiles.map((file) => file.key)
  //       )
  //       dismissDialog()
  //     },
  //     onCancel: dismissDialog
  //   })
  // }

  const handleReorder = (
    values: UpdateableLineItem,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    files: QuoteFile[]
  ) => {
    setFieldValue(
      'files',
      files.map((file) => file.key)
    )
    setEditableQuote(updateFilesForItem({ quote: editableQuote, options, itemKey: values.key, files }))
  }

  const handleAddFiles = (
    values,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    newFiles: QuoteFile[]
  ) => {
    // Altered for SB single file on line item
    // const existingFiles = getFilesForItem({ quote: editableQuote, itemKey: values.key })
    // const updatedFiles = [...existingFiles, ...newFiles]
    const updatedFiles = [...newFiles]
    setEditableQuote(updateFilesForItem({ quote: editableQuote, options, itemKey: values.key, files: updatedFiles }))

    const newFileKeys = updatedFiles.map((file) => file.key)
    setFieldValue('files', newFileKeys)
  }

  const {
    open: openDropArea,
    getInputProps,
    loading
  } = useFileDropArea({
    multiple: false,
    onUpload: (newFiles) => {
      // Altered for SB single file on line item
      // const existingFiles = values?.key ? getFilesForItem({ quote: editableQuote, itemKey: values.key }) : []
      // const updatedFiles = [...existingFiles, ...newFiles]
      const updatedFiles = [...newFiles]

      const quoteWithNewItem = updateLineItem({ quote: editableQuote, options, item: values })
      setEditableQuote(
        updateFilesForItem({ quote: quoteWithNewItem, options, itemKey: values.key, files: updatedFiles })
      )

      const newFileKeys = updatedFiles.map((file) => file.key)
      setFieldValue('files', newFileKeys)
    }
  })

  const handleMediaViewClick = () => {
    setHideCloseButton(true)
    openDialog(ViewMediaDialog, {
      title: values.name,
      index: 0,
      files: [files[0]],
      onClose: () => {
        setHideCloseButton(false)
        dismissDialog()
      }
    })
  }

  const mediaComponent = (
    <Grid item xs={12} style={{ position: 'relative' }}>
      {loading && (
        <div className={classes.loadingOverlay}>
          <Grid item container spacing={2} alignItems="center" justify="center">
            <Grid item>
              <Spinner size={10} />
            </Grid>
            <Grid item>
              <Typography variant="overline">Uploading...</Typography>
            </Grid>
          </Grid>
        </div>
      )}
      <div
        className={classnames({
          [classes.filesGridLoading]: loading
        })}
      >
        <FilesGrid
          columns={1}
          editable={true}
          files={files[0] ? [files[0]] : []}
          onDelete={loading || fromPdfImport ? null : (index) => handleMediaDeleteClick(values, setFieldValue, index)}
          onFileClick={fromPdfImport || isPdf ? () => handleMediaViewClick() : () => openDropArea()}
          onReorder={(reorderedFiles) => handleReorder(values, setFieldValue, reorderedFiles)}
          onUploadClick={files.length > 0 ? null : () => openDropArea()}
          showBorder={false}
          showDetails={false}
          filePreviewProps={{ showFirstPageOnly: true }}
        />
      </div>
    </Grid>
  )

  const totalValue = formatCurrency({ value: calculateTotal(values) })
  let quantPricing = `(${numeral(values.quantity).format('0,0[.]00') || 0} unit${
    values.quantity !== 1 ? 's' : ''
  } @ ${formatCurrency({
    value: values.pricePerUnit
  })}) =`

  if (xsDown)
    quantPricing = `(${numeral(values.quantity).format('0,0[.]00') || 0} @ ${formatCurrency({
      value: values.pricePerUnit
    })}) =`

  return (
    <Dialog {...props}>
      <input {...getInputProps()} />
      <DialogTitle
        loading={isSubmitting}
        rightContent={
          <CloseButton
            className={classnames({ [classes.hideCloseButton]: hideCloseButton })} // this CloseButton gets the click event instead of the ViewMediaDialog CloseButton in the same place even though the z-index is lower... workaround for this mystery
            disabled={isSubmitting}
            onCancel={onCancel}
          />
        }
      >
        {item && (
          <Typography variant={'h2'} component={'span'}>
            Edit Product or Service
          </Typography>
        )}
        {!item && (
          <Typography variant={'h2'} component={'span'}>
            New Product or Service
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <FileDropArea
          onUpload={(files) => handleAddFiles(values, setFieldValue, [files[0]])}
          label={'Drop to Add Image or Pdf'}
        >
          <Grid container spacing={2}>
            <ItemForm
              newItem={!item}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleSwitch={handleSwitch}
              mediaComponent={mediaComponent}
            />
          </Grid>
        </FileDropArea>
      </DialogContent>
      <DialogActions>
        <div className={classes.footer}>
          {!fromPdfImport && (
            <EyeballToggle
              classes={{ toggleLabel: classes.toggleLabel }}
              name="hideQuantity"
              edge={'start'}
              checked={Boolean(!values.hideQuantity)}
              onClick={() => handleSwitch('hideQuantity')}
              label={xsDown ? 'Units' : 'Unit/Quantity'}
            />
          )}
          <div className={classes.footerRight}>
            <div className={classes.footerPrice}>
              {values.hideQuantity ? (
                <>
                  {!xsDown && <Typography variant={'h3'} className={classes.totalLabel}>{`Total Price`}</Typography>}
                  <Typography variant={'h3'} className={classes.totalValue}>
                    {totalValue}
                  </Typography>
                </>
              ) : (
                <>
                  {!xsDown && <Typography variant={'h3'} className={classes.totalLabel}>{`Total Price`}</Typography>}
                  <Typography variant={'h3'} className={classes.quantPricing}>
                    {quantPricing}
                  </Typography>
                  <Typography variant={'h3'} className={classes.totalValue}>
                    {totalValue}
                  </Typography>
                </>
              )}
            </div>
            <Button prominence={1} type={'submit'} onClick={() => handleSubmit()} loading={isSubmitting}>
              Done
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export const addMediaActions = [
  {
    key: '0',
    label: 'Media',
    icon: ImageSmallIcon
  }
]

export const calculateTotal = (values) => {
  const pricePerUnit = values?.pricePerUnit ?? 0
  const quantity = values?.quantity ?? 0

  return fixToString(pricePerUnit * quantity)
}

export default EditItemDialog
