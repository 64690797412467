import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import InputLabel from '../InputLabel'
import MuiInput from '@material-ui/core/Input'
import type { InputProps as CoreInputProps } from '@ui/core/Input'
import { default as CoreInput } from '@ui/core/Input'
import type { StyleClasses } from '@ui/core/theme'
import Typography from '../Typography'

const useStyles = makeStyles<Theme, InputProps>(
  (theme) => ({
    root: (props) => {
      return {
        ...theme.typography.subtitle2,
        background: 'none',
        boxSizing: 'border-box',
        color: theme.palette.grey.A400,
        border: `1px solid ${theme.palette.grey[500]}`,
        outlineOffset: '1px',
        borderRadius: theme.borderRadius['sm'],
        transition: 'border 0.1s, box-shadow 0.1s',
        marginTop: props.label ? theme.spacing(0.25) : 0,
        '& textarea': {
          minHeight: '39px'
        },
        ...(props.readOnly && {
          cursor: 'default'
        })
      }
    },
    input: (props) => ({
      ...(props.readOnly && {
        '&$input': {
          cursor: 'default'
        }
      }),
      padding: theme.spacing(1.25, 2),
      minHeight: '22px',
      ...theme.typography.body1
    }),
    focused: (props) => ({
      // return {
      ...(props.readOnly && {
        '&$focused': {
          border: `1px solid ${theme.palette.grey[500]}`
        }
      }),
      ...(!props.readOnly && {
        border: `1px solid ${theme.palette.primary.main}`
      })
    }),
    hasLabel: {},
    hasSublabel: {},
    wrapper: {}
  }),
  { name: 'Input' }
)

export interface InputProps extends Omit<CoreInputProps, 'Typography'> {
  classes?: CoreInputProps['classes'] & StyleClasses<typeof useStyles>
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(props: InputProps, ref) {
  const classes = useStyles(props)
  const { inlineLabel, helperText, helperTextState, ...inputProps } = props

  return (
    <CoreInput
      {...props}
      Typography={Typography}
      label={
        props.label ? (
          <InputLabel required={props.required} disabled={props.disabled}>
            {props.label}
          </InputLabel>
        ) : (
          ''
        )
      }
      sublabel={props.sublabel ? <InputLabel variant={'sublabel'}>{props.sublabel}</InputLabel> : ''}
      inputContent={
        <MuiInput
          {...inputProps}
          classes={{
            root: classes.root,
            input: classes.input,
            focused: classes.focused
          }}
          ref={ref}
        />
      }
    />
  )
})

Input.defaultProps = {
  disableUnderline: true
}

export default Input
