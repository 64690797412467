import React, { useRef, useEffect } from 'react'
import type { QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Grid, useMediaQuery } from '@ui/stickybid'
import { InlineEditableHtml, InlineEditableMedia, InlineEditableText } from '../../../inlineEditable'
import type { InlineEditableHtmlRef } from '../../../inlineEditable/InlineEditableHtml'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import classnames from 'classnames'
import AnimationWrapper from '../../util/AnimationWrapper'
import { usePresentation } from '../../../context'

import cloneDeep from 'lodash/cloneDeep'

type StackSize = 'xs' | 'sm'
const stackSize: StackSize = 'xs'

const useStyles = makeStyles<Theme, PresentationSectionProps>(
  (theme: Theme) => {
    return {
      root: {},
      subRoot: {
        overflow: 'hidden',
        // allow 2px of overflow for media hover borders
        padding: 2,
        margin: -2
      },
      content: (props, fullWidthImage = props.section.fields?.fullWidthImage) => ({
        ...(fullWidthImage && {
          padding: theme.spacing(7),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3)
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
          },
          [theme.breakpoints.down(stackSize)]: {
            padding: theme.spacing(1, 2, 4, 2)
          }
        })
      }),
      columnItem: {
        width: '100%'
      },
      leftColumnColor: (props, color = props.section.fields?.leftOptions?.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        }
      }),
      rightColumnColor: (props, color = props.section.fields?.rightOptions?.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        }
      }),
      image: (props, fullWidthImage = props.section.fields?.fullWidthImage) => ({
        ...(fullWidthImage && {
          '&$image': {
            '& img': {
              objectFit: 'cover'
            }
          }
        })
      }),
      noPaddingRight: {
        '&$noPaddingRight': {
          paddingRight: 0,
          [theme.breakpoints.down(stackSize)]: {
            paddingRight: 12
          }
        }
      },
      noPaddingLeft: {
        '&$noPaddingLeft': {
          paddingLeft: 0,
          [theme.breakpoints.down(stackSize)]: {
            paddingLeft: 12
          }
        }
      },
      fullWidthImageItem: {
        '&$fullWidthImageItem': {
          alignSelf: 'stretch',
          position: 'relative'
        }
      },
      fullWidthImage: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          '& img': {
            clipPath: 'none !important'
          }
        }
      }
    }
  },
  { name: 'TwoColumnSection' }
)

function TwoColumnSection(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const {
    isEditable,
    isSettings,
    presentationIndustry,
    presetColors,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    WrapperComponent
  } = props
  const leftColRef = useRef<HTMLDivElement>()
  const rightColRef = useRef<HTMLDivElement>()

  const leftEditorRef = useRef<InlineEditableHtmlRef>()
  const rightEditorRef = useRef<InlineEditableHtmlRef>()

  const fields = section?.fields ?? ({} as any)
  const {
    animation,
    leftText,
    leftImage,
    leftCustom,
    rightText,
    rightImage,
    rightCustom,
    columnAlign,
    leftWidth = 6,
    rightWidth = 6,
    fullWidthImage,
    swap
  } = fields

  const leftType = fields?.leftType ?? 'image'
  const rightType = fields?.rightType ?? 'text'
  const leftOptions = fields?.leftOptions ?? {}
  const rightOptions = fields?.rightOptions ?? {}

  const alignItems = getAlignment(columnAlign)

  const isStacked = useMediaQuery(stackSize)
  const responsiveSpacing = isStacked ? 3 : 6

  const isAnimated = ['slide', 'fade', 'zoom'].includes(animation)

  useEffect(() => {
    if (swap) {
      const leftContent = leftEditorRef?.current?.getContent()
      const rightContent = rightEditorRef?.current?.getContent()

      const newSection = cloneDeep(section)

      newSection.fields.leftType = rightType
      newSection.fields.leftImage = rightImage
      newSection.fields.leftText = rightContent
      newSection.fields.leftCustom = rightCustom
      newSection.fields.leftOptions = rightOptions

      newSection.fields.rightType = leftType
      newSection.fields.rightImage = leftImage
      newSection.fields.rightText = leftContent
      newSection.fields.rightCustom = leftCustom
      newSection.fields.rightOptions = leftOptions

      newSection.fields.swap = false

      setFieldValue(`${sectionPrefix}`, newSection)
    }
  }, [swap])

  let direction = 'row'

  if (isStacked) {
    if (rightType === 'text' && leftType !== 'text') {
      direction = 'column'
    } else {
      direction = 'column-reverse'
    }
  }

  const { presentationLibrary } = usePresentation()

  const handleEditField = (field: string, content: string | QuoteFile) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  return (
    <WrapperComponent {...props}>
      <div className={classes.subRoot}>
        <Grid
          className={classes.root}
          container
          spacing={responsiveSpacing}
          alignItems={alignItems}
          direction={direction as any}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={stackSize === 'sm' ? 12 : leftWidth}
            md={stackSize === 'sm' ? leftWidth : null}
            ref={leftColRef}
            style={{ zIndex: leftType === 'image' ? 2 : 1 }}
            className={classnames({
              [classes.columnItem]: true,
              [classes.leftColumnColor]: leftOptions.color,
              [classes.noPaddingRight]: fullWidthImage,
              [classes.fullWidthImageItem]: fullWidthImage && leftType === 'image' && leftImage
            })}
          >
            <AnimationWrapper
              animation={animation}
              index={0}
              direction={leftType === 'text' ? 'top' : 'left'}
              className={classnames({ [classes.fullWidthImage]: fullWidthImage && leftImage })}
            >
              <>
                {leftType === 'text' && (
                  <>
                    <InlineEditableHtml
                      key={leftText}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      handleEdit={(content) => handleEditField('fields.leftText', content)}
                      content={leftText}
                      ref={leftEditorRef}
                      placeholderButtonText={'Add Text'}
                      classes={{ root: classes.content }}
                      presetColors={presetColors}
                    />
                  </>
                )}
                {leftType === 'custom' && (
                  <InlineEditableText
                    renderHtml={true}
                    multiline={true}
                    handleEdit={(content) => handleEditField('fields.leftCustom', content)}
                    placeholderButtonText={'Add HTML'}
                    classes={{ root: classes.content }}
                  >
                    {leftCustom}
                  </InlineEditableText>
                )}
                {leftType === 'image' && (
                  <InlineEditableMedia
                    classes={{
                      root: classes.image,
                      size: classnames({ [classes.fullWidthImage]: fullWidthImage && leftImage })
                    }}
                    mediaOptionsProps={{
                      sectionPrefix: sectionPrefix,
                      fieldSuffix: 'leftImage'
                    }}
                    presentationLibrary={presentationLibrary}
                    file={leftImage}
                    handleEdit={(file) => handleEditField('fields.leftImage', file)}
                    isAnimated={isAnimated}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    placeholderButtonText={'Add Image'}
                    presentationIndustry={presentationIndustry}
                  />
                )}
              </>
            </AnimationWrapper>
          </Grid>

          <Grid
            item
            xs={12}
            sm={stackSize === 'sm' ? 12 : rightWidth}
            md={stackSize === 'sm' ? rightWidth : null}
            ref={rightColRef}
            style={{ zIndex: rightType === 'image' ? 2 : 1 }}
            className={classnames({
              [classes.columnItem]: true,
              [classes.rightColumnColor]: rightOptions.color,
              [classes.noPaddingLeft]: fullWidthImage,
              [classes.fullWidthImageItem]: fullWidthImage && rightType === 'image' && rightImage
            })}
          >
            <AnimationWrapper
              animation={animation}
              index={1}
              direction={rightType === 'text' ? 'top' : 'right'}
              className={classnames({ [classes.fullWidthImage]: fullWidthImage && rightImage })}
            >
              <>
                {rightType === 'text' && (
                  <>
                    <InlineEditableHtml
                      key={rightText}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      handleEdit={(content) => handleEditField('fields.rightText', content)}
                      content={rightText}
                      ref={rightEditorRef}
                      placeholderButtonText={'Add Text'}
                      classes={{ root: classes.content }}
                      presetColors={presetColors}
                    />
                  </>
                )}
                {rightType === 'custom' && (
                  <InlineEditableText
                    renderHtml={true}
                    multiline={true}
                    handleEdit={(content) => handleEditField('fields.rightCustom', content)}
                    placeholderButtonText={'Add HTML'}
                    classes={{ root: classes.content }}
                  >
                    {rightCustom}
                  </InlineEditableText>
                )}
                {rightType === 'image' && (
                  <InlineEditableMedia
                    classes={{
                      root: classes.image,
                      size: classnames({ [classes.fullWidthImage]: fullWidthImage && rightImage })
                    }}
                    mediaOptionsProps={{
                      sectionPrefix: sectionPrefix,
                      fieldSuffix: 'rightImage'
                    }}
                    file={rightImage}
                    presentationLibrary={presentationLibrary}
                    handleEdit={(file) => handleEditField('fields.rightImage', file)}
                    isAnimated={isAnimated}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    placeholderButtonText={'Add Image'}
                    presentationIndustry={presentationIndustry}
                  />
                )}
              </>
            </AnimationWrapper>
          </Grid>
        </Grid>
      </div>
    </WrapperComponent>
  )

  function getAlignment(align: string) {
    switch (align) {
      case 'top':
        return 'flex-start'
      case 'center':
        return 'center'
      case 'bottom':
        return 'flex-end'
      default:
        return 'center'
    }
  }
}

export default React.memo(TwoColumnSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
