import React from 'react'
import type { PresentationSection } from 'paintscout'

import { ButtonSelectOption } from '@ui/stickybid'
import ImageIcon from '@material-ui/icons/Image'
import DescriptionIcon from '@material-ui/icons/Description'

interface ImageOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
}

function ImageOptions(props: ImageOptionsProps) {
  const { section, handleOptionFieldChange } = props
  const { fields = {} } = section
  const { animation, display = 'thumbnail', image = {}, thumbnailOnMobile } = fields

  const options = [
    ...(image?.format === 'pdf' || image?.format === 'ai'
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Display'}
                value={display}
                buttons={[
                  { icon: <ImageIcon />, value: 'thumbnail' },
                  { icon: <DescriptionIcon />, value: 'full' }
                ]}
                onChange={(value) => handleOptionFieldChange('fields.display', value)}
              />
            ),
            tab: 'content'
          }
        ]
      : []),
    ...((image?.format === 'pdf' || image?.format === 'ai') && display === 'full'
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Thumbnail on Mobile'}
                value={thumbnailOnMobile ?? false}
                buttons={[
                  {
                    icon: <ImageIcon />,
                    value: true
                  }
                ]}
                onChange={(value) => handleOptionFieldChange('fields.thumbnailOnMobile', !fields.thumbnailOnMobile)}
              />
            ),
            tab: 'content'
          }
        ]
      : [])
  ]

  return options
}

export default ImageOptions
