import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { RadioProps as CoreRadioProps } from '@ui/core/Radio'
import CoreRadio from '@ui/core/Radio'

const useStyles = makeStyles<Theme, RadioProps>((theme) => ({
  root: {}
}))

export interface RadioProps extends CoreRadioProps {
  classes?: CoreRadioProps['classes'] & StyleClasses<typeof useStyles>
}

function Radio(props: RadioProps) {
  const classes = useStyles(props)

  return <CoreRadio {...props} classes={classes} />
}

export default Radio
