import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const QuoteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 20 17'} {...props}>
    <svg>
      <path
        stroke="#878787"
        strokeWidth="0.5"
        d="M2.539 6.00749C2.09915 6.00749 1.6773 6.18223 1.36627 6.49326C1.05524 6.80429 0.880505 7.22614 0.880505 7.666V13.875C0.880505 14.3149 1.05524 14.7367 1.36627 15.0477C1.6773 15.3588 2.09915 15.5335 2.539 15.5335H16.539C16.9789 15.5335 17.4007 15.3588 17.7117 15.0477C18.0228 14.7367 18.1975 14.3149 18.1975 13.875V7.666C18.1975 7.22614 18.0228 6.80429 17.7117 6.49326C17.4007 6.18223 16.9789 6.00749 16.539 6.00749C16.2952 6.00749 16.0975 5.80983 16.0975 5.56599C16.0975 5.32216 16.2952 5.12449 16.539 5.12449C17.2131 5.12449 17.8595 5.39227 18.3361 5.86889C18.8127 6.34551 19.0805 6.99195 19.0805 7.666V13.875C19.0805 14.549 18.8127 15.1955 18.3361 15.6721C17.8595 16.1487 17.2131 16.4165 16.539 16.4165H2.539C1.86495 16.4165 1.21851 16.1487 0.741892 15.6721C0.265267 15.1955 -0.00249481 14.549 -0.00249481 13.875V7.666C-0.00249481 6.99195 0.265267 6.34551 0.741892 5.86889C1.21851 5.39227 1.86495 5.12449 2.539 5.12449H7.652C7.89584 5.12449 8.0935 5.32216 8.0935 5.56599C8.0935 5.80983 7.89584 6.00749 7.652 6.00749H2.539Z"
      />
      <path
        stroke="#878787"
        strokeWidth="0.5"
        d="M12.842 2.91868C12.571 2.80655 12.2728 2.77728 11.9852 2.83459C11.6976 2.89189 11.4334 3.03319 11.2261 3.24063C11.0188 3.44806 10.8776 3.7123 10.8204 3.99995C10.7633 4.2876 10.7927 4.58576 10.905 4.8567C11.0172 5.12763 11.2073 5.35919 11.4512 5.52211C11.695 5.68504 11.9817 5.772 12.275 5.772C12.7663 5.772 13.2465 5.91769 13.655 6.19063C14.0635 6.46358 14.3819 6.85151 14.5699 7.30541C14.7579 7.7593 14.8071 8.25877 14.7113 8.74061C14.6154 9.22246 14.3788 9.66506 14.0315 10.0125C13.6841 10.3599 13.2415 10.5964 12.7596 10.6923C12.2778 10.7881 11.7783 10.7389 11.3244 10.5509C10.8705 10.3629 10.4826 10.0445 10.2096 9.63603C9.93669 9.22754 9.791 8.74729 9.791 8.256C9.791 7.97985 10.0149 7.756 10.291 7.756C10.5671 7.756 10.791 7.97985 10.791 8.256C10.791 8.5495 10.878 8.83642 11.0411 9.08046C11.2042 9.32451 11.4359 9.51473 11.7071 9.62705C11.9783 9.73936 12.2766 9.76874 12.5645 9.71148C12.8524 9.65422 13.1168 9.51289 13.3243 9.30535C13.5319 9.09781 13.6732 8.83339 13.7305 8.54552C13.7877 8.25765 13.7584 7.95926 13.646 7.68809C13.5337 7.41693 13.3435 7.18517 13.0995 7.0221C12.8554 6.85904 12.5685 6.772 12.275 6.772C11.784 6.772 11.3039 6.62639 10.8956 6.35362C10.4874 6.08084 10.1691 5.69314 9.98113 5.2395C9.79316 4.78586 9.74392 4.28667 9.83962 3.80504C9.93533 3.32342 10.1717 2.881 10.5188 2.5337C10.8659 2.1864 11.3083 1.94981 11.7898 1.85386C12.2714 1.75791 12.7706 1.80692 13.2243 1.99465C13.6781 2.18239 14.0659 2.50043 14.3389 2.9086C14.6119 3.31677 14.7578 3.79671 14.758 4.28774C14.7581 4.56389 14.5344 4.78786 14.2583 4.788C13.9821 4.78813 13.7581 4.56439 13.758 4.28825C13.7579 3.99497 13.6708 3.70832 13.5077 3.46454C13.3447 3.22077 13.113 3.03081 12.842 2.91868Z"
      />
      <path
        stroke="#878787"
        strokeWidth="0.5"
        d="M12.359 0C12.6351 0 12.859 0.223858 12.859 0.5V11.945C12.859 12.2211 12.6351 12.445 12.359 12.445C12.0829 12.445 11.859 12.2211 11.859 11.945V0.5C11.859 0.223858 12.0829 0 12.359 0Z"
      />
      <path
        stroke="#878787"
        strokeWidth="0.5"
        d="M5.267 9.10449C4.28918 9.10449 3.4965 9.89717 3.4965 10.875C3.4965 11.8528 4.28918 12.6455 5.267 12.6455C6.24482 12.6455 7.0375 11.8528 7.0375 10.875C7.0375 9.89717 6.24482 9.10449 5.267 9.10449ZM2.6135 10.875C2.6135 9.40951 3.80151 8.22149 5.267 8.22149C6.73249 8.22149 7.9205 9.40951 7.9205 10.875C7.9205 12.3405 6.73249 13.5285 5.267 13.5285C3.80151 13.5285 2.6135 12.3405 2.6135 10.875Z"
      />
    </svg>
  </SvgIcon>
)
export default QuoteIcon
