import React from 'react'
import { Button } from '@ui/stickybid'
import { useDialogs } from '@ui/stickybid'
import NewClientDialog from './NewClientDialog'
import history from '../util/history'

export default function NewClientButton() {
  const { openDialog, dismissDialog } = useDialogs()

  return (
    <Button
      prominence={1}
      onClick={() => {
        openDialog(NewClientDialog, {
          onConfirm: (clientId: string) => {
            history.push({
              pathname: `/clients/${clientId}`
            })
            dismissDialog()
          },
          onClose: () => dismissDialog()
        })
      }}
    >
      + New Client
    </Button>
  )
}
