import type { TypographyProps } from '@material-ui/core'
import { Box, Typography, useTheme } from '@material-ui/core'
import type { FollowUp } from '@paintscout/api'
import { getObjectLabels } from '@paintscout/util/builder'
import moment from 'moment'
import React from 'react'
import { useClientOptions } from '../ClientOptionsProvider'
import Highlight from '../Highlight'
import type { FlexProps } from '../Flex'
import Flex from '../Flex'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventBusyIcon from '@material-ui/icons/EventBusy'

export default function FollowUpSummary({ followUp, ...props }: { followUp: FollowUp } & FlexProps) {
  const theme = useTheme()
  const { options } = useClientOptions()

  const { fromStatus, interval, days = moment.duration(interval).asDays(), months, notStatus } = followUp.conditions

  const objectLabels = getObjectLabels({ options })
  const docType = followUp.isInvoice ? 'Invoice' : objectLabels.quote.value

  return (
    <Flex direction="column" {...props}>
      <SubLabel followUp={followUp} icon={followUp.active ? EventAvailableIcon : EventBusyIcon}>
        <Chain
          elements={[
            // quote is
            docType && (
              <>
                <span>
                  <span style={{ fontWeight: 500 }}>{docType}</span> is{' '}
                </span>
              </>
            ),
            // <status>
            followUp.conditions.fromStatus && (
              <Highlight capitalize color={theme.palette.status[fromStatus]}>
                {followUp.conditions.fromStatus}{' '}
              </Highlight>
            ),
            // and not <status>
            followUp.conditions.notStatus?.length > 0 && (
              <>
                and {followUp.conditions.notStatus === 'responded' ? 'not' : 'has not been'}{' '}
                <Highlight capitalize color={theme.palette.status[notStatus === 'responded' ? 'accepted' : notStatus]}>
                  {followUp.conditions.notStatus}
                </Highlight>{' '}
              </>
            ),
            // after <days>
            (months || days) && (
              <>
                after <span style={{ fontWeight: 500 }}>{getIntervalDescription({ months, days })}</span>
              </>
            )
          ]}
        />
      </SubLabel>
    </Flex>
  )
}

/**
 * Renders each element if truthy. when falsey, truncates and doesn't render the rest.
 */
function Chain({ elements }: { elements: Array<JSX.Element | React.ReactNode> }) {
  const filtered = elements.filter(Boolean)

  if (filtered.length !== elements.length) {
    filtered.push(<React.Fragment key="...">...</React.Fragment>)
  }

  return filtered.map((el, index) => React.cloneElement(el as any, { key: index })) as any
}

function SubLabel({
  icon,
  children,
  followUp,
  ...props
}: TypographyProps & { icon: React.ComponentType; followUp: FollowUp }) {
  const Icon = icon
  return (
    <Typography
      variant={followUp.active ? 'body1' : 'subtitle1'}
      style={{ display: 'flex', alignItems: 'flex-start' }}
      gutterBottom
      {...props}
    >
      {/* <Hidden xsDown implementation="css"> */}
      <Box component={Icon} fontSize={20} marginRight={1} marginTop={'2px'} />
      {/* </Hidden> */}
      <Typography component="span">{children}</Typography>
    </Typography>
  )
}

function getIntervalDescription({ days, months }: { days: number; months: number }) {
  let txt = ''

  if (months) {
    txt += months === 1 ? '1 month' : `${months} months`
  }

  if (days) {
    if (months) {
      txt += ' and '
    }

    txt += days === 1 ? '1 day' : `${days} days`
  }

  return txt
}
