import React, { useEffect, useState } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import type { DialogProps } from '@ui/stickybid'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputField,
  InputLabel,
  KeypadInputField,
  MenuItem,
  Radio,
  Select,
  useClientOptions
} from '@ui/stickybid'
import { formatCurrency, getActivePayments, getQuoteOptions } from '@paintscout/util/builder'
import { Collapse, useIsMobile } from '@ui/core'
import type { QuoteDocument } from 'paintscout'
import { CloseButton } from '../common'
import getDepositAmount from '@paintscout/util/util/getDepositAmount/getDepositAmount'

export interface ConfigurePaymentDialogProps extends DialogProps {
  quote: QuoteDocument
  isCustomerView?: boolean
  onCancel: () => void
  onConfirm: ({ amount, note }: { amount: number; note: string }) => void
}

type PaymentType = 'full' | 'deposit' | 'custom'

const ConfigurePaymentDialog = ({
  onCancel,
  onConfirm,
  quote,
  isCustomerView,
  ...otherProps
}: ConfigurePaymentDialogProps) => {
  const { balance_due } = quote.totals
  const mobile = useIsMobile({ xs: true })

  const { options } = useClientOptions()
  const { depositAmount } = getQuoteOptions({ options, quote })

  const showDepositOption = !getActivePayments({ quote }).length

  const [paymentType, setPaymentType] = useState<PaymentType>('full')
  const [customType, setCustomType] = useState<'percentage' | 'amount'>('amount')
  const [customAmount, setCustomAmount] = useState<{ percentage: number; amount: number }>({
    percentage: 100,
    amount: balance_due
  })
  const [note, setNote] = useState('')
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    if (validationError) setValidationError('')
  }, [paymentType, customType])

  const getPaymentAmount = (result?: boolean) => {
    if (paymentType === 'full') {
      return balance_due
    } else if (paymentType === 'deposit') {
      return getDepositAmount({ quote, options })
    } else {
      if (result || customType === 'amount') {
        return customAmount.amount || 0
      } else {
        return customAmount.percentage || 0
      }
    }
  }

  const handleCustomAmountChange = (key: 'amount' | 'percentage', val: string) => {
    const parsed = parseFloat(val)
    if (key === 'amount') {
      setCustomAmount({ amount: parsed, percentage: (parsed / balance_due) * 100 })
    } else {
      setCustomAmount({ amount: (parsed / 100) * balance_due || 0, percentage: parsed })
    }
  }

  const handlePay = () => {
    const amount = getPaymentAmount(true)
    if (amount < 0.5) {
      return setValidationError('Credit card payments are only available for amounts of at least $0.50.')
    }
    if (paymentType === 'custom' && balance_due - amount < 100 && balance_due - amount > 0) {
      return setValidationError('Please pay the full amount or leave a remaining balance of $100 or more.')
    }
    if (paymentType === 'custom' && amount > balance_due) {
      return setValidationError(`Please enter an amount of ${formatCurrency({ options, value: balance_due })} or less.`)
    }
    if (paymentType === 'custom' && amount < 100) {
      return setValidationError('Please enter an amount of $100 or more.')
    }
    onConfirm({ amount, note })
  }

  return (
    <Dialog onClose={onCancel} fullWidth={true} maxWidth={'md'} {...otherProps}>
      <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>Configure Payment</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container spacing={2} item justifyContent={'space-between'}>
          <Grid item xs={12}>
            <Collapse show={!!validationError}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert severity={'error'} variant={'outlined'}>
                    {validationError}
                  </Alert>
                </Grid>
              </Grid>
            </Collapse>
            <InputLabel shrink>Payment Amount</InputLabel>
            <RadioGroup row={false} value={paymentType} onChange={(e, val) => setPaymentType(val as PaymentType)}>
              <FormControlLabel
                label={`Full Amount (${formatCurrency({ options, value: balance_due })})`}
                value={'full'}
                control={<Radio color={'primary'} />}
              />
              {!!depositAmount?.value && showDepositOption && (
                <FormControlLabel
                  label={`Deposit (${depositAmount.type === 'amount' ? '$' : ''}${depositAmount.value}${
                    depositAmount.type !== 'amount' ? '%' : ''
                  })`}
                  value={'deposit'}
                  control={<Radio color={'primary'} />}
                />
              )}
              <FormControlLabel label={'Custom'} value={'custom'} control={<Radio color={'primary'} />} />
            </RadioGroup>
            <Collapse show={paymentType === 'custom'}>
              <Grid container>
                <Grid item xs={mobile}>
                  <KeypadInputField
                    value={getPaymentAmount().toString()}
                    fullWidth={true}
                    margin="dense"
                    label={`Amount (${customType === 'amount' ? '$' : '%'})`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCustomAmountChange(customType, e.target.value)
                    }
                    autoSelect={true}
                    endAdornment={
                      isCustomerView ? null : (
                        <Select
                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                          value={customType}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            setCustomType(e.target.value as 'amount' | 'percentage')
                          }
                          renderValue={(value: string) => {
                            return <span>{value === 'percentage' ? `%` : `$`}</span>
                          }}
                        >
                          <MenuItem value="amount">$</MenuItem>
                          <MenuItem value="percentage">%</MenuItem>
                        </Select>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          {!isCustomerView && (
            <Grid item xs={12}>
              <InputField
                label={'Note'}
                value={note}
                multiline={true}
                name={'note'}
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePay}
          variant={'contained'}
          icon={MoneyIcon}
          disabled={paymentType === 'custom' && customAmount.amount === 0}
        >
          {`Pay ${formatCurrency({ options, value: getPaymentAmount(true) })}`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfigurePaymentDialog
