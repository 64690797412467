import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../..'
import compareSectionProps from '../../util/compareSectionProps'
import { InlineEditableHtml } from '../../../inlineEditable'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, PresentationSectionProps>(
  (theme: Theme) => {
    return {
      root: {},
      content: (props, color = props.section.fields?.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        }
      })
    }
  },
  { name: 'TextSection' }
)

function TextSection(props: PresentationSectionProps) {
  const { section, WrapperComponent, isSettings, sectionPrefix, isEditable, presetColors, setFieldValue, submitForm } =
    props

  const classes = useStyles(props)

  const { fields } = section
  const customColor = props.section.fields?.color

  const handleEditField = (field: string, content: string) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  return (
    <WrapperComponent {...props}>
      <InlineEditableHtml
        classes={{
          root: classnames({
            [classes.content]: !!customColor
          })
        }}
        placeholderButtonText={'Add Text'}
        isEditable={isEditable}
        isSettings={isSettings}
        handleEdit={(content) => handleEditField('fields.content', content)}
        content={fields.content}
        presetColors={presetColors}
      />
    </WrapperComponent>
  )
}

export default React.memo(TextSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
