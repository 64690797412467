import type { QuoteDocument, OrderItem, LineItem, QuoteFileVisibility } from 'paintscout'
import { uuid } from '../../../util'
import { getQuoteOptions } from '../../../quote'

export function getPresentationPreviewQuote(quote?: Partial<QuoteDocument>): QuoteDocument {
  const lineItems = [
    {
      key: uuid(),
      name: 'Your Product or Service',
      description: 'This is the description of your product or service.',
      price: 1234.5,
      show: true,
      calculateBy: 'quantity',
      quantity: 1,
      pricePerUnit: 1234.5,
      showPrice: true,
      show_price: true,
      use_total: true,
      files: ['limes']
    }
  ] as LineItem[]

  const defaultQuote = {
    _id: 'preview',
    type: 'quote' as const,
    name: '',
    number: 12345,
    archived: false,

    contact: {
      id: '',
      rev: '1',
      type: 'contact' as const,
      created_by: '',
      contact_type: 'contact' as const,
      created_date: Date.now(),
      updated_date: Date.now(),
      first_name: 'Bob',
      last_name: 'Johnson',
      phone_number: '222.333.4444',
      email: 'bjohnson@example.com'
    },
    owner: {
      email: 'jones@example.com',
      firstName: 'Estimator',
      lastName: 'Jones'
    },
    int_ext: {
      value: 'int'
    },
    files: {
      limes: {
        key: 'limes',
        type: 'image',
        cloudinaryPublicId: '_static/fixtures/limes',
        visibility: 'visible' as QuoteFileVisibility
      },
      'house-1': {
        key: 'house-1',
        type: 'image',
        cloudinaryPublicId: 'samples/houses/house-1',
        visibility: 'visible' as QuoteFileVisibility
      },
      'house-2': {
        key: 'house-2',
        type: 'image',
        cloudinaryPublicId: 'samples/houses/house-2',
        visibility: 'visible' as QuoteFileVisibility
      },
      'house-3': {
        key: 'house-3',
        type: 'image',
        cloudinaryPublicId: 'samples/houses/house-3',
        visibility: 'visible' as QuoteFileVisibility
      }
    },
    order: {
      area: lineItems.map((item) => ({ type: 'line_item', key: item.key })) as OrderItem[],
      substrate: [] as OrderItem[]
    },
    areas: {},
    lineItems: lineItems.reduce((acc: any, item: LineItem) => {
      acc[item.key] = item
      return acc
    }, {}),
    quote_date: Date.now(),
    hourly_rate: 50,
    created_by: '',
    status: {
      value: 'sent' as const
    },
    options: getQuoteOptions({}),
    totals: {
      hours: 0,
      prep: 0,
      price: 1234.5,
      total_discount: 123.45,
      after_discount: 1111.05,
      after_tax: 1111.05
    },
    discount_type: { value: 'percent' },
    discount: 10,
    terms: {
      name: '',
      label: '',
      content: '<h2>Terms</h2><br/><p>Your terms will be inserted here. You can edit them in settings.</p>'
    },
    substrates: {},
    dates: {}
  }

  return {
    ...defaultQuote,
    ...(quote ? quote : {})
  }
}
