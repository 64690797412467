import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { FieldAttributes } from 'formik'
import { Field } from 'formik'
import type { FormikSwitchProps } from '../formik/FormikSwitch'
import FormikSwitch from '../formik/FormikSwitch'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

const useSwitchFieldStyles = makeStyles(
  (theme) => ({
    root: {
      // marginBottom: '0.5em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginRight: 0
    },
    labelHolder: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {},
    sublabel: {},
    switch: {
      marginLeft: 0
    }
  }),
  { name: 'SwitchField' }
)

/**
 * A helper component to wrap each switch in a FormControl with a subtitle, and use the formik field props
 */
const SwitchField: React.FC<FieldAttributes<any> & FormikSwitchProps & { sublabel?: string }> = ({
  sublabel,
  label,
  ...props
}) => {
  const classes = useSwitchFieldStyles({})

  return (
    <FormControl className={classes.root}>
      <Field
        component={FormikSwitch}
        // switchProps={{
        classes={{
          root: classes.switch
        }}
        // }}
        {...props}
      />
      <div className={classes.labelHolder}>
        {label && (
          <Typography className={classes.label} variant={'body1'}>
            {label}
          </Typography>
        )}
        {sublabel && (
          <Typography className={classes.sublabel} variant={'body2'}>
            {sublabel}
          </Typography>
        )}
      </div>
    </FormControl>
  )
}

export default SwitchField
