import React from 'react'
import type { StyleClasses } from '../theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel'
import MuiInputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles<Theme, InputLabelProps>((theme) => ({
  root: {},
  formControl: {
    position: 'relative'
  },
  sublabel: {},
  labelFocused: {},
  sublabelFocused: {},
  focused: {},
  disabled: {}
}))

export interface InputLabelProps extends Omit<MuiInputLabelProps, 'variant'> {
  classes?: MuiInputLabelProps['classes'] & StyleClasses<typeof useStyles>
  variant?: 'label' | 'sublabel'
}

function InputLabel(props: InputLabelProps) {
  const classes = useStyles(props)
  const { variant, ...baseInputLabelProps } = props
  const { labelFocused, sublabelFocused, sublabel, ...baseClasses } = classes

  return <MuiInputLabel {...baseInputLabelProps} classes={baseClasses} shrink={true} />
}

InputLabel.defaultProps = {
  variant: 'label'
}

export default InputLabel
