import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../builder'

const getDepositAmount = ({ quote, options }: { quote: QuoteDocument; options: OptionsDocument }): number => {
  const { depositAmount, depositAmountBeforeTax } = getQuoteOptions({ quote, options })
  if (quote.totals.balance_due < 200) {
    return quote.totals.balance_due
  }

  if (depositAmount.type === 'amount') {
    return depositAmount.value
  }

  const total = depositAmountBeforeTax ? quote.totals.after_tax - quote.totals.total_tax : quote.totals.after_tax
  return (depositAmount.value / 100) * total
}

export default getDepositAmount
