/**
 * @module builder
 */
import type { PresentationOption, OptionsDocument } from 'paintscout'
import { uuid } from '../../../util'
import { getDefaultPresentation } from '../get-default-presentation'
import { makePicks } from './make-picks'
import { PRESENTATION_LABEL } from '../../../../constants'
import type { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'

const generalOptions = {
  letterSpacingOptions: ['0', '1px', '2px', '3px'],
  lineHeightOptions: ['1.4', '1.7', '2.0'],
  textTransformOptions: ['none', 'uppercase', 'lowercase'],
  fontWeightOptions: [300, 400, 500, 600]
}

export const presentationTypographyOptions = {
  heading1: {
    ...generalOptions,
    title: 'Heading 1',
    shortTitle: 'h1',
    fontSizeOptions: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem']
  },
  heading2: {
    ...generalOptions,
    title: 'Heading 2',
    shortTitle: 'h2',
    fontSizeOptions: ['1rem', '1.25rem', '1.5rem', '1.75rem', '2rem']
  },
  heading3: {
    ...generalOptions,
    title: 'Heading 3',
    shortTitle: 'h3',
    fontSizeOptions: ['0.9rem', '1rem', '1.1rem', '1.2rem', '1.3rem']
  },
  body: {
    ...generalOptions,
    title: 'Body',
    shortTitle: 'body',
    fontSizeOptions: ['0.9rem', '0.95rem', '1rem', '1.05rem', '1.1rem']
  }
}

export const defaultPresentationTypographyPicks = {
  heading1: {
    fontWeight: 0,
    lineHeight: 1,
    letterSpacing: 0,
    textTransform: 0,
    fontSize: 2
  },
  heading2: {
    fontWeight: 2,
    lineHeight: 1,
    letterSpacing: 0,
    textTransform: 0,
    fontSize: 2
  },
  heading3: {
    fontWeight: 2,
    lineHeight: 1,
    letterSpacing: 1,
    textTransform: 1,
    fontSize: 1
  },
  body: {
    lineHeight: 1,
    letterSpacing: 0,
    textTransform: 0,
    fontSize: 2
  }
}

export function createPresentationOption(args?: { options: OptionsDocument }): PresentationOption {
  const { options } = args
  const isPS = process.env.PRODUCT === 'ps'
  const newPresentation = { ...getDefaultPresentation({ options }), key: uuid(), label: `New ${PRESENTATION_LABEL}` }

  newPresentation.advanced.autoOpenNav = isPS ? true : false
  if (process.env.PRODUCT === 'sb') {
    newPresentation.advanced.style = {
      accentColor: '#000000',
      backgroundColor: '#FFFFFF',
      buttonColor: '#9e9e9e',
      textColor: '#000000',
      fontFamily: 'roboto, sans-serif',
      heading1: {
        fontFamily: 'default',
        fontSize: makePicks('heading1', 'fontSize'),
        fontWeight: makePicks('heading1', 'fontWeight'),
        letterSpacing: makePicks('heading1', 'letterSpacing'),
        lineHeight: makePicks('heading1', 'lineHeight'),
        textTransform: makePicks('heading1', 'textTransform') as TypographyStyleOptions['textTransform']
      },
      heading2: {
        fontFamily: 'default',
        fontSize: makePicks('heading2', 'fontSize'),
        fontWeight: makePicks('heading2', 'fontWeight'),
        letterSpacing: makePicks('heading2', 'letterSpacing'),
        lineHeight: makePicks('heading2', 'lineHeight'),
        textTransform: makePicks('heading2', 'textTransform') as TypographyStyleOptions['textTransform']
      },
      heading3: {
        fontFamily: 'default',
        fontSize: makePicks('heading3', 'fontSize'),
        fontWeight: makePicks('heading3', 'fontWeight'),
        letterSpacing: makePicks('heading3', 'letterSpacing'),
        lineHeight: makePicks('heading3', 'lineHeight'),
        textTransform: makePicks('heading3', 'textTransform') as TypographyStyleOptions['textTransform']
      },
      body: {
        fontFamily: 'default',
        fontSize: makePicks('body', 'fontSize'),
        letterSpacing: makePicks('body', 'letterSpacing'),
        lineHeight: makePicks('body', 'lineHeight'),
        textTransform: makePicks('body', 'textTransform') as TypographyStyleOptions['textTransform']
      }
    }
    newPresentation.advanced.useCart = true
  }

  return newPresentation
}
