import React, { useEffect, useState } from 'react'
import { makeStyles, MobileStepper } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '../theme'
import SwipeableViews from 'react-swipeable-views'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles<Theme, CarouselProps>(
  (theme) => ({
    root: {},
    swipeContainer: {},
    mobileStepper: {
      ...theme.typography.h3
    }
  }),
  { name: 'Carousel' }
)

export interface CarouselProps {
  classes?: StyleClasses<typeof useStyles>
  IconButton: React.JSXElementConstructor<any>
  index?: number
  children: React.ReactNode[]
  getActiveIndex?: React.Dispatch<React.SetStateAction<number>>
  beforeStepper?: React.ReactNode
  handleSwitching?: () => void
  handleTransitionEnd?: () => void
}

function Carousel(props: CarouselProps) {
  const classes = useStyles(props)
  const {
    children,
    getActiveIndex,
    IconButton,
    index: propsIndex,
    beforeStepper,
    handleSwitching,
    handleTransitionEnd
  } = props
  const [index, setIndex] = useState(propsIndex || 0)

  useEffect(() => {
    setIndex(propsIndex || 0)
  }, [propsIndex])

  const handleSetIndex = (i: number) => {
    setIndex(i)
    if (getActiveIndex) getActiveIndex(i)
  }

  const nextButton = (
    <IconButton
      onClick={() => {
        handleSetIndex(index + 1)
      }}
      disabled={index === children.length - 1}
      style={{ marginLeft: 16 }}
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  )

  const backButton = (
    <IconButton
      onClick={() => {
        handleSetIndex(index - 1)
      }}
      disabled={index === 0}
      style={{ marginRight: 16 }}
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  )

  const steps = children.length

  return (
    <>
      <SwipeableViews
        className={classes.swipeContainer}
        axis={'x'}
        index={index}
        onChangeIndex={(newIndex) => handleSetIndex(newIndex)}
        enableMouseEvents={true}
        animateTransitions
        onSwitching={handleSwitching}
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </SwipeableViews>
      {beforeStepper}
      <MobileStepper
        variant={steps < 7 ? 'dots' : 'text'}
        className={classes.mobileStepper}
        steps={steps}
        activeStep={index}
        position="static"
        nextButton={nextButton}
        backButton={backButton}
      />
    </>
  )
}

export default Carousel
