import React, { useState, useRef, useEffect } from 'react'
import type { DayPickerProps } from 'react-day-picker'
import DayPicker from 'react-day-picker'
import type { Moment } from 'moment'
import moment from 'moment'
import InputField from '../InputField'
import type { Theme } from '@material-ui/core'
import { Popover, makeStyles, Grid } from '@material-ui/core'
import classnames from 'classnames'
import type { PopoverProps as MuiPopoverProps } from '@material-ui/core/Popover'
import type { StyleClasses } from '../styles'

export interface DatePickerProps {
  classes?: StyleClasses<typeof useStyles>
  /**
   * Button component to use
   */
  Button: React.ComponentType<any>

  /**
   * Component to be used as the NavBar
   */
  DatePickerNavBar: React.ComponentType<any>

  id: string

  /**
   * Moment date for a single date, or an array of Moment dates for a range ([start, end])
   */
  value: Moment | Moment[]

  /**
   * Moment format string
   */
  format: string

  /**
   * controls state of whether or not the picker is open
   */
  open?: boolean

  /**
   * Show helper text at the bottom of the picker
   */
  info?: React.ReactNode

  /**
   * Shows a clear button to remove the value
   */
  clearable?: boolean

  noFocusOpen?: boolean

  /**
   * When date is changed (this won't fire until the user clicks OK)
   */
  onChange?: (date: Moment | Moment[]) => any

  /**
   * Fires when date is changed, but not yet confirmed
   */
  onDisplayValueChange?: (date: Moment | Moment[]) => any

  /**
   * When the picker closes
   */
  onClose?: () => any

  /**
   * When the picker is cancelled
   */
  onCancel?: () => any

  /**
   * Passes on to the react-day-picker DayPicker component
   */
  PickerProps?: DayPickerProps

  PopoverProps?: Omit<MuiPopoverProps, 'open'>
  /**
   * Changes the input component used
   *
   * default is InputField
   */
  InputComponent?: React.ComponentType<any>

  /**
   * Passes on extra props to the InputComponent
   */
  InputProps?: any

  /**
   * Selects a date without the need to click OK
   */
  selectOnClick?: boolean

  /**
   * function to styled the month labels
   */
  captionElement?: React.JSXElementConstructor<any>
  confirmLabel?: string
  disabled?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    disabled: {
      pointerEvents: 'none'
    },
    paper: {},
    popover: {},
    popoverContent: {},
    popoverFooter: {},
    picker: {},
    rangePicker: {},
    input: {},
    info: {}
  }),
  { name: 'DatePicker' }
)

function DatePicker(props: DatePickerProps) {
  const {
    Button,
    open,
    disabled,
    info,
    value,
    format,
    clearable,
    InputComponent = InputField,
    PickerProps = {},
    InputProps = {},
    PopoverProps = {},
    onChange,
    onCancel,
    onDisplayValueChange,
    onClose,
    noFocusOpen,
    selectOnClick,
    DatePickerNavBar,
    captionElement,
    confirmLabel
  } = props
  const classes = useStyles(props)
  const inputRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isClosing, setIsClosing] = useState(false)
  // we'll keep the displayValue an array no matter what so it's easier to work with
  const [displayValue, setDisplayValue] = useState(getSanitizedValue(value))

  // when selecting the 2nd date of a range, this will be set on mouse hover to highlight the range before selecting
  const [tentativeEndDate, setTentativeEndDate] = useState<Moment>(null)

  const [month, setMonth] = useState(moment(displayValue[0]))
  const isRange = Array.isArray(value)
  const isTouchDevice = 'ontouchstart' in window

  // If value prop changes while open, update internal state
  useEffect(() => {
    if (!isClosing) {
      setDisplayValue(getSanitizedValue(value))
      setMonth(getSanitizedValue(value)[0])
      if (onDisplayValueChange) {
        onDisplayValueChange(getSanitizedValue(value))
      }
    }
  }, [value])

  // Handle `open` prop
  useEffect(() => {
    if (open) {
      if (inputRef.current) {
        setAnchorEl(inputRef.current)
      } else {
        console.error('Unable to find inputRef')
      }
    } else if (open !== undefined) {
      setAnchorEl(null)
      setIsClosing(true)
    }
  }, [open])

  function closePopover() {
    if (onClose) {
      onClose()
    }

    setAnchorEl(null)
    setIsClosing(true)
  }

  function handleChange(day: Date) {
    const newDay = moment(day)
    const [from, to] = displayValue
    const isBeforeFirstDay = from && newDay.isBefore(from)
    const isRangeSelected = from && to
    const isSelectingFirstDay = !from || isBeforeFirstDay || isRangeSelected

    if (!isRange || isSelectingFirstDay) {
      setDisplayValue([newDay])
      if (onDisplayValueChange) {
        onDisplayValueChange([newDay])
      }
      if (selectOnClick) {
        onChange(newDay)
        closePopover()
      }
    } else {
      setDisplayValue([from, newDay])
      if (onDisplayValueChange) {
        onDisplayValueChange([from, newDay])
      }
      setTentativeEndDate(null)
    }
  }

  function handleDayMouseEnter(day: Date) {
    const momentDate = moment(day)
    if (isRange && !displayValue[1] && displayValue[0] && displayValue[0].isBefore(momentDate)) {
      setTentativeEndDate(momentDate)
    }
  }

  function handleCancel() {
    setDisplayValue(getSanitizedValue(value))
    if (onDisplayValueChange) {
      onDisplayValueChange(getSanitizedValue(value))
    }
    if (onCancel) {
      onCancel()
    }
    closePopover()
  }

  function handleClear() {
    const newValue = isRange ? [] : null
    onChange(newValue)
    setDisplayValue(getSanitizedValue(newValue))
    if (onDisplayValueChange) {
      onDisplayValueChange(newValue)
    }
    closePopover()
  }

  function handleConfirm() {
    // convert displayValue back to a non-array if necessary
    const newValue = displayValue.length > 1 ? displayValue : displayValue[0]
    onChange(newValue)
    closePopover()
  }

  const [startDate, endDate] = displayValue
  const displayedEndDate = tentativeEndDate || endDate

  return (
    <div ref={inputRef}>
      <InputComponent
        {...InputProps}
        disabled={disabled}
        readOnly
        className={classnames(classes.input, InputProps.className)}
        value={getSelectedDateText(displayValue, format)}
        {...(noFocusOpen
          ? {}
          : {
              onFocus: (ev) => {
                if (!isClosing && !InputProps?.disabled) {
                  setAnchorEl(ev.currentTarget)
                }
              },
              onClick: (ev) => !InputProps?.disabled && setAnchorEl(ev.currentTarget)
            })}
      />
      <Popover
        className={classes.popover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.paper }}
        {...PopoverProps}
        onClose={handleCancel}
        TransitionProps={{
          onExited: () => setIsClosing(false)
        }}
      >
        <div className={classes.popoverContent}>
          <DayPicker
            className={classnames(classes.picker, { [classes.rangePicker]: isRange })}
            // appends correct classes to days?
            modifiers={{
              start: startDate ? startDate.toDate() : undefined,
              end: displayedEndDate ? displayedEndDate.toDate() : undefined
            }}
            // disable days before beginning of range while selecting end date
            disabledDays={isRange && !endDate ? { before: startDate.toDate() } : undefined}
            // highlight selected day or range of days
            selectedDays={
              startDate
                ? isRange
                  ? [
                      startDate.toDate(),
                      {
                        from: startDate ? startDate.toDate() : undefined,
                        to: displayedEndDate ? displayedEndDate.toDate() : undefined
                      }
                    ]
                  : [startDate.toDate()]
                : undefined
            }
            month={month ? month.toDate() : undefined}
            navbarElement={({ month: shownMonth, onPreviousClick, onNextClick }) => (
              <DatePickerNavBar
                month={moment(shownMonth)}
                onPreviousClick={onPreviousClick}
                onNextClick={onNextClick}
                onMonthYearChange={(newMonth) => setMonth(newMonth)}
                PickerProps={PickerProps}
                value={value}
              />
            )}
            onDayMouseEnter={isTouchDevice ? handleChange : handleDayMouseEnter}
            captionElement={captionElement ? captionElement : () => null} // remove default caption element
            {...PickerProps}
            onDayClick={handleChange}
          />
          {info && <div className={classes.info}>{info}</div>}
          <div className={classes.popoverFooter}>
            <Grid container spacing={1}>
              {clearable && (
                <Grid item>
                  <Button variant="text" prominence={3} edge={'start'} onClick={handleClear}>
                    Clear
                  </Button>
                </Grid>
              )}
            </Grid>
            {!selectOnClick && (
              <Button onClick={handleConfirm} prominence={1} disabled={isRange ? displayValue.length < 2 : false}>
                {confirmLabel ? confirmLabel : 'OK'}
              </Button>
            )}
          </div>
        </div>
      </Popover>
    </div>
  )
}

/**
 * Forces the value to be an array
 */
function getSanitizedValue(value: Moment | Moment[]) {
  const isRange = !!value && Array.isArray(value)

  return isRange ? (value as Moment[]) : ([value] as Moment[])
}

function getSelectedDateText(dates: Moment[], format: string) {
  if (dates[1]) {
    return `${dates[0].format(format)} to ${dates[1].format(format)}`
  }

  if (dates[0]) {
    return `${dates[0].format(format)}`
  }

  return ''
}

export default DatePicker
