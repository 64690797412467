import React from 'react'
import type { FormLabelProps as MuiFormLabelProps } from '@material-ui/core/FormLabel'
import MuiFormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '../'

const useStyles = makeStyles<Theme, FormLabelProps>((theme) => ({
  root: {}
}))

export interface FormLabelProps extends MuiFormLabelProps {
  classes?: StyleClasses<typeof useStyles>
}

function FormLabel(props: FormLabelProps) {
  return <MuiFormLabel {...props} />
}

export default FormLabel
