import WebFont from 'webfontloader'
import startCase from 'lodash/startCase'

const allFontFamilies = [
  // 'default',
  'Bitter:300,300i,400,400i,500,500i',
  'Cormorant:300,300i,400,400i,500,500i',
  'Crimson Pro:300,300i,400,400i,500,500i',
  // 'degular',
  'Fraunces:300,300i,400,400i,500,500i',
  // 'georgia',
  'Karla:300,300i,400,400i,500,500i',
  'Montserrat:300,300i,400,400i,500,500i',
  // 'roboto',
  'Roboto Mono:300,300i,400,400i,500,500i',
  'Spectral:300,300i,400,400i,500,500i',
  'Ubuntu:300,300i,400,400i,500,500i',
  'Work Sans:300,300i,400,400i,500,500i',
  'Zilla Slab:300,300i,400,400i,500,500i'
]

// Roboto Slab => Zilla Slab
// BioRhyme => Zilla Slab
// Inknut Antiqua => Spectral
// Libre Baskerville => Fraunces
// Space Mono => Roboto Mono
// Inter => Ubuntu

function addVariants(fonts: string[]) {
  return fonts.map((font) => {
    const nameEnd = font.indexOf(',')
    const fontFamily = startCase(font).substring(0, nameEnd)
    return fontFamily.concat(':300,300i,400,400i,500,500i')
  })
}

const handleLoaded = (win: Window) => {
  if (win.sessionStorage) {
    win.sessionStorage.setItem('fontsLoaded', 'true')
  }
}

export function loadPresentationFonts(args: { win: Window; doc: Document; fontFamilies?: string[] }) {
  const { doc, fontFamilies, win } = args
  const fontsToLoad = fontFamilies ? addVariants(fontFamilies) : allFontFamilies
  if (fontLoadChecker({ fontsToLoad, doc, win })) {
    return null
  } else {
    WebFont.load({
      google: {
        families: fontsToLoad
      },
      // @ts-ignore
      context: win,
      active: () => handleLoaded(win)
    })
  }
}

function fontLoadChecker(args: { fontsToLoad: string[]; doc: Document; win: Window }) {
  const { doc, fontsToLoad, win } = args
  let allLoaded = true
  let docFonts = []
  doc.fonts.forEach((font) => {
    const family = font.family.toLowerCase()
    if (!docFonts.includes(family)) docFonts.push(family)
  })
  fontsToLoad.forEach((font) => {
    const nameEnd = font.indexOf(':')
    const family = font.substring(0, nameEnd).toLowerCase()
    // doc.fonts.check() returns true for fonts that don't (yet) exist in FontFaceSet, hence the second check against FontFaceSet (doc.fonts)
    if (!(doc as any).fonts.check(`12px ${family}`)) allLoaded = false
    if (!docFonts.includes(family)) allLoaded = false
  })
  if (allLoaded) handleLoaded(win)
  return allLoaded
}
