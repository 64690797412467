import React from 'react'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'

import CardsSection from '../Cards/CardsSection'

function TestimonialsSection(props: PresentationSectionProps) {
  return <CardsSection type={'testimonials'} {...props} />
}

export default React.memo(TestimonialsSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
