/**
 * @module builder
 */
import type { LineItem, OptionsDocument, QuoteDocument } from 'paintscout'

import type { UpdateableLineItem, UpdateableProduct, QuoteItemSection } from '../../index'
import { moveTo } from '../../util'
import { calculateQuote } from '../../util/calculate/calculate-quote'
import { uuid } from '../../../util'

import findIndex from 'lodash/findIndex'

export function updateLineItem(args: {
  quote: QuoteDocument
  item: UpdateableLineItem
  options: OptionsDocument
  calculate?: boolean
  insertAfterKey?: string
  itemSection?: QuoteItemSection
}): QuoteDocument {
  const { quote, options, item, calculate, insertAfterKey, itemSection } = args

  const key = item?.key ?? uuid()
  const section = item?.section ?? itemSection ?? 'bid'
  const isNewItem = quote.lineItems[key] ? false : true

  let originalItem = isNewItem ? { ...createBaseLineItem(key), section } : quote.lineItems[key]
  originalItem = moveTo(originalItem, section)
  // Getting rid of this as moveTo should add the same for either the items, given, or fallback section
  // const checkboxes = getCheckboxes(item)

  const newItem: LineItem = {
    ...originalItem,
    key,
    name: item.label,
    description: item.description,
    crew_note: item.crewNote,
    client_note: item.clientNote,
    hours: typeof item.hours === 'string' ? parseFloat(item.hours) : item.hours,
    price: typeof item.price === 'string' ? parseFloat(item.price) : item.price,
    calculate: item.calculate,
    calculateBy: item.calculateBy,
    quantity: item.quantity,
    hoursPerUnit: item.hoursPerUnit,
    pricePerUnit: item.pricePerUnit,
    files: item.files,
    source: item.source,
    customHourlyRate: item.customHourlyRate,
    hideOnWorkOrder: item.hideOnWorkOrder,
    hourlyRate: typeof item.hourlyRate === 'string' ? parseFloat(item.hourlyRate) : item.hourlyRate,
    materials: item.materials ? [...item.materials] : [],
    quoteType: item.quoteType,
    // stickybid
    taxRate: item.taxRate,
    showPrice: item.showPrice,
    hideQuantity: item.hideQuantity,
    fromPdfImport: item.fromPdfImport,
    acceptedOption: item.acceptedOption
    // ...checkboxes
  }

  if (item.quoteFiles) {
    quote.files = { ...quote.files, ...item.quoteFiles }
  }

  const areaOrder = [...quote.order.area]
  const substrateOrder = [...quote.order.substrate]

  if (isNewItem) {
    const orderItem = {
      key,
      type: 'line_item'
    }

    if (insertAfterKey) {
      const index = findIndex(areaOrder, { key: insertAfterKey })
      areaOrder.splice(index + 1, 0, orderItem)
      substrateOrder.splice(index + 1, 0, orderItem)
    } else {
      areaOrder.push(orderItem)
      substrateOrder.push(orderItem)
    }
  }

  const updatedQuote = {
    ...quote,
    lineItems: {
      ...quote.lineItems,
      [key]: newItem
    },
    order: {
      ...quote.order,
      area: areaOrder,
      substrate: substrateOrder
    }
  }
  const calculatedQuote = calculateQuote({ quote: updatedQuote, options })
  return calculate === false && !['hourlyRate', 'quantity'].includes(item.calculateBy) ? updatedQuote : calculatedQuote
}

export function createBaseLineItem(key: string): LineItem {
  return {
    key,
    show: true,
    show_price: true,
    show_crew: true,
    use_total: true,
    show_total: true,
    name: '',
    price: 0,
    hours: 0,
    customHourlyRate: false,
    hourlyRate: 0,
    description: '',
    crew_note: '',
    client_note: '',
    calculateBy: 'none',
    materials: [] as UpdateableProduct[],
    totals: {
      materials: 0,
      afterMaterials: 0
    },
    files: []
  }
}
