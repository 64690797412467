import { useEffect, useState } from 'react'
import type { AuthContextValue } from './AuthProvider'
import { useAuth } from './AuthProvider'

/**
 * Automatically redirects to login if the user is unauthenticated
 */
export default function Authorizer({
  children
}: {
  children: React.ReactNode | ((args: AuthContextValue) => React.ReactNode)
}) {
  const ctx = useAuth()
  const { auth0Client, session, authenticate, isAuthenticating } = ctx
  const [redirecting, setRedirecting] = useState(false)

  useEffect(() => {
    if (!redirecting && !session) {
      setRedirecting(true)
      localStorage.setItem('authRedirectUrl', window.location.pathname)
      auth0Client.loginWithRedirect({
        authorizationParams: {
          login_hint: JSON.parse(localStorage.getItem('lastLogin')) ?? undefined
        }
      })
    }
    // eslint-disable-next-line
  }, [redirecting, session])

  // refresh session if possible on page load
  useEffect(() => {
    if (!isAuthenticating) {
      authenticate({ ignoreCache: false }).catch((e) => {
        // error codes that require user to login again
        const needsLoginErrors = ['invalid_grant', 'login_required', 'access_denied', 'unauthorized']
        localStorage.setItem('authRedirectUrl', window.location.pathname)

        if (needsLoginErrors.includes(e.error)) {
          auth0Client.loginWithRedirect({
            authorizationParams: {
              login_hint: JSON.parse(localStorage.getItem('lastLogin')) ?? undefined
            }
          })
        } else {
          console.error(e)
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  return typeof children === 'function' ? children(ctx) : children
}
