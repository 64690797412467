import React, { useState } from 'react'
import type { DialogProps } from '@ui/stickybid'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DropdownSelect,
  FormControlLabel,
  FormSection,
  FormSectionTitle,
  Grid
} from '@ui/stickybid'
import { useSnackbar } from 'notistack'
import { useQuery } from '@apollo/react-hooks'
import type { GetUserResponse } from '../../graphql/queries/GET_USER'
import { GET_USER } from '../../graphql/queries/GET_USER'
import { useRouteMatch } from 'react-router'
import type { UserImportExportProps } from '../UserAdministrationForm/UserImportExport'

const defaultUserImportOptions = {
  quoteEmails: false,
  thankYouAcceptEmails: false,
  thankYouDeclineEmails: false,
  followUps: false
} as UserImportExportProps

const userOptions = [
  { value: 'auth0|608c7b6c42ec0c00710a1bea', label: 'Onboarding Account - Main User' }
  // NEED TO UPDATE IDS for these other users
  // { value: 'iqxvskejjohghvzh', label: 'Consulting 4 Contractors' },
  // { value: 'oxjbyvmmctvnheho', label: 'PBP TEMPLATE' },
  // { value: 'imgnzzxxwlujqesx', label: 'PCA TEMPLATE' },
  // { value: 'bcqjokvasggitxfx', label: 'SUMMIT TEMPLATE' },
  // { value: 'sqlwadwqemrpelag', label: 'Unforgettable Coatings' }
]

export interface ImportAccountDialogProps extends DialogProps {
  queueDocUpdates: (json: any, selectedOptions?: UserImportExportProps) => void
  onClose?: () => void
}

const useUserQuery = (id: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const { data, error } = useQuery<GetUserResponse>(GET_USER, {
    variables: {
      id: id ?? ''
    }
  })
  if (error) {
    enqueueSnackbar('Error retrieving user details', { variant: 'error' })
    return null
  }
  return data?.user
}

const ImportUserDialog = ({ onClose, queueDocUpdates, ...props }: ImportAccountDialogProps) => {
  const match = useRouteMatch<{ id: string; tab: string; section: string }>()
  const { id } = match.params
  const { enqueueSnackbar } = useSnackbar()
  const [importOptions, setImportOptions] = useState(defaultUserImportOptions)
  const [selectedUser, setSelectedUser] = useState(id)
  const details = useUserQuery(selectedUser)

  const handleImportCheckboxChange = (target: string) => {
    return function () {
      setImportOptions({ ...importOptions, [target]: !importOptions[target] })
    }
  }

  const handleImport = () => {
    if (details) {
      queueDocUpdates({ user: details }, importOptions)
      enqueueSnackbar('Documents ready to save!', { variant: 'success' })
      onClose()
    } else {
      enqueueSnackbar('Error retrieving account details', { variant: 'error' })
    }
  }

  return (
    <Dialog {...props}>
      <DialogTitle>Import From User</DialogTitle>
      <DialogContent>
        <DropdownSelect
          label="Template User"
          options={userOptions}
          value={userOptions[selectedUser]?.label}
          multiSelect={false}
          onChange={(ev) => setSelectedUser(ev.value)}
        />
        <br />
        <br />
        <FormSectionTitle title="Import" />
        <FormSection>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12}>
              <FormControlLabel
                label={'Quote Emails'}
                control={
                  <Checkbox checked={importOptions.quoteEmails} onChange={handleImportCheckboxChange('quoteEmails')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Thank You Accept Email'}
                control={
                  <Checkbox
                    checked={importOptions.thankYouAcceptEmails}
                    onChange={handleImportCheckboxChange('.thankYouAcceptEmails')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Thank You Decline Email'}
                control={
                  <Checkbox
                    checked={importOptions.thankYouDeclineEmails}
                    onChange={handleImportCheckboxChange('thankYouDeclineEmails')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={"Follow Up's"}
                control={
                  <Checkbox checked={importOptions.followUps} onChange={handleImportCheckboxChange('followUps')} />
                }
              />
            </Grid>
          </Grid>
        </FormSection>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button prominence={3} edge={'start'} onClick={onClose}>
            Cancel
          </Button>
        }
      >
        <Button prominence={1} onClick={handleImport} disabled={selectedUser === id}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportUserDialog
