import React from 'react'
import type { PresentationSection } from 'paintscout'

import { ColorOption, ButtonSelectOption } from '@ui/stickybid'

import { UTurnRightIcon } from '@ui/core/icons'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import Crop54Icon from '@material-ui/icons/Crop54'

interface LogoOptionsProps {
  section: PresentationSection
  presetColors: string[]
  handleOptionFieldChange: (field: string, value: any) => void
}

function LogoOptions(props: LogoOptionsProps) {
  const { section, presetColors, handleOptionFieldChange } = props
  const { fields = {} } = section
  const {
    animation,
    color,
    logoSize,
    logoAlignment,
    useGlassEffect,
    useContentBackground,
    contentColor,
    contentBackgroundColor
  } = section.fields

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(270deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Logo Alignment (H)'}
          value={logoAlignment}
          buttons={[
            { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'left' },
            { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
            { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'right' }
          ]}
          onChange={(value) => {
            handleOptionFieldChange('fields.logoAlignment', value)
          }}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Logo Size'}
          value={logoSize}
          buttons={[
            { icon: 'S', value: 'sm' },
            { icon: 'M', value: 'md' },
            { icon: 'L', value: 'lg' }
          ]}
          onChange={(value) => {
            handleOptionFieldChange('fields.logoSize', value)
          }}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Use Content Background'}
          value={useContentBackground}
          buttons={[{ icon: <Crop54Icon />, value: true }]}
          onChange={(value) => handleOptionFieldChange('fields.useContentBackground', !fields.useContentBackground)}
        />
      ),
      tab: 'content'
    },
    // ...(!useContentBackground
    //   ? [
    //       {
    //         Component: (
    //           <ColorOption
    //             label={'Custom Text Color'}
    //             value={color}
    //             useClearOption={true}
    //             helpTextForClear={'Clear Text Color'}
    //             defaultColor={''}
    //             clearColor={''}
    //             presetColors={presetColors}
    //             onChange={(value) => {
    //               handleOptionFieldChange(`fields.color`, value)
    //             }}
    //           />
    //         ),
    //         tab: 'color'
    //       }
    //     ]
    //   : []),
    ...(useContentBackground
      ? [
          // {
          //   Component: (
          //     <ColorOption
          //       label={'Custom Content Text Color'}
          //       value={contentColor}
          //       useClearOption={true}
          //       helpTextForClear={'Clear Text Color'}
          //       defaultColor={''}
          //       clearColor={''}
          //       presetColors={presetColors}
          //       onChange={(value) => {
          //         handleOptionFieldChange(`fields.contentColor`, value)
          //       }}
          //     />
          //   ),
          //   tab: 'content'
          // },
          {
            Component: (
              <ColorOption
                label={'Custom Content Background Color'}
                value={contentBackgroundColor}
                useClearOption={true}
                helpTextForClear={'Clear Background Color'}
                defaultColor={''}
                clearColor={''}
                presetColors={
                  presetColors || [
                    '#FFFFFF',
                    '#F0F0F0',
                    '#E5E4E2',
                    '#FAF9F6',
                    '#F9F6EE',
                    '#FCF5E5',
                    '#EDEADE',
                    '#FFFFF0'
                  ]
                }
                onChange={(value) => {
                  handleOptionFieldChange(`fields.contentBackgroundColor`, value)
                }}
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ButtonSelectOption
                label={'Use Glass Effect'}
                value={useGlassEffect}
                buttons={[{ icon: <PhotoFilterIcon />, value: true }]}
                infoText={'May not be available on all browsers. Fallback to Text Background Color.'}
                onChange={(value) => handleOptionFieldChange('fields.useGlassEffect', !fields.useGlassEffect)}
              />
            ),
            tab: 'content'
          }
        ]
      : [])
  ]

  return options
}

export default LogoOptions
