import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'

import Menu from '../Menu'
import MenuItem from '../MenuItem'

import WarningIcon from '@material-ui/icons/Warning'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    icon: {}
  })

export interface ResetAdornmentProps extends WithStyles<typeof styles> {
  onResetClick?: (e: React.MouseEvent) => void
  onClearClick?: (e: React.MouseEvent) => void
  resetLabel?: string
  clearLabel?: string
}

function ResetAdornment({ onResetClick, onClearClick, classes, ...props }: ResetAdornmentProps) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const resetLabel = props?.resetLabel ?? 'Reset Value'
  const clearLabel = props?.clearLabel ?? 'Clear Value'

  function handleClick(event) {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function resetValue(event: React.MouseEvent) {
    event.stopPropagation()
    setAnchorEl(null)
    onResetClick(event)
  }

  function clearValue(event: React.MouseEvent) {
    event.stopPropagation()
    setAnchorEl(null)
    onClearClick(event)
  }

  const handleClickAway = (ev: any) => {
    setAnchorEl(null)
  }

  return (
    <>
      <WarningIcon onClick={handleClick} classes={{ root: classes.icon }} />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {onResetClick && <MenuItem onClick={resetValue}>{resetLabel}</MenuItem>}
        {onClearClick && <MenuItem onClick={clearValue}>{clearLabel}</MenuItem>}
      </Menu>
    </>
  )
}

export default withStyles(styles)(ResetAdornment)
