import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { PopupMenu, IconButton, Typography, Tooltip } from '../'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import classnames from 'classnames'

interface ButtonSelectOptionProps {
  label: string
  value: string | number | boolean
  buttons: {
    icon: React.ReactNode
    value: string | number | boolean
    disabled?: boolean
    forceSelected?: boolean
  }[]
  onChange: (value: string | number | boolean) => void
  infoText?: string
  classes?: StyleClasses<typeof useStyles>
}

// forceSelected is used with disabled to give the appearance of a selected button (e.g., contentWidth with backgroundColor)

const useStyles = makeStyles<Theme, ButtonSelectOptionProps>(
  (theme: Theme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      label: {
        fontSize: '.9rem',
        marginRight: theme.spacing(3)
      },
      button: {
        borderRadius: 0,
        height: 26,
        width: 26,
        fontSize: '.8rem',
        fontWeight: 'bold',
        '& svg': {
          fontSize: '1rem'
        },
        '&:hover': {
          color: theme.palette.grey[700]
        }
      },
      selected: {
        background: theme.palette.grey[400]
      },
      forceSelected: {
        '&.Mui-disabled': {
          background: theme.palette.grey[400],
          color: theme.palette.common.black
        }
      },
      tooltipRoot: {
        marginRight: 'auto',
        marginLeft: theme.spacing(-1)
      },
      tooltipBadge: {
        cursor: 'pointer',
        top: 0,
        right: 0,
        '& svg': {
          fontSize: '1rem'
        }
      },
      dropdownToggle: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          '& $dropdownToggleIcon': {
            color: theme.palette.secondary.main
          }
        }
      },
      dropdownToggleIcon: {}
    }
  },
  { name: 'ButtonSelectOption' }
)

export default function ButtonSelectOption(props: ButtonSelectOptionProps) {
  const classes = useStyles(props)
  const { label, value, onChange, buttons, infoText } = props

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant={'h5'}>
        {label}
      </Typography>
      {infoText && (
        <div className={classes.tooltipRoot}>
          <Tooltip
            placement={'left'}
            content={infoText}
            icon={<InfoOutlinedIcon />}
            classes={{ badge: classes.tooltipBadge }}
          />
        </div>
      )}
      <div>
        {buttons.length < 9 ? (
          buttons.map((button, index) => {
            const selected = value === button.value
            return (
              <IconButton
                key={index}
                onClick={handleButtonClick(button.value)}
                classes={{
                  root: classnames({
                    [classes.button]: true,
                    [classes.selected]: selected || button.forceSelected,
                    [classes.forceSelected]: button.forceSelected
                  })
                }}
                disabled={button.disabled}
              >
                {button.icon}
              </IconButton>
            )
          })
        ) : (
          <PopupMenu
            component={
              <div className={classes.dropdownToggle}>
                <IconButton
                  classes={{
                    root: classnames({ [classes.button]: true, [classes.selected]: true })
                  }}
                >
                  {buttons.find((button) => button.value === value)?.icon}
                </IconButton>
                <ArrowDropDownIcon className={classes.dropdownToggleIcon} />
              </div>
            }
          >
            {buttons.map((button, index) => {
              const selected = value === button.value
              return (
                <IconButton
                  key={index}
                  onClick={handleButtonClick(button.value)}
                  classes={{
                    root: classnames({
                      [classes.button]: true,
                      [classes.selected]: selected || button.forceSelected,
                      [classes.forceSelected]: button.forceSelected
                    })
                  }}
                  disabled={button.disabled}
                >
                  {button.icon}
                </IconButton>
              )
            })}
          </PopupMenu>
        )}
      </div>
    </div>
  )

  function handleButtonClick(value: string | number | boolean) {
    return (ev: React.MouseEvent) => {
      onChange(value)
    }
  }
}
