import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { FormControl, createStyles, withStyles } from '@material-ui/core'
import Input from '../Input'
import { HtmlEditor } from '../RTE'
import type { OverridableValue } from 'paintscout'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& textarea': {
        minHeight: '38px'
      }
    },
    inputFocused: {},
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    htmlEditor: {
      minHeight: '3em'
    }
  })

type OverridableTextAreaPropsWithInjections = OverridableTextAreaProps & WithStyles<typeof styles>

export interface OverridableTextAreaProps {
  value?: OverridableValue
  showReset?: boolean
  showRestore?: boolean
  required?: boolean
  label?: string
  sublabel?: string
  disabled?: boolean
  allowHtml?: boolean
  htmlOnChangeDebounce?: number
  onChange?: (value: OverridableValue) => void
}

interface OverridableTextAreaState {
  value?: OverridableValue
  isDirty?: boolean
}

/**
 * Renders a Input component from material-ui and provides extra props for adding an icon
 */
class OverridableTextArea extends React.PureComponent<
  OverridableTextAreaPropsWithInjections,
  OverridableTextAreaState
> {
  public static defaultProps: Partial<OverridableTextAreaProps> = {
    showReset: true,
    showRestore: false
  }

  public static getDerivedStateFromProps(props: OverridableTextAreaProps, state: OverridableTextAreaState) {
    return {
      ...state,
      value: {
        ...state.value,
        default: props.value.default
      }
    }
  }

  constructor(props: OverridableTextAreaPropsWithInjections) {
    super(props)

    this.state = {
      value: this.props.value,
      isDirty: false
    }
  }

  public render() {
    const { label, sublabel, classes, allowHtml, required, value, showReset, showRestore, htmlOnChangeDebounce } =
      this.props
    const { value: valueObj } = this.state

    let textValue = ''
    if (valueObj.useCustom && typeof valueObj.custom !== 'undefined') {
      textValue = valueObj.custom.toString()
    } else if (typeof valueObj.default !== 'undefined') {
      textValue = valueObj.default.toString()
    }

    return (
      <FormControl classes={{ root: classes.root }} fullWidth={true} required={required}>
        {!allowHtml && (
          <Input
            label={label}
            sublabel={sublabel}
            onChange={this.handleInputChange}
            value={textValue}
            classes={{
              focused: classes.inputFocused
            }}
            required={required}
            multiline={true}
            fullWidth={true}
          />
        )}
        {allowHtml && (
          <HtmlEditor
            label={label}
            toolbar={{
              headings: false
            }}
            sublabel={sublabel}
            value={textValue}
            onChange={this.handleHtmlInputChange}
          />
        )}
      </FormControl>
    )
  }

  public handleInputChange = (event: any) => {
    const { value: inputValue } = event.target

    const value = {
      ...this.state.value,
      useCustom: true,
      custom: inputValue
    }

    this.setState({ value })

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  public handleHtmlInputChange = (v: string) => {
    // if (v === this.state.value.default) {
    //   return
    // }

    const newValue = {
      ...this.state.value
    }

    if (v.trim() === this.state.value.default) {
      newValue.useCustom = false
    } else {
      newValue.useCustom = true
      newValue.custom = v
    }

    this.setState({ value: newValue })

    if (this.props.onChange) {
      this.props.onChange(newValue)
    }
  }

  public handleResetDescription = (restore?: boolean) => {
    const value = {
      ...this.state.value,
      useCustom: !!restore
    }

    this.setState({ value })

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }
}

export default withStyles(styles)(OverridableTextArea)
