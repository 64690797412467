import type { OverridableValue } from 'paintscout'
import type { UpdateableProduct } from '../..'

export default function convertMaterials(product: UpdateableProduct): OverridableValue {
  const materials =
    product && product.totalPrice
      ? {
          useCustom: product.totalPrice.useCustom,
          custom: product.totalPrice.custom,
          default: product.totalPrice.default
        }
      : { useCustom: false, custom: 0, default: 0 }

  return materials
}
