import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { FormControlLabelProps as CoreFormControlLabelProps } from '@ui/core/FormControlLabel'
import CoreFormControlLabel from '@ui/core/FormControlLabel'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles<Theme, FormControlLabelProps>((theme) => ({
  root: {
    '& > .Mui-checked': {
      '& + .MuiFormControlLabel-label': {
        color: theme.palette.primary.dark
      }
    }
  },
  label: (props) => {
    const checked = props.checked
    if (checked) {
      return {
        ...theme.typography.subtitle2,
        color: theme.palette.primary.dark
      }
    } else {
      return {
        ...theme.typography.subtitle2
      }
    }
  }
}))

export interface FormControlLabelProps extends CoreFormControlLabelProps {
  classes?: CoreFormControlLabelProps['classes'] & StyleClasses<typeof useStyles>
}

const FormControlLabel = React.forwardRef(function FormControlLabel(props: FormControlLabelProps, ref) {
  const classes = useStyles(props)
  return <CoreFormControlLabel {...props} classes={classes} ref={ref} />
})

export default FormControlLabel
