import React, { useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/core'
import { createStyles, withStyles, CircularProgress } from '@material-ui/core'
import classnames from 'classnames'
import { FilterIcon } from '@ui/core/icons'
import Button from '../Button'
import ResponsivePopup from '../ResponsivePopup'

export interface FilterMenuProps {
  className?: string
  filterContent: React.ReactNode
  filtersActive?: boolean
  filterLabels?: string[]
  showExport?: boolean
  isExporting?: boolean

  onChange?: (id: string, value: any) => any
  onSearch?: () => any
  onClear?: () => any
  onExport?: () => any
}

const styles = (theme: Theme) =>
  createStyles({
    popover: {
      marginTop: 60,
      maxWidth: 725,
      minWidth: 340,
      borderRadius: theme.borderRadius.sm,
      boxShadow: 'none',
      border: '1px solid #e0e0e0',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    icon: {
      color: theme.palette.grey[400]
    },
    filterButtons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    filterButton: {
      // margin: '0 10px'
    },
    filterIcon: {
      marginRight: theme.spacing(2),
      cursor: 'pointer'
    },
    exportButton: {
      position: 'relative'
    },
    exportSpinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })

type FilterMenuPropsWithStyles = FilterMenuProps & WithStyles<typeof styles>

function FilterMenu({
  classes,
  filtersActive,
  filterContent,
  className,
  showExport,
  isExporting,
  onExport,
  onSearch,
  onClear
}: FilterMenuPropsWithStyles) {
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  return (
    <>
      <FilterIcon
        className={classes.filterIcon}
        style={{ color: filtersActive ? theme.palette.primary.main : theme.palette.secondary.light }}
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      />
      <ResponsivePopup
        classes={{
          popover: classes.popover
        }}
        open={!!anchorEl}
        PopoverProps={{
          anchorEl
        }}
        onClose={() => setAnchorEl(null)}
        footer={
          <div className={classes.filterButtons}>
            <Button prominence={3} onClick={onClear} edge={'start'}>
              Clear
            </Button>
            <div>
              {showExport && (
                <Button
                  className={classnames(classes.filterButton, classes.exportButton)}
                  prominence={3}
                  style={{ marginRight: 8 }}
                  disabled={isExporting}
                  onClick={onExport}
                >
                  Export
                  {isExporting && <CircularProgress size={24} className={classes.exportSpinner} />}
                </Button>
              )}
              <Button
                className={classes.filterButton}
                prominence={2}
                onClick={() => {
                  setAnchorEl(null)
                  onSearch()
                }}
              >
                Search
              </Button>
            </div>
          </div>
        }
      >
        {filterContent}
      </ResponsivePopup>
    </>
  )
}
export default withStyles(styles)(FilterMenu)
