/**
 * @module builder
 */
import type { QuoteDocument, OrderItem, OptionsDocument } from 'paintscout'

import type { UpdateableGroup } from '../index'

import type { QuoteItemSection, QuoteItemView } from '../../index'
import { calculateQuote } from '../../index'

import findIndex from 'lodash/findIndex'

import { getGroupDetails } from '../get-group-details'

export function updateGroup(args: {
  quote: QuoteDocument
  options: OptionsDocument
  group: UpdateableGroup
  calculate?: boolean
  section?: QuoteItemSection
}): QuoteDocument {
  const { quote, options, group, calculate, section } = args
  const { view } = group

  const areaOrder = updateOrderWithGroupParent(group, quote.order.area, 'area')
  const areaOrderIndex = findIndex(areaOrder, { key: group.key })
  if (areaOrderIndex < 0) {
    areaOrder.push({
      key: group.key,
      type: 'group'
    })
  }

  // const substrateOrder =
  //   view === 'substrate' ? updateOrderWithGroupParent(group, quote.order.substrate) : [...quote.order.substrate]

  const substrateOrder = updateOrderWithGroupParent(group, quote.order.substrate, 'substrate')
  const substrateOrderIndex = findIndex(substrateOrder, { key: group.key })
  if (substrateOrderIndex < 0) {
    substrateOrder.push({
      key: group.key,
      type: 'group'
    })
  }

  const updatedQuote = {
    ...quote,
    groups: {
      ...quote.groups,
      [group.key]: group
    },
    order: {
      ...quote.order,
      area: areaOrder,
      substrate: substrateOrder
    }
  }

  const {
    description: defaultDescription,
    price,
    files
  } = getGroupDetails({
    quote: updatedQuote,
    options,
    keys: group.children,
    section
  })

  const updatedFiles = [...files, ...group.files].filter((value, index, self) => self.indexOf(value) === index)

  updatedQuote.groups[group.key] = {
    ...updatedQuote.groups[group.key],
    description: {
      ...updatedQuote.groups[group.key].description,
      default: defaultDescription
    },
    price: {
      ...updatedQuote.groups[group.key].price,
      default: price
    },
    files: updatedFiles
  }

  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}

/**
 * Updates the items in order to include parent key for group
 */
function updateOrderWithGroupParent(group: UpdateableGroup, order: OrderItem[], view: QuoteItemView) {
  return order.map((orderItem) => {
    if (group.children.indexOf(orderItem.key) !== -1) {
      return {
        ...orderItem,
        parent: group.key
      }
    }
    return {
      ...orderItem
    }
  })
}
