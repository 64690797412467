import type { QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { roundHours } from '../util/round-hours'
import get from 'lodash/get'
import { fixNumber } from '../util/fix-number'

export function discounts(quote: QuoteDocument) {
  const newTotal = { ...quote.totals }

  if (!quote.totals) {
    quote.totals = {
      show_total: true,
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0
    }
  }

  let totalVisiblePriceDiscount = 0.0
  let totalVisibleHoursDiscount = 0.0

  let totalHiddenPriceDiscount = 0.0
  let totalHiddenHoursDiscount = 0.0

  const totalPrice = parseNumber(newTotal.after_adjustment ? newTotal.after_adjustment : newTotal.price)
  const additionalWorkPrice = parseNumber(get(quote, 'totals.additionalWork.price', 0))
  const additionalWorkMaterials = parseNumber(get(quote, 'totals.additionalWork.materials', 0))

  let priceWithAdditional = totalPrice + additionalWorkPrice + additionalWorkMaterials

  if (quote.discounts) {
    quote.discounts.forEach((discount) => {
      if (discount._deleted) {
        return
      }

      let price = 0.0
      let hours = 0.0

      if (discount.type && discount.type.value === 'value') {
        if (discount.hours && discount.calculate) {
          discount.calculated_price = parseNumber(discount.hours) * quote.hourly_rate
          price += discount.calculated_price
        } else if (discount.price) {
          price += parseNumber(discount.price)
        }

        if (discount.hours) {
          hours += parseNumber(discount.hours)
        }
      } else {
        // percentage
        if (discount.hours && discount.calculate) {
          const rate = parseNumber(discount.hours) * 0.01
          hours = (newTotal.hours + newTotal.prep) * rate
          discount.calculated_hours = roundHours(hours, quote)
          discount.calculated_price = discount.calculated_hours * quote.hourly_rate
          hours += discount.calculated_hours
          price += discount.calculated_price
        } else if (discount.price) {
          const rate = parseNumber(discount.price) * 0.01
          discount.calculated_price = priceWithAdditional * rate
          price += discount.calculated_price
        } else if (discount.hours) {
          const rate = parseNumber(discount.hours) * 0.01
          hours = (newTotal.hours + newTotal.prep) * rate
          discount.calculated_hours = roundHours(hours, quote)
          hours += discount.calculated_hours
        }
      }

      if (discount.visible) {
        totalVisiblePriceDiscount += price
      } else {
        totalHiddenPriceDiscount += price
      }

      if (discount.visibleWorkOrder) {
        totalVisibleHoursDiscount += hours
      } else {
        totalHiddenHoursDiscount += hours
      }
    })
  }

  priceWithAdditional -= totalHiddenPriceDiscount
  newTotal.hours -= totalHiddenHoursDiscount

  newTotal.total_discount += totalVisiblePriceDiscount

  newTotal.hours_after_discount =
    quote.version >= 3
      ? fixNumber(newTotal.hours + newTotal.prep - totalVisibleHoursDiscount)
      : newTotal.hours + newTotal.prep - totalVisibleHoursDiscount

  newTotal.after_discount = priceWithAdditional - newTotal.total_discount

  return newTotal
}
