// import React from 'react'
import type { PresentationSection } from 'paintscout'

// import type { Theme } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core'

// const useStyles = makeStyles<Theme, SpacerOptionsProps>((theme: Theme) => {
//   return {
//     root: {}
//   }
// })

interface SpacerOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
}

function SpacerOptions(props: SpacerOptionsProps) {
  // const classes = useStyles(props)
  // const { section, handleOptionFieldChange } = props
  // const { heightResponsive } = section.fields

  const options = []

  return options
}

export default SpacerOptions
