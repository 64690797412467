import React from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '../IconButton'
import type { IconButtonProps } from '../IconButton'
import type { StyleClasses } from '../theme'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'

export interface ActionButtonProps extends IconButtonProps {
  classes?: StyleClasses<typeof useStyles>
  className?: string
  actions: Action[]
  disabled?: boolean
  onActionClick: (event: React.MouseEvent<HTMLElement>, actionKey: string) => void
  PopupMenu: React.ComponentType<any>
  MenuItem: React.ComponentType<any>
}

export interface Action {
  key: string

  /**
   * Text of the MenuItem
   */
  label: string

  /**
   * Icon for the MenuItem
   */
  icon?: React.ComponentType<SvgIconProps>

  disabled?: boolean
}

const useStyles = makeStyles({
  root: {},
  button: {},
  menuItem: {}
})

function ActionButton({
  disabled,
  actions,
  className,
  onActionClick,
  PopupMenu,
  MenuItem,
  ...props
}: ActionButtonProps) {
  const classes = useStyles(props)
  const { classes: classProps, ...baseIconProps } = props

  return (
    <PopupMenu
      className={classnames(classes.root, className)}
      component={
        <IconButton
          className={classes.button}
          color="inherit"
          disabled={disabled}
          disableRipple={true}
          onClick={(ev) => ev.stopPropagation()}
          {...baseIconProps}
        >
          <MoreHorizIcon />
        </IconButton>
      }
    >
      {actions &&
        actions.map((action) => (
          <MenuItem
            className={classes.menuItem}
            key={action.key}
            icon={action.icon}
            disabled={action.disabled}
            onClick={(ev) => {
              onActionClick(ev, action.key)
            }}
          >
            {action.label}
          </MenuItem>
        ))}
    </PopupMenu>
  )
}

export default ActionButton
