import React, { useState, useEffect, useImperativeHandle } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { DescriptionIcon } from '@ui/core/icons'
import { HtmlContent, HtmlEditor, Placeholder } from '@ui/stickybid'

interface InlineEditableHtmlProps {
  content: string
  placeholderButtonText?: string
  handleEdit: (content: string) => void
  classes?: StyleClasses<typeof useStyles>
  isEditable?: boolean
  isSettings?: boolean
  header?: boolean
  presetColors?: string[]
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      '& .ProseMirror p': {
        '&:after': {
          display: 'none'
        }
      },
      color: theme.palette.text.primary
    },
    editorRoot: {
      cursor: 'text',
      minHeight: '0 !important',
      border: 'none !important',
      color: 'inherit',
      overflow: 'hidden'
      // '& .ProseMirror': {
      //   '& .ProseMirror-trailingBreak': {
      //     display: 'inline-block'
      //   }
      // }
    },
    placeholder: {},
    editor: {
      '& div': {
        padding: '0 !important',
        '& p': {
          margin: '0 !important'
        }
      }
    }
  }
})

export type InlineEditableHtmlRef = {
  getContent: () => string
}

const InlineEditableHtml = React.forwardRef(function InlineEditableHtml(
  props: InlineEditableHtmlProps,
  ref: React.Ref<InlineEditableHtmlRef>
) {
  const classes = useStyles(props)
  const { handleEdit, isSettings, isEditable, placeholderButtonText, header, presetColors } = props
  const [editing, setEditing] = useState(false)
  const [content, setContent] = useState(props.content)

  // reset contents to match props, needed for undo
  useEffect(() => {
    setContent(props.content)
  }, [props.content])

  const nullishContent = !content || content === '<p></p>'

  useImperativeHandle(ref, () => ({
    getContent: () => {
      return content || props.content
    }
  }))

  // instead of listening for a blur on the editor, we listen for a click outside of the section
  useEffect(() => {
    if (editing) {
      handleConfirm()
    }
  }, [isEditable])

  const handleConfirm = (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (ev) ev.stopPropagation()
    handleEdit(content)
    setEditing(false)
  }

  const handleChange = (content: string) => {
    setContent(content)
  }

  return (
    <>
      {isEditable && (
        <div
          onClick={() => {
            if (!editing) setEditing(true)
          }}
          className={classes.root}
        >
          {(!nullishContent || editing) && (
            // wrapping the RTE in a div (somehow) prevents errors from improper FloatingMenu cleanup on RTE unmount
            <div>
              <HtmlEditor
                tippyOptions={{ appendTo: document.querySelector('#BasePage-root'), zIndex: 4 }}
                header={header}
                value={content}
                classes={{ root: classes.editorRoot, editor: classes.editor }}
                onChange={handleChange}
                autofocus={nullishContent ? 'start' : null}
                hasColor={true}
                presetColors={presetColors}
              />
            </div>
          )}
          {nullishContent && !editing && (
            <Placeholder
              classes={{ placeholder: classes.placeholder }}
              Icon={DescriptionIcon}
              buttonText={placeholderButtonText}
            />
          )}
        </div>
      )}
      {!isEditable && (
        <>
          {!nullishContent && <HtmlContent className={classes.root} content={content} />}
          {nullishContent && isSettings && (
            <Placeholder
              classes={{ placeholder: classes.placeholder }}
              Icon={DescriptionIcon}
              buttonText={placeholderButtonText}
            />
          )}
        </>
      )}
    </>
  )
})

export default InlineEditableHtml
