import React, { useState } from 'react'
import type { QuoteFile, PresentationLibrary } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import Dialog from '@ui/stickybid/src/Dialog'
import { DialogActions, DialogContent, DialogTitle } from '@ui/stickybid/src/Dialog'
import {
  ConfirmationDialog,
  FileDropArea,
  IconButton,
  Tooltip,
  Typography,
  useDialogs,
  useFileDropArea,
  useMediaQuery
} from '@ui/stickybid'
import type { DialogProps } from '@ui/stickybid/src/Dialog'
import CloseButton from '@ui/stickybid/src/dialogs/CloseButton'
import { FilesGrid } from '../../'
import PublishIcon from '@material-ui/icons/Publish'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { addFilesToLibrary, removeFileFromLibrary, uuid } from '@paintscout/util/builder'
import { PRESENTATION_LABEL_PLURAL, PRESENTATION_LABEL } from '@paintscout/util'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, PresentationLibraryDialogProps>(
  (theme) => ({
    root: {},
    paper: {
      height: '100vh'
    },
    tooltipRoot: {
      display: 'inline-block',
      marginLeft: theme.spacing(2)
    },
    tooltipBadge: {
      cursor: 'pointer',
      top: 0,
      right: 0,
      '& svg': {
        fontSize: '1rem',
        color: theme.palette.grey['700']
      }
    },
    preContent: {
      padding: theme.spacing(0, 4, 1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3, 1)
      }
    },
    dropAreaRootEmpty: {
      height: '100%'
    },
    dropAreaRoot: {
      minHeight: '100%'
    },
    filesGridRoot: {
      marginTop: -7
    },
    noResultsBlock: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      height: '100%',
      justifyContent: 'center'
    }
  }),
  {
    name: 'PresentationLibraryDialog'
  }
)

export interface PresentationLibraryDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  presentationLibrary: {
    addFilesToLibrary: (args: { files: QuoteFile[] }) => void
    library: PresentationLibrary
    saveLibrary: (args: { library: PresentationLibrary }) => void
  }
  handleSelect?: (newFile: QuoteFile) => void
  onCancel: (event?: React.MouseEvent<HTMLElement>) => void
}

export default function PresentationLibraryDialog(props: PresentationLibraryDialogProps) {
  const classes = useStyles(props)
  const { onCancel, handleSelect, presentationLibrary, ...baseDialogProps } = props
  const { library: initialLibrary, saveLibrary } = presentationLibrary
  const [library, setLibrary] = useState(initialLibrary)
  const [isDirty, setIsDirty] = useState(false)
  const { openDialog, dismissDialog } = useDialogs()
  const columns = useMediaQuery('md') ? 3 : 4

  const files = library?.order.map((key) => {
    return library.values[key].file
  })
  const hasFiles = files?.length > 0

  const handleUploadFiles = (files: QuoteFile[]) => {
    setIsDirty(true)
    setLibrary(addFilesToLibrary({ files, library }))
  }

  const handleRemoveFile = (index: number) => {
    const file = files[index]
    openDialog(ConfirmationDialog, {
      message: `Are you sure you want to remove this item from your library? Existing ${PRESENTATION_LABEL_PLURAL} won't be affected.`,
      onConfirm: () => {
        setIsDirty(true)
        setLibrary(removeFileFromLibrary({ file, library }))
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  const handleReorderFiles = (files: QuoteFile[]) => {
    const newOrder = files.map((file) => file.cloudinaryPublicId) // We use cloudinaryPublicId as the key since we don't want the same picture in the library twice. The actual key is unique to each instance of the picture
    setIsDirty(true)
    setLibrary({ ...library, order: newOrder })
  }

  const handleSelectFile = (index: number) => {
    handleSelect({ ...files[index], key: uuid() })
    onCancel()
  }

  const { open, getInputProps, loading } = useFileDropArea({
    multiple: true,
    onUpload: handleUploadFiles
  })

  const handleClose = () => {
    if (isDirty) saveLibrary({ library })
    onCancel()
  }

  return (
    <Dialog {...baseDialogProps} classes={{ paper: classes.paper }} fullWidth maxWidth={'xl'}>
      <input {...getInputProps()} />
      <DialogTitle rightContent={<CloseButton onCancel={handleClose} />}>
        Library
        <Tooltip
          placement={'right'}
          content={`Add your most used images, videos, and pdfs to easily access them in any of your ${PRESENTATION_LABEL_PLURAL}.`}
          icon={<InfoOutlinedIcon />}
          classes={{ divRoot: classes.tooltipRoot, badge: classes.tooltipBadge }}
        />
      </DialogTitle>
      <DialogContent>
        <FileDropArea
          classes={{
            root: classnames({ [classes.dropAreaRoot]: hasFiles, [classes.dropAreaRootEmpty]: !hasFiles })
          }}
          forceLoading={loading}
          onUpload={(files) => handleUploadFiles(files)}
        >
          {!hasFiles ? (
            <div className={classes.noResultsBlock}>
              <Typography variant={'overline'}>{'Your Library is empty'}</Typography>
              <Typography
                variant={'body1'}
              >{`Add an item from your ${PRESENTATION_LABEL} or click the arrow to upload a file directly.`}</Typography>
            </div>
          ) : (
            <FilesGrid
              classes={{ root: classes.filesGridRoot }}
              columns={columns}
              editable
              files={files}
              isEditable
              onDelete={handleRemoveFile}
              onFileClick={handleSelect ? handleSelectFile : undefined}
              onReorder={handleReorderFiles}
              showDetails={false}
              filePreviewProps={{ showFirstPageOnly: true, fancyHover: true }}
            />
          )}
        </FileDropArea>
      </DialogContent>
      <DialogActions>
        <IconButton edge={'end'} onClick={() => open()}>
          <PublishIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}
