/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { QuoteTypeOption } from '../index'
import { getQuoteTypeOption } from './get-quote-type-option'

export function getQuoteTypeOptions(args: {
  options: OptionsDocument
  inactive?: boolean
  hideAll?: boolean
  quoteTypes?: string[]
}): QuoteTypeOption[] {
  const { options, inactive, hideAll, quoteTypes } = args
  const { intExtOptions } = options.options

  return intExtOptions.order
    .map((orderItem) => {
      return getQuoteTypeOption({ options, quoteType: orderItem })
    })
    .filter((item) => {
      if (!item) {
        return null
      }
      if (hideAll && item.key === 'all') {
        return null
      }
      if (!inactive && item.active !== true && typeof item.active !== 'undefined') {
        return null
      }

      if (quoteTypes && !quoteTypes.includes(item.key) && !quoteTypes.includes('all')) {
        return null
      }

      return item
    })
}
