import type { FeatureOptions } from 'paintscout'
import type { BillingPlan } from '../index'
import { getFeatures } from '../get-features'
const deepmerge = require('deepmerge')

const isLiveEnv = ['sb-test', 'sb'].includes(process.env.DEPLOYMENT_STAGE)
export const STARTER_ANNUAL_PRICE_ID = isLiveEnv ? 'price_1Na06tG3gxO33q1Oh9px5uUG' : 'price_1NL7QcG3gxO33q1OSajFSIyd'
export const STARTER_MONTHLY_PRICE_ID = isLiveEnv ? 'price_1Na06tG3gxO33q1Oepo2DBGX' : 'price_1NL7RVG3gxO33q1O8ckoxUXI'
export const PROFESSIONAL_ANNUAL_PRICE_ID = isLiveEnv
  ? 'price_1Na073G3gxO33q1OQBOAg56p'
  : 'price_1NL7XNG3gxO33q1OMF2cKlv3'
export const PROFESSIONAL_MONTHLY_PRICE_ID = isLiveEnv
  ? 'price_1Na073G3gxO33q1OMntMs95H'
  : 'price_1NL7XlG3gxO33q1ODe19krDV'
export const ADDITIONAL_USER_ANNUAL_PRICE_ID = isLiveEnv
  ? 'price_1Na0FFG3gxO33q1OYZIZ7HbA'
  : 'price_1NLYmYG3gxO33q1O41pmEAeh'
export const ADDITIONAL_USER_MONTHLY_PRICE_ID = isLiveEnv
  ? 'price_1Na0FFG3gxO33q1Oy1GMw586'
  : 'price_1NL7ZfG3gxO33q1OUK196eJV'

export function getPlans(): BillingPlan[] {
  // defaultFeatures the most limited (aka starter features)
  // add more features to trial and professional
  // !! KEEP featureLabels in PlanSelection SYNCED WITH BELOW !!

  const defaultFeatures = getFeatures()

  const professionalFeatures: FeatureOptions = deepmerge(defaultFeatures, {
    importQuoteImage: {
      enabled: true,
      ocr: {
        enabled: false
      }
    },
    quotes: {
      followUps: { enabled: true, sendText: false, userFollowUps: { enabled: false } }
    }
  })

  const starterFeatures = defaultFeatures

  const trialFeatures = professionalFeatures

  const enterpriseFeatures = professionalFeatures

  // !! plan values must also be present in stripe subscription price metadata !!
  const plans: BillingPlan[] = [
    {
      value: 'starter-an',
      label: 'Starter',
      features: starterFeatures,
      description: 'Enhance your current pricing software with stunning proposals.',
      price: '$35',
      priceId: STARTER_ANNUAL_PRICE_ID
    },
    {
      value: 'starter-mo',
      label: 'Starter',
      features: starterFeatures,
      description: 'Enhance your current pricing software with stunning proposals.',
      price: '$39',
      priceId: STARTER_MONTHLY_PRICE_ID
    },
    {
      value: 'professional-an',
      label: 'Professional',
      features: professionalFeatures,
      description: 'Scale your business with tools to help manage your sales workflow.',
      price: '$79',
      priceId: PROFESSIONAL_ANNUAL_PRICE_ID
    },
    {
      value: 'professional-mo',
      label: 'Professional',
      features: professionalFeatures,
      description: 'Scale your business with tools to help manage your sales workflow.',
      price: '$87',
      priceId: PROFESSIONAL_MONTHLY_PRICE_ID
    },
    {
      value: 'enterprise-an',
      label: 'Enterprise',
      features: enterpriseFeatures,
      description: `Custom solutions for your business's unique needs.`,
      price: 'Custom',
      priceId: 'custom'
    },
    {
      value: 'enterprise-mo',
      label: 'Enterprise',
      features: enterpriseFeatures,
      description: `Custom solutions for your business's unique needs.`,
      price: 'Custom',
      priceId: 'custom'
    },
    {
      value: 'trial',
      label: 'Trial',
      features: trialFeatures,
      description: '',
      price: '',
      priceId: ''
    }
  ]

  return plans
}
