import type { AreaSubstrate, OverridableValue } from 'paintscout'

export default function convertRateValue(areaSubstrate: AreaSubstrate): OverridableValue {
  let defaultRateValue = areaSubstrate.rate?.values[Number(areaSubstrate.coats) - 1]
  if (typeof defaultRateValue === 'undefined') {
    defaultRateValue = parseFloat(defaultRateValue)
  }

  const rateValue = {
    useCustom: areaSubstrate.rate_value !== defaultRateValue,
    custom: Number(areaSubstrate.rate_value),
    default: defaultRateValue
  }

  return rateValue
}
