import type { ClientMetaDocument, OptionsDocument, PresentationOption } from 'paintscout'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  Button,
  ConfirmationDialog,
  FormSection,
  FormSectionTitle,
  Grid,
  ImportJsonDialog,
  InputDialog,
  PresentationTileList,
  useDialogs
} from '@ui/stickybid'
import { PRESENTATION_LABEL, PRESENTATION_LABEL_PLURAL } from '@paintscout/util'
import { QuotePreviewDialog } from '../../dialogs'
import { useFormikContext } from 'formik'
import {
  copyPresentationOption,
  deletePresentationOption,
  getIsTrial,
  getPresentationAllowance,
  getPresentationOptions,
  getPresentationPreviewQuote,
  updatePresentationOption
} from '@paintscout/util/builder'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles<Theme, AdminPresentationOptionsProps>(
  (theme) => ({
    root: {}
  }),
  { name: 'AdminPresentationOptions' }
)

export interface AdminPresentationOptionsProps {
  classes?: StyleClasses<typeof useStyles>
}

function AdminPresentationOptions(props: AdminPresentationOptionsProps) {
  const classes = useStyles(props)
  const { values, setFieldValue, errors } = useFormikContext<{
    options: OptionsDocument
    meta: ClientMetaDocument
  }>()
  const { options, meta } = values
  const presentationOptions = getPresentationOptions({ options, inactive: true })
  const presentationCount = presentationOptions.length - 1 // less one for the default presentation
  const { subscription } = meta || {}
  const { plan_price_id } = subscription || {}
  const isTrial = getIsTrial({ meta, options })
  const presentationAllowance = getPresentationAllowance({ isTrial, plan_price_id, options })
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog } = useDialogs()

  const handlePreviewPresentation = (ev, key: string) => {
    openDialog(QuotePreviewDialog, {
      view: 'quote',
      givenPresentation: presentationOptions.find((pres) => pres.key === key),
      givenQuote: getPresentationPreviewQuote(),
      onConfirm: dismissDialog,
      onClose: dismissDialog
    })
  }

  const handleExportPresentation = (event: any, key: string) => {
    const presOpt = presentationOptions.find((pres) => pres.key === key)
    navigator.clipboard.writeText(JSON.stringify(presOpt))
    enqueueSnackbar(`${PRESENTATION_LABEL} copied to clipboard.`, { variant: 'success' })
  }

  const handleImportPresentation = () => {
    openDialog(ImportJsonDialog, {
      onImport: (json: JSON) => {
        try {
          const importedPresentation = json as unknown as PresentationOption
          const formerOrder = options.options.presentationOptions?.order
          const formerKeys = formerOrder ? [...options.options.presentationOptions.order] : []
          const isCopy = formerKeys.includes(importedPresentation.key)
          const updatedOptions = isCopy
            ? copyPresentationOption({
                presentation: importedPresentation,
                options: options
              })
            : updatePresentationOption({
                presentationOption: importedPresentation,
                options: options
              })
          const latterKeys = [...updatedOptions.options.presentationOptions.order]
          formerKeys.forEach((key) => {
            const index = latterKeys.indexOf(key)
            if (index > -1) latterKeys.splice(index, 1)
          })
          setFieldValue('options', updatedOptions)
          dismissDialog()
        } catch (err) {
          enqueueSnackbar(`Error importing ${PRESENTATION_LABEL}`, { variant: 'error' })
        }
      },
      onClose: dismissDialog
    })
  }

  const handleRenamePresentation = (event: any, key: string) => {
    const presOpt = presentationOptions.find((pres) => pres.key === key)
    openDialog(InputDialog, {
      title: `Rename ${PRESENTATION_LABEL}`,
      label: 'Title',
      defaultValue: presOpt.label,
      onConfirm: (newLabel: string) => {
        const updatedOptions = updatePresentationOption({
          presentationOption: { ...presOpt, label: newLabel },
          options
        })
        setFieldValue('options', updatedOptions)
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  const handleDeletePresentation = (event: any, key: string) => {
    openDialog(ConfirmationDialog, {
      message: `Are you sure you want to delete this ${PRESENTATION_LABEL}?`,
      onConfirm: () => {
        const presOpt = presentationOptions.find((pres) => pres.key === key)
        const updatedOptions = deletePresentationOption({
          presentationOption: presOpt,
          options
        })
        setFieldValue('options', updatedOptions)
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 8 }} alignItems={'flex-end'} justifyContent={'space-between'}>
        <Grid item xs={12} md style={{ marginBottom: 'auto' }}>
          <FormSectionTitle
            title={PRESENTATION_LABEL_PLURAL}
            subTitle={`${presentationCount} / ${presentationAllowance} ${PRESENTATION_LABEL_PLURAL}`}
            variant={'h2'}
          />
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <Button prominence={2} onClick={handleImportPresentation}>
            + Import JSON
          </Button>
        </Grid>
      </Grid>

      <FormSection>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PresentationTileList
              disableInactive={false}
              onDeleteItem={handleDeletePresentation}
              onExportItem={handleExportPresentation}
              onRenameItem={handleRenamePresentation}
              onSelectItem={handlePreviewPresentation}
              presentationOptions={presentationOptions}
              showActive={true}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default AdminPresentationOptions
