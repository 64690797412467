/**
 * @module builder
 */
import type { LineItemOption } from '../index'
import { uuid } from '../../util'

export function createLineItemOption(args?: {
  quoteType?: string
  calculate?: boolean
  calculateBy?: 'none' | 'hourlyRate' | 'quantity'
  quantity?: number
  showPrice?: boolean
  hideQuantity?: boolean
}): LineItemOption {
  const { quoteType, calculate, calculateBy, quantity, showPrice, hideQuantity } = args ?? {}

  const quoteTypes = quoteType ? [quoteType] : ['all']

  return {
    name: uuid(),
    label: '',
    description: '',
    hours: null,
    price: null,
    calculate: false,
    customHourlyRate: false,
    hideOnWorkOrder: false,
    hourlyRate: 0,
    crewNote: '',
    clientNote: '',
    quoteTypes,
    active: true,
    source: { provider: '', id: '', value: '', label: '' },
    // stickybid
    ...(calculate && { calculate }),
    ...(calculateBy && { calculateBy }),
    ...(quantity && { quantity }),
    ...(showPrice && { showPrice }),
    ...(hideQuantity && { hideQuantity })
  }
}
