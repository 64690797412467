/**
 * Any values that aren't sensitive and don't change between deployments
 * (otherwise they belong in .env)
 */

/**
 * Number of quotes allowed on trial accounts
 */
export const TRIAL_QUOTE_LIMIT = 15

/**
 * Number of files allowed on a quote
 */
export const TRIAL_QUOTE_FILES_LIMIT = 5

/**
 * REGEX to match valid UUID
 */
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

/**
 * Customer facing label for presentations
 */
export const PRESENTATION_LABEL = 'Sell Page'
export const PRESENTATION_LABEL_PLURAL = 'Sell Pages'

/**
 * companyId for all clients on the test deployment
 */
export const TEST_CLIENTS = [
  'bfivozgzqpuitatz',
  'uzjspkgjssyefboe',
  'tbqqqaprhvfxgreb',
  'iypaddsiyiuzzryh',
  'tsqxmpxhtfapssri',
  'fcnkuzoiwaechbvn'
]
