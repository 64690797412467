import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/stickybid'
import { Checkbox, FormControlLabel } from '@ui/stickybid'
import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DropdownSelect,
  InputField,
  KeypadInputField,
  useClientOptions
} from '@ui/stickybid'
import { getPayments, setPayments, uuid } from '@paintscout/util/builder'
import get from 'lodash/get'
import set from 'lodash/set'
import moment from 'moment'
import type { Payment, QuoteDocument } from 'paintscout'
import { useQuote } from '../../context/useQuote'
import { CloseButton } from '../'

export interface PaymentsDialogProps extends DialogProps {
  paymentIndex?: number
  onConfirm?: (quote: QuoteDocument) => void
  onCancel?: () => void
}

export interface PaymentsDialogState {
  existing: boolean
  payment: Payment
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing()
  }
}))

function PaymentsDialog(props: PaymentsDialogProps) {
  const { open, paymentIndex, onConfirm, onCancel, ...baseDialogProps } = props
  const classes = useStyles(props)
  const { options, save } = useClientOptions()
  const { quote, updateQuote } = useQuote()

  const existingPayment = typeof paymentIndex !== 'undefined' && getPayments({ quote })[paymentIndex]
  const [sendPaymentReceivedOnAdd, setSendPaymentReceivedOnAdd] = useState(
    options.options.emails.sendPaymentReceivedOnAdd ?? false
  )
  const [submitting, setSubmitting] = useState(false)

  const [payment, setPayment] = useState<Payment>(
    existingPayment || {
      id: uuid(),
      type: 'Check',
      amount: quote.totals.balance_due,
      date: Date.now(),
      note: '',
      deposit: false
    }
  )

  const dateFormat = get(options, 'options.dateFormat.momentValue', 'YYYY-MM-DD')
  const dateValue = moment(payment.date)

  const typeOptions = [
    { value: 'Check', label: 'Check' },
    { value: 'Cash', label: 'Cash' },
    { value: 'E-Transfer', label: 'E-Transfer' },
    { value: 'Credit Card', label: 'Credit Card' },
    { value: 'Visa', label: 'Visa' },
    { value: 'Mastercard', label: 'Mastercard' },
    { value: 'AMEX', label: 'AMEX' },
    { value: 'Venmo', label: 'Venmo' },
    { value: 'ACH', label: 'ACH' },
    { value: 'Wire Transfer', label: 'Wire Transfer' },
    { value: 'Other', label: 'Other' }
  ]

  const typeValue = {
    value: payment.type,
    label: payment.type
  }

  return (
    <Dialog open={open} maxWidth="md" {...baseDialogProps}>
      <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>
        {existingPayment ? 'Edit' : 'Add'} Payment
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <DropdownSelect
            value={typeValue}
            label={'Type/Method'}
            searchable={false}
            fullWidth={true}
            multiSelect={false}
            name={'type'}
            onChange={handleTypeChange}
            options={typeOptions}
          />
          <KeypadInputField
            label={'Amount'}
            value={payment.amount}
            fullWidth={true}
            // format={'price'}
            name={'amount'}
            onChange={handleKeypadChange}
          />
          <DatePicker
            id="payment-date"
            format={dateFormat}
            value={dateValue}
            onChange={handleDateChange}
            InputProps={{
              fullWidth: true,
              label: 'Payment Date'
            }}
          />
          <InputField
            label={'Note'}
            value={payment.note}
            multiline={true}
            name={'note'}
            fullWidth={true}
            onChange={handleChange}
          />
          {!existingPayment && (
            <FormControlLabel
              label={'Send payment receipt email to customer'}
              control={
                <Checkbox
                  name={'email'}
                  checked={sendPaymentReceivedOnAdd}
                  onChange={() => setSendPaymentReceivedOnAdd(!sendPaymentReceivedOnAdd)}
                />
              }
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button type={'submit'} prominence={1} onClick={handleConfirm} loading={submitting}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleChange(event: any) {
    const { value, name, type } = event.target

    setPayment({
      ...payment,
      [name]: type === 'number' ? Number(value) : value
    })
  }

  function handleKeypadChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target

    setPayment({
      ...payment,
      [name]: Number(value)
    })
  }

  function handleDateChange(value: moment.Moment) {
    setPayment({
      ...payment,
      date: value.unix() * 1000
    })
  }

  function handleTypeChange(value: any) {
    setPayment({
      ...payment,
      type: value.value
    })
  }

  async function handleConfirm() {
    setSubmitting(true)
    const payments = getPayments({ quote })

    if (typeof paymentIndex !== 'undefined') {
      payments[paymentIndex] = {
        ...payment,
        amount: typeof payment.amount === 'number' ? payment.amount : parseFloat(payment.amount)
      }
    } else {
      payments.push({
        ...payment,
        id: uuid(),
        amount: typeof payment.amount === 'number' ? payment.amount : parseFloat(payment.amount)
      })
    }

    if (options.options.emails.sendPaymentReceivedOnAdd !== sendPaymentReceivedOnAdd) {
      const updatedOptions = set(options, 'options.emails.sendPaymentReceivedOnAdd', sendPaymentReceivedOnAdd)
      await save({ options: updatedOptions })
    }

    const updatedQuote = setPayments({ quote, options, payments })
    updateQuote({ quote: updatedQuote })

    if (onConfirm) {
      onConfirm(updatedQuote)
    }
  }
}

export default PaymentsDialog
