import React from 'react'
import Button from '../Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    paddingTop: '0.1em',
    paddingBottom: '0.1em'
  }
})

export function LearnMoreLink({ link, label, ...props }: { link: string; label?: string }) {
  const classes = useStyles({ props })
  return (
    <Button className={classes.root} variant="text" size="small" href={link}>
      {label ? label : 'Learn More'}
    </Button>
  )
  // return (
  //   <Button className={classes.root} variant="text" size="small" href={link} icon={LaunchIcon}>
  //     {label ? label : 'Learn More'}
  //   </Button>
  // )
}
