import React from 'react'
import type { PresentationIndustry, QuoteFile, PresentationLibrary } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { Card as MuiCard, IconButton, PresEditorTooltip } from '@ui/stickybid'
import { InlineEditableHtml, InlineEditableMedia } from '../../../inlineEditable'
import classnames from 'classnames'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Star from '@material-ui/icons/Star'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

const useStyles = makeStyles<Theme, CardProps>(
  (theme: Theme) => {
    return {
      root: {
        overflow: 'visible',
        padding: 0,
        backgroundColor: 'transparent',
        '& :hover': {
          '& > $editControls': {
            display: 'block'
          }
        }
      },
      mediaRoot: {},
      spacer: {
        height: theme.spacing(3)
      },
      contentRoot: {
        padding: 0,
        position: 'relative',
        // last child
        '&:last-child': {
          paddingBottom: 0
        }
      },
      cardColor: (props, color = props.card.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        },
        '& div': {
          color: `${color} !important`
        }
      }),
      editControls: {
        // in the style of IconMenu
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.borderRadius.md,
        bottom: -41,
        boxShadow: theme.boxShadow[1],
        display: 'none',
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        zIndex: 4,
        '& button': {
          borderRadius: 0,
          padding: theme.spacing(1),
          '& svg': {
            color: theme.palette.grey[500]
          },
          '&:hover': {
            '&$deleteButton': {
              '& svg': {
                color: 'red'
              }
            },
            '& svg': {
              color: theme.palette.text.primary
            }
          }
        }
      },
      deleteButton: {
        '&$deleteButton': {
          '& svg': {
            color: 'red'
          }
        }
      },
      quoteIcon: {
        fontSize: '1.6em',
        transform: 'rotate(180deg)',
        margin: theme.spacing(-0.5, 0, 1, -0.5)
      },
      stars: {
        '& svg': {
          fontSize: '1.1em'
        },
        margin: theme.spacing(2.5, 0)
      },
      tooltipPlacementBottom: {
        marginTop: theme.spacing(3)
      },
      tooltipPlacementTop: {
        marginBottom: theme.spacing(2.5)
      }
    }
  },
  { name: 'Cards' }
)

export interface CardProps {
  card: any
  classes?: any
  handleCopyCard: (index: number) => void
  handleDeleteCard: (index: number) => void
  handleEditField: (field: string, content: any) => void
  handleMediaClick?: () => void
  handleReorderCard: (oldIndex: number, direction: 'left' | 'right') => void
  index: number
  isAnimated: boolean
  isEditable: boolean
  isSettings: boolean
  presentationIndustry: PresentationIndustry
  presentationLibrary: {
    addFilesToLibrary: (args: { files: QuoteFile[] }) => void
    library: PresentationLibrary
    saveLibrary: (args: { library: PresentationLibrary }) => void
  }
  presetColors?: string[]
  sectionPrefix: string
  type: 'presentation' | 'testimonials'
}

function Card(props: CardProps) {
  const classes = useStyles(props)
  const {
    card,
    handleCopyCard,
    handleDeleteCard,
    handleEditField,
    handleMediaClick,
    handleReorderCard,
    index,
    isAnimated,
    isEditable,
    isSettings,
    presentationIndustry,
    presentationLibrary,
    presetColors,
    sectionPrefix,
    type
  } = props
  const { content, credit, file, key, color, backgroundColor, imageOnly } = card

  return (
    <MuiCard
      id={key}
      classes={{ root: classes.root }}
      CardContentProps={{
        classes: { root: classnames({ [classes.contentRoot]: true, [classes.cardColor]: card.color }) }
      }}
      contentChildren={
        <>
          {isSettings && (
            <>
              <div className={classes.editControls}>
                <PresEditorTooltip
                  title={'Duplicate'}
                  classes={{
                    tooltipPlacementTop: classes.tooltipPlacementTop,
                    tooltipPlacementBottom: classes.tooltipPlacementBottom
                  }}
                >
                  <IconButton onClick={() => handleCopyCard(index)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </PresEditorTooltip>
                <PresEditorTooltip
                  title={'Move Left'}
                  classes={{
                    tooltipPlacementTop: classes.tooltipPlacementTop,
                    tooltipPlacementBottom: classes.tooltipPlacementBottom
                  }}
                >
                  <IconButton disabled={false} onClick={() => handleReorderCard(index, 'left')}>
                    <ArrowBackIcon />
                  </IconButton>
                </PresEditorTooltip>
                <PresEditorTooltip
                  title={'Move Right'}
                  classes={{
                    tooltipPlacementTop: classes.tooltipPlacementTop,
                    tooltipPlacementBottom: classes.tooltipPlacementBottom
                  }}
                >
                  <IconButton disabled={false} onClick={() => handleReorderCard(index, 'right')}>
                    <ArrowForwardIcon />
                  </IconButton>
                </PresEditorTooltip>
                <PresEditorTooltip
                  title={'Delete'}
                  classes={{
                    tooltipPlacementTop: classes.tooltipPlacementTop,
                    tooltipPlacementBottom: classes.tooltipPlacementBottom
                  }}
                >
                  <IconButton
                    className={classes.deleteButton}
                    data-testid="delete-button"
                    onClick={() => handleDeleteCard(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </PresEditorTooltip>
              </div>
            </>
          )}
          {type === 'presentation' && (
            <>
              <InlineEditableMedia
                mediaOptionsProps={{
                  sectionPrefix: sectionPrefix,
                  fieldSuffix: `cards[${index}].file`
                }}
                accept={'application/pdf, image/*'}
                classes={{ image: classes.mediaRoot }}
                file={file}
                handleEdit={(file) => handleEditField(`fields.cards.${index}.file`, file)}
                isAnimated={isAnimated}
                isEditable={isEditable}
                isSettings={isSettings}
                showAllPages
                placeholderButtonText={'Add Image'}
                presentationIndustry={presentationIndustry}
                presentationLibrary={presentationLibrary}
                youtube={false}
                filePreviewProps={{ showFirstPageOnly: true, onClick: handleMediaClick }}
              />
              {!imageOnly && (
                <>
                  {(file || isSettings) && <div className={classes.spacer}></div>}
                  <InlineEditableHtml
                    placeholderButtonText={'Add Text'}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    handleEdit={(content) => handleEditField(`fields.cards.${index}.content`, content)}
                    content={content}
                    presetColors={presetColors}
                  />
                </>
              )}
            </>
          )}
          {type === 'testimonials' && (
            <>
              <div>
                <FormatQuoteIcon className={classes.quoteIcon} />
                <InlineEditableHtml
                  placeholderButtonText={'Add Testimonial'}
                  isEditable={isEditable}
                  isSettings={isSettings}
                  handleEdit={(content) => handleEditField(`fields.cards.${index}.content`, content)}
                  content={content}
                  presetColors={presetColors}
                />
              </div>
              <div>
                <Stars />
                <InlineEditableHtml
                  placeholderButtonText={'Add Author'}
                  isEditable={isEditable}
                  isSettings={isSettings}
                  handleEdit={(credit) => handleEditField(`fields.cards.${index}.credit`, credit)}
                  content={credit}
                  presetColors={presetColors}
                />
              </div>
            </>
          )}
        </>
      }
    />
  )
  function Stars() {
    return (
      <div className={classes.stars}>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </div>
    )
  }
}

export default Card
