import type { LineItem, OrderItem, QuoteOptions } from 'paintscout'
import type { RenderableItem, QuoteItemConsumer, UpdateableProduct } from '../../index'
import get from 'lodash/get'
import { getProductLabel, roundPrice } from '../../util'
import { fixToString } from '../../../../calculator'

export function trimLineItem(
  item: LineItem & OrderItem,
  options: QuoteOptions,
  consumer: QuoteItemConsumer
): RenderableItem {
  const additionalFields: { [key: string]: any } = { materials: [] }

  if (consumer === 'crew' && item.crew_note) {
    additionalFields.crewNote = item.crew_note
  } else if (consumer === 'customer' && item.client_note) {
    additionalFields.clientNote = item.client_note
  }

  const price = get(item, 'totals.afterMaterials', item.price)
  const hours = item?.hours ?? 0

  additionalFields.painting = hours
  additionalFields.price = Number(roundPrice(item?.price ?? 0))

  const descriptionComponents = [item.description]

  if (consumer === 'crew' && item.materials) {
    item.materials.forEach((material: UpdateableProduct) => {
      const priceValue = material.totalPrice.useCustom ? material.totalPrice.custom : material.totalPrice.default

      const m = [
        material.label,
        material.quantity,
        priceValue && !options?.hideWorkOrderMaterialCost ? `$${fixToString(priceValue)}` : ''
      ]
        .filter((i) => i)
        .join(' - ')

      const unitLabel = material?.unitLabel ?? 'gal'

      descriptionComponents.push('<p>' + m + '</p>')

      additionalFields.materials.push({
        label: getProductLabel(material),
        volume: material.quantity,
        unitPrice: material.price?.useCustom ? material.price?.custom : material.price.default,
        unitLabel,
        totalPrice: priceValue
      })
    })
  }

  if (consumer === 'customer' && item.client_note) {
    descriptionComponents.push(item.client_note)
  }

  const description = descriptionComponents.filter((i) => i).join('\n')
  let name = item.name
  const displayHours =
    options.showWorkOrderAreaValues && options.showWorkOrderSubstrateValues && item.hoursPerUnit
      ? ` @ ${item.hoursPerUnit}hr`
      : ''

  const quantity = item.quantity !== undefined ? parseFloat(item.quantity as any) : 0

  if (process.env.PRODUCT === 'sb') {
    additionalFields.quantity = quantity || 0
    additionalFields.pricePerUnit = item.pricePerUnit || 0
    additionalFields.hideQuantity = item.hideQuantity
    additionalFields.showPrice = item.showPrice
    additionalFields.acceptedOption = item.acceptedOption
  }

  if (process.env.PRODUCT === 'ps') {
    // add quant to quote if not hidden, greater than 0, and not equal to 1
    if (
      consumer === 'customer' &&
      item.calculateBy === 'quantity' &&
      !item.hideQuantity &&
      quantity > 0 &&
      quantity !== 1
    ) {
      name = `${item.name} x ${item.quantity}`
    }

    // always add quant to work order as hours makes no sense without it
    if (consumer === 'crew') {
      name =
        item.calculateBy === 'quantity' && quantity > 0 ? `${item.name} x ${item.quantity}${displayHours}` : item.name
    }
  }

  return {
    key: item.key,
    type: item.type,
    name: name,
    files: item.files,
    description: { useCustom: false, default: description, custom: null },
    value: consumer === 'crew' ? hours : price,
    additionalFields,
    source: item?.source ?? { provider: '', id: '', value: '', label: '' }
  }
}
