import type { UserPreferences } from 'paintscout'
import React, { useState, useEffect } from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import KeypadInputField from '../KeypadInputField'
import type { InputFieldProps } from '../InputField'

import Typography from '../Typography'

import type { OverridableValue } from '@paintscout/util/builder'

import ResetAdornment from './ResetAdornment'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    inputFocused: {
      '& $adornment': {
        color: theme.palette.primary.main
      }
    },
    icon: {},
    adornment: {
      marginLeft: theme.typography.pxToRem(7),
      marginRight: theme.typography.pxToRem(4),
      color: theme.palette.grey[500]
    },
    helperText: {},
    inputInput: {}
  })

export interface OverridableInputFieldProps
  extends Omit<InputFieldProps, 'value' | 'onChange' | 'classes'>,
    WithStyles<typeof styles> {
  value?: OverridableValue
  helpPrefix?: string
  helpText?: string
  hideHelp?: boolean
  resetBlank?: boolean
  flipX?: boolean
  preferences?: UserPreferences
  onChange?: (value: OverridableValue) => void
  showKeypad?: 'always' | 'touch' | 'never'
}

function OverridableInputField(props: OverridableInputFieldProps) {
  const {
    classes,
    value,
    resetBlank,
    helpPrefix = 'Default Value:',
    hideHelp,
    helpText,
    format,
    onChange,
    flipX,
    preferences,
    ...baseInputProps
  } = props

  const [rawValue, setRawValue] = useState(value.useCustom ? value.custom : value.default)

  useEffect(() => {
    setRawValue(value.useCustom ? value.custom : value.default)
  }, [value])

  let helperText = null
  if (!hideHelp && helpText) {
    helperText = <>{helpText}</>
  } else if (!hideHelp && value.useCustom && value.default) {
    helperText = (
      <>
        {helpPrefix}{' '}
        <Typography
          display="inline"
          variant={'subtitle2'}
          component={'span'}
          format={format}
          classes={{ root: classes.helperText }}
          value={value.default}
        />
      </>
    )
  }

  // let numberValue = value.useCustom ? value.custom : value.default
  // if (isNaN(numberValue)) {
  //   numberValue = ''
  // }
  // console.log({ numberValue })

  return (
    <>
      <KeypadInputField
        {...baseInputProps}
        classes={{
          root: classes.root,
          adornment: classes.adornment,
          icon: classes.icon,
          inputFocused: classes.inputFocused,
          inputInput: classes.inputInput
        }}
        value={isNaN(rawValue) ? '' : rawValue}
        format={format}
        helperText={helperText}
        onChange={handleChange}
        onBlur={handleBlur}
        flipX={flipX}
        endAdornment={value.useCustom ? <ResetAdornment onResetClick={handleResetValue} /> : null}
      />
    </>
  )

  function handleResetValue(event: React.MouseEvent<HTMLElement>) {
    const updatedValue = {
      ...value,
      useCustom: false
    }

    setRawValue(value.default)

    if (onChange) {
      onChange(updatedValue)
    }
  }

  function handleBlur(event: React.FocusEvent) {
    if (resetBlank && rawValue === '') {
      setRawValue(value.default)
      onChange({ ...value, useCustom: false })
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const previousValue = value.useCustom ? value.custom : value.default

    const newRawValue = event.target.value
    setRawValue(newRawValue)

    if (newRawValue.slice(-1) === '.' || newRawValue === '') {
      return null
    }

    const newValue = parseFloat(newRawValue)
    const defaultValue = typeof value.default === 'string' ? parseFloat(value.default) : value.default

    if (value.useCustom && newValue === defaultValue) {
      if (onChange) {
        onChange({ ...value, useCustom: false })
      }
    } else if (previousValue === newValue) {
      return null
    } else if (((value as any) !== '' && resetBlank) || (value as any) === '') {
      if (onChange) {
        onChange({ ...value, useCustom: true, custom: newValue })
      }
    }
  }
}

export default withStyles(styles)(OverridableInputField)
