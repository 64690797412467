import { useQuery } from '@apollo/react-hooks'
import { Card } from '@material-ui/core'
import type { DialogProps } from '@ui/stickybid'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, SearchBar } from '@ui/stickybid'
import { jsonToLucene } from 'json-to-lucene'
import type { ClientMetaDocument } from 'paintscout'
import React, { useMemo, useState } from 'react'
import type { SearchClientsResponse } from '../graphql/queries/SEARCH_CLIENTS'
import { SEARCH_CLIENTS } from '../graphql/queries/SEARCH_CLIENTS'
import useDebounce from '../hooks/useDebounce'
import ClientSearchTable from './ClientSearchTable'
import ErrorMessage from './ErrorMessage'

export interface SearchClientsDialogProps extends DialogProps {
  title?: string
  // returns search result of client
  onConfirm: (client: Partial<ClientMetaDocument>) => any
}

export default function SearchClientsDialog({
  onConfirm,
  title = 'Search Clients',
  ...props
}: SearchClientsDialogProps) {
  const limit = 20
  const [text, setText] = useState('')
  const [page, setPage] = useState(1)

  const query = useMemo(
    () =>
      jsonToLucene({
        query: text
      }) || '*:*',
    // debounce how often  useMemo runs to create the new query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useDebounce(text, 250)]
  )
  const { loading, data, error, fetchMore } = useQuery<SearchClientsResponse>(SEARCH_CLIENTS, {
    variables: {
      query,
      limit
    },
    notifyOnNetworkStatusChange: true
  })

  const handleClientSearch = (query: string) => {
    setText(query)
    setPage(1)
  }

  const clients = data?.searchClients?.rows
  const totalRows = data?.searchClients?.total_rows ?? 0

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <SearchBar value={text} onChange={(query) => handleClientSearch(query)} />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <Card elevation={1}>
          <ClientSearchTable
            clients={loading ? [] : clients}
            loading={loading}
            rowsPerPage={limit}
            page={page}
            pages={Math.ceil(totalRows / limit)}
            onPageChange={(newPage) => setPage(newPage)}
            onClientClick={(row) => {
              onConfirm(row)
            }}
            onFetchNextPage={() => {
              fetchMore({
                variables: {
                  query,
                  limit,
                  bookmark: data.searchClients.bookmark
                },
                updateQuery(prev, { fetchMoreResult }) {
                  if (!fetchMoreResult) {
                    return prev
                  }

                  return {
                    ...prev,
                    searchClients: {
                      ...prev.searchClients,
                      bookmark: fetchMoreResult.searchClients.bookmark,
                      rows: [...prev.searchClients.rows, ...fetchMoreResult.searchClients.rows]
                    }
                  }
                }
              })
            }}
          />
        </Card>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button prominence={3} onClick={() => props.onClose(null, null)}>
            Cancel
          </Button>
        }
      />
    </Dialog>
  )
}
