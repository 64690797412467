import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import type { Action } from '../ActionButton'
import Typography from '../Typography'
import type { PresentationOption } from 'paintscout'
import type { TileListProps } from '../TileList'
import TileList from '../TileList'
import { useClientOptions } from '@ui/core/ClientOptionsProvider'
import { getObjectLabels } from '@paintscout/util/builder'

import { DeleteIcon } from '@ui/core/icons'
import EditIcon from '@material-ui/icons/EditOutlined'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

const useStyles = makeStyles<Theme, PresentationTileListProps>((theme) => ({
  root: {},
  tiles: (props, maxColumns = props.maxColumns || 3) => ({
    // cols equal to maxCols, force to fewer as needed as screen gets narrower
    gridTemplateColumns: `repeat(${maxColumns}, 1fr)`,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(${Math.min(2, maxColumns)}, 1fr)`
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  })
}))

export interface PresentationTileListProps {
  actions?: Action[]
  classes?: StyleClasses<typeof useStyles>
  disableActions?: boolean
  disabled?: boolean
  disableInactive?: boolean
  disableUnselect?: boolean
  hasRoomForNewPresentation?: boolean
  hasSelectedIcon?: boolean
  maxColumns?: number
  presentationOptions: PresentationOption[]
  selected?: string
  showActive?: boolean
  showUnselected?: TileListProps<any, any, any>['showUnselected']
  title?: string
  variant?: TileListProps<any, any, any>['variant']

  onRenameItem?: (event: any, key: string | number) => void
  onReorder?: (items: any) => void
  onSelectItem?: (event: any, key: string | number) => void
  onEditItem?: (event: any, key: string | number) => void
  onCopyItem?: (event: any, key: string | number) => void
  onExportItem?: (event: any, key: string | number) => void
  onDeleteItem?: (event: any, key: string | number) => void
  onItemAction?: (event: any, key: string | number, action: string) => void
  onSetDefault?: (key: string) => void
}

function PresentationTileList({
  actions,
  disableActions,
  disabled,
  disableInactive,
  disableUnselect,
  hasRoomForNewPresentation,
  hasSelectedIcon,
  presentationOptions,
  selected,
  showActive,
  showUnselected,
  title,
  variant,
  onCopyItem,
  onDeleteItem,
  onSelectItem,
  onEditItem,
  onExportItem,
  onItemAction,
  onRenameItem,
  onReorder,
  onSetDefault,
  ...props
}: PresentationTileListProps) {
  const classes = useStyles({ presentationOptions, ...props })
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const editAction = {
    key: 'edit',
    label: 'Edit',
    icon: EditIcon
  }

  const renameAction = {
    key: 'rename',
    label: 'Rename',
    icon: EditIcon
  }

  const copyAction = {
    key: 'copy',
    label: 'Duplicate',
    icon: CopyIcon,
    disabled: !hasRoomForNewPresentation
  }

  const exportAction = {
    key: 'export',
    label: 'Export',
    icon: ImportExportIcon
  }

  const deleteAction = {
    key: 'delete',
    label: 'Delete',
    icon: DeleteIcon
  }

  const setDefaultAction = {
    key: 'set-default',
    label: 'Set As Default',
    icon: StarBorderIcon
  }

  const handleActionClick = (event: React.MouseEvent<HTMLElement>, actionName: string, key: string | number) => {
    if (actionName === 'edit') {
      onEditItem(event, key)
    } else if (actionName === 'rename') {
      onRenameItem(event, key)
    } else if (actionName === 'copy') {
      onCopyItem(event, key)
    } else if (actionName === 'export') {
      onExportItem(event, key)
    } else if (actionName === 'delete') {
      onDeleteItem(event, key)
    } else if (actionName === 'set-default') {
      onSetDefault(key as string)
    } else if (onItemAction) {
      onItemAction(event, key, actionName)
    }
  }

  const tileItems = presentationOptions.map((opt, index) => {
    if (opt.key === options.options.defaultPresentation) {
      return { ...opt, icon: StarIcon }
    }
    if (opt.key === 'default') {
      return { ...opt, label: `${objectLabels.quote.value} Only` }
    } else {
      return opt
    }
  })

  if (!tileItems.length)
    return (
      <Typography variant="h6" align="center">
        No Presentations Found
      </Typography>
    )

  return (
    <TileList
      classes={{
        root: classes.root,
        tiles: classes.tiles
      }}
      actions={(item) => {
        if (disableActions) {
          return null
        }
        if (item.key === 'default') {
          return null
        } else {
          return [
            onEditItem ? editAction : null,
            onRenameItem ? renameAction : null,
            onCopyItem ? copyAction : null,
            onDeleteItem ? deleteAction : null,
            onSetDefault && options.options.defaultPresentation !== item.key ? setDefaultAction : null,
            onExportItem ? exportAction : null,
            ...(actions ? actions : [])
          ].filter((item) => item)
        }
      }}
      disabled={disabled}
      disableInactive={disableInactive}
      disableUnselect={disableUnselect}
      draggable={!!onReorder}
      hasSelectedIcon={hasSelectedIcon}
      items={tileItems}
      onActionClick={handleActionClick}
      onReorder={onReorder}
      onSelect={onSelectItem ?? onEditItem}
      selected={selected}
      showActive={showActive}
      showUnselected={showUnselected}
      title={title}
      variant={variant}
    />
  )
}

export default PresentationTileList
