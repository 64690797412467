import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { OverridableComponent } from '@material-ui/core/OverridableComponent'
import type { TableHeadProps, TableHeadTypeMap } from '@material-ui/core/TableHead'
import MuiTableHead from '@material-ui/core/TableHead'
import React, { useMemo } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.overline,
    background: 'white',
    borderBottom: 0
  }
}))

// Consumed by TableCell to automatically apply styles for the TableCell when it's in a TableHead
export const TableHeadContext = React.createContext({
  isHead: false
})

const TableHead: OverridableComponent<TableHeadTypeMap> = (props: TableHeadProps) => {
  const classes = useStyles(props)

  const value = useMemo(
    () => ({
      isHead: true
    }),
    []
  )

  return (
    <TableHeadContext.Provider value={value}>
      <MuiTableHead component="div" {...props} classes={classes} />
    </TableHeadContext.Provider>
  )
}

export default TableHead
