import React, { useState } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import IconButton from '../IconButton'
import PopupMenu from '../PopupMenu'
import type { PopupMenuProps } from '../PopupMenu'
import MenuItem from '../MenuItem'
import AddIcon from '@material-ui/icons/Add'
import PresEditorTooltip from '../PresEditorTooltip'

import type { Action } from '@ui/core/ActionButton'

export interface ButtonPopupMenuProps extends Omit<PopupMenuProps, 'component' | 'children'> {
  actions: Action[]
  disabled?: boolean
  buttonSize?: 'small' | 'medium'
  onActionClick: (event: React.MouseEvent<HTMLElement>, actionKey: string) => void
  buttonVariant?: 'default' | 'contained' | 'outlined'
  grid?: boolean
  columnCount?: number
  popupMenuProps?: any
  tooltipTitle?: string
}

const useStyles = makeStyles<Theme, ButtonPopupMenuProps>((theme) => ({
  menu: (props) => ({
    '& ul': {
      columnCount: props.columnCount,
      columnWidth: 180,
      '& li': {
        breakInside: 'avoid'
      }
    }
  })
}))

function ButtonPopupMenu(props: ButtonPopupMenuProps) {
  const {
    actions,
    buttonSize,
    buttonVariant = 'default',
    columnCount = 1,
    disabled,
    id,
    onActionClick,
    tooltipTitle,
    ...other
  } = props
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)

  return (
    <PresEditorTooltip
      title={tooltipTitle}
      disableHoverListener={open}
      disableFocusListener={open}
      disableTouchListener={open}
      {...(!tooltipTitle && {
        open: false
      })}
    >
      <PopupMenu
        TransitionProps={{ onEnter: () => setOpen(true), onExit: () => setOpen(false) }}
        component={
          <AddButton
            id={id}
            buttonSize={buttonSize}
            className={classes.button}
            buttonVariant={buttonVariant}
            disabled={disabled}
          />
        }
        {...other}
        classes={classes}
      >
        {actions &&
          actions.map((action) => (
            <MenuItem
              className={classes.menuItem}
              key={action.key}
              icon={action.icon}
              onClick={(ev) => onActionClick(ev, action.key)}
            >
              {action.label}
            </MenuItem>
          ))}
      </PopupMenu>
    </PresEditorTooltip>
  )
}

export default ButtonPopupMenu

export interface AddButtonProps {
  id: string
  buttonSize?: 'small' | 'medium'
  buttonVariant?: 'default' | 'contained' | 'outlined'
  disabled?: boolean
  className?: any
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export function AddButton(props: AddButtonProps) {
  const { id, buttonSize, className, buttonVariant, disabled, onClick } = props
  return (
    <IconButton
      id={id}
      size={buttonSize}
      color="primary"
      className={className}
      variant={buttonVariant}
      disabled={disabled}
      disableRipple={true}
      onClick={onClick}
    >
      <AddIcon />
    </IconButton>
  )
}
