import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const TwoCol = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 12 11'} {...props}>
    <svg>
      <path d="M4.2665 0.427856H0.2855V10.5629H4.2665V0.427856Z" />
      <path d="M11.0695 0.427856H7.08848V10.5629H11.0695V0.427856Z" />
    </svg>
  </SvgIcon>
)
export default TwoCol
