import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const NoFlagIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 420 420'} {...props}>
    <svg width="420" height="420">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9477 53.5L50.6989 53.7487L361.627 364.677L337.127 389.177L192.95 245H123V367.5H88V140.05L26 78.0498L50.7487 53.3011L50.9477 53.5ZM116.448 70L151.448 105H216.8L223.8 140H315.5V245H291.448L326.448 280H350.5V105H252.5L245.5 70H116.448ZM123 175.05V210H157.95L123 175.05Z"
      />
    </svg>
  </SvgIcon>
)
export default NoFlagIcon
