import type { AreaSubstrate, QuoteArea, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { roundHours } from '../util/round-hours'
import { roundPriceQuoteOptions } from '../util'
import { productVolume } from './product-volume'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'

export function valuePerHourSubstrate(substrate: AreaSubstrate, area: QuoteArea, quote: QuoteDocument) {
  const hourlyRate = parseNumber(
    substrate.overrideHourlyRate && typeof substrate.hourlyRate === 'number' ? substrate.hourlyRate : quote.hourly_rate
  )
  const rateType = substrate.rate.rate_type
  const rateValue = parseNumber(substrate.rate_value)
  const prep = parseNumber(substrate.prep)
  const coats = parseNumber(substrate.coats)
  const areaType = area.area_type.value || 'room'
  const product = substrate.product

  const quantity = rateType === 'quantity' ? parseNumber(substrate.quantity, 1) : 1

  let dimension
  const areaLength = parseNumber(area.dimensions.length)
  const areaWidth = parseNumber(area.dimensions.width)
  const areaHeight = parseNumber(area.dimensions.height)

  // console.log('value-per-hour-substrate', {
  //   'substrate.override_dimensions': substrate.override_dimensions,
  //   'quote.version': quote.version,
  //   'substrate.length': substrate.length,
  //   'substrate.width': substrate.width,
  //   'substrate.height': substrate.height,
  //   'substrate.override_length': substrate.override_length,
  //   'substrate.override_width': substrate.override_width,
  //   'substrate.override_height': substrate.override_height,
  //   areaLength,
  //   areaWidth,
  //   areaHeight,
  //   substrate
  // })

  if (rateType === 'quantity') {
    dimension = quantity
  } else if (
    substrate.override_dimensions ||
    (quote.version >= 2 && substrate.length && substrate.length !== area.dimensions.length) ||
    (quote.version >= 2 && substrate.height && substrate.height !== area.dimensions.height) ||
    (quote.version >= 2 && substrate.width && substrate.width !== area.dimensions.width)
  ) {
    let length
    let height
    let width
    if (areaType === 'room') {
      if (
        typeof substrate.override_length === 'undefined' ||
        typeof substrate.override_width === 'undefined' ||
        typeof substrate.override_height === 'undefined'
      ) {
        // this means it's an "old" room - before we allowed overriding
        length = parseNumber(area.dimensions.length)
        width = parseNumber(area.dimensions.width)
        height = parseNumber(area.dimensions.height)
      } else {
        length = parseNumber(substrate.override_length && substrate.length ? substrate.length : area.dimensions.length)
        width = parseNumber(substrate.override_width && substrate.width ? substrate.width : area.dimensions.width)
        height = parseNumber(substrate.override_height && substrate.height ? substrate.height : area.dimensions.height)
      }
    } else {
      if (quote.version === 2) {
        length = parseNumber(substrate.length ? substrate.length : area.dimensions.length)
        height = parseNumber(substrate.height ? substrate.height : area.dimensions.height)
        width = parseNumber(substrate.width ? substrate.width : area.dimensions.width)
      } else {
        if (
          typeof substrate.override_length === 'undefined' ||
          typeof substrate.override_width === 'undefined' ||
          typeof substrate.override_height === 'undefined'
        ) {
          length = parseNumber(substrate.length ? substrate.length : areaLength)
          height = parseNumber(substrate.height ? substrate.height : areaHeight)
          width = parseNumber(substrate.width ? substrate.width : areaWidth)
        } else {
          length = parseNumber(substrate.override_length ? substrate.length : areaLength)
          height = parseNumber(substrate.override_height ? substrate.height : areaHeight)
          width = parseNumber(substrate.override_width ? substrate.width : areaWidth)
        }
      }
    }

    if (rateType === 'lnft') {
      dimension = areaType === 'room' ? (length + width) * 2 : length
    } else if (rateType === 'sqft_walls') {
      dimension = areaType === 'room' ? (length + width) * 2 * height : length * height
    } else if (rateType === 'sqft_ceiling') {
      dimension = areaType === 'room' ? length * width : length * height
    } else if (rateType === 'sqft_floor') {
      dimension = areaType === 'room' ? length * width : length * height
    } else if (rateType === 'cuft') {
      dimension = length * width * height
    } else {
      dimension = length * height
    }
  } else {
    const lnft = parseNumber(area.dimensions.lnft)
    const sqft = parseNumber(area.dimensions.sqft)
    const sqftWalls = parseNumber(area.dimensions.sqft_walls)
    const sqftCeiling = parseNumber(area.dimensions.sqft_ceiling)
    const sqftFloor = parseNumber(area.dimensions.sqft_floor)
    const cuft = parseNumber(area.dimensions.cuft)
    if (rateType === 'lnft') {
      dimension = lnft
    } else if (rateType === 'sqft_walls') {
      dimension = areaType === 'room' ? sqftWalls : sqft
    } else if (rateType === 'sqft_ceiling') {
      dimension = areaType === 'room' ? sqftCeiling : sqft
    } else if (rateType === 'sqft_floor') {
      dimension = areaType === 'room' ? sqftFloor : sqft
    } else if (rateType === 'cuft') {
      dimension = cuft
    } else {
      dimension = sqft
    }
  }

  if (substrate.useInches) {
    dimension = dimension / 1728
  }

  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}

  productVolume(
    product,
    rateType,
    parseNumber(dimension),
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  const hours = rateValue ? (parseNumber(dimension) / rateValue) * quantity : 0
  const roundedHours = roundHours(hours, quote)

  const defaultHours = roundedHours
  let calculationHours = defaultHours

  if (substrate.override_hours) {
    if (typeof substrate.override_hours_value !== 'undefined' && substrate.override_hours_value !== null) {
      calculationHours = substrate.override_hours_value
    } else {
      calculationHours = substrate.hours
    }
  }

  let defaultPrice = hourlyRate * (calculationHours + prep)
  let price = defaultPrice
  if (substrate.override_price) {
    if (typeof substrate.override_price_value !== 'undefined' && substrate.override_price_value !== null) {
      price = substrate.override_price_value
    } else {
      price = substrate.price
    }
  }

  defaultPrice = roundPriceQuoteOptions(defaultPrice, quote)

  const updatedSubstrate = {
    ...substrate,
    default_hours: defaultHours,
    default_price: defaultPrice,
    price
  }

  return updatedSubstrate
}
