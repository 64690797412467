import { gql } from 'apollo-boost'
import type { ClientMetaDocument, OptionsDocument, InternalOptionsDocument, RatesDocument } from 'paintscout'

export const GET_CLIENT = gql`
  query getClient($id: String!) {
    client(id: $id) {
      meta {
        _id
        _rev
        name
        city
        address
        province
        country
        postal
        email
        active
        banned
        phone
        address
        parentCompanyId
        billingId
        nonBilling
        trialExpiry
        paymentExpiryNotice
        subscription {
          plan_id
          plan_price_id
          current_term_start
          current_term_end
          status
        }
        plan
        notes
        delete
      }
      rates
      options
      internalOptions
    }
  }
`

export interface GetClientResponse {
  client: {
    meta: ClientMetaDocument
    options: OptionsDocument
    internalOptions: InternalOptionsDocument
    rates: RatesDocument
  }
}
