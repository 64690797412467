import type { QuoteArea, QuoteDocument } from 'paintscout'
import { formatCurrency } from '../../../../../util'
import get from 'lodash/get'
import type { RenderableItemSubstrate } from '../../../../area-substrates'
import { fixNumber } from '../../../../../../calculator'

// Generate the Dimensions string for the areaSubstrate to be displayed to crew
export default function getDimensionString({
  areaSubstrate,
  area,
  quote,
  priceValue
}: {
  areaSubstrate: RenderableItemSubstrate
  area: QuoteArea
  quote: QuoteDocument
  priceValue?: number
}): string {
  let dimension = ''
  let price = ''
  const areaType = get(area, 'area_type.value', 'room')
  const { rateType } = areaSubstrate

  if (rateType === 'quantity' && !areaSubstrate.hideQuantityOnWorkOrder) {
    dimension = areaSubstrate.quantity.toString()
  } else {
    if (areaType === 'surface') {
      const length = areaSubstrate.length?.useCustom ? areaSubstrate.length.custom : areaSubstrate.length?.default
      const height = areaSubstrate.height?.useCustom ? areaSubstrate.height.custom : areaSubstrate.height?.default
      const customSubstrateDimensions = areaSubstrate.length?.useCustom || areaSubstrate.height?.useCustom

      const areaLnft = area.dimensions.override_lnft ? area.dimensions.lnft : area.dimensions.calculated_lnft
      const areaSqft = area.dimensions.override_sqft ? area.dimensions.sqft : area.dimensions.calculated_sqft

      const surfaceLnft = fixNumber(customSubstrateDimensions ? length : areaLnft)
      const surfaceSqft = fixNumber(customSubstrateDimensions ? length * height : areaSqft)

      if (areaSubstrate.rateType === 'lnft') {
        dimension = `${surfaceLnft}lnft`
      } else {
        dimension = `${surfaceSqft}sqft`
      }
    } else {
      const length = areaSubstrate.length?.useCustom ? areaSubstrate.length.custom : areaSubstrate.length?.default
      const width = areaSubstrate.width?.useCustom ? areaSubstrate.width.custom : areaSubstrate.width?.default
      const height = areaSubstrate.height?.useCustom ? areaSubstrate.height.custom : areaSubstrate.height?.default
      const customSubstrateDimensions =
        areaSubstrate.length?.useCustom || areaSubstrate.width?.useCustom || areaSubstrate.height?.useCustom

      const areaLnft = area.dimensions?.override_lnft ? area.dimensions.lnft : area.dimensions?.calculated_lnft
      const areaSqftWalls = area.dimensions?.override_sqft_walls
        ? area.dimensions.sqft_walls
        : area.dimensions?.calculated_sqft_walls
      const areaSqftCeilings = area.dimensions?.override_sqft_ceiling
        ? area.dimensions.sqft_ceiling
        : area.dimensions?.calculated_sqft_ceiling
      const areaSqftFloor = area.dimensions?.override_sqft_floor
        ? area.dimensions.sqft_floor
        : area.dimensions?.calculated_sqft_floor

      const surfaceLnft = fixNumber(customSubstrateDimensions ? 2 * (length + width) : areaLnft)
      const surfaceSqftWalls = fixNumber(customSubstrateDimensions ? 2 * (length + width) * height : areaSqftWalls)
      const surfaceSqftCeilings = fixNumber(customSubstrateDimensions ? length * width : areaSqftCeilings)
      const surfaceSqftFloor = fixNumber(customSubstrateDimensions ? length * width : areaSqftFloor)

      if (areaSubstrate.rateType === 'lnft') {
        dimension = `${surfaceLnft}lnft`
      } else if (areaSubstrate.rateType === 'sqftWalls') {
        dimension = `${surfaceSqftWalls}sqft`
      } else if (areaSubstrate.rateType === 'sqftCeiling') {
        dimension = `${surfaceSqftCeilings}sqft`
      } else if (areaSubstrate.rateType === 'sqftFloor') {
        dimension = `${surfaceSqftFloor}sqft`
      }
    }

    if (areaSubstrate.calculationType === 'price_per_value' && quote?.options?.showWorkOrderItemTotals) {
      if (priceValue) {
        price = formatCurrency({ value: priceValue })
      }
    }
  }
  const dimensionString = [dimension, price].filter((str) => str).join(', ')

  return dimensionString
}
