/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument, QuoteStatus } from 'paintscout'
import { revertQuoteOptions } from '../../quotes'
import { getFeature } from '../../../options'
import moment from 'moment'

export function setStatus(args: {
  quote: QuoteDocument
  status: QuoteStatus
  options: OptionsDocument
  note?: string
  customerNote?: string
  acceptedVia?: 'onTheSpot' | 'editQuoteInfo' | 'customer' | ''
  updateDates?: boolean // If status note only portion updated, only update its date
}): QuoteDocument {
  const { quote: _quote, status, note, customerNote, updateDates = true, options } = args
  const hasOptionalItems = getFeature({ options, path: 'quotes.optionalItems' })

  let quote = _quote
  const acceptedVia =
    status === 'accepted' && args.acceptedVia ? args.acceptedVia : quote.meta?.status?.acceptedVia || ''

  const updated = Date.now()

  const signatures = quote?.signatures ?? {
    estimator: '',
    customer: ''
  }

  if (
    hasOptionalItems &&
    ['accepted', 'declined', 'disputed', 'invoiced', 'onHold', 'paid', 'partial'].includes(quote.status.value) &&
    ['draft', 'sent', 'viewed'].includes(status)
  ) {
    quote = revertQuoteOptions({ quote, options }) // resets accepted optional items
  }

  return {
    ...quote,
    status: {
      ...quote.status,
      value: status,
      updated: updateDates ? updated : quote.status.updated,
      customerNote:
        typeof customerNote !== 'undefined' && customerNote !== null
          ? { value: customerNote, updated }
          : quote.status.customerNote,
      note:
        typeof note !== 'undefined' && note !== null
          ? {
              value: note,
              updated
            }
          : quote.status.note,
      history: {
        ...(quote.status.history ?? {}),
        // set previous status
        [quote.status.value]: {
          day:
            moment(quote.status.updated ?? quote.dates.created)
              .startOf('day')
              .unix() * 1000,
          timestamp: quote.status.updated ?? quote.dates.created
        },
        // put in updated status(if we update status value). it'll be the same as status.updated,
        // but for followUp querying purposes, we'll also stick it here
        [status]: updateDates
          ? {
              day: moment(updated).startOf('day').unix() * 1000,
              timestamp: updated
            }
          : quote.status.history[status]?.timestamp
          ? {
              ...quote.status.history[status]
            }
          : {}
      }
    },
    signatures: {
      ...quote.signatures,
      customer: status === 'accepted' || status === 'invoiced' ? signatures.customer : ''
    },
    dates: {
      ...quote.dates,
      client: {
        ...quote?.dates?.client,
        ...(quote.dates?.client?.accepted && status === 'accepted'
          ? { accepted: status === 'accepted' ? quote.dates?.client?.accepted : null }
          : {})
      },
      lastStatusUpdate: updateDates ? updated : quote.dates.lastStatusUpdate
    },
    meta: {
      ...quote.meta,
      status: {
        ...quote.meta?.status,
        acceptedVia
      }
    }
  }
}
