import React, { useEffect, useRef, useState } from 'react'
import type { QuoteFile } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Typography } from '../'
import debounce from 'lodash/debounce'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import classnames from 'classnames'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const useStyles = makeStyles<Theme, PdfViewerProps>(
  (theme) => ({
    documentWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'relative'
    },
    document: {
      opacity: 1,
      transition: 'opacity 0.7s cubic-bezier(0.37, 0, 0.63, 1)'
    },
    documentLoading: {
      opacity: 0
    },
    pdfDocPage: {
      margin: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(1.5, 1)
      }
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      background: 'white',
      aspectRatio: '8.5 / 11',
      width: '100%',
      transition: 'opacity 0.3s linear 0.7s',
      '& p': {
        marginTop: '10dvh'
      }
    },
    loadingLoaded: {
      position: 'absolute',
      opacity: 0
    }
  }),
  { name: 'PdfViewer' }
)

export interface PdfViewerProps {
  classes?: StyleClasses<typeof useStyles>
  file: QuoteFile
  maxSize?: number
}

/**
 * Render a pdf with selectable text and clickable links.
 * Not for use inside an iframe i.e., PresentationImbedPreview in its current form.
 */

function PdfViewer(props: PdfViewerProps) {
  const classes = useStyles(props)
  const { file, maxSize = 1200 } = props
  const [width, setWidth] = useState(700)
  const [lastResize, setLastResize] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const { src, selectedPages } = file

  const handleResize = debounce(() => {
    setLastResize(Date.now())
  }, 500)

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const pdfDoc = useRef<HTMLDivElement>(null)

  let wrapperWidth = pdfDoc?.current?.clientWidth || 700

  useEffect(() => {
    if (wrapperWidth !== width) setWidth(wrapperWidth > maxSize ? maxSize : wrapperWidth)
  }, [wrapperWidth, lastResize])

  function onDocumentLoadSuccess() {
    setWidth(pdfDoc?.current?.clientWidth)
    setIsLoading(false)
  }

  return (
    <div ref={pdfDoc} className={classes.documentWrapper}>
      <div className={classnames(classes.loading, classes.pdfDocPage, { [classes.loadingLoaded]: !isLoading })}>
        <Typography variant={'body1'}>Loading PDF...</Typography>
      </div>
      <Document
        className={classnames(classes.document, { [classes.documentLoading]: isLoading })}
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget={'_blank'}
      >
        {selectedPages.map((page, index) => {
          return <Page className={classes.pdfDocPage} width={width} key={`page_${index + 1}`} pageNumber={index + 1} />
        })}
      </Document>
    </div>
  )
}

export default PdfViewer
