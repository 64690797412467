import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import find from 'lodash/find'
import * as React from 'react'
import type { QuoteTypeOption } from '@paintscout/util/builder'
import { getQuoteTypeOptions, formatPhoneNumber } from '@paintscout/util/builder'
import type { QuoteHeaderCardProps } from '../QuoteHeaderCard'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { QuoteContext } from '../../../context/QuoteContext'
import { useClientOptions, useMediaQuery } from '@ui/stickybid'

const addressFormat = require('address-format')

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    contents: {
      flexDirection: 'row',
      paddingTop: 0
    }
  })

export interface CompanyCardProps extends Omit<QuoteHeaderCardProps, 'classes'>, WithStyles<typeof styles> {}

const CompanyCard = ({ classes }: CompanyCardProps) => {
  const { options } = useClientOptions()
  const { quote } = React.useContext(QuoteContext)

  const quoteTypeOptions = getQuoteTypeOptions({ options })
  const quoteType = find(quoteTypeOptions, { key: quote.int_ext.value as any }) as QuoteTypeOption

  const smDown = useMediaQuery('sm')

  const companyOptions = options.options

  let companyName = companyOptions.companyName.value
  let city = companyOptions.companyCity.value
  let province = companyOptions.companyProv.value
  let address = companyOptions.companyAddress.value
  let postal = companyOptions.companyPostal.value
  let phoneNumber = companyOptions.companyPhone.value
  let additional = companyOptions.companyAdditional?.value ?? ''
  let width = companyOptions.logo?.width ?? 1
  let height = companyOptions.logo?.height ?? 1
  let logoOrientation = companyOptions.logo.orientation ?? 'auto'

  if (quoteType && quoteType.overrideCompanyOptions) {
    companyName = quoteType?.companyOptions?.name ?? ''
    city = quoteType?.companyOptions?.city ?? ''
    province = quoteType?.companyOptions?.province ?? ''
    address = quoteType?.companyOptions?.address ?? ''
    postal = quoteType?.companyOptions?.postal ?? ''
    phoneNumber = quoteType?.companyOptions?.phoneNumber ?? ''

    width = quoteType?.companyOptions?.logo?.width ?? 1
    height = quoteType?.companyOptions?.logo?.height ?? 1
    logoOrientation = quoteType?.companyOptions?.logo?.orientation ?? 'auto'
  }

  let text = ''

  const fullAddress = addressFormat({ address, city, subdivision: province, postalCode: postal, countryCode: 'US' })

  if (fullAddress && fullAddress.length > 0) {
    text += fullAddress.join('\n') + '\n'
  }

  if (phoneNumber) {
    text += formatPhoneNumber({ options, phoneNumber })
  }

  return (
    <QuoteHeaderCard
      subtitle={companyName as string}
      text={text}
      imageWidth={width}
      imageHeight={height}
      imageOrientation={logoOrientation}
      classes={classes}
    />
  )
}

export default withStyles(styles)(CompanyCard)
