import type { OptionsDocument } from 'paintscout'
import { sanitizePhoneNumber } from '../../../util/sanitizePhoneNumber/sanitizePhoneNumber'

export function formatPhoneNumber({
  options,
  phoneNumber,
  extension
}: {
  options?: OptionsDocument
  phoneNumber?: any
  extension?: string
}) {
  if (!phoneNumber) {
    return ''
  }

  const separator = options?.options?.phoneNumbers?.separator ?? '.'
  const stripped: string = sanitizePhoneNumber(phoneNumber as string)

  let formatted = stripped.replace(/([1-9]\d\d)([1-9]\d\d)(\d\d\d\d)/gi, `$1${separator}$2${separator}$3`)

  if (extension) {
    formatted = `${formatted} ext. ${extension.trimEnd()}`
  }

  return formatted
}
