import React, { useState } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import type { QuoteFile } from 'paintscout'

import debounce from 'lodash/debounce'

import type { WithUseStyles } from '../styles'
import type { FilePreviewProps } from '../FilePreview'
import type { StyleClasses } from '@ui/core/theme'
import { FilePreview, Grid, InputField, Placeholder, Tab, Tabs, UploadImage } from '../'
import YouTubeIcon from '@material-ui/icons/YouTube'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    image: {},
    size: {},
    youtubePlaceholder: {
      aspectRatio: '16/9',
      height: 'unset'
    }
  }),
  { name: 'EmbedMedia' }
)

export interface EmbedMediaProps extends WithUseStyles<typeof useStyles> {
  value?: QuoteFile
  onChange: (file: QuoteFile) => void
  classes?: StyleClasses<typeof useStyles>

  className?: string
  disabled?: boolean
  accept?: string
  noClearButton?: boolean
  showSwapOnHover?: boolean
  square?: boolean
  maxWidth?: number
  maxHeight?: number
  buttonHeight?: number
  quality?: number
  youtube?: boolean
  format?: string
  showAllPages?: boolean
  filePreviewProps?: Partial<FilePreviewProps>
}

function EmbedMedia({
  value,
  onChange,
  accept,
  noClearButton,
  showSwapOnHover,
  square,
  maxWidth,
  maxHeight,
  buttonHeight,
  quality,
  disabled,
  youtube,
  format,
  showAllPages,
  filePreviewProps,
  ...rest
}: EmbedMediaProps) {
  const mediaFormat = format ? format : value?.format
  const tab = mediaFormat === 'youtube' ? 'youtube' : 'upload'
  const classes = useStyles(rest)

  return (
    <Grid container spacing={1}>
      {youtube !== false && (
        <Grid item sm={12}>
          <Tabs value={tab === 'youtube' ? 1 : 0} onChange={handleTabChange}>
            <Tab label="Upload" />
            <Tab label="YouTube" />
          </Tabs>
        </Grid>
      )}
      <Grid item sm={12} className={classes.size}>
        {tab === 'upload' && (
          <UploadImage
            classes={classes}
            publicId={value?.cloudinaryPublicId}
            src={value?.src}
            format={value?.format}
            isUploading={value?.isUploading}
            presentationOptions={value?.presentationOptions}
            onUpload={handleUpload}
            onClear={handleClear}
            quality={quality}
            noClearButton={noClearButton}
            showSwapOnHover={showSwapOnHover}
            square={square}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            buttonHeight={buttonHeight}
            accept={accept}
            disabled={disabled}
            showAllPages={showAllPages}
            filePreviewProps={filePreviewProps}
          />
        )}
        {tab === 'youtube' && <YouTube onChange={onChange} value={value} disabled={disabled} classes={classes} />}
      </Grid>
    </Grid>
  )

  function handleTabChange(event: any, value: any) {
    onChange({
      ...value,
      format: value === 1 ? 'youtube' : 'upload'
    })
  }

  async function handleUpload(file: QuoteFile) {
    onChange(file)
  }

  function handleClear() {
    onChange(null)
  }
}

export interface YouTubeProps {
  value?: QuoteFile
  onChange: (value: QuoteFile) => void
  disabled?: boolean
  classes?: StyleClasses<typeof useStyles>
}

function YouTube({ onChange, value, disabled, classes }: YouTubeProps) {
  const [unparsedUrl, setUnparsedUrl] = useState<string>(value?.youtubeId ?? '')

  const updateYoutubeVideo = debounce((unparsedUrl) => {
    const youtubeId = getYouTubeId(unparsedUrl)
    setUnparsedUrl(youtubeId)
    onChange({
      ...value,
      youtubeId
    })
  }, 1000)

  return (
    <Grid container spacing={3}>
      {!disabled && (
        <Grid item sm={12}>
          <InputField
            label={'YouTube URL or ID'}
            fullWidth={true}
            onChange={handleYouTubeUrlChange}
            value={unparsedUrl}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        {value?.youtubeId ? (
          <FilePreview file={value} />
        ) : (
          <Placeholder classes={{ placeholder: classes.youtubePlaceholder }} Icon={YouTubeIcon} />
        )}
      </Grid>
    </Grid>
  )

  function handleYouTubeUrlChange(ev: React.ChangeEvent<HTMLInputElement>) {
    // https://www.youtube.com/watch?v=Y-UqcW4bQLE
    setUnparsedUrl(ev.target.value)
    updateYoutubeVideo(ev.target.value)
  }

  function getYouTubeId(initialUrl) {
    let id = ''
    const url = initialUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_-]/i)
      id = id[0]
    } else {
      id = url
    }
    return Array.isArray(id) ? id[0] : id
  }
}

export default EmbedMedia
