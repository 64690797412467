import type { QuoteProduct } from 'paintscout'
import { fixNumber, parseNumber } from '../util'

export function productVolume(
  product: QuoteProduct,
  rateType: string,
  dimension: number,
  coats?: string | number,
  quantity?: string | number,
  roundPrices?: 'none' | 'up' | 'round',
  roundProducts?: 'none' | 'up' | 'round'
) {
  if (product) {
    let coverageRateValue = 0
    if (rateType === 'quantity') {
      coverageRateValue = parseNumber(
        product.quantity_coverage_rate_override
          ? product.quantity_coverage_rate_override_value
          : product.quantity_coverage_rate_value
      )
    } else if (rateType === 'lnft') {
      coverageRateValue = parseNumber(
        product.lnft_coverage_rate_override
          ? product.lnft_coverage_rate_override_value
          : product.lnft_coverage_rate_value
      )
    } else if (product.coverage_rate_value || product.coverage_rate_override) {
      coverageRateValue = parseNumber(
        product.coverage_rate_override ? product.coverage_rate_override_value ?? 0 : product.coverage_rate_value
      )
    }

    const q = parseNumber(quantity, 1)
    const c = parseNumber(coats, 1)

    if (!coverageRateValue) {
      product.volume = 0
    } else if (rateType === 'quantity') {
      let volume = coverageRateValue * q * c
      if (roundProducts === 'up') volume = Math.ceil(volume)
      else if (roundProducts === 'round') volume = fixNumber(volume, 0)
      product.volume = volume
    } else {
      if (!dimension) {
        return
      }
      let volume = (dimension / coverageRateValue) * q * c
      if (roundProducts === 'up') volume = Math.ceil(volume)
      else if (roundProducts === 'round') volume = fixNumber(volume, 0)
      product.volume = volume
    }

    const volume = product.volume_override ? product.volume_override_value : product.volume

    const productPrice = product.price_override ? product.price_override_value : product.price

    if (productPrice) {
      let totalPrice = volume * productPrice
      if (roundPrices === 'round') totalPrice = fixNumber(totalPrice, 0)
      else if (roundPrices === 'up') totalPrice = Math.ceil(totalPrice)
      product.totalPrice = totalPrice
    } else {
      product.totalPrice = 0
    }
  }
}
