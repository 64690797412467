/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument, QuoteSubstrate } from 'paintscout'
import type { UpdateableSubstrate } from '..'
import type { QuoteItemSection } from '../../..'
import { getQuoteOptions } from '../../util/get-options'
import { generateSubstrateDescription } from '../../util/generate-substrate-description'
import { getItemSection } from '../../util/get-item-section'

// Convert a quoteSubstrate retrieved from quote.substrates
// via key to an UpdateableSubstrate to be edited.
export function convertSubstrate(
  key: string,
  quote: QuoteDocument,
  options: OptionsDocument,
  substrate: QuoteSubstrate,
  showHiddenTotals?: boolean,
  sectionOverride?: QuoteItemSection
): UpdateableSubstrate {
  const type = 'substrate' as const
  const label = substrate.label
  // const  = substrate.client_label

  const clientLabel = substrate.clientLabel
    ? substrate.clientLabel
    : {
        useCustom: false,
        default: substrate.client_label,
        custom: ''
      }

  const quoteOptions = getQuoteOptions({ options, quote })

  const section = sectionOverride ?? getItemSection(substrate)

  const substrateDescription = {
    useCustom: !!substrate.area_string?.use_custom,
    custom: substrate.area_string?.custom ? substrate.area_string.custom : substrate.area_string?.value,
    default: generateSubstrateDescription({
      quote,
      clientOptions: options,
      substrateKey: key,
      options: quoteOptions,
      section
    })
  }

  const prep =
    showHiddenTotals && substrate.hidden_totals?.prep
      ? {
          useCustom: false,
          default: substrate.hidden_totals.prep,
          custom: substrate.hidden_totals.prep
        }
      : substrate.grouped_totals.prep
      ? {
          useCustom: false,
          default: substrate.grouped_totals.prep,
          custom: substrate.grouped_totals.prep
        }
      : {
          useCustom: false,
          default: substrate.totals.prep,
          custom: substrate.totals.prep
        }

  const hours =
    showHiddenTotals && substrate.hidden_totals?.hours
      ? {
          useCustom: false,
          default: substrate.hidden_totals.hours,
          custom: substrate.hidden_totals.hours
        }
      : substrate.grouped_totals.hours
      ? {
          useCustom: false,
          default: substrate.grouped_totals.hours,
          custom: substrate.grouped_totals.hours
        }
      : {
          useCustom: false,
          default: substrate.totals.hours,
          custom: substrate.totals.hours
        }

  const price =
    showHiddenTotals && substrate.hidden_totals?.price
      ? {
          useCustom: false,
          default: substrate.hidden_totals.price,
          custom: substrate.hidden_totals.price
        }
      : substrate.grouped_totals.price
      ? {
          useCustom: false,
          default: substrate.grouped_totals.price,
          custom: substrate.grouped_totals.price
        }
      : {
          useCustom: false,
          default: substrate.totals.price,
          custom: substrate.totals.price
        }

  const totals = {
    prep,
    hours,
    price
  }

  const files = substrate.files ? substrate.files : []
  return {
    key,
    type,
    section,
    label,
    clientLabel,
    substrateDescription,
    files,
    totals
  }
}
