import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useSnackbar } from 'notistack'
import { Buffer } from 'buffer'
import ImportCSVDialog from '../components/ImportCSVDialog'
import type { ImportOwner } from '../components/ImportCSVDialog/ImportCSVDialog'
import { checkImportedCSV } from '@paintscout/util'
import { AlertDialog, DialogStackContext } from '@ui/stickybid'

const UPLOAD_CSV_MUTATION = gql`
  mutation UploadCSV($file: String!, $owner: CsvOwner!, $type: String!, $provider: String) {
    uploadCSV(file: $file, owner: $owner, type: $type, provider: $provider)
  }
`

type ImportContactsArgs = {
  type: 'contacts'
  owners: ImportOwner[]
}

type ImportQuotesArgs = {
  type: 'quotes'
  owners: ImportOwner[]
  updateQuote: any
  companyId: string
}

type ImportCSVArgs = ImportContactsArgs | ImportQuotesArgs

export function useCSVImport() {
  const [isImporting, setIsImporting] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [uploadCSV] = useMutation(UPLOAD_CSV_MUTATION)
  const { openDialog, dismissDialog } = useContext(DialogStackContext)

  const handleImportCSV = useCallback(
    async (args: ImportCSVArgs) => {
      const { type, owners } = args
      openDialog(ImportCSVDialog, {
        owners,
        title: type,
        onClose: () => {
          dismissDialog()
          setIsImporting(false)
        },
        onConfirm: async (owner: { value: string; label: string; email: string }, file: File, provider?: string) => {
          const reader = new FileReader()
          let data = ''
          reader.onload = async (e) => {
            const text = e.target.result.toString()
            const { valid, errors } = checkImportedCSV(text, type, provider)

            if (valid) {
              data = Buffer.from(text.toString(), 'binary').toString('base64')
              await uploadCSV({ variables: { file: data, owner, type, provider } })
              dismissDialog()
              setIsDirty(true)
              enqueueSnackbar('Your upload has begun! An email will be sent to you when complete.', {
                variant: 'success'
              })
            } else {
              dismissDialog()
              enqueueSnackbar('Invalid CSV format, must use correct template.', { variant: 'error' })
              openDialog(AlertDialog, {
                title: 'CSV Format Errors',
                message: <>{errors}</>,
                onConfirm: dismissDialog
              })
            }
          }

          reader.readAsText(file)
        },
        onConfirmJson: async (owner: { value: string; label: string; email: string }, jsonInput: string) => {
          if (type !== 'quotes') return console.log('Invalid type for json import')
          const { updateQuote, companyId } = args as ImportQuotesArgs
          try {
            let parsedQuote = JSON.parse(jsonInput)
            parsedQuote = {
              ...parsedQuote,
              owner: {
                ...owner
              }
            }
            await updateQuote({
              variables: {
                quote: parsedQuote,
                companyId,
                clean: true
              }
            })
            enqueueSnackbar('Updated', { variant: 'success' })
            dismissDialog()
          } catch (error) {
            console.error(error)
            enqueueSnackbar(`Error saving json: ${error.message}`, { variant: 'error' })
          }
        }
      })
    },
    [enqueueSnackbar, uploadCSV]
  )

  useEffect(() => {
    if (isDirty) {
      const timeout = setTimeout(() => {
        setIsDirty(false)
      }, 300000) // 5 minutes

      return () => clearTimeout(timeout)
    }
  }, [isDirty])

  return {
    handleImportCSV,
    isImporting,
    isDirty,
    setIsDirty
  }
}
