import Grid from '@material-ui/core/Grid'
import { DatePicker, DropdownSelect } from '@ui/stickybid'
import type { SingleSelectOption } from '@ui/stickybid'
import type { InFilter, RangeFilter, ValueFilter } from 'json-to-lucene'
import type { Moment } from 'moment'
import moment from 'moment'
import React from 'react'
import { getPlans } from '@paintscout/util/builder'
import { getPlanLabel } from 'src/util'

export interface ClientSearchFiltersProps {
  filters: ValueFilter[]
  onChange?: (filters: ValueFilter[]) => any
}

export const PLAN_OPTIONS = getPlans()
  .filter((plan) => !plan.value.includes('addon-user-monthly'))
  .concat([
    { value: 'custom', label: 'Custom', price: '0', features: {}, priceId: '', description: '' },
    { value: 'non-billing', label: 'Non-Billing', price: '0', features: {}, priceId: '', description: '' }
  ])
  .map((filter) => ({ ...filter, label: getPlanLabel(filter.value) }))

const ACTIVE_OPTIONS: SingleSelectOption[] = [
  { label: 'No Active', value: ['false'] },
  { label: 'Include Active', value: ['true', 'false'] },
  { label: 'Only Active', value: ['true'] }
]

const DELETE_OPTIONS: SingleSelectOption[] = [
  { label: 'No Delete', value: ['false'] },
  { label: 'Include Delete', value: ['true', 'false'] },
  { label: 'Only Delete', value: ['true'] }
]

export default function ClientSearchFilters({ filters, onChange }: ClientSearchFiltersProps) {
  const dateFormat = 'DD/MM/YYYY'

  function handleFilterChange(filter: ValueFilter) {
    if (onChange) {
      onChange([...filters.filter(({ id }) => id !== filter.id), filter])
    }
  }

  // Helper method to update a range filter
  const handleRangeFilterChange = (id: string, { start, end }: { start?: any; end?: any }) => {
    const prevValue = filters.find((filter) => filter.id === id)?.value ?? []

    if (typeof start !== 'undefined') {
      handleFilterChange({
        id,
        type: 'range',
        value: [start, prevValue[1]]
      })
    }

    if (typeof end !== 'undefined') {
      handleFilterChange({
        id,
        type: 'range',
        value: [prevValue[0], end]
      })
    }
  }

  const expiryFilter = filters.find((filter) => filter.id === 'trialExpiry') as RangeFilter
  // const clientFilter = filters.find((filter) => filter.id === 'subscription.?') as RangeFilter //TODO Client subscription start filter
  const planFilter = filters.find(({ id }) => id === 'plan') as InFilter
  const activeFilter = filters.find(({ id }) => id === 'active') as InFilter
  const deleteFilter = filters.find(({ id }) => id === 'delete') as InFilter

  return (
    <Grid container spacing={2} alignItems={'flex-start'}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="plan"
              label="Plan"
              placeholder="Any Plan"
              openDown
              isMulti
              searchable={false}
              options={PLAN_OPTIONS}
              value={PLAN_OPTIONS.filter((option) => {
                if (!planFilter) return false
                return planFilter?.value.includes(option.value)
              })}
              onChange={(option: SingleSelectOption[], _: any) => {
                const value = option.map((vals) => (vals?.value ? vals.value : vals))
                handleFilterChange({
                  id: 'plan',
                  type: 'in',
                  value: value
                })
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="active"
              label="Active"
              openDown
              options={ACTIVE_OPTIONS}
              value={ACTIVE_OPTIONS.find((option) => {
                if (!activeFilter) return false

                return option.value[0] === activeFilter?.value[0] && option.value[1] === activeFilter?.value[1]
              })}
              onChange={(option: SingleSelectOption) => {
                handleFilterChange({
                  id: 'active',
                  type: 'in',
                  value: option.value
                })
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="delete"
              label="Delete"
              openDown
              options={DELETE_OPTIONS}
              value={DELETE_OPTIONS.find((option) => {
                if (!deleteFilter) return false

                return option.value[0] === deleteFilter?.value[0] && option.value[1] === deleteFilter?.value[1]
              })}
              onChange={(option: SingleSelectOption) => {
                handleFilterChange({
                  id: 'delete',
                  type: 'in',
                  value: option.value
                })
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="expiry-search-start-date"
              format={dateFormat}
              value={expiryFilter?.value[0] ? moment(expiryFilter.value[0]) : null}
              InputProps={{
                label: 'Expiry Start',
                margin: 'none',
                fullWidth: true
              }}
              PopoverProps={{
                transformOrigin: {
                  vertical: 'center',
                  horizontal: 'center'
                }
              }}
              clearable
              onChange={(date: Moment) => {
                handleRangeFilterChange('trialExpiry', { start: date ? date.unix() * 1000 : null })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="expiry-search-end-date"
              format={dateFormat}
              value={expiryFilter?.value[1] ? moment(expiryFilter.value[1]) : null}
              InputProps={{
                label: 'Expiry End',
                margin: 'none',
                fullWidth: true
              }}
              PopoverProps={{
                transformOrigin: {
                  vertical: 'center',
                  horizontal: 'center'
                }
              }}
              clearable
              onChange={(date: Moment) => {
                handleRangeFilterChange('trialExpiry', { end: date ? date.unix() * 1000 : null })
              }}
            />
          </Grid>
        </Grid>
        {/* TODO: Cliend subscription date filter */}
        {/* <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="subsription-search-start-date"
              format={dateFormat}
              value={expiryFilter?.value[0] ? moment(expiryFilter.value[0]) : null}
              InputProps={{
                label: 'Client Start',
                margin: 'none',
                fullWidth: true
              }}
              PopoverProps={{
                transformOrigin: {
                  vertical: 'center',
                  horizontal: 'center'
                }
              }}
              clearable
              onChange={(date: Moment) => {
                handleRangeFilterChange('client', { start: date ? date.unix() * 1000 : null })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="subscription-search-end-date"
              format={dateFormat}
              value={expiryFilter?.value[1] ? moment(expiryFilter.value[1]) : null}
              InputProps={{
                label: 'Client End',
                margin: 'none',
                fullWidth: true
              }}
              PopoverProps={{
                transformOrigin: {
                  vertical: 'center',
                  horizontal: 'center'
                }
              }}
              clearable
              onChange={(date: Moment) => {
                handleRangeFilterChange('trialExpiry', { end: date ? date.unix() * 1000 : null })
              }}
            />
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  )
}
