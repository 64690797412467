import React, { useState, useEffect } from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import { Drawer } from '@material-ui/core'

import find from 'lodash/find'

import Scrollspy from 'react-scrollspy'

import { Button, IconButton, useClientOptions } from '@ui/stickybid'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import HamburgerIcon from '@material-ui/icons/Menu'
import PresentationNavText from '../PresentationNavText'
import PresentationAcceptButton from '../PresentationAcceptButton'
import { usePresentationNav } from '../PresentationNavContext'
import { useQuote } from '../../../context'
import { filterActivePresentationPages, getObjectLabels, getPresentationLogo } from '@paintscout/util/builder'
import CloseButton from '../../../dialogs/common/CloseButton'
import type { PresentationOption } from 'paintscout'
import PresentationQuoteDrawer from '../PresentationQuoteDrawer'
import classnames from 'classnames'
import { useFrame } from 'react-frame-component'
import { useMediaQuery } from '@ui/stickybid'

export const useStyles = makeStyles<Theme>(
  (theme: Theme) => {
    return {
      drawerRoot: {
        margin: '0 auto',
        padding: theme.spacing(2, 4),
        width: '100%',
        maxWidth: 1144, // 1080 plus 4 * 8 * 2 for side padding
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2)
        }
      },
      appBar: {
        transition: 'height .1s cubic-bezier(0.46, 0.03, 0.52, 0.96), height 1s cubic-bezier(0.46, 0.03, 0.52, 0.96)',
        background: theme.palette.common.white,
        color: theme.palette.grey[700],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: 'none',
        maxWidth: '100vw',
        boxSizing: 'border-box',
        // height: fullHeightWithBanner * scale,
        justifyContent: 'center',
        '@media print': {
          display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
          // height: fullHeightWithBanner
        },
        '&$shrunk': {
          // height: shrunkHeightWithBanner * scale,
          [theme.breakpoints.down('xs')]: {
            // height: shrunkHeightWithBanner
          },
          '& $logoWrapper': {
            '& img': {
              maxWidth: 160,
              maxHeight: 89,
              [theme.breakpoints.down('xs')]: {
                maxWidth: 120,
                maxHeight: 60
              },
              [theme.breakpoints.down(359)]: {
                maxWidth: 90
              }
            }
          }
        }
      },
      logoWrapper: {
        display: 'flex',
        flex: 1,
        height: '100%',
        '& img': {
          transition: 'all 1s cubic-bezier(0.46, 0.03, 0.52, 0.96)',
          height: 'auto',
          width: 'auto',
          padding: theme.spacing(1, 0),
          objectFit: 'contain',
          maxWidth: 200,
          maxHeight: 116,
          [theme.breakpoints.down('xs')]: {
            maxWidth: 140,
            maxHeight: 77
          },
          [theme.breakpoints.down(359)]: {
            maxWidth: 100
          }
        }
      },
      isDummy: {
        width: '100%',
        position: 'relative',
        zIndex: 1,
        paddingRight: `0 !important`,
        transition: 'all .2s ease-out',
        '&:hover': {
          filter: 'grayscale(.9)',
          '&::after': {
            backgroundColor: 'rgba(255, 255, 255, .7)'
          }
        },
        // after pseudo element
        '&::after': {
          transition: 'all .2s ease-out',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }
      },
      toolbar: {
        boxSizing: 'border-box',
        margin: '0 auto',
        // padding: 0,
        width: '100%',
        maxWidth: 1144,
        padding: theme.spacing(0, 4),
        minHeight: theme.paintscout.header.height.md,
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 2)
        }
      },
      paper: {
        boxShadow: 'none',
        ['@media print']: {
          display: 'none'
        }
      },
      modal: {
        ['@media print']: {
          display: 'none'
        }
      },
      drawerLogo: {
        maxHeight: 80,
        maxWidth: 200,
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
          maxHeight: 60,
          maxWidth: 120
        }
      },
      closedContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      },
      noCart: {
        flexDirection: 'row-reverse',
        '& $menuButtonContent': {
          justifyContent: 'flex-end'
        }
      },
      left: {
        flex: '1 0 0',
        textAlign: 'left',
        display: 'flex',
        height: '100%'
      },
      center: {
        flex: '1 0 0',
        textAlign: 'center',
        display: 'flex',
        height: '100%',
        '& $logoWrapper': {
          justifyContent: 'center'
        }
      },
      right: {
        flex: '1 0 0',
        textAlign: 'right'
      },
      menuButtonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
      },
      dummyMenu: {
        fill: theme.palette.common.black,
        height: '100%'
      },
      shrunk: {},
      drawerCloseWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      acceptButtonRoot: {
        flexDirection: 'row-reverse',
        padding: 0,
        justifyContent: 'end',
        marginTop: theme.spacing(1)
      },
      acceptOnRightRoot: {
        flexDirection: 'row-reverse'
      },
      acceptOnRightMoreButton: {
        marginLeft: 0,
        marginRight: 12
      },
      hide: {
        display: 'none'
      },
      moreButton: {
        marginLeft: 0,
        marginRight: 12
      },
      [theme.breakpoints.down('md')]: {
        acceptButtonRoot: {
          flexDirection: 'column',
          alignItems: 'flex-end'
        },
        moreButton: {
          marginRight: 0
        },
        acceptOnRightMoreButton: {
          marginRight: 8
        }
      }
    }
  },
  { name: 'PresentationNavMobile' }
)

export interface PresentationNavMobileProps extends WithStyles<any> {
  banner?: React.ReactNode
  presentation?: PresentationOption
}

export default function PresentationNavMobile(props: PresentationNavMobileProps) {
  const classes = useStyles(props)
  const { banner } = props

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const [currentPageKey, setCurrentPageKey] = useState()

  const {
    isDummy,
    isPreview,
    isPreviewAccept,
    isQuoteEmpty,
    isSinglePage,
    onClose,
    onDismissDialog,
    onOpen,
    open,
    presentation
  } = usePresentationNav()
  const { window: win, document: doc } = useFrame()
  const { quote } = useQuote()
  const { logo } = getPresentationLogo({ options, quote, presentation })

  const [isShrunk, setShrunk] = useState(false)
  useEffect(() => {
    if (!isDummy) {
      const onScroll = () => {
        setShrunk((isShrunk) => {
          if (!isShrunk && (doc.body.scrollTop > 20 || doc.documentElement.scrollTop > 20)) {
            return true
          }

          if (isShrunk && doc.body.scrollTop < 4 && doc.documentElement.scrollTop < 4) {
            return false
          }

          return isShrunk
        })
      }

      win.addEventListener('scroll', onScroll)
      return () => win.removeEventListener('scroll', onScroll)
    }
  }, [])

  const currentPage = find(presentation.pages, { key: currentPageKey })
  const useCart = presentation?.advanced?.useCart

  let activeItems = []

  if (isSinglePage) {
    activeItems = presentation.pages[0].sections.filter((section) => section.active)
  } else {
    activeItems = presentation.pages.filter(filterActivePresentationPages)
  }

  const isDocumentOnly = presentation.key === 'default'
  const mdDown = useMediaQuery('md')
  const isTwoCol = isDocumentOnly && mdDown

  return (
    <>
      <AppBar
        position="fixed"
        classes={{
          root: classnames({
            [classes.appBar]: true,
            [classes.isDummy]: isDummy,
            [classes.shrunk]: isShrunk
          })
        }}
      >
        {banner}
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.closedContainer}>
            <div className={isTwoCol ? classes.hide : classes.left}>
              {isDummy ? (
                <HamburgerIcon className={classes.dummyMenu} />
              ) : (
                <MenuButton
                  activeItems={activeItems}
                  classes={classes}
                  currentPage={currentPage}
                  isPreview={isPreview}
                  isPreviewAccept={isPreviewAccept}
                  onChange={(id) => {
                    setCurrentPageKey(id)
                  }}
                  onDismissDialog={onDismissDialog}
                  onOpen={onOpen}
                  presentation={presentation}
                />
              )}
            </div>
            <div className={isTwoCol ? classes.left : classes.center}>
              <div className={classes.logoWrapper}>
                <img src={logo} />
              </div>
            </div>
            <div className={`${classes.right} ${classes.drawerCloseWrapper}`}>
              {useCart &&
                !isQuoteEmpty &&
                (isDummy ? (
                  <Button prominence={2}>{`View ${objectLabels.quote.value}`}</Button>
                ) : (
                  <PresentationQuoteDrawer />
                ))}
              {isDocumentOnly && (
                <PresentationAcceptButton
                  classes={{
                    acceptGridRoot: classes.acceptOnRightRoot,
                    moreButton: classes.acceptOnRightMoreButton
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                />
              )}
              {isPreviewAccept && <CloseButton onCancel={(ev) => onDismissDialog(ev, 'escapeKeyDown')} />}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        disablePortal
        open={open}
        onClose={onClose}
        classes={{ paper: classes.paper, modal: classes.modal }}
        anchor={'top'}
      >
        <div className={classes.drawerRoot}>
          <div className={classes.closedContainer} style={{ alignItems: 'flex-start', marginBottom: 12 }}>
            <div className={classes.left}></div>
            <div className={classes.center}>
              <img src={logo} className={classes.drawerLogo} />
            </div>
            <div className={classes.right}>
              <CloseButton onCancel={onClose} />
            </div>
          </div>
          <PresentationNavText presentation={presentation} />
          {!useCart && (
            <PresentationAcceptButton
              classes={{
                acceptGridRoot: classes.acceptButtonRoot,
                moreButton: classes.moreButton
              }}
            />
          )}
        </div>
      </Drawer>
    </>
  )
}

function MenuButton({
  activeItems,
  classes,
  currentPage,
  isPreview,
  isPreviewAccept,
  onChange,
  onDismissDialog,
  onOpen,
  presentation,
  ...props
}) {
  if (presentation.key === 'default') return null

  const useCart = presentation?.advanced?.useCart

  return (
    <Scrollspy
      componentTag={'div'}
      style={{ width: '100%' }}
      items={activeItems.map((item) => item.key)}
      offset={-150}
      onUpdate={(element) => {
        onChange(element?.id)
      }}
    >
      <div className={classes.menuButtonContent}>
        <IconButton onClick={onOpen} edge={useCart ? 'start' : isPreviewAccept ? null : 'end'}>
          <HamburgerIcon />
        </IconButton>
      </div>
    </Scrollspy>
  )
}

// function PopupNav({ presentation, onPageClick, ...props }) {
//   const activePages = presentation.pages.filter(filterActivePresentationPages)
//   const useCart = presentation?.advanced?.useCart

//   return (
//     <>
//       <PopupMenu
//         component={
//           <Button variant={'text'}>
//             Menu
//           </Button>
//         }
//       >
//         {activePages.map((page) => (
//           <PopupMenuItem key={page.key} onClick={(ev) => onPageClick(ev, page.key)}>
//             {page.title}
//           </PopupMenuItem>
//         ))}
//       </PopupMenu>
//       {useCart && <PresentationQuoteDrawer presentation={presentation} />}
//     </>
//   )
// }
