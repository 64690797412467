import type { QuoteDocument } from 'paintscout'
import { fixNumber, parseNumber } from '../util'
import get from 'lodash/get'

export function discount(quote: QuoteDocument) {
  const newTotal = { ...quote.totals }

  const discount = typeof quote.discount !== 'undefined' && quote.discount !== null ? parseNumber(quote.discount) : 0
  const discountType = (quote.discount_type && quote.discount_type.value) || 'percentage'

  const price = parseNumber(
    quote.totals && quote.totals.after_adjustment ? quote.totals.after_adjustment : quote.totals.price
  )

  const additionalWorkPrice = parseNumber(get(quote, 'totals.additionalWork.price', 0))
  const additionalWorkMaterials = parseNumber(get(quote, 'totals.additionalWork.materials', 0))

  const priceWithAdditional = price + additionalWorkPrice + additionalWorkMaterials

  if (discountType === 'percentage') {
    newTotal.total_discount = fixNumber(priceWithAdditional * discount * 0.01)
  } else {
    newTotal.total_discount = discount
  }

  newTotal.after_discount = priceWithAdditional - newTotal.total_discount

  return newTotal
}
