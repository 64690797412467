import type { PresentationIsEmptyProps } from '../../'

import { isMediaEmpty, isHtmlEmpty } from '../../util'

export default function isEmpty({ section, isPrint }: PresentationIsEmptyProps) {
  const fields = section?.fields

  const hasLeftText = !isHtmlEmpty(fields?.leftText)
  const hasRightText = !isHtmlEmpty(fields?.rightText)

  const hasLeftMedia = !isMediaEmpty({ file: fields?.leftImage })
  const hasRightMedia = !isMediaEmpty({ file: fields?.rightImage })

  const hasLeftCustom = !isHtmlEmpty(fields?.leftCustom)
  const hasRightCustom = !isHtmlEmpty(fields?.rightCustom)

  const hasLeftContent =
    (fields?.leftType === 'text' && hasLeftText) ||
    (fields?.leftType === 'image' && hasLeftMedia) ||
    (fields?.leftType === 'custom' && hasLeftCustom)
  const hasRightContent =
    (fields?.rightType === 'text' && hasRightText) ||
    (fields?.rightType === 'image' && hasRightMedia) ||
    (fields?.rightType === 'custom' && hasRightCustom)

  if (!hasLeftContent && !hasRightContent) {
    return true
  }

  return false
}
