import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { DatePickerNavBar as CoreDatePickerNavBar } from '@ui/core/DatePicker'
import type { DatePickerNavBarProps as CoreDatePickerNavBarProps } from '@ui/core/DatePicker'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

interface DatePickerNavBarProps extends CoreDatePickerNavBarProps {
  classes: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  monthYear: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectFormControl: {
    marginTop: theme.spacing(2)
  },
  selectGroup: {
    margin: `0 ${theme.spacing(2)}px`
  },
  select: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(),
    margin: 0, // safari adds default margin to select
    boxSizing: 'border-box',
    WebkitAppearance: 'none',
    textDecoration: 'underline',
    borderRadius: 0,
    background: 'none',
    cursor: 'pointer',
    border: `none`,
    color: theme.palette.primary.main,
    '&:first-of-type': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5
    },
    '&:first-of-type:not(:only-of-type)': {
      borderRight: 'none'
    },
    '&:last-of-type': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5
    }
  },
  rangePresets: {
    // marginTop: theme.spacing(2), // uncomment if using label
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectLabel: {
    ...theme.typography.overline,
    textAlign: 'center',
    marginBottom: theme.spacing(-1)
  }
}))

function DatePickerNavBar(props: DatePickerNavBarProps) {
  const classes = useStyles(props)
  return <CoreDatePickerNavBar classes={classes} LeftIcon={ArrowLeftIcon} RightIcon={ArrowRightIcon} {...props} />
}

export default DatePickerNavBar
