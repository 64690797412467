import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { PaperProps as MuiPaperProps } from '@material-ui/core/Paper'
import { default as MuiPaper } from '@material-ui/core/Paper'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '@media print': {
        boxShadow: 'none !important'
      }
    }
  })

export type MuiPaperPropsWithoutClasses = Omit<MuiPaperProps, 'classes'>

export interface PaperProps extends WithStyles<typeof styles>, MuiPaperPropsWithoutClasses {}

function Paper(props: PaperProps) {
  return <MuiPaper {...props} />
}

export default withStyles(styles)(Paper)
