import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { CarouselProps as CoreCarouselProps } from '@ui/core/Carousel'
import CoreCarousel from '@ui/core/Carousel'
import IconButton from '../IconButton'

const useStyles = makeStyles<Theme, CarouselProps>((theme) => {
  return {
    root: {},
    swipeContainer: {},
    mobileStepper: {}
  }
})

export interface CarouselProps extends Omit<CoreCarouselProps, 'IconButton'> {}

function Carousel(props: CarouselProps) {
  const classes = useStyles(props)

  return <CoreCarousel IconButton={IconButton} {...props} classes={classes} />
}

export default Carousel
