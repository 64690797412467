import React from 'react'
import type { VideoProps as CloudinaryVideoProps } from 'cloudinary-react'
import { Video as CloudinaryVideo } from 'cloudinary-react'
import type { WithStyles, Theme } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {}
  })

export interface VideoProps extends CloudinaryVideoProps, WithStyles<typeof styles> {}

function Video(props: VideoProps) {
  const { classes, ...otherProps } = props

  return (
    <CloudinaryVideo
      data-testid={`cloudinary-video:${otherProps.publicId}`}
      key={otherProps.publicId} // force component to re-mount when publicId changes
      resourceType="video"
      fallbackContent="Your browser does not support HTML5 video tags."
      // generate thumbnail to use as poster
      poster={{ publicId: `${otherProps.publicId}.jpg`, resourceType: 'video' }}
      {...otherProps}
      className={classnames(classes.root, otherProps.className)}
    />
  )
}

Video.defaultProps = {
  controls: true
}

export default withStyles(styles)(Video)
