import React, { useState } from 'react'
import type { FormikErrors, FormikTouched } from 'formik'
import { Field } from 'formik'
import { FormikInputField } from '@ui/stickybid'
import { Grid, makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles({
  root: {},
  icon: {
    cursor: 'pointer'
  }
})

export interface ResetPasswordFormProps {
  values: { password: string; confirmPassword: string }
  errors?: FormikErrors<{ password: string; confirmPassword: string }>
  touched?: FormikTouched<{ password: string; confirmPassword: string }>
}

const ResetPasswordForm = ({ errors, touched }: ResetPasswordFormProps) => {
  const classes = useStyles()
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true)
  const [hiddenConfirm, setHiddenConfirm] = useState<boolean>(true)
  let passwordHelperText = ''
  let confirmPasswordHelperText = ''

  if (errors && errors.password) {
    passwordHelperText = errors.password
  }
  if (errors && errors.confirmPassword && touched.confirmPassword) {
    confirmPasswordHelperText = 'Passwords must match'
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Field
            name="password"
            label="Password"
            required
            type={hiddenPassword ? 'password' : 'text'}
            endAdornment={
              hiddenPassword ? (
                <VisibilityOffIcon className={classes.icon} onClick={() => setHiddenPassword(false)} />
              ) : (
                <VisibilityIcon className={classes.icon} onClick={() => setHiddenPassword(true)} />
              )
            }
            component={FormikInputField}
            error={errors && !!errors.password}
            helperText={passwordHelperText}
            fullWidth
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="confirmPassword"
            label="Confirm Password"
            required
            type={hiddenConfirm ? 'password' : 'text'}
            component={FormikInputField}
            error={errors && !!errors.confirmPassword}
            helperText={confirmPasswordHelperText}
            onTouch={() => {
              touched.confirmPassword = true
            }}
            endAdornment={
              hiddenConfirm ? (
                <VisibilityOffIcon className={classes.icon} onClick={() => setHiddenConfirm(false)} />
              ) : (
                <VisibilityIcon className={classes.icon} onClick={() => setHiddenConfirm(true)} />
              )
            }
            fullWidth
            autoComplete="no"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ResetPasswordForm
