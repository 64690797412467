import React, { useRef, useEffect } from 'react'
import type { EditableLogo, QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import { Grid, useMediaQuery } from '@ui/stickybid'
import { InlineEditableHtml, InlineEditableLogo, InlineEditableMedia } from '../../../inlineEditable'
import type { InlineEditableHtmlRef } from '../../../inlineEditable/InlineEditableHtml'
import classnames from 'classnames'
import AnimationWrapper from '../../util/AnimationWrapper'

import cloneDeep from 'lodash/cloneDeep'
import { usePresentation } from '../../../context'

type StackSize = 'xs' | 'sm'
const stackSize: StackSize = 'xs'

const useStyles = makeStyles<Theme, PresentationSectionProps>(
  (theme: Theme) => {
    return {
      root: {},
      subRoot: {
        overflow: 'hidden',
        // allow 2px of overflow for media hover borders
        padding: 2,
        margin: -2
      },
      content: {},
      columnItem: {
        width: '100%'
      },
      logo: {
        height: 'auto',
        width: 'auto',
        maxHeight: 120,
        maxWidth: 200
      },
      leftColumnColor: (props, color = props.section.fields?.leftOptions?.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        }
      }),
      centerColumnColor: (props, color = props.section.fields?.centerOptions?.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        }
      }),
      rightColumnColor: (props, color = props.section.fields?.rightOptions?.color) => ({
        '& h1, & h2, & h3, & p, & ol, & ul': {
          color: `${color} !important`
        }
      })
    }
  },
  { name: 'ThreeColumnSection' }
)

interface ThreeColumnSectionProps extends PresentationSectionProps {
  logo: string
}

function ThreeColumnSection(props: ThreeColumnSectionProps) {
  const {
    isEditable,
    isSettings,
    logo,
    presentationIndustry,
    presetColors,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    WrapperComponent
  } = props
  const leftColRef = useRef<HTMLDivElement>()
  const centerColRef = useRef<HTMLDivElement>()
  const rightColRef = useRef<HTMLDivElement>()

  const isStacked = useMediaQuery(stackSize)
  const responsiveSpacing = isStacked ? 3 : 6

  const classes = useStyles(props)
  const fields = section?.fields ?? ({} as any)
  const {
    animation,
    centerImage,
    centerLogo: _centerLogo,
    centerOptions = {},
    centerText,
    centerType = 'text',
    leftImage,
    leftLogo: _leftLogo,
    leftOptions = {},
    leftText,
    leftType = 'image',
    rightImage,
    rightLogo: _rightLogo,
    rightOptions = {},
    rightText,
    rightType = 'text',
    swap
  } = fields

  const centerLogo = { src: logo, ..._centerLogo }
  const leftLogo = { src: logo, ..._leftLogo }
  const rightLogo = { src: logo, ..._rightLogo }

  const alignItems = getAlignment(fields?.columnAlign)

  const leftEditorRef = useRef<InlineEditableHtmlRef>()
  const centerEditorRef = useRef<InlineEditableHtmlRef>()
  const rightEditorRef = useRef<InlineEditableHtmlRef>()

  const isAnimated = ['slide', 'fade', 'zoom'].includes(animation)

  const { presentationLibrary } = usePresentation()

  useEffect(() => {
    if (swap) {
      const leftContent = leftEditorRef?.current?.getContent()
      const centerContent = centerEditorRef?.current?.getContent()
      const rightContent = rightEditorRef?.current?.getContent()
      const index = swap.indexOf('-')
      const a = swap.substring(0, index)
      const b = swap.substring(index + 1)

      let aText
      let bText
      switch (a) {
        case 'left':
          aText = leftContent
          break
        case 'center':
          aText = centerContent
          break
        case 'right':
          aText = rightContent
          break
      }
      switch (b) {
        case 'left':
          bText = leftContent
          break
        case 'center':
          bText = centerContent
          break
        case 'right':
          bText = rightContent
          break
      }

      const newSection = cloneDeep(section)

      newSection.fields[`${a}Type`] = section.fields[`${b}Type`]
      newSection.fields[`${a}Image`] = section.fields[`${b}Image`]
      newSection.fields[`${a}Logo`] = section.fields[`${b}Logo`]
      newSection.fields[`${a}Text`] = bText
      newSection.fields[`${a}Custom`] = section.fields[`${b}Custom`]
      newSection.fields[`${a}Options`] = section.fields[`${b}Options`]

      newSection.fields[`${b}Type`] = section.fields[`${a}Type`]
      newSection.fields[`${b}Image`] = section.fields[`${a}Image`]
      newSection.fields[`${b}Logo`] = section.fields[`${a}Logo`]
      newSection.fields[`${b}Text`] = aText
      newSection.fields[`${b}Custom`] = section.fields[`${a}Custom`]
      newSection.fields[`${b}Options`] = section.fields[`${a}Options`]

      newSection.fields.swap = ''
      setFieldValue(`${sectionPrefix}`, newSection)
    }
  }, [swap])

  const handleEditField = (field: string, content: string | QuoteFile | EditableLogo) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  let direction = 'row'
  if (isStacked) {
    direction = 'column'
  }

  return (
    <WrapperComponent {...props}>
      <div className={classes.subRoot}>
        <Grid
          className={classes.root}
          container
          spacing={responsiveSpacing}
          alignItems={alignItems}
          direction={direction as any}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={stackSize === 'sm' ? 12 : 4}
            md={stackSize === 'sm' ? 4 : null}
            ref={leftColRef}
            style={{ zIndex: leftType === 'image' ? 2 : 1 }}
            className={classnames({ [classes.columnItem]: true, [classes.leftColumnColor]: leftOptions.color })}
          >
            <AnimationWrapper animation={animation} index={0} direction={leftType === 'text' ? 'top' : 'left'}>
              <>
                {leftType === 'text' && (
                  <>
                    <InlineEditableHtml
                      key={leftText}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      handleEdit={(content) => handleEditField('fields.leftText', content)}
                      content={leftText}
                      ref={leftEditorRef}
                      placeholderButtonText={'Add Text'}
                      classes={{ root: classes.content }}
                      presetColors={presetColors}
                    />
                  </>
                )}
                {leftType === 'image' && (
                  <InlineEditableMedia
                    mediaOptionsProps={{
                      sectionPrefix: sectionPrefix,
                      fieldSuffix: 'leftImage'
                    }}
                    file={leftImage}
                    handleEdit={(file) => handleEditField('fields.leftImage', file)}
                    isAnimated={isAnimated}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    placeholderButtonText={'Add Image'}
                    presentationIndustry={presentationIndustry}
                    presentationLibrary={presentationLibrary}
                  />
                )}
                {leftType === 'logo' && (
                  <InlineEditableLogo
                    logo={leftLogo}
                    logoOptionsProps={{ sectionPrefix, fieldSuffix: 'leftLogo' }}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    handleEdit={(logo) => handleEditField('fields.leftLogo', logo)}
                  />
                )}
              </>
            </AnimationWrapper>
          </Grid>

          <Grid
            item
            xs={12}
            sm={stackSize === 'sm' ? 12 : 4}
            md={stackSize === 'sm' ? 4 : null}
            ref={centerColRef}
            style={{ zIndex: centerType === 'image' ? 2 : 1 }}
            className={classnames({ [classes.columnItem]: true, [classes.centerColumnColor]: centerOptions.color })}
          >
            <AnimationWrapper animation={animation} index={1} direction={'top'}>
              <>
                {centerType === 'text' && (
                  <>
                    <InlineEditableHtml
                      key={centerText}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      handleEdit={(content) => handleEditField('fields.centerText', content)}
                      content={centerText}
                      ref={centerEditorRef}
                      placeholderButtonText={'Add Text'}
                      classes={{ root: classes.content }}
                      presetColors={presetColors}
                    />
                  </>
                )}
                {centerType === 'image' && (
                  <InlineEditableMedia
                    mediaOptionsProps={{
                      sectionPrefix: sectionPrefix,
                      fieldSuffix: 'centerImage'
                    }}
                    file={centerImage}
                    handleEdit={(file) => handleEditField('fields.centerImage', file)}
                    isAnimated={isAnimated}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    placeholderButtonText={'Add Image'}
                    presentationIndustry={presentationIndustry}
                    presentationLibrary={presentationLibrary}
                  />
                )}
                {centerType === 'logo' && (
                  <InlineEditableLogo
                    logo={centerLogo}
                    logoOptionsProps={{ sectionPrefix, fieldSuffix: 'centerLogo' }}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    handleEdit={(logo) => handleEditField('fields.centerLogo', logo)}
                  />
                )}
              </>
            </AnimationWrapper>
          </Grid>

          <Grid
            item
            xs={12}
            sm={stackSize === 'sm' ? 12 : 4}
            md={stackSize === 'sm' ? 4 : null}
            ref={rightColRef}
            style={{ zIndex: rightType === 'image' ? 2 : 1 }}
            className={classnames({ [classes.columnItem]: true, [classes.rightColumnColor]: rightOptions.color })}
          >
            <AnimationWrapper animation={animation} index={2} direction={rightType === 'text' ? 'top' : 'right'}>
              <>
                {rightType === 'text' && (
                  <>
                    <InlineEditableHtml
                      key={rightText}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      handleEdit={(content) => handleEditField('fields.rightText', content)}
                      content={rightText}
                      ref={rightEditorRef}
                      placeholderButtonText={'Add Text'}
                      classes={{ root: classes.content }}
                      presetColors={presetColors}
                    />
                  </>
                )}
                {rightType === 'image' && (
                  <InlineEditableMedia
                    mediaOptionsProps={{
                      sectionPrefix: sectionPrefix,
                      fieldSuffix: 'rightImage'
                    }}
                    file={rightImage}
                    handleEdit={(file) => handleEditField('fields.rightImage', file)}
                    isAnimated={isAnimated}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    placeholderButtonText={'Add Image'}
                    presentationIndustry={presentationIndustry}
                    presentationLibrary={presentationLibrary}
                  />
                )}
                {rightType === 'logo' && (
                  <InlineEditableLogo
                    logo={rightLogo}
                    logoOptionsProps={{ sectionPrefix, fieldSuffix: 'rightLogo' }}
                    isEditable={isEditable}
                    isSettings={isSettings}
                    handleEdit={(logo) => handleEditField('fields.rightLogo', logo)}
                  />
                )}
              </>
            </AnimationWrapper>
          </Grid>
        </Grid>
      </div>
    </WrapperComponent>
  )

  function getAlignment(align: string) {
    switch (align) {
      case 'top':
        return 'flex-start'
      case 'center':
        return 'center'
      case 'bottom':
        return 'flex-end'
      default:
        return 'center'
    }
  }
}

export default React.memo(ThreeColumnSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
