import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { DialogProps } from '../'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '../'
import { CloseButton } from './'

const useStyles = makeStyles<Theme, InputDialogProps>(
  (theme) => ({
    root: {}
  }),
  { name: 'InputDialog' }
)

export interface InputDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  title: string
  label: string
  onConfirm: (value: string) => any
  onCancel: (event: React.MouseEvent<HTMLElement>) => any
}

function InputDialog(props: InputDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, title, label, defaultValue, ...baseDialogProps } = props
  const [inputValue, setInputValue] = useState((defaultValue as string) || '')

  return (
    <Dialog {...baseDialogProps}>
      <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>{title}</DialogTitle>
      <DialogContent>
        <Input label={label} value={inputValue} onChange={(ev) => setInputValue(ev.target.value)} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button prominence={1} onClick={() => onConfirm(inputValue)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InputDialog
