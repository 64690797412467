/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument, QuoteOptions } from 'paintscout'
import type { RenderableItem, QuoteItemSection, QuoteItemConsumer } from '../../../index'
import { filterItem, getQuoteOptions } from '../../../util'
import { trimLineItem } from '../../../line-items'
import { trimAreaBySection } from '../../../areas'
import { trimGroup } from '../../../groups'
import { trimArea } from '../../../areas'
import { getItemOrder, filterOrderItems, convertV1Quote } from '../util'
import { filterAreaItems, getAreaSubstrateValues, getAreaSubItems, getClientNote } from './util'

/**
 * Get Bid, Options, or Archived list of areas for a quote.
 */
export function getItemsByArea(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  showLineItems?: boolean
  trimBySubstrateSection?: QuoteItemSection
}): RenderableItem[] {
  const { options, overrideOptions, section, consumer, keys, trimBySubstrateSection, showGroups } = args
  const showLineItems = args.showLineItems === false ? false : true
  let quote = convertV1Quote(args.quote)

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const areaOrder = getItemOrder('area', section, quote)

  const items = areaOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys })) {
        return null
      }

      if (orderItem.type === 'area') {
        // Filter and trim Area by section
        const area = trimBySubstrateSection
          ? trimAreaBySection({ quote, areaKey: orderItem.key, section, options })
          : quote.areas[orderItem.key]

        if (!area) {
          return null
        }

        // Get areaSubstrates for given section, and their total value
        const { areaSubstrates, value } = getAreaSubstrateValues({
          area,
          section,
          quote,
          options,
          orderItem,
          quoteOptions,
          consumer,
          showGroups
        })

        // Filter out un-needed items in area
        if (filterAreaItems(area, section, areaSubstrates)) {
          return null
        }

        // Clean area for display
        const trimmedArea = trimArea({
          item: { ...orderItem, ...area },
          quote,
          options,
          consumer,
          section,
          areaSubstratesLength: areaSubstrates.length
        })
        if (!filterItem(area, section)) {
          trimmedArea.value = value
        }

        // Get sub-items for area display if needed
        const { cleanArea, subItems } = getAreaSubItems({
          trimmedArea,
          areaSubstrates,
          quoteOptions
        })

        return {
          ...cleanArea,
          subItems: [...subItems, ...getClientNote({ area })]
        }
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems[orderItem.key]
        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer) as RenderableItem
        }
      } else if (orderItem.type === 'group') {
        const group = quote.groups[orderItem.key]
        if (group && filterItem(group, section)) {
          return trimGroup(group, options, quote)
        }
      }

      return null
    })
    .filter((item) => item)

  return items
}
