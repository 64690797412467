import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles, styled } from '@material-ui/core/styles'
import { CustomBadge, Table, TableBody, TableCell, TableRow, Typography, useClientOptions } from '@ui/stickybid'
import EditIcon from '@material-ui/icons/Edit'
import type { RenderableTotals } from '@paintscout/util/builder'
import { getFeature, getItems, getObjectLabels, getQuoteOptions, getTotals } from '@paintscout/util/builder'
import classnames from 'classnames'
import { useQuote } from '../context/useQuote'
import { usePresentationNav } from '../presentation'
import { Hidden } from '@material-ui/core'
import type { ClassNameMap } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles<Theme, { isEditable?: boolean; showSubtotalItemCounter?: boolean }>(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginTop: theme.spacing(-1),
      '& :hover': {
        '& $calcDiv': {
          color: theme.palette.primary.main
        }
      }
    },
    tableWrapper: {
      cursor: ({ isEditable }) => (isEditable ? 'pointer' : 'auto'),
      display: 'flex',
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end'
      }
    },
    tableWrapperEditable: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },

    table: {
      width: 'auto',
      overflowX: 'auto',
      borderRadius: theme.typography.pxToRem(2),
      lineHeight: '1.7',
      borderTop: 'none'
    },
    tableRow: {
      background: 'transparent',
      height: theme.typography.pxToRem(32)
    },
    tableCell: {
      textAlign: 'right',
      borderBottomWidth: '0px',
      padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(12)}`
    },
    value: {
      paddingLeft: ({ showSubtotalItemCounter }) => (showSubtotalItemCounter ? theme.spacing(1) : theme.spacing(4)),
      textAlign: 'right',
      fontWeight: theme.typography.fontWeightMedium,
      '& span': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    calcDiv: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(),
      position: 'absolute',
      right: theme.spacing(-6),
      top: 3,
      '& svg': {
        fontSize: '1rem'
      },
      [theme.breakpoints.down('sm')]: {
        right: -26,
        '& svg': {
          fontSize: '.9rem'
        }
      },
      [theme.breakpoints.down('xs')]: {
        right: -23
      }
    },
    optionalItemLabel: {
      border: `1px solid ${theme.palette.grey[600]}`,
      borderRadius: theme.borderRadius.sm,
      padding: '1px 8px 3px 8px',
      whiteSpace: 'nowrap'
    }
  }),
  {
    name: 'TotalTable'
  }
)

export interface TotalTableProps {
  className?: string
  isEditable: boolean
  inPresentation: boolean
}

function TotalTable({ className, inPresentation, isEditable }: TotalTableProps) {
  const { handleOptionalItems, onEditDetails, quote } = useQuote()
  const { options } = useClientOptions()
  const { allowQuoteAdditionalWork } = getQuoteOptions({ options })

  const { selectedOptionalItems } = handleOptionalItems || {}
  const bidItems = getItems({
    quote,
    section: 'bid',
    view: 'area',
    consumer: 'customer',
    options
  })
  const optionalItems = getItems({
    quote,
    section: 'options',
    view: 'area',
    consumer: 'customer',
    options
  })
  const includedItemCount = bidItems?.length + selectedOptionalItems?.length
  const totalItemCount = bidItems?.length + optionalItems?.length
  const hasOptionalItems = getFeature({ options, path: 'quotes.optionalItems' })
  const showSubtotalItemCounter = optionalItems?.length > 0 && inPresentation && hasOptionalItems

  const classes = useStyles({ isEditable, showSubtotalItemCounter })
  const { presentation } = usePresentationNav()
  const totals = getTotals({
    quote,
    consumer: 'customer',
    options
  })

  const hasAdditionalWork = quote.is_invoice || allowQuoteAdditionalWork

  if (!totals || Object.keys(totals).length === 0) {
    return null
  }

  return (
    <div className={`${classes.root} ${className}`}>
      <div
        className={classnames(classes.tableWrapper, {
          [classes.tableWrapperEditable]: isEditable
        })}
        onClick={isEditable ? onEditDetails : null}
      >
        <Table className={classes.table}>
          <TableBody>
            <SubTotalRow
              classes={classes}
              includedItemCount={includedItemCount}
              showSubtotalItemCounter={showSubtotalItemCounter}
              isEditable={isEditable}
              totalItemCount={totalItemCount}
              totals={totals}
            />
            {hasAdditionalWork && <AdditionalWorkRow classes={classes} totals={totals} />}
            <DiscountRows classes={classes} totals={totals} />
            <TaxRows classes={classes} totals={totals} />
            <TotalRow classes={classes} totals={totals} />
            {(totals.amountPaid || quote.is_invoice) && <AmountPaidRow classes={classes} totals={totals} />}
            {(totals.amountPaid || quote.is_invoice) && totals.showBalanceDue && (
              <BalanceDueRow classes={classes} totals={totals} />
            )}
          </TableBody>
        </Table>
        <Hidden smDown>
          <div className={classes.calcDiv}>{isEditable && !presentation && <EditIcon />}</div>
        </Hidden>
      </div>
    </div>
  )
}

const StyledTableRow = styled((props) => <TableRow {...props} noBorder />)({
  background: 'transparent'
})

const StyledTableCell = styled((props) => <TableCell {...props} />)({
  padding: `0.4em 1em`
})

function SubTotalRow({
  classes,
  includedItemCount,
  showSubtotalItemCounter,
  isEditable,
  totalItemCount,
  totals
}: {
  classes: ClassNameMap<string>
  includedItemCount: number
  showSubtotalItemCounter?: boolean
  isEditable: boolean
  totalItemCount: number
  totals: RenderableTotals
}) {
  const { subTotal, customSubTotal, afterTax } = totals
  if (!subTotal || subTotal === afterTax) {
    return null
  }

  const subTotalContent = (
    <Typography
      classes={{ root: classes.value }}
      value={subTotal}
      format={'price'}
      showUnits={true}
      variant={'body1'}
    />
  )
  return (
    <StyledTableRow>
      <StyledTableCell style={{ maxWidth: '100vw' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: 'calc(100% + 8px)', margin: -4 }}>
          <div style={{ padding: '0 4px' }}>
            <Typography variant={'overline'}>Subtotal</Typography>
          </div>
          {showSubtotalItemCounter && (
            <div style={{ padding: '0 4px' }}>
              <Typography
                className={classes.optionalItemLabel}
                variant={'overline'}
              >{`${includedItemCount} of ${totalItemCount} Items`}</Typography>
            </div>
          )}
        </div>
      </StyledTableCell>
      <StyledTableCell data-testid="quote-sub-total">
        {customSubTotal && isEditable ? <CustomBadge>{subTotalContent}</CustomBadge> : subTotalContent}
      </StyledTableCell>
    </StyledTableRow>
  )
}

function AdditionalWorkRow({ classes, totals }: { classes: ClassNameMap<string>; totals: RenderableTotals }) {
  const { additionalWork } = totals
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  if (!additionalWork) {
    return null
  }

  return (
    <StyledTableRow noBorder>
      <StyledTableCell>{objectLabels.additionalWork.value}</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          value={additionalWork}
          format={'price'}
          showUnits={true}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function DiscountRows({ classes, totals }: { classes: ClassNameMap<string>; totals: RenderableTotals }) {
  const { discounts } = totals

  if (!discounts || discounts.length === 0) {
    return null
  }

  return (
    <>
      {discounts.map((discountItem) => {
        const discountLabel = discountItem.negative ? 'Additional: ' : 'Discount: '
        if (discountItem.amount === 0) {
          return null
        }
        return (
          <StyledTableRow key={discountItem.key}>
            <StyledTableCell>
              <Typography variant={'overline'}>
                {discountItem.description ? '' : discountLabel}
                {discountItem.description}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography
                classes={{ root: classes.value }}
                value={discountItem.amount}
                format={'price'}
                showUnits={true}
                variant={'body1'}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </>
  )
}

function TaxRows({ classes, totals }: { classes: ClassNameMap<string>; totals: RenderableTotals }) {
  const { taxes: tax = [], discounts, afterDiscount, afterTax } = totals

  if (!tax) {
    return null
  }

  return (
    <>
      {discounts && discounts.length !== 0 && afterDiscount !== afterTax && (
        <StyledTableRow>
          <StyledTableCell>
            <Typography variant={'overline'}>After Discount</Typography>
          </StyledTableCell>

          <StyledTableCell>
            <Typography
              classes={{ root: classes.value }}
              format={'price'}
              showUnits={true}
              showZeros={true}
              value={afterDiscount}
              variant={'body1'}
            />
          </StyledTableCell>
        </StyledTableRow>
      )}
      {tax.map((taxItem, index) => {
        return (
          <StyledTableRow key={`tax-${index}`}>
            <StyledTableCell>
              <Typography variant={'overline'}>{taxItem.description}</Typography>
            </StyledTableCell>

            <StyledTableCell>
              <Typography
                classes={{ root: classes.value }}
                format={'price'}
                showUnits={true}
                showZeros={true}
                value={taxItem.amount}
                variant={'body1'}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </>
  )
}

function TotalRow({ classes, totals }: { classes: ClassNameMap<string>; totals: RenderableTotals }) {
  const { afterTax } = totals
  if (typeof afterTax === 'undefined') {
    return null
  }

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography variant={'overline'}>Total</Typography>
      </StyledTableCell>
      <StyledTableCell data-testid="quote-total">
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          value={afterTax}
          showZeros={true}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function AmountPaidRow({ classes, totals }: { classes: ClassNameMap<string>; totals: RenderableTotals }) {
  const { amountPaid } = totals
  if (!amountPaid) {
    return null
  }

  return (
    <StyledTableRow>
      <StyledTableCell>Amount Paid</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          showZeros={true}
          value={amountPaid}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function BalanceDueRow({ classes, totals }: { classes: ClassNameMap<string>; totals: RenderableTotals }) {
  const { balanceDue } = totals

  return (
    <StyledTableRow>
      <StyledTableCell>Balance Due</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          showZeros={true}
          value={balanceDue}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TotalTable
