import type { RateType, AreaSubstrate, QuoteArea, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { productVolume } from './product-volume'
import { roundPriceQuoteOptions } from '../util'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'

export function pricePerValueSubstrate(substrate: AreaSubstrate, area: QuoteArea, quote: QuoteDocument) {
  const hourlyRate = parseNumber(
    substrate.overrideHourlyRate && typeof substrate.hourlyRate === 'number' ? substrate.hourlyRate : quote.hourly_rate
  )
  const rateType = substrate.rate.rate_type as RateType
  const rateValue = parseNumber(substrate.rate_value)
  const prep = parseNumber(substrate.prep)
  const coats = parseNumber(substrate.coats)
  const areaType = area.area_type.value || 'room'
  const product = substrate.product

  const quantity = rateType === 'quantity' ? parseNumber(substrate.quantity, 1) : 1

  let dimension
  if (rateType === 'quantity') {
    dimension = quantity
  } else if (
    substrate.override_dimensions ||
    (quote.version >= 2 && substrate.length && substrate.length !== area.dimensions.length) ||
    (quote.version >= 2 && substrate.width && substrate.width !== area.dimensions.width) ||
    (quote.version >= 2 && substrate.height && substrate.height !== area.dimensions.height)
  ) {
    const length = parseNumber(substrate.length)
    const height = parseNumber(substrate.height)
    const width = parseNumber(substrate.width)

    if (rateType === 'lnft') {
      dimension = length
    } else if (rateType === 'sqft_walls') {
      dimension = areaType === 'room' ? (length + width) * 2 * height : length * height
    } else if (rateType === 'sqft_ceiling') {
      dimension = areaType === 'room' ? length * width : length * height
    } else if (rateType === 'sqft_floor') {
      dimension = areaType === 'room' ? length * width : length * height
    } else if (rateType === 'cuft') {
      dimension = length * width * height
    } else {
      dimension = length * height
    }
  } else {
    const lnft = parseNumber(area.dimensions.lnft)
    const sqft = parseNumber(area.dimensions.sqft)
    const cuft = parseNumber(area.dimensions.cuft)
    const sqftWalls = parseNumber(area.dimensions.sqft_walls)
    const sqftCeiling = parseNumber(area.dimensions.sqft_ceiling)
    const sqftFloor = parseNumber(area.dimensions.sqft_floor)

    if (rateType === 'lnft') {
      dimension = lnft
    } else if (rateType === 'cuft') {
      dimension = cuft
    } else if (rateType === 'sqft_walls') {
      dimension = areaType === 'room' ? sqftWalls : sqft
    } else if (rateType === 'sqft_ceiling') {
      dimension = areaType === 'room' ? sqftCeiling : sqft
    } else if (rateType === 'sqft_floor') {
      dimension = areaType === 'room' ? sqftFloor : sqft
    } else {
      dimension = sqft
    }
  }

  if (substrate.useInches) {
    dimension = dimension / 1728
  }

  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}

  productVolume(
    product,
    rateType,
    parseNumber(dimension),
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  let defaultPrice = parseNumber(dimension) * rateValue * quantity + hourlyRate * prep
  let price = defaultPrice
  if (substrate.override_price) {
    if (typeof substrate.override_price_value !== 'undefined' && substrate.override_price_value !== null) {
      price = substrate.override_price_value
    } else {
      price = substrate.price
    }
  }

  defaultPrice = roundPriceQuoteOptions(defaultPrice, quote)

  return {
    ...substrate,
    hours: 0,
    price,
    default_price: defaultPrice
  }
}
