import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'

import { Grid } from '@ui/stickybid'

import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import PinterestIcon from '@material-ui/icons/Pinterest'
import PublicIcon from '@material-ui/icons/Public'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'

const useStyles = makeStyles<Theme, PresentationSectionProps>(
  (theme: Theme) => {
    return {
      root: {},
      linkIcon: (props) => ({
        fontSize: props.section.fields?.fontSize,
        '&:hover': {
          opacity: 0.8
        }
      })
    }
  },
  { name: 'SocialLinksSection' }
)

const getIcon = (type: string, className: string) => {
  switch (type) {
    case 'Facebook':
      return <FacebookIcon className={className} />
    case 'Instagram':
      return <InstagramIcon className={className} />
    case 'LinkedIn':
      return <LinkedInIcon className={className} />
    case 'Pinterest':
      return <PinterestIcon className={className} />
    case 'Twitter':
      return <TwitterIcon className={className} />
    case 'YouTube':
      return <YouTubeIcon className={className} />
    case 'Custom':
      return <PublicIcon className={className} />
    default:
      break
  }
}

function SocialLinksSection(props: PresentationSectionProps) {
  const { section, WrapperComponent, isEditable, isSettings } = props
  const classes = useStyles(props)
  const { fontSize, links, alignment } = section.fields

  const activeLinks = (links ?? []).filter((link) => {
    return link.href.length
  })

  // const flexAlign = {
  //   left: 'flex-start',
  //   center: 'center',
  //   right: 'flex-end'
  // }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={2} justifyContent={alignment}>
        {(isEditable || !isSettings) && (
          <>
            {activeLinks.map((link) => {
              return (
                <Grid item key={link.label}>
                  <a href={link.href} target="_blank" rel="noopener noreferrer">
                    {getIcon(link.label, classes.linkIcon)}
                  </a>
                </Grid>
              )
            })}
          </>
        )}
        {!isEditable && isSettings && (
          <>
            {activeLinks.map((link) => {
              return (
                <Grid item key={link.label}>
                  <span>{getIcon(link.label, `${classes.linkIcon} themeAccent`)}</span>
                </Grid>
              )
            })}
          </>
        )}
      </Grid>
    </WrapperComponent>
  )
}

export default React.memo(SocialLinksSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
