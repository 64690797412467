import React from 'react'
import type { DialogProps } from '@ui/stickybid'
import { InputField } from '@ui/stickybid'
import { Dialog, Button, Typography, DialogTitle, DialogActions, DialogContent } from '@ui/stickybid'
import { makeStyles } from '@material-ui/core'
import { CloseButton } from '../../../dialogs/common'
import type { PresentationPage } from 'paintscout'
import { Formik } from 'formik'
import * as Yup from 'yup'

export interface EditPageTemplateDialogProps extends DialogProps {
  page: PresentationPage
  isNew: boolean
  onConfirm: (page: PresentationPage) => void
  onCancel: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingTop: 0
  }
}))

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required')
    .min(1, 'Must be at least 1 character long.')
    .max(20, 'Must be 20 characters or less.'),
  description: Yup.string().max(80, 'Must be 80 characters or less.')
})

function EditPageTemplateDialog({ page, isNew, onConfirm, onCancel, ...props }: EditPageTemplateDialogProps) {
  const classes = useStyles()
  const initialValues = {
    title: page?.title ?? '',
    description: page?.description ?? ''
  }

  return (
    <Dialog {...props}>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          const newPage = {
            ...page,
            title: values.title,
            label: values.title,
            description: values.description
          }
          onConfirm(newPage)
        }}
      >
        {({ values, isSubmitting, setValues, errors, handleChange, submitForm }) => {
          return (
            <>
              <DialogTitle rightContent={<CloseButton onCancel={onCancel} disabled={isSubmitting} />}>
                {isNew ? 'Create Block Template' : 'Rename Block'}
                <Typography variant="body1">
                  {isNew && 'Save this Block with current sections as a favorite to be used on future presentations.'}
                </Typography>
              </DialogTitle>
              <DialogContent className={classes.content}>
                <InputField
                  label="Title"
                  name="title"
                  autoFocus
                  value={values.title}
                  sublabel={isNew ? 'Name to find your saved Block via later.' : ''}
                  onChange={handleChange}
                  fullWidth
                />
                {errors?.title && <ErrorMessage>{errors.title}</ErrorMessage>}
                {isNew && (
                  <>
                    <hr />
                    <InputField
                      label="Description"
                      name="description"
                      value={values.description}
                      sublabel="Details or extra information about saved Block."
                      onChange={handleChange}
                      fullWidth
                      multiline
                    />
                    {errors?.description && <ErrorMessage>{errors.description}</ErrorMessage>}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button prominence={1} disabled={isSubmitting} onClick={submitForm}>
                  Done
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

const ErrorMessage: React.FC = (props) => <Typography variant="subtitle2" color="error" {...props} />

export default EditPageTemplateDialog
