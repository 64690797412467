import type { Theme } from '@material-ui/core'
import { FormControl, makeStyles, useTheme } from '@material-ui/core'
import type { FormControlProps } from '@material-ui/core/FormControl'
import classnames from 'classnames'
import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Creatable from 'react-select/creatable'
import type { Props as ReactSelectProps } from 'react-select'
import type { StyleClasses } from '../styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  hasLabel: {},
  hasSublabel: {},
  title: {},
  select: {},
  disabled: {}
}))

export interface SingleSelectOption {
  value: any
  label?: React.ReactNode
}

export interface GroupedSelectOption {
  label: React.ReactNode
  options: SingleSelectOption[]
}

export interface SortOption {
  value: string
  direction?: string
  label: string
}

export type DropdownSelectOption = any | SingleSelectOption | GroupedSelectOption

export interface DropdownSelectProps
  extends Partial<
    Pick<
      ReactSelectProps,
      | 'onFocus'
      | 'onBlur'
      | 'onMenuClose'
      | 'onMenuOpen'
      // | 'openUp'
      // | 'openDown'
      | 'menuPlacement'
      | 'menuShouldScrollIntoView'
      | 'closeMenuOnSelect'
      | 'formatOptionLabel'
      | 'getOptionValue'
      | 'isMulti'
      | 'inputValue'
      | 'onInputChange'
      | 'isClearable'
    >
  > {
  InputLabel: React.ComponentType<any>
  classes?: StyleClasses<typeof useStyles>
  id?: string
  inputId?: string
  style?: React.CSSProperties
  className?: string
  value?: DropdownSelectOption | DropdownSelectOption[]
  defaultValue?: DropdownSelectOption | DropdownSelectOption[]
  options: DropdownSelectOption[]
  name?: string
  label?: string
  sublabel?: string
  searchable?: boolean
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
  maxMenuHeight?: number
  margin?: FormControlProps['margin']
  multiSelect?: boolean
  outlined?: boolean
  innerRef?: React.RefObject<any>
  selectRef?: React.RefObject<any>
  DropdownIndicatorIcon?: React.ComponentType<any>
  openUp?: boolean
  openDown?: boolean

  noOptionsMessage?: (obj: { inputValue?: string }) => string
  onChange?: (option: SortOption | DropdownSelectOption | DropdownSelectOption[], action?: any) => void
  onInputChange?: (
    val: string,
    event: {
      action: 'set-value' | 'input-change' | 'input-blur' | 'menu-close'
    }
  ) => any
  placeholder?: string

  // creatable props
  creatable?: boolean
  allowCreateWhileLoading?: boolean
  formatCreateLabel?: (inputValue: string) => React.ReactNode
  isValidNewOption?: (inputValue: string, selectValue: DropdownSelectOption, selectOptions: any[]) => boolean
  getNewOptionData?: (inputValue: string, optionLabel: React.ReactNode) => DropdownSelectOption
  // if provided, onChange will not fire
  onCreateOption?: (inputValue: string) => any
  createOptionPosition?: 'first' | 'last'
  selectStyles?: any

  // async props
  async?: boolean
  loadOptions?: (inputValue: string, callback: (options: DropdownSelectOption[]) => void) => void
}

function DropdownSelect({
  InputLabel,
  id,
  inputId,
  margin,
  label,
  sublabel,
  required,
  value,
  options,
  defaultValue,
  disabled,
  searchable,
  multiSelect,
  name,
  creatable,
  async,
  loadOptions,
  noOptionsMessage,
  onInputChange,
  onChange,
  allowCreateWhileLoading,
  formatCreateLabel,
  isValidNewOption,
  getNewOptionData,
  onCreateOption,
  createOptionPosition,
  placeholder,
  menuPlacement,
  menuShouldScrollIntoView,
  innerRef,
  openUp,
  openDown,
  selectRef,
  maxMenuHeight,
  selectStyles,
  DropdownIndicatorIcon,
  onMenuClose,
  ...other
}: DropdownSelectProps) {
  const classes = useStyles({ ...other })
  const theme = useTheme()

  const commonProps = {
    id,
    inputId,
    classNamePrefix: 'select',
    className: classnames({
      [classes.select]: true,
      [classes.hasSublabel]: !!sublabel
    }),
    value,
    options,
    onChange,
    defaultValue,
    isDisabled: disabled,
    isSearchable: searchable,
    isMulti: multiSelect,
    name,
    loadOptions,
    noOptionsMessage,
    placeholder,
    menuPlacement,
    openUp,
    openDown,
    menuShouldScrollIntoView,
    defaultOptions: options,
    onInputChange,
    maxMenuHeight,
    ref: selectRef,
    // portals break our tests
    menuPortalTarget: process.env.NODE_ENV !== 'test' ? document.body : null,
    onMenuClose,
    ...other
  }

  const creatableProps = {
    allowCreateWhileLoading,
    formatCreateLabel,
    isValidNewOption,
    getNewOptionData,
    onCreateOption,
    createOptionPosition,
    onBlur: (event: any) => {
      const { target } = event

      if (!target.value) {
        return
      }

      if (onCreateOption) {
        onCreateOption(target.value)
      } else if (onChange) {
        onChange(
          multiSelect
            ? [...(value as DropdownSelectOption[]), { value: target.value, label: target.value }]
            : { value: target.value, label: target.value }
        )
      }
    }
  }
  const CreateableComponent = async ? AsyncCreatableSelect : Creatable
  const SelectComponent = async ? AsyncSelect : Select

  const DropdownIndicator = (props) => <DropdownIndicatorIcon style={{ fill: 'currentColor', padding: '6px' }} />
  const fullSelectStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer'
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer'
    }),
    ...selectStyles
  }

  return (
    <FormControl
      innerRef={innerRef}
      required={required}
      {...other}
      classes={{
        root: classnames({
          [classes.root]: true,
          [classes.hasLabel]: !!label
        })
      }}
    >
      {label && (
        <InputLabel htmlFor={id} disabled={disabled} shrink={true} required={required}>
          {label}
        </InputLabel>
      )}
      {sublabel && (
        <InputLabel variant="sublabel" shrink={true} required={false}>
          {sublabel}
        </InputLabel>
      )}
      {creatable ? (
        <CreateableComponent
          {...(commonProps as any)}
          components={DropdownIndicatorIcon ? { DropdownIndicator } : undefined}
          styles={selectStyles}
          {...creatableProps}
          blurInputOnSelect={false}
        />
      ) : (
        <SelectComponent
          {...(commonProps as any)}
          components={DropdownIndicatorIcon ? { DropdownIndicator } : undefined}
          styles={fullSelectStyles}
        />
      )}
    </FormControl>
  )
}

export default DropdownSelect
