import round from 'lodash/round'

/** Rounds a number or string to a given number of decimals places and returns it as a string. Defaults to 2 decimals. */
export function fixToString(n: number | string, d: number = 2): string {
  let sanitized: number

  if (typeof n === 'string') {
    sanitized = parseFloat(n)
  } else if (typeof n !== 'number') {
    sanitized = 0
  } else {
    sanitized = n
  }

  return round(sanitized, d).toFixed(d)
}
