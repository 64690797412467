// Typescript version of material-ui internal component TabScrollButton
// https://github.com/mui-org/material-ui/blob/v1-beta/packages/material-ui/src/Tabs/TabScrollButton.js
import React from 'react'
import classnames from 'classnames'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import type { IconButtonProps } from '../IconButton'
import IconButton from '../IconButton'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  })

export interface TabScrollButtonProps extends WithStyles<typeof styles>, Omit<IconButtonProps, 'classes'> {
  direction?: 'left' | 'right'
  visible?: boolean
}

class TabScrollButton extends React.PureComponent<TabScrollButtonProps> {
  public static defaultProps: Partial<TabScrollButtonProps> = {
    visible: true
  }

  public render() {
    const { classes, className: classNameProp, direction, onClick, visible, ...other } = this.props

    const className = classnames(classes.root, classNameProp)

    if (!visible) {
      return <div className={className} />
    }

    return (
      <IconButton
        className={className}
        onClick={onClick}
        tabIndex={-1}
        disableRipple={true}
        disableTouchRipple={true}
        centerRipple={false}
        focusRipple={false}
        {...(other as any)}
      >
        {direction === 'left' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    )
  }
}

export default withStyles(styles)(TabScrollButton)
