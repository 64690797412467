import AddIcon from '@material-ui/icons/Add'
import ArchiveIcon from '@material-ui/icons/Archive'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'
import GroupIcon from '@material-ui/icons/FormatIndentIncrease'
import { BulkActionBar, BulkActionButton } from '@ui/stickybid'
import type { RenderableItem } from '@paintscout/util/builder'
import {
  getFeatures,
  // getFiles,
  getObjectLabels,
  getQuoteOptions
} from '@paintscout/util/builder'
import get from 'lodash/get'

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import React from 'react'

export interface QuoteBulkActionsProps {
  options: OptionsDocument
  quote: QuoteDocument
  tableView: string
  getSelectedItems: (args?: { section?: 'bid' | 'options' | 'additional' | 'pending' | 'archived' }) => RenderableItem[]
  getSelectedItemsByType: (type: string) => RenderableItem[]
  onBulkActionClick: (action: string, previousSection?: string) => (selectedItems: RenderableItem[]) => void
  onDeselectAll: () => void
}

export default function BulkActions({
  options,
  quote,
  tableView,
  getSelectedItems,
  getSelectedItemsByType,
  onBulkActionClick,
  onDeselectAll
}: QuoteBulkActionsProps) {
  if (getSelectedItems().length === 0) {
    return null
  }

  const features = getFeatures({ options })

  const { allowQuoteAdditionalWork } = getQuoteOptions({ options })

  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })

  const selectedGroups = getSelectedItemsByType('group')
  const selectedAreas = getSelectedItemsByType('area')
  const selectedSubstrates = getSelectedItemsByType('substrates')
  const selectedLineItems = getSelectedItemsByType('line_item')
  const selectedNonGroups = [...selectedAreas, ...selectedSubstrates, ...selectedLineItems]

  const selectedBidItems = getSelectedItems({ section: 'bid' })
  const selectedAdditionalItems = getSelectedItems({ section: 'additional' })
  const selectedPendingItems = getSelectedItems({ section: 'pending' })
  const selectedOptionItems = getSelectedItems({ section: 'options' })
  const selectedArchivedItems = getSelectedItems({ section: 'archived' })

  const hasSelectedBid = selectedBidItems.length > 0
  const hasSelectedAdditional = selectedAdditionalItems.length > 0
  const hasSelectedPending = selectedPendingItems.length > 0
  const hasSelectedOptions = selectedOptionItems.length > 0
  const hasSelectedArchived = selectedArchivedItems.length > 0

  const groupsFeature = get(features, 'quotes.groups.enabled')
  const additionalWorkFeature = get(features, 'quotes.additionalWork')

  const hasAdditionalWork = (quote.is_invoice || allowQuoteAdditionalWork) && additionalWorkFeature

  const selectedSections = [
    hasSelectedBid && 'bid',
    hasSelectedAdditional && 'additional',
    hasSelectedPending && 'pending',
    hasSelectedOptions && 'options',
    hasSelectedArchived && 'archived'
  ].filter((item) => item)

  const selectedSectionCount = selectedSections.length

  return (
    <BulkActionBar selectedItems={getSelectedItems()} itemName={'Item'} onDeselectAll={onDeselectAll}>
      {allowCopy() && (
        <BulkActionButton icon={CopyIcon} onClick={onBulkActionClick('copy')}>
          Copy
        </BulkActionButton>
      )}
      {allowMoveToBid() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('move-to-bid', selectedSections?.[0])}>
          Move to {objectLabels.quote.value}
        </BulkActionButton>
      )}
      {allowMoveToPending() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('move-to-pending', selectedSections?.[0])}>
          Move to {quote.is_invoice ? objectLabels.quote.value : objectLabels.additionalWork.value}
        </BulkActionButton>
      )}
      {allowMakeOption() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('make-option', selectedSections?.[0])}>
          Make {objectLabels.option.value}
        </BulkActionButton>
      )}
      {allowGroups() && (
        <BulkActionButton icon={GroupIcon} onClick={onBulkActionClick('group')}>
          Group
        </BulkActionButton>
      )}
      {allowAddToGroup() && (
        <BulkActionButton icon={GroupIcon} onClick={onBulkActionClick('addToGroup')}>
          Add to Group
        </BulkActionButton>
      )}
      {allowUngroup() && (
        <BulkActionButton icon={GroupIcon} onClick={onBulkActionClick('ungroup')}>
          Ungroup
        </BulkActionButton>
      )}
      {allowArchive() && (
        <BulkActionButton icon={ArchiveIcon} onClick={onBulkActionClick('archive', selectedSections?.[0])}>
          Archive
        </BulkActionButton>
      )}
      {allowDelete() && (
        <BulkActionButton icon={DeleteIcon} onClick={onBulkActionClick('delete')}>
          Delete
        </BulkActionButton>
      )}
    </BulkActionBar>
  )

  function allowMoveToBid() {
    if (hasAdditionalWork && quote.is_invoice) {
      return false
    }
    if (!hasSelectedOptions && !hasSelectedArchived) {
      return false
    }
    if (selectedSectionCount > 1) {
      return false
    }
    return true
  }

  function allowMakeOption() {
    if (hasSelectedBid && hasAdditionalWork && quote.is_invoice) {
      return false
    }

    return (
      (hasSelectedBid || hasSelectedAdditional || hasSelectedPending || hasSelectedArchived) &&
      selectedSectionCount === 1
    )
  }

  function allowMoveToPending() {
    return hasAdditionalWork && hasSelectedOptions && !hasSelectedBid && selectedSectionCount === 1
  }

  function allowCopy() {
    if (tableView !== 'area') {
      return false
    }

    if (selectedGroups.length > 0) {
      return false
    }

    if (hasAdditionalWork) {
      if (hasSelectedBid) {
        return false
      }
    }

    return true
  }

  function allowGroups() {
    return false
    // if (!groupsFeature) {
    //   return false
    // }
    // if (selectedSectionCount > 1) {
    //   // Don't allow grouping from multiple sections (for now)
    //   return false
    // }
    // if (selectedGroups.length > 0) {
    //   // Don't allow re-grouping groups
    //   return false
    // }
    // if (hasSelectedBid && hasAdditionalWork && quote.is_invoice) {
    //   return false
    // }
    // if (selectedAreas.length > 0 && selectedSubstrates.length > 0) {
    //   // Don't allow cross-view grouping
    //   return false
    // }

    // // Don't allow grouping of "split" areas (ie: just some substrates are options,
    // // but not the whole area)
    // if (selectedAreas.length > 0) {
    //   return !isSplitItem({ quote, options, keys: selectedAreas.map((item) => item.key) })
    // }

    // return true
  }

  function allowAddToGroup() {
    let allowAddToGroup = false
    if (selectedGroups.length === 0) {
      return false
    }
    if (selectedNonGroups.length === 0) {
      return false
    }
    if (selectedSectionCount !== 1) {
      return false
    }
    return true
  }

  function allowUngroup() {
    if (selectedSectionCount !== 1) {
      return false
    }
    if (selectedGroups.length !== 1) {
      return false
    }
    if (selectedNonGroups.length !== 0) {
      return false
    }
    return true
  }

  function allowArchive() {
    if (hasSelectedArchived) {
      return false
    }
    if (selectedSectionCount > 1) {
      return false
    }
    return true
  }

  function allowDelete() {
    if (!hasSelectedArchived) {
      return false
    }
    if (selectedSectionCount !== 1) {
      return false
    }
    if (tableView !== 'area') {
      return false
    }
    if (selectedGroups.length > 0) {
      return false
    }
    return true
  }
}
