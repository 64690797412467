import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

import type { OptionsDocument } from 'paintscout'
import { Button } from '@ui/stickybid'
import type { DialogProps } from '@ui/stickybid'
import { CloseButton } from '../common'
import {
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogStackContext,
  DialogTitle,
  EyeballToggle,
  FileDropArea,
  Grid,
  Spinner,
  Typography,
  useFileDropArea,
  useMediaQuery,
  ViewMediaDialog
} from '@ui/stickybid'
import { ItemForm } from '../../forms'
import numeral from 'numeral'
import FilesGrid from '../../FilesGrid'

import { useSnackbar } from 'notistack'
import type { LineItemOption } from '@paintscout/util/builder'
import { createLineItem, updateLineItemOption, formatCurrency } from '@paintscout/util/builder'
import { useFormik } from 'formik'
import { handleValidate, calculateTotal } from '../EditItemDialog/EditItemDialog'
import classnames from 'classnames'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  loadingOverlay: {
    alignItems: 'center',
    background: '#ddd',
    border: '2px dashed #aaa',
    borderRadius: '3px',
    content: "' '",
    display: 'flex',
    height: 128,
    justifyContent: 'center',
    position: 'absolute',
    width: 128,
    zIndex: 50
  },
  filesGridLoading: {
    visibility: 'hidden'
  },
  toggleLabel: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9rem'
    }
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  footerRight: {
    display: 'flex',
    alignItems: 'center'
  },
  footerPrice: {
    margin: theme.spacing(0, 2),
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1)
    }
  },
  totalLabel: {
    display: 'inline-block'
  },
  totalValue: {
    display: 'inline-block',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  quantPricing: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  },
  hideCloseButton: {
    display: 'none'
  }
}))

export interface EditLineItemOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  item?: LineItemOption
  options?: OptionsDocument
  isNew?: boolean
  allowCustomProduct?: boolean

  onConfirm?: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel?: () => void
}

function EditLineItemOptionDialog({
  loading,
  item,
  options,
  onConfirm,
  onCancel,
  isNew,
  ...props
}: EditLineItemOptionDialogProps) {
  const classes = useStyles(props)
  const [editableOptions, setEditableOptions] = useState<OptionsDocument>(options)
  const [hideCloseButton, setHideCloseButton] = useState(false)
  const xsDown = useMediaQuery('xs')

  const { openDialog, dismissDialog } = React.useContext(DialogStackContext)
  const { enqueueSnackbar } = useSnackbar()
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const { values, setFieldValue, isSubmitting, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: item ?? createLineItem({ calculate: true, calculateBy: 'quantity' }),
    validate: handleValidate,
    onSubmit: (values) => {
      const updatedOptions = updateLineItemOption({ options: editableOptions, lineItemOption: { ...values } })
      onConfirm(updatedOptions, isDirty)
    }
  })

  const files = (values?.files ?? []).map((key) => values?.quoteFiles[key])
  const isPdf = files[0]?.format === 'pdf'

  const {
    open: openDropArea,
    getInputProps,
    loading: dropAreaLoading
  } = useFileDropArea({
    multiple: false,
    onUpload: (newFiles) => {
      // Altered for SB single file on line item
      // const existingFiles = values?.key ? getFilesForItem({ quote: editableQuote, itemKey: values.key }) : []
      // const updatedFiles = [...existingFiles, ...newFiles]

      const updatedFiles = [...newFiles]
      const newFileKeys = updatedFiles.map((file) => file.key)
      const newQuoteFiles = {
        [newFileKeys[0]]: updatedFiles[0]
      }
      setEditableOptions(
        updateLineItemOption({
          options: editableOptions,
          lineItemOption: { ...values, files: updatedFiles, quoteFiles: newQuoteFiles }
        })
      )

      setFieldValue('quoteFiles', newQuoteFiles)
      setFieldValue('files', newFileKeys)
    }
  })

  const handleSwitch = (target: string) => {
    setFieldValue(target, !values[target])
    setIsDirty(true)
  }

  const handleAddFiles = (
    values,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    newFiles: QuoteFile[]
  ) => {
    // Altered for SB single file on line item
    // const existingFiles = getFilesForItem({ quote: editableQuote, itemKey: values.key })
    // const updatedFiles = [...existingFiles, ...newFiles]
    const updatedFiles = [...newFiles]
    const newFileKeys = updatedFiles.map((file) => file.key)
    const newQuoteFiles = {
      [newFileKeys[0]]: updatedFiles[0]
    }
    setEditableOptions(
      updateLineItemOption({
        options: editableOptions,
        lineItemOption: { ...values, files: updatedFiles, quoteFiles: newQuoteFiles }
      })
    )

    setFieldValue('quoteFiles', newQuoteFiles)
    setFieldValue('files', newFileKeys)
  }

  const handleMediaDeleteClick = (files?: QuoteFile[], index?: number, uploadFiles?: File[]) => {
    openDialog(ConfirmationDialog, {
      message: "Are you sure you'd like to remove media from this item?",
      noLabel: 'Cancel',
      title: 'Remove Media',
      onConfirm: () => {
        const updatedQuoteFiles = [...files.slice(0, index), ...files.slice(index + 1)]
        const updatedFiles = updatedQuoteFiles.map((file) => {
          return file.key
        })

        setFieldValue('files', updatedFiles)
        setFieldValue(
          'quoteFiles',
          updatedQuoteFiles.reduce((acc, file) => {
            acc[file.key] = file
            return acc
          }, {})
        )
        enqueueSnackbar('Successfully removed Media', { variant: 'success' })
        setIsDirty(true)
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  const handleReorder = (updatedFiles: QuoteFile[]) => {
    const updatedItem = {
      ...values,
      files: updatedFiles.map((file) => file.key),
      quoteFiles: updatedFiles.reduce((acc, file) => {
        acc[file.key] = file
        return acc
      }, {})
    }
    setFieldValue('files', updatedItem.files)
    setFieldValue('quoteFiles', updatedItem.quoteFiles)
    setIsDirty(true)
  }

  const handleMediaViewClick = () => {
    setHideCloseButton(true)
    openDialog(ViewMediaDialog, {
      title: values.name,
      index: 0,
      files: [files[0]],
      onClose: () => {
        setHideCloseButton(false)
        dismissDialog()
      }
    })
  }

  const mediaComponent = (
    <>
      <Grid item xs={12} style={{ position: 'relative' }}>
        {dropAreaLoading && (
          <div className={classes.loadingOverlay}>
            <Grid item container spacing={2} alignItems="center" justify="center">
              <Grid item>
                <Spinner size={10} />
                <Typography variant="overline">Uploading...</Typography>
              </Grid>
            </Grid>
          </div>
        )}
        <div
          className={classnames({
            [classes.filesGridLoading]: dropAreaLoading
          })}
        >
          <FilesGrid
            columns={1}
            editable={true}
            files={files[0] ? [files[0]] : []}
            onDelete={dropAreaLoading ? null : (index) => handleMediaDeleteClick(files, index)}
            onFileClick={isPdf ? () => handleMediaViewClick() : () => openDropArea()}
            onReorder={handleReorder}
            onUploadClick={files.length > 0 ? null : () => openDropArea()}
            showBorder={false}
            showDetails={false}
            filePreviewProps={{ showFirstPageOnly: true }}
          />
        </div>
      </Grid>
    </>
  )

  const totalValue = formatCurrency({ value: calculateTotal(values) })
  let quantPricing = `(${numeral(values.quantity).format('0,0[.]00') || 0} unit${
    values.quantity !== 1 ? 's' : ''
  } @ ${formatCurrency({
    value: values.pricePerUnit
  })}) =`

  if (xsDown)
    quantPricing = `(${numeral(values.quantity).format('0,0[.]00') || 0} @ ${formatCurrency({
      value: values.pricePerUnit
    })}) =`

  return (
    <Dialog fullWidth={true} maxWidth={'md'} {...props}>
      <input {...getInputProps()} />
      <DialogTitle
        loading={isSubmitting}
        rightContent={
          <CloseButton
            className={classnames({ [classes.hideCloseButton]: hideCloseButton })} // this CloseButton gets the click event instead of the ViewMediaDialog CloseButton in the same place even though the z-index is lower... workaround for this mystery
            disabled={isSubmitting}
            onCancel={onCancel}
          />
        }
      >
        <Typography variant={'h2'}>{`${isNew ? 'Add' : 'Edit'} Product or Service`}</Typography>
      </DialogTitle>
      <DialogContent>
        <FileDropArea
          onUpload={(files) => handleAddFiles(values, setFieldValue, [files[0]])}
          label={'Drop to Add Image or Pdf'}
        >
          <Grid container spacing={2}>
            <ItemForm
              newItem={isNew}
              isOptions={true}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleSwitch={handleSwitch}
              mediaComponent={mediaComponent}
            />
          </Grid>
        </FileDropArea>
      </DialogContent>
      <DialogActions>
        <div className={classes.footer}>
          <EyeballToggle
            classes={{ toggleLabel: classes.toggleLabel }}
            name="hideQuantity"
            edge={'start'}
            checked={Boolean(!values.hideQuantity)}
            onClick={() => handleSwitch('hideQuantity')}
            label={xsDown ? 'Units' : 'Unit/Quantity'}
          />
          <div className={classes.footerRight}>
            <div className={classes.footerPrice}>
              {values.hideQuantity ? (
                <>
                  {!xsDown && <Typography variant={'h3'} className={classes.totalLabel}>{`Total Price`}</Typography>}
                  <Typography variant={'h3'} className={classes.totalValue}>
                    {totalValue}
                  </Typography>
                </>
              ) : (
                <>
                  {!xsDown && <Typography variant={'h3'} className={classes.totalLabel}>{`Total Price`}</Typography>}
                  <Typography variant={'h3'} className={classes.quantPricing}>
                    {quantPricing}
                  </Typography>
                  <Typography variant={'h3'} className={classes.totalValue}>
                    {totalValue}
                  </Typography>
                </>
              )}
            </div>
            <Button prominence={1} type={'submit'} onClick={() => handleSubmit()} loading={isSubmitting}>
              Done
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default EditLineItemOptionDialog
