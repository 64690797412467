import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const ThreeCol = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 19 11'} {...props}>
    <svg>
      <path d="M4.865 0.292847H0.884003V10.4278H4.865V0.292847Z" />
      <path d="M11.668 0.292847H7.68698V10.4278H11.668V0.292847Z" />
      <path d="M18.471 0.292847H14.49V10.4278H18.471V0.292847Z" />
    </svg>
  </SvgIcon>
)
export default ThreeCol
