import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { FieldAttributes } from 'formik'
import { Field } from 'formik'
import type { FormikCheckboxProps } from '../formik/FormikCheckbox'
import FormikCheckbox from '../formik/FormikCheckbox'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

const useCheckboxFieldStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '0.5em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: 0
  },
  labelHolder: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {},
  sublabel: {},
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    marginLeft: theme.spacing(),
    marginRight: -5
  }
}))

/**
 * A helper component to wrap each checkbox in a FormControl with a subtitle, and use the formik field props
 */
const CheckboxField: React.FC<FieldAttributes<any> & FormikCheckboxProps & { sublabel?: string }> = ({
  sublabel,
  label,
  ...props
}) => {
  const classes = useCheckboxFieldStyles({})

  return (
    <FormControl className={classes.root}>
      <Field
        component={FormikCheckbox}
        checkboxProps={{
          classes: {
            root: classes.checkbox
          }
        }}
        {...props}
      />
      <div className={classes.labelHolder}>
        {label && (
          <Typography className={classes.label} variant={'body1'}>
            {label}
          </Typography>
        )}
        {sublabel && (
          <Typography className={classes.sublabel} variant={'body2'}>
            {sublabel}
          </Typography>
        )}
      </div>
    </FormControl>
  )
}

export default CheckboxField
