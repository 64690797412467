/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

import type { UpdateableLineItem } from '../../index'
import { getItemSection, getQuoteType } from '../../util'

export function getLineItem(args: { quote: QuoteDocument; key: string }): UpdateableLineItem {
  const { quote, key } = args
  const initialItem = quote.lineItems[key]

  const section = getItemSection(initialItem)
  const quoteType = initialItem.quoteType ? initialItem.quoteType : getQuoteType(args)
  const totals = initialItem.totals ? { ...initialItem.totals } : null

  return {
    key,
    type: 'lineItem',
    label: initialItem.name,
    description: initialItem.description,
    crewNote: initialItem.crew_note,
    clientNote: initialItem.client_note,
    hours: initialItem.hours,
    price: initialItem.price,
    calculate: initialItem.calculate,
    calculateBy: initialItem.calculateBy,
    quantity: initialItem.quantity,
    pricePerUnit: initialItem.pricePerUnit,
    hoursPerUnit: initialItem.hoursPerUnit,
    customHourlyRate: initialItem.customHourlyRate,
    hideOnWorkOrder: initialItem.hideOnWorkOrder,
    hourlyRate: initialItem.hourlyRate,
    section,
    materials: initialItem.materials ? [...initialItem.materials] : [],
    totals,
    quoteType,
    files: initialItem.files ? initialItem.files : [],
    quoteTypes: [],
    taxRate: initialItem.taxRate,
    showPrice: initialItem.showPrice,
    hideQuantity: initialItem.hideQuantity,
    fromPdfImport: initialItem.fromPdfImport,
    acceptedOption: initialItem.acceptedOption
  }
}
