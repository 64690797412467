import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import type { WithQuoteContext } from '../../../context/withQuoteContext'
import { withQuoteContext } from '../../../context/withQuoteContext'
import type { WithClientOptions } from '@ui/stickybid'
import { withClientOptions } from '@ui/stickybid'
import { HtmlEditor } from '@ui/stickybid'
import { FormSectionTitle } from '@ui/stickybid'
import { getObjectLabels } from '@paintscout/util/builder'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '500px'
    },
    editor: {
      minHeight: '25em'
    }
  })

export interface NotesViewProps extends WithStyles<typeof styles>, WithQuoteContext, WithClientOptions {}

export interface NotesViewState {
  isDirty?: boolean
}

class NotesView extends React.Component<NotesViewProps, NotesViewState> {
  constructor(props: NotesViewProps) {
    super(props)

    this.state = {
      isDirty: false
    }
  }

  public render() {
    const { classes, quoteContext, clientOptions } = this.props
    const { quote, isEditable } = quoteContext
    const { options } = clientOptions
    const estimatorNotes = quote?.estimatorNotes
    const objectLabels = getObjectLabels({ options })

    return (
      <>
        <FormSectionTitle
          title={'Estimator Notes'}
          variant="h2"
          subTitle={`These notes are a "scratch pad" for estimators and won't appear anywhere else on the ${objectLabels.quote.value.toLowerCase()}`}
        />
        <HtmlEditor
          toolbar={{
            headings: false
          }}
          value={estimatorNotes}
          onChange={this.handleChange}
          fullWidth={true}
          classes={classes}
          className={classes.editor}
          disabled={!isEditable}
          /* this is nasty, but the slate editor was 0% interested
          in becoming not-disabled when changed to editable */
          key={`notesView-editor-${isEditable}`}
        />
      </>
    )
  }

  public handleChange = (value: any) => {
    const { quoteContext } = this.props
    const { quote } = quoteContext

    if (quote.estimatorNotes === (value as string)) {
      return
    }

    const updatedQuote = {
      ...quote,
      estimatorNotes: value as string
    }

    quoteContext.updateQuote({ quote: updatedQuote })
  }
}

export default withStyles(styles)(withQuoteContext(withClientOptions(NotesView)))
