import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { getFeatures, getObjectLabels, uuid, reformatSpecialChars } from '@paintscout/util/builder'
import type { Theme } from '@material-ui/core/styles'
import { isHtml } from '@paintscout/util/util'
import { FastField, Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type { DialogProps } from '../Dialog'
import Dialog from '../Dialog'
import { DialogTitle, DialogContent, DialogActions, DialogActionButton } from '../Dialog'
import { FormikInputField, FormikHtmlEditor } from '../formik'
import { useClientOptions } from '../ClientOptionsProvider'
import type { FollowUp } from '@paintscout/api'
import { useDialogs } from '../DialogStack'
import Button from '../Button'
import { useSnackbar } from 'notistack'
import RadioButtons from '../RadioButtons/RadioButtons'
import type { StyleClasses } from '@ui/core/theme'
import CloseButton from './CloseButton'
import classnames from 'classnames'
import { getTemplateTags, getTemplateContext } from '@paintscout/util/util'

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {},
    dialogTitle: {
      marginBottom: theme.spacing(1)
    },
    radio: {
      padding: 9,
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      '&.Mui-checked': {
        color: theme.palette.primary.main
      }
    },
    radioGroup: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(-1)
    },
    radioGroupWrap: {
      marginBottom: theme.spacing(1),
      maxHeight: '100px !important'
    },
    charCount: {
      position: 'absolute'
    },
    charCountContainer: {
      position: 'relative'
    }
  }),
  { name: 'FollowUpDialog' }
)
export interface FollowUpDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  followUp?: FollowUp
  onConfirm: (followUp: FollowUp) => any
  onCancel: () => any
}

const validationSchema = Yup.object({
  subject: Yup.string().required(),
  message: Yup.string().required(),
  conditions: Yup.object({
    fromStatus: Yup.string().required(),
    notStatus: Yup.string().required(),
    days: Yup.number()
      .required()
      .when('months', {
        is: 0,
        then: Yup.number().moreThan(0).required()
      }),
    months: Yup.number()
  }),
  isInvoice: Yup.boolean().required(),
  active: Yup.boolean()
})

export default function FollowUpDialog({ followUp, onConfirm, onCancel, ...props }: FollowUpDialogProps) {
  const classes = useStyles(props)
  const isNew = !followUp
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog } = useDialogs()
  const { options } = useClientOptions()
  const features = getFeatures({ options })
  const invoicesEnabled = !!features.invoices?.enabled

  // TileList only takes strings/numbers as keys for values, so we'll JSON.stringify the month & day conditions to track them
  const intervalOptions = [
    {
      key: JSON.stringify({ months: 0, days: 1 }),
      label: '1 day',
      name: JSON.stringify({ months: 0, days: 1 })
    },
    { key: JSON.stringify({ months: 0, days: 2 }), label: '2 days', name: JSON.stringify({ months: 0, days: 2 }) },
    { key: JSON.stringify({ months: 0, days: 7 }), label: '7 days', name: JSON.stringify({ months: 0, days: 7 }) },
    { key: JSON.stringify({ months: 0, days: 14 }), label: '14 days', name: JSON.stringify({ months: 0, days: 14 }) },
    { key: JSON.stringify({ months: 0, days: 30 }), label: '30 days', name: JSON.stringify({ months: 0, days: 30 }) }
  ]

  return (
    <Dialog {...props}>
      <Formik
        initialValues={{
          key: uuid(),

          // if invoicesEnabled, we let the user choose quote/invoice. otherwise it's just false.
          // setting it to null will trigger the tile list to select
          isInvoice: invoicesEnabled ? null : false,
          active: true,
          quoteTypes: [],
          created: Date.now(),
          retroactive: true,
          sendFollowUpNotice: false,
          ...(followUp ?? {}),

          conditions: {
            fromStatus: null,
            notStatus: null,
            interval: null,
            months: 0,
            ...(followUp?.conditions ?? {}),
            // convert older followUps that used intervals into days (they were always < 30 days, so no need to worry about months)
            days:
              followUp?.conditions?.days ?? Math.floor((followUp?.conditions?.interval ?? 0) / (1000 * 60 * 60 * 24))
          },
          subject: isHtml(followUp?.subject) ? followUp.subject : `<p>${followUp?.subject ?? ''}</p>`,
          message: isHtml(followUp?.message) ? followUp.message : `<p>${followUp?.message ?? ''}</p>`
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          values.message = reformatSpecialChars(values.message)
          values.subject = reformatSpecialChars(values.subject)

          onConfirm({ ...values })
        }}
      >
        {({ values, setFieldValue }) => {
          const objectLabels = getObjectLabels({ options, invoice: values.isInvoice })

          const fromStatusTypeOptions: Array<{ key: string; label: string; name: string; sublabel?: string }> = [
            { key: 'sent', label: 'Sent', name: 'sent' },
            { key: 'viewed', label: 'Viewed', name: 'viewed' }
          ]

          const toStatusTypeOptions: Array<{ key: string; label: string; name: string; sublabel?: string }> = [
            { key: 'viewed', label: 'Viewed', name: 'viewed' },
            {
              key: 'responded',
              label: 'Responded',
              sublabel: values.isInvoice ? 'Paid or Partial' : 'Accepted, Declined or On-Hold',
              name: 'responded'
            }
          ]

          const templateContext = getTemplateContext()
          const templateTags = getTemplateTags({ options, invoice: values.isInvoice })

          const isIntervalChosen = !!(values.conditions.months || values.conditions.days)
          const isCustomInterval =
            isIntervalChosen &&
            !intervalOptions.find(
              (opt) => opt.key === JSON.stringify({ months: values.conditions.months, days: values.conditions.days })
            )
          const customInterval = isCustomInterval
            ? { months: values.conditions.months, days: values.conditions.days }
            : { months: null, days: null }
          const customIntervalDescription = getCustomIntervalDescription({
            days: values.conditions.days,
            months: values.conditions.months
          })

          return (
            <>
              <DialogTitle classes={{ root: classes.dialogTitle }} rightContent={<CloseButton onCancel={onCancel} />}>
                {isNew ? 'Create New Follow-Up' : 'Edit Follow-Up'}
              </DialogTitle>
              <DialogContent>
                <Form id="content-form">
                  <Grid container spacing={2} direction="column" alignItems="stretch">
                    {/* Quote or Invoice */}
                    {invoicesEnabled && (
                      <Grid item>
                        <FastField name="isInvoice">
                          {({ field }) => {
                            const objectLabelsNoInvoice = getObjectLabels({ options, invoice: false })
                            const items = [
                              {
                                key: 'quote',
                                name: 'quote',
                                label: objectLabelsNoInvoice.quote.value
                              },
                              {
                                key: 'invoice',
                                name: 'invoice',
                                label: 'Invoice'
                              }
                            ]

                            return (
                              <RadioButtons
                                classes={{ radio: classes.radio, radioGroup: classes.radioGroup }}
                                row
                                label="Document Type"
                                value={typeof field.value === 'boolean' ? (field.value ? 'invoice' : 'quote') : null}
                                options={items}
                                onChange={(value) => {
                                  field.onChange({
                                    target: { name: field.name, value: value === 'invoice' ? true : false }
                                  })
                                }}
                              />
                            )
                          }}
                        </FastField>
                      </Grid>
                    )}
                    {/* Has/Has not been */}
                    <Grid item>
                      <RadioButtons
                        classes={{ radio: classes.radio, radioGroup: classes.radioGroup }}
                        row
                        label={`Send Follow-Up if ${objectLabels.quote.value} has been...`}
                        options={fromStatusTypeOptions}
                        value={values.conditions.fromStatus}
                        onChange={(value) => {
                          if (value === 'viewed') setFieldValue('conditions.notStatus', 'responded')
                          setFieldValue('conditions.fromStatus', value)
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <RadioButtons
                        classes={{ radio: classes.radio, radioGroup: classes.radioGroup }}
                        row
                        label={`But ${objectLabels.quote.value} has not been...`}
                        options={toStatusTypeOptions}
                        value={values.conditions.notStatus}
                        disabled={values.conditions.fromStatus === 'viewed'}
                        onChange={(value) => {
                          setFieldValue('conditions.notStatus', value)
                        }}
                      />
                    </Grid>
                    {/* Send After... */}
                    <Grid item>
                      <RadioButtons
                        classes={{
                          radio: classes.radio,
                          radioGroup: classnames(classes.radioGroup, classes.radioGroupWrap)
                        }}
                        customSelected={isCustomInterval}
                        onCustomSelect={() => {
                          openDialog(CustomIntervalDialog, {
                            value: {
                              days: values.conditions.days,
                              months: values.conditions.months
                            },
                            onCancel: () => {
                              dismissDialog()
                            },
                            onConfirm: (value) => {
                              console.log('value', value)
                              if (value.months || value.days) {
                                // Need to reset then set days so our label updates correctly :/
                                setFieldValue('conditions.days', 0)
                                setFieldValue('conditions.days', value.days)
                                setFieldValue('conditions.months', value.months)
                              } else {
                                enqueueSnackbar('Minimum follow-up delay is 1 day.', { variant: 'error' })
                              }
                              dismissDialog()
                            }
                          })
                        }}
                        label={`Send After...`}
                        options={[
                          ...intervalOptions,
                          {
                            key: JSON.stringify(customInterval),
                            label: isCustomInterval ? customIntervalDescription : 'Custom',
                            name: JSON.stringify(customInterval)
                          }
                        ]}
                        value={
                          isIntervalChosen
                            ? JSON.stringify({
                                months: values.conditions.months,
                                days: values.conditions.days
                              })
                            : null
                        }
                        onChange={(value) => {
                          // Parse raw value to find months and days
                          const parsedValue = JSON.parse(value)
                          if (parsedValue.months === null && parsedValue.days === null) {
                            openDialog(CustomIntervalDialog, {
                              value: {
                                days: 0,
                                months: 0
                              },
                              onCancel: () => {
                                dismissDialog()
                              },
                              onConfirm: (value) => {
                                if (value.months || value.days) {
                                  setFieldValue('conditions.months', value.months)
                                  setFieldValue('conditions.days', value.days)
                                } else {
                                  enqueueSnackbar('Minimum follow-up delay is 1 day.', { variant: 'error' })
                                }
                                dismissDialog()
                              }
                            })
                          } else {
                            setFieldValue('conditions.months', parsedValue.months)
                            setFieldValue('conditions.days', parsedValue.days)
                          }
                        }}
                      />
                    </Grid>
                    {/* Subject & Message */}
                    <Box padding={1}>
                      <Grid container spacing={2} direction="column">
                        <Grid item>
                          <FastField
                            component={FormikHtmlEditor}
                            name={'subject'}
                            multiline={false}
                            label={'Subject'}
                            data-testid={'subject'}
                            templateContext={templateContext}
                            template={{ context: templateContext, tags: templateTags }}
                            fullWidth
                            bubbleMenuDisabled
                            floatingMenuDisabled
                            menuBarEnabled
                            templateTagsOnly
                            required
                          />
                        </Grid>
                        <Grid className={classes.charCountContainer} item>
                          <FastField
                            component={FormikHtmlEditor}
                            name={'message'}
                            label={'Message'}
                            data-testid={'message'}
                            templateContext={templateContext}
                            template={{ context: templateContext, tags: templateTags }}
                            fullWidth
                            bubbleMenuDisabled
                            floatingMenuDisabled
                            menuBarEnabled
                            required
                          />
                          <Typography className={classes.charCount} variant="subtitle2">
                            Chars: {values.message.length}{' '}
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <Field
                            component={FormikCheckbox}
                            inputProps={{ 'data-testid': 'sendFollowUpNotice' }}
                            name="sendFollowUpNotice"
                            label={`Send me a copy when this follow-up is sent to customers.`}
                            required
                            fullWidth
                            multiline
                          />
                        </Grid> */}
                      </Grid>
                    </Box>
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <DialogActionButton form="content-form" type="submit">
                  Confirm
                </DialogActionButton>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

function CustomIntervalDialog({
  value,
  onCancel,
  onConfirm,
  ...props
}: DialogProps & {
  value: { days: number; months: number }
  onCancel: () => any
  onConfirm: (value: { days: number; months: number }) => any
}) {
  return (
    <Dialog {...props}>
      <Formik
        initialValues={{
          months: value.months ?? 0,
          days: value.days ?? 0
        }}
        // @ts-ignore
        validator={() => ({})}
        onSubmit={(values) => {
          onConfirm({
            days: values.days > 0 ? values.days : 0,
            months: values.months > 0 ? values.months : 0
          })
        }}
      >
        <>
          <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>Send After...</DialogTitle>
          <DialogContent>
            <Form id="interval-form">
              <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                  <Field
                    component={FormikInputField}
                    label="Months"
                    name="months"
                    type="number"
                    inputProps={{ min: 0, style: { width: 100 } }}
                  />
                </Grid>
                <Grid item>
                  <Field
                    component={FormikInputField}
                    label="Days"
                    name="days"
                    type="number"
                    inputProps={{ min: 0, max: 31, style: { width: 100 } }}
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button form="interval-form" type="submit" variant={'contained'}>
              Confirm
            </Button>
          </DialogActions>
        </>
      </Formik>
    </Dialog>
  )
}

function getCustomIntervalDescription({ days, months }: { days: number; months: number }) {
  let txt = ''

  if (months) {
    txt += months === 1 ? '1 month' : `${months} months`
  }

  if (days) {
    if (months) {
      txt += ' and '
    }

    txt += days === 1 ? '1 day' : `${days} days`
  }

  return txt
}
