import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import * as React from 'react'
import type { QuoteHeaderCardProps } from '../QuoteHeaderCard'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    image: {
      display: 'flex',
      alignItems: 'start',

      '& img': {
        marginRight: theme.spacing(1.5),
        maxHeight: theme.typography.pxToRem(90),
        maxWidth: '75%',

        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
          maxHeight: theme.typography.pxToRem(90),
          maxWidth: '100%'
        },
        '@media print': {
          marginRight: theme.spacing(1.5),
          maxHeight: theme.typography.pxToRem(80),
          maxWidth: theme.typography.pxToRem(240)
        }
      }
    }
  })

export interface QuoteHeaderLogoProps extends Omit<QuoteHeaderCardProps, 'classes'>, WithStyles<typeof styles> {
  logo: string
}

function QuoteHeaderLogo(props: QuoteHeaderLogoProps) {
  const { classes, logo } = props

  return (
    <div className={classes.image}>
      <img src={logo} />
    </div>
  )
}

export default withStyles(styles)(QuoteHeaderLogo)
