import React from 'react'
import UploadImage from '../../UploadImage'
import type { QuoteFile } from 'paintscout'

export interface UploadCompanyLogoProps {
  /**
   * Fallback if publicId is unavailable
   */
  src?: string

  /**
   * Provide this if you can
   */
  publicId?: string

  classes?: any
  label?: string
  onChange?: (value: {
    value: string
    cdnValue: string
    cloudinaryId: string
    width?: number
    height?: number
    fileType: string
  }) => void
  disableApiUpload?: boolean
}

export function UploadCompanyLogo({
  src,
  publicId,
  classes,
  label,
  onChange,
  disableApiUpload
}: UploadCompanyLogoProps) {
  function handleUpload(quoteFile: QuoteFile) {
    if (onChange) {
      onChange({
        value: quoteFile.src,
        cdnValue: quoteFile.src,
        cloudinaryId: quoteFile.cloudinaryPublicId,
        width: quoteFile.width,
        height: quoteFile.height,
        fileType: quoteFile.format
      })
    }
  }

  function handleClear() {
    if (onChange) {
      onChange({
        value: null,
        cdnValue: null,
        cloudinaryId: null,
        fileType: null
      })
    }
  }

  return (
    <UploadImage
      classes={classes}
      label={label}
      publicId={publicId}
      src={!publicId ? src : null}
      maxHeight={600}
      maxWidth={600}
      onUpload={handleUpload}
      onClear={handleClear}
      accept={'image/png, image/jpeg, image/gif'}
      apiUploadOptions={
        disableApiUpload ? null : { invalidate: true, overwrite: true, unique_filename: false, folder: '_companyLogos' }
      }
    />
  )
}

export default UploadCompanyLogo
