/**
 * @module builder
 */

import type { OptionsDocument, QuoteDocument } from 'paintscout'

import { calculate, buildSubstrates, sanitizeSubstrates } from '../../../../calculator'

import { generateProductDescription } from '../generate-product-description'

export function calculateQuote(args?: {
  quote: QuoteDocument
  options: OptionsDocument
  selectedOptionalItems?: string[]
}): QuoteDocument {
  const { quote, options, selectedOptionalItems } = args ?? {}

  const builtSubstrates = buildSubstrates(quote, options)

  const calculatedQuote = calculate(builtSubstrates, options, selectedOptionalItems)

  const sanitizedQuote = sanitizeSubstrates(calculatedQuote)

  sanitizedQuote.productString = {
    ...sanitizedQuote.productString,
    default: generateProductDescription({ quote })
  }

  return sanitizedQuote
}
