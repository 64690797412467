/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { QuoteItemSection, UpdateableSubstrate } from '../../index'
import { convertSubstrate } from '../convert-substrate'

export function getSubstrate(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  key: string
  showHiddenTotals?: boolean
  section?: QuoteItemSection
}): UpdateableSubstrate {
  const { quote, options, key, showHiddenTotals, section } = args
  const sanitizedKey = key.trim().toLowerCase()
  const fullSubstrate = quote.substrates[sanitizedKey]

  const convertedSubstrate = convertSubstrate(key, quote, options, fullSubstrate, showHiddenTotals, section)

  return convertedSubstrate
}
