import { createFile } from '@paintscout/util/builder'
import type { UploadOptions } from '@paintscout/util/cloudinary'
import { uploadFile as cloudinaryUploadFile } from '@paintscout/util/cloudinary'
import type { QuoteFile } from 'paintscout'

interface UploadFileProps extends UploadOptions {
  file: string | File
  cloudName: string
  companyId: string
  uploadPreset: string
}

export default async function uploadFile(props: UploadFileProps): Promise<QuoteFile> {
  const { file, ...uploadOptions } = props

  const { secure_url, format, pages, public_id, width, height, type } = await cloudinaryUploadFile(file, uploadOptions)

  const quoteFile = createFile({ type: file instanceof File ? file.type : type })

  return cleanObject({
    ...quoteFile,
    cloudinaryPublicId: public_id,
    format,
    height,
    pages,
    src: secure_url,
    type,
    width
  }) as QuoteFile
}

function cleanObject(obj: Record<string, any>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined))
}
