import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Button,
  MenuItem,
  OnboardingPopper,
  PopupMenu,
  useClientOptions,
  useOnboardingContext,
  useUser
} from '@ui/stickybid'
import { useQuote } from '../../../context/useQuote'
import React, { useState, useEffect } from 'react'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { formatPhoneNumber, isUserAssigned, isUserOwner } from '@paintscout/util/builder'

import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import MapIcon from '@material-ui/icons/MapOutlined'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/EditOutlined'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'

import type { Contact } from '@paintscout/api'
import { useContactQuery } from '@paintscout/api'
import { usePresentationNav } from '../../../presentation'

const addressFormat = require('address-format')

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing()
    },
    contents: {},
    placeholderContent: {
      paddingLeft: 0
    },
    hoverMenu: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      flexGrow: 1,
      marginRight: 5,
      minWidth: '135px'
    },
    cardLink: {
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },
    menuItemDivider: {
      padding: 0,
      height: 0,
      minHeight: 0,
      paddingBottom: theme.spacing(),
      marginBottom: theme.spacing()
    },
    quoteHeaderAddress: {
      whiteSpace: 'pre-wrap',
      marginTop: theme.spacing()
    }
  })
)

export interface ContactCardProps {
  hidePlaceholder?: boolean
  contact?: Partial<Contact>
}

export function ContactCard(props: ContactCardProps) {
  const classes = useStyles(props)
  const { hidePlaceholder } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const { presentation } = usePresentationNav()
  const { activeTask } = useOnboardingContext()
  const isActiveTask = activeTask?.key === 'create-quote_add-customer' && !activeTask?.completed

  const { onSelectContact, onCreateContact, onEditContact, isEditable, quote } = useQuote()
  const { options } = useClientOptions()
  const { preferences, user, hasPermissions } = useUser()
  const [deletedContact, setDeletedContact] = useState(false)

  const contact = props?.contact ?? quote.contact

  const { data: contactData } = useContactQuery({
    skip: !user || !contact || !(contact as any)?.id,
    variables: {
      id: (contact as any)?.id
    }
  })
  useEffect(() => {
    if (contactData && !contactData.contact && contact) {
      setDeletedContact(true)
    }
    if (contactData?.contact?._deleted) setDeletedContact(true)
  }, [contactData])

  const isAssigned = user ? (contact ? isUserAssigned(contact, user.user_id) : true) : false
  const isOwner = user ? (contact ? isUserOwner(contact, user.user_id) : true) : false
  const canEdit = user
    ? isOwner || (isAssigned && hasPermissions(['write:assigned-contacts'])) || hasPermissions(['write:all-contacts'])
    : false

  const phoneNumber = formatPhoneNumber({ options, phoneNumber: contact?.phone_number })
  const alternatePhoneNumber = formatPhoneNumber({ options, phoneNumber: contact?.alternate_phone })
  const contactInfo = getContactInfo({ phoneNumber, alternatePhoneNumber })

  const showPlaceholder = !hidePlaceholder && !contact && isEditable && !presentation

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const placeholderContent = (
    <div className={classes.hoverMenu}>
      <OnboardingPopper open={isActiveTask} title={'First, Add a Customer'}>
        <Button
          classes={{ root: classes.button }}
          prominence={4}
          fullWidth={true}
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add Customer
        </Button>
      </OnboardingPopper>
    </div>
  )

  return (
    <PopupMenu
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      component={
        <QuoteHeaderCard
          classes={{
            root: classes.root,
            contents: classes.contents,
            placeholderContent: classes.placeholderContent
          }}
          onClick={handleClick}
          title={'Customer'}
          subtitle={<div>{contactInfo.name}</div>}
          showPlaceholder={showPlaceholder}
          clickable={isEditable}
          placeholderContent={placeholderContent}
          data-testid={'quote-contact-card'}
        >
          <div>{contactInfo.details}</div>
        </QuoteHeaderCard>
      }
    >
      {canEdit && !deletedContact && contact && (
        <MenuItem icon={EditIcon} onClick={handleEditContact}>
          Edit Contact
        </MenuItem>
      )}
      <MenuItem icon={PeopleIcon} onClick={handleSelectContact}>
        Select Existing Contact
      </MenuItem>
      {(user ? hasPermissions(['create:contacts']) : false) && (
        <MenuItem icon={AddIcon} onClick={handleCreateContact}>
          Create & Use New Contact
        </MenuItem>
      )}
      {(!!phoneNumber || !!contact?.email || !!contact?.address) && (
        <MenuItem disabled classes={{ root: classes.menuItemDivider }} />
      )}
      {phoneNumber && (
        <MenuItem onClick={handleOpenCall} icon={PhoneIcon}>
          {formatPhoneNumber({ options, phoneNumber })}
        </MenuItem>
      )}
      {contact?.email && (
        <MenuItem onClick={handleOpenEmail} icon={EmailIcon}>
          {contact.email}
        </MenuItem>
      )}
      {contact?.address && (
        <MenuItem onClick={handleOpenMap} icon={MapIcon}>
          Open in Google Maps
        </MenuItem>
      )}
    </PopupMenu>
  )

  function getContactInfo({ phoneNumber, alternatePhoneNumber }) {
    const contact = props?.contact ?? quote.contact

    const details = []
    let name = null

    if (contact) {
      const {
        first_name,
        last_name,
        secondary_first_name,
        secondary_last_name,
        email,
        alternate_email,

        billingAddress,
        shippingAddress,
        address,
        city,
        province,
        postal,
        company
      } = contact

      if (company) {
        details.push(
          <div key="company" className="quote-header-company">
            {company}
          </div>
        )
      }

      if (phoneNumber) {
        details.push(
          <div key="phone" className="quote-header-phone-number">
            {phoneNumber}
          </div>
        )
      }

      if (alternatePhoneNumber) {
        details.push(
          <div key="alt-phone" className="quote-header-alternate-phone">
            {alternatePhoneNumber}
          </div>
        )
      }

      if (email) {
        details.push(
          <div key="email" className="quote-header-email">
            {email}
          </div>
        )
      }

      if (alternate_email) {
        details.push(
          <div key="alt-email" className="quote-header-alternate-email">
            {alternate_email}
          </div>
        )
      }

      // let fullAddress
      // if (billingAddress) {
      //   fullAddress = addressFormat({
      //     address: billingAddress.address,
      //     city: billingAddress.city,
      //     subdivision: billingAddress.province,
      //     postalCode: billingAddress.zip
      //   })
      // } else if (shippingAddress) {
      //   fullAddress = addressFormat({
      //     address: shippingAddress.address,
      //     city: shippingAddress.city,
      //     subdivision: shippingAddress.province,
      //     postalCode: shippingAddress.zip
      //   })
      // } else {
      //   fullAddress = addressFormat({ address, city, subdivision: province, postalCode: postal })
      // }

      // if (fullAddress && fullAddress.length > 0) {
      //   details.push(
      //     <div key="address" className={classnames('quote-header-address', classes.quoteHeaderAddress)}>
      //       {fullAddress.join('\n')}
      //     </div>
      //   )
      // }

      name = (
        <React.Fragment key="name">
          <div>
            {first_name && <span className="quote-header-first-name">{first_name}</span>}
            {first_name && last_name && <span> </span>}
            {last_name && <span className="quote-header-last-name">{last_name}</span>}
          </div>
          <div>
            {secondary_first_name && <span className="quote-header-first-name">{secondary_first_name}</span>}
            {secondary_first_name && secondary_last_name && <span> </span>}
            {secondary_last_name && <span className="quote-header-last-name">{secondary_last_name}</span>}
          </div>
        </React.Fragment>
      )
    }

    return { name, details }
  }

  function handleOpenCall() {
    // window.open(`tel:${contact.phone_number}`)
    document.location.href = `tel:${contact.phone_number}`
  }
  function handleOpenEmail() {
    window.open(`mailto:${contact.email}`, '_blank')
  }
  function handleOpenMap() {
    window.open(`http://maps.google.com/?q=${contact.address}`, '_blank')
  }

  function handleSelectContact() {
    onSelectContact()
    setAnchorEl(null)
  }

  function handleCreateContact() {
    onCreateContact()
    setAnchorEl(null)
  }

  function handleEditContact() {
    onEditContact()
    setAnchorEl(null)
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(null)
  }
}

export default ContactCard
