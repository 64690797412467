/**
 * @module builder
 */
import type { QuoteDocument, QuoteStatus } from 'paintscout'
import { getStatusOptions } from '../get-status-options'
import find from 'lodash/find'
import { capitalizeFirstLetter } from '../../../../util'

export function getStatus(args: { status: string | QuoteStatus }) {
  const { status: quoteStatus } = args

  const statusOptions = getStatusOptions({})

  let status = find(statusOptions, { value: quoteStatus as any })

  if (!status) {
    status = {
      value: quoteStatus as QuoteStatus,
      label: capitalizeFirstLetter(quoteStatus),
      primary: true
    }
  }

  return status
}

export function getStatusNote(args: { quote: QuoteDocument; customer?: boolean }) {
  const { quote, customer } = args

  if (customer) {
    return quote?.status?.customerNote?.value ?? null
  } else {
    return quote?.status?.note?.value ?? null
  }
}
