import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import ContactCard from '../ContactCard'
import QuoteHeaderLogo from '../QuoteHeaderLogo'
import AddressCard from '../AddressCard'
import QuoteDateCard from '../QuoteDateCard/QuoteDateCard'
import QuoteStatusCard from '../QuoteStatusCard'
import QuoteIDCard from '../QuoteIDCard'
import QuoteTotalCard from '../QuoteTotalCard'
import CompanyCard from '../CompanyCard'
import EstimatorCard from '../EstimatorCard'
import EditIcon from '@material-ui/icons/Edit'

import { usePresentationNav } from '../../../presentation'
import { useQuote } from '../../../context'
import { getPresentationLogo } from '@paintscout/util/builder'
import { useClientOptions, Grid, Typography } from '@ui/stickybid'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4)
    },
    topRow: {
      marginBottom: theme.spacing(1)
    },
    bottomRow: {
      marginTop: theme.spacing(1)
    },
    statusCardWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      marginLeft: 'auto',
      padding: 0
    },
    statusCardDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    dateCard: {
      marginBottom: theme.spacing(2)
    },
    editOcrBlock: {
      position: 'relative',
      textAlign: 'right'
    },
    editOcrBlockEditable: {
      cursor: 'pointer',
      '&:hover': {
        '& div': {
          color: `${theme.palette.common.black} !important`
        },
        '& $editIcon': {
          color: theme.palette.primary.main
        },
        '& $noImportedDataTitle': {
          color: theme.palette.primary.main
        }
      }
    },
    editIcon: {
      color: theme.palette.secondary.main,
      position: 'absolute',
      right: -36,
      bottom: 3,
      fontSize: '1rem'
    },
    noImportedDataTitle: {
      color: theme.palette.secondary.main
    }
  })

export interface QuoteHeaderProps extends WithStyles<typeof styles> {
  isEditable?: boolean
  workOrder?: boolean
  className?: string
}

function QuoteHeaderFull({ classes, workOrder, className, ...props }: QuoteHeaderProps) {
  const { quote, onImportQuoteImage, isEditable } = useQuote()
  const { options } = useClientOptions()
  const { presentation } = usePresentationNav()

  const isTemplate = quote.type === 'quoteTemplate'

  const { logo } = getPresentationLogo({ options, quote, presentation })
  const showEstimatorCard = quote.owner || (isEditable && !presentation)

  return (
    <div className={`${classes.root} ${className}`} id="quote-header-full">
      <Grid container spacing={2} justifyContent={'flex-start'} classes={{ container: classes.topRow }}>
        {logo && (
          <Grid item xs={3}>
            <QuoteHeaderLogo logo={logo} />
          </Grid>
        )}
        <Grid item xs={3}>
          <CompanyCard />
        </Grid>
        {showEstimatorCard && (
          <Grid item xs={3}>
            <EstimatorCard />
          </Grid>
        )}
        <Grid item xs={3} className={classes.statusCardWrapper}>
          {!isTemplate && (
            <div className={classes.statusCardDiv}>
              <QuoteStatusCard workOrder={workOrder} />
              <QuoteIDCard />
              <QuoteDateCard />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} classes={{ container: classes.bottomRow }}>
        <Grid item md={3}>
          {!isTemplate && <ContactCard />}
        </Grid>
        <Grid item md={3}>
          <AddressCard type="billingAddress" />
        </Grid>
        {!!quote.importedContent?.primary?.src && (
          <>
            <Grid item md={2}></Grid>
            <Grid item md={4}>
              <div
                className={classnames(classes.editOcrBlock, {
                  [classes.editOcrBlockEditable]: isEditable && !presentation
                })}
                onClick={isEditable && !presentation ? handleEditOcrFields : null}
              >
                {isEditable && !presentation && <EditIcon className={classes.editIcon} />}
                {isEditable && !presentation && !quote.job_identifier && !quote.totalAdjustment?.rate && (
                  <Typography className={classes.noImportedDataTitle} variant={'overline'}>
                    Edit Imported Data
                  </Typography>
                )}

                <QuoteIDCard importedId />
                <QuoteTotalCard />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )

  function handleEditOcrFields(ev: React.MouseEvent) {
    onImportQuoteImage({ edit: true })
  }
}

export default withStyles(styles)(QuoteHeaderFull)
