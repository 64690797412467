import React, { useState, useMemo, useEffect } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import { jsonToLucene } from 'json-to-lucene'
import ErrorMessage from '../../components/ErrorMessage'
import useRouter from 'use-react-router'

import NewUserButton from '../../components/NewUserButton'
import UserSearchTable from '../../components/UserSearchTable/UserSearchTable'
import { NavHeader } from '../../components/NavDrawer'
import { Card } from '@material-ui/core'

import { useLazyQuery } from '@apollo/react-hooks'
import type { SearchUsersResponse } from '../../graphql/queries/SEARCH_USERS'
import { SEARCH_USERS } from '../../graphql/queries/SEARCH_USERS'
import UserSearchBar from 'src/components/UserSearchBar'
import { getFiltersFromLocation } from '../../components/ClientSearchBar/ClientSearchBar'
import type { SearchProps } from '../Clients/SearchClients'
import { setUrlQuery } from '../../util/setUrlQuery'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles<Theme>({
  root: {}
})

export default function SearchUsers() {
  const classes = useStyles({})
  const { location, history } = useRouter<any, any, { page: number }>()
  const [page, setPage] = useState(location.state?.page ?? 1)
  const limit = 50

  const [filters, setFilters] = useState(getFiltersFromLocation(location))
  const { enqueueSnackbar } = useSnackbar()
  const [query, setQuery] = useState('') // will get populated from UserSearchBar
  const baseFilter = {
    type: 'in',
    id: 'app_metadata.roles',
    not: true,
    value: ['superadmin']
  }
  const baseQuery = useMemo(
    () =>
      jsonToLucene({
        filters: [
          // filter out users that are superadmins
          {
            type: 'in',
            id: 'app_metadata.roles',
            not: true,
            value: ['superadmin']
          }
        ]
      }),
    // debounce how often  useMemo runs to create the new query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [search, { loading, data, error, fetchMore }] = useLazyQuery<SearchUsersResponse>(SEARCH_USERS, {
    variables: {
      query,
      limit
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (newData: SearchUsersResponse) => {
      // reset page back to 1 if user refreshes the page (cache is reset)
      if (
        page * limit > newData?.searchUsers?.rows.length &&
        newData?.searchUsers?.rows.length !== newData?.searchUsers?.total_rows
      ) {
        setPage(1)
      }
    }
  })

  function handleUserSearch({ text, filters, isInitialSearch }: SearchProps) {
    let searchQuery = jsonToLucene({ query: text ?? '', filters: [...(filters as any), baseFilter as any] }) || ''
    if (searchQuery === '') {
      searchQuery = baseQuery
    }

    setUrlQuery({
      location,
      params: { search: text || null, filters },
      replaceParams: true
    })

    setQuery(searchQuery)
    setFilters(filters)

    if (!isInitialSearch) {
      setPage(1)
    }

    search({
      variables: {
        // eslint-disable-next-line no-useless-escape
        query: searchQuery.replace(/\"true\"/gi, 'true').replace(/\"false\"/gi, 'false'),
        limit
      }
    })
  }

  useEffect(() => {
    history.replace({
      ...history.location,
      state: { page }
    })
  }, [page, history])

  // show error toast
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Unable to search Clients`, { variant: 'error' })
    }
  }, [error, enqueueSnackbar])

  const users = data?.searchUsers?.rows
  const totalRows = data?.searchUsers?.total_rows ?? 0

  return (
    <div className={classes.root}>
      <NavHeader title="Users" />
      <NewUserButton />
      <UserSearchBar
        loading={loading}
        filters={filters}
        showChips={true}
        onSearch={(query, filters, isInitialSearch) => {
          handleUserSearch({
            text: query,
            filters,
            isInitialSearch
          })
        }}
      />
      {error && <ErrorMessage>{error?.message || 'Something went wrong'}</ErrorMessage>}

      <Card>
        <UserSearchTable
          users={loading ? [] : users}
          loading={loading}
          rowsPerPage={limit}
          pages={Math.floor(totalRows / limit)}
          onFetchNextPage={() => {
            fetchMore({
              variables: {
                // eslint-disable-next-line no-useless-escape
                query: query.replace(/\"true\"/gi, 'true').replace(/\"false\"/gi, 'false'),
                limit,
                bookmark: data.searchUsers.bookmark
              },
              updateQuery(prev, { fetchMoreResult }) {
                if (!fetchMoreResult) {
                  return prev
                }

                return {
                  ...prev,
                  searchUsers: {
                    ...prev.searchUsers,
                    bookmark: fetchMoreResult.searchUsers.bookmark,
                    rows: [...prev.searchUsers.rows, ...fetchMoreResult.searchUsers.rows]
                  }
                }
              }
            })
          }}
        />
      </Card>
    </div>
  )
}
