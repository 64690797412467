import React from 'react'
import type { PresentationSectionProps } from '../../'

export default function Divider(props: PresentationSectionProps) {
  const { WrapperComponent } = props

  return (
    <WrapperComponent {...props}>
      <div>
        <hr />
      </div>
    </WrapperComponent>
  )
}
