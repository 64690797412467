/**
 * @module database
 */

import companiesJson from './$companies.json'

import sbOptionsJson from './sb/$options.json'

import trialTemplate1 from './trial/docs/templates/59bce278-32fe-470f-bb90-ad361968fa45.json'
import trialTemplate2 from './trial/docs/templates/830558e5-8579-4652-8351-e332aa5c9778.json'

import ratesJson from './$rates.json'
import blankRatesJson from './blank/$rates.json'
import trialRatesJson from './trial/$rates.json'
import sbRatesJson from './sb/$rates.json'

import trialContact1 from './trial/docs/contacts/contact-1b5f8334-b828-41ae-9820-15d543eaacdd.json'
import trialContact2 from './trial/docs/contacts/contact-49f92091-0dfd-4c10-91c6-9e4c3b538e4a.json'
import trialContact3 from './trial/docs/contacts/contact-59eb9635-16e3-450d-9200-a81ca4847ef7.json'
import trialContact4 from './trial/docs/contacts/contact-a95352d1-75d3-4d79-badc-29b5ed0568bf.json'

import sbTrialContact from './sb/contacts/contact-c2ddde38-a762-41ad-9163-841d92bf82b8.json'

import trialDraft1Quote from './trial/docs/draft-1/quote-7d8bdcfd-80b2-4af8-8646-28e96cf8a821.json'
import trialDraft1Activity from './trial/docs/draft-1/activity-564be000846e663384af2bae12129bd6.json'

import trialDraft2Quote from './trial/docs/draft-2/quote-e0fc5cc2-3099-4595-9d40-4da83de71c07.json'
import trialDraft2Activity from './trial/docs/draft-2/activity-acdb01f219be2e7d38ad50e8fc8c045c.json'

import trialExteriorQuoteAcceptedQuote from './trial/docs/exterior-quote-accepted/quote-831c8888-1205-4c32-95f9-1b51adb7152f.json'
import trialExteriorQuoteAcceptedActivity1 from './trial/docs/exterior-quote-accepted/activity-39a249f687662e8ffd6b740f468d4a1a.json'
import trialExteriorQuoteAcceptedActivity2 from './trial/docs/exterior-quote-accepted/activity-6311c8b060ccd8e38718907769813a27.json'
import trialExteriorQuoteAcceptedActivity3 from './trial/docs/exterior-quote-accepted/activity-79c7730bfb55405611f71f746b416c72.json'
import trialExteriorQuoteAcceptedActivity4 from './trial/docs/exterior-quote-accepted/activity-e1a9fc180c14c95a6fb6ed66a25f10a5.json'

import trialInteriorQuoteSentQuote from './trial/docs/interior-quote-sent/quote-53d2f8e9-c487-49de-89ce-e89499d64c8f.json'
import trialInteriorQuoteSentActivity1 from './trial/docs/interior-quote-sent/activity-1be1ab0ecbeb4157196c5f111618061b.json'
import trialInteriorQuoteSentActivity2 from './trial/docs/interior-quote-sent/activity-1eddc738e8e7dc274b8f4832e69eb6c1.json'
import trialInteriorQuoteSentActivity3 from './trial/docs/interior-quote-sent/activity-36ffebba83d13d15ea9d43f472f9c5db.json'
import trialInteriorQuoteSentActivity4 from './trial/docs/interior-quote-sent/activity-8750fac7e9d4ed91284558be435f2133.json'
import trialInteriorQuoteSentActivity5 from './trial/docs/interior-quote-sent/activity-bd06924147d7ee864ccaa32fd0277783.json'
import trialInteriorQuoteSentActivity6 from './trial/docs/interior-quote-sent/activity-c0acf70bcbcedc0e36b54913f7ed79e7.json'
import trialInteriorQuoteSentActivity7 from './trial/docs/interior-quote-sent/activity-df8824e842e268c44c1b31383ca3d5d7.json'
import trialInteriorQuoteSentActivity8 from './trial/docs/interior-quote-sent/activity-f77a3e7837cee8b91a9b3ffdee93d0b4.json'

import trialInteriorQuoteViewedQuote from './trial/docs/interior-quote-viewed/quote-49ccbc76-0d7d-4c4b-92a4-85908e732d79.json'
import trialInteriorQuoteViewedActivity1 from './trial/docs/interior-quote-viewed/activity-047273e9a614c79dbac8dcb45c61bdfa.json'
import trialInteriorQuoteViewedActivity2 from './trial/docs/interior-quote-viewed/activity-3d79dc8a44a37398e23e6e274e8740ce.json'
import trialInteriorQuoteViewedActivity3 from './trial/docs/interior-quote-viewed/activity-9843eabfa05ce248f82f78030de675ea.json'
import trialInteriorQuoteViewedActivity4 from './trial/docs/interior-quote-viewed/activity-fcf90fb2b44e8a5470faf65178a90798.json'

export { getIndustryLineItems } from './sb/line-items/getIndustryLineItems'
export { industryOptions } from './sb/presentations/industryOptions'

import sequenceJson from './$sequence.json'
import type { OptionsDocument, FeatureOptions } from 'paintscout'
import prefsTemplate from './preferences-template.json'
import cloneDeep from 'lodash/cloneDeep'

export const getPreferencesFixture = () => {
  return JSON.parse(JSON.stringify(prefsTemplate))
}

export const getCompaniesFixture = () => JSON.parse(JSON.stringify(companiesJson))
export const getRatesFixture = (config: { template?: string } = {}) => {
  if (config.template === 'blank') {
    return JSON.parse(JSON.stringify(blankRatesJson))
  } else if (config.template === 'trial') {
    return JSON.parse(JSON.stringify(trialRatesJson))
  } else if (config.template === 'sb') {
    return JSON.parse(JSON.stringify(sbRatesJson))
  } else {
    return JSON.parse(JSON.stringify(ratesJson))
  }
}
export const getSequenceFixture = () => JSON.parse(JSON.stringify(sequenceJson))

export const getOptionsFixture = (
  config: {
    features?: Partial<FeatureOptions>
  } = {}
) => {
  const optionsDoc = JSON.parse(JSON.stringify(sbOptionsJson))

  if (config.features) {
    optionsDoc.options.features = config.features
  }

  return optionsDoc as OptionsDocument
}

export const getOtherFixtures = ({ template }: { template?: string }) => {
  const baseline = getBaseline(template)

  const now = Date.now()

  if (template === 'blank') {
    return []
  }

  const trialContacts = getTrialContacts(template)

  trialContacts
    .map((contact) => {
      const createdDate = adjustDate(contact.created_date)
      return { ...contact, created_date: createdDate, updated_date: createdDate }
    })
    .map((contact) => {
      return JSON.parse(JSON.stringify(contact))
    })

  const trialTemplates = getTrialTemplates(template)

  const trialActivity = getTrialActivities(template)
    .map((activity) => {
      return {
        ...activity,
        details: {
          ...activity.details,
          timestamp: adjustDate(activity.details.timestamp)
        }
      }
    })
    .map((activity) => {
      return JSON.parse(JSON.stringify(activity))
    })

  const trialQuotes = getTrialQuotes(template)
    .map((quote: any) => {
      const updatedQuote = cloneDeep(quote)
      delete updatedQuote.urls
      updatedQuote.quote_date = adjustDate(updatedQuote.quote_date)
      if (updatedQuote.status) {
        if (updatedQuote.status.updated) {
          updatedQuote.status.updated = adjustDate(updatedQuote.status.updated)
        }
        if (updatedQuote.status.history) {
          Object.keys(updatedQuote.status.history).forEach((status) => {
            updatedQuote.status.history[status].timestamp = adjustDate(updatedQuote.status.history[status].timestamp)
            updatedQuote.status.history[status].day = adjustDate(updatedQuote.status.history[status].day)
          })
        }
      }

      const createdDate = adjustDate(quote.dates.created)
      updatedQuote.dates.created = createdDate
      updatedQuote.dates.updated = createdDate

      if (updatedQuote.dates?.client) {
        Object.keys(updatedQuote.dates.client).forEach((d) => {
          if (updatedQuote.dates.client[d]) {
            updatedQuote.dates.client[d] = adjustDate(updatedQuote.dates.client[d])
          }
        })
      }

      return updatedQuote
    })
    .map((quote) => {
      return JSON.parse(JSON.stringify(quote))
    })

  return [...trialActivity, ...trialQuotes, ...trialTemplates]

  function adjustDate(d: number) {
    return now - (baseline - d)
  }
}

function getTrialContacts(template) {
  if (template === 'sb') {
    return [sbTrialContact]
  }

  return [trialContact1, trialContact2, trialContact3, trialContact4]
}

function getTrialTemplates(template) {
  if (template === 'sb') {
    /* no templates for sb yet */
    return []
  }

  return [trialTemplate1, trialTemplate2]
}

function getTrialActivities(template) {
  if (template === 'sb') {
    // no trial activities for SB
    // return [sbSentQuoteActivity, sbAcceptedQuoteActivity]
    return []
  }

  return [
    trialDraft1Activity,
    trialDraft2Activity,
    trialExteriorQuoteAcceptedActivity1,
    trialExteriorQuoteAcceptedActivity2,
    trialExteriorQuoteAcceptedActivity3,
    trialExteriorQuoteAcceptedActivity4,
    trialInteriorQuoteSentActivity1,
    trialInteriorQuoteSentActivity2,
    trialInteriorQuoteSentActivity3,
    trialInteriorQuoteSentActivity4,
    trialInteriorQuoteSentActivity5,
    trialInteriorQuoteSentActivity6,
    trialInteriorQuoteSentActivity7,
    trialInteriorQuoteSentActivity8,
    trialInteriorQuoteViewedActivity1,
    trialInteriorQuoteViewedActivity2,
    trialInteriorQuoteViewedActivity3,
    trialInteriorQuoteViewedActivity4
  ]
}

function getTrialQuotes(template) {
  if (template === 'sb') {
    // no trial quotes for sb
    // return [sbSentQuote, sbAcceptedQuote]
    return []
  }
  return [
    trialDraft1Quote,
    trialDraft2Quote,
    trialExteriorQuoteAcceptedQuote,
    trialInteriorQuoteSentQuote,
    trialInteriorQuoteViewedQuote
  ]
}

function getBaseline(template) {
  if (template === 'sb') {
    return 1648781870000
  }

  return 1601230058000 // the day this was done, the dates should be normalized relative to this
}
