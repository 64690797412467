import type { QuoteDocument } from 'paintscout'
import { parseNumber, fixNumber } from '../util'

export function tax(quote: QuoteDocument) {
  const { materialRate, laborRate } = (quote.tax_details ?? []).reduce(
    (acc, item) => {
      const m = parseNumber(item.materialRate)
      const l = parseNumber(item.laborRate)
      return {
        materialRate: acc.materialRate + m,
        laborRate: acc.laborRate + l
      }
    },
    { materialRate: 0, laborRate: 0 }
  )

  if (materialRate || laborRate) {
    const price = parseNumber(quote.totals.after_discount)
    const materials = parseNumber(quote.totals.materials)
    const labor = price - materials

    const materialsTax = materials * materialRate * 0.01
    const laborTax = labor * laborRate * 0.01

    const totalTax = materialsTax + laborTax

    return fixNumber(totalTax)
  }

  const taxRate = parseNumber(quote.tax_rate)
  const price = parseNumber(quote.totals.after_discount)

  const totalTax = price * taxRate * 0.01
  const r = fixNumber(totalTax)

  return r
}
