import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core'
import type { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { default as MuiButton } from '@material-ui/core/Button'
import classnames from 'classnames'
import React from 'react'
import Spinner from '../Spinner'
import type { StyleClasses } from '../theme'

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden'
  },
  loading: {
    '& $startIcon': {
      opacity: 0
    },
    '& $endIcon': {
      opacity: 0
    }
  },
  startIcon: {},
  endIcon: {},
  spinnerWrapper: {
    opacity: '1 !important',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  buttonSpan: {
    whiteSpace: 'nowrap'
  },
  spinner: {},
  label: {},
  contained: {},
  disabled: {},
  outlined: {},
  text: {},
  textPrimary: {},
  textSecondary: {},
  sizeSmall: {},
  sizeLarge: {}
}))

export interface ButtonProps extends MuiButtonProps {
  classes?: MuiButtonProps['classes'] & StyleClasses<typeof useStyles>
  children?: React.ReactNode
  loading?: boolean
  component?: React.ElementType
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button({ loading, children, ...props }, ref) {
  const classes = useStyles({ loading, ...props })
  const {
    loading: loadingClass,
    spinnerWrapper: spinnerWrapperClass,
    spinner: spinnerClass,
    buttonSpan,
    ...otherClasses
  } = classes
  const theme = useTheme()
  const { variant } = props
  const disabled = !!(props.disabled || loading)

  return (
    <MuiButton
      {...props}
      disabled={disabled}
      classes={{
        ...otherClasses,
        root: classnames(classes.root, loading && loadingClass)
      }}
      ref={ref}
    >
      <span className={buttonSpan} aria-hidden={loading} style={loading ? { opacity: 0 } : {}}>
        {children}
      </span>
      {loading && (
        <div className={spinnerWrapperClass} data-testid="button-spinner">
          <Spinner
            fullWidth
            fullHeight
            size={15}
            color={variant === 'contained' ? '#fff' : theme.palette.primary.main}
            classes={{ spinner: spinnerClass }}
          />
        </div>
      )}
    </MuiButton>
  )
})

Button.defaultProps = {
  variant: 'contained',
  loading: false
}

export default Button
