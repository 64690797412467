import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { DropzoneProps as ReactDropzoneProps, DropzoneState, DropzoneRef } from 'react-dropzone'
import ReactDropzone from 'react-dropzone'
import classnames from 'classnames'

import Button from '../Button'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%'
    },
    acceptDrop: {},
    rejectDrop: {
      // '& $uploadButton': {
      //   borderColor: theme.palette.error.main,
      //   padding: 0
      // }
    },
    addBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.borderRadius.md,
      border: `1px solid ${theme.palette.grey[500]}`,
      height: '100%',
      '&:hover': {
        cursor: 'pointer',
        borderColor: theme.palette.primary.main,
        color: alpha(theme.palette.primary.main, 0.8)
      }
    },
    addWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${theme.spacing(3)}px 0`
    },
    addText: {
      paddingTop: theme.spacing(2)
    }
  })

export interface DropzoneProps extends WithStyles<typeof styles>, ReactDropzoneProps {
  style?: React.CSSProperties
  className?: string
  text?: string

  /**
   * Makes the button the full height of the dropzone
   *
   * (you may _not_ want this if you want to use a normal sized 'outlined' variant button and keep the size consistent)
   */
  buttonFullHeight?: boolean

  shouldRenderPreview?: boolean
  dropzoneRef?: React.RefObject<DropzoneRef>
  renderPreview?: (dropzoneArgs: DropzoneState) => any
  onError?: (files: File[], errors: string[]) => any
}

function Dropzone({
  classes,
  className,
  text,
  shouldRenderPreview,
  renderPreview,
  style,
  dropzoneRef,
  buttonFullHeight,
  maxSize,
  onError,
  disabled,
  ...dropzoneProps
}: DropzoneProps) {
  function handleDropRejected(files: File[], event) {
    if (onError) {
      onError(
        files,
        files.map((file) => {
          let error = 'FILE_INVALID'
          if (file.size > maxSize) {
            error = 'LIMIT_FILE_SIZE' // matches multer error response for exceeding file size
          }

          return error
        })
      )
    }

    if (dropzoneProps.onDropRejected) {
      return dropzoneProps.onDropRejected(files, event)
    }
  }

  return (
    <ReactDropzone
      {...dropzoneProps}
      maxSize={maxSize}
      onDropRejected={handleDropRejected}
      ref={dropzoneRef}
      disabled={disabled}
    >
      {(dropzoneArgs) => {
        const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = dropzoneArgs
        const buttonMessage = isDragReject ? 'Invalid File Type' : text || 'Add'
        return (
          <div
            {...getRootProps()}
            className={classnames({
              [classes.root]: true,
              [className]: !!className,
              [classes.acceptDrop]: isDragAccept,
              [classes.rejectDrop]: isDragReject
            })}
            style={style}
          >
            <input {...getInputProps({})} />
            {shouldRenderPreview && renderPreview ? (
              renderPreview(dropzoneArgs)
            ) : (
              <div className={classes.addBody}>
                <div className={classes.addWrapper}>
                  <ArrowUpwardIcon />
                  <Button className={classes.addText} prominence={3}>
                    {buttonMessage}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )
      }}
    </ReactDropzone>
  )
}

Dropzone.defaultProps = {
  maxSize: 1024 * 200000 // 100mb
}

export default withStyles(styles)(Dropzone)
