import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Dialog as CoreDialog } from '@ui/core'
import type { DialogProps as CoreDialogProps } from '@ui/core'
import { useMediaQuery } from '../styles'

const useStyles = makeStyles<Theme, DialogProps>((theme) => ({
  root: {},
  paper: {
    borderRadius: theme.borderRadius.md
  }
}))

export interface DialogProps extends CoreDialogProps {
  children?: React.ReactNode
}

function Dialog(props: DialogProps) {
  const classes = useStyles(props)
  const { forceFullScreen, ...rest } = props
  const fullscreen = useMediaQuery('xs')

  return (
    <CoreDialog
      {...rest}
      forceFullScreen={forceFullScreen || fullscreen}
      classes={classes}
      maxWidth={props.maxWidth || 'md'}
      fullWidth={true}
    >
      {props.children}
    </CoreDialog>
  )
}

export default Dialog
