import React from 'react'
import type { LinkProps as MuiLinkProps } from '@material-ui/core/Link'
import MuiLink from '@material-ui/core/Link'
import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'

const styles = createStyles({
  root: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
})

/**
 * A styled Link from react-router for the app
 */
function Link(props: MuiLinkProps & ReactRouterLinkProps & WithStyles<typeof styles>) {
  return <MuiLink component={ReactRouterLink as any} {...props} />
}

export default withStyles(styles)(Link)
