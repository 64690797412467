import type { QuoteItemSection, QuoteItemView, QuoteTemplateOption } from '@paintscout/util/builder'
import type { QuoteDocument, PresentationEstimator, QuoteFile } from 'paintscout'
import React from 'react'

/**
 * The value of the quote context received by consumers
 *
 */
export interface QuoteContextValue {
  quote?: QuoteDocument
  cleanQuote?: QuoteDocument
  tableView?: 'area' | 'substrate'
  presentationPage?: string
  sidebarPage?: string
  estimator?: PresentationEstimator
  isDirty?: boolean
  isCustomerView?: boolean
  isEditable?: boolean
  hasActiveAccount?: boolean
  canAddAdditionalWork?: boolean
  isSaving?: boolean
  permissionToEdit?: boolean

  editingTemplate?: QuoteTemplateOption

  save?: (quote?: QuoteDocument) => Promise<QuoteDocument>
  reset?: () => any

  // contact
  onSelectContact?: () => any
  onEditContact?: () => any
  onCreateContact?: () => any
  onEditSecondaryContact?: () => any
  onCreateSecondaryContact?: () => any
  onSelectSecondaryContact?: () => any
  onRemoveSecondaryContact?: () => any

  // estimator
  onSelectEstimator?: () => any

  // customer view optional items
  handleOptionalItems?: {
    selectedOptionalItems: string[]
    onSelectOptionalItems: (keys: string[]) => any
  }

  // quote info
  onEditTerms?: () => any
  onEditDetails?: () => any
  onEditNote?: () => any
  onEditJobAddress?: (addressType?: 'billingAddress' | 'shippingAddress') => any

  onAccept?: (pending?: boolean) => any
  onImportQuoteImage?: (args: { files?: QuoteFile[]; edit?: boolean }) => any
  quoteImportUploadLoading?: boolean
  quoteImportUpload?: () => void

  // quote items
  onEditItem?: (key: string) => any
  onEditItemFiles?: (args: { itemKey: string; index: number }) => any
  onItemAction?: (action: string, section: QuoteItemSection, keys: string[]) => any
  onReorder?: (args: { items: any[]; section: QuoteItemSection; view: QuoteItemView; addIndex?: number }) => void
  onAddItem?: (section?: QuoteItemSection, insertAfterKey?: string, type?: string) => any

  onTableViewChange?: (view: string) => any
  onSidebarPageChange?: (sidebarPage: string) => any
  onPresentationPageChange?: (presentationPage: string) => any

  toggleEditable?: (editable?: boolean) => any
  updateQuote?: (args: { quote: QuoteDocument; autosave?: boolean }) => any
  refetch?: () => any
}

export const QuoteContextInitValue: QuoteContextValue = {
  tableView: 'area' as const,
  presentationPage: '',
  sidebarPage: 'index',
  estimator: null,
  permissionToEdit: true,
  isDirty: false,
  editingTemplate: null,
  isEditable: true,
  hasActiveAccount: false,
  canAddAdditionalWork: false,
  isSaving: false,
  quoteImportUploadLoading: false,
  save: () => Promise.resolve(null),
  reset: () => {},
  onAccept: () => {},
  onAddItem: () => {},
  onCreateContact: () => {},
  onCreateSecondaryContact: () => {},
  onEditContact: () => {},
  onEditDetails: () => {},
  onEditItem: () => {},
  onEditItemFiles: () => {},
  onEditJobAddress: () => {},
  onEditNote: () => {},
  onEditSecondaryContact: () => {},
  onEditTerms: () => {},
  onImportQuoteImage: () => {},
  onItemAction: () => {},
  onPresentationPageChange: () => {},
  onRemoveSecondaryContact: () => {},
  onReorder: () => {},
  onSelectContact: () => {},
  onSelectEstimator: () => {},
  onSelectSecondaryContact: () => {},
  onSidebarPageChange: () => {},
  onTableViewChange: () => {},
  quoteImportUpload: () => {},
  toggleEditable: () => {},
  updateQuote: () => {}
}

export const QuoteContext = React.createContext<QuoteContextValue>(QuoteContextInitValue)
