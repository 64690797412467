import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { CardProps as MuiCardProps } from '@material-ui/core/Card'
import { default as MuiCard } from '@material-ui/core/Card'
import React from 'react'
import type { Action } from '../ActionButton'
import ActionButton from '../ActionButton'
import CustomBadge from '../CustomBadge'
import classnames from 'classnames'
import type { StyleClasses } from '@ui/core/theme'
// import type { WithUseStyles } from '../styles'
import CheckCircle from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles<Theme, TileProps>(
  (theme) => ({
    root: {
      ...theme.typography.body1,
      position: 'relative',
      // maxWidth: theme.typography.pxToRem(230),
      width: '100%',
      overflowWrap: 'anywhere',
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.borderRadius.md,
      boxShadow: 'none',
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      textAlign: 'left',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: '0.5s min-height',
      userSelect: 'none',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.grey[100]
      },
      // css gradients cannot be transitioned, so we
      // apply it as a pseudo element and fade it in/out with opacity
      '&:before': {
        zIndex: 0,
        position: 'absolute',
        content: '""',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transition: '0.3s opacity',
        opacity: 0,
        background: theme.palette.grey[100]
      },
      '&$selected:before': {
        opacity: 1
      }
    },
    content: (props) => ({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'start',
      justifyContent: 'center',
      '& label': {
        cursor: 'pointer'
      },
      ...(!props.Thumbnail && {
        marginLeft: theme.spacing(2)
      })
    }),
    layout: {
      position: 'relative',
      zIndex: 1,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    selected: {
      // color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main
    },
    hasThumbnail: {
      flexDirection: 'row'
    },
    thumbnail: {
      width: theme.typography.pxToRem(64),
      height: theme.typography.pxToRem(64),
      flexShrink: 0,
      marginRight: theme.spacing(3),
      '& svg': {
        borderRadius: theme.borderRadius.md,
        width: '100%',
        height: '100%'
      },
      '& img': {
        borderRadius: theme.borderRadius.md,
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      }
    },
    dense: {
      minHeight: 0,
      padding: '1em 1.5em'
    },
    disabled: {
      background: theme.palette.grey[200],
      borderColor: theme.palette.grey[400],
      color: theme.palette.grey[700],
      pointerEvents: 'none',
      cursor: 'default'
    },
    inactiveBadge: {
      backgroundColor: theme.palette.error.dark
    },
    inactive: {},
    actionButton: {
      margin: -12,
      marginLeft: 0,
      color: theme.palette.grey.A400
    },
    selectedIcon: {
      position: 'absolute',
      top: 6,
      right: 6
    }
  }),
  { name: 'Tile' }
)

export interface TileProps extends Omit<MuiCardProps, 'classes'> {
  actions?: Action[]
  active?: boolean
  children?: React.ReactNode
  classes?: StyleClasses<typeof useStyles>
  dense?: boolean
  disabled?: boolean
  hasSelectedIcon?: boolean
  id?: any
  onActionClick?: (event: React.MouseEvent<HTMLElement>, actionName: string, rowId: string | number) => void
  selected?: boolean
  Thumbnail?: React.FunctionComponent
}

const Tile = React.forwardRef(function Tile(props: TileProps, ref) {
  const classes = useStyles(props)

  const {
    selected: selectedClass,
    dense: denseClass,
    disabled: disabledClass,
    inactive: inactiveClass,
    content: contentClass,
    layout: layoutClass,
    thumbnail: thumbnailClass,
    hasThumbnail: hasThumbnailClass,
    root,

    ...baseCardClasses
  } = classes
  const {
    actions,
    active,
    dense,
    disabled,
    hasSelectedIcon,
    id,
    onActionClick,
    onClick,
    selected,
    Thumbnail,
    ...baseCardProps
  } = props
  const cardClasses = [classes.root]
  if (selected) {
    cardClasses.push(selectedClass)
  } else if (disabled) {
    cardClasses.push(disabledClass)
  } else if (!active && typeof active !== 'undefined') {
    cardClasses.push(inactiveClass)
  }

  if (dense) {
    cardClasses.push(denseClass)
  }

  const content =
    props.active || typeof props.active === 'undefined' ? (
      props.children
    ) : (
      <CustomBadge message={'This item is inactive and will not be shown.'} classes={{ root: classes.inactiveBadge }}>
        {props.children}
      </CustomBadge>
    )

  function handleClick(event: any) {
    if (props.onClick && !props.disabled) {
      props.onClick(event)
    }
  }
  return (
    <MuiCard
      component="button"
      // @ts-ignore - explicitly set type as 'button' to prevent submitting forms
      type="button"
      {...baseCardProps}
      ref={ref}
      onClick={handleClick}
      classes={{ root: cardClasses.join(' ') }}
      data-testid="tile"
    >
      {selected && hasSelectedIcon && <CheckCircle className={classes.selectedIcon} />}
      <div className={classnames({ [layoutClass]: true, [hasThumbnailClass]: !!Thumbnail })}>
        {Thumbnail && (
          <div className={thumbnailClass}>
            <Thumbnail />
          </div>
        )}
        <div className={layoutClass}>
          <div className={contentClass}>{content}</div>
          {actions && (
            <ActionButton
              classes={{
                button: classes.actionButton
              }}
              onActionClick={(ev, action) => props.onActionClick(ev, action, props.id)}
              disabled={disabled}
              actions={props.actions}
            />
          )}
        </div>
      </div>
    </MuiCard>
  )
})

Tile.defaultProps = {
  color: 'primary',
  dense: false,
  disabled: false
}

export default Tile
