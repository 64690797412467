import type { PresentationSection } from 'paintscout'

export function getSectionPadding(args: {
  section: PresentationSection
  source: 'presentation' | 'section'
  size: 'desktop' | 'tablet' | 'mobile'
  returnDefault?: boolean
  noTopPadding?: boolean
  contentWidth?: 'full' | 'md' | 'sm' | 'x-sm'
  horizontalOnly?: boolean
}): string {
  // tablet size is down('md'), mobile size is down('sm')
  const {
    section,
    source,
    size,
    returnDefault = false,
    noTopPadding = false,
    contentWidth: forceContentWidth,
    horizontalOnly
  } = args
  const fields = section.fields ?? {}
  const sectionType = section.type

  // default values
  let horizontalAlign = 'center'
  let verticalAlign = 'center'
  let sectionHeight = 'sm'
  let contentWidth = 'full'

  if (!returnDefault) {
    if (fields.horizontalAlign) horizontalAlign = fields.horizontalAlign
    if (fields.verticalAlign) verticalAlign = fields.verticalAlign
    if (fields.sectionHeight) sectionHeight = fields.sectionHeight
    if (fields.contentWidth) contentWidth = fields.contentWidth
  }

  // force centering of insets for sections below desktop size
  if (size !== 'desktop') {
    horizontalAlign = 'center'
  }

  // the "background image" of the poster layout must always be full width
  if (sectionType === 'banner' && section.fields.layout === 'poster' && source === 'presentation') {
    horizontalAlign = 'center'
    verticalAlign = 'center'
    sectionHeight = 'x-sm'
    contentWidth = 'full'
  }

  // md contentWidth === md sectionHeight
  // sm contentWidth === lg sectionHeight
  // lg contentWidth and xs sectionHeight are 0
  // xs contentWidth and sm sectionHeight are odd balls

  const alignmentFactor = 4
  const padding = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }

  const backgroundColor = section.background?.color
  const fullWidthImage2Col = sectionType === '2-column' && section.fields.fullWidthImage

  if (
    (contentWidth === 'full' &&
      backgroundColor &&
      !['#fff', '#ffffff'].includes(backgroundColor.toLowerCase()) &&
      !['banner'].includes(sectionType)) ||
    (contentWidth === 'full' && section.background?.useTheme)
  ) {
    contentWidth = 'md'
  }

  if (forceContentWidth) {
    contentWidth = forceContentWidth
  }

  let forcedInset = ['text', '2-column', '3-column', 'divider', 'quote', 'terms', 'cards', 'testimonials'].includes(
    sectionType
  )

  if (sectionType === 'gallery' && section.fields?.files?.some((file) => file.format === 'pdf')) {
    forcedInset = true
  }

  if (fullWidthImage2Col) {
    forcedInset = false
    contentWidth = 'full'
    sectionHeight = 'x-sm'
  }

  if (sectionType === 'spacer' && sectionHeight === 'x-sm') sectionHeight = 'x-sm-spacer'

  const horizontalPadding = {
    desktop: {
      'x-sm': 256,
      sm: 184,
      md: 112,
      full: 0
    },
    tablet: {
      'x-sm': 64,
      sm: 64,
      md: 64,
      full: !forcedInset ? 0 : 64
    },
    mobile: {
      'x-sm': 32,
      sm: 32,
      md: 32,
      full: !forcedInset ? 0 : 32
    }
  }
  const verticalPadding = {
    desktop: {
      'x-sm': 0,
      'x-sm-spacer': 24,
      sm: 48,
      md: 112,
      lg: 184
    },
    tablet: {
      'x-sm': 0,
      'x-sm-spacer': 24,
      sm: 48,
      md: 64,
      lg: 64
    },
    mobile: {
      'x-sm': 0,
      'x-sm-spacer': 16,
      sm: 32,
      md: 40,
      lg: 40
    }
  }

  if (horizontalAlign === 'left') {
    padding.left = horizontalPadding[size][contentWidth] / alignmentFactor
    padding.right = horizontalPadding[size][contentWidth] - horizontalPadding[size][contentWidth] / alignmentFactor
  }
  if (horizontalAlign === 'right') {
    padding.left = horizontalPadding[size][contentWidth] - horizontalPadding[size][contentWidth] / alignmentFactor
    padding.right = horizontalPadding[size][contentWidth] / alignmentFactor
  }
  if (horizontalAlign === 'center') {
    padding.left = horizontalPadding[size][contentWidth] / 2
    padding.right = horizontalPadding[size][contentWidth] / 2
  }
  if (verticalAlign === 'top') {
    padding.top = verticalPadding[size][sectionHeight] / alignmentFactor
    padding.bottom = verticalPadding[size][sectionHeight] - verticalPadding[size][sectionHeight] / alignmentFactor
  }
  if (verticalAlign === 'bottom') {
    padding.top = verticalPadding[size][sectionHeight] - verticalPadding[size][sectionHeight] / alignmentFactor
    padding.bottom = verticalPadding[size][sectionHeight] / alignmentFactor
  }
  if (verticalAlign === 'start') {
    padding.top = 0
    padding.bottom = verticalPadding[size][sectionHeight] / 2
  }
  if (verticalAlign === 'end') {
    padding.top = verticalPadding[size][sectionHeight] / 2
    padding.bottom = 0
  }
  if (verticalAlign === 'center') {
    padding.top = verticalPadding[size][sectionHeight] / 2
    padding.bottom = verticalPadding[size][sectionHeight] / 2
  }

  if (noTopPadding) {
    padding.top = 0
  }

  return `${horizontalOnly ? 0 : padding.top}px ${padding.right}px ${horizontalOnly ? 0 : padding.bottom}px ${
    padding.left
  }px`
}

// LEGACY TABLET AND MOBILE SIZES
// const horizontalPadding = {
//   desktop: {
//     'x-sm': 256,
//     sm: 184,
//     md: 112,
//     full: 0
//   },
//   tablet: {
//     'x-sm': 192,
//     sm: 96,
//     md: 64,
//     full: 0
//   },
//   mobile: {
//     'x-sm': 104,
//     sm: 62,
//     md: 40,
//     full: 0
//   }
// }
// const verticalPadding = {
//   desktop: {
//     'x-sm': 0,
//     sm: 48,
//     md: 112,
//     lg: 184
//   },
//   tablet: {
//     'x-sm': 0,
//     sm: 48,
//     md: 64,
//     lg: 96
//   },
//   mobile: {
//     'x-sm': 0,
//     sm: 32,
//     md: 40,
//     lg: 62
//   }
// }
