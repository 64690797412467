import React from 'react'
import type { Ref } from 'react'
import type { StyleClasses } from '../theme'
import { makeStyles, Tooltip } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { InputProps as MuiInputProps } from '@material-ui/core/Input'
import classnames from 'classnames'

import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles<Theme, InputProps>(
  (theme) => ({
    root: {},
    wrapper: (props) => ({
      ...(props.fullWidth && { flex: 1 })
    }),
    inlineWrapper: {
      display: 'flex',
      flexDirection: 'row',
      '& label': {
        position: 'static',
        margin: 0,
        border: '1px solid red'
      },
      '& $labelWrapper': {
        paddingTop: theme.spacing(2)
      },
      '& $inputWrapper > div': {
        marginTop: 0
      }
    },
    labelWrapper: {},
    labelSubWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    reset: {
      cursor: 'pointer',
      lineHeight: 1,
      fontSize: '.75rem'
    },
    inputWrapper: {},
    hasLabel: {},
    hasSublabel: {},
    input: {},
    helperText: {},
    focused: {}
  }),
  { name: 'Core_Input' }
)

export interface InputProps extends MuiInputProps {
  Typography: React.ComponentType<any>
  classes?: MuiInputProps['classes'] & StyleClasses<typeof useStyles>
  ref?: Ref<HTMLInputElement> // this is here to appease MessageInput

  label?: React.ReactNode
  sublabel?: React.ReactNode
  inputContent?: React.ReactNode
  inlineLabel?: boolean
  helperText?: React.ReactNode
  helperTextState?: 'disabled' | 'error' | 'filled' | 'focused'
  onReset?: () => any
  showReset?: boolean
}

function Input(props: InputProps) {
  const classes = useStyles(props)

  const {
    label: labelContent,
    sublabel: sublabelContent,
    inputContent,
    inlineLabel,
    helperText: helperTextContent,
    helperTextState,
    onReset,
    showReset,
    Typography,
    ...baseInputProps
  } = props

  const {
    hasLabel,
    hasSublabel,
    inlineWrapper,
    labelWrapper,
    inputWrapper,
    wrapper,
    labelSubWrapper,
    reset,
    helperText: helperTextClass,
    ...baseClasses
  } = classes

  const helperText = helperTextContent ? (
    <FormHelperText
      classes={{ root: helperTextClass }}
      className={helperTextClass}
      disabled={helperTextState === 'disabled'}
      error={helperTextState === 'error'}
      filled={helperTextState === 'filled'}
      focused={helperTextState === 'focused'}
    >
      {helperTextContent}
    </FormHelperText>
  ) : null

  return (
    <div className={classnames({ [wrapper]: true, [inlineWrapper]: inlineLabel })}>
      {(labelContent || sublabelContent) && (
        <div className={labelWrapper}>
          <div className={labelSubWrapper}>
            {labelContent && labelContent}
            {showReset && (
              <span
                className={classes.reset}
                onClick={(ev) => {
                  ev.preventDefault()
                  onReset()
                }}
              >
                <Tooltip title={'Reset to default (generated) value'}>
                  {/* tooltip does not like being the direct parent of Typography */}
                  <span>
                    <Typography variant={'overline'}>{'(Reset)'}</Typography>
                  </span>
                </Tooltip>
              </span>
            )}
          </div>
          {sublabelContent && sublabelContent}
        </div>
      )}
      <div className={inputWrapper}>
        {inputContent && inputContent}
        {helperText}
      </div>
    </div>
  )
}

export default Input
