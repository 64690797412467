import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import type { MenuItemProps as CoreMenuItemProps } from '@ui/core/MenuItem'
import CoreMenuItem from '@ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles<Theme, MenuItemProps>(
  (theme: Theme) => ({
    root: (props) => ({
      '& img': {
        fontSize: '1.5em',
        width: '1em',
        height: '1em',
        marginRight: '1rem'
      },
      padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
      ...(props.iconOnly && {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`
      }),
      ...theme.typography.subtitle1,
      color: theme.palette.text.primary,
      '&:hover': {
        '& svg': {
          fill: theme.palette.text.primary
        }
      }
    }),
    icon: (props) => ({
      color: theme.palette.grey[500],
      marginRight: theme.spacing(3),
      ...(props.iconOnly && {
        marginRight: 0
      })
    }),
    iconAfter: (props) => ({
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(3),
      ...(props.iconOnly && {
        marginLeft: 0
      })
    })
  }),
  { name: 'MenuItem' }
)

export interface MenuItemProps extends CoreMenuItemProps {
  children?: React.ReactNode
}

const MenuItem = React.forwardRef(function MenuItem({ children, ...props }: MenuItemProps, ref) {
  const classes = useStyles(props)

  return (
    <CoreMenuItem {...props} ref={ref as any} classes={classes}>
      {children}
    </CoreMenuItem>
  )
})

MenuItem.defaultProps = {
  disabled: false,
  iconAfter: false
}

export default MenuItem
