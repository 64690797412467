import { Box, makeStyles, useTheme } from '@material-ui/core'
import { DeleteIcon } from '@ui/core/icons'
import type { Theme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import type { FollowUp } from '@paintscout/api'
import React from 'react'
import ActionButton from '../ActionButton'
import FollowUpSummary from '../FollowUpSummary'
import HtmlContent from '../HtmlContent'
import Checkbox from '../Checkbox'
import Typography from '../Typography'
import Table, { TableBody, TableCell, TableHead, TableRow } from '../Table'
import { getFeatures } from '@paintscout/util/builder'
import { useClientOptions } from '..'
import get from 'lodash/get'
import ToolTip from '../Tooltip/Tooltip'

const useStyles = makeStyles<Theme, FollowUpTableProps>((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      maxHeight: 800
    }
  },
  messageBody: {
    paddingRight: theme.spacing(2),
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 110
    },
    // '& p': {
    // ellipsis truncate after 2 lines of text - works on most browsers
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    // maxHeight is for fallback on FF, which doesnt support -webkit-box-orient
    maxHeight: 70,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
    // }
  },
  cellButton: {
    width: '100%',
    // keep focus outline within the table
    marginLeft: 4,
    paddingLeft: -4
  },
  headerRow: {
    background: 'inherit'
  },
  // chrome doesn't work if we set position: sticky on the thead, so we have to put it on every cell
  headerCell: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'inherit',
    textAlign: 'center'
  },
  toolTipNoIcon: {
    display: 'none'
  }
}))

export interface FollowUpTableProps {
  followUps: FollowUp[]
  controls?: {
    toggle?: boolean
    edit?: boolean
    copy?: boolean
    delete?: boolean
  }
  disabled?: boolean
  onAction?: (action: 'edit' | 'copy' | 'delete' | 'toggle' | 'toggleSendText', followUp: FollowUp) => any
  settingsView?: boolean
  noStatus?: boolean
}

export default function FollowUpTable({ ...props }: FollowUpTableProps) {
  const classes = useStyles(props)
  const {
    followUps,
    controls = { edit: true, toggle: true, copy: true, delete: true },
    onAction,
    disabled,
    noStatus
  } = props

  const hasCrud = controls?.edit || controls?.copy || controls?.delete
  const theme = useTheme()
  const statusColors = theme.palette.status
  const { options } = useClientOptions()
  const features = getFeatures({ options })
  const showSendText = get(features, 'quotes.followUps.sendText', false)
  const showStatus = !hasCrud && !noStatus

  return (
    <Box className={classes.root}>
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.headerCell} style={{ textAlign: 'left' }}>
              <Typography variant="h3">Follow-Up</Typography>
            </TableCell>
            {showStatus && <TableCell className={classes.headerCell}>Status</TableCell>}
            <TableCell className={classes.headerCell} isControl>
              Active
            </TableCell>
            {showSendText && (
              <TableCell className={classes.headerCell} isControl>
                SMS
              </TableCell>
            )}
            {hasCrud && !!followUps.length && <TableCell className={classes.headerCell} isControl />}
          </TableRow>
        </TableHead>
        <TableBody>
          {followUps.map((followUp) => {
            const messageLength = followUp.message?.length || 0
            const overMaxTextLength = messageLength > 1500
            const statusColor = followUp.sent ? statusColors['sent'] : statusColors['draft']
            return (
              <>
                <TableRow key={followUp.key} style={{ borderBottom: 'none' }}>
                  <TableCell
                    component="div"
                    className={classes.cellButton}
                    onClick={() => controls?.edit && !disabled && onAction?.('edit', followUp)}
                  >
                    <div>
                      <Typography variant="body1" noWrap gutterBottom style={{ fontWeight: 500 }}>
                        <HtmlContent content={followUp.subject} />
                      </Typography>
                      <Typography variant="subtitle1" className={classes.messageBody}>
                        <HtmlContent content={followUp.message} />
                      </Typography>
                    </div>
                  </TableCell>
                  {showStatus && (
                    <TableCell>
                      <Typography variant={'h5'} noWrap style={{ color: statusColor, fontWeight: 700 }}>
                        {followUp?.sent ? 'Sent' : 'Sched.'}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell isControl>
                    <Checkbox
                      checked={followUp.active}
                      disabled={!controls.toggle || disabled || !!followUp.sent}
                      data-testid={`toggle-follow-up-${followUp.key}`}
                      onClick={(ev) => {
                        onAction?.('toggle', followUp)
                      }}
                    />
                  </TableCell>
                  {showSendText && (
                    <>
                      <TableCell isControl>
                        <ToolTip
                          disabled={!overMaxTextLength || !followUp.active}
                          classes={{
                            icon: classes.toolTipNoIcon
                          }}
                          content={<Typography variant="subtitle2">SMS character limit exceeded (1500)</Typography>}
                        >
                          <Checkbox
                            checked={overMaxTextLength || !followUp.active ? false : followUp.sendText}
                            disabled={
                              !controls.toggle || disabled || !followUp.active || overMaxTextLength || !!followUp.sent
                            }
                            data-testid={`toggle-follow-up-send-text-${followUp.key}`}
                            onClick={(ev) => {
                              onAction?.('toggleSendText', followUp)
                            }}
                          />
                        </ToolTip>
                      </TableCell>
                    </>
                  )}
                  {hasCrud && (
                    <TableCell isControl>
                      <ActionButton
                        disabled={disabled}
                        actions={[
                          {
                            key: 'edit',
                            label: 'Edit',
                            icon: EditIcon
                          },
                          {
                            key: 'copy',
                            label: 'Copy',
                            icon: CopyIcon
                          },
                          {
                            key: 'delete',
                            label: 'Delete',
                            icon: DeleteIcon
                          }
                        ]}
                        onActionClick={(ev, action) => {
                          onAction(action as any, followUp)
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <td colSpan={99}>
                    <FollowUpSummary followUp={followUp} mt={0.5} mb={0.25} />
                  </td>
                </TableRow>
              </>
            )
          })}
        </TableBody>
      </Table>
      {followUps.length === 0 && (
        <Typography style={{ marginTop: 8 }} variant="h6" align="center">
          No Follow-Ups yet
        </Typography>
      )}
    </Box>
  )
}
