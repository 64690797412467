import QuoteHeader from './QuoteHeader'
import QuoteHeaderCard from './QuoteHeaderCard'

export * from './QuoteHeaderCard'
export { QuoteHeaderCard }

export * from './ContactCard'
export * from './QuoteDateCard'
export * from './QuoteIDCard'
export * from './AddressCard'

import QuoteIDCard from './QuoteIDCard'
export { QuoteIDCard }

import QuoteDateCard from './QuoteDateCard'
export { QuoteDateCard }

import ContactCard from './ContactCard'
export { ContactCard }

import AddressCard from './AddressCard'
export { AddressCard }

export default QuoteHeader
