/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument, QuoteOptions } from 'paintscout'
import type { QuoteItemSection, QuoteItemConsumer, RenderableItem, RenderableSubItem } from '../../../index'
import { filterOrderItems, getItemOrder } from '../../get-items/util'
import { trimLineItem } from '../../../line-items'
import { trimSubstrate } from '../../../substrates'
import { getQuoteOptions, filterItem } from '../../../util'
import getSubstrateAreas from '../util/get-substrate-areas'
import { filterCrewSubstrateItems } from './util'

/**
 * Get Bid, Options, or Archived list of substrates for a WorkOrder.
 */
export function getCrewItemsBySubstrate(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  showLineItems?: boolean
  showValues?: boolean
}): RenderableItem[] {
  const { quote, options, overrideOptions, section, consumer, keys } = args
  const showLineItems = args.showLineItems === false ? false : true
  const showValues = args.showValues === false ? false : true

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const substrateOrder = getItemOrder('substrate', section, quote)

  const substrates = substrateOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys, workOrder: true })) {
        return null
      }

      if (orderItem.type === 'substrate') {
        const substrate = quote.substrates[orderItem.key]

        if (!substrate || (orderItem.parent && !filterItem(substrate, section))) {
          return null
        }

        // Get areas that are associated with this substrate in renderable form
        const substrateAreas = getSubstrateAreas({
          quote,
          options: quoteOptions,
          clientOptions: options,
          substrateKey: orderItem.key,
          section,
          showValues,
          consumer,
          view: 'substrate'
        })

        const subItemTotal = substrateAreas.reduce((acc: number, substrateArea: RenderableSubItem) => {
          return acc + Number(substrateArea.value)
        }, 0)

        // Filter out un-needed substrate items for section
        if (filterCrewSubstrateItems({ substrate, substrateAreas, section })) {
          return null
        }

        // Trim substrate with its areaSubstrates
        const trimmedSubstrate = trimSubstrate({
          quote,
          clientOptions: options,
          item: { ...orderItem, ...substrate },
          subItems: substrateAreas,
          options: quoteOptions,
          consumer,
          section
        })

        return {
          ...trimmedSubstrate,
          subItems: substrateAreas,
          value: subItemTotal
        }
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems[orderItem.key]
        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer) as RenderableItem
        }
      }

      return null
    })
    .filter((item) => item)

  return substrates
}
