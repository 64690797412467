import React, { useState, useEffect, useRef } from 'react'
import type { EditableLogo } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles, ClickAwayListener } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { PopupMenuItem, PresEditorTooltip } from '@ui/stickybid'
import { SectionOptionsPopup } from '../../'
import EditOutlineIcon from '@material-ui/icons/EditOutlined'
import classnames from 'classnames'

interface InlineEditableLogoProps {
  classes?: StyleClasses<typeof useStyles>
  logo: EditableLogo
  logoOptionsProps: {
    sectionPrefix: string
    fieldSuffix: string
  }
  isEditable: boolean
  handleEdit: (logo: EditableLogo) => void
  isSettings: boolean
}

const useStyles = makeStyles<Theme, InlineEditableLogoProps>(
  (theme: Theme) => {
    return {
      root: {
        position: 'relative'
      },
      hovered: {
        cursor: 'pointer',
        '&:hover': {
          outline: `2px solid ${theme.palette.presentation.sectionBorder.hover}`,
          zIndex: 1,
          '& $iconMenuRoot': {
            display: 'flex'
          }
        }
      },
      focused: {
        outline: `2px solid ${theme.palette.presentation.sectionBorder.focus}`,
        zIndex: 1
      },
      logoWrapper: (props) => ({
        width: '100%',
        display: 'flex',
        justifyContent: props.logo?.horizontalAlign || 'center'
      }),
      logo: (props) => ({
        height: 'auto',
        width: 'auto',
        maxHeight: 140,
        maxWidth: 140,
        ...(props.logo?.size === 'xs' && {
          maxHeight: 80,
          maxWidth: 80
        }),
        ...(props.logo?.size === 'sm' && {
          maxHeight: 110,
          maxWidth: 110
        }),
        ...(props.logo?.size === 'md' && {
          maxHeight: 140,
          maxWidth: 140
        }),
        ...(props.logo?.size === 'lg' && {
          maxHeight: 170,
          maxWidth: 170
        }),
        ...(props.logo?.size === 'xl' && {
          maxHeight: 200,
          maxWidth: 200
        })
      }),
      iconMenuRoot: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.borderRadius.md,
        bottom: theme.spacing(2),
        boxShadow: theme.boxShadow[1],
        display: 'none',
        left: theme.spacing(2),
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        zIndex: 3
      },
      iconMenuRootFocused: {
        display: 'flex'
      }
    }
  },
  { name: 'InlineEditableLogo' }
)

export default function InlineEditableLogo(props: InlineEditableLogoProps) {
  const classes = useStyles(props)
  const { logoOptionsProps, isEditable, handleEdit, isSettings } = props
  const [logo, setLogo] = useState(props.logo)

  // update logo in case of prop.logo change (e.g., logo to logo swap from ThreeColumnOptions)
  useEffect(() => {
    setLogo(props.logo)
  }, [props.logo])

  const { sectionPrefix, fieldSuffix } = logoOptionsProps || {}
  const [isFocused, setIsFocused] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isEditable) {
      setIsFocused(true)
    }
    if (!isEditable) {
      // remove focus if parent section loses focus
      // instead of listening for a blur on the editor, we listen for a click outside of the section
      handleConfirm()
      setIsFocused(false)
      setMenuAnchorEl(null)
    }
  }, [isEditable])

  const handleConfirm = (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (ev) ev.stopPropagation()
    handleEdit(logo)
  }

  const handleOpenOptions = (ev) => {
    if (!isFocused) setIsFocused(true)
    setMenuAnchorEl(wrapperRef.current)
  }

  // not sure why react isn't rerendering when the logo styles change without this key setting hack but here we are
  const { src, ...logoStyles } = logo

  if (!isSettings)
    return (
      <div className={classes.logoWrapper}>
        <img className={classes.logo} src={src} />
      </div>
    )
  else
    return (
      <ClickAwayListener
        onClickAway={() => {
          if (isFocused) {
            setIsFocused(false)
            setMenuAnchorEl(null)
          }
        }}
      >
        <div
          className={classnames(classes.root, {
            [classes.hovered]: !isFocused,
            [classes.focused]: isFocused
          })}
          onClick={() => {
            if (!isFocused) {
              setIsFocused(true)
            }
          }}
          ref={wrapperRef}
        >
          <div
            className={classnames(classes.iconMenuRoot, {
              [classes.iconMenuRootFocused]: isFocused && !menuAnchorEl
            })}
          >
            <PresEditorTooltip title={'Logo Options'}>
              <PopupMenuItem icon={EditOutlineIcon} iconOnly onClick={(ev) => handleOpenOptions(ev)} />
            </PresEditorTooltip>
          </div>
          {menuAnchorEl && (
            <SectionOptionsPopup
              menuAnchorEl={menuAnchorEl}
              onClose={() => setMenuAnchorEl(null)}
              open
              logoOptionsProps={{
                logo,
                sectionPrefix: sectionPrefix,
                fieldSuffix: fieldSuffix
              }}
            />
          )}
          <div key={JSON.stringify(logoStyles)} className={classes.logoWrapper}>
            <img className={classes.logo} src={src} />
          </div>
        </div>
      </ClickAwayListener>
    )
}
