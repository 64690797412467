import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { User } from '@paintscout/api'
import type { TileProps } from '../'
import { Tile, Typography } from '../'
import React from 'react'

export interface UserTileProps extends WithStyles<typeof styles>, Omit<TileProps, 'classes'> {
  user: User
}

const styles = () =>
  createStyles({
    root: {}
  })

function UserTile({ user, ...props }: UserTileProps) {
  return (
    <Tile {...props}>
      <div>{user.name}</div>
      <Typography variant="subtitle1">{getRoleName(user)}</Typography>
    </Tile>
  )
}

export function getRoleName(user: User) {
  if ((user?.app_metadata?.roles ?? []).indexOf('admin') > -1) {
    return 'Admin'
  }
  if ((user?.app_metadata?.roles ?? []).indexOf('sales') > -1) {
    return 'Sales'
  }
  if ((user?.app_metadata?.roles ?? []).indexOf('crew') > -1) {
    return 'Crew'
  }
  if ((user?.app_metadata?.roles ?? []).indexOf('viewer') > -1) {
    return 'Viewer'
  }
}

export default withStyles(styles)(UserTile)
