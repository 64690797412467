import * as React from 'react'
import type { OptionsDocument, RatesDocument } from 'paintscout'

export interface ClientOptionsValue {
  options?: OptionsDocument
  rates?: RatesDocument

  isDirty: boolean

  save: (args?: {
    options: OptionsDocument
    rates?: RatesDocument
  }) => Promise<{ options: OptionsDocument; rates?: RatesDocument }>

  savePartialOptions: (args: { input: string; key: string }) => Promise<{ output: string }>

  reset: () => any
  /**
   * @param args.isDirty defaults to true
   */
  updateOptions: (args: { options: OptionsDocument; isDirty?: boolean }) => void

  /**
   * @param args.isDirty defaults to true
   */
  updateRates: (args: { options: OptionsDocument; rates: RatesDocument; isDirty?: boolean }) => void
}

export default React.createContext<ClientOptionsValue>({
  isDirty: false,
  save: (() => {}) as any,
  savePartialOptions: (() => {}) as any,
  reset: () => {},
  updateOptions: () => {},
  updateRates: () => {}
})
