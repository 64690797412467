import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { getPresentationContentStyles, Grid, Typography } from '@ui/stickybid'
import { QuoteIcon } from '@ui/core/icons'
import type { LabelsOption } from '@paintscout/util/builder'

const useStyles = makeStyles<Theme, QuotePlaceholderProps>((theme) => ({
  root: {
    '&$root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 125,
      backgroundColor: theme.palette.grey[100],
      ...getPresentationContentStyles(theme, { noMargins: ['li'] })
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center'
  }
}))

export interface QuotePlaceholderProps {
  classes?: StyleClasses<typeof useStyles>
  objectLabels: LabelsOption
}

function QuotePlaceholder(props: QuotePlaceholderProps) {
  const classes = useStyles(props)
  const { objectLabels } = props

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container spacing={1}>
        <Grid item xs={8}>
          <QuoteIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography variant={'h2'}>{`Custom ${objectLabels.quote.value}`}</Typography>
        </Grid>
        <Grid className={classes.container} item xs={5}>
          <Typography variant={'subtitle1'}>{`${objectLabels.quote.plural} are inserted automatically.`}</Typography>
          <Typography variant={'subtitle1'}>
            {`When viewing or sending, your ${objectLabels.quote.value.toLowerCase()} will be inserted wherever this placeholder is set in the presentation.`}
          </Typography>
        </Grid>
        <Grid className={classes.container} item xs={6}></Grid>
      </Grid>
    </div>
  )
}

export default QuotePlaceholder
