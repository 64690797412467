import type { OptionsDocument, QuoteDocument, QuoteSubstrate } from 'paintscout'
import { buildSubstrateOrder } from './build-substrate-order'

import uniq from 'lodash/uniq'
import { getAreaSubstrateKey } from '../../builder/quote/area-substrates/get-area-substrate-key'
import { updateSubstrateDescriptions } from '../../builder/quote/substrates/update-substrate-descriptions'

export function buildSubstrates(initialQuote: QuoteDocument, options: OptionsDocument): QuoteDocument {
  const quote = {
    ...initialQuote
  }

  let substrates = {
    ...quote.substrates
  }

  const defaultClientLabels: any = {}

  quote.order.area
    .filter((orderItem) => {
      if (orderItem._deleted) {
        return false
      }
      if (orderItem.type !== 'area') {
        return false
      }
      if (!quote.areas[orderItem.key]) {
        return false
      }

      return true
    })
    .forEach((orderItem) => {
      const areaKey = orderItem.key
      const area = quote.areas[areaKey]

      if (!area.substrates) {
        return
      }

      Object.keys(area.substrates).forEach((section) => {
        area.substrates[section].forEach((areaSubstrate) => {
          if (areaSubstrate._deleted) {
            return
          }

          const rateLabel = areaSubstrate.rate.label

          const rateKey = getAreaSubstrateKey(quote, areaSubstrate)

          const substrateName = areaSubstrate.rate.name
          const rate = areaSubstrate.rate

          let substrate: QuoteSubstrate
          let checkboxes

          if (!substrates[rateKey]) {
            substrate = {
              name: substrateName,
              section: areaSubstrate.rate.section,
              label: rateLabel,
              client_label: areaSubstrate.rate.client_label ? areaSubstrate.rate.client_label : '',
              totals: {
                hours: 0,
                price: 0,
                prep: 0
              },
              area_string: {
                use_custom: false
              },
              product_string: {
                use_custom: false
              },
              show_price: true
            }

            if (!defaultClientLabels[rateKey]) {
              defaultClientLabels[rateKey] = []
            }
            defaultClientLabels[rateKey].push(
              areaSubstrate.rate.client_label ? areaSubstrate.rate.client_label : areaSubstrate.rate.label
            )

            if (rate) {
              substrate.report_text = {
                value: rate.report_text,
                custom: '',
                use_custom: false
              }
            }

            // If not created yet, base off areaSubstrate location
            // (not really needs as we dont want to track sections here, but leaving for meantime)
            checkboxes = {
              show: areaSubstrate.show !== false,
              use_total: areaSubstrate.use_total !== false,
              show_price: !!areaSubstrate.show_price
            }
          } else {
            substrate = { ...substrates[rateKey] }

            substrate.name = substrateName

            if (rate) {
              substrate.report_text = {
                value: rate.report_text,
                custom: '',
                use_custom: false
              }
            }

            if (!substrate.section) {
              substrate.section = rate.section
            }

            if (!substrate.area_string) {
              substrate.area_string = {
                custom: '',
                use_custom: false
              }
            }

            if (!substrate.product_string) {
              substrate.product_string = {
                value: 'Product String',
                custom: '',
                use_custom: false
              }
            }

            substrate.label = rateLabel

            if (!substrate.client_label) {
              substrate.client_label = rate.client_label ? rate.client_label : ''
            }

            if (!defaultClientLabels[rateKey]) {
              defaultClientLabels[rateKey] = []
            }
            defaultClientLabels[rateKey].push(
              areaSubstrate.rate.client_label ? areaSubstrate.rate.client_label : areaSubstrate.rate.label
            )

            checkboxes = {
              show: typeof substrate.show !== 'undefined' && substrate.show !== null ? substrate.show : true,
              use_total:
                typeof substrate.use_total !== 'undefined' && substrate.use_total !== null ? substrate.use_total : true,
              show_price:
                typeof substrate.show_price !== 'undefined' && substrate.show_price !== null
                  ? substrate.show_price
                  : false
            }
          }

          substrates = {
            ...substrates,
            [rateKey]: {
              ...substrate,
              ...checkboxes
            }
          }
        })
      })
    })

  Object.keys(defaultClientLabels).forEach((key) => {
    const uniqueLabels = uniq(defaultClientLabels[key])
    substrates[key].clientLabel = {
      useCustom: false,
      custom: '',
      ...substrates[key].clientLabel,
      default: uniqueLabels.join(', ')
    }
  })

  const substrateOrder = buildSubstrateOrder({ ...quote, substrates })

  const updatedQuote = {
    ...quote,
    order: {
      ...quote.order,
      substrate: substrateOrder
    },
    substrates: { ...substrates }
  }

  // Update descriptions of all quote.substrates
  updatedQuote.substrates = updateSubstrateDescriptions({
    quote: updatedQuote,
    options
  })

  return updatedQuote
}
