import get from 'lodash/get'
import type { UpdateableAreaSubstrate, RenderableItemSubstrate } from '../../../area-substrates'

export interface ItemDescriptionDetails {
  prep: number
  hours: number
  price: number
  value: number
  totalPrice: number
  materialsValue: number
  clientLabel: string
  itemName: string
  rateClientLabel: string
  rateItemLabel: string
  rateItemName: string
}

/**
 * @description Get price, name and label details from an item to be formatted into description
 *
 * @returns ItemDescriptionDetails
 */
export const getItemDetails = ({
  item
}: {
  item: UpdateableAreaSubstrate | RenderableItemSubstrate
}): ItemDescriptionDetails => {
  let prep = Number(item.prep)
  if (!prep) {
    prep = 0
  }
  let hours = Number(item.hours?.useCustom ? item.hours.custom : item.hours.default)
  if (!hours) {
    hours = 0
  }
  let price = Number(item.price.useCustom ? item.price.custom : item.price.default)
  if (!price) {
    price = 0
  }
  let materialsValue = Number(item?.materials?.useCustom ? item?.materials?.custom : item?.materials?.default)
  if (!materialsValue) {
    materialsValue = 0
  }

  const value = hours + prep

  const rateClientLabel: string = get(item, 'rate.clientLabel', '')
  const rateItemLabel: string = get(item, 'rate.label', '')
  const rateItemName: string = get(item, 'rate.name', '')

  const clientLabel: string = get(item, 'clientLabel', '')
  const itemName: string = get(item, 'name', '')

  const totalPrice = price + materialsValue

  return {
    prep,
    hours,
    price,
    value,
    totalPrice,
    materialsValue,
    clientLabel,
    itemName,
    rateClientLabel,
    rateItemLabel,
    rateItemName
  }
}
