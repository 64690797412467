import { Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import type { FormikInputFieldProps } from '@ui/stickybid'
import {
  Alert,
  Button,
  FormikDropdownSelect,
  FormikInputField,
  FormSection,
  FormSectionTitle,
  IconButton,
  InputLabel,
  KeypadInputField,
  LearnMoreLink,
  PageHelpMessage,
  SwitchField
} from '@ui/stickybid'

import { getFeatures, getObjectLabels, uuid } from '@paintscout/util/builder'
import { FastField, Field, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useMemo } from 'react'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  root: {},
  taxSection: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: '0.4fr 0.6fr',
    marginBottom: theme.spacing()
  },
  removeTax: {
    '& svg': {
      color: 'red'
    }
  },
  defaultSubtitle: {
    display: 'grid',
    textAlign: 'center',
    paddingRight: '27px',
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      marginLeft: theme.spacing(1.5)
    }
  },
  defaultCheckbox: {},
  discountSection: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: '0.3fr 0.1fr 0.6fr',
    marginBottom: theme.spacing(),
    alignItems: 'end'
  },
  discountCheckboxes: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: '1fr'
  },
  fileSettings: {
    display: 'grid',
    gridGap: `${theme.spacing()}px ${theme.spacing(5)}px`,
    gridTemplateColumns: '0.8fr',
    marginTop: theme.spacing()
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(33)
  },
  discountSelection: {
    alignSelf: 'flex-end'
  },
  pdfSubtext: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular
  },
  acceptanceSection: {
    display: 'grid',
    gridGap: theme.spacing(2)
  },
  switchDiv: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(-1),
      marginTop: theme.spacing(-1)
    }
  },
  secondarySubRow: {
    '&$secondarySubRow': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  hr: {
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  }
}))

export interface QuoteOptionsProps {
  showSuperAdminSettings?: boolean
  hideNonSuperAdminSettings?: boolean
}

function QuoteOptions({ showSuperAdminSettings, hideNonSuperAdminSettings }: QuoteOptionsProps) {
  const classes = useStyles({})
  const { values, handleChange } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })

  const features = getFeatures({ options })

  const discountOptions = [
    { value: 'percentage', label: '%' },
    { value: 'value', label: '$' }
  ]

  const initialSequenceValues = useMemo(
    () => ({
      quote: values.options.options.quotes?.sequence?.startAt?.quote ?? 0,
      invoice: values.options.options.quotes?.sequence?.startAt?.invoice ?? 0
    }),
    // eslint-disable-next-line
    [values.options._rev]
  )

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path={'settings-quote-settings'}
        openContent={
          <>
            <FormSectionTitle title={`${objectLabels.quote.value} Settings`} variant="h2" />
            <Typography>
              Customize and adjust the skeleton of your quote. This will include customizing your hourly rate, hours
              rounding, and more!
              <LearnMoreLink link="https://www.stickybid.com/videos/#settings-estimates" />
            </Typography>
          </>
        }
        closedContent={
          <>
            <FormSectionTitle title={`${objectLabels.quote.value} Settings`} variant="h2" />
            <Alert
              content={`Changes to settings on this page will not affect or re-calculate existing ${objectLabels.quote.plural.toLowerCase()}.`}
              severity="warning"
              variant={'outlined'}
            />
          </>
        }
      />
      {!hideNonSuperAdminSettings && (
        <>
          <FormSection>
            <FormSectionTitle
              title={'Tax Rates'}
              subTitle={`Default tax rates and descriptions for ${objectLabels.quote.plural.toLowerCase()}.`}
              variant={'h3'}
            />
            <TaxOptions />
          </FormSection>
          <FormSection>
            <FormSectionTitle
              title={'Discounts'}
              subTitle={`Set a default discount rate and description.`}
              variant={'h3'}
            />
            <Grid container spacing={3}>
              <Grid item xs={8} sm={3}>
                <KeypadInputField
                  allowDecimal
                  allowNegative={false}
                  fullWidth
                  label={`Default Discount Rate,  ${
                    values.options.options?.discountType?.value === 'percentage' ? '%' : '$'
                  }`}
                  name="options.options.discountRate.value"
                  onChange={handleChange}
                  placeholder={'0'}
                  value={values.options.options.discountRate.value || 0}
                />
              </Grid>
              <Grid item xs={4} sm={3} className={classes.discountSelection}>
                <Field
                  data-testid="discountType"
                  inputId="discountType"
                  component={FormikDropdownSelect}
                  options={discountOptions}
                  variant="outlined"
                  name="options.options.discountType.value"
                  fullWidth
                  searchable={false}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={7}>
                <FastField
                  component={FormikInputField}
                  name="options.options.discountDescription.value"
                  label={'Default Discount Description'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SwitchField
                  name="options.options.discountRate.allow_custom"
                  label={`Allow Discount to be customized on individual ${objectLabels.quote.plural.toLowerCase()}.`}
                />
              </Grid>
            </Grid>
          </FormSection>
          {/* <FormSection>
            <FormSectionTitle
              title={`Imported ${objectLabels.quote.value} Settings`}
              subTitle={`Configure how imported ${objectLabels.quote.plural.toLowerCase()} are handled.`}
              variant={'h3'}
            />
            <Grid container spacing={3}>
              <Grid item xs={4} sm={3}>
                <FastField
                  label="Total Style"
                  sublabel={`Choose whether to use the total or the subtotal + tax.`}
                  name="options.options.quotes.importedQuoteOptions.totalStyle"
                  margin="dense"
                  component={FormikDropdownSelect}
                  options={[
                    { label: 'Total', value: 'total' },
                    { label: 'Subtotal + Tax', value: 'subtotal' }
                  ]}
                />
              </Grid>
            </Grid>
          </FormSection> */}
        </>
      )}

      {showSuperAdminSettings && (
        <FormSection>
          <FormSectionTitle
            title={`${objectLabels.quote.value} IDs`}
            subTitle={`Adjust where new ${objectLabels.quote.value} IDs begin`}
            variant={'h3'}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={SequenceInputField}
                defaultValue={initialSequenceValues.quote}
                min={initialSequenceValues.quote}
                max={1000000}
                type="number"
                name="options.options.quotes.sequence.startAt.quote"
                label={`Start ${objectLabels.quote.value} IDs at`}
                fullWidth
              />
            </Grid>
            {features?.invoices?.enabled && (
              <Grid item xs={12}>
                <Field
                  component={FormikInputField}
                  type="number"
                  defaultValue={initialSequenceValues.invoice}
                  inputProps={{}}
                  min={initialSequenceValues.invoice}
                  max={1000000}
                  name="options.options.quotes.sequence.startAt.invoice"
                  label={`Start Invoice IDs at`}
                  fullWidth
                />
                <InputLabel shrink={true} variant="sublabel">
                  * Due to the possibility of duplicates you may only increase these numbers. If you need to decrease
                  them please contact support
                </InputLabel>
              </Grid>
            )}
          </Grid>
        </FormSection>
      )}
    </div>
  )
}

function TaxOptions() {
  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })

  return (
    <>
      {(values.options.options.taxRates ?? []).map((taxRate, taxRateIndex) => {
        return (
          <TaxRateRow
            rateName={`options.options.taxRates[${taxRateIndex}].rate`}
            descriptionName={`options.options.taxRates[${taxRateIndex}].description`}
            index={taxRateIndex}
            key={taxRateIndex}
            isDefault={options.options.taxRates[taxRateIndex]?.isDefault}
            isPrimary={taxRateIndex === 0}
          />
        )
      })}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <Button
            prominence={2}
            name="addTaxRate"
            onClick={() => {
              setFieldValue('options.options.taxRates', [
                ...(options.options.taxRates ?? []),
                { key: uuid(), rate: 0, description: '', isDefault: false }
              ])
            }}
          >
            + Add Another Tax Rate
          </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          <SwitchField
            name="options.options.taxRate.allow_custom"
            label={`Allow Tax Rates to be customized on individual ${objectLabels.quote.plural.toLowerCase()}.`}
          />
        </Grid>
      </Grid>
    </>
  )
}

function TaxRateRow({
  rateName,
  descriptionName,
  isDefault,
  isPrimary,
  index
}: {
  rateName: string
  descriptionName: string
  isDefault?: boolean
  isPrimary?: boolean
  index?: number
}) {
  const { values, errors, setFieldValue, handleChange } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const classes = useStyles({})

  return (
    <Grid container spacing={3} alignItems={'flex-end'}>
      <Grid item xs={12} sm={2}>
        <KeypadInputField
          allowDecimal
          allowNegative={false}
          decimalScale={4}
          fixedDecimalScale={false}
          fullWidth
          label={'Tax Rate, %'}
          name={rateName}
          onChange={handleChange}
          placeholder={'Tax Rate'}
          value={get(values, rateName) || 0}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FastField
          component={FormikInputField}
          name={descriptionName}
          type="text"
          label={'Tax Description'}
          fullWidth
        />
      </Grid>
      {isPrimary && (
        <>
          <Grid container xs={12} sm={3} className={classes.switchDiv}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.defaultSubtitle} variant="subtitle1">
                Default
              </Typography>
            </Grid>
          </Grid>
          <hr className={classes.hr} />
        </>
      )}
      {!isPrimary && (
        <>
          <Grid item xs={12} sm={5} className={classes.secondarySubRow}>
            <Grid container xs={12} className={classes.switchDiv}>
              <Grid item xs>
                <SwitchField
                  className={classes.defaultCheckbox}
                  name={`options.options.taxRates[${index}].isDefault`}
                  sublabel={'Default'}
                  checked={isDefault}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <IconButton
                  name={`removeTax-${index}`}
                  className={classes.removeTax}
                  onClick={() => {
                    const updatedTaxRates = [...options.options.taxRates]
                    updatedTaxRates.splice(index, 1)
                    setFieldValue('options.options.taxRates', updatedTaxRates)
                  }}
                  edge={'end'}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <hr className={classes.hr} />
        </>
      )}
    </Grid>
  )
}

/**
 * Input used for sequences to enforce the min/max range on the inputs
 */
const SequenceInputField: React.FC<FormikInputFieldProps & { min: number; max: number }> = ({ min, max, ...props }) => {
  const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    // clamp value on blur
    const sanitized = Math.min(Math.max(parseInt(ev.currentTarget.value, 10), min), max)

    props.form.setFieldValue(props.field.name, sanitized)

    if (props.field.onBlur) {
      props.field.onBlur(ev)
    }
  }
  return <FormikInputField {...props} onBlur={onBlur} inputProps={{ ...(props.inputProps ?? {}), min, max }} />
}

export default QuoteOptions
