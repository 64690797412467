/**
 * @module builder
 */
import { getQuoteOptions } from '../../quote'
import type { OptionsDocument } from 'paintscout'
import numeral from 'numeral'
const isPs = process.env.PRODUCT === 'ps'

export function formatCurrency(args?: {
  options?: OptionsDocument
  value: string | number
  abbreviate?: boolean
  separateThousands?: boolean
}) {
  const { value, options, abbreviate, separateThousands: _separateThousands } = args

  const separateThousands = _separateThousands ?? getQuoteOptions({ options }).separateThousands

  let format: string
  if (abbreviate) format = parseFloat(value as string) > 10000 ? '$0.00a' : separateThousands ? '$0,0.00' : '$0.00'
  if (!abbreviate) format = separateThousands ? '$0,0.00' : '$0.00'

  if (isPs) return numeral(value).format(format)
  else return numeral(value).format(format).toUpperCase()
}
