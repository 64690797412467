import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields

  const hasLeftContent =
    (fields?.leftText && fields.leftText.replace('<p></p>', '').trim() && fields?.leftType === 'text') ||
    (fields?.leftType === 'image' && fields?.leftImage) ||
    fields?.leftType === 'logo'
  const hasCenterContent =
    (fields?.centerText && fields.centerText.replace('<p></p>', '').trim() && fields?.centerType === 'text') ||
    (fields?.centerType === 'image' && fields?.centerImage) ||
    fields?.centerType === 'logo'
  const hasRightContent =
    (fields?.rightText && fields.rightText.replace('<p></p>', '').trim() && fields?.rightType === 'text') ||
    (fields?.rightType === 'image' && fields?.rightImage) ||
    fields?.rightType === 'logo'

  if (!hasLeftContent && !hasCenterContent && !hasRightContent && !fields?.title) {
    return true
  }

  return false
}
