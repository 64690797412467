import React, { useEffect, useState } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { Typography } from '..'

import StickyBidIcon from '../../../core/src/icons/StickyBidIcon'

export type CVLogoProps = {
  scale?: number
  customLogoSrc?: string
  fallback?: React.ReactNode
  label?: string
}

const useStyles = makeStyles({
  root: {
    opacity: 0,
    animation: '$fadeIn 1s linear 0.2s 1 forwards'
  },
  icon: {
    fill: '#000',
    width: '100%',
    height: '100%',
    // animation: '$pulse 4000ms ease infinite',
    maxWidth: 200,
    maxHeight: 100,
    objectFit: 'contain'
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },

  '@keyframes pulse': {
    '0%': {
      opacity: 0.7,
      transform: 'scale(1.0)'
    },
    '80%': {
      opacity: 1,
      transform: 'scale(1.2)'
    },
    '100%': {
      opacity: 0.7,
      transform: 'scale(1.0)'
    }
  }
})

/** Renders the logo for CustomerView loading. Takes a customLogoSrc with an option to customize the fallback. Defaults to the StickyBid logo */
function CVLogo({ scale = 1, customLogoSrc, fallback, label, ...props }: CVLogoProps) {
  const [useFallback, setUseFallback] = useState(true)
  const classes = useStyles(props)

  useEffect(() => {
    try {
      const testImg = new window.Image()
      testImg.onload = () => {
        setUseFallback(false)
      }
      testImg.src = customLogoSrc
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div className={classes.root} style={{ position: 'relative' }} {...props}>
      {customLogoSrc ? (
        useFallback ? (
          fallback ?? <CircularProgress size={50} />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <img src={customLogoSrc} className={classes.icon} />
            {label && (
              <Typography style={{ marginTop: 16 }} variant={'h3'}>
                {label}
              </Typography>
            )}
          </div>
        )
      ) : (
        <StickyBidIcon className={classes.icon} />
      )}
    </div>
  )
}
export default CVLogo
