import type { QuoteDocument, QuoteArea, OrderItem, QuoteOptions, OptionsDocument, AreaSubstrate } from 'paintscout'
import type { RenderableSubItem } from '../..'
import type { QuoteItemSection, QuoteItemConsumer, QuoteItemView } from '../../..'
import { getAreaSubstrate, getAreaSubstrateKey, isInSection } from '../../../area-substrates'
import { getArea } from '../../../areas'
import { getItemSection } from '../../../util'
import { formatSubstrateArea } from './format-substrate-areas'

// Filter and format substrate order item
export const filterSubstrateAreas = ({
  quote,
  options,
  area,
  clientOptions,
  substrateOrderItem,
  key,
  substrateKey,
  section,
  showValues,
  consumer,
  view
}: {
  quote: QuoteDocument
  area: QuoteArea
  substrateOrderItem: OrderItem
  key: string
  substrateKey: string
  options: QuoteOptions
  clientOptions: OptionsDocument
  section: QuoteItemSection
  showValues: boolean
  consumer?: QuoteItemConsumer
  view?: QuoteItemView
}): RenderableSubItem => {
  const areaSection = getItemSection(area)
  const areaItem = getArea({ quote, options: clientOptions, key })
  const areaSubstrate = getAreaSubstrate({
    quote,
    options: clientOptions,
    areaKey: key,
    key: substrateOrderItem.key,
    full: true
  }) as AreaSubstrate

  if (!areaSubstrate) {
    return
  }

  const areaSubstrateKey = getAreaSubstrateKey(quote, areaSubstrate as any)
  const areaSubstrateSection = getItemSection(areaSubstrate)

  // Filter to make sure we want to include areaSubstrate in
  // this substrate area
  if (!isInSection({ areaSubstrateSection, areaSection, section })) {
    return null
  }

  if (areaSubstrateKey !== substrateKey) {
    return
  }

  // Format/clean areaSubstrate based on consumer/view we are returning to
  const substrateArea = formatSubstrateArea({
    areaSubstrate,
    quote,
    areaItem,
    options,
    area,
    clientOptions,
    substrateOrderItem,
    key,
    section,
    showValues,
    consumer,
    view
  })

  // Add substrateArea if valid
  if (substrateArea) {
    return substrateArea
  } else {
    return null
  }
}
