import React from 'react'
import { createStyles, FormControl, Grid, withStyles } from '@material-ui/core'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { OnboardingPopper, useOnboardingContext } from '../../OnboardingContext'
import { FastField, Form, useFormikContext, Field } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import { FormikInputField, FormikPhoneNumberInput, FormikDropdownSelect } from '../../formik'
import FormLabel from '../../FormLabel'
import FormSection from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import type { SingleSelectOption, DropdownSelectOption } from '../../DropdownSelect'
import { industryOptions } from '@paintscout/util/database/fixtures/sb/presentations/industryOptions'
import { getObjectLabels } from '@paintscout/util/builder'
import UploadCompanyLogo from './UploadCompanyLogo'
import DateRadio from './DateFormat'
import TimezoneSelect from './TimezoneSelect'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    colorPicker: {
      textAlign: 'center'
    }
  })

export interface GeneralFormProps extends WithStyles<typeof styles> {
  /**
   * Called when the color has changed. Use this if you want to show a preview of the theme
   * to the user
   */
  onColorChange?: (colors: { primary: string; secondary: string }) => any
  showAdvanced?: boolean
}

/**
 * A form for the General section of client options
 */
function GeneralForm({ classes }: GeneralFormProps) {
  const { values, errors, handleSubmit } = useFormikContext<{ options: OptionsDocument; rates: RatesDocument }>()

  const objectLabels = getObjectLabels({ options: values.options })
  const quoteLabelList: DropdownSelectOption[] = [
    {
      value: 'Quote',
      label: 'Quote'
    },
    {
      value: 'Estimate',
      label: 'Estimate'
    },
    {
      value: 'Proposal',
      label: 'Proposal'
    },

    {
      value: 'Bid',
      label: 'Bid'
    }
  ]

  // const orientationOptions: DropdownSelectOption[] = [
  //   { value: 'auto', label: 'Auto (Choose best for logo size)' },
  //   { value: 'default', label: 'Default (Side-by-Side)' },
  //   { value: 'stack', label: 'Stack (Put logo above company info)' }
  // ]

  const { activeTask } = useOnboardingContext()
  const isActiveTask = activeTask?.key === 'upload-logo' && !activeTask.completed
  const highlightUpload = isActiveTask && !values.options.options.logo.value

  // const uploadLogoNode = document.getElementById('uploadLogo')

  // useEffect(() => {
  //   if (isActiveTask) {
  //     window.scrollTo({ top: uploadLogoNode?.getBoundingClientRect().top - 80, behavior: 'smooth' })
  //   }
  // }, [uploadLogoNode])

  return (
    <Form className={classes.root}>
      <FormSection hideDivider>
        <FormSectionTitle
          title={'Company Information'}
          variant={'h2'}
          subTitle={`This information will be included on ${objectLabels.quote.plural.toLowerCase()} sent to customers`}
          showDivider
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={9}>
            <FastField
              label="Name"
              name="options.options.companyName.value"
              margin="dense"
              component={FormikInputField}
              required
              fullWidth
              error={!!errors.options && errors.options.options.companyName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={9}>
            <FastField
              label="Address"
              name="options.options.companyAddress.value"
              margin="dense"
              component={FormikInputField}
              error={!!errors.options && errors.options.options.companyAddress}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <FastField
              label="City"
              name="options.options.companyCity.value"
              margin="dense"
              component={FormikInputField}
              error={!!errors.options && errors.options.options.companyCity}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FastField
              label="State / Prov"
              name="options.options.companyProv.value"
              margin="dense"
              error={!!errors.options && errors.options.options.companyProv}
              component={FormikInputField}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FastField
              label="Zip / Postal"
              name="options.options.companyPostal.value"
              margin="dense"
              component={FormikInputField}
              error={!!errors.options && errors.options.options.companyPostal}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <FastField
              label="Country"
              name="options.options.companyCountry.value"
              margin="dense"
              component={FormikDropdownSelect}
              options={[
                { label: 'United States', value: 'United States' },
                { label: 'Canada', value: 'Canada' },
                { label: 'Australia', value: 'Australia' }
              ]}
              error={!!errors.options && errors.options.options.companyCountry}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FastField
              label="Phone"
              name="options.options.companyPhone.value"
              error={!!errors.options && errors.options.options.companyPhone}
              margin="dense"
              separator={values.options?.options?.phoneNumbers?.separator}
              component={FormikPhoneNumberInput}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection hideDivider>
        <div id={'uploadLogo'}>
          <FormSectionTitle
            title={'Logo'}
            variant={'h2'}
            subTitle={`Add your company logo to be displayed on your ${objectLabels.quote.plural.toLowerCase()}`}
            showDivider
          />
        </div>
        <Grid container spacing={3}>
          <OnboardingPopper open={highlightUpload} title={'Upload Your Logo!'}>
            <Grid item xs={12} md={6}>
              <FastField name="options.options.logo">
                {({ field }) => (
                  <div style={values.options.options.logo.value ? { width: 200 } : null}>
                    <UploadCompanyLogo
                      publicId={field.value.cloudinaryId}
                      src={field.value.src}
                      onChange={(value) => {
                        field.onChange({ target: { name: field.name, value } })
                        if (value?.value) handleSubmit()
                      }}
                    />
                  </div>
                )}
              </FastField>
            </Grid>
          </OnboardingPopper>
          {/* <Grid item xs={12} md={6}>
            <FastField
              label={`Logo Orientation`}
              name={'options.options.logo.orientation'}
              margin="dense"
              options={orientationOptions}
              required
              fullWidth
              searchable={false}
              creatable={false}
              component={FormikDropdownSelect}
            />
          </Grid> */}
        </Grid>
      </FormSection>
      <FormSection hideDivider>
        <FormSectionTitle
          title={'Industry'}
          variant={'h2'}
          subTitle={`Select your company industry to customize your StickyBid experience`}
          showDivider
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FastField
              label="Industry"
              name="options.options.companyIndustry.value"
              margin="dense"
              component={FormikDropdownSelect}
              options={industryOptions}
              error={!!errors.options && errors.options.options.companyIndustry}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection hideDivider>
        <FormSectionTitle
          title={'Display'}
          variant={'h2'}
          subTitle={`Change how ${objectLabels.quote.plural.toLowerCase()} are displayed to customers`}
          showDivider
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FastField
              label={`Quote Name/Label`}
              name={'options.options.labels.quote'}
              margin="dense"
              options={quoteLabelList}
              required
              fullWidth
              searchable={false}
              creatable={false}
              component={FormikDropdownSelect}
            />
          </Grid>

          <Grid item xs={12} md={6} container>
            <Grid item xs={12}>
              <Field name="options.options.timezone" required>
                {({ field, form, ...rest }) => (
                  <TimezoneSelect
                    {...field}
                    {...rest}
                    label="Timezone"
                    margin="dense"
                    onChange={(option: SingleSelectOption) => {
                      field.onChange({ target: { name: field.name, value: option.value } })
                    }}
                  />
                )}
              </Field>
            </Grid>
            {/* <Grid item xs={12}>
              <FastField
                label="Phone Number Separator"
                margin="dense"
                fullWidth
                options={[
                  { label: 'Dot (222.333.4444)', value: '.' },
                  { label: 'Dash (222-333-4444)', value: '-' }
                ]}
                searchable={false}
                creatable={false}
                component={FormikDropdownSelect}
                name={'options.options.phoneNumbers.separator'}
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component={'fieldset' as any} margin="dense">
              <FormLabel>Date format</FormLabel>
              <Field name="options.options.dateFormat.momentValue">
                {({ field, form }) => <DateRadio {...field} />}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
      </FormSection>
    </Form>
  )
}
export default withStyles(styles)(GeneralForm)
