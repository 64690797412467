import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { FileDropArea, useClientOptions, useOnboardingContext } from '@ui/stickybid'
import {
  Button,
  Collapse,
  FormSection,
  getPresentationContentStyles,
  Grid,
  MenuItem,
  OnboardingPopper,
  PopupMenu,
  Typography
} from '@ui/stickybid'
import type { Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import type { RenderableItem, QuoteItemSection } from '@paintscout/util/builder'
import {
  getActivePayments,
  getDetails,
  getFeature,
  getItems,
  getObjectLabels,
  getQuoteOptions,
  setQuoteOptions
} from '@paintscout/util/builder'

import type { QuoteDocument } from 'paintscout'

import BulkActions from '../../BulkActions'

import QuoteHeader from '../../QuoteHeader'
import QuoteTerms from '../../QuoteTerms'
import QuoteSignCard from '../../QuoteHeader/QuoteSignCard'
import BidTable from './BidTable'
import OptionsTable from './OptionsTable'
import TotalTable from '../../../TotalTable'
import ImportedQuoteView from './ImportedQuoteView/ImportedQuoteView'
import GalleryFiles from '../../GalleryFiles'
import PaymentsTable from './PaymentsTable'
import { usePresentationNav } from '../../../presentation'
// import FilesByItem from '../../FilesByItem'
import classnames from 'classnames'
import { useQuote } from '../../../context/useQuote'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AcceptInvoiceOptionsButton } from '../../../presentation'

const useStyles = makeStyles<Theme, QuoteViewProps>(
  (theme) => ({
    root: {
      '& div': {
        // nested in here so it has more specificity and beats the same from Presentation
        ...getPresentationContentStyles(theme, { noMargins: ['li'] })
      }
    },
    paperPadding: {
      // passed in from Quote and edited here
    },
    paperPaddingTop: {
      // passed in from Quote and edited here
    },
    galleryImport: {
      // passed in from Quote and edited here
    },
    headerWrapper: {
      overflowWrap: 'anywhere'
    },
    spacer: {},
    row: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between'
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    },
    filesWrapper: {
      marginTop: theme.spacing(4)
    },
    importBottomSpacer: {
      height: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(3)
      }
    },
    defaultStyles: {
      '& div': {
        // nested in here so it has more specificity and beats the same from Presentation
        ...getPresentationContentStyles(theme, { noMargins: ['li'] })
      }
      // padding: theme.spacing(0, 6),
      // marginBottom: theme.spacing(4),
      // [theme.breakpoints.down('sm')]: {
      //   padding: theme.spacing(3),
      //   marginBottom: theme.spacing(2)
      // }
    },
    editButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    editButton: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    addItemButton: {
      maxWidth: 200,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'unset'
      }
    },
    acceptInvoiceOptionsRoot: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    termsSection: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate',
      '@media print': {
        pageBreakBefore: 'always'
      }
    },
    picturesSection: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate'
    },
    noteSection: {
      background: theme.palette.grey['100'],
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
      // whiteSpace: 'pre-wrap'
    },
    optionsTable: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate'
    },
    optionsTableRoot: {
      marginTop: theme.spacing(-2) // if present, we need options table to creep up into bid tables bottom margin
    },
    paymentsTable: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate'
    },
    totalsLeft: {
      '& button': {
        marginBottom: theme.spacing(3)
      }
    },
    inPresentation: {
      pointerEvents: 'none'
    },
    importPdfButton: {
      position: 'absolute',
      top: 0,
      borderRadius: theme.borderRadius.sm,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0
    },
    // this is kinda lame but it's better than having the button and a bunch of logic in Quote
    importPdfButtonShift: {
      top: -56,
      [theme.breakpoints.down('sm')]: {
        top: -32
      }
    },
    importPdfButtonSpacer: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1)
      }
    },
    divider: {
      marginTop: -30,
      textAlign: 'center',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('sm')]: {
        marginTop: -40
      }
    },
    dividerTop: {
      background: `linear-gradient(rgba(255,255,255,0) 0%, rgba(238,238,238,1) 100%)`,
      height: 60,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    dividerLine: {
      height: 0,
      borderTop: `1px solid ${theme.palette.secondary.main}`
    }
  }),
  { name: 'QuoteView' }
)

export interface QuoteViewProps {
  classes?: StyleClasses<typeof useStyles>
  inPresentation?: boolean
  isRevision?: boolean
  printOnly?: boolean
}

export interface QuoteViewState {
  // preventAutoSelectContact: boolean
  selectedItems: {
    bid: RenderableItem[]
    options: RenderableItem[]
    additional: RenderableItem[]
    pending: RenderableItem[]
    archived: RenderableItem[]
  }
}

function QuoteView(props: QuoteViewProps) {
  const classes = useStyles(props)
  const { inPresentation, isRevision, printOnly } = props
  const {
    canAddAdditionalWork,
    isEditable,
    onAddItem,
    onEditTerms,
    onImportQuoteImage,
    onItemAction,
    handleOptionalItems,
    quote,
    quoteImportUpload,
    quoteImportUploadLoading,
    tableView,
    updateQuote
  } = useQuote()
  const { onSelectOptionalItems, selectedOptionalItems: selectedOptionalItemKeys } = handleOptionalItems || {}
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const isInvoice = quote.is_invoice
  const { activeTask } = useOnboardingContext()
  const isActiveTask = activeTask?.key === 'create-quote_add-item' && !activeTask?.completed
  const canImportImage = getFeature({ options, path: 'importQuoteImage.enabled' })
  const hasOptionalItems = getFeature({ options, path: 'quotes.optionalItems' })
  const defaultToOptions = hasOptionalItems && isInvoice
  const { isPreview } = usePresentationNav()

  const optionalItems: RenderableItem[] = getItems({
    quote,
    section: 'options',
    view: tableView,
    consumer: 'customer',
    options,
    overrideOptions: { showPrices: true }
  })

  const [selectedItems, setSelectedItems] = useState<QuoteViewState['selectedItems']>({
    bid: [],
    options:
      selectedOptionalItemKeys?.map((key) => {
        return optionalItems.find((item) => item.key === key)
      }) || [],
    additional: [],
    pending: [],
    archived: []
  })

  const localSelectedOptionalItemKeys = selectedItems.options.map((item) => item.key)

  // local -> context
  useEffect(() => {
    if (onSelectOptionalItems) {
      onSelectOptionalItems(localSelectedOptionalItemKeys)
    }
  }, [selectedItems.options])

  // context -> local
  useEffect(() => {
    setSelectedItems({
      ...selectedItems,
      options:
        selectedOptionalItemKeys?.map((key) => {
          return optionalItems.find((item) => item.key === key)
        }) || []
    })
  }, [selectedOptionalItemKeys])

  const details = getDetails({ quote, options })
  const { showQuoteFiles, allowQuotePayments, showQuotePayments } = getQuoteOptions({
    quote,
    options
  })
  const canEdit = isEditable && !inPresentation
  const showGallery = (quote.galleryFiles?.length > 0 || !inPresentation) && !isInvoice

  const activePayments = getActivePayments({ quote })

  const showPaymentsTable =
    (!!quote.totals.after_tax || quote.totals.amount_paid !== 0) &&
    ((canEdit && (isInvoice || allowQuotePayments || activePayments.length)) || (!canEdit && activePayments.length > 0))

  const isImportedQuote = !!quote.importedContent?.primary?.src
  const status = quote.status.value
  const isDraft = status === 'draft'
  const showImportButton = !isInvoice && canImportImage && (isDraft || isImportedQuote) && !isRevision
  const isInvoiceFromImport = quote.fromPdfImport && isInvoice

  // imported quote/invoice
  if (isImportedQuote) {
    return (
      <>
        {!inPresentation && (
          <>
            <div className={classnames(classes.paperPadding, classes.paperPaddingTop)}>
              {showImportButton && (
                <>
                  <div className={classes.importPdfButtonSpacer}></div>
                  <PopupMenu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    component={
                      <Button
                        className={classes.importPdfButton}
                        disabled={quoteImportUploadLoading}
                        endIcon={<ExpandMoreIcon />}
                        prominence={5}
                      >
                        {`Replace/Remove PDF ${objectLabels.quote.value}`}
                      </Button>
                    }
                  >
                    <MenuItem icon={SwapVertIcon} onClick={quoteImportUpload}>
                      Replace Imported PDF
                    </MenuItem>
                    <MenuItem icon={RemoveCircleOutlineIcon} onClick={() => onImportQuoteImage({ files: null })}>
                      Remove Imported PDF
                    </MenuItem>
                  </PopupMenu>
                </>
              )}

              {canImportImage ? (
                <FileDropArea
                  accept={'application/pdf, image/*'}
                  forceLoading={quoteImportUploadLoading}
                  label={'Drop Quote PDF or Image'}
                  multiple={false}
                  onUpload={(files) => onImportQuoteImage({ files: files })}
                >
                  <QuoteHeader />
                </FileDropArea>
              ) : (
                <QuoteHeader />
              )}
            </div>
            <div className={classes.divider}>
              <div className={classes.dividerTop}>
                <Typography variant={'overline'}>{`Internal Use Only`}</Typography>
              </div>
              <div className={classes.dividerLine} />
              <Typography variant={'overline'}>{`Imported ${objectLabels.quote.value}`}</Typography>
            </div>
          </>
        )}
        <ImportedQuoteView
          file={quote.importedContent.primary}
          preview={!inPresentation}
          forceImageRender={isPreview || printOnly}
        />
        {showPaymentsTable && (
          <div
            className={classnames({
              [classes.spacer]: true,
              [classes.paymentsTable]: true,
              [classes.hidePrint]: !showQuotePayments,
              [classes.paperPadding]: true,
              [classes.defaultStyles]: true
            })}
          >
            <PaymentsTable
              onChange={handlePaymentsChange}
              collapsed={false}
              onToggle={() => handleToggle('showQuotePayments')}
              inPresentation={inPresentation || isRevision}
            />
          </div>
        )}
        {showGallery && (
          <div
            className={classnames({
              [classes.hidePrint]: !showQuoteFiles,
              [classes.filesWrapper]: true,
              [classes.paperPadding]: true,
              [classes.defaultStyles]: true,
              [classes.galleryImport]: true
            })}
          >
            <GalleryFiles hideAdd={inPresentation} />
          </div>
        )}
        {!inPresentation && !isEditable && (
          <div
            className={classnames({
              [classes.hidePrint]: !showQuoteFiles,
              [classes.filesWrapper]: true,
              [classes.paperPadding]: true
            })}
          >
            <FormSection hideDivider={true}>
              <QuoteTerms />
            </FormSection>
          </div>
        )}
        {(showPaymentsTable || showGallery || (!inPresentation && !isEditable)) && (
          <div className={classes.importBottomSpacer}></div>
        )}
      </>
    )
  }

  /**
   * Get the selected items from state.selectedItems
   *
   * @param args
   * @param args.section Get only items from a section
   */
  function getSelectedItems(args?: { section?: 'bid' | 'options' | 'additional' | 'pending' | 'archived' }) {
    const section = args && args.section ? args.section : null
    const { bid, options, additional, pending, archived } = selectedItems

    if (section) {
      return selectedItems[section]
    }

    return [...bid, ...options, ...additional, ...pending, ...archived]
  }

  /**
   * Gets the selected items by type
   */
  function getSelectedItemsByType(type: string) {
    const { bid, options, additional, pending, archived } = selectedItems
    const items = [...bid, ...options, ...additional, ...pending, ...archived]

    return items.filter((item) => item.type === type)
  }

  const handleBulkActionClick =
    (action: string, previousSection: QuoteItemSection) => (selectedItems: RenderableItem[]) => {
      onItemAction(
        action,
        previousSection,
        selectedItems.map((item) => item.key)
      )

      setSelectedItems({
        bid: [],
        options: [],
        additional: [],
        pending: [],
        archived: []
      })
    }

  /**
   * Sets the selected items for the section
   */
  const handleItemSelection =
    (args: { section: 'bid' | 'options' | 'additional' | 'pending' | 'archived' }) =>
    (event: React.ChangeEvent<HTMLElement>, newSelectedItems: RenderableItem[]) => {
      setSelectedItems({
        ...selectedItems,
        [args.section]: newSelectedItems
      })
    }

  function handleDeselectAll() {
    setSelectedItems({
      bid: [],
      options: [],
      additional: [],
      pending: [],
      archived: []
    })
  }

  function handlePaymentsChange(quote: QuoteDocument) {
    updateQuote({ quote })
  }

  function handleToggle(name: string) {
    const quoteOptions = getQuoteOptions({ options, quote })

    quoteOptions[name] = !quoteOptions[name]

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  const itemsArea = (
    <>
      <div className={classes.headerWrapper}>
        {showImportButton && !inPresentation && (
          <>
            <div className={classes.importPdfButtonSpacer}></div>
            <Button
              className={classnames(classes.importPdfButton, classes.importPdfButtonShift)}
              disabled={quoteImportUploadLoading}
              onClick={quoteImportUpload}
              prominence={5}
            >{`Import PDF ${objectLabels.quote.value}`}</Button>
          </>
        )}
        <QuoteHeader
          className={classnames({
            [classes.inPresentation]: inPresentation
          })}
        />
      </div>
      <BidTable
        selectedItems={getSelectedItems({ section: 'bid' })}
        onItemSelection={handleItemSelection({ section: 'bid' })}
        inPresentation={inPresentation || isRevision}
      />
      <OptionsTable
        classes={{ itemTableRoot: classes.optionsTableRoot }}
        inPresentation={inPresentation}
        isRevision={isRevision}
        onItemSelection={handleItemSelection({ section: 'options' })}
        onToggle={() => handleToggle('showQuoteOptions')}
        selectedItems={getSelectedItems({ section: 'options' })}
      />
      {/* <Divider /> */}
      <Grid container justifyContent={'flex-end'}>
        {canEdit && !isInvoiceFromImport && (
          <Grid item xs={12} sm className={classes.totalsLeft}>
            <OnboardingPopper open={isActiveTask} title={'Nice, now add an Item'}>
              <Button
                className={classes.addItemButton}
                startIcon={<AddIcon />}
                fullWidth
                prominence={4}
                onClick={() => onAddItem(defaultToOptions ? 'options' : 'bid')}
              >
                Add Item
              </Button>
            </OnboardingPopper>

            <Button prominence={3} onClick={onEditTerms} fullWidth={false} edge={'start'} style={{ display: 'block' }}>
              Terms & Conditions
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm>
          <Collapse show={inPresentation && isInvoice && selectedOptionalItemKeys.length > 0 && !isPreview}>
            <AcceptInvoiceOptionsButton
              classes={{ root: classes.acceptInvoiceOptionsRoot }}
              selectedOptionalItems={selectedOptionalItemKeys}
            />
          </Collapse>
        </Grid>
        {details.totals.show && (
          <Grid item xs={12} sm={'auto'} alignItems={'flex-end'}>
            <TotalTable
              className={classnames({
                [classes.inPresentation]: inPresentation
              })}
              inPresentation={inPresentation}
              isEditable={isInvoiceFromImport ? false : isEditable}
            />
          </Grid>
        )}
      </Grid>
    </>
  )

  // non-imported quote/invoice
  return (
    <>
      <div className={classes.root} id={'quote'} data-testid="quote">
        {canImportImage ? (
          <FileDropArea
            accept={'application/pdf, image/*'}
            forceLoading={quoteImportUploadLoading}
            label={`Drop ${objectLabels.quote.value} PDF or Image`}
            multiple={false}
            onUpload={(files) => onImportQuoteImage({ files: files })}
          >
            {itemsArea}
          </FileDropArea>
        ) : (
          <>{itemsArea}</>
        )}
        {showPaymentsTable && (
          <div
            className={classnames({
              [classes.spacer]: true,
              [classes.paymentsTable]: true,
              [classes.hidePrint]: !showQuotePayments
            })}
          >
            <PaymentsTable
              onChange={handlePaymentsChange}
              collapsed={false}
              onToggle={() => handleToggle('showQuotePayments')}
              inPresentation={inPresentation || isRevision}
            />
          </div>
        )}
        {showGallery && (
          <div className={classnames({ [classes.hidePrint]: !showQuoteFiles, [classes.filesWrapper]: true })}>
            <GalleryFiles hideAdd={inPresentation} />
          </div>
        )}

        {!inPresentation && !isEditable && (
          <FormSection hideDivider={true}>
            <QuoteTerms />
          </FormSection>
        )}

        {inPresentation && quote?.signatures?.customer && (
          <FormSection hideDivider={true} style={{ maxWidth: 320, paddingBottom: 0 }}>
            <QuoteSignCard hideRecap={true} />
          </FormSection>
        )}
      </div>
      {(canEdit || canAddAdditionalWork) && (
        <BulkActions
          options={options}
          quote={quote}
          tableView={tableView}
          getSelectedItems={getSelectedItems}
          getSelectedItemsByType={getSelectedItemsByType}
          onBulkActionClick={handleBulkActionClick}
          onDeselectAll={handleDeselectAll}
        />
      )}
    </>
  )
}

export default QuoteView
