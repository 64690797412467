import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const LeafIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} {...props}>
    <svg width={'24'} height={'24'}>
      <path d="M4 12C4 7.58172 7.58172 4 12 4H20V12C20 16.4183 16.4183 20 12 20H4V12Z" />
    </svg>
  </SvgIcon>
)
export default LeafIcon
