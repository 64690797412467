import CardsOptions from '../Cards/CardsOptions'
import type { PresentationSection } from 'paintscout'

interface TestimonialsOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
}

function TestimonialsOptions(props: TestimonialsOptionsProps) {
  return CardsOptions({ type: 'testimonials', ...props })
}

export default TestimonialsOptions
