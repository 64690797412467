import React, { useContext, useState } from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { Fade, createStyles, withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import classnames from 'classnames'
import EditIcon from '@material-ui/icons/Edit'
import { QuoteContext, useQuote } from '../../../context'
import { usePresentationNav } from '../../../presentation'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      background: 'none',
      // minHeight: theme.typography.pxToRem(80),
      borderRadius: 0,
      boxShadow: theme.shadows[0],
      textAlign: 'left',
      overflow: 'visible',

      '& button': {
        color: theme.palette.grey[800]
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 0
      }
    },
    clickable: {
      cursor: 'pointer',
      '&:hover button': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main
      }
    },

    infoWrapper: {
      width: '100%'
    },
    contents: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.typography.pxToRem(16),
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      '&:last-child': {
        paddingBottom: 0
      },
      ...theme.typography.body1
    },
    innerContents: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        // flexDirection: 'column'
      },
      ['@media print']: {
        flexDirection: 'row'
      }
    },
    title: {
      paddingRight: theme.spacing(1),
      ...theme.typography.overline,
      color: theme.palette.secondary.main,

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) '
    },
    border: {
      // borderTop: `solid ${theme.typography.pxToRem(2)}`,
      borderColor: theme.palette.grey.A100,
      marginBottom: theme.typography.pxToRem(10)
    },
    titleHover: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    hoverIcon: {
      ...theme.typography.overline,
      marginLeft: theme.spacing(),
      '@media print': {
        display: 'none'
      }
    },
    subtitle: {
      flexDirection: 'column',
      ...theme.typography.subtitle1,
      color: theme.palette.common.black
    },
    text: {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word'
    },
    innerContentsStacked: {
      flexDirection: 'column'
    },
    placeholderContent: {
      marginTop: theme.spacing()
    },
    isSecondary: {
      paddingBottom: theme.spacing(),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4.5)
      }
    }
  })
}

export interface QuoteHeaderCardProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  style?: React.CSSProperties
  title?: React.ReactText | JSX.Element
  subtitle?: React.ReactText | JSX.Element
  text?: React.ReactText | JSX.Element
  image?: React.ReactNode
  imageWidth?: number
  imageHeight?: number
  imageOrientation?: 'default' | 'auto' | 'stack'
  clickable?: boolean
  placeholderContent?: JSX.Element
  showPlaceholder?: boolean
  showBorder?: boolean
  hideBorder?: boolean
  isSecondary?: boolean
  hoverIcon?: any
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export interface QuoteHeaderCardState {
  showHover?: boolean
}

const fadeTimeout = 300

function QuoteHeaderCard({
  image,
  imageWidth,
  imageHeight,
  imageOrientation,
  title,
  subtitle,
  text,
  placeholderContent,
  showPlaceholder,
  isSecondary,
  onClick,

  clickable,
  showBorder,
  hideBorder = false,
  classes,
  children,
  hoverIcon,
  ...otherProps
}: QuoteHeaderCardProps) {
  const [showHover, setShowHover] = useState<boolean>(false)
  const quote = useQuote()
  const { isEditable } = useContext(QuoteContext)
  const presentationNav = usePresentationNav()
  const isQuoteEditable = clickable && quote?.onAccept && isEditable
  const canEditQuote = (!presentationNav?.isCustomerView && isQuoteEditable) ?? false

  const stackImage = imageOrientation === 'stack' || (imageOrientation === 'auto' && imageWidth / imageHeight > 1.5)

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    setShowHover(false)
    if (onClick) {
      onClick(ev)
    }
  }
  return (
    <Card
      {...otherProps}
      classes={{
        root: classes.root
      }}
      onClick={clickable ? handleClick : undefined}
    >
      <CardContent
        className={classnames(classes.contents, { [classes.clickable]: clickable })}
        onMouseEnter={() => {
          setShowHover(true)
        }}
        onMouseLeave={() => {
          setShowHover(false)
        }}
      >
        <div
          className={classnames(classes.innerContents, {
            [classes.innerContentsStacked]: stackImage
          })}
        >
          {image}

          <div className={classes.infoWrapper}>
            {/* {!hideBorder && (title || showBorder) && <div className={classes.border}></div>} */}
            {(title || hoverIcon) && <Title hoverIcon={hoverIcon} />}
            <Fade
              in={!placeholderContent || !showPlaceholder}
              appear={false}
              timeout={fadeTimeout}
              unmountOnExit={false}
            >
              <>
                {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                {text && <div className={classes.text}>{text}</div>}
                {children}
              </>
            </Fade>
          </div>
        </div>

        <Fade in={placeholderContent && showPlaceholder} appear={false} timeout={fadeTimeout} unmountOnExit={true}>
          <div
            className={classnames(classes.placeholderContent, {
              [classes.isSecondary]: isSecondary
            })}
          >
            {placeholderContent}
          </div>
        </Fade>
      </CardContent>
    </Card>
  )

  interface TitleProps {
    hoverIcon?: any
  }

  function Title(props: TitleProps) {
    const Icon = props.hoverIcon ?? EditIcon
    const hoverIcon = <Icon classes={{ root: classes.hoverIcon }} />

    return (
      <div className={classnames(classes.title, showHover && clickable ? classes.titleHover : '')}>
        {title}
        {canEditQuote && hoverIcon}
      </div>
    )
  }
}

export default withStyles(styles)(QuoteHeaderCard)
