import type { QuoteDocument } from 'paintscout'
import get from 'lodash/get'
import { parseNumber } from '../util/parse-number'

export function totalAdjustment(quote: QuoteDocument): Partial<QuoteDocument['totals']> {
  const newTotal = {} as Partial<QuoteDocument['totals']>

  const totalAdjustment = get(quote, 'totalAdjustment') || {}

  const adjustmentValue =
    typeof totalAdjustment.rate !== 'undefined' && totalAdjustment.rate !== null ? parseNumber(totalAdjustment.rate) : 0
  const adjustmentType = totalAdjustment.type ? totalAdjustment.type : 'percentage'

  const price = parseNumber(quote.totals.price)

  if (adjustmentType === 'percentage') {
    newTotal.total_adjustment = price * adjustmentValue * 0.01
  } else {
    newTotal.total_adjustment = adjustmentValue
  }

  newTotal.after_adjustment = price + newTotal.total_adjustment

  return newTotal
}
