import React, { useContext, useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import QuoteHeaderCard from '../QuoteHeaderCard'
import moment from 'moment'
import { DatePicker } from '@ui/stickybid'
import { setDate } from '@paintscout/util/builder'
import { useClientOptions } from '@ui/stickybid'
import { QuoteContext } from '../../../context/QuoteContext'
import { Hidden, TextField } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { usePresentationNav } from '../../../presentation'
import EventIcon from '@material-ui/icons/Event'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 3,
      marginBottom: -3,
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      overflow: 'visible'
    },
    rootEditable: {
      '&:hover': {
        '& $dateEditIcon': {
          color: theme.palette.primary.main
        },
        '& $calendarIcon': {
          color: theme.palette.primary.main
        }
      }
    },
    infoWrapper: {
      '&$infoWrapper': {
        display: 'inline-flex'
      }
    },
    editable: {},
    button: {
      width: 88,
      position: 'absolute',
      opacity: 0,
      height: theme.spacing(4),
      top: 0
    },
    datePicker: {
      '&$editable': {
        pointerEvents: 'none'
      },
      // remove the underline on the inputs on DatePicker
      '& > div:before': {
        border: 'none !important'
      },
      '& > div:after': {
        border: 'none !important'
      }
    },
    input: {
      ...theme.typography.body1,
      padding: 0,
      '& input': {
        textAlign: 'right',
        width: 84,
        [theme.breakpoints.down('sm')]: {
          textAlign: 'left'
        }
      },
      '& $inputDisabled': {
        color: `${theme.palette.common.black} `,
        padding: 0
      }
    },
    inputDisabled: {},
    dateEditIcon: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(-6),
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(),
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '.9rem'
      }
    },
    dateIconHolder: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginRight: -2
      }
    },
    calendarIcon: {
      color: theme.palette.secondary.main,
      fontSize: '1rem',
      marginRight: theme.spacing(1)
    }
  })

export interface QuoteDateCardProps extends WithStyles<typeof styles> {}

function QuoteDateCard({ classes }: QuoteDateCardProps) {
  const { options } = useClientOptions()
  const { quote, updateQuote, isEditable } = useContext(QuoteContext)
  const { presentation } = usePresentationNav()

  const [pickerOpen, setPickerOpen] = useState(false)

  function handleChange(date: moment.Moment) {
    updateQuote({ quote: setDate({ quote, date: date.unix() * 1000 }) })
  }

  const handleClick = () => {
    if (isEditable && !pickerOpen) {
      setPickerOpen(true)
    }
  }

  const handleClose = () => {
    setPickerOpen(false)
  }

  const dateFormat = options.options.dateFormat.momentValue

  const currentYear = new Date().getFullYear()
  const fromMonth = new Date(currentYear - 5, 0)
  const toMonth = new Date(currentYear + 5, 11)

  return (
    <QuoteHeaderCard
      classes={{
        root: classnames(classes.root, { [classes.rootEditable]: isEditable }),
        infoWrapper: classes.infoWrapper
      }}
      hideBorder={true}
      clickable={isEditable}
      title={''}
      onClick={handleClick}
      text={
        <div className={classes.dateIconHolder}>
          <EventIcon className={classes.calendarIcon} />
          <DatePicker
            id="quote-date-header"
            noMinInputHeight={true}
            open={pickerOpen}
            format={dateFormat}
            value={moment(quote.quote_date)}
            onChange={handleChange}
            PickerProps={{
              fromMonth: fromMonth,
              toMonth: toMonth
            }}
            InputComponent={React.forwardRef(function InputComponent(inputProps, ref) {
              return (
                <TextField
                  {...inputProps}
                  value={moment(quote.quote_date).format(dateFormat)}
                  ref={ref}
                  disabled={!isEditable}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.input,
                      disabled: classes.inputDisabled
                    }
                  }}
                />
              )
            })}
            onClose={handleClose}
          />
          {isEditable && !presentation && (
            <Hidden smDown>
              <div>
                <EditIcon className={classes.dateEditIcon} />
              </div>
            </Hidden>
          )}
          <div
            className={classes.button}
            onClick={() => {
              if (isEditable) {
                setPickerOpen(true)
              }
            }}
          />
        </div>
      }
    />
  )
}

export default withStyles(styles)(QuoteDateCard)
