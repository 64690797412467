/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import get from 'lodash/get'
import type { QuoteDetails } from '../../index'
import { calculateQuote } from '../../util'

export function setDetails(args: {
  quote: QuoteDocument
  details: Partial<QuoteDetails>
  options: OptionsDocument
  calculate?: boolean
}): QuoteDocument {
  const { quote, options, details, calculate } = args
  const { discount, tax, notes, hourlyRate, clientVisible, crewVisible, totals } = details

  let totalAdjustment =
    typeof details.totalAdjustment.rate !== 'number'
      ? parseFloat(details.totalAdjustment.rate)
      : details.totalAdjustment.rate
  if (isNaN(totalAdjustment)) {
    totalAdjustment = 0
  }

  const quoteOptions = quote.options ? quote.options : ({} as any)

  // We don't allow showing prices if there's a totalAdjustment... So we'll turn that off here.
  const showPrices = quoteOptions.showPrices && totalAdjustment === 0
  // We also don't want to allow grouping when the total has been adjusted
  const groupHours = quoteOptions.groupHours && totalAdjustment === 0

  const showWorkOrderAreaValues = quoteOptions.showWorkOrderAreaValues && !totals.hours.useCustom
  const showWorkOrderSubstrateValues = quoteOptions.showWorkOrderSubstrateValues && !totals.hours.useCustom

  const source = {
    ...(details.source ?? {}),
    id: details.source?.id ?? '',
    provider: details.source?.provider ?? ''
  }

  let discountRate = get(discount, 'rate', quote.discount)
  if (typeof discountRate === 'string') {
    discountRate = parseFloat(discountRate)
  }

  const updatedQuote = {
    ...quote,
    options: {
      ...quoteOptions,
      showPrices,
      groupHours,
      showWorkOrderAreaValues,
      showWorkOrderSubstrateValues
    },
    hourly_rate: typeof hourlyRate === 'string' ? parseFloat(hourlyRate) : hourlyRate,
    clientVisible: !!clientVisible,
    crewVisible: !!crewVisible,
    client_note: get(notes, 'client', quote.client_note),
    internal_note: get(notes, 'internal'),
    productString: get(notes, 'products'),

    discount: discountRate,
    discount_type: {
      ...quote.discount_type,
      value: get(discount, 'type', get(quote, 'discount_type.value', 'percentage'))
    },
    discount_description: get(discount, 'description', quote.discount_description),
    discount_isShown: true,
    totalAdjustment: {
      ...quote.totalAdjustment,
      ...details.totalAdjustment,
      rate: totalAdjustment
    },

    source,
    tax_rate: typeof tax.rate === 'string' ? parseFloat(tax.rate) : tax.rate,
    tax_description: tax.description,
    tax_details: tax?.details,
    totals: {
      ...quote.totals,
      override_hours: totals.hours.useCustom,
      show_total: totals.show,
      showBalanceDue: totals.showBalanceDue,
      override_hours_value:
        typeof totals.hours.custom === 'string' ? parseFloat(totals.hours.custom) : totals.hours.custom
    }
  }

  const calculatedQuote = calculateQuote({ quote: updatedQuote, options })
  return calculate === false ? updatedQuote : calculatedQuote
}
