import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'
import { DialogStackContext } from '@ui/stickybid'
import type { DialogProps } from '@ui/stickybid'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@ui/stickybid'
import { gql } from 'apollo-boost'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import type { Auth0User, ClientMetaDocument } from 'paintscout'
import React, { useContext, useState } from 'react'
import NewUserForm from './NewUserForm'
import CompanyIcon from '@material-ui/icons/LocationCity'

export interface NewUserDialogProps extends DialogProps {
  client: Pick<ClientMetaDocument, '_id' | 'name'>
  onConfirm: (user: Auth0User) => any
}

export default function NewUserDialog({ client, onConfirm, ...props }: NewUserDialogProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { dismissDialog } = useContext(DialogStackContext)

  const [createUser] = useMutation(gql`
    mutation createUser($input: CreateUserInput!, $companyId: String!, $sendEmail: Boolean!) {
      createUser(input: $input, companyId: $companyId, sendEmail: $sendEmail) {
        user_id
        email
        name
        user_metadata {
          firstName
          lastName
          phoneNumber
        }
        app_metadata {
          active
          roles
        }
        preferences
      }
    }
  `)

  const initialUserValues = {
    email: 'info+newtrial@stickybid.com',
    user_metadata: {
      firstName: 'New',
      lastName: 'Trial',
      phoneNumber: ''
    },
    app_metadata: {
      roles: ['admin']
    }
  } as Partial<Auth0User>

  const initialValues = {
    ...initialUserValues,
    sendEmail: true
  } as any

  async function handleSubmit(values: any) {
    setIsSubmitting(true)

    try {
      const res = await createUser({
        variables: {
          input: {
            email: values.email,
            user_metadata: values.user_metadata,
            app_metadata: values.app_metadata
          },
          companyId: client._id,
          sendEmail: values.sendEmail
        },
        refetchQueries: ['searchUsers']
      })
      onConfirm(res.data.createUser)
      enqueueSnackbar('User Created', { variant: 'success' })
    } catch (e) {
      console.error(e)
      const { graphQLErrors } = e

      if (graphQLErrors) {
        graphQLErrors.map((error) =>
          enqueueSnackbar(error.message, {
            variant: 'error'
          })
        )
      } else {
        enqueueSnackbar('Unable to create user', { variant: 'error' })
      }
    }
    setIsSubmitting(false)
  }

  return (
    <Dialog {...props}>
      <DialogTitle loading={isSubmitting}>
        <Typography variant={'h2'} gutterBottom style={{ marginTop: 8 }}>
          New User
        </Typography>
        <Typography variant={'h3'} style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
          <CompanyIcon style={{ marginBottom: 5, marginRight: 8 }} />
          <span>{client.name}</span>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ values, errors }) => (
            <Form id="new-user">
              <NewUserForm values={values} errors={errors} />
            </Form>
          )}
        />
      </DialogContent>
      <DialogActions
        leftButton={
          <Button edge={'start'} prominence={3} onClick={dismissDialog} disabled={isSubmitting}>
            Cancel
          </Button>
        }
      >
        <Button variant="contained" type="submit" form="new-user" disabled={isSubmitting}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
