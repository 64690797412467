import React, { useState } from 'react'
import type { DialogProps } from '@ui/stickybid'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DropdownSelect,
  FormControlLabel,
  FormSection,
  FormSectionTitle,
  Grid
} from '@ui/stickybid'
import { useSnackbar } from 'notistack'
import { useQuery } from '@apollo/react-hooks'
import type { GetClientResponse } from '../../graphql/queries/GET_CLIENT'
import { GET_CLIENT } from '../../graphql/queries/GET_CLIENT'
import { useRouteMatch } from 'react-router'
import type { ClientImportExportProps } from '../ClientAdministrationForm/ClientImportExport'

const defaultImportOptions = {
  areaLabels: false,
  lineItems: false,
  products: false,
  rates: false,
  presentations: false,
  terms: false,
  disclaimers: false
}

const accountOptions = [
  { value: 'dhjlwwbvfyploard', label: 'Onboarding Account' },
  { value: 'iqxvskejjohghvzh', label: 'Consulting 4 Contractors' },
  { value: 'oxjbyvmmctvnheho', label: 'PBP TEMPLATE' },
  { value: 'imgnzzxxwlujqesx', label: 'PCA TEMPLATE' },
  { value: 'bcqjokvasggitxfx', label: 'SUMMIT TEMPLATE' },
  { value: 'sqlwadwqemrpelag', label: 'Unforgettable Coatings' }
]

export interface ImportAccountDialogProps extends DialogProps {
  queueDocUpdates: (json: any, selectedOptions?: ClientImportExportProps) => void
  onClose?: () => void
}

const useClientQuery = (id: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const { data, error } = useQuery<GetClientResponse>(GET_CLIENT, {
    variables: {
      id: id ?? ''
    }
  })
  if (error) {
    enqueueSnackbar('Error retrieving account details', { variant: 'error' })
    return null
  }
  return data?.client
}

const ImportAccountDialog = ({ onClose, queueDocUpdates, ...props }: ImportAccountDialogProps) => {
  console.log('ImportAccountDialog')
  const match = useRouteMatch<{ id: string; tab: string; section: string }>()
  const { id } = match.params
  const { enqueueSnackbar } = useSnackbar()
  const [importOptions, setImportOptions] = useState(defaultImportOptions)
  const [selectedAccount, setSelectedAccount] = useState(id)
  const details = useClientQuery(selectedAccount)

  const handleImportCheckboxChange = (target: string) => {
    return function () {
      setImportOptions({ ...importOptions, [target]: !importOptions[target] })
    }
  }

  const handleImport = () => {
    if (details) {
      queueDocUpdates(details, importOptions)
      enqueueSnackbar('Documents ready to save!', { variant: 'success' })
      onClose()
    } else {
      enqueueSnackbar('Error retrieving account details', { variant: 'error' })
    }
  }

  return (
    <Dialog {...props}>
      <DialogTitle>Import From Account</DialogTitle>
      <DialogContent>
        <DropdownSelect
          label="Template Account"
          options={accountOptions}
          value={accountOptions[selectedAccount]?.label}
          multiSelect={false}
          onChange={(ev) => setSelectedAccount(ev.value)}
        />
        <br />
        <br />
        <FormSectionTitle title="Import" />
        <FormSection>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12}>
              <FormControlLabel
                label={'Area Labels'}
                control={
                  <Checkbox checked={importOptions.areaLabels} onChange={handleImportCheckboxChange('areaLabels')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Line Items'}
                control={
                  <Checkbox checked={importOptions.lineItems} onChange={handleImportCheckboxChange('lineItems')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Products'}
                control={
                  <Checkbox checked={importOptions.products} onChange={handleImportCheckboxChange('products')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Rates'}
                control={<Checkbox checked={importOptions.rates} onChange={handleImportCheckboxChange('rates')} />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Presentations'}
                control={
                  <Checkbox
                    checked={importOptions.presentations}
                    onChange={handleImportCheckboxChange('presentations')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Terms'}
                control={<Checkbox checked={importOptions.terms} onChange={handleImportCheckboxChange('terms')} />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Disclaimers'}
                control={
                  <Checkbox checked={importOptions.disclaimers} onChange={handleImportCheckboxChange('disclaimers')} />
                }
              />
            </Grid>
          </Grid>
        </FormSection>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button prominence={3} edge={'start'} onClick={onClose}>
            Cancel
          </Button>
        }
      >
        <Button prominence={1} onClick={handleImport} disabled={selectedAccount === id}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportAccountDialog
