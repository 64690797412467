import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { InlineEditableMedia } from '../../../inlineEditable'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import { useMediaQuery } from '@ui/stickybid'
import { usePresentation } from '../../../context'

const useStyles = makeStyles<Theme, PresentationSectionProps>(
  (theme: Theme) => {
    return {
      root: {},
      content: {},
      image: {
        maxWidth: '100%'
      }
    }
  },
  { name: 'MediaSection' }
)

function MediaSection(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const {
    isEditable,
    isSettings,
    presentationIndustry,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    WrapperComponent
  } = props
  // const { setFieldValue, submitForm } = formik
  const { fields = {} } = section
  const { placeholder, display, thumbnailOnMobile } = fields
  const { presentationLibrary } = usePresentation()

  let image = fields.image

  // TODO - work around for now
  if (image?.format === 'ai') {
    image = { ...image, format: 'pdf', src: image.src.replace('.ai', '.pdf') }
  }

  const handleEditField = (field: string, content: any) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  const xsDown = useMediaQuery('xs')

  const showAllPages = display === 'full' && (!xsDown || (xsDown && !thumbnailOnMobile))

  return (
    <WrapperComponent {...props}>
      <InlineEditableMedia
        mediaOptionsProps={{
          sectionPrefix: sectionPrefix,
          fieldSuffix: 'image'
        }}
        classes={{ root: classes.image }}
        file={image}
        handleEdit={(file) => handleEditField('fields.image', file)}
        isEditable={isEditable}
        isSettings={isSettings}
        placeholderButtonText={placeholder || 'Add Media'}
        presentationIndustry={presentationIndustry}
        presentationLibrary={presentationLibrary}
        showAllPages={showAllPages}
      />
    </WrapperComponent>
  )
}

export default React.memo(MediaSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
