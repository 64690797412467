/**
 * @module builder
 */
import type { QuoteDocument, Payment } from 'paintscout'

export function getPayments(args: { quote: QuoteDocument }): Payment[] {
  const { quote } = args

  const { payments } = quote
  return payments ? [...payments] : []
}
