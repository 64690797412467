import React from 'react'
import type { FormikErrors } from 'formik'
import { Field, FieldArray } from 'formik'
import type { Auth0User } from 'paintscout'
import { FormikInputField, Select, MenuItem, CheckboxField } from '@ui/stickybid'
import { Grid, makeStyles } from '@material-ui/core'
import getHighestRole from '../../util/getHighestRole'

const useStyles = makeStyles({
  root: {},
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
})
export interface NewUserFormProps {
  values: Partial<Auth0User>
  errors?: FormikErrors<Partial<Auth0User>>
}

const NewUserForm = ({ errors }: NewUserFormProps) => {
  const classes = useStyles()
  let emailHelperText = ''

  if (errors && errors.email) {
    emailHelperText = errors.email
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <FieldArray
                name="app_metadata.roles"
                render={(arrayHelpers) => (
                  <Select
                    label="Role"
                    margin="dense"
                    value={getHighestRole(arrayHelpers.form.values.app_metadata.roles)}
                    variant="outlined"
                    onChange={(e) => {
                      arrayHelpers.form.setFieldValue('app_metadata.roles', [e.target.value])
                    }}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="sales">Sales</MenuItem>
                    <MenuItem value="crew">Crew</MenuItem>
                    <MenuItem value="viewer">Viewer</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid className={classes.checkboxDiv} item xs={6} sm={9}>
              <CheckboxField label="Send Password Email?" name={'sendEmail'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="user_metadata.firstName"
            label="First Name"
            required
            component={FormikInputField}
            fullWidth
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="user_metadata.lastName"
            label="Last Name"
            required
            component={FormikInputField}
            fullWidth
            autoComplete="no"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="email"
            label="Email"
            type="email"
            required
            component={FormikInputField}
            fullWidth
            error={errors && !!errors.email}
            helperText={emailHelperText}
            autoComplete="no"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default NewUserForm
