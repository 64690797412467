import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { PopupMenuItem, PresEditorTooltip } from '@ui/stickybid'
import type { PresentationOption, PresentationPage, PresentationSection } from 'paintscout'

import { uuid } from '@paintscout/util/builder'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { arrayMove } from 'react-sortable-hoc'

import { CutIcon, PasteIcon } from '@ui/core/icons'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditOutlineIcon from '@material-ui/icons/EditOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import classnames from 'classnames'

export interface ClipboardItem {
  isCut: boolean
  copiedPageIndex: number
  copiedSection: PresentationSection
  copiedSectionIndex: number
}

const useStyles = makeStyles<Theme, SectionMenuProps>(
  (theme) => ({
    root: {},
    sectionMenu: {
      backgroundColor: theme.palette.grey[100],
      transition: `top .3s cubic-bezier(0.33, 1, 0.68, 1), border 0s ease-in .18s, right 0s ease-in .18s`,
      borderRadius: theme.borderRadius.md,
      boxShadow: theme.boxShadow[1],
      display: 'none',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      right: 0,
      top: 20,
      zIndex: 3
    },
    sectionFocusedMenu: {
      border: `2px solid ${theme.palette.presentation.sectionBorder.focus}`,
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: 'none',
      display: 'flex',
      top: -42,
      right: -2
    },
    deleteIcon: {
      '& svg': {
        color: 'red'
      },
      '&:hover': {
        '& svg': {
          fill: 'red'
        }
      }
    }
  }),
  { name: 'SectionMenu' }
)

export interface SectionMenuProps {
  classes?: StyleClasses<typeof useStyles>
  addPages: boolean
  clipboard: ClipboardItem
  enqueueSnackbar: any
  focusedSection: string
  handleCreateSection: (newSection: PresentationSection, sectionIndex: number, pageIndex: number) => void
  handleDeleteSection: (key: string, pageIndex: number) => void
  handleReorderSections: (pages: PresentationPage[]) => void
  index: number
  pageIndex: number
  presentation: PresentationOption
  section: PresentationSection
  sectionOptionsOpen: boolean
  setClipboard: React.Dispatch<React.SetStateAction<ClipboardItem>>
  setFocusedSection: (section: string) => void
  setMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
}

function SectionMenu(props: SectionMenuProps) {
  const classes = useStyles(props)
  const {
    addPages,
    clipboard,
    enqueueSnackbar,
    focusedSection,
    handleCreateSection,
    handleDeleteSection,
    handleReorderSections,
    index,
    pageIndex,
    presentation,
    section,
    sectionOptionsOpen,
    setClipboard,
    setFocusedSection,
    setMenuAnchorEl
  } = props
  const optionsMenuRef = useRef<HTMLSpanElement>()

  const isQuoteSection = ['quote', 'work-order'].includes(section.type)
  const showCopySection = !isQuoteSection && section.type !== 'terms'

  return (
    <div
      className={classnames(classes.sectionMenu, {
        ['sectionHoverMenu']: !sectionOptionsOpen,
        [classes.sectionFocusedMenu]: focusedSection === section.key && !sectionOptionsOpen
      })}
    >
      <PresEditorTooltip title={'Section Options'}>
        <span ref={optionsMenuRef}>
          <PopupMenuItem
            icon={EditOutlineIcon}
            iconOnly
            onClick={(ev) =>
              handleOpenSectionOptions(ev, section.key, setFocusedSection, setMenuAnchorEl, optionsMenuRef.current)
            }
          />
        </span>
      </PresEditorTooltip>
      {!addPages && (
        <>
          <PresEditorTooltip title={'Cut Section'}>
            <PopupMenuItem
              icon={CutIcon}
              iconOnly
              onClick={() =>
                handleCopySection({
                  isCut: true,
                  enqueueSnackbar,
                  pageIndex,
                  section,
                  sectionIndex: index,
                  setClipboard
                })
              }
            />
          </PresEditorTooltip>
          {showCopySection && (
            <PresEditorTooltip title={'Copy Section'}>
              <PopupMenuItem
                icon={FileCopyOutlinedIcon}
                iconOnly
                onClick={() =>
                  handleCopySection({
                    isCut: false,
                    enqueueSnackbar,
                    pageIndex,
                    section,
                    sectionIndex: index,
                    setClipboard
                  })
                }
              />
            </PresEditorTooltip>
          )}
          <PresEditorTooltip title={'Paste Below Section'}>
            <PopupMenuItem
              icon={PasteIcon}
              iconOnly
              disabled={!clipboard}
              onClick={() =>
                handlePasteSection({
                  pageIndex,
                  sectionIndex: index,
                  clipboard,
                  setClipboard,
                  handleCreateSection,
                  setFocusedSection,
                  presentation,
                  handleReorderSections
                })
              }
            />
          </PresEditorTooltip>
          <PresEditorTooltip title={'Move Section Down'}>
            <PopupMenuItem
              icon={ArrowDownwardIcon}
              iconOnly
              onClick={() => handleMoveSection(presentation, 'down', pageIndex, index, handleReorderSections)}
            />
          </PresEditorTooltip>
          <PresEditorTooltip title={'Move Section Up'}>
            <PopupMenuItem
              icon={ArrowUpwardIcon}
              iconOnly
              onClick={() => handleMoveSection(presentation, 'up', pageIndex, index, handleReorderSections)}
            />
          </PresEditorTooltip>
          <PresEditorTooltip title={'Delete Section'}>
            <PopupMenuItem
              icon={DeleteOutlineIcon}
              iconOnly
              onClick={(ev) => handleDelete(ev, section.key, pageIndex, setFocusedSection, handleDeleteSection)}
              classes={{ root: classes.deleteIcon }}
            />
          </PresEditorTooltip>
        </>
      )}
    </div>
  )
}

export default React.memo(SectionMenu, (prevProps, nextProps) => {
  if (prevProps.addPages !== nextProps.addPages) return false
  if (prevProps.pageIndex !== nextProps.pageIndex) return false
  if (prevProps.focusedSection !== nextProps.focusedSection) return false
  if (prevProps.index !== nextProps.index) return false
  if (prevProps.sectionOptionsOpen !== nextProps.sectionOptionsOpen) return false
  if (!isEqual(prevProps.section, nextProps.section)) return false
  if (!isEqual(prevProps.clipboard, nextProps.clipboard)) return false
  if (!isEqual(prevProps.presentation, nextProps.presentation)) return false

  // functions shouldn't change
  // enqueueSnackbar
  // handleCreateSection
  // handleDeleteSection
  // handleReorderSections
  // setClipboard
  // setFocusedSection
  // setMenuAnchorEl
  return true
})

function handleMoveSection(
  presentation: PresentationOption,
  direction: 'up' | 'down',
  pageIndex: number,
  sectionIndex: number,
  handleReorderSections: any
) {
  const numberOfPages = presentation.pages.length
  const numberOfSections = presentation.pages[pageIndex].sections.length

  if (numberOfPages === 1) {
    return handleMoveSectionWithinPage(presentation, direction, pageIndex, sectionIndex, handleReorderSections)
  }

  if ((sectionIndex === 0 && direction === 'up') || (sectionIndex === numberOfSections - 1 && direction === 'down')) {
    return handleMoveSectionBetweenPages(presentation, direction, pageIndex, sectionIndex, handleReorderSections)
  }

  handleMoveSectionWithinPage(presentation, direction, pageIndex, sectionIndex, handleReorderSections)
}

function handleMoveSectionWithinPage(
  presentation: PresentationOption,
  direction: 'up' | 'down',
  pageIndex: number,
  sectionIndex: number,
  handleReorderSections: any
) {
  const pages = cloneDeep(presentation.pages)
  const sections = cloneDeep(presentation.pages[pageIndex].sections)

  let newIndex = sectionIndex
  if (direction === 'up') {
    newIndex = newIndex - 1
    if (newIndex < 0) newIndex = sections.length - 1
  }
  if (direction === 'down') {
    newIndex = newIndex + 1
    if (newIndex === sections.length) newIndex = 0
  }

  const newSections = arrayMove(sections, sectionIndex, newIndex)
  pages[pageIndex].sections = newSections
  handleReorderSections(pages)
}

function handleMoveSectionBetweenPages(
  presentation: PresentationOption,
  direction: 'up' | 'down',
  pageIndex: number,
  sectionIndex: number,
  handleReorderSections: any
) {
  const pages = cloneDeep(presentation.pages)
  const section = pages[pageIndex].sections[sectionIndex]
  // remove section from current page
  const currentPageSections = pages[pageIndex].sections
  const newPageSections = currentPageSections.filter((section, index) => index !== sectionIndex)
  pages[pageIndex].sections = newPageSections

  if (direction === 'up') {
    if (pageIndex === 0) {
      pages[pages.length - 1].sections.push(section)
    } else {
      pages[pageIndex - 1].sections.push(section)
    }
  }
  if (direction === 'down') {
    if (pageIndex === pages.length - 1) {
      pages[0].sections.unshift(section)
    } else {
      pages[pageIndex + 1].sections.unshift(section)
    }
  }

  handleReorderSections(pages)
}

function handleCopySection(args: {
  enqueueSnackbar: any
  isCut: boolean
  pageIndex: number
  section: PresentationSection
  sectionIndex: number
  setClipboard: React.Dispatch<React.SetStateAction<ClipboardItem>>
}) {
  const { enqueueSnackbar, isCut, pageIndex, section, sectionIndex, setClipboard } = args

  setClipboard({
    isCut,
    copiedPageIndex: pageIndex,
    copiedSection: section,
    copiedSectionIndex: sectionIndex
  })
  enqueueSnackbar(`Section ${isCut ? 'Cut' : 'Copied'}`, { variant: 'success' })
}

function handlePasteSection(args: {
  pageIndex: number
  sectionIndex: number
  clipboard: ClipboardItem
  setClipboard: React.Dispatch<React.SetStateAction<ClipboardItem>>
  handleCreateSection: any
  setFocusedSection: any
  presentation: PresentationOption
  handleReorderSections: any
}) {
  const {
    pageIndex,
    sectionIndex,
    clipboard,
    setClipboard,
    handleCreateSection,
    setFocusedSection,
    presentation,
    handleReorderSections
  } = args
  if (clipboard.isCut) {
    handleSwapSectionsByIndex({
      pageIndexStart: clipboard.copiedPageIndex,
      pageIndexEnd: pageIndex,
      sectionIndexStart: clipboard.copiedSectionIndex,
      sectionIndexEnd: sectionIndex + 1,
      presentation,
      handleReorderSections
    })
    setClipboard({ ...clipboard, isCut: false })
  } else {
    handleCreateSection({ ...clipboard.copiedSection, key: uuid() }, sectionIndex, pageIndex)
  }
  setFocusedSection(clipboard.copiedSection.key)
}

function handleDelete(
  ev: React.MouseEvent<Element, MouseEvent>,
  sectionKey: string,
  pageIndex: number,
  setFocusedSection: any,
  handleDeleteSection: any
) {
  setFocusedSection('')
  handleDeleteSection(sectionKey, pageIndex)
}

function handleSwapSectionsByIndex(args: {
  pageIndexStart: number
  pageIndexEnd: number
  sectionIndexStart: number
  sectionIndexEnd: number
  presentation: PresentationOption
  handleReorderSections: any
}) {
  const { pageIndexStart, pageIndexEnd, sectionIndexStart, sectionIndexEnd, presentation, handleReorderSections } = args
  const newPages = cloneDeep(presentation.pages)
  const section = newPages[pageIndexStart].sections.splice(sectionIndexStart, 1)[0]
  newPages[pageIndexEnd].sections.splice(sectionIndexEnd, 0, section)
  handleReorderSections(newPages)
}

const handleOpenSectionOptions = (
  ev: React.MouseEvent<any, MouseEvent>,
  sectionKey: string,
  setFocusedSection: any,
  setMenuAnchorEl: any,
  menuAnchorEl: any
) => {
  ev.stopPropagation()
  setFocusedSection(sectionKey)
  setMenuAnchorEl(menuAnchorEl)
}
