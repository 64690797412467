import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { SliderProps as CoreSliderProps } from '@ui/core/Slider'
import CoreSlider from '@ui/core/Slider'

const useStyles = makeStyles<Theme, SliderProps>((theme) => ({
  root: {}
}))

export interface SliderProps extends CoreSliderProps {
  classes?: CoreSliderProps['classes'] & StyleClasses<typeof useStyles>
}

function Slider(props: SliderProps) {
  const classes = useStyles(props)

  return <CoreSlider {...props} classes={classes} />
}

export default Slider
