import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import type { Auth0UserWithClaims, OptionsDocument, UserPreferencesDocument } from 'paintscout'
import Grid from '../../Grid'
import type { Theme } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/LockOutlined'
import Email from '@material-ui/icons/EmailOutlined'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { Field, useFormikContext } from 'formik'
import get from 'lodash/get'
import { getObjectLabels } from '@paintscout/util/builder'
import Button from '../../Button'
import { FormikInputField } from '../../formik'
import FormSection from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import PhoneNumberInput from '../../PhoneNumberInput'
import SignatureCanvas from '../../SignatureCanvas'
import { PageHelpMessage, LearnMoreLink } from '../../PageHelpMessage'
import Typography from '../../Typography'
import { downloadCSVTemplate } from '@paintscout/util'
import { useCSVExport, useCSVImport } from 'shared/hooks'
import { useSearchCombinedUsersQuery } from '@paintscout/api'

const useStyles = makeStyles<Theme, UserAccountFormProps>(
  (theme) => ({
    root: {},
    changeInfoButtons: {
      maxWidth: 450
    },
    changeInfoButton: {
      margin: theme.typography.pxToRem(15),
      marginLeft: 0
    },
    canvasButton: {
      justifyContent: 'left'
    }
  }),
  { name: 'UserAccountForm' }
)

export interface UserAccountFormProps {
  classes?: StyleClasses<typeof useStyles>
  disableSignature?: boolean
  allowEmailChange?: boolean
  showChangePassword?: boolean
  showImportExport?: boolean

  onChangePassword?: () => any
}

function UserAccountForm(props: UserAccountFormProps) {
  const classes = useStyles(props)
  const { allowEmailChange, disableSignature, onChangePassword, showChangePassword, showImportExport } = props
  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    preferences: UserPreferencesDocument
    user: Auth0UserWithClaims
  }>()
  const { options, preferences, user } = values
  const objectLabels = getObjectLabels({ options })

  const [owners, setOwners] = useState([])
  const companyId = user?.app_metadata?.companyId
  const { data } = useSearchCombinedUsersQuery({
    variables: {
      auth0Query: '',
      couchQuery: '*:*'
    },
    fetchPolicy: 'cache-and-network'
  })
  useEffect(() => {
    const users = (data?.searchCombinedUsers?.rows ?? [])
      .map((user) => {
        if (user.app_metadata?.active) {
          return {
            value: user.user_id,
            label: user.name,
            email: user.email,
            companyId
          }
        }
      })
      .filter(Boolean)

    setOwners(users)
  }, [data])
  const { handleImportCSV, isDirty, setIsDirty } = useCSVImport()
  const { handleExportCSV, isExporting } = useCSVExport()

  const handleSignatureChange = (event: any, data?: string) => {
    setFieldValue('preferences.signature' as any, data)
  }

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path="profile-account"
        openContent={
          <>
            <Typography variant={'h2'} color="inherit">
              Account
            </Typography>
            <Typography>
              This is where you can update your personal information or change your password!
              <LearnMoreLink link="http://help.stickybid.com/en/articles/3206825-updating-profile-estimator-information" />
            </Typography>
          </>
        }
        closedContent={<FormSectionTitle title={'Account'} variant="h2" />}
      />

      <FormSection>
        <Grid container={true}>
          <Grid item={true} xs={12} sm={9} lg={6} xl={8}>
            <Field
              component={FormikInputField}
              name="user.email"
              disabled={!allowEmailChange}
              margin="dense"
              label={'Email'}
              icon={Email}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid container={true} className={classes.changeInfoButtons}>
          {showChangePassword && (
            <Grid item={true} xs={12} sm={6}>
              <Button
                className={classes.changeInfoButton}
                startIcon={<LockIcon />}
                prominence={2}
                onClick={onChangePassword}
              >
                Change Password
              </Button>
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'Contact Information'}
          variant={'h4'}
          subTitle={`Shown on ${objectLabels.quote.plural} to help customers contact you.`}
        />
        <Grid container spacing={3}>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={9} lg={6} xl={4}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.firstName"
                margin="dense"
                label={'First Name'}
                required
                fullWidth
                helperTextState="error"
                helperText={get(errors, 'user.user_metadata.firstName')}
              />
            </Grid>
            <Grid item xs={12} sm={9} lg={6} xl={4}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.lastName"
                margin="dense"
                label={'Last Name'}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={4} lg={6} xl={4}>
              <Field
                component={FormikInputField}
                inputComponent={PhoneNumberInput}
                inputProps={{ separator: options?.options?.phoneNumbers?.separator }}
                name="user.user_metadata.phoneNumber"
                margin="dense"
                label={'Phone Number'}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={6} xl={4}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.title"
                margin="dense"
                label={'Title'}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'Signature'}
          variant={'h4'}
          subTitle={`Your signature that is shown on ${objectLabels.quote.plural}.`}
        />
        {!disableSignature ? (
          <SignatureCanvas
            disabled={disableSignature}
            caption={'Sign Here'}
            classes={{ captionRow: classes.canvasButton }}
            value={preferences?.signature ?? ''}
            onChange={handleSignatureChange}
          />
        ) : (
          <img src={preferences.signature} style={{ width: '100%', maxWidth: 450, height: 150 }} />
        )}
      </FormSection>
      {showImportExport && (
        <FormSection>
          <FormSectionTitle
            title={'Import / Export'}
            variant={'h2'}
            subTitle={`Transfer data in and out of StickyBid.`}
          />
          <FormSectionTitle
            title={'Customers'}
            variant={'h4'}
            subTitle={'Use CSV files to bulk add or export customers.'}
          />
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item xs={'auto'}>
              <Button
                onClick={() => handleImportCSV({ type: 'contacts', owners })}
                prominence={2}
                startIcon={<ArrowDownwardIcon />}
                disabled={isDirty}
              >
                Import
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button
                prominence={2}
                loading={isExporting}
                onClick={() => handleExportCSV({ type: 'contacts' })}
                startIcon={<ArrowDownwardIcon style={{ transform: 'rotate(180deg)' }} />}
              >
                Export
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => downloadCSVTemplate('contacts')}
                edge={'start'}
                prominence={3}
                style={{ marginTop: '-24px' }}
              >
                Download CSV Template
              </Button>
            </Grid>
          </Grid>
        </FormSection>
      )}
    </div>
  )
}

export default UserAccountForm
