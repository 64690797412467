import type { ThemeOptions } from '@material-ui/core'
import type { TypeBackground } from '@material-ui/core/styles/createPalette'
import { createTheme as createBaseTheme } from '@ui/core/theme'
import merge from 'lodash/merge'

export type { ThemeOptions }

export function createTheme(options?: ThemeOptions) {
  const fontScale = 1

  const fontSizes = {
    h1: `2.0rem`, // 32
    h2: `1.5rem`, // 27
    h3: `0.88rem`, // 16
    h4: `0.88rem`, // 16
    h5: `1rem`, // 18
    h6: `1rem`, // 18
    subtitle1: `1rem`, // 18
    subtitle2: `0.8rem`, // ~14.5
    body1: `1rem`, // 18
    body2: `0.8rem`, // ~14.5
    button: `0.8rem`, // ~14.5
    caption: `0.8rem`, // ~14.5
    overline: `0.8rem` // ~14.5
  }

  const lineHeight = 1.4
  const letterSpacing = '0.15em'

  // the design guide specifies Light | Regular | Medium | Semibold
  // these names below are as they are in mui selection
  const fontWeightLight = 300
  const fontWeightRegular = 400
  const fontWeightMedium = 500
  // const fontWeightBold = 700

  const black = '#000000'
  const grey = '#878787'
  const white = '#ffffff'

  const presentationSectionBorderHover = '#9065fc'
  const presentationSectionBorderFocus = '#50a9cc'
  const presentationPageBorder = '#008080'

  return createBaseTheme(
    merge(
      {
        overrides: {
          MuiCssBaseline: {
            '@global': {
              hr: {
                borderWidth: '1px 0 0 0'
              },
              '*': {
                '-webkit-tap-highlight-color': 'transparent'
              },
              '.onboardingHighlight': {
                position: 'relative !important',
                overflow: 'visible !important',
                '&:after': {
                  animation: '$pulse 3000ms ease-in infinite',
                  borderRadius: '0.25rem' as string,
                  boxShadow: '0px 0px 8px 6px rgba(33, 159, 255, 0.8)',
                  content: '""',
                  height: '100%',
                  left: 0,
                  pointerEvents: 'none',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  zIndex: 1
                }
              },

              '@keyframes pulse': {
                '0%': {
                  opacity: 0.4
                },
                '40%': {
                  opacity: 1
                },
                '100%': {
                  opacity: 0.4
                }
              }
            }
          },
          MuiInput: {
            input: {
              '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 100px #000 inset',
                '-webkit-text-fill-color': '#fff'
              }
            }
          }
        },
        palette: {
          primary: { main: black },
          secondary: { main: grey },
          presentation: {
            sectionBorder: {
              hover: presentationSectionBorderHover,
              focus: presentationSectionBorderFocus
            },
            pageBorder: {
              hover: presentationPageBorder
            }
          },
          onboarding: { main: '#30b6a0' },
          stickybid: { main: '#30b6a0' },
          // onboarding: { main: '#5708ff' },
          common: {
            black,
            white
          },
          text: {
            primary: black
          },
          background: {
            default: white
          }
        },
        boxShadow: {
          1: '3px 0px 12px -2px rgba(0,0,0,0.23)'
        },
        props: {
          // disable material-ui ripples
          MuiButtonBase: {
            disableRipple: true
          }
        },
        paintscout: {
          header: {
            height: {
              sm: '50px',
              md: '60px'
            }
          },
          fontScale: 1,
          palette: {
            primary: '#333',
            secondary: '#333'
          }
        },
        borderRadius: {
          none: '0',
          sm: '0.25rem' as string,
          md: '0.5rem' as string,
          lg: '0.75rem' as string,
          xl: '1rem' as string,
          '2xl': '1.5rem' as string,
          '3xl': '2rem' as string,
          full: '9999px'
        },
        typography: {
          fontFamily: 'degular, sans-serif',
          htmlFontSize: 16,
          fontWeight: fontWeightRegular,
          h1: {
            fontSize: fontSizes.h1,
            color: black,
            lineHeight: 1,
            fontWeight: fontWeightLight,
            textTransform: 'none' as const
          },
          h2: {
            fontSize: fontSizes.h2,
            color: black,
            lineHeight: 1,
            fontWeight: fontWeightMedium,
            textTransform: 'none' as const
          },
          h3: {
            fontSize: fontSizes.h3,
            color: black,
            lineHeight: 1,
            letterSpacing: '0.1em',
            fontWeight: fontWeightMedium,
            textTransform: 'uppercase' as const
          },
          h4: {
            fontSize: fontSizes.h4,
            color: grey,
            lineHeight: 1,
            letterSpacing: '0.1em',
            fontWeight: fontWeightMedium,
            textTransform: 'uppercase' as const
          },
          h5: {
            fontSize: fontSizes.h5,
            color: black,
            lineHeight: 1,
            fontWeight: fontWeightMedium,
            textTransform: 'none' as const
          },
          h6: {
            fontSize: fontSizes.h6,
            color: grey,
            lineHeight: 1,
            fontWeight: fontWeightRegular,
            textTransform: 'none' as const
          },
          subtitle1: {
            fontSize: fontSizes.subtitle1,
            color: grey,
            lineHeight,
            fontWeight: fontWeightMedium,
            textTransform: 'none' as const
          },
          subtitle2: {
            fontSize: fontSizes.subtitle2,
            color: grey,
            lineHeight,
            fontWeight: fontWeightMedium,
            textTransform: 'none' as const
          },
          body1: {
            fontSize: fontSizes.body1,
            lineHeight,
            fontWeight: fontWeightRegular,
            textTransform: 'none' as const
          },
          body2: {
            fontSize: fontSizes.body2,
            lineHeight,
            fontWeight: fontWeightRegular,
            textTransform: 'none' as const
          },
          button: {
            fontSize: fontSizes.button,
            lineHeight,
            letterSpacing,
            fontWeight: fontWeightMedium,
            textTransform: 'uppercase' as const
          },
          caption: {
            fontSize: fontSizes.caption,
            lineHeight,
            fontWeight: fontWeightRegular,
            textTransform: 'none' as const
          },
          overline: {
            fontSize: fontSizes.overline,
            lineHeight,
            letterSpacing,
            fontWeight: fontWeightMedium,
            textTransform: 'uppercase' as const
          }
        }
      } as ThemeOptions,
      options
    )
  )
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    boxShadow: {
      1: '3px 0px 12px -2px rgba(0,0,0,0.23)'
    }
    paintscout: {
      header: {
        height: {
          sm: string
          md: string
        }
      }
      fontScale: number
      palette: {
        primary: string
      }
    }
  }

  interface ThemeOptions {
    boxShadow?: Theme['boxShadow']
    paintscout?: {
      header?: {
        height?: {
          sm?: string
          md?: string
        }
      }
      fontScale: number
      palette?: {
        primary?: string
      }
    }
  }
}

type QuoteStatus =
  | 'sent'
  | 'created'
  | 'viewed'
  | 'accepted'
  | 'declined'
  | 'partial'
  | 'paid'
  | 'invoiced'
  | 'draft'
  | 'onHold'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    status: { [key in QuoteStatus]: string }
    accentBackground: Partial<TypeBackground>
    presentation: {
      sectionBorder: {
        focus: string
        hover: string
      }
      pageBorder: {
        hover: string
      }
    }
    onboarding: {
      main: string
    }
    stickybid: {
      main: string
    }
  }

  interface PaletteOptions {
    accentBackground?: Partial<TypeBackground>
    presentation?: {
      sectionBorder?: {
        focus?: string
        hover?: string
      }
      pageBorder?: {
        hover?: string
      }
    }
    onboarding?: {
      main?: string
    }
    stickybid?: {
      main?: string
    }
  }
}
