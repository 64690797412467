import React from 'react'
import type { PresentationPreview, PresentationStyle, QuoteFile, PresentationLibrary } from 'paintscout'
import type { Theme } from '@material-ui/core'
import type { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'

// Value of our presentation context
export interface PresentationContextValue {
  presentationPage?: string
  sidebarPage?: string
  isDirty?: boolean
  isSaving?: boolean
  isEditable?: boolean
  setIsDirty?: (value: boolean) => void
  setIsSaving?: (value: boolean) => void
  preview?: PresentationPreview
  setPreview?: (preview: PresentationPreview) => void
  focusedSection?: string
  setFocusedSection?: (section: string) => void
  presentationTheme?: Theme
  presentationStyle?: PresentationStyle
  setPresentationStyle?: (presentationStyle: PresentationStyle) => void
  presentationLibrary?: {
    addFilesToLibrary: (args: { files: QuoteFile[] }) => void
    library: PresentationLibrary
    saveLibrary: (args: { library: PresentationLibrary }) => void
  }
  addPages?: boolean
  setAddPages?: (addPages: boolean) => void
  autosaveDisabled?: boolean

  onPresentationPageChange?: (presentationPage: string) => any
  onSidebarPageChange?: (sidebarPage: string) => any
  useCart?: boolean
}

const typographyNullStyles = {
  color: '',
  fontFamily: '',
  fontSize: '',
  fontStyle: '',
  fontWeight: null,
  letterSpacing: '',
  textDecoration: '',
  textTransform: 'none' as TypographyStyleOptions['textTransform'],
  lineHeight: ''
}

export const PresentationContextInitValue: PresentationContextValue = {
  isDirty: false,
  isSaving: false,
  isEditable: false,
  preview: 'none',
  focusedSection: '',
  presentationStyle: {
    accentColor: '#000000',
    backgroundColor: '#FFFFFF',
    buttonColor: '#000000',
    textColor: '#000000',
    fontFamily: 'roboto, sans-serif',
    heading1: typographyNullStyles,
    heading2: typographyNullStyles,
    heading3: typographyNullStyles,
    body: typographyNullStyles
  },
  useCart: false,
  addPages: true,

  setAddPages: () => {},
  setIsDirty: () => {},
  setIsSaving: () => {},
  setPreview: () => {},
  setFocusedSection: () => {},
  onPresentationPageChange: () => {},
  onSidebarPageChange: () => {}
}

export const PresentationContext = React.createContext<PresentationContextValue>(PresentationContextInitValue)
