import type { Theme } from '@material-ui/core'
import { makeStyles, TableSortLabel } from '@material-ui/core'
import type { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell'
import MuiTableCell from '@material-ui/core/TableCell'
import React, { useContext } from 'react'
import { TableHeadContext } from './TableHead'

export interface TableCellProps extends Omit<MuiTableCellProps, 'component'> {
  component?: string | React.ComponentType
  /**
   * Adjusts the width of the cell to a standard size for cells that contain
   * buttons/checkboxes/etc
   */
  isControl?: boolean
  sortable?: boolean

  /**
   * Fades the text if it is not the active sort
   */
  fadeWhenInactive?: boolean
}

const useStyles = makeStyles<Theme, TableCellProps & { isHead?: boolean }>(
  (theme: Theme) => {
    return {
      root: ({ isHead, padding, isControl, sortable, sortDirection, fadeWhenInactive }) => {
        return {
          ...(isHead ? theme.typography.overline : theme.typography.body1),

          padding: theme.spacing(),

          width: isControl ? 24 : 'auto',
          borderBottom: 'none',
          userSelect: 'none',
          background: 'none',

          maxWidth: '20vw',
          textOverflow: 'ellipsis',
          overflow: 'hidden',

          // fade cell text if header
          color: fadeWhenInactive && !!sortDirection ? theme.palette.secondary.light : 'inherit',

          '&:first-child': {
            paddingLeft: 0
          },
          '&:last-child': {
            paddingRight: 0
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw'
          }
        }
      },

      sortLabel: {
        '&:hover,&:focus,&$sortActive': {
          color: theme.palette.grey[700],
          '& $sortIcon': {
            // color: `${theme.palette.secondary.main} !important`, // !important because i can't figure out the specificity for this when active
            height: theme.typography.pxToRem(12)
          }
        }
      },
      sortIcon: {
        color: '#fff'
      },
      sortActive: {
        color: '#fff'
      }
    }
  },
  {
    name: 'TableCell'
  }
)

function TableCell(props: TableCellProps) {
  const { isControl, sortable, fadeWhenInactive, ...rest } = props
  const { isHead } = useContext(TableHeadContext)
  const classes = useStyles({ ...props, isHead, isControl, fadeWhenInactive })

  const active = !!props.sortDirection

  return (
    <MuiTableCell component={'div' as any} {...rest} classes={{ root: classes.root }}>
      {isHead && sortable ? (
        <TableSortLabel
          active={active}
          classes={{
            root: classes.sortLabel,
            icon: classes.sortIcon,
            active: classes.sortActive
          }}
          direction={props.sortDirection as 'asc' | 'desc'}
        >
          {props.children}
        </TableSortLabel>
      ) : (
        props.children
      )}
    </MuiTableCell>
  )
}

export default TableCell
