import type { AreaSubstrate } from 'paintscout'
import type { Rate } from '../../../../options'
import convertCalculationType from './convert-calculation-type'
import convertRateType from './convert-rate-type'

export default function convertRate(areaSubstrate: AreaSubstrate): Rate {
  const calculationType = convertCalculationType(areaSubstrate.rate.calculation_type)
  const rateType = convertRateType(areaSubstrate.rate.rate_type)

  const rate = {
    quoteType: areaSubstrate.rate.int_ext,
    rateSection: areaSubstrate.rate.section,
    name: areaSubstrate.rate.name,
    coverage: areaSubstrate.rate.coverage,
    custom: !!areaSubstrate.rate.use_custom,
    label: areaSubstrate.rate.label,
    clientLabel: areaSubstrate.rate.client_label,
    reportText: areaSubstrate.rate.report_text ?? '<p></p>',
    areaReportText: areaSubstrate.rate.areaReportText ?? '<p></p>',
    rateType,
    calculationType,
    possibleApplications: areaSubstrate.rate.possible_applications,
    defaultApplications: areaSubstrate.rate.default_applications,
    defaultWidth: areaSubstrate.rate.default_width,
    defaultLength: areaSubstrate.rate.default_length,
    defaultHeight: areaSubstrate.rate.default_height,
    values: areaSubstrate.rate.values,
    useCoats: !!areaSubstrate.rate.useCoats
  }

  return rate
}
