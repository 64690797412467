import React from 'react'
import type { PresentationSection } from 'paintscout'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { StringOption, ButtonSelectOption } from '@ui/stickybid'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'

const useStyles = makeStyles<Theme, SpacerOptionsProps>(
  (theme: Theme) => {
    return {
      root: {}
    }
  },
  { name: 'SocialLinksOptions' }
)

interface SpacerOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
}

function SocialLinksOptions(props: SpacerOptionsProps) {
  const classes = useStyles(props)
  const { section, handleOptionFieldChange } = props
  const { fontSize, links, alignment } = section.fields

  const handleLinkChange = (value: string, index: number) => {
    handleOptionFieldChange(`fields.links[${index}].href`, value)
  }

  const Links = links.map((link, index) => {
    return (
      <StringOption
        key={link.label}
        label={link.label}
        value={link.href}
        onChange={(ev) => handleLinkChange(ev.target.value, index)}
      />
    )
  })

  const options = Links.map((Link) => {
    return {
      Component: Link,
      tab: 'content'
    }
  })

  options.push(
    {
      Component: (
        <ButtonSelectOption
          label={'Icon Size'}
          value={fontSize}
          buttons={[
            { icon: 'S', value: '2rem' },
            { icon: 'M', value: '3rem' },
            { icon: 'L', value: '4rem' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.fontSize', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Alignment'}
          value={alignment}
          buttons={[
            { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'flex-start' },
            { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
            { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'flex-end' },
            { icon: 'B', value: 'space-between' },
            { icon: 'A', value: 'space-around' },
            { icon: 'E', value: 'space-evenly' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.alignment', value)}
        />
      ),
      tab: 'content'
    }
  )

  return options
}

export default SocialLinksOptions
