import React from 'react'
import { makeStyles, Hidden } from '@material-ui/core'
import { EyeballToggle } from '@ui/stickybid'

const useStyles = makeStyles(
  (theme) => {
    return {
      root: {},
      button: {
        marginRight: theme.spacing(1),
        '& svg': {
          fontSize: '1.1rem'
        }
      }
    }
  },
  { name: 'AmountHeadColumn' }
)
export default function AmountHeadColumn({
  consumer,
  disabled,
  onChange,
  showAmount,
  isEditable,
  ...props
}: {
  consumer: string
  isEditable?: boolean
  showAmount?: boolean
  disabled?: boolean
  onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, checked: boolean) => void
}) {
  const classes = useStyles(props)

  const showSwitch = isEditable

  if (consumer === 'crew') {
    if (showSwitch) {
      return (
        <EyeballToggle
          className={classes.button}
          data-testid="amount-column-switch"
          disabled={disabled}
          checked={showAmount}
          onClick={(ev) => onChange(ev, !showAmount)}
          size={'small'}
        />
      )
    } else {
      return (
        <Hidden smDown implementation="css">
          Hours
        </Hidden>
      )
    }
  }

  if (consumer === 'customer') {
    if (showSwitch) {
      return (
        <EyeballToggle
          classes={{ toggleButton: classes.button }}
          data-testid="amount-column-switch"
          disabled={disabled}
          checked={showAmount}
          onClick={(ev) => onChange(ev, !showAmount)}
          size={'small'}
        />
      )
    } else {
      return (
        <Hidden smDown implementation="css">
          $
        </Hidden>
      )
    }
  }

  return null
}
