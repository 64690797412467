import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { ActionButtonProps as CoreActionButtonProps, Action } from '@ui/core/ActionButton'
import CoreActionButton from '@ui/core/ActionButton'
import PopupMenu from '../PopupMenu'
import MenuItem from '../MenuItem'

export type { Action }

export interface ActionButtonProps extends Omit<CoreActionButtonProps, 'PopupMenu' | 'MenuItem'> {}

const useStyles = makeStyles({
  root: {},
  button: {},
  menuItem: {}
})

function ActionButton({ disabled, actions, className, onActionClick, ...props }: ActionButtonProps) {
  return (
    <CoreActionButton
      PopupMenu={PopupMenu}
      MenuItem={MenuItem}
      disabled={disabled}
      actions={actions}
      className={className}
      onActionClick={onActionClick}
      {...props}
    />
  )
}

export default ActionButton
