import React from 'react'
import type { WithStyles } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Typography from '../Typography'

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pageLabel: {
      margin: '0 8px'
    }
  })

export interface TablePaginationProps extends WithStyles<typeof styles> {
  currentPage: number
  pages?: number
  disablePrevPage?: boolean
  disableNextPage?: boolean
  disableFirstPage?: boolean
  disableLastPage?: boolean

  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, page: number) => any
}

class TablePagination extends React.Component<TablePaginationProps> {
  public handlePrevButtonClick = (event) => {
    this.props.onChangePage(event, this.props.currentPage - 1)
  }

  public handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.currentPage + 1)
  }

  public handleFirstButtonClick = (event) => {
    this.props.onChangePage(event, 1)
  }

  public handleLastButtonClick = (event) => {
    this.props.onChangePage(event, this.props.pages)
  }

  public render() {
    const { classes, currentPage, pages, disablePrevPage, disableNextPage, disableFirstPage, disableLastPage } =
      this.props

    return (
      <div className={classes.root}>
        {/*<IconButton disabled={disableFirstPage} aria-label="Previous Page" onClick={this.handleFirstButtonClick}>
          <FirstPageIcon />
        </IconButton>*/}
        <IconButton disabled={disablePrevPage} aria-label="Previous Page" onClick={this.handlePrevButtonClick}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography className={classes.pageLabel} variant="subtitle2">
          Page {currentPage} {pages ? `of ${pages}` : ''}
        </Typography>
        <IconButton disabled={disableNextPage} aria-label="Next Page" onClick={this.handleNextButtonClick}>
          <KeyboardArrowRight />
        </IconButton>
        {/*<IconButton disabled={disableLastPage} aria-label="Next Page" onClick={this.handleLastButtonClick}>
          <LastPageIcon />
        </IconButton>*/}
      </div>
    )
  }
}

export default withStyles(styles)(TablePagination)
