import React from 'react'
import type { FieldProps } from 'formik'
import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'

export interface FormikInputFieldProps extends FieldProps<any>, Omit<InputFieldProps, 'form' | 'classes'> {
  valueDefault?: any
}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikInputField = ({ field, form, valueDefault, ...props }: FormikInputFieldProps) => {
  return <InputField {...field} {...props} value={field.value ?? valueDefault} />
}

export default FormikInputField
