import type { PresentationOption, PresentationPreview } from 'paintscout'
import React from 'react'

export interface PresentationNavContextValue {
  open?: boolean
  cartOpen?: boolean

  presentation?: PresentationOption
  preview?: PresentationPreview
  currentPage?: string
  currentSection?: string
  view?:
    | 'work-order'
    | 'product-order-form'
    | 'quote'
    | 'details'
    | 'notes'
    | 'activity'
    | 'terms'
    | 'presentation'
    | 'archived-items'

  onDismissDialog?: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void
  onOpen?: () => void
  onClose?: () => void

  onOpenCart?: () => void
  onCloseCart?: () => void

  onPageClick?: (ev: React.MouseEvent, pageKey: string) => void
  onSectionClick?: (ev: React.MouseEvent, pageKey: string) => void
  onAcceptClick?: (ev: React.MouseEvent, selectedOptionalItems?: string[], usePaymentCallback?: boolean) => void
  onPayClick?: (deposit?: boolean) => void
  onMoreClick?: (ev: React.MouseEvent, action: string) => void

  allowResponse?: boolean
  isCustomerView?: boolean
  isPreview?: boolean
  isPreviewAccept?: boolean
  isPreviewView?: boolean
  isQuoteEmpty?: boolean
  isDummy?: boolean

  /**
   * If customer is accepting/declining
   */
  isResponding?: boolean
  isSinglePage?: boolean
  popupMenu?: boolean
}

export const PresentationNavContextInitValue: PresentationNavContextValue = {
  open: false,
  cartOpen: false,

  currentPage: null,
  currentSection: null,
  view: 'quote',

  onOpen: () => {},
  onClose: () => {},

  onOpenCart: () => {},
  onCloseCart: () => {},

  onPageClick: () => {},
  onSectionClick: () => {},
  onAcceptClick: () => {},
  onPayClick: () => {},
  onMoreClick: () => {},

  allowResponse: false,
  isCustomerView: false,
  isPreviewAccept: false,
  isPreviewView: false,
  isSinglePage: false,
  isQuoteEmpty: false,
  isResponding: false,
  popupMenu: false
}

const PresentationNavContext = React.createContext<PresentationNavContextValue>(PresentationNavContextInitValue)

export default PresentationNavContext
