import { makeStyles } from '@material-ui/core'
import type { FollowUp } from '@paintscout/api'
import { uuid } from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import type { OptionsDocument } from 'paintscout'
import React from 'react'
import { ConfirmationDialog, Typography, Grid } from '..'
import Button from '../Button'
import FollowUpDialog from '../dialogs/FollowUpDialog'
import { useDialogs } from '../DialogStack'
import FollowUpTable from '../FollowUpTable'
import FormSection from '../FormSection'
import FormSectionTitle from '../FormSectionTitle'
import get from 'lodash/get'
import SwitchField from '../SwitchField'
import { useUser } from '@ui/core'
import { removeAuth0Prefix } from '@paintscout/util/users'

const useStyles = makeStyles((theme) => ({
  root: {},
  addressbookSection: {
    width: '100%',
    maxWidth: 600
  },
  templates: {
    marginTop: theme.typography.pxToRem(15)
  },
  formTitle: {
    marginBottom: -theme.spacing(2)
  },
  formSection: {
    marginTop: theme.spacing()
  }
}))

export interface UserFollowUpsFormProps {
  onDialogConfirm: () => void
  userId?: string
  type: 'client' | 'user'
}

export default function FollowUpsForm({ onDialogConfirm, type, userId: _userId }: UserFollowUpsFormProps) {
  const { values, setFieldValue } = useFormikContext<{ options: OptionsDocument }>()
  const { options } = values
  const { openDialog, dismissDialog } = useDialogs()
  const classes = useStyles()
  const title = type === 'client' ? 'Follow-Ups' : 'Custom Follow-Ups'
  const subtitle =
    type === 'client'
      ? 'Messages that are automatically sent to customers after a certain time period of inactivity'
      : 'Personalize your own automatic Follow-Up messages instead of using the defaults set by your company administrator'

  const { user } = useUser()
  // there won't be a user via admin.stickybid access, we either specify a userId (for user follow-ups) or omit it (for client follow-ups)
  const userId = removeAuth0Prefix(_userId || user?.user_id || '')

  const userFollowUpsEnabled = options.options.emails.followUps?.userFollowUps?.[userId]?.enabled
  const visibleType = type === 'user' && userFollowUpsEnabled ? 'user' : 'client'
  const disabled = type === 'user' && !userFollowUpsEnabled
  const path =
    visibleType === 'client'
      ? 'options.options.emails.followUps'
      : `options.options.emails.followUps.userFollowUps.${userId}`

  const followUpOrder: string[] = get(values, `${path}.order`)
  const followUpValues: { [key: string]: FollowUp } = get(values, `${path}.values`)

  function handleEdit(followUp: FollowUp) {
    openDialog(FollowUpDialog, {
      followUp,
      onConfirm: (followUp) => {
        setFieldValue(`${path}.values.${followUp.key}`, followUp)
        dismissDialog()
        onDialogConfirm()
      },
      onCancel: dismissDialog
    })
  }

  function handleToggle(followUp: FollowUp) {
    setFieldValue(`${path}.values.${followUp.key}.active`, !followUp.active)
  }

  function handleToggleSendText(followUp: FollowUp) {
    setFieldValue(`${path}.values.${followUp.key}.sendText`, !followUp.sendText)
  }

  function handleCopy(followUp: FollowUp) {
    const newKey = uuid()
    const index = followUpOrder.findIndex((o) => o === followUp.key)

    setFieldValue(`${path}.order`, [...followUpOrder.slice(0, index), newKey, ...followUpOrder.slice(index)])
    setFieldValue(`${path}.values.${newKey}`, {
      ...followUp,
      key: newKey
    })
    onDialogConfirm()
  }

  function handleDelete(followUp: FollowUp) {
    openDialog(ConfirmationDialog, {
      title: 'Are you sure?',
      message: `This Follow-Up will no longer be available`,
      onConfirm: () => {
        const updatedFollowUpValues = {
          ...followUpValues
        }

        delete updatedFollowUpValues[followUp.key]
        setFieldValue(`${path}.values`, updatedFollowUpValues)
        setFieldValue(
          `${path}.order`,
          followUpOrder.filter((o) => o !== followUp.key)
        )
        dismissDialog()
        onDialogConfirm()
      },
      onCancel: dismissDialog
    })
  }

  return (
    <div className={classes.root}>
      <FormSectionTitle title={title} variant={'h2'} classes={{ title: classes.formTitle }} />
      <Grid container spacing={2} style={{ marginBottom: 8 }} alignItems={'flex-end'} justifyContent={'flex-end'}>
        <Grid item xs={12} md style={{ marginBottom: 'auto' }}>
          <Typography variant={'subtitle1'}>{subtitle}</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Button
            disabled={disabled}
            prominence={2}
            onClick={() => {
              openDialog(FollowUpDialog, {
                onConfirm: (followUp) => {
                  const updatedFollowUpValues = {
                    ...(followUpValues ?? {}),
                    [followUp.key]: followUp
                  }

                  setFieldValue(`${path}.values`, updatedFollowUpValues)
                  setFieldValue(`${path}.order`, [...(followUpOrder ?? []), followUp.key])

                  onDialogConfirm()
                  dismissDialog()
                },
                onCancel: dismissDialog
              })
            }}
          >
            New Follow-Up
          </Button>
        </Grid>
        {type === 'user' && (
          <Grid item xs={12}>
            <SwitchField
              name={`options.options.emails.followUps.userFollowUps.${userId}.enabled`}
              label={`Enable Custom Follow-Ups`}
            />
          </Grid>
        )}
      </Grid>
      <FormSection className={classes.formSection}>
        <FollowUpTable
          disabled={disabled}
          settingsView
          followUps={followUpOrder?.map((key) => followUpValues[key]).filter(Boolean) || []}
          onAction={(action, followUp) => {
            switch (action) {
              case 'edit':
                handleEdit(followUp)
                break
              case 'copy':
                handleCopy(followUp)
                break
              case 'delete':
                handleDelete(followUp)
                break
              case 'toggle':
                handleToggle(followUp)
                break
              case 'toggleSendText':
                handleToggleSendText(followUp)
                break
            }
          }}
        />
      </FormSection>
    </div>
  )
}
