import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip'
import { default as MuiTooltip } from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/HelpOutline'
import Badge from '@material-ui/core/Badge'
import Typography from '../Typography'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      width: theme.typography.pxToRem(15),
      marginLeft: theme.typography.pxToRem(15),
      color: theme.palette.primary.main
    },
    popper: {
      opacity: 1
    },
    htmlTooltip: {
      backgroundColor: '#fff',
      opacity: 1,
      color: 'rgba(0, 0, 0, 1)',
      maxWidth: 350,
      border: '1px solid #dadde9',
      padding: 10,
      '& b': {
        fontWeight: theme.typography.fontWeightMedium
      },
      '& em': {
        fontStyle: 'italic'
      }
    },
    badgeRoot: {},
    badge: {
      top: '50%',
      right: -5,
      marginTop: -1
    },
    divRoot: {}
  })

export interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  disabled?: boolean
  title?: string
  content?: string | React.ReactNode
  component?: React.ReactNode
  timeout?: number
  autoOpen?: boolean
  icon?: React.ReactNode
  closeOnClick?: boolean
}

interface TooltipState {
  openTooltip: boolean
  autoOpen?: boolean
}

type TooltipPropsWithInjections = TooltipProps & WithStyles<typeof styles>

class Tooltips extends React.PureComponent<TooltipPropsWithInjections, TooltipState> {
  public static defaultProps: Partial<TooltipPropsWithInjections> = {
    placement: 'right'
  }

  constructor(props: any) {
    super(props)
    this.state = {
      openTooltip: false,
      autoOpen: this.props.autoOpen
    }
  }

  public componentDidMount() {
    if (this.state.autoOpen) {
      setTimeout(
        () => {
          this.setState({ openTooltip: true })
          setTimeout(() => {
            this.setState({ openTooltip: false })
          }, 5000)
        },
        this.props.timeout ? this.props.timeout : 5000
      )
    }
  }

  public handleMouseEnter() {
    this.setState({ openTooltip: true })
  }

  public handleMouseOut() {
    this.setState({ openTooltip: false })
  }

  public render() {
    const props = this.props
    const { children, component, classes, autoOpen, placement, title, content, disabled, closeOnClick } = props
    const { openTooltip } = this.state

    const icon = props.icon ? props.icon : <HelpIcon className={classes.icon} />

    const tooltipContent = (
      <>
        {title && <Typography variant={'h4'}>{title}</Typography>}
        {content && <Typography variant={'body2'}>{content}</Typography>}
      </>
    )
    return (
      <>
        {!autoOpen ? (
          <>
            {!disabled ? (
              <div
                className={classes.divRoot}
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onMouseLeave={this.handleMouseOut.bind(this)}
              >
                <MuiTooltip
                  title={tooltipContent}
                  open={openTooltip}
                  placement={placement}
                  classes={{ tooltip: classes.htmlTooltip, popper: classes.popper }}
                  onClick={closeOnClick ? this.handleMouseOut.bind(this) : undefined}
                >
                  {component && !children ? (
                    <Badge
                      badgeContent={icon}
                      onClick={() => {
                        this.setState({ openTooltip: !this.state.openTooltip })
                      }}
                      classes={{ root: classes.badgeRoot, badge: classes.badge }}
                    >
                      {component}
                    </Badge>
                  ) : (
                    <Badge badgeContent={icon} classes={{ root: classes.badgeRoot, badge: classes.badge }}>
                      {children}
                    </Badge>
                  )}
                </MuiTooltip>
              </div>
            ) : (
              <div>{children}</div>
            )}
          </>
        ) : (
          <>
            {!disabled ? (
              <MuiTooltip
                title={tooltipContent}
                placement={placement}
                classes={{ tooltip: classes.htmlTooltip, popper: classes.popper }}
              >
                {component && !children ? (
                  <>{component}</>
                ) : (
                  <Badge badgeContent={icon} classes={{ root: classes.badgeRoot, badge: classes.badge }}>
                    <div>{children}</div>
                  </Badge>
                )}
              </MuiTooltip>
            ) : (
              <div>{children}</div>
            )}
          </>
        )}
      </>
    )
  }
}

export default withStyles(styles)(Tooltips)
