import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import NotesIcon from '@material-ui/icons/Notes'
import ListIcon from '@material-ui/icons/List'
import React, { useContext } from 'react'
import { useClientOptions } from '@ui/stickybid'
import { Typography } from '@ui/stickybid'
import type { RenderableItem, QuoteItemSection } from '@paintscout/util/builder'
import { getItems, getObjectLabels } from '@paintscout/util/builder'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemColumn, ItemTableCell } from '../../../../ItemTable'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    noResults: {
      textAlign: 'center',
      margin: theme.spacing(4)
    }
  })

export interface ArchivedTableProps {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
}

type ArchivedTablePropsWithInjections = ArchivedTableProps & WithStyles<typeof styles>

function ArchivedTable({ classes, ...itemTableProps }: ArchivedTablePropsWithInjections) {
  const { quote, isEditable, tableView, onEditItem, onItemAction } = useContext(QuoteContext)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const items = getItems({
    quote,
    section: 'archived',
    view: tableView,
    consumer: 'customer',
    options,
    overrideOptions: { showPrices: true }
  }).map((item) => {
    if (!item.description && !item.value && item.subItems && item.subItems.length > 0) {
      const firstSubItem = item.subItems.shift()
      return {
        ...item,
        description: { useCustom: false, default: firstSubItem.description, custom: '' },
        value: firstSubItem.value
      }
    }

    return item
  })

  function handleItemAction(action: string, section: QuoteItemSection, item: RenderableItem) {
    if (action === 'edit') {
      onEditItem(item.key)
    } else {
      onItemAction(action, section, [item.key])
    }
  }

  return (
    <div>
      {items && items.length > 0 ? (
        <ItemTable
          items={items}
          checkboxes={isEditable}
          draggable={false}
          showAddButton={false}
          onItemClick={isEditable ? (ev, item) => onEditItem(item.key) : null}
          renderHeadColumns={() => (
            <>
              <ItemTableCell>Item</ItemTableCell>
              <ItemTableCell align="right">$</ItemTableCell>

              {/* action column */}
              {isEditable && <ItemTableCell />}
            </>
          )}
          renderRowColumns={(item: RenderableItem) => {
            const actions = []
            actions.push({
              key: 'edit',
              label: 'Edit',
              icon: EditIcon
            })
            if (item.type === 'area') {
              actions.push({
                key: 'edit-text',
                label: 'Edit Text',
                icon: NotesIcon
              })
            }
            if (!quote.is_invoice) {
              actions.push({
                key: 'move-to-bid',
                label: `Move to ${objectLabels.quote.value}`,
                icon: AddBoxIcon
              })
            }

            actions.push({
              key: 'make-option',
              label: `Make ${objectLabels.option.value}`,
              icon: AddBoxIcon
            })

            if (item.type !== 'group') {
              actions.push({
                key: 'copy',
                label: 'Copy',
                icon: CopyIcon
              })
              if (tableView === 'area') {
                actions.push({
                  key: 'delete',
                  label: 'Delete',
                  icon: DeleteIcon
                })
              }
            }

            if (item.type === 'group') {
              actions.push({
                key: 'ungroup',
                label: 'Ungroup',
                icon: ListIcon
              })
            }
            return (
              <>
                <ItemTableCell colSpan={2}>
                  <ItemColumn item={item} consumer={'customer'} isEditable={isEditable} showAmount />
                </ItemTableCell>
                {isEditable && (
                  <ItemTableCell cancelDrag isControl>
                    <ActionColumn
                      onActionClick={(ev, action) => handleItemAction(action, 'archived', item)}
                      actions={actions}
                    />
                  </ItemTableCell>
                )}
              </>
            )
          }}
          {...itemTableProps}
        />
      ) : (
        <div className={classes.noResults}>
          <Typography variant="h4">
            There are no archived items on this {objectLabels.quote.value.toLowerCase()}.
          </Typography>
          <Typography variant="subtitle1">
            Items can be archived by selecting "Archive" from the Item Action menu.
          </Typography>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(ArchivedTable)
