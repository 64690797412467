import type { QuoteArea, QuoteDocument } from 'paintscout'
import { add } from '../util/add'
import { parseNumber } from '../util/parse-number'
import { getItemSection } from '../../builder/quote/util/get-item-section'

import clone from 'lodash/clone'
import find from 'lodash/find'
import get from 'lodash/get'
import { getAreaSubstrateKey } from '../../builder/quote/area-substrates/get-area-substrate-key'
import allSame from '../util/all-same'

export function areaTotals(area: QuoteArea, quote: QuoteDocument): QuoteArea {
  if (!area.substrates) {
    return area
  }

  const updatedArea = clone(area)

  let overridePrice
  let overridePriceValue
  let overrideMaterials
  let overrideMaterialsValue
  let overrideHours
  let overrideHoursValue
  let overridePrep
  let overridePrepValue

  if (updatedArea.totals && updatedArea.totals.override_hours_value) {
    overrideHoursValue = parseNumber(updatedArea.totals.override_hours_value)
    overrideHours = !!updatedArea.totals.override_hours
  }
  if (updatedArea.totals && updatedArea.totals.override_prep_value) {
    overridePrepValue = parseNumber(updatedArea.totals.override_prep_value)
    overridePrep = !!updatedArea.totals.override_prep
  }

  if (updatedArea.totals && updatedArea.totals.override_price_value) {
    overridePriceValue = parseNumber(updatedArea.totals.override_price_value)
    overridePrice = !!updatedArea.totals.override_price
  }
  if (updatedArea.totals && updatedArea.totals.override_materials_value) {
    overrideMaterialsValue = parseNumber(updatedArea.totals.override_materials_value)
    overrideMaterials = !!updatedArea.totals.override_materials
  }

  updatedArea.totals = {} as any
  let pending = { visiblePrice: 0 } as any
  let additionalWork = { visiblePrice: 0 } as any
  const substrateSections: string[] = []

  Object.keys(updatedArea.substrates).forEach((rateSection: string) => {
    updatedArea.totals = add(
      updatedArea.totals,
      updatedArea.substrates[rateSection].reduce(
        (totals, substrate) => {
          if (substrate._deleted) {
            return totals
          }

          const rateKey = getAreaSubstrateKey(quote, substrate)
          const label = get(substrate, 'rate.client_label', get(substrate, 'rate.label', 'Custom'))
          const substrateSection = getItemSection(substrate)
          // this is the case where an area AND one of it's children are made options/etc separately.
          const includeBidPrice = substrate.use_total || getItemSection(area) === substrateSection

          const prep = includeBidPrice || substrate.additionalWork ? parseNumber(substrate.prep) : 0

          let hours = 0
          if (substrate.show_crew !== false && (includeBidPrice || substrate.additionalWork)) {
            let substrateHours = substrate.hours
            if (substrate.override_hours && typeof substrate.override_hours_value !== 'undefined') {
              substrateHours = substrate.override_hours_value
            } else if (typeof substrate.default_hours !== 'undefined') {
              substrateHours = substrate.default_hours
            }

            hours = parseNumber(substrateHours)
          }

          if (quote && quote.order && quote.order.substrate && quote.substrates[rateKey]) {
            const substrateOrderItem = find(quote.order.substrate, { key: rateKey })
            if (substrateOrderItem && substrateOrderItem.parent) {
              totals = add(
                totals,
                {
                  hours,
                  prep
                },
                quote
              )
              return totals
            }
          }

          let price = substrate.price
          if (substrate.override_price && typeof substrate.override_price_value !== 'undefined') {
            price = substrate.override_price_value
          } else if (typeof substrate.default_price !== 'undefined') {
            price = substrate.default_price
          }

          const visiblePrice = substrate.show_price || !includeBidPrice ? 0 : parseNumber(price)

          let materials = 0
          if (substrate.product) {
            if (
              substrate.product.totalPrice_override &&
              typeof substrate.product.totalPrice_override_value !== 'undefined' &&
              substrate.product.totalPrice_override_value !== null
            ) {
              materials = substrate.product.totalPrice_override_value ? substrate.product.totalPrice_override_value : 0
            } else {
              materials = substrate.product.totalPrice
            }
          }

          materials = Math.round((materials + Number.EPSILON) * 100) / 100

          const addedTotals = {
            hours,
            prep,

            price: includeBidPrice ? price : 0,
            materials: includeBidPrice ? materials : 0,
            visiblePrice
          }

          if (substrate.pending) {
            pending = add(pending, { hours, prep, price, materials, visiblePrice }, quote)
          } else if (substrate.additionalWork) {
            additionalWork = add(additionalWork, { hours, prep, price, materials, visiblePrice }, quote)
          } else {
            totals = add(totals, addedTotals, quote)
          }
          substrateSections.push(substrateSection)
          return totals
        },
        {
          hours: 0,
          prep: 0,
          price: 0,
          visiblePrice: 0,
          materials: 0,
          pending: { hours: 0, prep: 0, price: 0, visiblePrice: 0, materials: 0 },
          additionalWork: { hours: 0, prep: 0, price: 0, visiblePrice: 0, materials: 0 }
        }
      ),
      quote
    )
  })

  updatedArea.totals.pending = pending
  updatedArea.totals.additionalWork = additionalWork

  updatedArea.totals.override_hours = overrideHours
  updatedArea.totals.override_hours_value = overrideHoursValue

  updatedArea.totals.override_prep = overridePrep
  updatedArea.totals.override_prep_value = overridePrepValue

  if (updatedArea.totals.override_prep || updatedArea.totals.override_hours) {
    const hours = updatedArea.totals.override_hours
      ? parseNumber(updatedArea.totals.override_hours_value)
      : updatedArea.totals.hours
    const prep = updatedArea.totals.override_prep
      ? parseNumber(updatedArea.totals.override_prep_value)
      : updatedArea.totals.prep
    updatedArea.totals.price = (hours + prep) * quote.hourly_rate
  }

  updatedArea.totals.override_materials = overrideMaterials
  updatedArea.totals.override_materials_value = overrideMaterialsValue

  updatedArea.totals.override_price = overridePrice
  updatedArea.totals.override_price_value = overridePriceValue

  // If all substrates in one section, we want to make sure if area is additional or pending, that
  // overrriden valus get passed to those totals so it is calculated correctly
  if (quote?.version >= 3 && allSame(substrateSections) && ['additional', 'pending'].includes(substrateSections[0])) {
    if (substrateSections[0] === 'additional') {
      updatedArea.totals.additionalWork = {
        ...updatedArea.totals.additionalWork,
        hours: overrideHours ? overrideHoursValue : updatedArea.totals.additionalWork.hours,
        materials: overrideMaterials ? overrideMaterialsValue : updatedArea.totals.additionalWork.materials,
        prep: overridePrep ? overridePrepValue : updatedArea.totals.additionalWork.prep,
        price: overridePriceValue ? overridePriceValue : updatedArea.totals.additionalWork.price
      }
    } else {
      updatedArea.totals.pending = {
        ...updatedArea.totals.pending,
        hours: overrideHours ? overrideHoursValue : updatedArea.totals.pending.hours,
        materials: overrideMaterials ? overrideMaterialsValue : updatedArea.totals.pending.materials,
        prep: overridePrep ? overridePrepValue : updatedArea.totals.pending.prep,
        price: overridePriceValue ? overridePriceValue : updatedArea.totals.pending.price
      }
    }
  }

  return updatedArea
}
