import React from 'react'
import type { QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../..'
import { InlineEditableHtml, InlineEditableMedia } from '../../../inlineEditable'
import compareSectionProps from '../../util/compareSectionProps'
import classnames from 'classnames'
import { getSectionPadding } from '../../util'
import AnimationWrapper from '../../util/AnimationWrapper'
import { usePresentation } from '../../../context'

const useStyles = makeStyles<
  Theme,
  PresentationSectionProps & { useGlassEffect?: boolean; textBackgroundColor?: boolean; noBackground?: boolean }
>(
  (theme: Theme) => {
    return {
      root: {},
      content: (
        props,
        color = props.section?.fields?.layoutOptions?.[props.section?.fields?.layout ?? 'poster']?.color ?? ''
      ) => ({
        ...(color && {
          textShadow:
            !props.section?.fields?.layout || props.section?.fields?.layout === 'poster' ? `0 0 9px black` : 'none',
          '& h1, & h2, & h3, & p, & ol, & ul': {
            color: `${color} !important`
          }
        })
      }),
      posterWrapper: {
        position: 'relative',
        overflow: 'hidden'
      },
      posterImageWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      },
      posterContentWrapper: (props) => ({
        padding: getSectionPadding({ section: props.section, source: 'section', size: 'desktop', contentWidth: 'md' }),
        position: 'relative',
        zIndex: 1,
        margin: theme.spacing(10, 0),
        [theme.breakpoints.down('md')]: {
          padding: getSectionPadding({ section: props.section, source: 'section', size: 'tablet', contentWidth: 'md' })
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(7, 0),
          padding: getSectionPadding({ section: props.section, source: 'section', size: 'mobile', contentWidth: 'md' })
        }
      }),
      fullSize: {
        height: '100%',
        width: '100%'
      },
      collageWrapper: (
        props,
        imagePosition = props.section?.fields?.layoutOptions?.collage?.imagePosition,
        centeredImage = imagePosition === 'top' || imagePosition === 'bottom'
      ) => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gridTemplateRows: `repeat(8, 1fr) auto`,
        ...(centeredImage && {
          // 56px is equal to content width md padding
          gridTemplateColumns: '1fr',
          gridTemplateRows: `1fr ${theme.spacing(18)}px auto`
        }),
        [theme.breakpoints.down('xs')]: {
          display: 'block'
        }
      }),
      collageImageWrapper: (
        props,
        imagePosition = props.section?.fields?.layoutOptions?.collage?.imagePosition,
        verticalAlign = props.section?.fields?.layoutOptions?.collage?.verticalAlign ?? 'start',
        centeredImage = imagePosition === 'top' || imagePosition === 'bottom'
      ) => ({
        alignSelf: verticalAlign,
        gridColumn: getGridPosition({ imagePosition, verticalAlign, size: 'desktop' }).image.gridColumn,
        gridRow: getGridPosition({ imagePosition, verticalAlign, size: 'desktop' }).image.gridRow,
        [theme.breakpoints.down('md')]: {
          gridColumn: getGridPosition({ imagePosition, verticalAlign, size: 'tablet' }).image.gridColumn
        },
        ...(centeredImage && {
          margin: getSectionPadding({
            section: props.section,
            source: 'section',
            size: 'desktop',
            contentWidth: 'md',
            horizontalOnly: true
          }),
          [theme.breakpoints.down('md')]: {
            margin: getSectionPadding({
              section: props.section,
              source: 'section',
              size: 'tablet',
              contentWidth: 'md',
              horizontalOnly: true
            })
          },
          [theme.breakpoints.down('sm')]: {
            margin: getSectionPadding({
              section: props.section,
              source: 'section',
              size: 'mobile',
              contentWidth: 'md',
              horizontalOnly: true
            })
          },
          [theme.breakpoints.down('xs')]: {
            margin: '0 !important'
          },
          zIndex: 1
        })
      }),
      collageImage: {
        [theme.breakpoints.down('xs')]: {
          clipPath: 'none !important'
        }
      },
      collageContentWrapper: (
        props,
        useGlassEffect = props.useGlassEffect,
        textBackgroundColor = props.textBackgroundColor,
        noBackground = props.noBackground,
        imagePosition = props.section?.fields?.layoutOptions?.collage?.imagePosition,
        verticalAlign = props.section?.fields?.layoutOptions?.collage?.verticalAlign ?? 'start',
        centeredImage = imagePosition === 'top' || imagePosition === 'bottom',
        inset = !!props.section?.fields?.contentWidth && props.section?.fields?.contentWidth !== 'full'
      ) => ({
        // padding in this section is equal to content width padding
        alignSelf: verticalAlign,
        transition: 'all .3s ease',
        ...(!noBackground && {
          backgroundColor: useGlassEffect ? 'rgba(255, 255, 255, .25)' : textBackgroundColor,
          backdropFilter: useGlassEffect ? 'blur(5px) saturate(80%) contrast(80%) brightness(130%)' : '',
          padding: theme.spacing(imagePosition === 'top' ? 24 : 7, 7, imagePosition === 'bottom' ? 24 : 7, 7),
          ...(!centeredImage && { zIndex: 1 })
        }),
        ...(noBackground && {
          backgroundColor: 'transparent',
          backdropFilter: 'none',
          padding: theme.spacing(0, 7),
          zIndex: 2
        }),
        // border: useGlassEffect ? '1px solid rgba(215, 215, 215, .6)' : 'none',
        gridColumn: getGridPosition({ imagePosition, verticalAlign, size: 'desktop' }).content.gridColumn,
        gridRow: getGridPosition({ imagePosition, verticalAlign, size: 'desktop' }).content.gridRow,
        [theme.breakpoints.down('md')]: {
          gridColumn: getGridPosition({ imagePosition, verticalAlign, size: 'tablet' }).content.gridColumn,
          ...(!noBackground && {
            padding: theme.spacing(imagePosition === 'top' ? 22 : 4, 4, imagePosition === 'bottom' ? 22 : 4, 4)
          }),
          ...(noBackground && {
            padding: theme.spacing(0, 4)
          })
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(imagePosition === 'top' ? 22 : 4, 2, imagePosition === 'bottom' ? 22 : 4, 2)
        },
        [theme.breakpoints.down('xs')]: {
          ...(!noBackground && {
            padding: theme.spacing(2)
          }),
          ...(noBackground && {
            padding: theme.spacing(2, inset ? 0 : 2)
          })
        }
      })
    }
  },
  { name: 'BannerSection' }
)

function BannerSection(props: PresentationSectionProps) {
  const {
    isEditable,
    isSettings,
    presentationIndustry,
    presetColors,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    WrapperComponent
  } = props
  const useGlassEffect =
    section?.fields?.layoutOptions?.collage?.useGlassEffect && CSS.supports('backdrop-filter', 'blur(4px)')
  const textBackgroundColor = section?.fields?.layoutOptions?.collage?.textBackgroundColor
  const noBackground = section?.fields.layoutOptions?.collage?.noBackground ?? false
  const classes = useStyles({ useGlassEffect, textBackgroundColor, noBackground, ...props })
  const { presentationLibrary } = usePresentation()

  const { animation, content, image, layout = 'poster' } = section.fields
  const isAnimated = ['slide', 'fade', 'zoom'].includes(animation)

  const imagePosition = props.section?.fields?.layoutOptions?.collage?.imagePosition
  const allowVideo = layout === 'collage' && ['top', 'bottom'].includes(imagePosition)

  const customColor = section.fields?.layoutOptions?.[props.section?.fields?.layout ?? 'poster']?.color

  const handleEditField = (field: string, content: string | QuoteFile) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  const imageWrapperClass = classnames({
    [classes.posterImageWrapper]: layout === 'poster',
    [classes.collageImageWrapper]: layout === 'collage'
  })

  const contentWrapperClass = classnames({
    [classes.posterContentWrapper]: layout === 'poster',
    [classes.collageContentWrapper]: layout === 'collage',
    ['themeBackground']: layout === 'collage' && !useGlassEffect && !textBackgroundColor && !noBackground
  })

  const mediaRootClass = classnames({
    [classes.fullSize]: layout === 'poster'
  })

  const mediaImageClass = classnames({
    [classes.fullSize]: layout === 'poster',
    [classes.collageImage]: layout === 'collage'
  })

  const mediaSizeClass = classnames({
    [classes.fullSize]: layout === 'poster'
  })

  return (
    <WrapperComponent {...props}>
      <div
        className={classnames({
          [classes.posterWrapper]: layout === 'poster',
          [classes.collageWrapper]: layout === 'collage'
        })}
      >
        <AnimationWrapper className={imageWrapperClass} animation={animation} index={0} direction={'right'}>
          <InlineEditableMedia
            mediaOptionsProps={{
              sectionPrefix: sectionPrefix,
              fieldSuffix: 'image'
            }}
            accept={allowVideo ? 'video/*,image/*' : 'image/*'}
            classes={{ root: mediaRootClass, image: mediaImageClass, size: mediaSizeClass }}
            file={image}
            getFocusFromSection={layout === 'poster'}
            handleEdit={(file) => handleEditField('fields.image', file)}
            hasRightSideMenus={layout === 'collage' && imagePosition === 'right' ? true : false}
            isAnimated={isAnimated}
            isEditable={isEditable}
            isSettings={isSettings}
            placeholderButtonText={'Add Image'}
            presentationIndustry={presentationIndustry}
            presentationLibrary={presentationLibrary}
            youtube={allowVideo}
          />
        </AnimationWrapper>
        <AnimationWrapper
          className={contentWrapperClass}
          active={layout !== 'poster'}
          animation={animation}
          index={1}
          direction={'bottom'}
        >
          <AnimationWrapper active={layout === 'poster'} animation={animation} index={1} direction={'bottom'}>
            <InlineEditableHtml
              classes={{ root: classnames({ [classes.content]: !!customColor }) }}
              content={content}
              handleEdit={(content: string) => handleEditField('fields.content', content)}
              isEditable={isEditable}
              isSettings={isSettings}
              presetColors={presetColors}
            />
          </AnimationWrapper>
        </AnimationWrapper>
      </div>
    </WrapperComponent>
  )
}

function getGridRow(verticalAlign: string) {
  if (verticalAlign === 'start') return '2 / -1'
  if (verticalAlign === 'center') return '1 / -1'
  if (verticalAlign === 'end') return '1 / 8'
}

function getGridPosition(args: {
  imagePosition: string
  verticalAlign: string
  size: 'desktop' | 'tablet' | 'mobile'
}) {
  const { imagePosition, verticalAlign, size } = args
  // default left
  let image = { gridColumn: '1 / 7', gridRow: '1 / -1' }
  let content = { gridColumn: '5 / -1', gridRow: getGridRow(verticalAlign) }

  if (imagePosition === 'bottom') {
    image = { gridColumn: '1 / -1', gridRow: '2 / -1' }
    content = { gridColumn: '1 / -1', gridRow: '1 / 3' }
  }

  if (imagePosition === 'top') {
    image = { gridColumn: '1 / -1', gridRow: '1 / 3' }
    content = { gridColumn: '1 / -1', gridRow: '2 / -1' }
  }

  if (imagePosition === 'right') {
    image = { gridColumn: '3 / -1', gridRow: '1 / -1' }
    content = { gridColumn: '1 / 5', gridRow: getGridRow(verticalAlign) }
    if (size === 'tablet') {
      content.gridColumn = '1 / 5'
      image.gridColumn = '2 / -1'
    }
    if (size === 'mobile') {
      image = { gridColumn: '1 / -1', gridRow: '1 / 3' }
      content = { gridColumn: '1 / -1', gridRow: '2 / -1' }
    }
  }

  if (imagePosition === 'left') {
    image = { gridColumn: '1 / 7', gridRow: '1 / -1' }
    content = { gridColumn: '5 / -1', gridRow: getGridRow(verticalAlign) }
    if (size === 'tablet') {
      content.gridColumn = '5 / -1'
      image.gridColumn = '1 / 8'
    }
    if (size === 'mobile') {
      image = { gridColumn: '1 / -1', gridRow: '1 / 3' }
      content = { gridColumn: '1 / -1', gridRow: '2 / -1' }
    }
  }

  return { image, content }
}

export default React.memo(BannerSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
