import type { OptionsDocument, QuoteDocument } from 'paintscout'
import clone from 'lodash/clone'
import isPlainObject from 'lodash/isPlainObject'

import { areaDimensions, areaTotals } from './areas'
import { areaSubstrates } from './area-substrates'
import { calculateLineItem } from './line-items'
// import { calculateGroup } from './groups'
import { substrateTotals } from './substrates'
import { quoteTotals } from './totals'
import { getGroupDetails } from '../builder/quote/groups/get-group-details/get-group-details'

export function calculate(
  quote: QuoteDocument,
  options: OptionsDocument,
  selectedOptionalItems?: string[]
): QuoteDocument {
  const updatedQuote = clone(quote)

  if (updatedQuote.areas) {
    Object.keys(updatedQuote.areas).forEach((areaKey) => {
      let updatedArea = clone(updatedQuote.areas[areaKey])

      if (!isPlainObject(updatedArea)) {
        return
      }

      updatedArea.dimensions = areaDimensions(updatedArea)

      updatedArea.substrates = areaSubstrates(updatedArea, updatedQuote, options)
      updatedArea = areaTotals(updatedArea, updatedQuote)

      updatedQuote.areas[areaKey] = updatedArea
    })
  }

  if (updatedQuote.lineItems) {
    Object.keys(updatedQuote.lineItems).forEach((lineItemKey) => {
      let updatedLineItem = clone(updatedQuote.lineItems[lineItemKey])
      updatedLineItem = calculateLineItem(updatedQuote, updatedLineItem)
      updatedQuote.lineItems[lineItemKey] = updatedLineItem
    })
  }

  if (updatedQuote.groups) {
    Object.keys(updatedQuote.groups).forEach((groupKey) => {
      const group = updatedQuote.groups[groupKey]
      const { price } = getGroupDetails({
        quote: updatedQuote,
        options,
        keys: group.children,
        section: group.section
      })
      updatedQuote.groups[groupKey] = {
        ...updatedQuote.groups[groupKey],
        price: {
          ...updatedQuote.groups[groupKey].price,
          // default: calculateGroup(updatedQuote, groupKey)
          default: price
        }
      }
    })
  }

  updatedQuote.substrates = substrateTotals(updatedQuote)

  if (!updatedQuote.totals) {
    updatedQuote.totals = {
      show_total: true,
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0
    }
  }

  updatedQuote.totals = quoteTotals(updatedQuote, options, selectedOptionalItems)

  return updatedQuote
}
