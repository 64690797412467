import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const Divider = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 15 2'} {...props}>
    <svg>
      <path d="M0.528 0.594849C0.528 0.318706 0.751857 0.0948486 1.028 0.0948486H14.327C14.6031 0.0948486 14.827 0.318706 14.827 0.594849C14.827 0.870991 14.6031 1.09485 14.327 1.09485H1.028C0.751857 1.09485 0.528 0.870991 0.528 0.594849Z" />
    </svg>
  </SvgIcon>
)
export default Divider
