import React, { useEffect, useState } from 'react'
import type { SvgIconProps, Theme } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { Box, makeStyles, Tooltip, Typography, Hidden } from '@material-ui/core'
import { motion } from 'framer-motion'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import type { Action } from '../ActionButton'
import Button from '../Button'
import type { TileProps } from '../Tile'
import Tile from '../Tile'
import Image from '../FilePreview/Image'
import { OnboardingPopper, useOnboardingContext } from '../OnboardingContext'
import { Transformation } from 'cloudinary-react'
import CheckmarkIcon from '@material-ui/icons/Check'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import Add from '@material-ui/icons/Add'
import classnames from 'classnames'

const MotionButton = motion(Button)

export interface TileItem {
  key: string | number
  label: string
  icon?: React.ComponentType<SvgIconProps>
  sublabel?: string
  Thumbnail?: React.FunctionComponent
  thumbnailId?: string
  active?: boolean
  disabled?: boolean

  /**
   * This item can only be selected on its own. It will deselect any other
   * items when selected
   */
  individual?: boolean
}

type ValueType = string | number

type TileListVariants = 'standard' | 'select' | 'multiselect'
export interface TileListProps<
  T extends TileItem,
  Variant extends TileListVariants,
  Value extends Variant extends 'multiselect' ? ValueType[] : ValueType
> {
  classes?: StyleClasses<typeof useStyles>

  /**
   * Title of section of tiles
   */
  title?: string

  /**
   * Subtitle of section of tiles
   */
  subtitle?: string

  /**
   * Tile items
   */
  items?: T[]

  /**
   * Use dense variant of Tile components
   */
  dense?: boolean

  /**
   * don't allow the selections to be unselected, only switched
   */
  disableUnselect?: boolean

  /**
   * Disable inactive tiles
   */
  disableInactive?: boolean

  /**
   * Disables all tiles
   */
  disabled?: boolean

  /**
   * Variants of the TileList for behaviour
   *
   * standard - show all tiles, clicking on them fires onSelect event
   * select - a tile is selected and the rest are hidden
   * multiselect - multiple tiles are selected and the rest is hidden afterwards
   */
  variant?: 'standard' | 'select' | 'multiselect'

  /**
   * Selected item keys. Needs to be an array if multiSelect is true
   */
  selected?: Value

  /**
   * Field to use in item for the tile sublabel
   */
  subLabelField?: string

  /**
   * Action button items
   */
  actions?: ((tile: TileItem) => Action[]) | Action[]

  /**
   * Enable custom tile
   */
  customTile?:
    | boolean
    | {
        title?: string
        selected?: boolean
      }

  /**
   * Show Create button with the given title
   */
  createButton?: string

  /**
   * Always show unselected tiles
   */
  showUnselected?: boolean

  showActive?: boolean
  noItemTitle?: string
  noItemHelpText?: string

  draggable?: boolean
  dragAxis?: 'x' | 'y' | 'xy'

  /**
   * When a tile is selected
   */
  onSelect?: (event: any, selected: Value) => void

  /**
   * When the custom tile is selected
   */
  onCustom?: (event: any, setSelecting: (selecting: boolean) => any) => any

  /**
   * When the create button is clicked
   */
  onCreate?: (event: any) => any

  /**
   * When an action on a tile is clicked
   */
  onActionClick?: (event: React.MouseEvent<HTMLElement>, actionName: string, rowId: string | number) => void

  /**
   * When a tile is drag and dropped
   */
  onReorder?: (items: T[]) => any

  hasSelectedIcon?: boolean
  useThumbnails?: boolean
}

const useStyles = makeStyles<Theme, TileListProps<TileItem, TileListVariants, ValueType[] | ValueType>>(
  (theme) => ({
    root: {},
    dragging: {
      zIndex: 2147483002
    },
    tiles: {
      position: 'relative',
      display: 'grid',
      gridGap: theme.spacing(),
      justifyContent: 'space-between',
      alignItems: 'center',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    selectedRow: {
      display: 'grid',
      justifyContent: 'space-between',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center'
    },
    changeButton: {
      marginLeft: 20,
      width: '50%',
      minWidth: 200,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 'auto'
      }
    },
    title: {
      ...theme.typography.overline,
      display: 'block',
      marginBottom: 5
    },
    subtitle: {
      marginBottom: 10
    },
    tile: {
      width: '100%',
      height: '104px',
      maxWidth: 'none'
    },
    subLabel: {
      // fontWeight: theme.typography.fontWeightRegular
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(0.5)
    },
    selectedSubLabel: {
      // ...theme.typography.body2,
      color: theme.palette.primary.contrastText,
      marginTop: theme.spacing(0.5)
    },
    label: {
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
        marginLeft: 8
      }
    },
    smallLabel: {
      fontSize: '0.9rem'
    },
    tinyLabel: {
      fontSize: '0.8rem'
    },
    thumbnailPlaceholder: {
      backgroundColor: theme.palette.grey[300],
      borderRadius: theme.borderRadius.md,
      height: '100%',
      width: '100%'
    },
    addButton: {
      height: '100%',
      minHeight: theme.typography.pxToRem(56),
      borderRadius: theme.borderRadius.md
    },
    helpText: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.grey[500]
    },
    titleBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1)
    }
  }),
  { name: 'TileList' }
)

/**
 * A TileList component that can support multi select.
 *
 * It does not support all features that TileList does. Soon, we should use this
 * as the basis for the TileList rewrite.
 */
export default function TileList<
  T extends TileItem,
  Variant extends TileListVariants,
  Value extends Variant extends 'multiselect' ? ValueType[] : ValueType
>({
  actions,
  createButton,
  customTile,
  dense,
  disabled,
  disableInactive,
  disableUnselect,
  dragAxis = 'xy',
  draggable,
  hasSelectedIcon,
  noItemHelpText,
  noItemTitle,
  onActionClick,
  onCreate,
  onCustom,
  onReorder,
  onSelect,
  showActive,
  showUnselected = false,
  subLabelField,
  subtitle,
  title,
  useThumbnails,
  variant = 'standard',
  ...props
}: TileListProps<T, Variant, Value>) {
  const classes = useStyles(props)
  const [selected, setSelected] = useState<ValueType[]>(getValueType(props.selected))
  const [isSelecting, setSelecting] = useState(selected.length === 0)

  let itemsWithoutThumbId =
    isSelecting || showUnselected ? props.items : props.items.filter((item) => selected.includes(item.key))

  let items = itemsWithoutThumbId.map((item) => {
    if (!item.Thumbnail && item.thumbnailId) {
      const Thumbnail = () => (
        <Image publicId={item.thumbnailId}>
          <Transformation effect={'trim'} crop="scale" width="200" />
        </Image>
      )
      item.Thumbnail = Thumbnail
    }
    if (!item.Thumbnail && !item.thumbnailId) {
      const Thumbnail = () => <div className={classes.thumbnailPlaceholder}></div>
      item.Thumbnail = Thumbnail
    }
    return item
  })

  if (customTile) {
    const Thumbnail = () => <div className={classes.thumbnailPlaceholder}></div>
    const customTileItem = {
      key: '__custom__',
      label: (typeof customTile !== 'boolean' && customTile?.title) || 'Custom',
      Thumbnail: Thumbnail
    } as any

    if (typeof customTile !== 'boolean' && customTile?.selected && !isSelecting) {
      items = [customTileItem]
    } else if (isSelecting) {
      items = [customTileItem, ...items]
    }
  }

  function handleReorder({ oldIndex, newIndex }: any) {
    if (oldIndex === newIndex) {
      return
    }
    if (onReorder) {
      const newOrder = arrayMove([...items], oldIndex, newIndex)
      onReorder(newOrder)
    }
  }

  function toggleSelect(selecting = !isSelecting) {
    if (selecting) {
      if (variant !== 'multiselect') {
        setSelected([])
        onSelect(null, [] as Value)
      }
      setSelecting(true)
    } else {
      setSelecting(false)
      onSelect(null, variant === 'multiselect' ? (selected as Value) : (selected[0] as Value))
    }
  }

  // update internal selected if props.selected changes
  useEffect(() => {
    setSelected(getValueType(props.selected))
  }, [props.selected])

  const { activeTask } = useOnboardingContext()
  const highlightCreate = activeTask?.key === 'create-item' && !activeTask?.completed
  const highlightTermsItem = activeTask?.key === 'update-terms' && !activeTask?.completed

  return (
    <SortableTiles
      axis={dragAxis}
      helperClass={classes.dragging}
      pressDelay={150}
      shouldCancelStart={() => !draggable}
      onSortEnd={handleReorder}
      className={classes.root}
    >
      {/* title/subtitle */}
      {(title || subtitle || (!disabled && variant === 'multiselect' && isSelecting)) && (
        <>
          <Box className={classes.titleBox}>
            <Box>
              {title && (
                <Typography className={classes.title} variant={'caption'}>
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Box>
            {/* done button for multiselect */}
            {!disabled && variant === 'multiselect' && isSelecting && (
              <Tooltip title={selected.length === 0 ? 'Select at least 1 tile' : ''} placement="left">
                <Box marginRight={[0, 0, 3]}>
                  <Button icon={CheckmarkIcon} disabled={selected.length === 0} onClick={() => toggleSelect()}>
                    Done
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>
        </>
      )}
      {/* no items text */}
      {!items.length && (noItemTitle || noItemHelpText) && (
        <div>
          <Typography variant="h4">{noItemTitle}</Typography>
          <Typography className={classes.helpText}>{noItemHelpText}</Typography>
        </div>
      )}
      {/* tiles */}
      <div className={classes.tiles}>
        {items.map((item, index) => {
          const isSelected =
            selected.includes(item.key) ||
            // if it's a custom tile, we're not selecting, and customTile.selected is true
            (!isSelecting && item.key === '__custom__' && typeof customTile !== 'boolean' && customTile?.selected)

          return (
            <OnboardingPopper
              key={item.key}
              open={highlightTermsItem && item.key === 'all/default'}
              title={'Click Here to Update!'}
            >
              <SortableTile
                id={item.key}
                key={item.key}
                index={index}
                dense={dense}
                draggable={draggable}
                disabled={disabled || item.disabled || item.key === '__custom__' || item.key === 'default'}
                disableTile={disabled || item.disabled || (disableInactive && !item.active)}
                active={showActive ? item.active : undefined}
                selected={isSelected}
                hasSelectedIcon={hasSelectedIcon}
                Thumbnail={useThumbnails ? item.Thumbnail ?? null : null}
                className={classes.tile}
                actions={typeof actions === 'function' ? actions(item) : actions}
                onActionClick={onActionClick}
                onClick={(event: any) => {
                  const individualSelected = selected.find((key) => items.find((i) => i.key === key)?.individual)

                  if (item.key === '__custom__' && onCustom) {
                    if (isSelecting) {
                      setSelecting(false)
                    }
                    onCustom(event, setSelecting)
                  } else if (variant === 'select' || variant === 'multiselect') {
                    // show all items if we weren't already
                    if (!isSelecting && !disableUnselect) {
                      toggleSelect()
                    }

                    // if the item is an individual item, or we're not multiselect, set the selected
                    // to this item only
                    if ((item.individual || variant === 'select') && !isSelected) {
                      setSelected([item.key])
                    } else {
                      // if we had an individual selected before, remove it from selected
                      if (individualSelected) {
                        setSelected([])
                      }

                      // update selected with this item
                      setSelected((prev) =>
                        isSelected && !disableUnselect ? prev.filter((i) => i !== item.key) : [...prev, item.key]
                      )
                    }

                    if (variant === 'select' && !isSelected) {
                      // if we selected an item in single select mode, hide the other items
                      setSelecting(false)

                      // fire events
                      onSelect(event, item.key as Value)
                    }
                  } else {
                    // tiles are not selectable, so just fire onSelect for that tile
                    onSelect(event, item.key as Value)
                  }
                }}
              >
                <label
                  className={classnames(classes.label, {
                    [classes.smallLabel]: item.label.length > 20,
                    [classes.tinyLabel]: item.label.length > 40
                  })}
                >
                  {item.label}
                  {item.icon && <item.icon />}
                </label>
                {item[subLabelField] && items[index] && isSelected ? (
                  <Typography variant={'overline'} component={'label'} className={classes.selectedSubLabel}>
                    {items[index][subLabelField]}
                  </Typography>
                ) : (
                  <Typography variant={'overline'} component={'label'} className={classes.subLabel}>
                    {item[subLabelField]}
                  </Typography>
                )}
              </SortableTile>
            </OnboardingPopper>
          )
        })}
        {/* create button */}
        {createButton && (
          <>
            <OnboardingPopper open={highlightCreate} title={'Create New!'}>
              <Button
                id={'createButton'}
                classes={{ root: classes.addButton }}
                prominence={3}
                size={'medium'}
                startIcon={<Add />}
                fullWidth={true}
                onClick={(event) => {
                  if (onCreate) {
                    onCreate(event)
                  }
                }}
              >
                <Hidden smDown={true}>{createButton}</Hidden>
                <Hidden mdUp={true}>{'Add'}</Hidden>
              </Button>
            </OnboardingPopper>
          </>
        )}
        {/* change selection button */}
        {!disabled && !isSelecting && variant !== 'standard' && !showUnselected && (
          <MotionButton
            startIcon={<SwapHorizIcon />}
            className={classes.changeButton}
            prominence={3}
            size={'medium'}
            onClick={() => toggleSelect()}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.1
              }
            }}
          >
            Change Selection
          </MotionButton>
        )}
      </div>
    </SortableTiles>
  )
}

// convert props.selected into an array if a single value was provided
function getValueType(val: ValueType[] | ValueType): ValueType[] {
  return val ? (Array.isArray(val) ? val : [val]) : []
}

const itemVariants = {
  hidden: { opacity: 0, transition: { duration: 0.2 } },
  show: { opacity: 1 }
}

const transition = {
  type: 'spring',
  damping: 100,
  stiffness: 1000
}

export const SortableTile = SortableElement(function SortableTile({
  disableTile,
  draggable,
  ...props
}: TileProps & { disableTile?: boolean }) {
  // wrap the tile in a div so that the transitions from drag & drop don't
  // conflict with MotionTile
  return (
    <div>
      <motion.div
        variants={itemVariants}
        // only animate position change when dragging is disabled (otherwise animations conflict with react-sortable-hoc)
        layout={!draggable ? 'position' : undefined}
        transition={!draggable ? transition : undefined}
      >
        <Tile {...props} disabled={disableTile} />
      </motion.div>
    </div>
  )
})

export const SortableTiles = SortableContainer((props: any) => <div {...props} />)
