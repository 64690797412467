/**
 * @module builder
 */
import type { UpdateableItem, MoveableItem } from '../../index'

export function getCheckboxes(item: UpdateableItem): MoveableItem {
  if (item.section === 'options') {
    return {
      show: true,
      use_total: false,
      additionalWork: false,
      pending: false
    }
  } else if (item.section === 'archived') {
    return {
      show: false,
      use_total: false,
      additionalWork: false,
      pending: false
    }
  } else if (item.section === 'additional') {
    return {
      show: false,
      use_total: false,
      additionalWork: true,
      pending: false
    }
  } else if (item.section === 'pending') {
    return {
      show: false,
      use_total: false,
      additionalWork: false,
      pending: true
    }
  } else {
    return {
      show: true,
      use_total: true,
      additionalWork: false,
      pending: false
    }
  }
}
