import { gql } from 'apollo-boost'

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!, $options: JSON) {
    updateUser(input: $input, options: $options) {
      user {
        user_id
        email
        name
        picture
        user_metadata {
          firstName
          lastName
          phoneNumber
          customAvatar {
            label
            userEditable
            value
            cdnValue
            cloudinaryId
            width
            height
            fileType
            encoding
          }
        }
        app_metadata {
          active
          roles
        }
        preferences
      }
      options
    }
  }
`
