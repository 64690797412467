import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { getPresentationContentStyles, Grid, Typography } from '@ui/stickybid'
import ListIcon from '@material-ui/icons/List'

const useStyles = makeStyles<Theme, TermsPlaceholderProps>(
  (theme) => ({
    root: {
      '&$root': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 125,
        backgroundColor: theme.palette.grey[100],
        ...getPresentationContentStyles(theme, { noMargins: ['li'] })
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'center'
    }
  }),
  { name: 'TermsPlaceholder' }
)

export interface TermsPlaceholderProps {
  classes?: StyleClasses<typeof useStyles>
}

function TermsPlaceholder(props: TermsPlaceholderProps) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container spacing={1}>
        <Grid item xs={8}>
          <ListIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography variant={'h2'}>Custom Terms</Typography>
        </Grid>
        <Grid className={classes.container} item xs={5}>
          <Typography variant={'subtitle1'}>Terms are inserted automatically.</Typography>
          <Typography variant={'subtitle1'}>
            When viewing or sending, your terms will be inserted wherever this placeholder is set in the presentation.
          </Typography>
        </Grid>
        <Grid className={classes.container} item xs={6}></Grid>
      </Grid>
    </div>
  )
}

export default TermsPlaceholder
