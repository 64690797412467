import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { IconButton, Typography } from '../'

interface ButtonOptionProps {
  label: string
  buttons: {
    icon: React.ReactNode
    onClick?: () => void
  }[]
  classes?: StyleClasses<typeof useStyles>
}

const useStyles = makeStyles<Theme, ButtonOptionProps>(
  (theme: Theme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      label: {
        fontSize: '.9rem',
        marginRight: theme.spacing(3)
      },
      button: {
        borderRadius: 0,
        height: 26,
        width: 26,
        fontSize: '.8rem',
        fontWeight: 'bold',
        '& svg': {
          fontSize: '1rem'
        },
        '&:hover': {
          color: theme.palette.grey[700]
        },
        '&.Mui-disabled': {
          // for this component disabled is a way to display informational icons, not disabled buttons
          color: theme.palette.common.black
        }
      }
    }
  },
  { name: 'ButtonOption' }
)

export default function ButtonOption(props: ButtonOptionProps) {
  const classes = useStyles(props)
  const { label, buttons } = props
  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant={'h5'}>
        {label}
      </Typography>
      <div>
        {buttons.map((button, index) => {
          return (
            <IconButton
              key={index}
              disabled={!button.onClick}
              onClick={button.onClick ? button.onClick : null}
              classes={{ root: classes.button }}
            >
              {button.icon}
            </IconButton>
          )
        })}
      </div>
    </div>
  )
}
