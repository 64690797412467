import React from 'react'
import type { DialogActionsProps as MuiDialogActionsProps } from '@material-ui/core/DialogActions'
import MuiDialogActions from '@material-ui/core/DialogActions'
import type { ButtonProps } from '../../Button'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { cloneChildrenWithClassName, cloneElementWithClassName } from '../../util'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography

  return createStyles({
    root: {
      height: pxToRem(70),
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3)
      }
    },
    noBorder: {
      borderTop: 'none'
    },
    action: {},
    centerButton: {
      width: '90%',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    leftButton: {
      width: '100%'
    },
    rightButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing()
      }
    },
    centeredButton: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%'
    }
  })
}

export interface DialogActionsProps extends WithStyles<typeof styles>, Omit<MuiDialogActionsProps, 'classes'> {
  className?: string
  leftButton?: React.ReactElement<ButtonProps>
  centerButton?: React.ReactElement<ButtonProps>
  showBorder?: boolean
  centeredButton?: boolean
}

class DialogActions extends React.PureComponent<DialogActionsProps, any> {
  public static defaultProps: Partial<DialogActionsProps> = {
    showBorder: false
  }

  constructor(props) {
    super(props)
  }

  public render() {
    const { className, leftButton, classes, showBorder, centeredButton, centerButton, ...otherProps } = this.props

    return (
      <MuiDialogActions
        className={className}
        {...otherProps}
        classes={{
          root: [classes.root, !showBorder ? classes.noBorder : ''].join(' ')
        }}
        data-testid="dialogActions"
      >
        {leftButton && (
          <div className={classes.leftButton}>{cloneElementWithClassName(leftButton, classes.action)}</div>
        )}
        {centerButton && (
          <div className={classes.centerButton}>{cloneElementWithClassName(centerButton, classes.centerButton)}</div>
        )}
        <div className={centeredButton ? classes.centeredButton : classes.rightButton}>
          {cloneChildrenWithClassName(this.props.children, classes.action)}
        </div>
      </MuiDialogActions>
    )
  }
}

export default withStyles(styles)(DialogActions)
