import type { UpdateableItem } from '../../'
import type { RenderableItem } from '../../items'

/**
 * Gets the array of image keys from an item merging both files & images keys.
 */
export function getFileKeysForItem(args: { item: UpdateableItem | RenderableItem }): string[] {
  const { item } = args

  const keys = []

  if (item.files) {
    keys.push(...item.files)
  }

  return keys
}
