import React from 'react'

import UserSearchTable from '../UserSearchTable'
import NewUserButton from '../NewUserButton'
import type { ClientMetaDocument, Auth0User } from 'paintscout'
import { FormSectionTitle, FormSection, Flex } from '@ui/stickybid'
import { makeStyles, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { jsonToLucene } from 'json-to-lucene'
import { useQuery } from '@apollo/react-hooks'
import { useFormikContext } from 'formik'
import { SEARCH_USERS } from 'src/graphql/queries/SEARCH_USERS'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))

export default function ClientUsers() {
  const {
    values: { meta }
  } = useFormikContext<{ meta: ClientMetaDocument }>()

  const classes = useStyles({})

  const { loading, data, error } = useQuery<{
    searchUsers: {
      rows: Auth0User[]
      total_rows: number
    }
  }>(SEARCH_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: jsonToLucene({
        filters: [
          { type: 'equal', id: 'app_metadata.companyId', value: meta._id },
          {
            type: 'in',
            id: 'app_metadata.roles',
            not: true,
            value: ['superadmin']
          }
        ]
      }),
      limit: 100
    }
  })

  return (
    <>
      <FormSectionTitle title="Users" />
      <FormSection>
        <NewUserButton client={meta} fullWidth variant="outlined" style={{ marginBottom: 8 }} />
        <Card elevation={0}>
          <UserSearchTable
            className={classes.table}
            users={data?.searchUsers?.rows ?? []}
            loading={loading}
            rowsPerPage={100}
            noResultsMessage={error ? error.message : 'There are no users for this client yet'}
          />
          {data?.searchUsers?.total_rows > 0 && (
            <Flex mt={2} mr={2} justify="flex-end">
              <Typography variant="body1">{data.searchUsers.total_rows} Total Users</Typography>
            </Flex>
          )}
        </Card>
      </FormSection>
    </>
  )
}
