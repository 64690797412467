import { makeStyles } from '@material-ui/core'
import {
  ConfirmationDialog,
  FormSection,
  FormSectionTitle,
  PageHelpMessage,
  Typography,
  useDialogs,
  useOnboardingContext
} from '@ui/stickybid'
import type { LineItemOption } from '@paintscout/util/builder'
import {
  createLineItemOption,
  deleteLineItemOption,
  getLineItemOptions,
  getObjectLabels,
  reorderLineItemOptions,
  copyLineItemOption
} from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React from 'react'
import EditLineItemOptionDialog from '../../dialogs/EditLineItemOptionDialog'
import OptionsTileList from '../../OptionsTileList'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 5, 0, 0)
  },
  title: {
    padding: 0
  }
}))

export interface LineItemOptionsProps {
  onDialogConfirm?: () => void
  allowCustomProduct?: boolean
}

function LineItemOptions({ onDialogConfirm, allowCustomProduct }: LineItemOptionsProps) {
  const classes = useStyles({})
  const { openDialog, dismissDialog } = useDialogs()
  const quoteType = 'all'
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()

  const { options } = values
  const { updateOptionsOnboard } = useOnboardingContext()
  const isFirstNewItem = !options.options.onboardingTasks?.createProduct

  const items = getLineItemOptions({ options, quoteType, inactive: true })
  const objectLabels = getObjectLabels({ options })

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path="settings-line-items"
        openContent={
          <>
            <div className={classes.titleRow}>
              <FormSectionTitle title={'Products & Services'} classes={{ root: classes.title }} variant="h2" />
            </div>
            <Typography>
              Create your most commonly used Products & Services to easily add them to Quotes later!{' '}
            </Typography>
          </>
        }
        closedContent={
          <div className={classes.titleRow}>
            <FormSectionTitle title={'Products & Services'} classes={{ root: classes.title }} variant="h2" />
          </div>
        }
      />
      <FormSection>
        <OptionsTileList
          title={''}
          subtitle={''}
          items={items.map((item) => ({ ...item, key: item.name }))}
          createTitle={'Add New Product/Service'}
          showCreate={true}
          includeUnitPrice={true}
          onReorder={handleReorder}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
          onCreateClick={handleCreateItem}
          onCopyItem={handleCopyItem}
        />
      </FormSection>
    </div>
  )

  function handleReorder(lineItemOptions: LineItemOption[]) {
    const updatedOptions = reorderLineItemOptions({
      lineItemOptions,
      options,
      quoteType
    })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getLineItemOptions({ options, quoteType, inactive: true })
    const item = items.find((i) => i.name === key)

    openDialog(EditLineItemOptionDialog, {
      allowCustomProduct,
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  async function handleCreateItem(event: React.MouseEvent) {
    const item = createLineItemOption({
      calculate: true,
      calculateBy: 'quantity',
      showPrice: true,
      hideQuantity: false
    })

    openDialog(EditLineItemOptionDialog, {
      allowCustomProduct,
      options,
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue(
          'options',
          isFirstNewItem
            ? updateOptionsOnboard({ key: 'createProduct', value: true, options: updatedOptions })
            : updatedOptions
        )
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>This line item will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getLineItemOptions({ options, quoteType, inactive: true })
        const updatedOptions = deleteLineItemOption({ lineItemOption: items.find((i) => i.name === key), options })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyLineItemOption({ options, key })
    setFieldValue('options', updatedOptions)
  }
}

export default LineItemOptions
