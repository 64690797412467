import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { Divider } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}px 0`
    },
    divider: {},
    noPadding: {
      paddingTop: 0
    }
  })

type DivProps = JSX.IntrinsicElements['div']
export interface FormSectionProps extends DivProps {
  hideDivider?: boolean
}

type FormSectionPropsWithInjections = FormSectionProps & WithStyles<typeof styles>

/**
 * Renders a div with a Divider above it
 */
const FormSection = (props: FormSectionPropsWithInjections) => {
  const { hideDivider, ...otherProps } = props
  return (
    <React.Fragment>
      {!props.hideDivider && <Divider classes={{ root: props.classes.divider }} />}
      <div
        className={classnames({ [props.classes.root]: true, [props.classes.noPadding]: hideDivider })}
        {...otherProps}
      >
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(FormSection)
