import React from 'react'
import type { RenderableItem } from '@paintscout/util/builder'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { HtmlContent } from '@ui/stickybid'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      // display: 'inline-box',
      // height: '100%'
    },
    bold: {
      ...theme.typography.subtitle1,
      color: theme.palette.common.black
    },
    itemIcons: {
      display: 'flex'
    },
    itemIcon: {
      color: theme.palette.grey[500],
      fontSize: '1.5em',

      marginLeft: theme.spacing() / 2
    },
    additional: {
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: theme.spacing(0.5)
    }
  }),
  { name: 'NameColumn' }
)

export default function NameColumn({
  item,
  additional,
  ...props
}: {
  item: RenderableItem
  isEditable?: boolean
  additional?: string
  classes?: StyleClasses<typeof useStyles>
}) {
  const classes = useStyles(props)
  const { name } = item
  const description = item?.description ?? {
    useCustom: false,
    custom: '',
    default: ''
  }

  return (
    <div className={classnames(classes.root, { [classes.bold]: !!name })}>
      {name ? name : <HtmlContent content={description.useCustom ? description.custom : description.default} />}
      <span className={classes.additional}>{additional}</span>
    </div>
  )
}
