import React from 'react'
import type { PresentationSection } from 'paintscout'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import isURL from 'validator/es/lib/isURL'

import { StringOption, ColorOption, ButtonSelectOption } from '@ui/stickybid'

import { UTurnRightIcon } from '@ui/core/icons'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'

const useStyles = makeStyles<Theme, ButtonOptionsProps>((theme: Theme) => {
  return {
    root: {}
  }
})

interface ButtonOptionsProps {
  section: PresentationSection
  presetColors: string[]
  handleOptionFieldChange: (field: string, value: any) => void
}

function ButtonOptions(props: ButtonOptionsProps) {
  const classes = useStyles(props)
  const { section, presetColors, handleOptionFieldChange } = props
  const { animation, color, horizontalAlignInContainer = 'center', href, label } = section.fields
  const isHrefValid = isURL(href)

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon style={{ transform: 'rotate(180deg)' }} />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <StringOption
          errorMessage={label ? '' : 'Add a label'}
          label={'Label'}
          onChange={(ev) => handleOptionFieldChange('fields.label', ev.target.value)}
          value={label}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <StringOption
          errorMessage={isHrefValid ? '' : 'Link is invalid'}
          label={'Link'}
          onChange={(ev) => handleOptionFieldChange('fields.href', ev.target.value)}
          value={href}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ColorOption
          label={'Custom Button Color'}
          value={color}
          useClearOption={true}
          helpTextForClear={'Remove Color Override'}
          defaultColor={''}
          clearColor={''}
          presetColors={presetColors}
          onChange={(e) => {
            handleOptionFieldChange(`fields.color`, e)
          }}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Content Alignment (H)'}
          value={horizontalAlignInContainer}
          buttons={[
            { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'left' },
            { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
            { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'right' }
          ]}
          onChange={(value) => {
            handleOptionFieldChange('fields.horizontalAlignInContainer', value)
          }}
        />
      ),
      tab: 'content'
    }
  ]

  return options
}

export default ButtonOptions
