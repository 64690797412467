import type { QuoteArea } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import type { QuoteItemSection } from '../../../..'
import { filterItem, getItemSection } from '../../../../util'

// Filter crew items for area based on section and areaSubstrates
export const filterCrewAreaItems = ({
  area,
  section,
  areaSubstrates
}: {
  area: QuoteArea
  section: QuoteItemSection
  areaSubstrates: RenderableSubItem[]
}): boolean => {
  const areaSection = getItemSection(area)

  const areaTotals = area.totals
  const hasOverriddenHours = areaTotals.override_prep || areaTotals.override_hours

  if (areaSection !== section && !areaSubstrates.length) {
    return true
  }

  // Crew Bid section specific filters
  if (section === 'bid') {
    // If the whole area is not in the bid, we definitely don't want to show it.
    if (!filterItem(area, section)) {
      return true
    }

    // If there are no areaSubstrates, we still want to show it if it has:
    // overridden hours,
    // no substrate order,
    // or custom substrate string
    if (!areaSubstrates.length && !hasOverriddenHours) {
      return true
    }
  }

  return false
}
