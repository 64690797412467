import React from 'react'
import { Paper } from '@ui/stickybid'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core/styles'
import QuoteContextProvider from '../context/QuoteContextProvider'
import ArchivedItemsView from './views/ArchivedItemsView'
import NotesView from './views/NotesView'
import QuoteView from './views/QuoteView'
import WorkOrderView from './views/WorkOrderView'
import { useQuote } from '../context'
import type { QuoteDocument } from 'paintscout'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, QuoteProps>(
  (theme: Theme) => ({
    root: {
      marginBottom: 20
    },
    paper: (props) => ({
      position: 'relative',
      boxShadow: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      padding: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(4),
        width: '100%'
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(4),
        marginBottom: 0
      },

      border: `1px solid #888`,
      borderRadius: theme.borderRadius.md,
      ...(props.noCorners && {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }),
      '@media print': {
        border: 'none',
        margin: 0,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          padding: 0
        },
        [theme.breakpoints.down('xs')]: {
          padding: 0
        }
      }
    }),
    importedQuoteCV: {
      '&$importedQuoteCV': {
        background: 'transparent',
        border: 'none',
        borderRadius: 0
      }
    },
    borderOnPdfPages: {
      '&$borderOnPdfPages': {
        border: `1px solid ${theme.palette.grey[400]}`
      }
    },
    galleryImportInDrawer: {
      '&$galleryImportInDrawer': {
        background: 'white',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1.5),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5)
        }
      }
    },
    displayNone: {
      display: 'none'
    },
    content: {
      '@media print': {
        padding: '0'
      }
    },
    noPadding: {
      '&$noPadding': {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          padding: 0
        },
        [theme.breakpoints.down('xs')]: {
          padding: 0
        }
      }
    },
    noMargin: {
      '&$noMargin': {
        marginTop: 0,
        marginBottom: 0
      }
    },
    // keep this in sync with the padding of $paper above
    // need this for imported quotes to prevent padding around the imported image
    paperPadding: {
      padding: theme.spacing(0, 8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3)
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2)
      }
    },
    paperPaddingTop: {
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(4)
      }
    }
  }),
  { name: 'Quote' }
)

export type QuoteView =
  | 'quote'
  | 'work-order'
  | 'archived-items'
  | 'activity'
  | 'details'
  | 'terms'
  | 'notes'
  | 'presentation'

export interface QuoteProps {
  view?: QuoteView
  classes?: StyleClasses<typeof useStyles>

  // If you aren't rendering inside of a QuoteContextProvider, you can provide
  // the necessary props here to render without one
  quote?: QuoteDocument
  tableView?: 'area' | 'substrate'
  noCorners?: boolean

  inPresentation?: boolean
  isRevision?: boolean

  onClick?: (event: React.MouseEvent) => any
  onClickCapture?: (event: React.MouseEvent) => any
  isEditable?: boolean
  printOnly?: boolean
}

// type QuotePropsWithInjections = QuoteProps & WithUseStyles<typeof useStyles>

/**
 * Renders a quote
 *
 * It will use the quote from an existing QuoteContextProvider if there is one, but if not, it'll provide its
 * own using the `quote` prop
 */
function Quote(props: QuoteProps) {
  const classes = useStyles(props)
  const { inPresentation, isEditable, isRevision, onClick, onClickCapture, printOnly, quote: propQuote, view } = props
  const { quote: hookQuote } = useQuote()
  const quote = propQuote || hookQuote
  const isImportedQuote = quote.importedContent?.primary?.src
  const inDrawer = !!quote.presentationTemplate?.advanced?.useCart

  const content = (
    <Paper
      classes={{
        root: classnames({
          [classes.paper]: true,
          'Quote-paper': true,
          [classes.noPadding]: isImportedQuote,
          [classes.importedQuoteCV]: isImportedQuote && inPresentation,
          [classes.borderOnPdfPages]: isImportedQuote && inPresentation && !inDrawer,
          [classes.noMargin]: quote.is_invoice && inPresentation
        })
      }}
      onClick={onClick}
      onClickCapture={onClickCapture}
    >
      <>
        <div className={classes.content}>
          {view === 'quote' && (
            <QuoteView
              classes={{
                paperPadding: classes.paperPadding,
                paperPaddingTop: classes.paperPaddingTop,
                galleryImport: inPresentation && inDrawer ? classes.galleryImportInDrawer : null,
                importBottomSpacer: inPresentation && inDrawer ? classes.displayNone : null
              }}
              inPresentation={inPresentation}
              isRevision={isRevision}
              printOnly={printOnly}
            />
          )}
          {view === 'work-order' && <WorkOrderView />}
          {view === 'archived-items' && <ArchivedItemsView />}
          {view === 'notes' && <NotesView />}
        </div>
      </>
    </Paper>
  )

  if (propQuote) {
    return (
      <QuoteContextProvider quote={quote} isEditable={isEditable}>
        {content}
      </QuoteContextProvider>
    )
  } else {
    return <>{content}</>
  }
}

Quote.defaultProps = {
  view: 'quote' as QuoteView
}

export default Quote
