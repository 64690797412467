import { useApolloClient } from '@apollo/react-hooks'
import type { User, UserQuery, UserQueryVariables } from '@paintscout/api'
import { UserDocument } from '@paintscout/api'
import type { DialogProps } from '@ui/stickybid'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Spinner, UserTiles } from '@ui/stickybid'
import { removeAuth0Prefix } from '@paintscout/util/users'
import { useSnackbar } from 'notistack'
import type { Auth0User } from 'paintscout'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { SEARCH_USERS } from 'src/graphql/queries/SEARCH_USERS'
import { jsonToLucene } from 'json-to-lucene'

export interface SelectEstimatorDialogProps extends DialogProps {
  companyId: string
  currentUser: User
  onConfirm?: (user: User) => void
  onCancel?: () => void
}

export interface SelectEstimatorDialogState {
  selectedUsers: string[]
}

function SelectEstimatorDialog(props: SelectEstimatorDialogProps) {
  const { onCancel, onConfirm, companyId, currentUser, ...baseDialogProps } = props

  const { enqueueSnackbar } = useSnackbar()
  const [selectedEstimator, setSelectedEstimator] = useState(currentUser?.user_id ? currentUser?.user_id : '')

  const { loading, data, error } = useQuery<{
    searchUsers: {
      rows: Auth0User[]
      total_rows: number
    }
  }>(SEARCH_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: jsonToLucene({
        filters: [
          { type: 'equal', id: 'app_metadata.companyId', value: companyId },
          {
            type: 'in',
            id: 'app_metadata.roles',
            not: true,
            value: ['superadmin']
          }
        ]
      }),
      limit: 100
    }
  })

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Unable to retrieve Staff list', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const users = data?.searchUsers?.rows ?? []
  const currentOwnerId = currentUser?.user_id
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialOwner = useMemo(() => users.find((user) => user.user_id === currentOwnerId), [users])
  const apolloClient = useApolloClient()
  return (
    <Dialog maxWidth="md" {...baseDialogProps}>
      <DialogTitle>Select Receiving Estimator</DialogTitle>
      <DialogContent>
        {loading && !users.length ? (
          <Spinner fullWidth fullHeight />
        ) : (
          <UserTiles
            disabled={loading}
            allowNone={false}
            users={[
              ...users.filter(
                (user) =>
                  !initialOwner || // Filter out our default user
                  (removeAuth0Prefix(user.user_id) !== removeAuth0Prefix(initialOwner.user_id) &&
                    !(
                      (user?.app_metadata?.roles ?? []).includes('viewer') &&
                      (user?.app_metadata?.roles ?? []).length === 1
                    ))
              )
            ]}
            selectedUserIds={selectedEstimator ? [selectedEstimator] : []}
            onUserSelect={async (user) => {
              setSelectedEstimator(user?.user_id ?? null)
            }}
          />
        )}
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={onCancel} prominence={3} edge={'start'}>
            Cancel
          </Button>
        }
      >
        <Button
          type="submit"
          prominence={1}
          disabled={loading}
          onClick={async () => {
            if (selectedEstimator) {
              const { data } = await apolloClient.query<UserQuery, UserQueryVariables>({
                query: UserDocument,
                variables: {
                  id: selectedEstimator
                }
              })

              if (data.user) {
                //@ts-ignore
                onConfirm(data.user)
              } else {
                enqueueSnackbar('An error occurred while loading the selected Estimator', { variant: 'error' })
              }
            } else {
              enqueueSnackbar('An error occurred while loading the Estimator', { variant: 'error' })
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function getRoleName(user: User) {
  if ((user?.app_metadata?.roles ?? []).indexOf('admin') > -1) {
    return 'Admin'
  }
  if ((user?.app_metadata?.roles ?? []).indexOf('sales') > -1) {
    return 'Sales'
  }
  if ((user?.app_metadata?.roles ?? []).indexOf('crew') > -1) {
    return 'Crew'
  }
  if ((user?.app_metadata?.roles ?? []).indexOf('viewer') > -1) {
    return 'Viewer'
  }
}

export default SelectEstimatorDialog
