import React from 'react'
import { makeStyles } from '@material-ui/core'
import CoreDatePicker from '@ui/core/DatePicker'
import type { DatePickerProps as CoreDatePickerProps } from '@ui/core/DatePicker'
import type { Theme } from '@material-ui/core'
import DatePickerNavBar from './DatePickerNavBar'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from '../Button'
import Input from '../Input'

export interface DatePickerProps extends Omit<CoreDatePickerProps, 'DatePickerNavBar' | 'Button'> {
  selectOnClick?: boolean
  noMinInputHeight?: boolean
}

const useStyles = makeStyles<Theme, DatePickerProps>(
  (theme) => ({
    paper: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.borderRadius.lg,
      boxShadow: '3px 0px 12px -6px rgba(0,0,0,0.23)',
      marginTop: theme.spacing(2)
    },
    popover: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 8
    },
    popoverContent: {
      backgroundColor: '#f7f7f7',
      padding: theme.spacing(1.5),
      overflow: 'hidden'
    },
    popoverFooter: (props) => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: theme.spacing(0, 2, 1, 2),
      ...(props.selectOnClick && !props.clearable && { display: 'none' })
    }),
    picker: (props) => ({
      display: 'block',
      marginBottom: theme.spacing(),
      ...(props.selectOnClick && !props.clearable && { marginBottom: 0 }),
      ...theme.typography.body1,
      '& .DayPicker-Day': {
        fontWeight: 600,
        padding: '5px 11px',
        margin: '5px'
      },
      '& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: '#eeeeee !important',
          borderRadius: theme.borderRadius['sm']
        },
      '& .DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
        backgroundColor: `${theme.palette.grey[700]} !important`,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.borderRadius['sm']
      },
      '& .DayPicker-Day:focus:not(.focus-visible) ': {
        outline: 'none'
      },
      '& .DayPicker-wrapper:focus:not(.focus-visible) ': {
        outline: 'none'
      }
    }),
    rangePicker: (props, month = props.value) => ({
      '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)': {
        backgroundColor: `${theme.palette.grey[500]} !important`,
        color: theme.palette.primary.contrastText,
        borderRadius: 0
      },
      '& .DayPicker-Day--start': {
        borderRadius: `${theme.borderRadius['sm']} 0 0 ${theme.borderRadius['sm']} !important`
      },
      '& .DayPicker-Day--end': {
        borderRadius: `0 ${theme.borderRadius['sm']} ${theme.borderRadius['sm']} 0 !important`
      }
    }),
    input: (props) => ({
      userSelect: 'none',
      ...(!props.noMinInputHeight && { minHeight: '43px !important' })
    }),
    info: {
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing()}px ${theme.spacing()}px`,
      maxWidth: 360,
      ...theme.typography.subtitle1
    }
  }),
  { name: 'DatePickerSB' }
)

function DatePicker(props: DatePickerProps) {
  const InputIcon = <ArrowDropDownIcon />
  const { InputProps = {}, ...remainingProps } = props
  InputProps.endAdornment = InputIcon
  const isRange = Array.isArray(props.value)
  const selectOnClick = !isRange
  const classes = useStyles({ selectOnClick, ...remainingProps })

  return (
    <CoreDatePicker
      classes={classes}
      selectOnClick={selectOnClick}
      Button={Button}
      DatePickerNavBar={DatePickerNavBar}
      InputProps={InputProps}
      InputComponent={Input}
      confirmLabel={'Done'}
      {...remainingProps}
    />
  )
}

export default DatePicker
