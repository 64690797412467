import React, { useState, useEffect } from 'react'
import type { DialogProps } from '@ui/stickybid'
import {
  Alert,
  AlertDialog,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Spinner,
  Typography,
  useClientOptions,
  useDialogs
} from '@ui/stickybid'
import CloseButton from '../common/CloseButton'
import ErrorIcon from '@material-ui/icons/Error'
import type { Stripe } from 'stripe'
import { Elements as StripeElements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import type { QuoteEventType, QuoteDocument } from 'paintscout'

import { useSnackbar } from 'notistack'

import { formatCurrency, getObjectLabels } from '@paintscout/util/builder'

import { useProcessEvent } from '../../hooks/useProcessEvent'

import Done from '@material-ui/icons/Done'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { usePaymentProcessedQuery } from '@paintscout/api'

export interface CollectPaymentDialogProps extends DialogProps {
  quote: QuoteDocument
  amount: number
  source: 'collect-payment' | 'customer-view'
  note?: string
  onCancel?: () => void
  onConfirm: (updatedQuote: QuoteDocument) => void | Promise<void>
}

function CollectPaymentDialog(props: CollectPaymentDialogProps) {
  const { onCancel, onConfirm, quote, source, amount, note, ...otherProps } = props
  const { processEvent } = useProcessEvent()
  const { openDialog, dismissAllDialogs } = useDialogs()
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })

  const { enqueueSnackbar } = useSnackbar()
  const [stripeOptions, setStripeOptions] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)

  const [loading, setLoading] = useState(false)
  const [elementsLoading, setElementsLoading] = useState(false)

  const [error, setError] = useState('')

  useEffect(() => {
    async function process() {
      setLoading(true)
      setElementsLoading(true)
      const res = await processEvent({
        type: 'get-payment-intent' as QuoteEventType,
        provider: 'stripe',
        params: {
          quoteId: quote._id,
          source,
          paymentAmount: amount,
          note
        }
      })

      if (res.error || res.result?.error) {
        let error: string | undefined
        if (res.error) {
          if (typeof res.error === 'object' && res.error && 'message' in res.error) {
            error = (res.error as { message: string }).message
          } else {
            error = res.error as string
          }
        } else if (res.result?.error) {
          error = res.result.error
        }
        setError(error)
        if (error?.includes('Rev Mismatch')) {
          openDialog(AlertDialog, {
            title: `${objectLabels.quote.value} Has Been Updated`,
            icon: <ErrorIcon />,
            color: 'warning',
            message: `The ${objectLabels.quote.value} was updated by the estimator while you were viewing it. It may have been for internal purposes, but we suggest you look over it once more first.`,
            onConfirm: () => {
              dismissAllDialogs()
              window.location.reload()
            }
          })
        } else {
          enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' })
          onCancel()
        }
      } else if (res.result) {
        setStripeOptions({
          clientSecret: res.result.result.clientSecret,
          account: res.result.result.account
        })
        setStripePromise(loadStripe(res.result.result.publishableKey))
      }
      setLoading(false)
      setElementsLoading(false)
    }
    process()
  }, [])

  return (
    <StripeElements stripe={stripePromise} options={stripeOptions}>
      <CheckoutForm
        elementsLoading={elementsLoading}
        loading={loading}
        error={error}
        setError={setError}
        setLoading={setLoading}
        {...props}
      />
    </StripeElements>
  )
}

function CheckoutForm({
  amount,
  error,
  elementsLoading,
  loading,
  note,
  onCancel,
  onConfirm,
  quote,
  setError,
  setLoading,
  source,
  ...otherProps
}: CollectPaymentDialogProps & {
  error: string
  elementsLoading: boolean
  loading: boolean
  setError: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const stripe = useStripe()
  const elements = useElements()
  const { options } = useClientOptions()
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissAllDialogs } = useDialogs()

  const paymentAmount = formatCurrency({ options, value: amount })

  const handleTitle = () => {
    return !quote.is_invoice && !(quote.totals.balance_due < 200) ? 'Pay Deposit' : 'Make Payment'
  }

  const handleSubtitle = () => {
    return ` - ${paymentAmount} of ${formatCurrency({
      options,
      value: quote.totals.balance_due
    })}`
  }

  return (
    <Dialog onClose={dismissAllDialogs} fullWidth={true} maxWidth={'md'} {...otherProps}>
      <form onSubmit={handleSubmit}>
        <DialogTitle rightContent={<CloseButton disabled={loading} onCancel={dismissAllDialogs} />}>
          {handleTitle()}
          {handleSubtitle()}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {elementsLoading && (
              <Grid item xs={12}>
                <Spinner style={{ display: 'block', margin: '24px auto', width: 31 }} />
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Alert severity={'error'} variant={'outlined'}>
                  {error}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <PaymentElement />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          leftButton={
            <Button disabled={loading} prominence={3} onClick={onCancel}>
              {'< Back'}
            </Button>
          }
        >
          <Button disabled={loading} type="submit" variant={'contained'} icon={Done}>
            Pay {paymentAmount}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)

    if (!stripe || !elements) {
      onCancel()
      enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' })
      return null
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.toString()
      },
      redirect: 'if_required'
    })

    setLoading(false)

    if (result.error) {
      setError(handleStripeErrorMessage(result.error as any as Stripe.StripeRawError))
      enqueueSnackbar('Unable to process payment.', { variant: 'error' })
    } else {
      handleSuccessfulPayment()
    }
  }

  function handleSuccessfulPayment() {
    dismissAllDialogs()
    openDialog(PaymentProcessingDialog, {
      amount: paymentAmount,
      quote,
      paymentCount: (quote.payments?.length ?? 0) + 1,
      onConfirm
    })
  }
}

function PaymentProcessingDialog(
  props: DialogProps & {
    amount: string
    quote: QuoteDocument
    paymentCount: number
    onConfirm: CollectPaymentDialogProps['onConfirm']
  }
) {
  const { amount, quote, paymentCount, onConfirm, ...otherProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [processing, setProcessing] = useState(true)
  const [confirming, setConfirming] = useState(false)
  const { dismissAllDialogs } = useDialogs()

  const { data } = usePaymentProcessedQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: quote._id,
      paymentCount
    },
    onError: (error) => {
      console.log('Error processing payment:')
      console.log(error.message)
      dismissAllDialogs()
      enqueueSnackbar('Unable to process payment.', { variant: 'error' })
    },
    onCompleted: () => {
      if (data && data.paymentProcessed?.updatedQuote) {
        setProcessing(false)
      }
    }
  })

  async function handleConfirm() {
    if (onConfirm) {
      setConfirming(true)
      await onConfirm({ ...quote, ...data.paymentProcessed?.updatedQuote })
      setConfirming(false)
    }
  }

  return (
    <Dialog {...otherProps}>
      <div style={{ height: 28 }}></div>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
          {processing ? (
            <>
              <Grid item>
                <Spinner style={{ height: '3em', width: '3em' }} />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant={'h3'}>
                  Payment Processing...
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <CheckCircleOutlineIcon style={{ height: '3em', width: '3em', color: '#60b948' }} />
              </Grid>
              <Grid item>
                <Typography variant={'h2'}>Payment Succeeded!</Typography>
              </Grid>
              <Grid item>
                <Typography variant={'h3'}>{amount} received</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!processing && (
          <Button loading={confirming} onClick={handleConfirm} type="submit" variant={'contained'} icon={Done}>
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CollectPaymentDialog

function handleStripeErrorMessage(error: Stripe.StripeRawError) {
  if (error.type === 'card_error') {
    return `${error.message} Please verify the details and try again, or use a different card to complete your purchase.`
  }
  return error.message
}
