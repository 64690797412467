import { createClient } from 'pexels'
import type { ErrorResponse, PhotosWithTotalResults } from 'pexels'
import * as Sentry from '@sentry/core'

const client = createClient(process.env.PEXELS_API_KEY)

interface SearchPhotosParams {
  query: string
  orientation?: 'landscape' | 'portrait' | 'square'
  size?: 'large' | 'medium' | 'small'
  color?:
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'turquoise'
    | 'blue'
    | 'violet'
    | 'pink'
    | 'brown'
    | 'black'
    | 'gray'
    | 'white'
    | string
  locale?:
    | 'en-US'
    | 'pt-BR'
    | 'es-ES'
    | 'ca-ES'
    | 'de-DE'
    | 'it-IT'
    | 'fr-FR'
    | 'sv-SE'
    | 'id-ID'
    | 'pl-PL'
    | 'ja-JP'
    | 'zh-TW'
    | 'zh-CN'
    | 'ko-KR'
    | 'th-TH'
    | 'nl-NL'
    | 'hu-HU'
    | 'vi-VN'
    | 'cs-CZ'
    | 'da-DK'
    | 'fi-FI'
    | 'uk-UA'
    | 'el-GR'
    | 'ro-RO'
    | 'nb-NO'
    | 'sk-SK'
    | 'tr-TR'
    | 'ru-RU'
  page?: number
  per_page?: number
}

export async function searchPhotos({
  query,
  orientation,
  size,
  color,
  locale,
  page = 1,
  per_page = 15
}: SearchPhotosParams): Promise<PhotosWithTotalResults | ErrorResponse> {
  try {
    const response = client.photos.search({
      query,
      page,
      per_page,
      ...(orientation && { orientation }),
      ...(size && { size }),
      ...(color && { color }),
      ...(locale && { locale })
    })
    return response
  } catch (error) {
    Sentry.captureException(error, { tags: { pexels: 'true' } })
    return { error }
  }
}
