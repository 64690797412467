import React, { useContext, useRef, useEffect } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { useQuote } from '../../context/useQuote'
import {
  ConfirmationDialog,
  FileDropArea,
  Grid,
  Spinner,
  useClientOptions,
  useFileDropArea,
  useMediaQuery,
  ViewMediaDialog
} from '@ui/stickybid'
import type { QuoteFile } from 'paintscout'
import { makeStyles } from '@material-ui/core'

import { updateGalleryFiles } from '@paintscout/util/builder'

import { DialogStackContext, Typography } from '@ui/stickybid'
import { EditMediaDialog, FilesGrid } from '../..'
import { usePresentationNav } from '../../presentation'

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      title: {
        borderBottom: `1px solid #888`,
        paddingBottom: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5)
      },
      loadingOverlay: {
        display: 'flex',

        border: '2px dashed #aaa',
        content: "' '",
        height: '100%',

        alignItems: 'center',
        justifyContent: 'center',

        width: '100%',
        position: 'absolute',
        borderRadius: '3px',
        zIndex: 50,
        background: '#ddd'
      }
    }
  },
  { name: 'GalleryFiles' }
)

export interface GalleryFilesProps {
  hideAdd?: boolean
}

/**
 * Shows the files for section grouped by item if there are any
 */
export default function GalleryFiles(props: GalleryFilesProps) {
  const classes = useStyles(props)
  const { hideAdd } = props
  const { quote, isEditable, updateQuote, tableView, onEditItemFiles } = useQuote()
  const { options } = useClientOptions()
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const currentQuote = useRef(quote)
  const mdDown = useMediaQuery('md')
  const { isCustomerView } = usePresentationNav()

  // Maintain & use current quote ref so no gallery state issues on image add
  useEffect(() => {
    currentQuote.current = quote
  })

  const {
    open: openDropArea,
    getInputProps,
    loading
  } = useFileDropArea({
    onUpload: handleUploadFiles
  })

  function handleReorder(files: QuoteFile[]) {
    const newOrder = files.map((file) => file.key)
    updateQuote({
      quote: { ...quote, galleryFiles: newOrder },
      autosave: true
    })
  }

  const allFiles = (quote.galleryFiles ?? [])
    .map((file) => {
      return quote.files?.[file]
    })
    .filter(Boolean)

  const hasFiles = Object.keys(allFiles).length > 0
  const showTitle = hasFiles || isEditable

  if (!hasFiles && !isEditable) {
    return null
  }

  if (!isEditable || hideAdd) {
    return (
      <>
        {showTitle && (
          <div className={classes.title}>
            <Typography variant={'h2'}>Gallery</Typography>
          </div>
        )}
        <div onClick={(ev) => ev.stopPropagation()}>
          <FilesGrid
            columns={mdDown ? 3 : 4}
            files={allFiles}
            justify="flex-start"
            mobileColumns={2}
            onFileClick={handleViewFile}
            filePreviewProps={{ showFirstPageOnly: true, showViewHover: true }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <input {...getInputProps()} />
      {showTitle && (
        <div className={classes.title}>
          <Typography variant={'h2'}>Gallery</Typography>
        </div>
      )}
      <FileDropArea
        onUpload={handleUploadFiles}
        multiple={false}
        label={'Drop media to add to the Gallery'}
        accept={'application/pdf, image/*'}
      >
        {loading && (
          <div className={classes.loadingOverlay}>
            <Grid item container spacing={2} alignItems="center" justify="center">
              <Grid item>
                <Spinner size={10} />
              </Grid>
              <Grid item>
                <Typography variant="overline">Uploading...</Typography>
              </Grid>
            </Grid>
          </div>
        )}
        <FilesGrid
          columns={mdDown ? 3 : 4}
          editable
          files={allFiles}
          isEditable
          justify="flex-start"
          mobileColumns={2}
          onDelete={handleDeleteFile}
          onFileClick={handleEditFile}
          onUploadClick={openDropArea}
          onReorder={handleReorder}
          filePreviewProps={{ showFirstPageOnly: true }}
        />
      </FileDropArea>
    </>
  )

  function handleViewFile(index: number) {
    openDialog(ViewMediaDialog, {
      title: 'Gallery',
      files: allFiles,
      index,
      enableDownload: isCustomerView,
      onClose: dismissDialog
    })
  }

  function handleEditFile(index: number) {
    openDialog(EditMediaDialog, {
      file: quote.files[quote.galleryFiles[index]],
      index,
      onCancel: () => {
        dismissDialog()
      },
      onConfirm: (updatedFile: QuoteFile) => {
        const fileKey = quote.galleryFiles?.[index]
        updateQuote({
          quote: {
            ...quote,
            files: {
              ...quote.files,
              [fileKey]: updatedFile
            }
          },
          autosave: true
        })
        dismissDialog()
      }
    })
  }

  function handleDeleteFile(index: number) {
    openDialog(ConfirmationDialog, {
      message: 'Are you sure you want to delete this item from the gallery?',
      onConfirm: () => {
        quote.galleryFiles.splice(index, 1)
        updateQuote({ quote, autosave: true })
        dismissDialog()
      },
      onCancel: () => dismissDialog()
    })
  }

  function handleUploadFiles(files: QuoteFile[]) {
    const updatedQuote = updateGalleryFiles({ quote: currentQuote?.current, files, append: true })
    updateQuote({ quote: updatedQuote, autosave: false })
  }
}
