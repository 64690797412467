import type { QuoteDocument, OrderItem, QuoteOptions, QuoteArea, LineItem } from 'paintscout'

import get from 'lodash/get'

import { filterItem } from '../filter-item'
import { roundPrice } from '../round-price'
import type { QuoteItemSection } from '../../index'
import { parseNumber, fixNumber } from '../../../../calculator'

export interface SubstrateProducts {
  [key: string]: { quantity: number; totalPrice: number; unitLabel: string; unitPrice: number }
}

export function generateProductDescription(args?: { quote: QuoteDocument; section?: QuoteItemSection }): string {
  const { quote } = args
  const section = args.section ? args.section : 'bid'

  const showVolume = !(quote.options?.hideWorkOrderVolumes ?? false)
  const roundProduct = quote.options?.roundProducts
  const showMaterialCost = !(quote.options?.hideWorkOrderMaterialCost ?? false)

  const order = quote.order.area
  let substrateProducts: SubstrateProducts = {}
  order.forEach((orderItem: OrderItem) => {
    if (orderItem._deleted) {
      return null
    }

    if (orderItem.type === 'area') {
      const area = quote.areas[orderItem.key]

      if (!filterItem(area, section)) {
        return null
      }

      if (area) {
        substrateProducts = getAreaProducts(area, section, substrateProducts, quote.options)
      }
    } else if (orderItem.type === 'line_item') {
      const lineItem = quote.lineItems[orderItem.key]

      if (!filterItem(lineItem, section)) {
        return null
      }

      substrateProducts = getLineItemProducts(lineItem, section, substrateProducts, quote.options)
    }
  })

  return Object.keys(substrateProducts)
    .map((item) => {
      const { quantity, totalPrice, unitLabel, unitPrice } = substrateProducts[item]

      let cleanQuantity: string | number = ''
      let cleanPrice = ''

      if (quantity && showVolume) {
        if (roundProduct === 'up') {
          cleanQuantity = Math.ceil(quantity)
          const adjustedPrice = cleanQuantity * unitPrice
          cleanPrice = showMaterialCost && totalPrice ? ` - $${roundPrice(adjustedPrice)}` : ''
        } else if (roundProduct === 'round') {
          cleanQuantity = fixNumber(quantity, 0)
          const adjustedPrice = cleanQuantity * unitPrice
          cleanPrice = showMaterialCost && totalPrice ? ` - $${roundPrice(adjustedPrice)}` : ''
        } else {
          cleanQuantity = roundPrice(quantity)
          cleanPrice = showMaterialCost && totalPrice ? ` - $${roundPrice(totalPrice)}` : ''
        }
        return `${item} (Estimated: ${cleanQuantity} ${unitLabel}${cleanPrice})`
      }
      return item
    })
    .filter((item) => item)
    .join('\n')
}

export function getAreaProducts(
  area: QuoteArea,
  section: QuoteItemSection,
  substrateProducts: SubstrateProducts,
  quoteOptions?: QuoteOptions
) {
  Object.keys(area.substrates).forEach((substrateSection) => {
    const substrates = area.substrates[substrateSection]
    substrates.forEach((substrate) => {
      if (section && !filterItem(substrate, section)) {
        return
      }

      const { product } = substrate

      if (product) {
        const label = [get(substrate, 'product.product.label', ''), get(substrate, 'product.product.color', '')]
          .filter((item) => item)
          .join(' - ')

        const quantity = product.volume_override ? product.volume_override_value : product.volume
        const totalPrice = product.totalPrice_override ? product.totalPrice_override_value : product.totalPrice
        const unitLabel = product.product?.unitLabel ?? 'gal'
        const unitPrice = product.price_override ? product.price_override_value : product.price

        if (substrateProducts[label]) {
          substrateProducts[label].quantity += roundQuantity(quantity, quoteOptions)
          substrateProducts[label].totalPrice += totalPrice ? totalPrice : 0
        } else {
          substrateProducts[label] = {
            quantity,
            totalPrice: totalPrice ? totalPrice : 0,
            unitLabel,
            unitPrice
          }
        }
      }
    })
  })

  return substrateProducts
}

export function getLineItemProducts(
  lineItem: LineItem,
  section: QuoteItemSection,
  substrateProducts: SubstrateProducts,
  quoteOptions?: QuoteOptions
) {
  ;(lineItem.materials ?? []).forEach((material) => {
    const label = [material?.label, material?.color].filter((item) => item).join(' - ')
    if (!filterItem(lineItem, section)) {
      return
    }
    const quantity = material?.quantity
    const totalPrice = material?.totalPrice?.useCustom ? material.totalPrice.custom : material.totalPrice.default ?? 0
    const unitLabel = material?.unitLabel ?? ''
    const unitPrice = material?.price?.useCustom ? material.price.custom : material.price.default ?? 0

    if (substrateProducts[label]) {
      substrateProducts[label].quantity += roundQuantity(parseNumber(quantity), quoteOptions)
      substrateProducts[label].totalPrice += totalPrice ? totalPrice : 0
    } else {
      substrateProducts[label] = {
        quantity: parseNumber(quantity),
        totalPrice: totalPrice ? totalPrice : 0,
        unitLabel,
        unitPrice
      }
    }
  })

  return substrateProducts
}

function roundQuantity(quantity: number, quoteOptions: QuoteOptions): number {
  const roundProducts = quoteOptions?.roundProducts || false
  const individualProductRounding = quoteOptions?.individualProductRounding || false

  if (individualProductRounding) {
    if (roundProducts === 'up') {
      return Math.ceil(quantity)
    } else if (roundProducts === 'round') {
      return fixNumber(quantity, 0)
    }
  }

  return quantity
}

/*
export interface UpdateableProduct
  extends Omit<ProductOption, 'coverage' | 'lnftCoverage' | 'quantityCoverage' | 'volume' | 'price'> {
  color: string
  custom?: boolean
  coverage: OverridableValue
  lnftCoverage: OverridableValue
  quantityCoverage: OverridableValue
  volume: OverridableValue
  price: OverridableValue
  totalPrice: OverridableValue
  quantity?: number
  unitLabel?: string
}
*/
