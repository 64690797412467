import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { PresentationOption, PresentationPreview, QuoteDocument } from 'paintscout'
import type { StyleClasses } from '@ui/core/theme'
import type { QuoteView } from '../../Quote'
import { Footer, getPresentationTheme } from '@ui/stickybid'
import PresentationNavContextProvider from '../PresentationNav/PresentationNavContext/PresentationNavContextProvider'
import PresentationNavMobile from '../PresentationNav/PresentationNavMobile/PresentationNavMobile'
import Presentation from '../Presentation/Presentation'
import { Formik } from 'formik'
import { useQuote } from '../../context'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, CustomerViewContentsProps>(
  (theme: Theme) => {
    return {
      layout: (props) => {
        // height of the mobile navbar, with or without the banner
        const topMargin = props.hideNav ? '0' : props.banner ? '171px' : '117px'
        const topMarginMobile = props.hideNav ? '0' : props.banner ? '134px' : '78px'

        return {
          overflow: 'hidden',
          position: 'relative',
          display: 'block',
          margin: `${topMargin} auto 0 auto`,
          maxWidth: 1144,
          padding: theme.spacing(0, 4),
          [theme.breakpoints.down('md')]: {
            padding: 0
          },
          [theme.breakpoints.down('xs')]: {
            margin: `${topMarginMobile} auto 0 auto`
          },
          '@media print': {
            paddingTop: 0,
            marginTop: 0
          }
        }
      },
      invoice: {
        marginTop: 0
      },
      presentationWrapper: {
        '@media print': {
          width: 'auto',
          padding: 0
        }
      }
    }
  },
  { name: 'CustomerViewContents' }
)

export interface CustomerViewContentsProps {
  allowResponse?: boolean
  banner?: React.ReactNode
  ChatButton?: React.ReactNode
  classes?: StyleClasses<typeof useStyles>
  currentPage: string
  getHeight?: (height: number) => number
  handleAcceptClick?: (_event: any, selectedOptionalItems?: string[], usePaymentCallback?: boolean) => void
  handleMoreClick?: (_event: any, data: string) => void
  handlePageClick: (ev: React.MouseEvent, pageKey: string) => void
  handlePayClick?: (_event: any, amount: number) => void
  handleSectionClick: (ev: React.MouseEvent, pageKey: string) => void
  hideFooter?: boolean
  hideNav?: boolean
  isInvoice?: boolean
  isPreview?: boolean
  isPreviewAccept?: boolean
  isScreenshot?: boolean
  loadAllFonts?: boolean
  navOpen: boolean
  onDismissDialog?: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void
  pdf?: boolean
  presentation: PresentationOption
  preview?: PresentationPreview
  responding?: boolean
  setNavOpen: (open: boolean) => void
  showChat?: QuoteDocument['owner']
  usePresentationFromProp?: boolean
  view: QuoteView
}

function CustomerViewContents(props: CustomerViewContentsProps) {
  const classes = useStyles(props)
  const {
    allowResponse = true,
    banner,
    ChatButton,
    currentPage,
    getHeight,
    handleAcceptClick,
    handlePayClick,
    handleMoreClick,
    handlePageClick,
    handleSectionClick,
    hideFooter,
    hideNav,
    isInvoice,
    isPreview,
    isPreviewAccept,
    isScreenshot,
    loadAllFonts,
    navOpen,
    onDismissDialog,
    pdf,
    presentation,
    preview,
    responding,
    setNavOpen,
    showChat,
    usePresentationFromProp,
    view
  } = props
  // const showChat = true
  const [cartOpen, setCartOpen] = useState(false)
  // const infiniteScroll = presentation?.advanced?.infiniteScroll
  const infiniteScroll = true
  const stacked = pdf || infiniteScroll

  const style = presentation.advanced?.style || {}
  const presentationTheme = useMemo(() => getPresentationTheme(style), [style])
  const { quote } = useQuote()

  const isQuoteEmpty = useMemo(() => {
    return Object.keys(quote?.lineItems || {}).length === 0 && !quote.importedContent && !quote.is_invoice
  }, [quote.lineItems, quote.importedContent, quote.is_invoice])

  return (
    <PresentationNavContextProvider
      allowResponse={allowResponse}
      cartOpen={cartOpen}
      currentPage={currentPage}
      currentSection={currentPage}
      isCustomerView
      isPreview={isPreview}
      isPreviewAccept={isPreviewAccept}
      isQuoteEmpty={isQuoteEmpty}
      isResponding={responding}
      onAcceptClick={handleAcceptClick}
      onClose={() => setNavOpen(false)}
      onCloseCart={() => setCartOpen(false)}
      onDismissDialog={onDismissDialog}
      onMoreClick={handleMoreClick}
      onOpen={() => setNavOpen(true)}
      onOpenCart={() => setCartOpen(true)}
      onPageClick={handlePageClick}
      onPayClick={handlePayClick}
      onSectionClick={handleSectionClick}
      open={navOpen}
      presentation={presentation}
      preview={preview}
      view={view}
    >
      <div className={classnames({ [classes.layout]: true, [classes.invoice]: isInvoice })}>
        {!isScreenshot && !hideNav && <PresentationNavMobile banner={banner} classes={classes} />}
        <div className={classes.presentationWrapper}>
          <Formik
            initialValues={presentation}
            onSubmit={() => {
              // Do nothing, formik here for context
            }}
          >
            <Presentation
              filterTypes={pdf ? ['quote', 'work-order', 'pictures', 'terms'] : null}
              isPreview={isPreview}
              isScreenshot={isScreenshot}
              isSettings={false}
              loadAllFonts={loadAllFonts}
              getHeight={getHeight}
              page={stacked ? null : currentPage}
              presentation={isScreenshot || usePresentationFromProp ? presentation : null}
              presentationTheme={presentationTheme}
              view={view}
            />
          </Formik>
          {showChat && ChatButton}
          {!isScreenshot && !hideFooter && <Footer />}
        </div>
      </div>
    </PresentationNavContextProvider>
  )
}

export default CustomerViewContents
