import React from 'react'
import type { PresentationSection } from 'paintscout'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import { UTurnRightIcon } from '@ui/core/icons'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'
import CodeIcon from '@material-ui/icons/Code'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import { DescriptionIcon, ImageSmallIcon } from '@ui/core/icons'
import { ButtonSelectOption, ButtonOption, SliderOption } from '@ui/stickybid'

const useStyles = makeStyles<Theme, TwoColumnOptionsProps>((theme: Theme) => {
  return {
    root: {}
  }
})

interface TwoColumnOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
  handleOptionSectionChange: (section: PresentationSection) => void
}

function TwoColumnOptions(props: TwoColumnOptionsProps) {
  const classes = useStyles(props)
  const { section, handleOptionSectionChange, handleOptionFieldChange } = props
  const fields = section?.fields ?? ({} as any)
  const { animation, rightType, leftType, columnAlign, leftWidth = 6, fullWidthImage } = fields

  const handleSwapClick = async () => {
    handleOptionFieldChange('fields.swap', true)
  }

  const getIcon = (type: string) => {
    if (type === 'text') {
      return <DescriptionIcon />
    } else if (type === 'image') {
      return <ImageSmallIcon />
    } else if (type === 'custom') {
      return <CodeIcon />
    }
  }

  const handleWidthChange = (leftWidth: number) => {
    handleOptionFieldChange('fields.leftWidth', leftWidth)
    handleOptionFieldChange('fields.rightWidth', 12 - leftWidth)
  }

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon style={{ transform: 'rotate(180deg)' }} />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Full Width Image'}
          value={fullWidthImage ?? false}
          buttons={[{ icon: <AspectRatioIcon />, value: true }]}
          onChange={(value) => handleOptionFieldChange('fields.fullWidthImage', !fields.fullWidthImage)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <SliderOption
          label={'Column Sizes'}
          value={leftWidth}
          min={1}
          max={11}
          marks={[{ value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }]}
          onChange={(value) => handleWidthChange(value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Column Alignment'}
          value={columnAlign}
          buttons={[
            { icon: <VerticalAlignTopIcon />, value: 'top' },
            { icon: <VerticalAlignCenterIcon />, value: 'center' },
            { icon: <VerticalAlignBottomIcon />, value: 'bottom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.columnAlign', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonOption
          label={'Swap Columns'}
          buttons={[
            { icon: getIcon(leftType) },
            { icon: <SwapHorizIcon />, onClick: () => handleSwapClick() },
            { icon: getIcon(rightType) }
          ]}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Left Content Type'}
          value={leftType}
          buttons={[
            { icon: <ImageSmallIcon />, value: 'image' },
            { icon: <DescriptionIcon />, value: 'text' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.leftType', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Right Content Type'}
          value={rightType}
          buttons={[
            { icon: <ImageSmallIcon />, value: 'image' },
            { icon: <DescriptionIcon />, value: 'text' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.rightType', value)}
        />
      ),
      tab: 'content'
    }
  ]

  return options
}

export default TwoColumnOptions

//   one day
{
  /* <Select
    variant={'outlined'}
    label={'Right Content Type'}
    onChange={(ev) => handleEditField('section.fields.rightType', ev.target.value as string)}
    value={rightType}
  >
    <PopupMenuItem icon={ImageSmallIcon} value="image">
      Media
    </PopupMenuItem>
    <PopupMenuItem icon={TextFieldsIcon} value="text">
      Text
    </PopupMenuItem>
    <PopupMenuItem icon={CodeIcon} value="custom">
      Custom
    </PopupMenuItem>
  </Select>
  <Select
    variant={'outlined'}
    label={'Left Content Type'}
    onChange={(ev) => handleEditField('section.fields.leftType', ev.target.value as string)}
    value={leftType}
  >
    <PopupMenuItem icon={ImageSmallIcon} value="image">
      Media
    </PopupMenuItem>
    <PopupMenuItem icon={TextFieldsIcon} value="text">
      Text
    </PopupMenuItem>
    <PopupMenuItem icon={CodeIcon} value="custom">
      Custom
    </PopupMenuItem>
  </Select> */
}
