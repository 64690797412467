import map from 'lodash/map'
import escapeRegExp from 'lodash/escapeRegExp'
import reduce from 'lodash/reduce'

export interface TemplateArgs {
  text: string
  context: Context
}

export interface Context {
  [key: string]: ContextValue
}

export type ContextValue = string | (() => string) | number | (() => number)

/**
 * Replaces {{}} tags in the text using the values supplied in context
 */
export function template(args: TemplateArgs) {
  const { text, context } = args

  const contextValues = processContext(context)

  let compiledText = text

  map(contextValues, (value, key) => {
    compiledText = compiledText.replace(new RegExp(`{{${key}}}`, 'g'), value ? `${value}` : '')
  })

  // Preserves newlines
  return compiledText.replace(/(\r\n|\n|\r)/gm, '<br />')
}

/**
 * The opposite of template, replaces values in the text with {{}} tags
 */
export function untemplate(args: TemplateArgs) {
  const { text, context } = args

  const contextValues = processContext(context)

  let compiledText = text

  map(contextValues, (value, key) => {
    const replace = value ? `{{${key}}}` : ''
    compiledText = compiledText.replace(new RegExp(escapeRegExp(`${value}`), 'g'), replace)
  })

  return compiledText
}

/**
 * Enumerates through a Context object and runs any functional values to get the result.
 * If a functional value returns undefined or null, the value is omitted.
 */
function processContext(context: { [key: string]: ContextValue }) {
  return reduce(
    context,
    (values: { [key: string]: string | number }, contextValue, key) => {
      if (typeof contextValue === 'function') {
        const value = contextValue()
        if (value !== undefined || value !== null) {
          values[key] = value
        }
      } else {
        values[key] = contextValue
      }

      return values
    },
    {}
  )
}
