import React from 'react'
import type { UserPreferencesDocument, Auth0UserWithClaims, AppMetadata, Permission } from 'paintscout'

// legacy
export interface UserProfile {
  _id: string
  firstName: string
  lastName: string
  phoneNumber: string
  title?: string
  email: string
  preferences: UserPreferencesDocument
  roles?: string[]
  active?: boolean
  company?: AppMetadata['company']
}

export interface UserValue {
  user?: Auth0UserWithClaims
  preferences?: UserPreferencesDocument
  profile?: UserProfile
  highestRole?: string
  isDirty?: boolean
  isSuperadmin?: boolean
  isAdmin?: boolean
  isSales?: boolean
  isCrew?: boolean
  isViewer?: boolean
  hasPermissions?: (permissions: Permission[], any?: boolean) => boolean
  updateTrialExpired?: (date: number) => void
  isTrial?: boolean
  billingId?: string
  nonBilling?: boolean
  trialExpired?: boolean
  trialExpiry?: number
  subscription?: AppMetadata['company']['subscription']
  activeSubscription?: boolean
  inactiveSubscription?: boolean
  remainingQuotes?: number
}

const UserContext = React.createContext<UserValue>({})

export default UserContext
