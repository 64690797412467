/**
 * @module builder
 */

import type { QuoteStatus } from 'paintscout'

export const QUOTE_STATUS_OPTIONS = [
  { value: 'draft' as QuoteStatus, label: 'Draft', primary: true },
  { value: 'sent' as QuoteStatus, label: 'Sent', primary: true },
  { value: 'onHold' as QuoteStatus, label: 'On Hold', primary: false },
  { value: 'viewed' as QuoteStatus, label: 'Viewed', primary: true },
  { value: 'accepted' as QuoteStatus, label: 'Accepted', primary: true },
  { value: 'declined' as QuoteStatus, label: 'Declined', primary: true },
  { value: 'invoiced' as QuoteStatus, label: 'Accepted & Invoiced', primary: false }
]
export const INVOICE_STATUS_OPTIONS = [
  { value: 'draft' as QuoteStatus, label: 'Draft', primary: true },
  { value: 'sent' as QuoteStatus, label: 'Sent', primary: true },
  { value: 'viewed' as QuoteStatus, label: 'Viewed', primary: true },
  { value: 'partial' as QuoteStatus, label: 'Partial', primary: true },
  { value: 'paid' as QuoteStatus, label: 'Paid', primary: true }
]

export const CLOSED_STATUSES = ['accepted', 'declined', 'invoiced', 'onHold', 'paid', 'disputed', 'partial']

export function getStatusOptions(args?: { isInvoice?: boolean; all?: boolean }) {
  const { isInvoice, all } = args ?? {}
  if (all) {
    return [...QUOTE_STATUS_OPTIONS, ...INVOICE_STATUS_OPTIONS]
  } else if (isInvoice) {
    return INVOICE_STATUS_OPTIONS
  } else {
    return QUOTE_STATUS_OPTIONS
  }
}
