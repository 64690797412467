import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { InputLabelProps as CoreInputLabelProps } from '@ui/core/InputLabel'
import CoreInputLabel from '@ui/core/InputLabel'

const useStyles = makeStyles<Theme, InputLabelProps>((theme) => ({
  label: {
    ...theme.typography.overline,
    whiteSpace: 'nowrap',
    fontSize: '1.0rem',
    color: theme.palette.common.black,
    lineHeight: '1.2rem'
  },
  labelFocused: {
    color: theme.palette.common.black
  },
  sublabel: {
    ...theme.typography.body1,
    color: theme.palette.secondary.main,
    '&$sublabelFocused': { color: theme.palette.grey[400] },
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap'
    }
  },
  sublabelFocused: {}
}))

export interface InputLabelProps extends Omit<CoreInputLabelProps, 'classes' | 'variant'> {
  classes?: CoreInputLabelProps['classes'] & StyleClasses<typeof useStyles>
  variant?: 'label' | 'sublabel'
}

function InputLabel(props: InputLabelProps) {
  const classes = useStyles(props)
  const { variant, ...baseInputLabelProps } = props
  const { label, labelFocused, sublabel, sublabelFocused, ...baseClasses } = classes

  return (
    <CoreInputLabel
      classes={{
        root: variant === 'sublabel' ? sublabel : label,
        focused: variant === 'sublabel' ? sublabelFocused : sublabelFocused,
        ...baseClasses
      }}
      {...baseInputLabelProps}
    />
  )
}

export default InputLabel
