import type { AreaSubstrate, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { roundHours } from '../util/round-hours'
import { productVolume } from './product-volume'
import { roundPriceQuoteOptions } from '../util'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'

export function quantitySubstrate(substrate: AreaSubstrate, quote: QuoteDocument) {
  const quantity = parseNumber(substrate.quantity, 1)
  const prep = parseNumber(substrate.prep)
  const calculationType = substrate.rate.calculation_type || 'value_per_hour'
  const coats = parseNumber(substrate.coats)
  const rateValue = parseNumber(substrate.rate_value)
  const hourlyRate = parseNumber(
    substrate.overrideHourlyRate && typeof substrate.hourlyRate === 'number' ? substrate.hourlyRate : quote.hourly_rate
  )
  const product = substrate.product

  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}

  productVolume(
    product,
    'quantity',
    1,
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  if (calculationType === 'price_per_value') {
    let defaultPrice = rateValue * quantity + hourlyRate * prep
    let hours = 0
    if (substrate.override_hours) {
      if (typeof substrate.override_hours_value !== 'undefined') {
        hours = parseNumber(substrate.override_hours_value)
      } else {
        hours = parseNumber(substrate.hours)
      }
      defaultPrice = hourlyRate * (prep + hours)
    }

    let price = defaultPrice
    if (substrate.override_price) {
      if (substrate.override_price_value) {
        price = parseNumber(substrate.override_price_value)
      } else {
        price = parseNumber(substrate.price)
      }
    }

    defaultPrice = roundPriceQuoteOptions(defaultPrice, quote)

    return {
      ...substrate,
      hours,
      price,
      default_price: defaultPrice,
      default_hours: 0
    }
  } else if (calculationType === 'hours_per_value') {
    const hours = quantity / rateValue
    const defaultHours = roundHours(hours, quote, 'quantity')
    let calculationHours = defaultHours
    if (substrate.override_hours) {
      if (typeof substrate.override_hours_value !== 'undefined') {
        calculationHours = substrate.override_hours_value
      } else {
        calculationHours = substrate.hours
      }
    }

    let price = hourlyRate * (calculationHours + prep)
    if (substrate.override_price) {
      if (substrate.override_price_value) {
        price = parseNumber(substrate.override_price_value)
      } else {
        price = parseNumber(substrate.price)
      }
    }

    price = roundPriceQuoteOptions(price, quote)

    return {
      ...substrate,
      hours: defaultHours,
      price
    }
  } else {
    const hours = quantity * rateValue
    const defaultHours = roundHours(hours, quote, 'quantity')

    let calculationHours = defaultHours
    if (substrate.override_hours) {
      if (typeof substrate.override_hours_value !== 'undefined') {
        calculationHours = substrate.override_hours_value
      } else {
        calculationHours = substrate.hours
      }
    }

    let defaultPrice = hourlyRate * (calculationHours + prep)

    let price = defaultPrice
    if (substrate.override_price) {
      if (typeof substrate.override_price_value !== 'undefined') {
        price = parseNumber(substrate.override_price_value)
      } else {
        price = parseNumber(substrate.price)
      }
    }

    defaultPrice = roundPriceQuoteOptions(defaultPrice, quote)

    return {
      ...substrate,
      hours: calculationHours,
      price,
      default_hours: defaultHours,
      default_price: defaultPrice
    }
  }
}
