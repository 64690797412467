import { gql } from 'apollo-boost'

export const UPDATE_CLIENT = gql`
  mutation ($id: String!, $meta: ClientMetaInput, $options: JSON, $internalOptions: JSON, $rates: JSON) {
    updateClient(id: $id, meta: $meta, options: $options, internalOptions: $internalOptions, rates: $rates) {
      meta {
        _id
        _rev
        notes
        delete
      }
      options
      internalOptions
      rates
    }
  }
`
