import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import Button from '../Button'
import { ConfirmationDialog } from '../dialogs'
import { useDialogs } from '../DialogStack'
import Dropzone from '../Dropzone'
import FilePreview from '../FilePreview'
import type { FilePreviewProps } from '../FilePreview'
import InputLabel from '../InputLabel'
import Spinner from '../Spinner'
import type { StyleClasses } from '../styles'
import type { QuoteFile } from 'paintscout'
import { compressFile } from '../files'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import type { UploadOptions, ApiUploadOptions } from '@paintscout/util/cloudinary'
import { uploadFile as appUploadFile } from '@ui/core'
import { useCloudinary } from '../CloudinaryProvider'
import { useUser } from '../UserProvider'

const useStyles = makeStyles<Theme, Partial<UploadImageProps>>(
  (theme) => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    size: {},
    label: {
      marginBottom: theme.spacing(1)
    },
    dropzone: {
      position: 'relative',
      flexGrow: 1,
      '& $button': {
        // material-ui will spit out a warning about not being able to find $button, but it still works
        // https://github.com/mui-org/material-ui/issues/15511
        minHeight: (props: Partial<UploadImageProps>) => (props.buttonHeight ? props.buttonHeight : 0)
      }
    },
    button: {
      height: '100%'
    },
    above: {
      display: 'flex',
      alignItems: 'space-between'
    },
    clearButton: {
      marginTop: theme.spacing()
    },
    clearWrapper: (props) => ({
      display: 'flex',
      justifyContent: props.avatar ? 'center' : 'start',
      width: '100%'
    }),
    acceptDrop: {},
    rejectDrop: {
      '& $button': {
        borderColor: theme.palette.grey.A100
      }
    },
    previewOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgba(255,255,255,0.7)'
    },
    preview: {
      width: '100%',
      height: '100%'
    },
    img: {
      '&:not($disabled)': {
        cursor: 'pointer'
      },
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    image: (props) => ({
      borderRadius: props?.avatar ? '2em' : null,
      height: props.avatar ? '28px' : null,
      width: props.avatar ? '28px' : null
    }),
    disabled: {}
  }),
  { name: 'UploadImage' }
)

export interface UploadImageProps {
  classes?: StyleClasses<typeof useStyles>
  apiUploadOptions?: ApiUploadOptions
  uploadOptions?: UploadOptions
  /**
   * use publicId if possible
   */
  src?: string
  label?: string
  className?: string
  disabled?: boolean
  publicId?: string
  format?: string
  pages?: number
  square?: boolean
  noClearButton?: boolean
  showSwapOnHover?: boolean
  accept?: string
  maxWidth?: number
  maxHeight?: number
  buttonHeight?: number
  quality?: number
  presentationOptions?: QuoteFile['presentationOptions']
  avatar?: boolean
  isUploading?: boolean
  showAllPages?: boolean
  onUpload: (file: QuoteFile) => any
  onClear: () => any
  filePreviewProps?: Partial<FilePreviewProps>
}

function UploadImage({
  className,
  src,
  publicId,
  format,
  pages,
  label,
  square,
  noClearButton,
  showSwapOnHover,
  disabled,
  isUploading,
  onClear,
  onUpload,
  maxWidth,
  maxHeight,
  buttonHeight,
  quality,
  presentationOptions,
  accept = 'image/*',
  apiUploadOptions,
  uploadOptions,
  showAllPages,
  filePreviewProps,
  ...props
}: UploadImageProps) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles({ buttonHeight, ...props })
  const [loading, setLoading] = useState(false)
  const { openDialog, dismissDialog } = useDialogs()
  const { cloudName, uploadPreset } = useCloudinary()
  const { user } = useUser()

  const [apiUploadFile, { loading: apiLoading }] = useMutation(gql`
    mutation uploadFile($file: String!, $options: UploadOptions) {
      uploadFile(file: $file, options: $options) {
        secure_url
        format
        public_id
        width
        height
        type
      }
    }
  `)

  const isLoading = isUploading || loading || apiLoading

  async function handleDrop(files: File[]) {
    try {
      setLoading(true)
      const file = await compressFile({ file: files[0], maxWidth, maxHeight, quality })
      const quoteFile = await appUploadFile({
        file,
        cloudName,
        uploadPreset,
        companyId: user.app_metadata?.companyId,
        ...uploadOptions
      })

      onUpload(quoteFile)

      if (apiUploadOptions) {
        await apiUploadFile({
          context: {
            onlineOnly: true
          },
          variables: {
            file: quoteFile.src,
            options: apiUploadOptions
          }
        })
      }
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Unable to upload image', { variant: 'error' })
    }
    setLoading(false)
  }

  return (
    <div
      {...props}
      className={classnames(
        {
          [classes.root]: true,
          [classes.disabled]: !!disabled
        },
        className
      )}
    >
      <div className={classes.above}>
        {label && (
          <InputLabel shrink={true} className={classes.label}>
            {label}
          </InputLabel>
        )}
      </div>
      <Dropzone
        className={classes.dropzone}
        onDrop={handleDrop}
        disabled={isLoading || disabled}
        accept={accept}
        buttonFullHeight={true}
        shouldRenderPreview={isLoading || !!publicId}
        renderPreview={() => {
          return (
            <div className={classes.preview}>
              {isLoading ? (
                <div className={classes.previewOverlay}>
                  <Spinner fullWidth={true} fullHeight={true} />
                </div>
              ) : (
                <FilePreview
                  classes={{ image: classes.image, size: classes.size }}
                  square={square}
                  showSwapOnHover={showSwapOnHover && !disabled}
                  showAllPages={showAllPages}
                  file={{
                    cloudinaryPublicId: publicId,
                    type: 'image',
                    format,
                    pages,
                    visibility: 'visible',
                    presentationOptions: presentationOptions || {}
                  }}
                  {...filePreviewProps}
                />
              )}
            </div>
          )
        }}
      />
      {!isLoading && !noClearButton && (
        <div className={classes.clearWrapper}>
          <Button
            className={classes.clearButton}
            disabled={!src && !publicId}
            edge={'start'}
            onClick={onClear}
            prominence={3}
          >
            Clear Image
          </Button>
        </div>
      )}
    </div>
  )

  function handleClear() {
    openDialog(ConfirmationDialog, {
      title: 'Are you sure?',
      message: 'This will clear the current image',
      onConfirm: () => {
        onClear()
        dismissDialog()
      },
      onCancel: () => dismissDialog()
    })
  }
}

export default UploadImage
