import React, { useEffect } from 'react'
import type { Editor, EditorOptions, Extensions, FocusPosition } from '@tiptap/react'
import type { Props as TippyProps } from 'tippy.js'
import { Node } from '@tiptap/core'
import { useEditor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import CharacterCount from '@tiptap/extension-character-count'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '../theme/createTheme'
import type { Context } from '@paintscout/util/templater'

export interface RTEProps {
  classes?: StyleClasses<typeof useStyles>
  value?: string

  /**
   * Disables the RTE and should be used for appropriate styling in makeStyles. If you wish to just
   * set the RTE to read-only but keep normal appearance, set editable to false in editorOptions
   **/
  disabled?: boolean

  /**
   * Disable text formatting menus on select or highlight
   * Bubble = Menu on highlighted text
   * Floating = Menu on empty line w/ cursor
   **/
  bubbleMenuDisabled?: boolean
  floatingMenuDisabled?: boolean
  menuBarEnabled?: boolean

  /* Props that may or may not be needed in SB, but they're needed for compatibility with PaintScout */
  label?: React.ReactNode
  sublabel?: React.ReactNode
  className?: string
  characterCount?: number

  fullWidth?: boolean
  maxHeight?: number
  multiline?: boolean
  templateContext?: Context
  toolbar?: {
    bold?: boolean
    italic?: boolean
    underline?: boolean
    headings?: boolean
    lists?: boolean
    template?: {
      tooltip?: string
      tags: Array<{
        key: string
        label: string
        hideValue?: boolean
      }>
    }
    link?: boolean
  }
  autofocus?: FocusPosition
  onChange?: (value: string) => void
  onBlur?: (ev?: any) => void
  onFocus?: () => void
  Toolbar?: (props: { editor: Editor }) => JSX.Element
  FloatingMenu?: (props: { editor: Editor }) => JSX.Element
  BubbleMenu?: (props: { editor: Editor }) => JSX.Element
  MenuBar?: (props: { editor: Editor }) => JSX.Element
  extensions?: Extensions
  editorOptions?: Partial<EditorOptions>
  tippyOptions?: Partial<TippyProps>
}

const useStyles = makeStyles<Theme, RTEProps>(
  (theme) => ({
    root: (props) => ({
      ...(props.label && { marginTop: theme.spacing(1) })
    }),
    noDisplay: {
      '&$noDisplay': {
        display: 'none'
      }
    }
  }),
  { name: 'RTECore' }
)

export default function RTE({
  value,
  onChange,
  onBlur,
  onFocus,
  Toolbar,
  FloatingMenu: FloatingMenuContent,
  BubbleMenu: BubbleMenuContent,
  MenuBar: MenuBarContent,
  extensions = [],
  editorOptions,
  disabled,
  autofocus,
  multiline = true,
  bubbleMenuDisabled = false,
  floatingMenuDisabled = false,
  menuBarEnabled = false,
  tippyOptions,
  characterCount,
  ...props
}: RTEProps) {
  const classes = useStyles(props)

  const editorExtensions = [
    StarterKit,
    Underline,
    TextAlign.configure({
      alignments: ['left', 'center', 'right', 'justify'],
      types: ['heading', 'paragraph'],
      defaultAlignment: 'left'
    }),
    Link.configure({
      openOnClick: false
    }),
    CharacterCount.configure({
      limit: characterCount || null
    }),
    ...extensions
  ]

  const SingleLiner = Node.create({
    name: 'singleLiner',
    topNode: true,
    content: 'block'
  })

  if (!multiline) editorExtensions.unshift(SingleLiner)

  const editor = useEditor({
    extensions: editorExtensions,
    content: value,
    editable: !disabled,
    parseOptions: {
      preserveWhitespace: true
    },
    ...(autofocus && { autofocus }),
    ...editorOptions,
    onBlur: (...args) => {
      editorOptions?.onBlur && editorOptions.onBlur(...args)
      onBlur?.(args[0].event)
    },
    onFocus: (...args) => {
      editorOptions?.onFocus && editorOptions.onFocus(...args)
      onFocus?.()
    },
    onUpdate: ({ editor, transaction }) => {
      editorOptions?.onUpdate && editorOptions.onUpdate({ editor, transaction })
      onChange?.(editor.getHTML())
    }
  })

  useEffect(() => {
    if (editor) {
      editor.setEditable(!disabled)
    }
  }, [editor, disabled])

  useEffect(() => {
    if (!(editor?.getHTML() == value)) {
      editor?.commands?.setContent(value)
    }
  }, [value])

  // appendTo makes the menus trigger a blur when a click is released on them (bad for InlineEditableHtml). fortunately it's hard to do this on Bubble and Floating doesn't need appendTo
  const { appendTo, ...tippyOptionsWithoutAppendTo } = tippyOptions || {}

  return (
    <>
      <div>{props.label}</div>
      <div>{props.sublabel}</div>
      <div className={classes.root} {...props}>
        {Toolbar && editor && <Toolbar editor={editor} {...props} />}
        {FloatingMenuContent && editor && !floatingMenuDisabled && (
          <div className={classes.noDisplay}>
            <FloatingMenu editor={editor} tippyOptions={tippyOptions ? tippyOptionsWithoutAppendTo : { zIndex: 4 }}>
              <FloatingMenuContent classes={classes} editor={editor} {...props} />
            </FloatingMenu>
          </div>
        )}
        {BubbleMenuContent && editor && !bubbleMenuDisabled && (
          <div className={classes.noDisplay}>
            <BubbleMenu editor={editor} tippyOptions={tippyOptions ? tippyOptions : { zIndex: 4 }}>
              <BubbleMenuContent classes={classes} editor={editor} {...props} />
            </BubbleMenu>
          </div>
        )}
        <EditorContent editor={editor} className={classes.editor} />
        {MenuBarContent && editor && menuBarEnabled && <MenuBarContent classes={classes} editor={editor} {...props} />}
      </div>
    </>
  )
}
