import type { QuoteArea } from 'paintscout'
import type { QuoteItemSection, RenderableSubItem } from '../../../../index'
import { filterItem } from '../../../../util'

// Return true if we want to filter out the area item
export const filterAreaItems = (
  area: QuoteArea,
  section: QuoteItemSection,
  areaSubstrates: RenderableSubItem[]
): boolean => {
  const areaTotals = area.totals
  const hasOverriddenPrice = areaTotals.override_materials || areaTotals.override_price || areaTotals.override_prep
  const allAreaSubstrateLength = Object.values(area.substrates ?? {}).reduce((acc, section) => acc + section.length, 0)

  // console.log({
  //   section,
  //   hasOverriddenPrice,
  //   allAreaSubstrateLength,
  //   filterItem: filterItem(area,section),
  //   'areaSubstrates.length':
  //   areaSubstrates.length,
  //   'area.substrate_string.use_custom': area.substrate_string.use_custom
  // })

  // *Completely* Empty area in bid section w/ overridden substrate_string exception
  if (section === 'bid' && filterItem(area, section) && !allAreaSubstrateLength && area.substrate_string.use_custom) {
    return false
  }

  // If not in given section and no areaSubstrates
  if (!filterItem(area, section) && !areaSubstrates.length) {
    return true
  }

  // Customer Bid section specific filters
  if (section === 'bid') {
    // Filter item that is in bid section if it has no substrates in bid section or overridden price
    if (filterItem(area, section) && !areaSubstrates.length && !hasOverriddenPrice) {
      return true
    }

    // If the whole area is not in the bid, we definitely don't want to show it.
    if (!filterItem(area, section)) {
      return true
    }
  }

  // For all sections filter if no areaSubstrates,
  // and no overriden description or price.
  if (!areaSubstrates.length && !hasOverriddenPrice && !area.substrate_string.use_custom) {
    return true
  }

  return false
}
