import React from 'react'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'

function SpacerSection(props: PresentationSectionProps) {
  const { WrapperComponent } = props

  return (
    <WrapperComponent {...props}>
      <div></div>
    </WrapperComponent>
  )
}

export default React.memo(SpacerSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
