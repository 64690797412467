import { makeStyles } from '@material-ui/core/styles'

import {
  // ConfirmationDialog,
  FormSection,
  FormSectionTitle,
  LearnMoreLink,
  PageHelpMessage,
  Typography,
  useDialogs,
  useOnboardingContext
} from '@ui/stickybid'
import type { TermsOption } from '@paintscout/util/builder'
import {
  // createTermsOption,
  // deleteTermsOption,
  // getObjectLabels,
  getTermsOptions,
  // getFeature,
  reorderTermsOptions
  // copyTermsOption
} from '@paintscout/util/builder'
import { useFormikContext } from 'formik'

import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import EditTermsOptionDialog from '../../dialogs/EditTermsOptionDialog'
import OptionsTileList from '../../OptionsTileList'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 5, 0, 0)
  },
  title: {
    padding: 0
  }
}))

export interface TermsOptionsProps {
  onDialogConfirm?: () => void
}

function TermsOptions({ onDialogConfirm }: TermsOptionsProps) {
  const classes = useStyles({})
  const { openDialog, dismissDialog } = useDialogs()
  const [quoteType, setQuoteType] = useState('all')
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options, rates } = values

  const { updateOptionsOnboard } = useOnboardingContext()
  const isFirstTermsUpdate = !options.options.onboardingTasks?.termsUpdated

  // const objectLabels = getObjectLabels({ options })
  // const invoiceLabels = getObjectLabels({ options, invoice: true })
  const items = getTermsOptions({ options, quoteType: quoteType, inactive: true })
  // const quoteTypes = getQuoteTypeOptions({ options, inactive: true })

  // const hasInvoices = getFeature({ options, path: 'invoices.enabled' })

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path="settings-terms"
        openContent={
          <>
            <div className={classes.titleRow}>
              <FormSectionTitle title={'Terms'} variant="h2" classes={{ root: classes.title }} />
              {/* <Select variant={'outlined'} onChange={handleQuoteTypeChange} value={quoteType}>
                <MenuItem value="all">All {objectLabels.quote.value} Types</MenuItem>
                {quoteTypes
                  .filter((quoteType: QuoteTypeOption) => {
                    return quoteType.key !== 'all'
                  })
                  .map((quoteType: QuoteTypeOption) => {
                    return (
                      <MenuItem key={quoteType.key} value={quoteType.key}>
                        {quoteType.label}
                      </MenuItem>
                    )
                  })}
              </Select> */}
            </div>
            <Typography>
              Here you can add your company contract(s).{' '}
              <LearnMoreLink link="https://www.stickybid.com/videos/#settings-terms" />
            </Typography>
            <Typography></Typography>
            <Typography>
              <em>
                P.S. make sure to select a default contract on your quote type, so it pre-populates for you on the
                quote!
              </em>
            </Typography>
          </>
        }
        closedContent={
          <div className={classes.titleRow}>
            <FormSectionTitle title={'Terms'} variant="h2" classes={{ root: classes.title }} />
            {/* <Select variant={'outlined'} onChange={handleQuoteTypeChange} value={quoteType}>
              <MenuItem value="all">All {objectLabels.quote.value} Types</MenuItem>
              {quoteTypes
                .filter((quoteType: QuoteTypeOption) => {
                  return quoteType.key !== 'all'
                })
                .map((quoteType: QuoteTypeOption) => {
                  return (
                    <MenuItem key={quoteType.key} value={quoteType.key}>
                      {quoteType.label}
                    </MenuItem>
                  )
                })}
            </Select> */}
          </div>
        }
      />
      <FormSection>
        <OptionsTileList
          title={''}
          useThumbnails={false}
          // static key for onboardingHighlight - only works while we have only one set of quote-terms
          items={items.map((item) => ({ ...item, key: item.name }))}
          // createTitle={'Add New Terms Document'}
          showCreate={false}
          onReorder={handleReorder}
          onEditItem={handleEditItem}
          // onDeleteItem={handleDeleteItem}
          // onCopyItem={handleCopyItem}
          // onCreateClick={handleCreateItem}
        />
      </FormSection>
    </div>
  )

  // function handleQuoteTypeChange(ev: React.ChangeEvent<HTMLSelectElement>) {
  //   const { value } = ev.target
  //   setQuoteType(value)
  // }

  function handleReorder(termsOptions: TermsOption[]) {
    const updatedOptions = reorderTermsOptions({ termsOptions, options: options })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getTermsOptions({ options, inactive: true })
    const item = items.find((i) => i.name === key)

    openDialog(EditTermsOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        setFieldValue(
          'options',
          isFirstTermsUpdate
            ? updateOptionsOnboard({ key: 'termsUpdated', value: true, options: updatedOptions })
            : updatedOptions
        )
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  // function handleCreateItem(event: React.MouseEvent) {
  //   const item = createTermsOption()

  //   openDialog(EditTermsOptionDialog, {
  //     options,
  //     item,
  //     onConfirm: async (updatedOptions: OptionsDocument) => {
  //       setFieldValue('options', updatedOptions)
  //       dismissDialog()
  //       if (onDialogConfirm) {
  //         onDialogConfirm()
  //       }
  //     },
  //     onCancel: () => {
  //       dismissDialog()
  //     }
  //   })
  // }

  // function handleDeleteItem(event: any, key: string) {
  //   openDialog(ConfirmationDialog, {
  //     message: (
  //       <>
  //         <p>These Terms will no longer be available to add to new {objectLabels.quote.plural}.</p>
  //         <p>Existing {objectLabels.quote.plural} will not be affected.</p>
  //       </>
  //     ),
  //     onConfirm: (ev: React.MouseEvent<HTMLElement>) => {
  //       dismissDialog()

  //       const items = getTermsOptions({ options: options, inactive: true })
  //       const updatedOptions = deleteTermsOption({ termsOption: items.find((i) => i.name === key), options })

  //       setFieldValue('options', updatedOptions)
  //     },
  //     onCancel: (ev: React.MouseEvent<HTMLElement>) => {
  //       dismissDialog()
  //     }
  //   })
  // }

  // function handleCopyItem(event: any, key: string) {
  //   const updatedOptions = copyTermsOption({ options, key })
  //   setFieldValue('options', updatedOptions)
  // }
}

export default TermsOptions
