import type { QuoteDocument } from 'paintscout'

export function isInAreaGroup({ quote, areaKey }: { quote: QuoteDocument; areaKey: string }) {
  let inAreaGroup = false
  Object.keys(quote.groups ?? []).forEach((groupKey) => {
    if (quote.groups[groupKey].children.includes(areaKey)) {
      inAreaGroup = true
    }
  })
  return inAreaGroup
}
