import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'

type SpanProps = JSX.IntrinsicElements['span']

export interface HighlightProps extends SpanProps {
  color?: 'primary' | 'secondary' | 'error' | string
  fontWeight?: 'light' | 'regular' | 'medium' | 'bold'
  capitalize?: boolean
  uppercase?: boolean
}

const useStyles = makeStyles<Theme, HighlightProps>((theme) => ({
  root: (props) => {
    let color = props.color
    let fontWeight = theme.typography.fontWeightRegular
    switch (props.color) {
      case 'primary':
        color = theme.palette.primary.main
        break
      case 'secondary':
        color = theme.palette.secondary.main
        break
      case 'error':
        color = theme.palette.error.main
    }

    switch (props.fontWeight) {
      case 'light':
        fontWeight = theme.typography.fontWeightLight
        break
      case 'regular':
        fontWeight = theme.typography.fontWeightRegular
        break
      case 'medium':
        fontWeight = theme.typography.fontWeightMedium
        break
      case 'bold':
        fontWeight = theme.typography.fontWeightBold
        break
    }
    return {
      color,
      fontWeight,
      textTransform: props.capitalize ? 'capitalize' : props.uppercase ? 'uppercase' : undefined,
      whiteSpace: 'pre-wrap'
    }
  }
}))

export default function Highlight(props: HighlightProps) {
  const { color, fontWeight, capitalize, uppercase, ...spanProps } = props
  const classes = useStyles(props)

  return <span {...spanProps} className={classnames(classes.root, props.className)} />
}

Highlight.defaultProps = {
  color: 'primary',
  fontWeight: 'bold'
}
