import type { PresentationSection } from 'paintscout'

interface TextOptionsProps {
  section: PresentationSection
  presetColors: string[]
  handleOptionFieldChange: (field: string, value: any) => void
}

function TextOptions(props: TextOptionsProps) {
  const { section, presetColors, handleOptionFieldChange } = props
  const { color } = section.fields

  const options = [
    // {
    //   Component: (
    //     <ColorOption
    //       label={'Custom Text Color'}
    //       value={color}
    //       useClearOption={true}
    //       helpTextForClear={'Clear Text Color'}
    //       defaultColor={''}
    //       clearColor={''}
    //       presetColors={presetColors}
    //       onChange={(value) => {
    //         handleOptionFieldChange(`fields.color`, value)
    //       }}
    //     />
    //   ),
    //   tab: 'color'
    // }
  ]

  return options
}

export default TextOptions
