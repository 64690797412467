import React from 'react'
import { DialogTitle, DialogActions, DialogContent } from '../Dialog'
import type { DialogProps } from '../Dialog'
import { Button, Dialog, IconButton, PdfViewer } from '../'
import { makeStyles, Hidden } from '@material-ui/core'
import type { QuoteFile } from 'paintscout'

import CloseButton from './CloseButton'
import LinkIcon from '@material-ui/icons/Link'

export interface ViewPdfDialogProps extends DialogProps {
  file: QuoteFile
  onCancel: (event: React.MouseEvent<HTMLElement>) => void
  title?: string
}

const useStyles = makeStyles(
  (theme) => ({
    pdfContent: {
      background: theme.palette.grey[200]
    }
  }),
  { name: 'ViewPdfDialog' }
)

function ViewPdfDialog(props: ViewPdfDialogProps) {
  const classes = useStyles(props)
  const { file, onCancel, title, ...rest } = props

  return (
    <Dialog fullScreen onClose={onCancel} {...rest}>
      <Hidden xsDown>
        <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>{title ?? 'View PDF'}</DialogTitle>
      </Hidden>
      <DialogContent className={classes.pdfContent}>
        <PdfViewer file={file} />
      </DialogContent>
      <DialogActions
        leftButton={
          <a href={file.src} target={'_blank'} rel={'noopener noreferrer nofollow'}>
            <IconButton>
              <LinkIcon />
            </IconButton>
          </a>
        }
      >
        <Button prominence={1} onClick={onCancel}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewPdfDialog
