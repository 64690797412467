import React from 'react'

import type { Theme, WithStyles, WithTheme } from '@material-ui/core'
import {
  withStyles,
  withTheme,
  createStyles,
  Popover,
  CardContent,
  useMediaQuery,
  CardActions
} from '@material-ui/core'
import type { PopoverProps as MuiPopoverProps } from '@material-ui/core/Popover'
import Dialog from '../Dialog'
import IconButton from '../IconButton'
import CloseIcon from '@material-ui/icons/Close'
import type { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'

export interface ResponsivePopupProps extends WithStyles<typeof styles>, WithTheme {
  open: boolean
  children: React.ReactNode
  PopoverProps?: Partial<MuiPopoverProps>
  modalBreakpoint?: keyof BreakpointValues
  footer?: React.ReactNode
  onClose?: React.ReactEventHandler<unknown>
}

const styles = (theme: Theme) =>
  createStyles({
    popover: {},
    modal: {},
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'sticky',
      top: 0,
      background: 'white',
      zIndex: 1
    },
    cardContent: {
      padding: 0
    },
    modalContent: {
      // padding: '16px !important',
      padding: theme.spacing(4, 4, 0, 4),
      height: 'calc(100% - 32px)', // 16px top padding + 24px bottom padding from CardContent
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4, 4, 0, 4)
    },
    footer: {
      position: 'sticky',
      background: 'white',
      padding: theme.spacing(4),
      zIndex: 1,
      bottom: 0
    }
  })

/**
 * A popup that will expand into a fullscreen modal on xs sized screens
 */
function ResponsivePopup({
  theme,
  classes,
  children,
  open,
  PopoverProps = {},
  onClose,
  footer,
  modalBreakpoint = 'xs'
}: ResponsivePopupProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down(modalBreakpoint))

  if (!isMobile) {
    return (
      <Popover classes={{ paper: classes.popover }} open={open} onClose={onClose} {...PopoverProps}>
        <CardContent className={classes.cardContent}>
          <div className={classes.content}>{children}</div>
        </CardContent>
        <CardActions className={classes.footer}>{footer}</CardActions>
      </Popover>
    )
  } else {
    return (
      <Dialog open={open} classes={{ root: classes.modal }} onClose={onClose}>
        <div className={classes.header}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <CardContent className={classes.modalContent}>
          <div className={classes.content}>{children}</div>
        </CardContent>
        <CardActions className={classes.footer}>{footer}</CardActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withTheme(ResponsivePopup))
