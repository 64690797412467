/**
 * @module builder
 */

import type { PresentationOption, PresentationPage } from 'paintscout'
import { uuid } from '../../../util'

export function pastePresentationPage(args: {
  insertIndex: number
  presentation: PresentationOption
  page: PresentationPage
}): PresentationOption {
  const { insertIndex, presentation, page } = args

  const newSections = page.sections.map((section) => {
    return {
      ...section,
      key: uuid()
    }
  })

  const newPage = { ...page, sections: newSections, key: uuid() }

  const updatedPages = [...presentation.pages]
  updatedPages.splice(insertIndex, 0, newPage)

  return {
    ...presentation,
    pages: updatedPages
  }
}
