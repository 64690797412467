import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const ChevronIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} {...props}>
    <svg width={'24'} height={'24'} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 4L20 12L16 20H4L8 12L4 4H16Z" />
    </svg>
  </SvgIcon>
)
export default ChevronIcon
