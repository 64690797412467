import React, { useEffect } from 'react'

import type { PromptProps } from 'react-router'
import { Prompt, useLocation } from 'react-router'

/**
 * A modification of react-router's Prompt that only checks when
 * the location.pathname has changed
 */
export default function RoutePrompt(props: PromptProps) {
  const location = useLocation()

  useEffect(() => {
    if (props.when) {
      window.onbeforeunload = () => true
    }

    return () => {
      window.onbeforeunload = () => undefined as undefined
    }
  }, [props.when])

  return (
    <Prompt
      {...props}
      message={(nextLocation, action) => {
        if (nextLocation.pathname !== location.pathname) {
          if (typeof props.message === 'function') {
            return props.message(nextLocation, action)
          } else {
            return props.message
          }
        }
      }}
    />
  )
}
