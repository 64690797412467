import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const Spacer = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 15 6'} {...props}>
    <svg>
      <path d="M0.653 0.931885C0.653 0.655742 0.876857 0.431885 1.153 0.431885H14.202C14.4781 0.431885 14.702 0.655742 14.702 0.931885V5.28188C14.702 5.55803 14.4781 5.78188 14.202 5.78188H1.153C0.876857 5.78188 0.653 5.55803 0.653 5.28188V0.931885ZM1.653 1.43188V4.78188H13.702V1.43188H1.653Z" />
    </svg>
  </SvgIcon>
)
export default Spacer
