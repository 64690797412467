import React from 'react'
import IconButton from '../IconButton'
import { createStyles, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import classnames from 'classnames'

interface CloseButtonProps {
  className?: string
  onCancel: (ev?: any) => any
  disabled?: boolean
}

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {}
  })
})

function CloseButton({ onCancel, disabled, ...props }: CloseButtonProps) {
  const classes = useStyles({ props })

  return (
    <IconButton
      className={classnames(props.className, classes.root)}
      edge={'end'}
      disabled={disabled}
      onClick={onCancel}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default CloseButton
