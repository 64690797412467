import React, { useEffect, useState } from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { usePresentationNav } from '../PresentationNav/PresentationNavContext'
import { useInView } from '@ui/core'

export default function AnimationWrapper(args: {
  active?: boolean
  animation: string
  children: React.ReactElement
  className?: string
  // direction it comes from
  direction?: 'top' | 'bottom' | 'left' | 'right'
  index: number
}) {
  const { active = true, animation, children, className, direction } = args
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const animate = !!animation && animation !== 'none' && active

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    rootMargin: isMobile ? '-120px 0px' : '-200px 0px',
    skip: !animate
  })

  const [visible, setVisible] = useState(false)
  const showing = visible && (inView || entry?.boundingClientRect.top < 200)

  const { preview } = usePresentationNav()

  const index = isMobile ? 0 : args.index

  useEffect(() => {
    // toggle visibility when animation changes to the new animation can be previewed
    setVisible(false)
    setTimeout(() => {
      setVisible(true)
    }, 100)
  }, [animation, preview])

  // from https://easings.net/
  const easeInOutCubic = 'cubic-bezier(0.65, 0, 0.35, 1)'
  const easeOutCubic = 'cubic-bezier(0.33, 1, 0.68, 1)'
  const easeOutBack = 'cubic-bezier(0.34, 1.56, 0.64, 1)'

  const getDelay = (_index: number, factor: number) => {
    const index = _index || 0
    return `${index * factor}ms`
  }

  const getAnimation = () => {
    if (!animate) return {}
    if (animation === 'fade') {
      return { opacity: showing ? 1 : 0, transition: showing ? `1.6s ${easeInOutCubic} all` : 'none' }
    }
    if (animation === 'zoom') {
      return {
        opacity: showing ? 1 : 0,
        transform: showing ? 'scale3d(1, 1, 1)' : 'scale3d(0.2, 0.2, 0.2)',
        transition: showing
          ? `opacity 1.3s ${easeInOutCubic} ${getDelay(index, 260)}, transform 1.6s ${easeOutCubic} ${getDelay(
              index,
              260
            )}`
          : 'none'
      }
    }
    if (animation === 'slide' || animation === 'spring') {
      let origin = '0, max(-90%, -300px), 0'

      switch (direction) {
        case 'top':
          origin = '0, max(-90%, -300px), 0'
          break
        case 'bottom':
          origin = '0, min(90%, 300px), 0'
          break
        case 'left':
          origin = 'max(-90%, -300px), 0, 0'
          break
        case 'right':
          origin = 'min(90%, 300px), 0, 0'
          break
      }

      return {
        opacity: showing ? 1 : 0,
        transform: showing ? 'translate3d(0, 0, 0)' : `translate3d(${origin})`,
        transition: showing
          ? `opacity 0.8s ${easeInOutCubic} ${getDelay(index, 260)}, transform 1.6s ${
              animation === 'spring' ? easeOutBack : easeOutCubic
            } ${getDelay(index, 260)}`
          : 'none'
      }
    }
  }

  return (
    <div ref={ref} style={getAnimation()} className={className}>
      {children}
    </div>
  )
}
