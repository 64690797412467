import React from 'react'
import type { QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../..'
import { InlineEditableHtml, InlineEditableMedia } from '../../../inlineEditable'
import { getSectionPadding } from '../../util'
import compareSectionProps from '../../util/compareSectionProps'
import AnimationWrapper from '../../util/AnimationWrapper'
import classnames from 'classnames'
import { usePresentation } from '../../../context'

const useStyles = makeStyles<Theme, PresentationSectionProps & { useGlassEffect?: boolean }>(
  (theme: Theme) => {
    return {
      root: {},
      customColor: (
        props,
        color = props.section?.fields?.useContentBackground
          ? props.section.fields.contentColor
          : props.section.fields.color
      ) => ({
        ...(color && {
          '& h1': {
            color: `${color} !important`
          },
          '& h2': {
            color: `${color} !important`
          },
          '& h3': {
            color: `${color} !important`
          },
          '& p': {
            color: `${color} !important`
          },
          '& ol': {
            color: `${color} !important`
          },
          '& ul': {
            color: `${color} !important`
          }
        })
      }),
      content: (props) => ({
        textShadow: props.section?.fields?.useContentBackground ? 'none' : `0 0 9px black`
      }),
      wrapper: {
        position: 'relative',
        overflow: 'hidden',
        padding: theme.spacing(10, 0)
      },
      imageWrapper: {
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      },
      contentWrapper: (props) => ({
        textAlign: 'center',
        padding: getSectionPadding({ section: props.section, source: 'section', size: 'desktop', contentWidth: 'md' }),
        position: 'relative',
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
          padding: getSectionPadding({ section: props.section, source: 'section', size: 'tablet', contentWidth: 'md' })
        },
        [theme.breakpoints.down('sm')]: {
          padding: getSectionPadding({ section: props.section, source: 'section', size: 'mobile', contentWidth: 'md' })
        }
      }),
      contentSubwrapper: (
        props,
        useContentBackground = props.section?.fields?.useContentBackground,
        contentBackgroundColor = props.section?.fields?.contentBackgroundColor,
        useGlassEffect = props.useGlassEffect && CSS.supports('backdrop-filter', 'blur(4px)')
      ) => ({
        textAlign: 'left',
        transition: 'all .3s ease',
        ...(useContentBackground && {
          backgroundColor: useGlassEffect ? 'rgba(255, 255, 255, .25)' : contentBackgroundColor,
          backdropFilter: useGlassEffect ? 'blur(5px) saturate(80%) contrast(80%) brightness(130%)' : '',
          padding: theme.spacing(8),
          display: 'inline-block',
          margin: '0 auto'
        }),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2)
        }
      }),
      fullSize: {
        height: '100%',
        width: '100%'
      },
      placeholder: (props, isEditable = props.isEditable) => ({
        display: isEditable ? 'flex' : 'none',
        height: 75,
        margin: theme.spacing(1, 0)
      }),
      logoWrapper: (props, logoAlignment = props.section?.fields?.logoAlignment) => ({
        textAlign: logoAlignment,
        width: '100%'
      }),
      logo: (props, logoSize = props.section?.fields?.logoSize) => ({
        ...(logoSize === 'lg' && {
          maxHeight: 300,
          maxWidth: 400,
          objectFit: 'cover'
        }),
        ...(logoSize === 'md' && {
          maxHeight: 200,
          maxWidth: 300,
          objectFit: 'cover'
        }),
        ...(logoSize === 'sm' && {
          maxHeight: 100,
          maxWidth: 200,
          objectFit: 'cover'
        })
      })
    }
  },
  { name: 'LogoSection' }
)

interface LogoSectionProps extends PresentationSectionProps {
  logo: string
}

function LogoSection(props: LogoSectionProps) {
  const {
    isEditable,
    isSettings,
    logo,
    presentationIndustry,
    presetColors,
    section,
    sectionPrefix,
    setFieldValue,
    submitForm,
    WrapperComponent
  } = props

  const useGlassEffect = section?.fields?.useGlassEffect && CSS.supports('backdrop-filter', 'blur(4px)')
  const classes = useStyles({ useGlassEffect, ...props })
  const { animation, content, contentTop, image, useContentBackground, color, contentColor, contentBackgroundColor } =
    section.fields
  const customColor = (useContentBackground && contentColor) || (!useContentBackground && color)

  const isAnimated = ['slide', 'fade', 'zoom'].includes(animation)

  const { presentationLibrary } = usePresentation()

  const handleEditField = (field: string, content: string | QuoteFile) => {
    setFieldValue(`${sectionPrefix}.${field}`, content)
    submitForm()
  }

  return (
    <WrapperComponent {...props}>
      <div className={classes.wrapper}>
        <AnimationWrapper animation={animation} index={0} direction={'right'} className={classes.imageWrapper}>
          <InlineEditableMedia
            mediaOptionsProps={{
              sectionPrefix: sectionPrefix,
              fieldSuffix: 'image'
            }}
            accept={'image/*'}
            classes={{ root: classes.fullSize, image: classes.fullSize, size: classes.fullSize }}
            file={image}
            getFocusFromSection={true}
            handleEdit={(file) => handleEditField('fields.image', file)}
            isAnimated={isAnimated}
            isEditable={isEditable}
            isSettings={isSettings}
            placeholderButtonText={'Add Image'}
            presentationIndustry={presentationIndustry}
            presentationLibrary={presentationLibrary}
            youtube={false}
          />
        </AnimationWrapper>

        <div className={classes.contentWrapper}>
          <AnimationWrapper animation={animation} index={1} direction={'bottom'}>
            <div
              className={classnames(classes.contentSubwrapper, {
                ['themeBackground']: useContentBackground && !useGlassEffect && !contentBackgroundColor
              })}
            >
              <InlineEditableHtml
                classes={{
                  root: classnames({ [classes.content]: true, [classes.customColor]: !!customColor }),
                  placeholder: classes.placeholder
                }}
                isEditable={isEditable}
                isSettings={isSettings}
                handleEdit={(content: string) => handleEditField('fields.contentTop', content)}
                content={contentTop}
                placeholderButtonText={'Add Text (Optional)'}
                presetColors={presetColors}
              />
              <div className={classes.logoWrapper}>
                <img className={classes.logo} src={logo} />
              </div>
              <InlineEditableHtml
                classes={{
                  root: classnames({ [classes.content]: true, [classes.customColor]: !!customColor }),
                  placeholder: classes.placeholder
                }}
                isEditable={isEditable}
                isSettings={isSettings}
                handleEdit={(content: string) => handleEditField('fields.content', content)}
                content={content}
                placeholderButtonText={'Add Text (Optional)'}
                presetColors={presetColors}
              />
            </div>
          </AnimationWrapper>
        </div>
      </div>
    </WrapperComponent>
  )
}

export default React.memo(LogoSection, (prevProps, nextProps) => {
  if (prevProps.logo !== nextProps.logo) return false
  return compareSectionProps(prevProps, nextProps)
})
