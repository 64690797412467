import type { QuoteArea, QuoteDocument } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import { getItemDetails } from '../../util/get-item-details'
import type { RenderableItemSubstrate } from '../../../../area-substrates'
import { getCrewDescription } from '../../util/get-crew-description'
import getDimensionString from './get-dimension-string'

interface toRenderableCrewAreaSubstrateProps {
  key: string
  areaSubstrate: RenderableItemSubstrate
  quote: QuoteDocument
  area: QuoteArea

  showValues: boolean
  hideDimensions?: boolean
}

/**
 * Convert AreaSubstrate into a RenderableSubItem
 * to be viewed by crew within an Area on WorkOrders
 */
export default function toRenderableCrewAreaSubstrate({
  key,
  areaSubstrate,
  quote,
  area,

  showValues = true,
  hideDimensions = false
}: toRenderableCrewAreaSubstrateProps): RenderableSubItem {
  const { prep, hours, price, totalPrice, clientLabel, itemName } = getItemDetails({
    item: areaSubstrate
  })

  const { crewDescription, productFields } = getCrewDescription({
    product: areaSubstrate.product,
    prep,
    hours,
    itemName,
    clientLabel,
    showValues,
    options: quote.options
  })
  let name = clientLabel ? clientLabel : itemName
  const coats = areaSubstrate?.coats ?? 0

  const dimensionString = getDimensionString({
    areaSubstrate,
    area,
    quote,
    priceValue: price
  })

  if (dimensionString && !hideDimensions) {
    name = `${name} (${dimensionString})`
  }

  const r = {
    key,
    name,
    description: name === crewDescription ? '' : crewDescription,
    crewNote: areaSubstrate.notes,
    value: areaSubstrate.totalHours,
    reportText: areaSubstrate.areaReportText,
    additionalFields: {
      prep,
      painting: hours,
      price: totalPrice,
      coats,
      materials: productFields ? [productFields] : []
    }
  } as RenderableSubItem

  return r
}
