import type { PresentationSectionProps } from '../../'
import isEqual from 'lodash/isEqual'

export default function compareSectionProps(
  prevProps: PresentationSectionProps,
  nextProps: PresentationSectionProps
): boolean {
  // propsAreEqual?

  if (prevProps.editFromDialog !== nextProps.editFromDialog) return false
  // ** only need to rerender sections on focus if they are gaining or losing it
  if (
    (prevProps.section.key === prevProps.focusedSection || prevProps.section.key === nextProps.focusedSection) &&
    prevProps.focusedSection !== nextProps.focusedSection
  )
    return false
  if (prevProps.hasSectionOptions !== nextProps.hasSectionOptions) return false
  if (prevProps.index !== nextProps.index) return false
  if (prevProps.isEditable !== nextProps.isEditable) return false
  if (prevProps.isPreview !== nextProps.isPreview) return false
  if (prevProps.isSettings !== nextProps.isSettings) return false
  if (prevProps.pageIndex !== nextProps.pageIndex) return false
  if (prevProps.sectionPrefix !== nextProps.sectionPrefix) return false
  if (prevProps.showHelp !== nextProps.showHelp) return false
  if (prevProps.size !== nextProps.size) return false
  if (prevProps.useCart !== nextProps.useCart) return false

  if (!isEqual(prevProps.presentationContentStyles, nextProps.presentationContentStyles)) return false
  if (!isEqual(prevProps.presetColors, nextProps.presetColors)) return false
  if (!isEqual(prevProps.section, nextProps.section)) return false
  if (!isEqual(prevProps.objectLabels, nextProps.objectLabels)) return false
  // setFieldValue doesn't change
  // submitForm doesn't change
  // WrapperComponent doesn't itself change, and takes the same props as above

  return true
}
