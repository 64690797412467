import Compressor from 'compressorjs'

export interface CompressFileOptions {
  file: File
  maxWidth?: number
  maxHeight?: number
  quality?: number
}

export default async function compressFile({ file, maxWidth = 1920, maxHeight = 4096, quality }: CompressFileOptions) {
  if (!file.type || !file.type.startsWith('image')) {
    return file
  }

  if (file.type === 'image/gif') {
    return file
  }

  const newFile = await new Promise<File>((res, rej) => {
    new Compressor(file, {
      // https://www.npmjs.com/package/compressorjs#quality
      ...(quality && { quality }),
      maxWidth,
      maxHeight,
      success(result) {
        res(result as File)
      },
      error: rej
    })
  })

  return newFile
}
