import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import NoteIcon from '@material-ui/icons/CommentOutlined'
import { DeleteIcon } from '@ui/core/icons'
import EditIcon from '@material-ui/icons/EditOutlined'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import {
  ActionButton,
  Collapse,
  ConfirmationDialog,
  DialogStackContext,
  FormSectionTitle,
  IconButton,
  MenuItem,
  PopupMenu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useClientOptions,
  useMediaQuery
} from '@ui/stickybid'
import {
  getActivePayments,
  getPayments,
  getQuoteOptions,
  hasIntegrationInterface,
  setPayments
} from '@paintscout/util/builder'
import get from 'lodash/get'
import moment from 'moment'
import type { Payment, QuoteDocument } from 'paintscout'
import React, { useContext } from 'react'

import MoneyIcon from '@material-ui/icons/AttachMoney'
import PaymentIcon from '@material-ui/icons/Payment'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import { QuoteContext } from '../../../../context/QuoteContext'
import AddItemButton from '../../../../ItemTable/AddItemButton'
import { CollectPaymentDialog, ConfigurePaymentDialog, PaymentsDialog, ViewPaymentDialog } from '../../../../dialogs'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& thead': {
        // background: theme.palette.grey[200],
      },
      '& thead th, & thead th span': {
        color: theme.palette.secondary.main
        // color: theme.palette.grey[800]
      },
      '& tbody td': {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`
      }
    },
    headRow: {
      borderBottomColor: theme.palette.grey[400]
    },
    readOnly: {
      '& tr': {
        cursor: 'default'
      }
    },
    titleRoot: {
      borderBottom: '1px solid #888',
      marginBottom: theme.spacing(1),
      paddingBottom: 12,
      [theme.breakpoints.down('md')]: {
        marginBottom: 0
      }
    },
    mobileTitleValue: {
      fontWeight: theme.typography.fontWeightMedium
    },
    mobileIcons: {
      fontSize: 'inherit',
      verticalAlign: 'middle',
      marginRight: theme.typography.pxToRem(4)
    },
    mobileValueContainer: {
      ...theme.typography.body2,
      // color: theme.palette.grey[600],
      width: '100%'
    },
    mobileValue: {
      marginLeft: theme.typography.pxToRem(10)
    },
    mobileEstimatorContainer: {
      ...theme.typography.body2,
      color: theme.palette.grey[500],
      width: '100%'
    },
    fixedCell: {
      width: 100
    },
    dateCell: {
      width: 115
    },
    actionCell: {
      '&$actionCell': {
        paddingBottom: 0,
        paddingTop: 0
      }
    },
    actionRoot: {
      '& :hover': {
        color: theme.palette.primary.main
      }
    },
    actionButton: {
      color: theme.palette.secondary.main
    },
    addItemButton: {
      marginTop: theme.spacing(2)
    }
  })
}

export interface PaymentsTableProps extends WithStyles<typeof styles> {
  collapsed?: boolean
  onChange: (quote: QuoteDocument) => void
  onToggle?: (ev: any) => void
  inPresentation: boolean
}

function PaymentsTable({ classes, collapsed, onToggle, onChange, inPresentation }: PaymentsTableProps) {
  const { quote, isEditable: isEditableContext, updateQuote } = useContext(QuoteContext)
  const { openDialog, dismissDialog, dismissAllDialogs } = useContext(DialogStackContext)
  const { options } = useClientOptions()
  const isEditable = isEditableContext && !inPresentation
  const hasBalanceDue = quote.totals.balance_due && !inPresentation

  const { allowPaymentsOnInvoices } = getQuoteOptions({ quote, options })
  const allowCreditCardPayments = hasIntegrationInterface({ options, integration: 'stripe' }) && allowPaymentsOnInvoices

  const dateFormat = get(options, 'options.dateFormat.momentValue')
  const activePayments = getActivePayments({ quote })
  const mdDown = useMediaQuery('md')
  const isPrint = useMediaQuery('print')
  const renderFullSize = !mdDown || isPrint

  if (collapsed && !isEditable) {
    return null
  }

  function handleActionClick(actionKey: string, id: string) {
    const payments = getPayments({ quote })
    const index = payments.findIndex((payment) => payment.id === id)

    switch (actionKey) {
      case 'delete':
        openDialog(ConfirmationDialog, {
          message: 'Are you sure you want to remove this payment?',
          onConfirm: () => {
            dismissDialog()
            payments[index] = { ...payments[index], deleted: true }

            const updatedQuote = setPayments({ quote, options, payments })
            onChange(updatedQuote)
          },
          onCancel: dismissDialog
        })
        break
      case 'edit':
        handleEditPayment(payments[index])
        break
    }
  }

  function handleViewStripePayment(activityId: string) {
    openDialog(ViewPaymentDialog, {
      activityId,
      onClose: dismissDialog,
      onCancel: dismissDialog
    })
  }

  function handleEditPayment(payment?: Payment) {
    const payments = getPayments({ quote })
    const index = payments.findIndex((_payment) => _payment.id === payment?.id)

    if (payment?.activityId) {
      handleViewStripePayment(payment?.activityId)
    } else {
      openDialog(PaymentsDialog, {
        paymentIndex: index === -1 ? undefined : index,
        onConfirm: dismissDialog,
        onCancel: dismissDialog
      })
    }
  }

  function handleConfigurePayment() {
    openDialog(ConfigurePaymentDialog, {
      quote,
      onCancel: dismissDialog,
      onConfirm: handleCollectPayment
    })
  }

  function handleCollectPayment({ amount, note }: { amount: number; note: string }) {
    openDialog(CollectPaymentDialog, {
      quote,
      amount,
      note,
      source: 'collect-payment',
      onCancel: dismissDialog,
      onConfirm: (updatedQuote: QuoteDocument) => {
        updateQuote({ quote: updatedQuote })
        dismissAllDialogs()
      }
    })
  }

  return (
    <div className={`${!isEditable ? classes.readOnly : ''} ${classes.root}`}>
      <FormSectionTitle
        classes={{ root: classes.titleRoot }}
        variant={'h2'}
        title={'Payments'}
        showToggle={false}
        toggleValue={!collapsed}
        onToggle={onToggle}
      />
      <Collapse show={!collapsed}>
        <Table component="table">
          {!!activePayments.length && renderFullSize && (
            <TableHead component="thead">
              <TableRow className={classes.headRow} component="tr">
                <TableCell component="th" className={classes.fixedCell}>
                  Date
                </TableCell>
                <TableCell component="th" className={classes.fixedCell}>
                  Method
                </TableCell>
                <TableCell component="th">Note</TableCell>
                <TableCell component="th" className={classes.fixedCell} align="right">
                  Receipt
                </TableCell>
                <TableCell component="th" className={classes.fixedCell} align="right">
                  Amount
                </TableCell>
                {/* action column */}
                {isEditable && <TableCell component="th" />}
              </TableRow>
            </TableHead>
          )}
          <TableBody component="tbody">
            {activePayments.map((payment, index) => (
              <TableRow
                component="tr"
                key={index}
                noBorder={isEditable && index === activePayments.length - 1}
                onClick={isEditable ? () => handleEditPayment(payment) : null}
              >
                {renderFullSize && (
                  <>
                    <TableCell component="td" className={classes.dateCell}>
                      {moment(payment.date).format(dateFormat)}
                    </TableCell>
                    <TableCell component="td">{payment.type}</TableCell>
                    <TableCell component="td">{payment.note}</TableCell>
                    <TableCell component="td" align="right">
                      {payment.receipt_url ? (
                        <a
                          onClick={(ev) => {
                            ev.stopPropagation()
                          }}
                          href={payment.receipt_url}
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <IconButton edge={'end'} size={'small'}>
                            <OpenInNewIcon style={{ height: 18, width: 18 }} />
                          </IconButton>
                        </a>
                      ) : null}
                    </TableCell>
                    <TableCell component="td" align="right">
                      <Typography
                        style={{ fontWeight: 500 }}
                        value={payment.amount}
                        format={'price'}
                        showUnits
                        variant={'body1'}
                      />
                    </TableCell>
                    {isEditable && (
                      <TableCell className={classes.actionCell} isControl component="td" align="right">
                        <ActionButton
                          classes={{ root: classes.actionRoot, button: classes.actionButton }}
                          edge={'end'}
                          size={'small'}
                          actions={[
                            {
                              key: 'edit',
                              icon: EditIcon,
                              label: 'Edit'
                            },
                            {
                              key: 'delete',
                              icon: DeleteIcon,
                              label: 'Delete'
                            }
                          ]}
                          onActionClick={(ev, actionKey) => {
                            handleActionClick(actionKey, payment.id)
                          }}
                          disabled={!!activePayments[index]?.activityId}
                        />
                      </TableCell>
                    )}
                  </>
                )}

                {/* mobile column */}
                {!renderFullSize && (
                  <>
                    <TableCell component="td">
                      <div className={classes.mobileTitleValue}>
                        <ReceiptOutlinedIcon className={classes.mobileIcons} /> {payment.type}
                        {payment.amount && payment.amount !== 0 && (
                          <span>
                            <Typography value={payment.amount} format={'price'} showUnits />
                          </span>
                        )}
                      </div>
                      <div className={classes.mobileValueContainer}>
                        <EventOutlinedIcon className={classes.mobileIcons} /> {moment(payment.date).format(dateFormat)}
                        {payment.note && (
                          <div>
                            <NoteIcon className={classes.mobileIcons} /> {payment.note}
                          </div>
                        )}
                        {payment.receipt_url && (
                          <div>
                            <a
                              onClick={(ev) => {
                                ev.stopPropagation()
                              }}
                              href={payment.receipt_url}
                              target={'_blank'}
                              rel={'noreferrer'}
                              style={{ textDecoration: 'none' }}
                            >
                              <OpenInNewIcon className={classes.mobileIcons} />{' '}
                              <span style={{ textDecoration: 'underline' }}>Open Receipt</span>
                            </a>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(hasBalanceDue || isEditable) && (
          <PopupMenu
            component={
              <AddItemButton onClick={(ev) => ev.stopPropagation()} className={classes.addItemButton}>
                Add Payment
              </AddItemButton>
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem key="add-payment" icon={MoneyIcon} onClick={() => handleEditPayment()}>
              Add Payment
            </MenuItem>
            {allowCreditCardPayments && (
              <MenuItem key="collect-payment" icon={PaymentIcon} onClick={handleConfigurePayment}>
                Collect Credit Card Payment
              </MenuItem>
            )}
          </PopupMenu>
        )}
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(PaymentsTable)
