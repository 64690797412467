import type { ThemeOptions } from '@material-ui/core/styles/createTheme'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import type { ActivityType, QuoteStatus } from 'paintscout'
import merge from 'lodash/merge'
import type { Theme } from '@material-ui/core'

export type { ThemeOptions }

export function createTheme(options: ThemeOptions = {}) {
  const grey = '#AAAAAA'
  const white = '#fff'

  const statusColors = {
    sent: '#f9cc67',
    created: '#50a9cc',
    viewed: '#9065fc',
    accepted: '#60b948',
    declined: '#fc2e06',
    disputed: '#fc2e06',
    partial: '#8A63A4',
    paid: '#60B948',
    invoiced: '#60B948',
    draft: '#AAAAAA',
    onHold: '#ff9f00'
  }

  const severityColors = {
    warning: {
      main: '#f6b51e', // a little darker than statusColors.sent
      light: '#fdefce',
      dark: '#635129',
      contrastText: white
    },
    success: {
      main: statusColors.accepted,
      light: '#d3f8e2',
      dark: '#0d5229',
      contrastText: white
    },
    error: {
      main: statusColors.declined,
      light: '#fed5cd',
      dark: '#641202',
      contrastText: white
    },
    info: {
      main: statusColors.created,
      light: '#d7ecf4',
      dark: '#1f4351',
      contrastText: white
    }
  }

  const baseThemeOptions: ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1024,
        xl: 1280
      }
    },
    borderRadius: {
      none: '0',
      sm: '0.125rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      full: '9999px'
    },
    size: {
      '0': '0px',
      px: '1px',
      '0.5': '0.125rem',
      '1': '0.25rem',
      '1.5': '0.375rem',
      '2': '0.5rem',
      '2.5': '0.625rem',
      '3': '0.75rem',
      '3.5': '0.875rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      '112': '32rem',
      '128': '48rem',
      '144': '64rem',

      auto: 'auto',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      full: '100%',
      min: 'min-content',
      max: 'max-content'
    },
    palette: {
      status: statusColors,
      ...severityColors,
      activityType: {
        ...statusColors,
        edited: statusColors.draft,
        'conflict-resolution': statusColors.draft,
        'status-changed': statusColors.draft,
        'status-updated': statusColors.draft,
        archived: statusColors.draft,
        unarchived: statusColors.draft,
        copied: statusColors.created,
        deleted: statusColors.declined,
        payment: '#AFD91A',
        assignment: statusColors.sent,
        error: statusColors.declined,
        warning: statusColors.sent,
        downloaded: statusColors.draft,
        merged: statusColors.draft,
        'owner-changed': statusColors.sent,
        'type-changed': statusColors.draft,
        message: '#50A0CF',
        restored: statusColors.partial,
        'thank-you-sent': statusColors.draft,
        'follow-up-sent': statusColors.sent
      },
      text: {
        primary: '#3E4449'
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#ededed',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#3E4449', // custom
        A700: '#616161'
      }
    }
  }

  const mergedOptions = merge(baseThemeOptions, options)
  const theme = createMuiTheme(mergedOptions)

  return theme
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    size: {
      '0': '0px'
      px: '1px'
      '0.5': '0.125rem'
      '1': '0.25rem'
      '1.5': '0.375rem'
      '2': '0.5rem'
      '2.5': '0.625rem'
      '3': '0.75rem'
      '3.5': '0.875rem'
      '4': '1rem'
      '5': '1.25rem'
      '6': '1.5rem'
      '7': '1.75rem'
      '8': '2rem'
      '9': '2.25rem'
      '10': '2.5rem'
      '11': '2.75rem'
      '12': '3rem'
      '14': '3.5rem'
      '16': '4rem'
      '20': '5rem'
      '24': '6rem'
      '28': '7rem'
      '32': '8rem'
      '36': '9rem'
      '40': '10rem'
      '44': '11rem'
      '48': '12rem'
      '52': '13rem'
      '56': '14rem'
      '60': '15rem'
      '64': '16rem'
      '72': '18rem'
      '80': '20rem'
      '96': '24rem'
      '112': '32rem'
      '128': '48rem'
      '144': '64rem'
      auto: 'auto'
      '1/2': '50%'
      '1/3': '33.333333%'
      '2/3': '66.666667%'
      '1/4': '25%'
      '2/4': '50%'
      '3/4': '75%'
      '1/5': '20%'
      '2/5': '40%'
      '3/5': '60%'
      '4/5': '80%'
      '1/6': '16.666667%'
      '2/6': '33.333333%'
      '3/6': '50%'
      '4/6': '66.666667%'
      '5/6': '83.333333%'
      '1/12': '8.333333%'
      '2/12': '16.666667%'
      '3/12': '25%'
      '4/12': '33.333333%'
      '5/12': '41.666667%'
      '6/12': '50%'
      '7/12': '58.333333%'
      '8/12': '66.666667%'
      '9/12': '75%'
      '10/12': '83.333333%'
      '11/12': '91.666667%'
      full: '100%'
      min: 'min-content'
      max: 'max-content'
    }
    borderRadius: {
      none: '0'
      sm: '0.125rem'
      md: '0.375rem'
      lg: '0.5rem'
      xl: '0.75rem'
      '2xl': '1rem'
      '3xl': '1.5rem'
      full: '9999px'
    }
  }

  interface ThemeOptions {
    size?: Theme['size']
    borderRadius?: Theme['borderRadius']
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    quote: {
      status: { [key in QuoteStatus]: string }
    }
    activityType: Record<ActivityType, string>
  }

  interface PaletteOptions {
    status?: { [key in QuoteStatus]: string }
    activityType?: Record<ActivityType, string>
  }
}

declare module '@material-ui/styles/defaultTheme' {
  // makeStyles refers to DefaultTheme, so make sure we update that as well
  interface DefaultTheme extends Theme {}
}

/**
 * Adds a 'classes' prop to allow override of useStyles classes
 *
 * (note: this may introduce a recursive type if MyProps are references in useStyles - in which case, use the `StyleClasses` type)
 * example:
 * ```typescript
 * interface MyProps extends WithUseStyles<typeof useStyles> {}
 * ```
 */
export interface WithUseStyles<T extends (...args: any) => any> {
  classes?: Partial<Record<keyof ReturnType<T>, string>>
}

/**
 * Types a `classes` prop to match useStyles
 *
 * ```typescript
 * interface MyProps {
 *   classes?: StyleClasses<typeof useStyles>
 * }
 * ```
 */
export type StyleClasses<T extends (...args: any) => any> = Partial<Record<keyof ReturnType<T>, string>>
