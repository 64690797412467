import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Typography, Slider } from '../'
import type { SliderProps } from '../'

const useStyles = makeStyles<Theme, SliderOptionProps>(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    sliderRoot: {
      marginLeft: theme.spacing(3)
    },
    label: {
      flexShrink: 0
    }
  }),
  { name: 'SliderOption' }
)

export interface SliderOptionProps {
  classes?: StyleClasses<typeof useStyles>
  marks: SliderProps['marks']
  label: string
  value: number
  min: number
  max: number
  onChange: (value: number) => void
}

function SliderOption(props: SliderOptionProps) {
  const classes = useStyles(props)
  const { marks, label, value, min, max, onChange } = props

  const handleChange: any = (event: React.ChangeEvent<HTMLSpanElement>, value: number) => {
    onChange(value)
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant={'h5'}>
        {label}
      </Typography>
      <Slider
        onMouseDown={(event) => event.stopPropagation()}
        classes={{ root: classes.sliderRoot }}
        defaultValue={value}
        marks={marks}
        max={max}
        min={min}
        onChange={handleChange}
        step={null}
        track={false}
      />
    </div>
  )
}

export default SliderOption
