import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const Page = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 16 19'} {...props}>
    <svg>
      <path d="M9.33002 6.44271V0.699493H8.16802V7.60471H15.0732V6.44271H9.33002Z" />
      <path d="M2.37695 1.21018C2.05388 1.21018 1.74409 1.33859 1.51532 1.56735C1.28656 1.79612 1.15815 2.10591 1.15815 2.42898V16.303C1.15815 16.6264 1.28679 16.9371 1.51532 17.1656C1.74409 17.3944 2.05388 17.5228 2.37695 17.5228H13.3369C13.6603 17.5228 13.971 17.3941 14.1995 17.1656C14.4275 16.9377 14.5557 16.6272 14.5557 16.303V7.32389L8.44386 1.21018H2.37695ZM0.693664 0.745694C1.14003 0.29933 1.74525 0.0481796 2.37695 0.0481796H8.92528L15.7177 6.84267V16.303C15.7177 16.9336 15.4684 17.5401 15.0212 17.9873C14.5746 18.4339 13.9683 18.6848 13.3369 18.6848H2.37695C1.74525 18.6848 1.14003 18.4336 0.693664 17.9873C0.247063 17.5407 -0.00385094 16.9344 -0.00385094 16.303V2.42898C-0.00385094 1.79728 0.2473 1.19206 0.693664 0.745694Z" />
    </svg>
  </SvgIcon>
)
export default Page
