import React, { useEffect, useState } from 'react'
import { Hidden } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles, Fade } from '@material-ui/core'
import type { DialogProps } from '../../'
import {
  Carousel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilePreview,
  IconButton,
  Typography
} from '../../'
import type { QuoteFile } from 'paintscout'
import { CloseButton } from '../'
import classnames from 'classnames'

import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import GetAppIcon from '@material-ui/icons/GetApp'

import FileSaver from 'file-saver'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles<Theme>(
  (theme) => ({
    paper: {
      margin: 'auto'
    },
    scrollPaper: {
      height: '95vh',
      '&$paperFullScreen': {
        height: '100vh'
      }
    },
    paperFullScreen: {},
    content: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      transition: 'background .6s ease'
    },
    pdfContent: {
      background: theme.palette.grey[200]
    },
    swipeContainer: {
      flexGrow: 1,
      display: 'flex',
      '& .react-swipeable-view-container': {
        flexGrow: 1
      }
    },
    filePreview: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    filePreviewSize: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    pdfFilePreviewSize: {
      height: 'auto',
      display: 'flex',
      justifyContent: 'center'
    },
    image: {
      objectFit: 'contain',
      userDrag: 'none',
      userSelect: 'none',
      margin: 'auto'
    },
    imageGrab: {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing'
      }
    },
    pageMargin: {
      margin: theme.spacing(2, 4),
      '&:first-child': {
        '&$pageMargin': {
          marginTop: theme.spacing(4),
          [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1.5)
          }
        }
      },
      '&:last-child': {
        '&$pageMargin': {
          marginBottom: theme.spacing(4),
          [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1.5)
          }
        }
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(1.5)
      }
    },
    mobileStepper: {
      padding: theme.spacing(1, 2.5),
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 0)
      }
    },
    hideStepper: {
      display: 'none'
    },
    caption: {
      background: 'white',
      padding: theme.spacing(3, 3, 1),
      textAlign: 'center',
      width: '100%',
      lineHeight: 1
    },
    captionNoStepper: {
      padding: theme.spacing(3)
    },
    downloadButton: {
      position: 'absolute',
      left: theme.spacing(4),
      bottom: 8,
      [theme.breakpoints.down('sm')]: {
        left: theme.spacing(3)
      }
    }
  }),
  { name: 'ViewMediaDialog' }
)

export interface ViewMediaDialogProps extends DialogProps {
  files: QuoteFile[]
  index?: number
  title?: string
  startFullscreen?: boolean
  enableDownload?: boolean
  onIndexChange?: (index: number) => void
}

function ViewMediaDialog(props: ViewMediaDialogProps) {
  const classes = useStyles(props)
  const { files, title, onClose, startFullscreen, enableDownload, onIndexChange, ...dialogProps } = props
  const [activeIndex, setActiveIndex] = useState(props.index || 0)
  const [fullscreen, setFullscreen] = useState(startFullscreen)
  const { enqueueSnackbar } = useSnackbar()
  const currentFile = files[activeIndex]
  const { caption } = currentFile
  useEffect(() => {
    if (onIndexChange) onIndexChange(activeIndex)
  }, [activeIndex])

  const multipage = files.length > 1
  const isPdf = currentFile.format === 'pdf'

  async function downloadPdf() {
    const url = currentFile.src
    const regex = /([^\/]+)\.pdf$/
    const match = url.match(regex)
    const fileName = match[1] || `pdf-${Date.now().toString()}`

    try {
      const { data: fileData } = await axios({
        url,
        method: 'get',
        headers: {
          Accept: 'application/pdf'
        },
        responseType: 'arraybuffer'
      })

      FileSaver.saveAs(new Blob([fileData], { type: 'application/pdf' }), fileName)
    } catch (err) {
      enqueueSnackbar('Unable to download PDF', { variant: 'error' })
      console.log('err', err)
    }
  }

  return (
    <Dialog
      classes={{
        paper: classes.paper,
        paperScrollPaper: classes.scrollPaper,
        paperFullScreen: classes.paperFullScreen
      }}
      maxWidth="lg"
      fullWidth={true}
      forceFullScreen={fullscreen}
      disablePortal
      {...dialogProps}
    >
      <DialogTitle
        classes={{ root: classes.titleRoot }}
        rightContent={<CloseButton onCancel={() => onClose({}, null)} />}
        rightContentSecondary={
          <Hidden xsDown implementation="css">
            <IconButton onClick={() => setFullscreen(!fullscreen)} edge={'end'}>
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Hidden>
        }
      >
        {title || 'View Media'}
      </DialogTitle>
      <DialogContent className={classnames(classes.content, { [classes.pdfContent]: isPdf })}>
        <Carousel
          index={props.index}
          getActiveIndex={setActiveIndex}
          classes={{
            swipeContainer: classnames(classes.swipeContainer, { [classes.imageGrab]: multipage }),
            mobileStepper: classnames(classes.mobileStepper, { [classes.hideStepper]: !multipage })
          }}
          beforeStepper={
            caption ? (
              <Typography
                variant={'body1'}
                className={classnames(classes.caption, { [classes.captionNoStepper]: !multipage })}
              >
                {caption}
              </Typography>
            ) : null
          }
        >
          {files.map((file) => (
            <FilePreview
              key={file.key}
              file={file}
              showAllPages
              classes={{
                root: classes.filePreview,
                size: file.format === 'pdf' ? classes.pdfFilePreviewSize : classes.filePreviewSize,
                image: classes.image,
                pageMargin: classes.pageMargin
              }}
            />
          ))}
        </Carousel>
        <Fade in={multipage && isPdf && enableDownload}>
          <IconButton edge={'start'} className={classes.downloadButton} onClick={downloadPdf}>
            <GetAppIcon />
          </IconButton>
        </Fade>
      </DialogContent>
      {!multipage && isPdf && enableDownload && (
        <DialogActions
          leftButton={
            <IconButton edge={'start'} onClick={downloadPdf}>
              <GetAppIcon />
            </IconButton>
          }
        ></DialogActions>
      )}
    </Dialog>
  )
}

export default ViewMediaDialog
