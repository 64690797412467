import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { QuoteItemSection, UpdateableItem } from '../../../index'
import { getArea } from '../../../areas'
import { getLineItem } from '../../../line-items'
import { getSubstrate } from '../../../substrates'
import { getGroup } from '../../../groups'

export function getItem(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  sectionOverride?: QuoteItemSection
}): UpdateableItem {
  const { quote, key } = args

  if (!key) {
    return null
  }

  const sanitizedKey = key.trim().toLowerCase()

  if (quote.areas[key]) {
    return getArea(args)
  } else if (quote.lineItems?.[key]) {
    return getLineItem(args)
  } else if (quote.substrates?.[sanitizedKey]) {
    return getSubstrate(args)
  } else if (quote.groups?.[key]) {
    return getGroup(args)
  }
  return null
}
