import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { DeleteIcon } from '@ui/core/icons'
import EditIcon from '@material-ui/icons/EditOutlined'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import get from 'lodash/get'
import React, { useContext } from 'react'
import { ActionButton, Collapse, useClientOptions, useUser } from '@ui/stickybid'
import type { RenderableItem, QuoteItemSection } from '@paintscout/util/builder'
import { CLOSED_STATUSES, getFeatures, getItems, getObjectLabels, getQuoteOptions } from '@paintscout/util/builder'
import classnames from 'classnames'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemColumn, ItemTableCell } from '../../../../ItemTable'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    itemTableRoot: {},
    tableHead: {},
    headerCell: {},
    optionsTitle: {
      width: '90%'
    },
    actionButton: {
      color: theme.palette.secondary.main,
      paddingTop: theme.spacing(0.5),
      marginTop: 'auto',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 0,
        right: -3
      },
      '&:hover': {
        color: theme.palette.primary.main
      },
      '@media print': {
        display: 'none'
      }
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      borderBottom: '1px solid #888',
      '& h2': {
        // 12px matches with payment and gallery titles
        marginBottom: `12px !important`
      },
      '& h4': {
        marginBottom: `${theme.spacing(1)}px !important`
      }
    },
    itemCell: {
      width: '100%',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      }
    },
    totalSelected: {
      // color: theme.palette.success.main,
      marginBottom: -19.7

      // textAlign: 'right',
      // marginBottom: theme.spacing(1)
    },
    dragHandle: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      }
    },
    tableRow: {
      // minHeight: '112px',
      '&:hover': {
        '& $dragHandle': {
          display: 'block'
        }
      }
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    }
  })
}

export interface OptionsTableProps extends WithStyles<typeof styles> {
  collapsed?: boolean
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  consumer?: 'crew' | 'customer'
  showValues?: boolean

  onToggle: (ev: React.MouseEvent) => any
  inPresentation?: boolean
  isRevision?: boolean
  rightContent?: React.ReactNode
}

function OptionsTable({
  classes,
  collapsed,
  onItemSelection,
  onToggle,
  consumer: consumerProp,
  showValues: showValuesProp,
  rightContent,
  inPresentation,
  isRevision,
  ...itemTableProps
}: OptionsTableProps) {
  const { isAdmin, isCrew } = useUser()
  const { quote, tableView, onReorder, onEditItem, onItemAction, isEditable, canAddAdditionalWork } =
    useContext(QuoteContext) // isEditable comes from here
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  const features = getFeatures({ options })
  const hasAdditionalWork = get(features, 'quotes.additionalWork')
  const hasOptionalItems = get(features, 'quotes.optionalItems')

  if (!hasOptionalItems) return null

  const status = quote.status.value
  const quoteIsOpen = !CLOSED_STATUSES.includes(status) || ['partial', 'paid'].includes(status)

  const consumer = consumerProp ? consumerProp : 'customer'
  const showValues = typeof showValuesProp !== 'undefined' ? showValuesProp : true

  const { addIndex, showQuoteOptions, showDetailedBreakdown } = getQuoteOptions({
    quote,
    options
  })

  const { allowQuoteAdditionalWork } = getQuoteOptions({ options })

  const items: RenderableItem[] = getItems({
    quote,
    section: 'options',
    view: tableView,
    consumer: 'customer',
    options,
    overrideOptions: { showPrices: true }
  })

  // const items = i.map((item) => {
  // const itemDescription = item.description.useCustom ? item.description.custom : item.description.default
  // if (!itemDescription && !item.value && item.subItems && item.subItems.length === 1 && !showDetailedBreakdown) {
  //   const firstSubItem = item.subItems.shift()
  //   return {
  //     ...item,
  //     description: { useCustom: false, default: firstSubItem.name, custom: '' },
  //     value: firstSubItem.value
  //   }
  // }

  // return item
  // })

  if (items.length === 0 && !canAddAdditionalWork) {
    return null
  }

  if (collapsed && !canAddAdditionalWork) {
    return null
  }

  function handleReorder({ items, addIndex }) {
    onReorder({ items, section: 'options', view: tableView, addIndex })
  }

  function handleItemAction(action: string, section: QuoteItemSection, item: RenderableItem) {
    if (action === 'edit') {
      onEditItem(item.key)
    } else {
      onItemAction(action, section, [item.key])
    }
  }

  const selectedKeys = itemTableProps.selectedItems.map((item) => item.key)

  return (
    <div className={classnames({ [classes.root]: true, [classes.hidePrint]: items.length === 0 || collapsed })}>
      <Collapse show={!collapsed}>
        <ItemTable
          draggable={!!isEditable && !inPresentation}
          classes={{
            root: classes.itemTableRoot,
            tableHead: classes.tableHead
          }}
          items={items}
          checkboxes={inPresentation}
          checkboxesDisabled={!quoteIsOpen || isRevision}
          headerCheckbox={false}
          addIndex={addIndex.options}
          addButtonLabel={'Add New Item'}
          onItemSelection={onItemSelection}
          onItemClick={
            (!quote.is_invoice || isAdmin) && canAddAdditionalWork ? (ev, item) => onEditItem(item.key) : null
          }
          onReorder={handleReorder}
          renderHeadColumns={() => <></>}
          renderRowColumns={(item: RenderableItem, hasCheckbox: boolean) => {
            const actions = []
            actions.push({
              key: 'edit',
              label: 'Edit',
              icon: EditIcon
            })
            actions.push({
              key: 'copy',
              label: 'Copy',
              icon: CopyIcon
            })
            actions.push({
              key: 'delete',
              label: 'Delete',
              icon: DeleteIcon
            })
            return (
              <ItemTableCell className={classes.itemCell} colSpan={2}>
                <ItemColumn
                  item={item}
                  consumer={'customer'}
                  inPresentation={inPresentation || isRevision}
                  isEditable={canAddAdditionalWork}
                  section={'options'}
                  selectedOption={selectedKeys?.includes(item.key)}
                  showAmount={showValues}
                  hasCheckbox={hasCheckbox}
                  ActionButton={
                    canAddAdditionalWork ? (
                      <ActionButton
                        actions={isCrew ? null : actions}
                        className={classes.actionButton}
                        edge={'end'}
                        onActionClick={(ev, action) => handleItemAction(action, 'options', item)}
                        size={'small'}
                      />
                    ) : null
                  }
                />
              </ItemTableCell>
            )
          }}
          {...itemTableProps}
        />
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(OptionsTable)
