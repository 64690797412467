export function getPlanLabel(plan: string): string {
  let planType = ''
  switch (plan) {
    case 'trial':
      planType = 'Trial'
      break
    case 'starter-an':
      planType = 'Starter - Annual'
      break
    case 'starter-mo':
      planType = 'Starter - Monthly'
      break
    case 'professional-an':
      planType = 'Professional - Annual'
      break
    case 'professional-mo':
      planType = 'Professional - Monthly'
      break
    case 'enterprise-an':
      planType = 'Enterprise - Annual'
      break
    case 'enterprise-mo':
      planType = 'Enterprise - Monthly'
      break
    case 'non-billing':
      planType = 'Non-Billing'
      break
    case 'custom':
      planType = 'Custom'
      break
    default:
      planType = 'Custom'
      break
  }
  return planType
}
