import type { QuoteDocument, QuoteFile, OptionsDocument } from 'paintscout'
import { getItem } from '../../items/get-items'
import { getFiles } from '../get-files'

/**
 * Gets the array of image keys from an item.
 */
export function getFilesForItem(args: {
  quote: QuoteDocument
  options: OptionsDocument
  itemKey: string
}): QuoteFile[] {
  try {
    const { quote, options, itemKey } = args
    const item = getItem({ quote, options, key: itemKey })

    if (item?.files) {
      const files = getFiles({ quote, keys: item.files })
      return files
    }
  } catch (err) {
    console.log(err)
  }

  return []
}
