import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { QuoteFile } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Alert, Button, Dialog, Grid, InputField, Spinner, Tooltip, Typography, useCloudinary, useUser } from '../..'
import { DialogActions, DialogContent, DialogTitle } from '../../Dialog'
import type { DialogProps } from '../../Dialog'
import CloseButton from '../CloseButton'
import { searchPhotos } from '@paintscout/util/pexels'
import { uploadFile } from '@ui/core'
import * as Sentry from '@sentry/core'
import type { ErrorResponse, Photo, PhotosWithTotalResults } from 'pexels'
import PhotoOption from './PhotoOption'
import type { AlbumPhoto } from './PhotoOption'
import PhotoAlbum from 'react-photo-album'
import SearchIcon from '@material-ui/icons/Search'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles<Theme, StockPhotoDialogProps>(
  (theme) => ({
    dialogTitle: {
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    tooltipRoot: {
      display: 'inline-block',
      marginLeft: theme.spacing(2)
    },
    tooltipBadge: {
      cursor: 'pointer',
      top: 0,
      right: 0,
      '& svg': {
        fontSize: '1rem',
        color: theme.palette.grey['700']
      }
    },
    paper: {
      height: '100vh'
    },
    noResultsBlock: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      height: '100%',
      justifyContent: 'center'
    },
    actionsRoot: {
      height: theme.spacing(3)
    },
    loadingSpinner: {
      padding: theme.spacing(2, 0)
    },
    preContent: {
      padding: theme.spacing(0, 4, 1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3, 1)
      }
    },
    loadMoreButton: {
      display: 'block',
      margin: '0 auto'
    }
  }),
  { name: 'StockPhotoDialog' }
)

export interface StockPhotoDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  defaultQuery: string
  specificQuery?: string
  onUpload: (newFile: QuoteFile) => void
  onCancel: (event?: React.MouseEvent<HTMLElement>) => void
}

function StockPhotoDialog(props: StockPhotoDialogProps) {
  const classes = useStyles(props)
  const { defaultQuery: _defaultQuery, onCancel, onUpload, specificQuery, ...baseDialogProps } = props
  const { cloudName, uploadPreset } = useCloudinary()
  const { user } = useUser()
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState<PhotosWithTotalResults>()
  const [error, setError] = useState(false)
  const perPage = 30

  const hasMore = results?.total_results > results?.photos.length && !!results?.photos.length

  const previousQuery = window.localStorage.getItem('pexelsQuery')
  const defaultQuery = specificQuery || previousQuery || (_defaultQuery === 'other' ? 'Dinosaurs' : _defaultQuery)

  // the ref malarkey is so results below wait can get the latest value of query after the input is debounced
  // extract this into a submitDebounce function if needed elsewhere
  const [query, setQuery] = useState(defaultQuery)
  const queryRef = useRef<string>()
  const scrollRef = useRef<HTMLDivElement>()

  useEffect(() => {
    queryRef.current = query
  }, [query])

  const handleError = () => {
    Sentry.captureException(error, { tags: { pexels: 'true' } })
    setError(true)
  }

  const asyncWait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const handleSearch = async (ev?: React.SyntheticEvent) => {
    ev?.preventDefault() // don't close the dialog on form submit
    setIsLoading(true)
    await asyncWait(500) // wait for Input debounce onChange to fire
    if (!queryRef.current) return setIsLoading(false) // incase someone sneaks past the debounce with a quick enter on an empty string
    try {
      const results = await searchPhotos({ query: queryRef.current, per_page: perPage })
      if (searchWasSuccessful(results)) {
        // don't overwrite previous query with a specific query
        if (queryRef.current && queryRef.current !== specificQuery) {
          window.localStorage.setItem('pexelsQuery', queryRef.current)
        }
        setResults(results as PhotosWithTotalResults)
        setError(false)
      } else {
        handleError()
      }
    } catch {
      handleError()
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  const handleLoadMore = useCallback(async () => {
    try {
      const currentPage = results.photos.length / perPage
      const newResults = await searchPhotos({ query, page: currentPage + 1, per_page: perPage })
      if (searchWasSuccessful(newResults)) {
        setResults({
          ...(newResults as PhotosWithTotalResults),
          photos: [...results.photos, ...(newResults as PhotosWithTotalResults).photos]
        })
        setError(false)
      } else {
        handleError()
      }
    } catch {
      handleError()
    }
  }, [results, query, perPage])

  // useInfiniteScroll wasn't working so the below is a minimal recreation
  const scroll = (el: HTMLElement) => {
    if (el.scrollHeight - el.scrollTop - 5 <= el.clientHeight) {
      handleLoadMore()
    }
  }

  useEffect(() => {
    const el = scrollRef.current
    const cb = () => scroll(el)
    if (hasMore) el?.addEventListener('scroll', cb)

    return () => {
      el?.removeEventListener('scroll', cb)
    }
  }, [scrollRef, isLoading, handleLoadMore, hasMore])

  const handleSelect = async (photo: Photo) => {
    setIsLoading(true)
    const { src, id } = photo
    const quoteFile = await uploadFile({
      file: `${src.original}?auto=compress&cs=tinysrgb&h=1600&w=1280`,
      cloudName,
      uploadPreset,
      companyId: user.app_metadata?.companyId,
      docId: `${id}`
    })

    onUpload({ ...quoteFile, pexelsQuery: queryRef.current })
    setIsLoading(false)
    onCancel()
  }

  const albumPhotos: AlbumPhoto[] = results?.photos.map((photo) => {
    return {
      ...photo,
      src: photo.src.large,
      altSrc: photo.src,
      onClick: () => handleSelect(photo)
    }
  })

  return (
    <Dialog {...baseDialogProps} classes={{ paper: classes.paper }} fullWidth maxWidth={'xl'}>
      <DialogTitle classes={{ root: classes.dialogTitle }} rightContent={<CloseButton onCancel={onCancel} />}>
        Photos by{' '}
        <a href={'https://www.pexels.com/'} target="_blank" rel="noopener noreferrer">
          Pexels
        </a>
        <Tooltip
          placement={'right'}
          content={
            'We are pleased to partner with Pexels to bring you beautiful, royalty-free, stock images. No licensing or attribution necessary.'
          }
          icon={<InfoOutlinedIcon />}
          classes={{ divRoot: classes.tooltipRoot, badge: classes.tooltipBadge }}
        />
      </DialogTitle>
      <div className={classes.preContent}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2}>
            <Grid item xs>
              <InputField
                autoFocus
                fullWidth
                label={'Search Images'}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setQuery(ev.target.value)}
                value={query}
              />
            </Grid>
            <Grid item xs style={{ alignItems: 'flex-end', display: 'flex', flexGrow: 0 }}>
              <Button
                disabled={isLoading || !query}
                prominence={1}
                style={{ height: 45, minWidth: 45, width: 45 }}
                type={'submit'}
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <DialogContent ref={scrollRef}>
        {isLoading ? (
          <Spinner className={classes.loadingSpinner} fullWidth fullHeight />
        ) : (
          <>
            {results?.total_results === 0 || error ? (
              <>
                {error ? (
                  <div className={classes.noResultsBlock}>
                    <Alert variant={'text'} severity={'error'}>
                      There was an error searching photos. Please try again.
                    </Alert>
                  </div>
                ) : (
                  <div className={classes.noResultsBlock}>
                    <Typography variant={'overline'}>{'No results :('}</Typography>
                    <Typography variant={'overline'}>Try another search term</Typography>
                  </div>
                )}
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PhotoAlbum
                    columns={(containerWidth) => {
                      if (containerWidth < 350) return 1
                      if (containerWidth < 750) return 2
                      if (containerWidth < 1000) return 3
                      return 4
                    }}
                    componentsProps={{
                      imageProps: { loading: 'eager' }
                    }}
                    layout={'masonry'}
                    photos={albumPhotos}
                    renderPhoto={PhotoOption}
                  />
                </Grid>
                {!hasMore && (
                  <Grid item xs={12}>
                    <Typography
                      style={{ display: 'block', textAlign: 'center', marginTop: 16, marginBottom: 8 }}
                      variant={'overline'}
                    >
                      {'End of results'}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot }}></DialogActions>
    </Dialog>
  )
}

export default StockPhotoDialog

function searchWasSuccessful(results: PhotosWithTotalResults | ErrorResponse) {
  if ((results as ErrorResponse).error) {
    return false
  } else {
    return true
  }
}
