/**
 * @module builder
 */
import round from 'lodash/round'

/**
 * rounds a number to exactly two decimals and returns a string
 */
export function roundPrice(n: number): string {
  let sanitized

  if (typeof n === 'string') {
    sanitized = parseFloat(n)
  } else if (typeof n !== 'number') {
    sanitized = 0
  } else {
    sanitized = n
  }

  return round(sanitized, 2).toFixed(2)
}
