import React from 'react'
import type { PresentationSection } from 'paintscout'
import { usePresentation } from '../../../context'

import { ButtonSelectOption, StringOption, ColorOption } from '@ui/stickybid'

// import { QuoteIcon } from '@ui/core/icons'
// import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined'

import { UTurnRightIcon } from '@ui/core/icons'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'
interface QuoteOptionsProps {
  section: PresentationSection
  presetColors: string[]
  handleOptionFieldChange: (field: string, value: any) => void
}

function QuoteOptions(props: QuoteOptionsProps) {
  const { section, presetColors, handleOptionFieldChange } = props
  const { fields = {} } = section
  const {
    animation,
    asCartLink = false,
    label,
    color,
    horizontalAlignInContainer = 'center',
    buttonSize = 'sm'
  } = fields
  const { useCart } = usePresentation()

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon style={{ transform: 'rotate(180deg)' }} />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    // ...(useCart
    //   ? [
    //       {
    //         Component: (
    //           <ButtonSelectOption
    //             label={'Embedded in the Presentation'}
    //             value={asCartLink}
    //             buttons={[{ icon: <QuoteIcon />, value: false }]}
    //             onChange={(value) => handleOptionFieldChange('fields.asCartLink', value)}
    //           />
    //         ),
    //         tab: 'content'
    //       },
    //       {
    //         Component: (
    //           <ButtonSelectOption
    //             label={'As Link to Cart'}
    //             value={asCartLink}
    //             buttons={[{ icon: <ShoppingBasketOutlinedIcon />, value: true }]}
    //             onChange={(value) => handleOptionFieldChange('fields.asCartLink', value)}
    //           />
    //         ),
    //         tab: 'content'
    //       }
    //     ]
    //   : []),
    ...(useCart
      ? [
          {
            Component: (
              <StringOption
                label={'Button Label'}
                value={label}
                onChange={(ev) => handleOptionFieldChange('fields.label', ev.target.value)}
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ButtonSelectOption
                label={'Size'}
                value={buttonSize}
                buttons={[
                  { icon: 'S', value: 'sm' },
                  { icon: 'M', value: 'md' },
                  { icon: 'L', value: 'lg' },
                  { icon: 'XL', value: 'xl' }
                ]}
                onChange={(value) => {
                  handleOptionFieldChange('fields.buttonSize', value)
                }}
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ColorOption
                label={'Custom Button Color'}
                value={color}
                useClearOption={true}
                helpTextForClear={'Remove Color Override'}
                defaultColor={''}
                clearColor={''}
                presetColors={presetColors}
                onChange={(e) => {
                  handleOptionFieldChange(`fields.color`, e)
                }}
              />
            ),
            tab: 'content'
          },
          {
            Component: (
              <ButtonSelectOption
                label={'Content Alignment (H)'}
                value={horizontalAlignInContainer}
                buttons={[
                  { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'left' },
                  { icon: <VerticalAlignCenterIcon style={{ transform: 'rotate(-90deg)' }} />, value: 'center' },
                  { icon: <VerticalAlignTopIcon style={{ transform: 'rotate(90deg)' }} />, value: 'right' }
                ]}
                onChange={(value) => {
                  handleOptionFieldChange('fields.horizontalAlignInContainer', value)
                }}
              />
            ),
            tab: 'content'
          }
        ]
      : [])
  ]

  return options
}

export default QuoteOptions
