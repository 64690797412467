import React from 'react'
import { makeStyles } from '@material-ui/core'

import StickyBidIcon from '../../../core/src/icons/StickyBidIcon'

export type SpinningLogoProps = {
  scale?: number
}

const useStyles = makeStyles({
  root: {
    opacity: 0,
    animation: '$fadeIn 1s linear 0.2s 1 forwards'
  },
  icon: {
    fill: '#000',
    width: '100%',
    height: '100%',
    animation: '$sb-logo 3600ms cubic-bezier(0.33, 1, 0.45, 1) 400ms infinite'
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },

  '@keyframes sb-logo': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '20%': {
      transform: 'rotate(-45deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
})

/** Renders a spinning StickyBid logo for loading screens, etc. */
function SpinningLogo({ scale = 1, ...props }: SpinningLogoProps) {
  const classes = useStyles(props)
  const baseWidth = 252 * scale

  return (
    <div
      className={classes.root}
      style={{
        position: 'relative',
        width: baseWidth * 1.5
      }}
      {...props}
    >
      <StickyBidIcon className={classes.icon} />
    </div>
  )
}
export default SpinningLogo
