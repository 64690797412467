import React, { useEffect, useState } from 'react'
import type { QuoteDocument } from 'paintscout'
import { Fab, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import CheckIcon from '@material-ui/icons/Check'
import { useFrame } from 'react-frame-component'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, AcceptedBadgeProps>(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.status.accepted,
      bottom: theme.spacing(2),
      boxShadow: 'none',
      left: theme.spacing(2),
      opacity: 0.9,
      pointerEvents: 'none',
      position: 'fixed',
      transition: 'opacity 0.5s ease-out',
      zIndex: 3,
      ['@media print']: {
        display: 'none'
      }
    },
    hidden: {
      opacity: 0
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        fontSize: '1.2rem',
        marginRight: theme.spacing(1)
      }
    }
  }),
  { name: 'AcceptedBadge' }
)

export interface AcceptedBadgeProps {
  classes?: StyleClasses<typeof useStyles>
  quote: QuoteDocument
}

function AcceptedBadge(props: AcceptedBadgeProps) {
  const classes = useStyles(props)
  const { quote } = props
  const [isHidden, setIsHidden] = useState(false)
  const { window: win, document: doc } = useFrame()

  const status = quote.status.value
  const displayStatuses = ['accepted', 'paid', 'invoiced']
  const display = displayStatuses.includes(status)

  useEffect(() => {
    if (display) {
      const onScroll = () => {
        setIsHidden((isHidden) => {
          if (
            !isHidden &&
            win.innerHeight + win.scrollY >= doc.body.offsetHeight &&
            doc.body.offsetHeight > win.innerHeight
          ) {
            return true
          }

          if (isHidden && win.innerHeight + win.scrollY < doc.body.offsetHeight) {
            return false
          }

          return isHidden
        })
      }

      win.addEventListener('scroll', onScroll)
      return () => win.removeEventListener('scroll', onScroll)
    }
  }, [display])

  if (!display) return null

  const isDepositPaid = !quote.is_invoice && quote.meta?.isDepositPaid
  const isDepositFullAmount = !quote.is_invoice && quote.meta?.isDepositFullAmount

  const label = isDepositPaid ? (isDepositFullAmount ? 'Paid' : 'Deposit Paid') : status

  return (
    <Fab className={classnames(classes.root, { [classes.hidden]: isHidden })} color={'primary'} variant={'extended'}>
      <span className={classes.wrapper}>
        <CheckIcon />
        {label}
      </span>
    </Fab>
  )
}

export default AcceptedBadge
