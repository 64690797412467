import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

import { useClientOptions, Grid, getPresentationContentStyles } from '@ui/stickybid'
import { getTerms } from '@paintscout/util/builder'

import QuoteSignCard from '../QuoteHeader/QuoteSignCard'

import { useQuote } from '../../context'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      marginTop: theme.spacing(2),
      '& div': {
        '&$termsContent': {
          // nested in here so it has more specificity and beats the same from Presentation
          ...getPresentationContentStyles(theme, { noMargins: ['li'] })
        }
      }
    },
    termsContent: {}
  }),
  { name: 'QuoteTerms' }
)

export interface QuoteTermsProps {
  classes?: StyleClasses<typeof useStyles>
}

export default function QuoteTerms(props: QuoteTermsProps) {
  const classes = useStyles(props)
  const { options } = useClientOptions()

  const { quote, isEditable } = useQuote()

  const status = quote?.status?.value

  const { content } = getTerms({ quote, options })

  const quoteTypeOption = options.options.intExtOptions?.values?.[quote?.int_ext?.value] ?? ({} as any)

  // We, unfortunately, need to make sure to also check options.intExtOptions.values.
  // quoteOptions in case this client has a quoteType override for signatures.
  // It's not something we really advertise, but we have a client with a weird workflow
  // where they require a signature only on certain quote types.

  return (
    <div className={classes.root} id={'terms'}>
      <div dangerouslySetInnerHTML={{ __html: content }} className={classes.termsContent} />
      {(status === 'accepted' || !isEditable) &&
        (options.options.quotes.requireSignatureOnSpot ||
          options.options.quotes.requireSignatureSent ||
          quoteTypeOption?.quoteOptions?.requireSignatureSent ||
          quoteTypeOption?.quoteOptions?.quoteOptions?.requireSignatureOnSpot) && (
          <Grid container>
            <Grid item sm={12} md={6}>
              <QuoteSignCard />
            </Grid>
          </Grid>
        )}
    </div>
  )
}
