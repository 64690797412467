import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import type { StyleClasses } from '../theme'

type DivProps = Omit<JSX.IntrinsicElements['div'], 'color'>

export interface SpinnerProps extends DivProps {
  classes?: StyleClasses<typeof useStyles>
  color?: 'primary' | 'secondary' | React.CSSProperties['color']
  size?: number
  fullWidth?: boolean
  fullHeight?: boolean
}

const useStyles = makeStyles<Theme, SpinnerProps>(
  (theme: Theme) => {
    const color = (props: SpinnerProps) => {
      switch (props.color) {
        case 'primary':
          return theme.palette.primary.main
        case 'secondary':
          return theme.palette.secondary.main
      }
      return props.color
    }

    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: (props) => (props.fullWidth ? '100%' : null),
        height: (props) => (props.fullHeight ? '100%' : null)
      },
      spinner: {
        width: (props) => props.size,
        height: (props) => props.size,
        borderRadius: '50%',
        border: (props) => `${Math.ceil(props.size / 10)}px solid ${color(props)}`,
        borderColor: (props) => `${color(props)} transparent ${color(props)} transparent`,
        animationName: '$dual-ring-animation',
        animation: 'dual-ring-animation 4s linear infinite'
      },
      '@keyframes dual-ring-animation': {
        '0%': {
          transform: 'rotate(0deg)'
        },
        '50%': {
          transform: 'rotate(720deg)'
        },
        '100%': {
          transform: 'rotate(1080deg)'
        }
      }
    }
  },
  {
    name: 'Spinner'
  }
)

function Spinner({ className, fullWidth, fullHeight, ...divProps }: SpinnerProps) {
  const classes = useStyles({ fullWidth, fullHeight, ...divProps })

  return (
    <div className={classnames(classes.root, className)} {...divProps}>
      <div className={classes.spinner} />
    </div>
  )
}

Spinner.defaultProps = {
  color: 'primary',
  size: 30
}

export default Spinner
