/**
 * Get the rainforest injection options from local storage
 * Returns all options in local storage that begin with 'rainforest.'
 */
export default function getRainforestInjection() {
  if (typeof window === 'undefined' || !['dev', 'local'].includes(process.env.DEPLOYMENT_STAGE)) {
    return {}
  }

  // Declining cookies in safari will break local storage
  try {
    return Object.entries({ ...localStorage })
      .filter(([key]) => key.startsWith('rainforest.'))
      .map(([key, value]) => ({
        [key.replace('rainforest.', '')]: value
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {})
  } catch (e) {
    return {}
  }
}
