import type { PresentationPageOption } from 'paintscout'
import { uuid } from '../../../../builder/util'

export function getPrefabPages(): PresentationPageOption[] {
  return [].concat(
    aboutPages,
    testimonialPages,
    featurePages,
    textPages,
    galleryPages,
    bannerPages,
    teamPages,
    // layoutPages,
    mediaPages,
    mediaAndTextPages,
    documentPages,
    footerPages
  )
}

const defaultSrc = 'https://res.cloudinary.com/stickybid/image/upload/v1675117938/_static/placeholders/grey.png'
const defaultPublicId = '_static/placeholders/grey'

const aboutPages: PresentationPageOption[] = [
  {
    title: 'About Us',
    label: 'About Us',
    type: 'empty',
    categories: ['about'],
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h1>INSERT_TITLE</h1><h2>Designers Dedicated to Delivering Dreams</h2>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        key: uuid(),
        stickybidOnly: true,
        type: 'banner',
        label: 'Banner',
        title: 'Banner',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          content: '<h1>About Us</h1><h2>Designers Dedicated to Delivering Dreams</h2>',
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          },
          layout: 'collage',
          layoutOptions: {
            poster: {
              color: '#ffffff'
            },
            collage: {
              color: '',
              textBackgroundColor: '',
              useGlassEffect: false,
              verticalAlign: 'start',
              imagePosition: 'top'
            }
          },
          sectionHeight: 'x-sm'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        }
      },
      {
        key: uuid(),
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'none',
          columnAlign: 'top',
          verticalAlign: 'start',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: null,
          leftWidth: 6,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Duis varius pretium</h3><p></p><p>Odio quis fringilla. Nam luctus vitae velit porttitor auctor. Duis et elit id ex porttitor tincidunt quis dignissim est. Aenean sed accumsan ipsum. Quisque eu elementum nisl. Phasellus ac nisi metus. Nam suscipit nibh nunc, non rutrum odio ultrices et.</p>',
          rightWidth: 6,
          leftText:
            '<h3>Cras est est</h3><p></p><p>Facilisis quis neque a, blandit ultricies nisi. Maecenas tempus leo libero, a consectetur purus facilisis vitae. Phasellus vel efficitur magna. Etiam sit amet leo eget neque molestie tempus in ut risus. Sed interdum sapien a blandit fermentum. Quisque cursus elit eu metus facilisis malesuada.</p>',
          sectionHeight: 'md',
          contentWidth: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        }
      }
    ]
  },
  {
    categories: ['about'],
    label: 'About Us',
    title: 'About Us',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h1>INSERT_TITLE</h1><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
      titleContentField: 'sections[1].fields.content'
    },
    sections: [
      {
        type: 'image',
        label: 'Media',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          },
          placeholder: '',
          sectionHeight: 'x-sm'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content:
            '<h1>About Us</h1><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
          color: ''
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['about'],
    label: 'About Us',
    title: 'About Us',
    type: 'empty',
    active: true,
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h1>INSERT_TITLE</h1>',
      titleContentField: 'sections[1].fields.content'
    },
    sections: [
      {
        type: 'image',
        label: 'Media',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          },
          placeholder: '',
          sectionHeight: 'x-sm'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content: '<h1>About Us</h1>',
          color: '',
          verticalAlign: 'bottom',
          sectionHeight: 'sm'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'top',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: null,
          leftWidth: 6,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
          rightWidth: 6,
          leftText:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
          sectionHeight: 'sm'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['about'],
    label: 'About Us',
    title: 'About Us',
    type: 'empty',
    active: true,
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h1>INSERT_TITLE</h1><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p><p></p><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus.</p>',
      titleContentField: 'sections[0].fields.rightText'
    },
    sections: [
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/2',
              imageOrientation: 'portrait'
            }
          },
          leftWidth: 6,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h1>About Us</h1><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p><p></p><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus.</p>',
          rightWidth: 6,
          sectionHeight: 'x-sm',
          fullWidthImage: true
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const testimonialPages: PresentationPageOption[] = [
  {
    categories: ['testimonial'],
    label: 'Testimonials',
    title: 'Testimonials',
    type: 'empty',
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        key: uuid(),
        type: 'testimonials',
        label: 'Testimonials',
        title: '',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'none',
          columns: 1,
          cards: [
            {
              content:
                '<p>Add testimonials from your clients that underscore the quality your company brings. Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions. The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
              credit: '<h3>Jessie, Company Inc.</h3>',
              key: uuid()
            }
          ],
          sectionHeight: 'md',
          contentWidth: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        }
      }
    ]
  },
  {
    title: 'Testimonials',
    label: 'Testimonials',
    categories: ['testimonial'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        key: uuid(),
        type: 'testimonials',
        label: 'Testimonials',
        title: '',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'none',
          columns: 2,
          cards: [
            {
              content:
                '<p>Add testimonials from your clients that underscore the quality your company brings. Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions. The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
              credit: '<h3>Jessie, Company Inc.</h3>',
              key: uuid()
            },
            {
              content:
                '<p>The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
              credit: '<h3>Carey B.</h3>',
              key: uuid()
            }
          ],
          sectionHeight: 'md',
          contentWidth: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        }
      }
    ]
  },
  {
    categories: ['testimonial'],
    label: 'Testimonials',
    title: 'Testimonials',
    type: 'empty',
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        key: uuid(),
        type: 'testimonials',
        label: 'Testimonials',
        title: '',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'none',
          columns: 3,
          cards: [
            {
              content:
                '<p>Add testimonials from your clients that underscore the quality your company brings. Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions. The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
              credit: '<h3>Jessie, Company Inc.</h3>',
              key: uuid()
            },
            {
              content:
                '<p>The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
              credit: '<h3>Carey B.</h3>',
              key: uuid()
            },
            {
              content:
                '<p>Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions. The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
              credit: '<h3>Taylor C.</h3>',
              key: uuid()
            }
          ],
          sectionHeight: 'md',
          contentWidth: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        }
      }
    ]
  }
]

const featurePages: PresentationPageOption[] = [
  {
    type: 'empty',
    categories: ['feature'],
    title: 'Our Values',
    label: 'Our Values',
    description: '',
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h3>INSERT_TITLE</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.&nbsp;Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
      titleContentField: 'sections[0].fields.rightText'
    },
    sections: [
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'slide',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '1/1',
              clipPath: 'none'
            }
          },
          leftWidth: 5,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Our Values</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.&nbsp;Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightWidth: 7,
          fullWidthImage: true
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    categories: ['feature'],
    title: 'Our Mission',
    label: 'Our Mission',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'spacer',
        label: 'Spacer',
        title: 'Spacer',
        background: { noPadding: true, color: '' },
        fields: { heightResponsive: 'md', sectionHeight: 'x-sm' },
        active: true,
        advanced: { useForNav: false, allowCustom: false },
        key: uuid()
      },
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'text',
          leftOptions: { color: '', backgroundColor: '' },
          leftWidth: 5,
          rightType: 'image',
          rightOptions: { color: '', backgroundColor: '' },
          rightWidth: 7,
          leftText:
            '<h2>"</h2><h2>A profound design process eventually makes the patron, the architect, and every occasional visitor in the building a slightly better human being."</h2><p></p><h3>Juhani Pallasmaa</h3>',
          rightImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/2',
              imageOrientation: 'landscape'
            }
          },
          fullWidthImage: true
        },
        active: true,
        advanced: { useForNav: false, allowCustom: false },
        key: uuid()
      },
      {
        stickybidOnly: true,
        type: 'spacer',
        label: 'Spacer',
        title: 'Spacer',
        background: { noPadding: true, color: '' },
        fields: { heightResponsive: 'md', sectionHeight: 'x-sm' },
        active: true,
        advanced: { useForNav: false, allowCustom: false },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    label: 'Features',
    title: 'Features',
    categories: ['feature'],
    description: '',
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'slide',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '1/1',
              clipPath: 'drop'
            }
          },
          leftWidth: 5,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Lorem ipsum dolor</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.&nbsp;Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightWidth: 7,
          fullWidthImage: false
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftWidth: 7,
          rightType: 'image',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightWidth: 5,
          fullWidthImage: false,
          leftText:
            '<h3>Morbi dignissim</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.&nbsp;Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '1/1',
              clipPath: 'drop',
              clipPathOrientation: 'right'
            }
          }
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    categories: ['feature'],
    label: 'Features',
    title: 'Features',
    description: '',
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'slide',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          },
          leftWidth: 8,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Lorem ipsum dolor</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightWidth: 4,
          fullWidthImage: true
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        stickybidOnly: true,
        type: 'spacer',
        label: 'Spacer',
        title: 'Spacer',
        background: {
          noPadding: true,
          color: ''
        },
        fields: {
          heightResponsive: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftWidth: 4,
          rightType: 'image',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightWidth: 8,
          fullWidthImage: true,
          leftText:
            '<h3>Morbi dignissim</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          }
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        stickybidOnly: true,
        type: 'spacer',
        label: 'Spacer',
        title: 'Spacer',
        background: {
          noPadding: true,
          color: ''
        },
        fields: {
          heightResponsive: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          },
          leftWidth: 8,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Phasellus condimentum</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightWidth: 4,
          fullWidthImage: true
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    title: 'Details',
    label: 'Details',
    type: 'empty',
    categories: ['feature', 'mediaAndText'],
    description: '',
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'cards',
        label: 'Cards',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columns: 3,
          cards: [
            {
              file: {
                src: defaultSrc,
                format: 'png',
                cloudinaryPublicId: defaultPublicId,
                width: 1920,
                height: 1280,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '5/4',
                  imageOrientation: 'landscape'
                }
              },
              content:
                '<h3>Lorem ipsum dolor</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
              key: uuid()
            },
            {
              file: {
                src: defaultSrc,
                format: 'png',
                cloudinaryPublicId: defaultPublicId,
                width: 1920,
                height: 1280,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '5/4',
                  imageOrientation: 'landscape'
                }
              },
              content:
                '<h3>Morbi dignissim</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
              key: uuid()
            },
            {
              file: {
                src: defaultSrc,
                format: 'png',
                cloudinaryPublicId: defaultPublicId,
                width: 1920,
                height: 1280,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '5/4',
                  imageOrientation: 'landscape'
                }
              },
              content:
                '<h3>Phasellus condimentum</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
              key: uuid()
            }
          ],
          useCarousel: false
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    title: 'More Info',
    label: 'More Info',
    type: 'empty',
    categories: ['feature', 'text'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: '3-column',
        label: '3 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: null,
          centerType: 'text',
          centerOptions: {
            color: '',
            backgroundColor: ''
          },
          centerText:
            '<h3>Morbi dignissim</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Phasellus condimentum</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
          leftText:
            '<h3>Lorem ipsum dolor</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const textPages: PresentationPageOption[] = [
  {
    type: 'empty',
    label: 'More Info',
    title: 'More Info',
    categories: ['text'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h2>INSERT_TITLE</h2><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content:
            '<h2>More Info</h2><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
          color: ''
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    label: 'Our Values',
    title: 'Our Values',
    categories: ['text'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h1>INSERT_TITLE</h1><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content:
            '<h1>Our Values</h1><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>',
          color: ''
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    label: 'Our Unfair Advantage',
    title: 'Our Unfair Advantage',
    categories: ['text'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h1>INSERT_TITLE</h1>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content: '<h1>Our Unfair Advantage</h1>',
          color: ''
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    label: 'Tagline',
    title: 'Tagline',
    categories: ['text'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content:
            '<h2>Lorem ipsum dolor sit amet consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate.</h2>',
          color: '',
          sectionHeight: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    type: 'empty',
    title: 'More Info',
    label: 'More Info',
    categories: ['text'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'top',
          verticalAlign: 'center',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: null,
          leftWidth: 7,
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<h3>Ullamcorper odio laoreet</h3><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum.</p>',
          rightWidth: 5,
          leftText:
            '<h3>Lorem ipsum dolor</h3><p></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis accumsan ac nec ante. Praesent ullamcorper volutpat vulputate. Donec aliquet nisl et viverra bibendum. Cras accumsan nunc quis ante convallis, quis sodales nisl hendrerit. Sed tristique eu dui et tincidunt. Ut suscipit metus sed lectus viverra, nec egestas metus luctus.</p>'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const galleryPages: PresentationPageOption[] = [
  {
    type: 'empty',
    label: 'Gallery',
    title: 'Gallery',
    categories: ['gallery'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h2>INSERT_TITLE</h2>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          content: '<h2>Gallery</h2>',
          color: ''
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        key: uuid(),
        type: 'gallery',
        label: 'Gallery',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          columns: 3,
          showBorder: false,
          files: [
            {
              src: defaultSrc,
              format: 'png',
              cloudinaryPublicId: defaultPublicId,
              width: 1920,
              height: 1280,
              type: 'upload',
              visibility: 'visible',
              key: uuid(),
              presentationOptions: {
                aspectRatio: '4/3',
                imageOrientation: 'landscape'
              }
            },
            {
              src: defaultSrc,
              format: 'png',
              cloudinaryPublicId: defaultPublicId,
              width: 1920,
              height: 1280,
              type: 'upload',
              visibility: 'visible',
              key: uuid(),
              presentationOptions: {
                aspectRatio: '4/3',
                imageOrientation: 'landscape'
              }
            },
            {
              src: defaultSrc,
              format: 'png',
              cloudinaryPublicId: defaultPublicId,
              width: 1920,
              height: 1280,
              type: 'upload',
              visibility: 'visible',
              key: uuid(),
              presentationOptions: {
                aspectRatio: '4/3',
                imageOrientation: 'landscape'
              }
            },
            {
              src: defaultSrc,
              format: 'png',
              cloudinaryPublicId: defaultPublicId,
              width: 1920,
              height: 1280,
              type: 'upload',
              visibility: 'visible',
              key: uuid(),
              presentationOptions: {
                aspectRatio: '4/3',
                imageOrientation: 'landscape'
              }
            },
            {
              src: defaultSrc,
              format: 'png',
              cloudinaryPublicId: defaultPublicId,
              width: 1920,
              height: 1280,
              type: 'upload',
              visibility: 'visible',
              key: uuid(),
              presentationOptions: {
                aspectRatio: '4/3',
                imageOrientation: 'landscape'
              }
            },
            {
              src: defaultSrc,
              format: 'png',
              cloudinaryPublicId: defaultPublicId,
              width: 1920,
              height: 1280,
              type: 'upload',
              visibility: 'visible',
              key: uuid(),
              presentationOptions: {
                aspectRatio: '4/3',
                imageOrientation: 'landscape'
              }
            }
          ]
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        }
      }
    ]
  }
]

const bannerPages: PresentationPageOption[] = [
  {
    categories: ['banner'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Welcome',
    label: 'Welcome',
    type: 'empty',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h1 style="text-align: center">INSERT_TITLE</h1><p></p><h2 style="text-align: center">Consectetur adipiscing elit. Donec non felis diam. </h2>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'banner',
        label: 'Banner',
        title: 'Banner',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          content:
            '<h1 style="text-align: center">Welcome</h1><p></p><h2 style="text-align: center">Consectetur adipiscing elit. Donec non felis diam. </h2>',
          fullWidthImage: false,
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/1',
              imageOrientation: 'landscape'
            }
          },
          layout: 'poster',
          layoutOptions: {
            poster: {
              color: '#ffffff'
            },
            collage: {
              color: '',
              textBackgroundColor: '',
              useGlassEffect: false,
              verticalAlign: 'start',
              imagePosition: 'left'
            }
          },
          sectionHeight: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['banner', 'mediaAndText'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Our Promise To You',
    label: 'Our Promise To You',
    description: '',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h1>INSERT_TITLE</h1>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'banner',
        label: 'Banner',
        title: 'Banner',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          content: '<h1>Our Promise To You</h1>',
          fullWidthImage: false,
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/2',
              imageOrientation: 'landscape'
            }
          },
          layout: 'collage',
          layoutOptions: {
            poster: {
              color: '#ffffff'
            },
            collage: {
              color: '',
              textBackgroundColor: '',
              useGlassEffect: false,
              verticalAlign: 'start',
              imagePosition: 'left'
            }
          },
          sectionHeight: 'sm',
          verticalAlign: 'center'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['banner'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Our Mission',
    label: 'Our Mission',
    description: '',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h1 style="text-align: center">INSERT_TITLE</h1>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'banner',
        label: 'Banner',
        title: 'Banner',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          content: '<h1 style="text-align: center">Our Mission</h1>',
          fullWidthImage: false,
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/2',
              clipPath: 'chevron',
              clipPathOrientation: 'top',
              imageOrientation: 'landscape'
            }
          },
          layout: 'collage',
          layoutOptions: {
            poster: {
              color: '#ffffff'
            },
            collage: {
              color: '',
              textBackgroundColor: '',
              useGlassEffect: false,
              verticalAlign: 'end',
              imagePosition: 'top',
              noBackground: false
            }
          },
          sectionHeight: 'sm',
          verticalAlign: 'end',
          contentWidth: 'full'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['banner', 'mediaAndText'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'How We Do',
    label: 'How We Do',
    description: '',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent:
        '<h1>INSERT_TITLE</h1><p></p><p>Consectetur adipiscing elit. Donec non felis diam. Cras nec feugiat sapien, non porta arcu. Aenean interdum diam elit, eu laoreet erat porta id. Vestibulum vel dignissim tortor. Praesent vel pellentesque velit.</p><p></p><p>Nunc sed sem ac ipsum sollicitudin finibus. Etiam eget maximus lectus, ut finibus enim.</p><p></p><p>Nulla vitae leo convallis diam maximus malesuada. Nunc non ligula urna. Donec tristique augue et leo ultricies, id feugiat ligula suscipit. Aenean sit amet aliquam ligula.</p>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'banner',
        label: 'Banner',
        title: 'Banner',
        background: {
          color: '',
          noPadding: false,
          useTheme: true
        },
        fields: {
          animation: 'none',
          content:
            '<h1>How We Do</h1><p></p><p>Consectetur adipiscing elit. Donec non felis diam. Cras nec feugiat sapien, non porta arcu. Aenean interdum diam elit, eu laoreet erat porta id. Vestibulum vel dignissim tortor. Praesent vel pellentesque velit.</p><p></p><p>Nunc sed sem ac ipsum sollicitudin finibus. Etiam eget maximus lectus, ut finibus enim.</p><p></p><p>Nulla vitae leo convallis diam maximus malesuada. Nunc non ligula urna. Donec tristique augue et leo ultricies, id feugiat ligula suscipit. Aenean sit amet aliquam ligula.</p>',
          fullWidthImage: false,
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '4/3',
              imageOrientation: 'landscape'
            }
          },
          layout: 'collage',
          layoutOptions: {
            poster: {
              color: '#ffffff'
            },
            collage: {
              color: '',
              textBackgroundColor: '',
              useGlassEffect: true,
              verticalAlign: 'start',
              imagePosition: 'left',
              noBackground: false
            }
          },
          sectionHeight: 'md',
          verticalAlign: 'center',
          contentWidth: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['banner'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Welcome',
    label: 'Welcome',
    type: 'empty',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'logo',
        label: 'Logo',
        title: 'Logo',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          color: '#ffffff',
          content: '<p></p>',
          contentBackgroundColor: '#f0f0f0',
          contentColor: '#000000',
          contentTop: '',
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '16/9',
              imageOrientation: 'landscape'
            }
          },
          logoAlignment: 'center',
          logoSize: 'md',
          useContentBackground: true,
          sectionHeight: 'x-sm',
          useGlassEffect: true
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const teamPages: PresentationPageOption[] = [
  {
    categories: ['team', 'mediaAndText'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    type: 'empty',
    label: 'Our Team',
    title: 'Our Team',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'cards',
        label: 'Cards',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columns: 3,
          cards: [
            {
              file: {
                src: defaultSrc,
                format: 'png',
                cloudinaryPublicId: defaultPublicId,
                width: 1920,
                height: 1280,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '1/1',
                  clipPath: 'circle'
                }
              },
              content:
                '<h2 style="text-align: center"><strong>Jesse Smith</strong></h2><h3 style="text-align: center">Job Title</h3><p></p><p>Consectetur adipiscing elit. Donec non felis diam. Cras nec feugiat sapien, non porta arcu. Aenean interdum diam elit, eu laoreet erat porta id.</p>',
              key: uuid()
            },
            {
              file: {
                src: defaultSrc,
                format: 'png',
                cloudinaryPublicId: defaultPublicId,
                width: 1920,
                height: 1280,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '1/1',
                  clipPath: 'circle'
                }
              },
              content:
                '<h2 style="text-align: center"><strong>Ira Ahmad</strong></h2><h3 style="text-align: center">Job Title</h3><p></p><p>Consectetur adipiscing elit. Donec non felis diam. Cras nec feugiat sapien, non porta arcu. Aenean interdum diam elit, eu laoreet erat porta id.</p>',
              key: uuid()
            },
            {
              file: {
                src: defaultSrc,
                format: 'png',
                cloudinaryPublicId: defaultPublicId,
                width: 1920,
                height: 1280,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '1/1',
                  clipPath: 'circle'
                }
              },
              content:
                '<h2 style="text-align: center"><strong>Lenny Preece</strong></h2><h3 style="text-align: center">Job Title</h3><p></p><p>Consectetur adipiscing elit. Donec non felis diam. Cras nec feugiat sapien, non porta arcu. Aenean interdum diam elit, eu laoreet erat porta id.</p>',
              key: uuid()
            }
          ],
          useCarousel: false
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const layoutPages: PresentationPageOption[] = [
  {
    categories: ['layout'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    type: 'empty',
    label: 'Spacer',
    title: 'Spacer',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'spacer',
        label: 'Spacer',
        title: 'Spacer',
        background: {
          noPadding: true,
          color: ''
        },
        fields: {
          heightResponsive: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['layout'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Divider',
    label: 'Divider',
    type: 'empty',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: 'divider',
        label: 'Divider',
        title: 'Divider',
        background: {
          noPadding: true,
          color: ''
        },
        fields: {
          marginTop: 40,
          marginBottom: 40,
          marginTopResponsive: 'md',
          marginBottomResponsive: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const mediaPages: PresentationPageOption[] = [
  {
    categories: ['media'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    type: 'empty',
    label: 'Image',
    title: 'Image',
    description: '',
    active: true,
    advanced: {
      allowCustom: true,
      useForNav: true
    },
    sections: [
      {
        type: 'image',
        label: 'Media',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          image: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '3/2',
              imageOrientation: 'landscape'
            }
          },
          placeholder: ''
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['media'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Images',
    label: 'Images',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: '2-column',
        label: '2 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '5/4',
              imageOrientation: 'landscape'
            }
          },
          leftWidth: 6,
          rightType: 'image',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText: '',
          rightWidth: 6,
          rightImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '5/4',
              imageOrientation: 'landscape'
            }
          },
          fullWidthImage: true
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  },
  {
    categories: ['media'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    title: 'Video',
    label: 'Video',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: 'image',
        label: 'Media',
        title: '',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          image: {
            visibility: 'visible',
            format: 'youtube',
            youtubeId: 'oOkGmK3_Hdg'
          },
          placeholder: '',
          contentWidth: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const mediaAndTextPages: PresentationPageOption[] = [
  {
    categories: ['mediaAndText'],
    thumbnailId: 'lpdcvwuvuwdnyjyo/fluid_rp9qhr',
    type: 'empty',
    label: '2 Image + 1 Text',
    title: '2 Image + 1 Text',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: '3-column',
        label: '3 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'image',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '5/4',
              imageOrientation: 'landscape'
            }
          },
          centerType: 'text',
          centerOptions: {
            color: '',
            backgroundColor: ''
          },
          centerText:
            '<h2>Lorem ipsum dolor sit amet consectetur adipiscing elit. Donec non felis diam. Cras nec feugiat sapien, non porta arcu. </h2>',
          rightType: 'image',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText: '',
          rightImage: {
            src: defaultSrc,
            format: 'png',
            cloudinaryPublicId: defaultPublicId,
            width: 1920,
            height: 1280,
            type: 'upload',
            visibility: 'visible',
            presentationOptions: {
              aspectRatio: '5/4',
              imageOrientation: 'landscape'
            }
          }
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]

const documentPages: PresentationPageOption[] = [
  {
    categories: ['mediaAndText', 'documents'],
    type: 'empty',
    label: 'Documents',
    title: 'Documents',
    description: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true,
      titleContent: '<h2>INSERT_TITLE</h2>',
      titleContentField: 'sections[0].fields.content'
    },
    sections: [
      {
        stickybidOnly: true,
        type: 'text',
        label: 'Text',
        title: 'New Text',
        background: { noPadding: false, color: '' },
        fields: {
          content: '<h2>Documents</h2>',
          color: '',
          contentWidth: 'full'
        },
        active: true,
        advanced: { useForNav: false, allowCustom: false },
        key: uuid()
      },
      {
        stickybidOnly: true,
        type: 'cards',
        label: 'Cards',
        title: '',
        background: { noPadding: false, color: '' },
        fields: {
          animation: 'none',
          columns: 3,
          cards: [
            {
              file: {
                src: 'https://res.cloudinary.com/stickybid/image/upload/v1704407995/_static/placeholders/teaching-design.pdf',
                format: 'pdf',
                cloudinaryPublicId: '_static/placeholders/teaching-design',
                width: 358,
                height: 565,
                pages: 1,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '5/4',
                  imageOrientation: 'landscape'
                }
              },
              content:
                '<h3>Lorem ipsum dolor</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
              key: uuid()
            },
            {
              file: {
                src: 'https://res.cloudinary.com/stickybid/image/upload/v1704407996/_static/placeholders/bicycles-and-tricycles.pdf',
                format: 'pdf',
                cloudinaryPublicId: '_static/placeholders/bicycles-and-tricycles',
                width: 344,
                height: 507,
                pages: 3,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '5/4',
                  imageOrientation: 'landscape'
                }
              },
              content:
                '<h3>Phasellus condimentum</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
              key: uuid()
            },
            {
              file: {
                src: 'https://res.cloudinary.com/stickybid/image/upload/v1704407995/_static/placeholders/elements-of-design.pdf',
                format: 'pdf',
                cloudinaryPublicId: '_static/placeholders/elements-of-design',
                width: 403,
                height: 640,
                pages: 2,
                type: 'upload',
                visibility: 'visible',
                presentationOptions: {
                  aspectRatio: '5/4',
                  imageOrientation: 'landscape'
                }
              },
              content:
                '<h3>Morbi dignissim</h3><p></p><p>Consectetur adipiscing elit. In eget est cursus, ullamcorper odio laoreet, pharetra lacus. In nec dui sit amet erat venenatis.</p>',
              key: uuid()
            }
          ],
          useCarousel: false
        },
        active: true,
        advanced: { useForNav: false, allowCustom: false },
        key: uuid()
      }
    ]
  }
]

const footerPages: PresentationPageOption[] = [
  {
    categories: ['mediaAndText', 'footer'],
    title: 'Contact Us',
    label: 'Contact Us',
    type: 'empty',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [
      {
        type: 'divider',
        label: 'Divider',
        title: 'Divider',
        background: {
          noPadding: true,
          color: ''
        },
        fields: {
          marginTop: 40,
          marginBottom: 40,
          marginTopResponsive: 'md',
          marginBottomResponsive: 'md'
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      },
      {
        type: '3-column',
        label: '3 Column',
        title: 'My New Section',
        background: {
          noPadding: false,
          color: ''
        },
        fields: {
          animation: 'none',
          columnAlign: 'center',
          verticalAlign: 'center',
          leftType: 'text',
          leftOptions: {
            color: '',
            backgroundColor: ''
          },
          leftImage: null,
          centerType: 'logo',
          centerOptions: {
            color: '',
            backgroundColor: ''
          },
          centerText: '<p>&nbsp;</p>',
          rightType: 'text',
          rightOptions: {
            color: '',
            backgroundColor: ''
          },
          rightText:
            '<p style="text-align: right"><a target="_blank" rel="noopener noreferrer nofollow" href="http://www.websitehere.com">www.websitehere.com</a></p><p style="text-align: right">123.456.7890</p><p style="text-align: right"><a target="_blank" rel="noopener noreferrer nofollow" href="mailto:hello@yourwebsite.com">hello@yourwebsite.com</a></p>',
          leftText: "<h2>Let's stay connected</h2>",
          centerLogo: {
            size: 'sm',
            horizontalAlign: 'center'
          }
        },
        active: true,
        advanced: {
          useForNav: false,
          allowCustom: false
        },
        key: uuid()
      }
    ]
  }
]
