import type { QuoteDocument, OrderItem, QuoteFile } from 'paintscout'

export interface GetFilesArgs {
  quote: QuoteDocument
  keys?: string[]

  /**
   * only return files that are attached to an area or line item (that are not deleted)
   */
  attached?: boolean
}

export function getFiles(args: GetFilesArgs): QuoteFile[] {
  const { quote, keys, attached } = args

  if (!quote?.files) {
    return []
  }

  // returns all files attached to areas, line items, and groups
  if (attached) {
    return [
      ...quote.order.area.reduce((acc: QuoteFile[], orderItem: OrderItem): QuoteFile[] => {
        let item
        if (orderItem._deleted) {
          return acc
        } else if (orderItem.type === 'line_item') {
          item = quote.lineItems[orderItem.key]
        } else if (orderItem.type === 'area') {
          item = quote.areas[orderItem.key]
        } else if (orderItem.type === 'group') {
          item = quote.groups[orderItem.key]
        } else {
          return acc
        }

        if (!item || !item.files) {
          return acc
        }

        const files = item.files
          .filter((fileKey) => quote.files && quote.files[fileKey])
          .map((fileKey: string) => {
            return quote.files[fileKey]
          })
        return [...acc, ...files]
      }, [])
    ]
  }

  // if not attached and no keys return nothing
  if (!keys) {
    return []
  }

  // if not attached return files for the keys
  return [
    ...keys
      .filter((key) => quote.files && quote.files[key])
      .reduce((files, key) => {
        if (quote.files[key]) {
          return [...files, quote.files[key]]
        }

        return files
      }, [])
  ]
}
