import React, { useState, useEffect } from 'react'
import type { QuoteFile } from 'paintscout'
import type { StyleClasses } from '@ui/core/theme'

import type { ImageProps, VideoProps, CloudinaryComponentProps } from 'cloudinary-react'
import Image from './Image'
import Video from './Video'
import type { Theme } from '@material-ui/core'
import { makeStyles, Avatar } from '@material-ui/core'
import classnames from 'classnames'
import Typography from '../Typography'
import Spinner from '../Spinner'
import ReactYouTube from 'react-youtube'

import { PageIcon } from '@ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import AttachmentIcon from '@material-ui/icons/Attachment'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'

type CommonCloudinaryProps = Omit<ImageProps & VideoProps, keyof CloudinaryComponentProps>
export interface FilePreviewProps extends CommonCloudinaryProps {
  classes?: StyleClasses<typeof useStyles>
  className?: string
  fancyHover?: boolean
  file: QuoteFile
  inGallery?: boolean
  inPresentation?: boolean
  isAnimated?: boolean
  isEditable?: boolean
  onPageSelect?: (pages: number[]) => void
  selectedPages?: number[]
  showAllPages?: boolean
  showFirstPageOnly?: boolean
  showPdfIcon?: boolean
  showSwapOnHover?: boolean
  showViewHover?: boolean
  square?: boolean
  uploading?: boolean
  videoThumbnail?: boolean // if true, the video will only render an image thumbnail rather than a video player
}

const useStyles = makeStyles<Theme, FilePreviewProps>(
  (theme) => ({
    root: (props) => ({
      position: 'relative',
      textAlign: 'center',
      overflow: 'hidden'
    }),
    size: {},
    pdfPaper: {
      width: '100%',
      boxShadow: 'none',
      minHeight: 120,
      borderRadius: 0
    },
    thumbnailPdfPaper: {
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: 0,
      borderRadius: 3,
      overflow: 'hidden',
      '& > img': {
        objectPosition: '50% 0%'
      }
    },
    pageMargin: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1.5)
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    imageViewDiv: {
      '&:hover': {
        '& $shadowDiv': {
          opacity: 0.7
        },
        '& $shadowText': {
          opacity: 2,
          zIndex: 10
        }
      }
    },
    shadowText: (props) => ({
      position: 'absolute',
      bottom: props.inGallery ? '10%' : '5%',
      left: props.inGallery ? '30%' : '0%',
      lineHeight: '1',
      fontSize: '1rem',
      fontWeight: 'bold',
      color: 'white',
      opacity: 0,
      pointerEvents: 'none'
    }),
    shadowDiv: (props) => ({
      position: 'absolute',
      top: '122%',
      left: '50%',
      backgroundColor: 'transparent',
      fontSize: '1.9rem',
      outline: props.inGallery ? '110px solid rgba(0,0,0,0.2)' : '50px solid rgba(0,0,0,0.2)', // fill
      opacity: 0.0,
      pointerEvents: 'none'
    }),
    pageDiv: (props) => ({
      position: 'relative',
      width: '100%',
      aspectRatio: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.grey['200'],
      borderRadius: theme.borderRadius['md'],
      '&:hover': {
        '& $descriptionIcon': {
          color: theme.palette.secondary.main
        },
        '& $pageIcon': {
          color: theme.palette.secondary.main
        }
      }
    }),
    pageIcon: (props) => ({
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
      color:
        props.isEditable || props.inGallery
          ? theme.palette.secondary.light
          : `${theme.palette.secondary.light} !important`,
      outline: '0px solid rgba(0,0,0,0.34)',
      pointerEvents: 'none'
    }),
    descriptionIcon: (props) => ({
      zIndex: 12,
      lineHeight: 1,
      position: 'absolute',
      top: props.inGallery ? '65%' : '62%',
      left: props.inGallery ? '50%' : '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      padding: '0.1em',
      fontSize: props.inGallery ? '0.9rem' : '0.8rem',
      outline: '0px solid rgba(0,0,0,0.36)',
      color:
        props.isEditable || props.inGallery
          ? theme.palette.secondary.light
          : `${theme.palette.secondary.light} !important`,
      opacity: props.isEditable || props.inGallery ? 0.9 : `${0.9} !important`,
      fontWeight: 'bold',
      pointerEvents: 'none'
    }),
    pdfImage: (props) => ({
      display: props.inGallery ? 'auto' : 'none',
      position: 'absolute',
      top: props.inGallery ? '65%' : '62%',
      left: props.inGallery ? '50%' : '50%',
      transform: 'translate(-50%, -50%)',
      height: props.inGallery ? '70px' : '20px',
      width: props.inGallery ? '60px' : '20px',
      ...(props.square ? { aspectRatio: '1/1', objectFit: 'cover', borderRadius: 3 } : null)
    }),
    placeholder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.typography.h1
    },
    fileRoot: {
      transition: 'transform 400ms ease',
      maxHeight: '100%',
      maxWidth: '100%',
      // fixes a small gap below  causing it not to fill parent div - https://stackoverflow.com/a/10266919
      verticalAlign: 'top'
    },
    image: (props) => {
      return {
        height: 'auto',
        width: 'auto',
        ...(props.square ? { aspectRatio: '1/1', objectFit: 'cover', borderRadius: 3 } : null)
      }
    },
    clickable: (props) => ({
      cursor: 'pointer',
      '&:hover': {
        '& img': {
          transform: props.fancyHover ? 'scale(1.05)' : 'none'
        }
      }
    }),
    tightWidth: (props) => ({
      display: 'flex',
      justifyContent: props.file?.presentationOptions?.horizontalAlign || 'center'
    }),
    uploadProgress: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0
    },
    swapOverlay: {
      display: 'none',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.4)',
      color: 'white',
      cursor: 'pointer'
      // borderRadius: theme.borderRadius.md
    },
    swapOverlayWrapper: {
      position: 'relative',
      '&:hover': {
        '& $swapOverlay': {
          display: 'flex'
        }
      }
    },
    textOverlay: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      padding: '1em',
      background: 'rgba(0,0,0,0.4)',
      color: 'white',
      pointerEvents: 'none'
    },
    videoOverlay: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      opacity: 0.8,
      pointerEvents: 'none'
    },
    title: {
      color: 'white'
    },
    caption: {
      color: 'white'
    },
    youtubeContentWrapper: {
      width: '100%',
      pointerEvents: 'none'
    },
    youtubeWrapper: {
      width: '100%',
      position: 'relative',
      paddingTop: '56.25%',
      '& iframe': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0
      }
    },
    pageNumbers: {
      position: 'absolute',
      background: theme.palette.grey[200],
      borderRadius: theme.borderRadius.md,
      right: theme.spacing(1),
      top: theme.spacing(1),
      padding: theme.spacing(0.5, 1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0)
      }
    },
    pageNumberText: {
      color: theme.palette.primary.main
    },
    selected: {
      border: '1px solid #000'
    },
    selectedIcon: {
      position: 'absolute',
      right: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      // fontSize: '2rem',
      borderRadius: '50%',
      background: 'white',
      padding: 0
    },
    selectButton: {
      justifySelf: 'left',
      alignSelf: 'flex-start'
    },
    pages: {}
  }),
  { name: 'FilePreview' }
)

function FilePreview({
  className,
  file,
  uploading,
  square,
  showSwapOnHover,
  selectedPages,
  onPageSelect,
  showFirstPageOnly,
  showAllPages,
  showPdfIcon,
  videoThumbnail,
  isAnimated,
  ...otherProps
}: FilePreviewProps) {
  let content: React.ReactNode
  const classes = useStyles({ file, square, ...otherProps })
  const { cloudinaryPublicId: publicId, title, caption } = file
  const [currentPages, setCurrentPages] = useState<number[]>(Array.from({ length: file.pages }, (v, i) => i))

  useEffect(() => {
    setCurrentPages(selectedPages)
  }, [selectedPages])

  const isVideo = (file?.type ?? '')?.startsWith('video') || ['mp4', 'mov'].includes(file?.format)
  const isPdf = file?.format === 'pdf'

  if (file.youtubeId && file.format === 'youtube') {
    content = (
      <div className={classes.youtubeWrapper}>
        <ReactYouTube videoId={file?.youtubeId} opts={{ playerVars: { rel: 0 } }} />
      </div>
    )
  } else if (!file.cloudinaryPublicId || uploading) {
    content = (
      <div className={classnames(classes.fileRoot, classes.placeholder)} onClick={otherProps.onClick}>
        <AttachmentIcon color="inherit" fontSize={'inherit'} />
      </div>
    )
  } else {
    if (file.type && isVideo) {
      if (videoThumbnail) {
        // workaround for https://github.com/cloudinary/cloudinary-react/issues/85
        content = (
          <Image
            {...otherProps}
            classes={{
              root: classnames(classes.fileRoot, classes.image)
            }}
            publicId={`${publicId}.jpg`}
            resourceType="video"
          />
        )
      } else {
        content = (
          <Video
            {...otherProps}
            classes={{
              root: classnames(classes.fileRoot, classes.image)
            }}
            publicId={publicId}
            title={title}
          />
        )
      }
    } else if (isPdf) {
      let pages = []
      if (showFirstPageOnly) {
        pages = [0]
      } else if (selectedPages && !showAllPages) {
        pages = selectedPages
      } else if (file.selectedPages && !showAllPages) {
        pages = file.selectedPages
      } else if (!showAllPages) {
        pages = [0]
      } else if (file.pages) {
        pages = Array.from({ length: file.pages }).map((item, index) => index)
      } else {
        pages = [0]
      }

      const handlePdfPageClick = (page: any) => {
        if (!selectedPages) return onPageSelect(page)
        if (selectedPages?.includes(page)) {
          const c = currentPages.filter((p) => p !== page)
          setCurrentPages(c)
          if (onPageSelect) {
            onPageSelect(c)
          }
        } else {
          const c = [...selectedPages, page]
          setCurrentPages(c)
          if (onPageSelect) {
            onPageSelect(c)
          }
        }
      }

      content = (
        <div className={classes.pages}>
          {pages.map((page, index) => (
            <div key={page} className={classes.pageMargin}>
              {!!onPageSelect && !!selectedPages && (
                <div className={classes.pageNumbers}>
                  <Typography className={classes.pageNumberText} variant={'subtitle1'}>
                    {page + 1}/{pages.length}
                  </Typography>
                </div>
              )}
              {!!onPageSelect && !!selectedPages && selectedPages?.includes(page) && (
                <CheckCircleIcon className={classes.selectedIcon} />
              )}
              {showPdfIcon ? (
                <div
                  onClick={(ev) => {
                    return onPageSelect ? handlePdfPageClick(page) : otherProps.onClick ? otherProps.onClick(ev) : null
                  }}
                  className={classes.pageDiv}
                >
                  <PageIcon className={classes.pageIcon} />
                  <Typography className={classes.descriptionIcon} variant={'h5'}>
                    View PDF
                  </Typography>
                </div>
              ) : (
                <div
                  className={classnames({
                    [classes.pdfPaper]: true,
                    [classes.thumbnailPdfPaper]: showFirstPageOnly,
                    [classes.selected]: !!onPageSelect && selectedPages?.includes(page) ? true : false
                  })}
                  key={page}
                >
                  <Image
                    {...otherProps}
                    page={page + 1}
                    classes={{
                      root: classnames({
                        [classes.fileRoot]: true,
                        [classes.image]: true,
                        [classes.clickable]: !!onPageSelect
                      })
                    }}
                    publicId={`${publicId}.png`}
                    resourceType="image"
                    onClick={(ev) =>
                      onPageSelect ? handlePdfPageClick(page) : otherProps.onClick ? otherProps.onClick(ev) : null
                    }
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )
    } else {
      content = (
        <div className={classnames(classes.imageViewDiv, classes.size)}>
          {otherProps.showViewHover && (
            <>
              <Typography variant={'h5'} className={classes.shadowText}>
                View Image
              </Typography>
              <div className={classes.shadowDiv} />
            </>
          )}
          <Image
            {...otherProps}
            publicId={publicId}
            title={title}
            classes={{
              root: classnames(classes.fileRoot, classes.image)
            }}
            onClick={(ev) => (otherProps.onClick ? otherProps.onClick(ev) : null)}
          />
        </div>
      )
    }
  }

  if (content) {
    return (
      <div
        className={classnames({
          [classes.root]: true,
          [classes.clickable]: !!otherProps.onClick,
          [className]: !!className,
          [classes.uploading]: !!uploading,
          [classes.tightWidth]: showSwapOnHover,
          [classes.size]: true
        })}
        data-testid="file-preview"
      >
        <div
          className={classnames({
            [classes.youtubeContentWrapper]: file.format === 'youtube',
            [classes.swapOverlayWrapper]: showSwapOnHover,
            [classes.size]: true
          })}
        >
          {content}
          {showSwapOnHover && <Overlay classes={classes} />}
          {videoThumbnail && !uploading && file?.type?.startsWith('video') && <VideoOverlay classes={classes} />}
          {uploading && (
            <Spinner
              className={classes.uploadProgress}
              fullWidth={true}
              fullHeight={true}
              data-testid="file-preview-upload-spinner"
            />
          )}
        </div>
      </div>
    )
  }

  return null
}

function VideoOverlay(props) {
  const { classes } = props

  return (
    <div className={classes.videoOverlay}>
      <Avatar>
        <PlayArrow color="inherit" />
      </Avatar>
    </div>
  )
}

function Overlay(props: { classes: any }) {
  const { classes } = props
  return (
    <div className={classes.swapOverlay}>
      <SwapVertIcon />
    </div>
  )
}

export default FilePreview
