import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useExportContactsLazyQuery } from '@paintscout/api'
import FileSaver from 'file-saver'

type ExportCSVArgs = {
  type: 'contacts'
}

function formatDate(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function useCSVExport() {
  const { enqueueSnackbar } = useSnackbar()
  const [isExporting, setIsExporting] = useState(false)
  const [exportContacts, { data, loading, error }] = useExportContactsLazyQuery({
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loading && isExporting) {
      if (data?.exportContacts?.rows) {
        const dateStr = formatDate(new Date())
        const fileName = `${dateStr}-stickybid-customers.csv`
        FileSaver.saveAs(new Blob([data.exportContacts.csv], { type: 'text/csv' }), fileName)
        enqueueSnackbar('Customers exported successfully.', { variant: 'success' })
      } else if (error) {
        enqueueSnackbar('Unable to export customers.', { variant: 'error' })
      } else {
        enqueueSnackbar('No customers found to export.', { variant: 'error' })
      }
      setIsExporting(false)
    }
  }, [data, loading, error, isExporting, enqueueSnackbar])

  const handleExportCSV = useCallback(
    (args: ExportCSVArgs) => {
      const { type } = args
      if (type === 'contacts') {
        setIsExporting(true)
        exportContacts({
          variables: {
            query: '*:*',
            sort: ['-updated_date']
          }
        })
      } else {
        console.log('Unsupported export type')
      }
    },
    [exportContacts]
  )

  return {
    handleExportCSV,
    isExporting
  }
}
