import { Grid } from '@material-ui/core/'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { WithClientOptions, WithDialogStackContext } from '@ui/stickybid'
import {
  Button,
  CustomBadge,
  FormSectionTitle,
  HtmlContent,
  Typography,
  withClientOptions,
  withDialogStackContext
} from '@ui/stickybid'
import type { QuoteItemSection, QuoteItemView } from '@paintscout/util/builder'
import {
  getDetails,
  getObjectLabels,
  getQuoteOptions,
  getTotals,
  getFeature,
  setQuoteOptions
} from '@paintscout/util/builder'
import { isEmpty } from '@paintscout/util/util'
import classnames from 'classnames'
import type { QuoteDocument } from 'paintscout'
import React from 'react'
import AreaSubstrateView from '../../../AreaSubstrateView'
import type { WithQuoteContext } from '../../../context/withQuoteContext'
import { withQuoteContext } from '../../../context/withQuoteContext'
import EditCrewNoteDialog from '../../../dialogs/EditCrewNoteDialog'
import FilesByItem from '../../FilesByItem'
import QuoteHeader from '../../QuoteHeader'
import QuoteHeaderCard from '../../QuoteHeader/QuoteHeaderCard'
import OptionsTable from '../QuoteView/OptionsTable'
import WorkOrderTable from './WorkOrderTable'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    headerWrapper: {},
    note: {
      padding: 0,
      cursor: 'pointer'
    },
    noteHover: {
      padding: 0
    },
    hours: {
      padding: 0
    },
    tableWrapper: {
      marginBottom: theme.spacing(2)
    },
    optionsTable: {},
    row: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between'
    },
    table: {
      // marginBottom: theme.spacing(4),
      '& thead': {
        background: theme.palette.grey[200]
      },
      '& thead th': {
        color: theme.palette.common.black
      }
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    },
    spacer: {
      marginTop: theme.spacing(5)
    },
    afterHeaderWrapper: {
      display: 'grid',
      gridTemplateColumns: 'auto 270px',
      gridGap: theme.spacing(2)
    },
    internalNote: {
      whiteSpace: 'pre-wrap'
    },
    headerTotals: {
      marginBottom: theme.spacing(2)
    }
  })

export interface WorkOrderViewProps
  extends WithStyles<typeof styles>,
    WithQuoteContext,
    WithClientOptions,
    WithDialogStackContext {}

class WorkOrderView extends React.Component<WorkOrderViewProps> {
  public render() {
    const { classes, quoteContext, clientOptions } = this.props
    const { tableView, isEditable, quote } = quoteContext
    const { options } = clientOptions
    const quoteOptions = getQuoteOptions({ quote, options })
    const {
      showWorkOrderAreas,
      showWorkOrderAreaOptions,
      showWorkOrderSubstrates,
      showWorkOrderAreaValues,
      showWorkOrderSubstrateValues,
      showWorkOrderFiles,
      workOrderOptionsConsumer
    } = quoteOptions

    const details = getDetails({ quote })
    const objectLabels = getObjectLabels({ options })
    const { internal: internalNote } = details.notes

    const toggleWorkOrderTotalHours = getFeature({ options, path: 'quotes.workOrderToggleTotalHours' })

    const productNote = details.notes.products.useCustom
      ? (details.notes.products.custom as string)
      : (details.notes.products.default as string)

    const totals = getTotals({ quote, options, consumer: 'crew' })
    const hasTotalDimensions =
      totals.dimensions &&
      (totals.dimensions.lnft > 0 ||
        totals.dimensions.sqft > 0 ||
        totals.dimensions.sqftWalls > 0 ||
        totals.dimensions.sqftCeiling > 0 ||
        totals.dimensions.sqftFloor > 0)

    const defaultView = !quote.defaultView || quote.defaultView === 'crew' ? 'area' : quote.defaultView

    const noteHoverContent = (
      <Button variant={'outlined'} fullWidth={true} classes={{ root: classes.hidePrint }}>
        Edit Crew Note
      </Button>
    )
    const productHoverContent = (
      <Button variant={'outlined'} fullWidth={true} classes={{ root: classes.hidePrint }}>
        Edit Product Description
      </Button>
    )

    const optionsViewSwitcher = isEditable ? (
      <AreaSubstrateView
        view={workOrderOptionsConsumer}
        onChange={this.handleOptionsViewChange}
        options={[
          { label: 'Customer/Price', value: 'customer' },
          { label: 'Crew/Hours', value: 'crew' }
        ]}
      />
    ) : null

    const hasInternalNote = !isEmpty(internalNote)

    const totalHours = details.totals?.hours?.useCustom ? details.totals.hours.custom : details.totals.hours.default
    const showTotalHours =
      (!toggleWorkOrderTotalHours || showWorkOrderAreaValues) && (isEditable || Number(totalHours) > 0)

    return (
      <div className={classes.root} id={'work-order'} data-testid="work-order">
        <div className={classes.headerWrapper}>
          <QuoteHeader isEditable={isEditable} workOrder={true} />
        </div>
        <Grid container={true} spacing={3} classes={{ root: classes.headerTotals }}>
          <Grid item={true} xs={12} sm={8}>
            {(hasInternalNote || isEditable) && (
              <QuoteHeaderCard
                title="Crew Note"
                classes={{ contents: classes.note, placeholderContent: classes.noteHover }}
                onClick={this.handleEditNote}
                clickable={true}
                placeholderContent={noteHoverContent}
                showPlaceholder={!hasInternalNote}
              >
                <Typography
                  variant={'body1'}
                  className={classnames(classes.internalNote, 'crew-note-highlight')}
                  component={'div'}
                  onClick={this.handleNotesClick}
                >
                  <HtmlContent content={internalNote} />
                </Typography>
              </QuoteHeaderCard>
            )}
          </Grid>

          <Grid item={true} xs={12} sm={4} className={'hours-card'}>
            {showTotalHours && (
              <QuoteHeaderCard
                title="Total Hours"
                classes={{ contents: classes.hours }}
                clickable={isEditable}
                onClick={this.handleEditNote}
              >
                {details.totals.hours.useCustom ? (
                  <CustomBadge showBadge={isEditable}>
                    <Typography
                      variant={'h2'}
                      format={'hours'}
                      value={
                        typeof details.totals.hours.custom === 'string'
                          ? parseFloat(details.totals.hours.custom)
                          : details.totals.hours.custom
                      }
                    />
                  </CustomBadge>
                ) : (
                  <Typography
                    variant={'h2'}
                    value={
                      typeof details.totals.hours.default === 'string'
                        ? parseFloat(details.totals.hours.default)
                        : details.totals.hours.default
                    }
                    format={'hours'}
                  />
                )}
              </QuoteHeaderCard>
            )}
          </Grid>

          <Grid item={true} xs={12} sm={8}>
            {(!!productNote || isEditable) && (
              <QuoteHeaderCard
                title="Product Description"
                classes={{ contents: classes.note, placeholderContent: classes.noteHover }}
                onClick={this.handleEditNote}
                clickable={isEditable}
                placeholderContent={productHoverContent}
                showPlaceholder={!productNote}
              >
                <Typography
                  variant={'body1'}
                  component={'div'}
                  className={classes.internalNote}
                  onClick={this.handleNotesClick}
                >
                  <HtmlContent content={productNote} />
                </Typography>
              </QuoteHeaderCard>
            )}
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            {hasTotalDimensions && (
              <QuoteHeaderCard title="Total Dimensions (SQFT)" clickable={false}>
                <Grid container={true}>
                  {totals.dimensions.sqftWalls > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography component="span">Walls: </Typography>
                      <Typography component="span" value={totals.dimensions.sqftWalls} />
                    </Grid>
                  )}
                  {totals.dimensions.sqftCeiling > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography component="span">Ceiling: </Typography>
                      <Typography component="span" value={totals.dimensions.sqftCeiling} />
                    </Grid>
                  )}
                  {totals.dimensions.sqftFloor > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography component="span">Floor: </Typography>
                      <Typography component="span" value={totals.dimensions.sqftFloor} />
                    </Grid>
                  )}
                  {totals.dimensions.sqft > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography component="span">Other: </Typography>
                      <Typography component="span" value={totals.dimensions.sqft} />
                    </Grid>
                  )}
                  {totals.dimensions.lnft > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography component="span">Lnft: </Typography>
                      <Typography component="span" value={totals.dimensions.lnft} />
                    </Grid>
                  )}
                </Grid>
              </QuoteHeaderCard>
            )}
          </Grid>
        </Grid>

        <div className={classnames({ [classes.tableWrapper]: true, [classes.hidePrint]: !showWorkOrderAreas })}>
          <WorkOrderTable
            classes={{ root: classes.table }}
            section={'bid'}
            tableView={'area'}
            title={'Areas'}
            collapsed={!showWorkOrderAreas}
            onReorder={this.handleReorder}
            onAddClick={this.handleAdd}
            onItemClick={this.handleItemClick}
            showAddButton={false}
            onToggle={(ev: any) => this.handleToggle(ev, 'showWorkOrderAreas')}
            showHours={showWorkOrderAreaValues}
            onToggleAmount={this.handleToggleAmount}
            showEmpty={true}
            showLineItems={true}
          />
        </div>

        {showWorkOrderAreas && (
          <div className={classnames({ [classes.tableWrapper]: true, [classes.hidePrint]: !showWorkOrderAreas })}>
            <WorkOrderTable
              classes={{ root: classes.table }}
              section={'additional'}
              tableView={'area'}
              title={'Additional Work'}
              onReorder={this.handleReorder}
              onAddClick={this.handleAdd}
              onItemClick={this.handleItemClick}
              showAddButton={false}
              showHours={showWorkOrderAreaValues}
              showEmpty={false}
              showLineItems={true}
            />
          </div>
        )}

        <div className={classnames({ [classes.tableWrapper]: true, [classes.hidePrint]: !showWorkOrderSubstrates })}>
          <WorkOrderTable
            classes={{ root: classes.table }}
            section={'bid'}
            tableView={'substrate'}
            title={'Substrates'}
            collapsed={!showWorkOrderSubstrates}
            onReorder={this.handleReorder}
            onAddClick={this.handleAdd}
            onItemClick={this.handleItemClick}
            onToggle={(ev: any) => this.handleToggle(ev, 'showWorkOrderSubstrates')}
            showHours={showWorkOrderSubstrateValues}
            showLineItems={false}
          />
        </div>

        <div
          className={classnames({
            [classes.tableWrapper]: true,
            [classes.optionsTable]: true,
            [classes.hidePrint]: !showWorkOrderAreaOptions
          })}
        >
          {workOrderOptionsConsumer === 'customer' ? (
            <OptionsTable
              collapsed={!showWorkOrderAreaOptions}
              onToggle={(ev: any) => this.handleToggle(ev, 'showWorkOrderAreaOptions')}
              consumer={'customer'}
              showValues={showWorkOrderAreaValues}
              rightContent={optionsViewSwitcher}
            />
          ) : (
            <WorkOrderTable
              classes={{ root: classes.table }}
              section={'options'}
              tableView={'area'}
              rightContent={optionsViewSwitcher}
              title={objectLabels.option.value}
              subTitle={'These items are optional additions and are not included in the total'}
              collapsed={!showWorkOrderAreaOptions}
              onReorder={this.handleReorder}
              onAddClick={this.handleAdd}
              onItemClick={this.handleItemClick}
              showAddButton={false}
              onToggle={(ev: any) => this.handleToggle(ev, 'showWorkOrderAreaOptions')}
              showHours={showWorkOrderAreaValues}
              onToggleAmount={this.handleToggleAmount}
              showEmpty={true}
              showLineItems={true}
            />
          )}
        </div>

        <div
          id={'pictures'}
          className={classnames({ [classes.tableWrapper]: true, [classes.hidePrint]: !showWorkOrderFiles })}
        >
          <FilesByItem
            FormSectionTitle={
              <FormSectionTitle
                title={'Media'}
                showToggle={isEditable}
                toggleValue={showWorkOrderFiles}
                style={{ marginBottom: 0 }}
                onToggle={(ev: any) => this.handleToggle(ev, 'showWorkOrderFiles')}
              />
            }
            sections={['bid', 'options']}
            consumer={'crew'}
          />
        </div>
      </div>
    )
  }

  public handleOptionsViewChange = (event: any, view: 'crew' | 'customer') => {
    const { quoteContext, clientOptions } = this.props
    const { quote } = quoteContext
    const { options } = clientOptions
    const quoteOptions = getQuoteOptions({ options, quote })

    const updatedQuote = setQuoteOptions({ quoteOptions: { ...quoteOptions, workOrderOptionsConsumer: view }, quote })
    quoteContext.updateQuote({ quote: updatedQuote })
  }

  public handleToggleAmount = (ev: any) => {
    const { quoteContext, clientOptions } = this.props
    const { quote } = quoteContext
    const { options } = clientOptions

    const quoteOptions = getQuoteOptions({ options, quote })
    const keys = [
      'showWorkOrderAreaValues',
      'showWorkOrderSubstrateValues',
      'showWorkOrderAreaOptionValues',
      'showWorkOrderSubstrateOptionValues'
    ]
    keys.forEach((key) => {
      quoteOptions[key] = !quoteOptions[key]
    })

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    quoteContext.updateQuote({ quote: updatedQuote })
  }

  public handleEditNote = (event: React.MouseEvent<HTMLElement>) => {
    const { quoteContext, dialogStackContext } = this.props
    const { quote } = quoteContext

    dialogStackContext.openDialog(EditCrewNoteDialog, {
      quote,
      dialogStackContext,
      onConfirm: (updatedQuote: QuoteDocument) => {
        quoteContext.updateQuote({ quote: updatedQuote })

        dialogStackContext.dismissDialog()
      },
      onCancel: () => {
        dialogStackContext.dismissDialog()
      }
    })
  }

  public handleReorder = (args: { items: any[]; section: QuoteItemSection; view: QuoteItemView }) => {
    if (this.props.quoteContext.onReorder) {
      this.props.quoteContext.onReorder(args)
    }
  }

  public handleAdd = (event: React.MouseEvent<HTMLElement>) => {
    if (this.props.quoteContext.onAddItem) {
      this.props.quoteContext.onAddItem()
    }
  }

  public handleTableViewChange = (event: any, view: string) => {
    if (this.props.quoteContext.onTableViewChange) {
      this.props.quoteContext.onTableViewChange(view)
    }
  }

  public handleItemClick = (event: React.MouseEvent<HTMLElement>, key: string) => {
    if (this.props.quoteContext.onEditItem) {
      this.props.quoteContext.onEditItem(key)
    }
  }

  public handleNotesClick = (event: React.MouseEvent<HTMLElement>) => {
    const { isEditable } = this.props.quoteContext
    if (this.props.quoteContext.onEditDetails) {
      this.props.quoteContext.onEditDetails()
    }
  }

  // public handleItemActionClick = (ev: any, action: string, key: string) => {
  //   const { onEditItem, onItemAction } = this.props.quoteContext
  //   if (action === 'edit') {
  //     onEditItem(key)
  //   } else {
  //     onItemAction(action, [key])
  //   }
  // }

  public handleToggle = (event: any, name: string) => {
    const { quoteContext, clientOptions } = this.props
    const { quote } = quoteContext
    const { options } = clientOptions

    const quoteOptions = getQuoteOptions({ options, quote })

    quoteOptions[name] = !quoteOptions[name]

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    quoteContext.updateQuote({ quote: updatedQuote })
  }
}

export default withStyles(styles)(withQuoteContext(withClientOptions(withDialogStackContext(WorkOrderView))))
