import { gql } from 'apollo-boost'
import type { UserDocument } from 'paintscout'

export const GET_USER = gql`
  query getUser($id: String!) {
    user(id: $id) {
      user_id
      email
      name
      picture
      user_metadata {
        firstName
        lastName
        phoneNumber
        customAvatar {
          label
          value
          cdnValue
          cloudinaryId
          width
          height
          fileType
          encoding
        }
      }
      app_metadata {
        active
        roles
        elasticsearch
      }
      preferences
      client {
        meta {
          _id
          _rev
          type
          active
          trialExpiry
          billingId
          parentCompanyId
          plan
          name
          address
          city
          province
          country
          postal
          phone
          email
          notes
        }
        options
      }
    }
  }
`

export interface GetUserResponse {
  user: UserDocument
}
