import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const StickyBid = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 53.26 52.93'} className={props.classes?.root} {...props}>
    <svg>
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="26.63"
        y1="1"
        x2="26.63"
        y2="51.93"
      />
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="52.09"
        y1="26.46"
        x2="1.17"
        y2="26.46"
      />
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="44.64"
        y1="8.46"
        x2="8.63"
        y2="44.47"
      />
      <line
        style={{ fill: 'none', stroke: '#30b6a0', strokeMiterlimit: 10, strokeWidth: '5.47px' }}
        x1="44.64"
        y1="44.47"
        x2="8.63"
        y2="8.46"
      />
    </svg>
  </SvgIcon>
)
export default StickyBid
