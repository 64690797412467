import type { QuoteDocument, OptionsDocument, PresentationOption } from 'paintscout'

import { getQuotePresentation } from '../get-quote-presentation'
import { getQuoteTypeOptions } from '../../../options'

import find from 'lodash/find'

/**
 * Returns the logo that should appear on a presentation or a quote inside of a presentation
 * Precedence goes to the custom presentation logo, then to the quoteType logo, and finally the company logo
 */

export function getPresentationLogo(args: {
  options: OptionsDocument
  quote: QuoteDocument
  presentation?: PresentationOption
  inactive?: boolean // return inactive presentations
  current?: boolean // return current presentations from options(sb feature)
  view?:
    | 'work-order'
    | 'product-order-form'
    | 'quote'
    | 'details'
    | 'notes'
    | 'activity'
    | 'terms'
    | 'presentation'
    | 'archived-items'
}) {
  const { current, inactive, options, presentation: _presentation, quote, view } = args
  let logo = options.options?.logo?.value
  let isCustom = false

  try {
    const quoteTypeOptions = getQuoteTypeOptions({ options })
    const quoteType = find(quoteTypeOptions, { key: quote.int_ext.value })
    if (quoteType && quoteType.overrideCompanyOptions && quoteType.companyOptions && quoteType.companyOptions?.logo) {
      logo = quoteType.companyOptions.logo.value
      isCustom = true
    }

    // take passed in presentation, else use the one attached to the quote
    const presentation = _presentation || getQuotePresentation({ options, quote, inactive, current, view })
    if (presentation?.advanced?.customLogo?.value) {
      logo = presentation.advanced.customLogo.value
      isCustom = true
    }
  } catch (err) {
    console.error(err)
  }

  return { logo, isCustom }
}
