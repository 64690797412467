/**
 * @module builder
 */
import type { MoveableItem, QuoteItemSection } from '../../index'
import { filterBid } from './filter-bid'
import { filterOption } from './filter-option'
import { filterArchived } from './filter-archived'
import { filterAdditional } from './filter-additional'
import { filterPending } from './filter-pending'

export function filterItem(item: MoveableItem, section: QuoteItemSection): boolean {
  if (!item) {
    return false
  }

  if (item.type === 'group') {
    return item.section === section
  }

  if (section === 'bid') {
    return filterBid(item)
  } else if (section === 'options') {
    return filterOption(item)
  } else if (section === 'archived') {
    return filterArchived(item)
  } else if (section === 'additional') {
    return filterAdditional(item)
  } else if (section === 'pending') {
    return filterPending(item)
  } else {
    return true
  }
}
