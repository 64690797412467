import { overridePriceSubstrate } from './override-price-substrate'
import { overrideHoursSubstrate } from './override-hours-substrate'
import { quantitySubstrate } from './quantity-substrate'
import { pricePerValueSubstrate } from './price-per-value-substrate'
import { valuePerHourSubstrate } from './value-per-hour-substrate'

import { zero } from '../util/zero'
import { parseNumber } from '../util/parse-number'

import clone from 'lodash/clone'
import type { QuoteArea, AreaSubstrate, QuoteDocument, OptionsDocument } from 'paintscout'
import { convertDimensions } from '../../builder/quote/area-substrates/util/util'
import { getArea } from '../../builder/quote/areas/get-area/get-area'

export function singleSubstrate(
  substrate: AreaSubstrate,
  area: QuoteArea,
  quote: QuoteDocument,
  options: OptionsDocument
) {
  if (substrate._deleted) {
    return substrate
  }

  // Sync the area dimensions w/ substrate dimensions to account for overrides there
  const updateableArea =
    quote?.areas[area?.key] && quote?.version >= 3 ? getArea({ quote, options, key: area.key }) : null
  let syncedLength = null
  let syncedWidth = null
  let syncedHeight = null
  if (updateableArea) {
    const { length, width, height } = convertDimensions({ areaSubstrate: substrate, area: updateableArea })
    syncedLength = length
    syncedWidth = width
    syncedHeight = height
  }

  const updatedSubstrate = {
    ...clone(substrate),
    ...(updateableArea
      ? {
          height: syncedHeight.useCustom ? syncedHeight.custom : syncedHeight.default,
          width: syncedWidth.useCustom ? syncedWidth.custom : syncedWidth.default,
          length: syncedLength.useCustom ? syncedLength.custom : syncedLength.default
        }
      : {})
  }

  if (!updatedSubstrate.rate) {
    return zero<AreaSubstrate>(updatedSubstrate)
  }

  if (!updatedSubstrate.rate_value && updatedSubstrate.coats && updatedSubstrate.rate && updatedSubstrate.rate.values) {
    const coats = parseNumber(updatedSubstrate.coats)
    updatedSubstrate.rate_value = updatedSubstrate.rate.values[coats - 1]
  }

  if (updatedSubstrate.rate.calculation_type === 'price') {
    return overridePriceSubstrate(updatedSubstrate, quote)
  } else if (updatedSubstrate.rate.calculation_type === 'hours') {
    return overrideHoursSubstrate(updatedSubstrate, quote)
  } else if (updatedSubstrate.rate.rate_type === 'quantity') {
    return quantitySubstrate(updatedSubstrate, quote)
  } else if (updatedSubstrate.rate.calculation_type === 'price_per_value') {
    return pricePerValueSubstrate(updatedSubstrate, area, quote)
  } else {
    return valuePerHourSubstrate(updatedSubstrate, area, quote)
  }
}
