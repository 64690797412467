import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import isTouchDevice from 'is-touch-device'
import React, { useRef, useState } from 'react'
import Keypad from '../Keypad'
import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'
import { useUser } from '../UserProvider'
import NumberFormat from 'react-number-format'

export interface KeypadInputProps extends Omit<InputFieldProps, 'classes'>, WithStyles<typeof styles> {
  showKeypad?: 'always' | 'never' | 'touch'
  allowDecimal?: boolean
  allowNegative?: boolean
  decimalScale?: number
  fixedDecimalScale?: boolean
  flipX?: boolean
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      minHeight: '44px'
    },
    wrapper: {
      position: 'relative',
      display: 'inline-block',
      width: '100%'
    },
    keypad: {
      position: 'absolute'
    },
    inputFocused: {},
    inputInput: {},
    icon: {},
    adornment: {}
  })
}

function KeypadInputField({
  showKeypad,
  onBlur,
  autoFocus,
  name,
  allowDecimal,
  allowNegative,
  decimalScale,
  fixedDecimalScale,
  classes,
  flipX,
  readOnly,
  ...inputProps
}: KeypadInputProps) {
  const [open, setOpen] = useState(autoFocus)
  const [inputElement, setInputElement] = useState(null)
  const { preferences } = useUser()
  const keypadEnabled = isEnabled()

  const keypadRef = useRef(null)

  function toggle() {
    setOpen(!open)
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    // This was an extra bit to stop the underlying (non-keypad)
    // input from firing the blur event when the keypad was open.
    if ((keypadEnabled && !open && onBlur) || (!keypadEnabled && onBlur)) {
      onBlur(event)
    }
  }

  setTimeout(() => {
    if (keypadRef && keypadRef.current && keypadRef.current.scrollIntoView) {
      keypadRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  })

  return (
    <div className={classes.wrapper}>
      <InputField
        {...inputProps}
        classes={{
          root: classes.root,
          inputFocused: classes.inputFocused,
          icon: classes.icon,
          adornment: classes.adornment,
          inputInput: classes.inputInput
        }}
        autoFocus={autoFocus}
        onBlur={handleInputBlur}
        onClick={toggle}
        name={name}
        inputRef={(element) => {
          setInputElement(element)
        }}
        readOnly={readOnly || keypadEnabled}
        inputComponent={NumberFormatCustom as any}
        inputProps={{ decimalScale: decimalScale, fixedDecimalScale: fixedDecimalScale }}
      />
      {keypadEnabled && (
        <Keypad
          value={inputProps.value as number}
          ref={keypadRef}
          allowNegative={allowNegative}
          allowDecimal={allowDecimal}
          classes={{ root: classes.keypad }}
          onClose={toggle}
          onChange={inputProps.onChange}
          open={open}
          name={name}
          flipX={flipX}
          anchor={inputElement}
        />
      )}
    </div>
  )

  function isEnabled() {
    if (inputProps.disabled || readOnly) {
      return false
    }
    // Use showKeypad if it exists. If not,
    const keypadPreference = preferences?.keypad ?? 'touch'
    const keypad = showKeypad ?? keypadPreference

    return keypad === 'always' || (keypad === 'touch' && isTouchDevice())
  }
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  decimalScale?: number
  fixedDecimalScale?: boolean
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, decimalScale, fixedDecimalScale = true, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
      decimalScale={decimalScale || 2}
      fixedDecimalScale={fixedDecimalScale}
    />
  )
}

export default withStyles(styles)(KeypadInputField)
