import type { OptionsDocument, RatesDocument } from 'paintscout'
import type { OnboardingTasks } from '@paintscout/util/builder'
import React from 'react'

export type OnboardingTask = {
  button?: boolean
  completed: boolean
  hide?: boolean
  inProgress?: boolean
  key: string
  label: string
  learnHow?: () => void
  multistep?: boolean
  onClick: () => void
  onConfirm?: () => void
  steps?: OnboardingTask[]
  disabled?: boolean
}

export interface UpdateOptionsOnboardProps {
  key: keyof OnboardingTasks
  value: string | boolean
  options?: OptionsDocument
}

export interface OnboardingContextValue {
  activeTask: OnboardingTask
  completedTaskCount: number
  open: boolean
  resetOnboarding: () => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  taskCount: number
  taskItems: OnboardingTask[]
  updateAndSaveOptionsOnboard: (args: UpdateOptionsOnboardProps) => Promise<{
    options: OptionsDocument
    rates?: RatesDocument
  }>
  updateOptionsOnboard: (args: UpdateOptionsOnboardProps) => OptionsDocument
}

export const OnboardingContextInitValue: OnboardingContextValue = {
  activeTask: null,
  completedTaskCount: 0,
  open: false,
  resetOnboarding: () => null,
  setOpen: () => {},
  taskCount: 0,
  taskItems: [],
  updateAndSaveOptionsOnboard: () => null,
  updateOptionsOnboard: () => null
}

const OnboardingContext = React.createContext<OnboardingContextValue>(OnboardingContextInitValue)

export default OnboardingContext
