import type { OptionsDocument } from 'paintscout'

/**
 * Sanitizes older $options and turns them into correct properties
 */
export function sanitizeOptions(options: OptionsDocument): OptionsDocument {
  return {
    ...options,
    options: {
      ...options.options,
      labels: options.options.labels ?? {
        quote: 'Quote',
        workOrder: 'Work Order',
        application: 'Coat',
        labour: 'Painting',
        prep: 'Prep'
      }
    }
  }
}
