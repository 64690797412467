import { useActivityQuery } from '@paintscout/api'
import type { DialogProps } from '@ui/stickybid'
import { useDialogs } from '@ui/stickybid'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputField,
  Spinner,
  useClientOptions
} from '@ui/stickybid'
import React, { useCallback, useState } from 'react'
import { formatCurrency, setPayments } from '@paintscout/util/builder'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import getPaymentMethod from '@paintscout/util/util/getPaymentMethod/getPaymentMethod'
import { useQuote } from '../../context'
import type { Payment } from 'paintscout'
import { CloseButton } from '../common'
import * as Sentry from '@sentry/core'

export interface ViewPaymentDialogProps extends DialogProps {
  activityId: string
  onClose?: () => void
  onCancel: () => void
}

const ViewPaymentDialog = (props: ViewPaymentDialogProps) => {
  const { open, activityId, onClose: onConfirm, onCancel, ...baseDialogProps } = props
  const { options } = useClientOptions()
  const { quote, updateQuote } = useQuote()
  const { dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()
  const dateFormat = options.options.dateFormat.momentValue

  const [payment, setPayment] = useState<Payment>()

  const handleError = (error: any) => {
    Sentry.captureException(error)
    enqueueSnackbar('Unable to fetch payment activity', { variant: 'error' })
    dismissDialog()
  }

  const { loading, data } = useActivityQuery({
    fetchPolicy: 'network-only',
    pollInterval: 120000,
    notifyOnNetworkStatusChange: false,
    variables: {
      id: activityId
    },
    onCompleted: useCallback((data) => {
      if (!data.activity) {
        handleError(new Error(`Unable to fetch payment activity with id: ${activityId}`))
      } else {
        setPayment(quote.payments?.find((payment) => payment.activityId === activityId))
      }
    }, []),
    onError: useCallback((error) => {
      handleError(error)
    }, [])
  })

  const activity = data?.activity
  const paymentMethod = activity ? getPaymentMethod((activity.details as any)?.providerDetails?.latest_charge) : ''

  function handleChange(event: any) {
    const { value, name } = event.target
    setPayment({
      ...payment,
      [name]: value
    })
  }

  function isDirty() {
    const original = quote.payments?.find((payment) => payment.activityId === activityId)?.note
    return original !== payment?.note
  }

  function handleConfirm() {
    if (isDirty()) {
      const payments = quote.payments?.map((quotePayment) =>
        quotePayment.activityId === activityId ? payment : quotePayment
      )
      const updatedQuote = setPayments({ quote, payments, options })
      updateQuote({ quote: updatedQuote })
    }

    if (onConfirm) {
      onConfirm()
    }
  }

  return (
    <Dialog open={open} maxWidth="md" {...baseDialogProps}>
      <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>View Payment</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {loading && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}
          {!!activity && (
            <>
              {paymentMethod && (
                <Grid item xs={12}>
                  <InputField label={'Type/Method'} value={paymentMethod} readOnly fullWidth />
                </Grid>
              )}
              <Grid item xs={12}>
                <InputField
                  label={'Amount'}
                  value={formatCurrency({ options, value: (activity.details as any).amountPaid })}
                  readOnly
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label={'Date'}
                  value={moment(activity.details.timestamp).format(dateFormat)}
                  readOnly
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label={'Note'}
                  name={'note'}
                  value={payment?.note ?? ''}
                  fullWidth
                  multiline
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant={'contained'} onClick={handleConfirm}>
          {'done'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewPaymentDialog
