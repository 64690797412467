import React, { useMemo } from 'react'
import type { PresentationIndustry, PresentationLibrary, QuoteFile } from 'paintscout'
import type { WithStyles } from '@material-ui/core'
import { createStyles, Tooltip, withStyles } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import type { DropzoneProps, FilePreviewProps } from '@ui/stickybid'
import { Button, Dropzone, FilePreview, Grid, Typography, useMediaQuery, useUser } from '@ui/stickybid'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import { useConnection } from 'react-detect-offline'
import { TRIAL_QUOTE_FILES_LIMIT } from '@paintscout/util'
import { useQuote } from '../context'
import { getFiles } from '@paintscout/util/builder'
import PublishIcon from '@material-ui/icons/Publish'
import { InlineEditableMedia } from '../inlineEditable'

export interface FilesGridProps {
  files: QuoteFile[]
  isSettings?: boolean
  isEditable?: boolean
  handleEditFile?: (field: string, content: QuoteFile) => void
  mediaOptionsProps?: {
    sectionPrefix: string
  }
  editable?: boolean
  showUploadDropzone?: boolean
  onUploadClick?: (event: React.MouseEvent) => void
  responsiveTransformation?: boolean
  columns?: number
  mobileColumns?: number
  showBorder?: boolean
  fullItemHeight?: boolean
  showDetails?: boolean
  justify?: 'space-around' | 'space-between' | 'center' | 'flex-start' | 'flex-end'
  inPresentation?: boolean
  presentationIndustry?: PresentationIndustry
  presentationLibrary?: {
    addFilesToLibrary: (args: { files: QuoteFile[] }) => void
    library: PresentationLibrary
    saveLibrary: (args: { library: PresentationLibrary }) => void
  }

  onReorder?: (files: QuoteFile[]) => any
  onUpload?: (files: File[]) => any
  onFileClick?: (index: number) => any
  onDelete?: (index: number) => void
  onEdit?: (index: number) => void

  filePreviewProps?: Partial<FilePreviewProps>
}

type FilesGridPropsWithInjections = FilesGridProps & WithStyles<typeof styles>

const styles = createStyles({
  root: {}, // to be passed in
  dragging: {
    zIndex: 2147483002
  },
  grid3: {
    '@media print': {
      width: '25%',
      flexBasis: 'unset'
    }
  },
  grid6: {
    '@media print': {
      width: '50%',
      flexBasis: 'unset'
    }
  },
  addButton: {
    aspectRatio: '1/1'
  }
})

const SortableFilePreview = withStyles({
  filePreview: {
    height: '100%',
    width: '100%',
    flexGrow: 1,
    borderRadius: 3,
    objectFit: 'cover',
    aspectRatio: '1/1',
    userDrag: 'none',
    userSelect: 'none'
  },
  grid3: {
    '@media print': {
      width: '25%',
      flexBasis: 'unset'
    }
  },
  grid4: {
    '@media print': {
      width: '33.333%',
      flexBasis: 'unset'
    }
  },
  grid6: {
    '@media print': {
      width: '50%',
      flexBasis: 'unset'
    }
  },
  grid12: {
    '@media print': {
      width: '100%',
      flexBasis: 'unset'
    }
  },
  itemInner: ({ showBorder }: { showBorder: boolean }) => {
    const baseStyle = {
      height: '100%',
      // padding: 4,
      display: 'flex'
    }

    if (showBorder === false) {
      return {
        ...baseStyle
      }
    }

    return {
      height: '100%',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      // border: '1px solid #efefef',
      background: 'white',
      ...baseStyle
    }
  },

  imageWrapper: ({ showBorder }: { showBorder: boolean }) => {
    return {
      position: 'relative'
      // offset padding when border is hidden
      // ...(!showBorder && { marginLeft: -4 })
    }
  },
  imageWrapperItem: ({ fullItemHeight }: { fullItemHeight: boolean }) => {
    return {
      maxHeight: '100%',
      ...(fullItemHeight && { height: '100%' })
    }
  },
  item: { aspectRatio: '1/1' },
  caption: {
    marginBottom: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  deleteIcon: {
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
    height: 28,
    width: 28,
    top: -13,
    right: -13,
    color: '#fff',
    background: 'radial-gradient(circle, rgba(0,0,0,1) 50%, rgba(255,255,255,1) 50%);',
    border: '1px solid #000',
    borderRadius: '50%',
    overflow: 'hidden',
    '&:hover': {
      color: '#eee',
      background: 'radial-gradient(circle, rgba(0,0,0,1) 50%, rgba(238,238,238,1) 50%);'
    }
  },
  detailsBlock: {
    flexBasis: 0
  },
  editButton: {
    '& svg': {
      height: 14,
      marginRight: -5
    }
  },
  onClickWrapper: {
    cursor: 'pointer',
    height: 'inherit'
  }
})(
  SortableElement(
    ({
      file,
      fileIndex,
      classes,
      columns,
      mobileColumns,
      showBorder,
      fullItemHeight,
      showDetails,
      isSettings,
      isEditable,
      inPresentation,
      handleEditFile,
      mediaOptionsProps,
      presentationIndustry,
      presentationLibrary,
      onClick,
      onDelete,
      onEdit,
      filePreviewProps
    }: {
      file?: QuoteFile
      fileIndex: number
      columns: number
      mobileColumns: number
      showBorder: boolean
      fullItemHeight: boolean
      showDetails: boolean
      isSettings: boolean
      isEditable: boolean
      inPresentation: boolean
      presentationIndustry?: PresentationIndustry
      presentationLibrary?: {
        addFilesToLibrary: (args: { files: QuoteFile[] }) => void
        library: PresentationLibrary
        saveLibrary: (args: { library: PresentationLibrary }) => void
      }
      handleEditFile: (field: string, content: QuoteFile) => void
      mediaOptionsProps?: {
        sectionPrefix: string
      }
      classes: any
      onClick?: () => any
      onDelete?: (index: number) => any
      onEdit?: (index: number) => void
      filePreviewProps?: Partial<FilePreviewProps>
    }) => {
      const { sectionPrefix } = mediaOptionsProps || {}
      // to prevent rendering placeholders on CV/Preview
      // can't return null for a sortable element, queue this semihacky solution
      if (!isSettings && !file) return <div style={{ display: 'none' }}></div>

      let transformationProps: any = {
        ...getImageWidth(columns),
        crop: 'pad',
        background: '#fff'
      }

      if (file?.type?.startsWith('video')) {
        transformationProps = {
          ...transformationProps,
          controls: false
        }
      }

      const { columnWidth, columnClass } = getColumnWidth(columns, classes)
      const { columnWidth: mobileColumnWidth } = getColumnWidth(mobileColumns, classes)

      const handleEdit = (file: QuoteFile) => {
        // this gets called from onFileClick as in EditItemDialog
        // the if prevents trying to call an undefined function
        if (handleEditFile) handleEditFile(`fields.files.[${fileIndex}]`, file)
      }

      return (
        <Grid
          item
          key={file?.key ? file.key : file?.src ?? file?.tempKey}
          xs={mobileColumns ? mobileColumnWidth : 12}
          sm={columnWidth}
          md={columnWidth}
          className={columnClass}
        >
          <div className={classes.itemInner}>
            <Grid container classes={{ container: classes.imageWrapper }} alignItems="center">
              <Grid item xs={12} classes={{ root: classes.imageWrapperItem }}>
                {onDelete && (
                  <CancelIcon
                    className={classes.deleteIcon}
                    onClick={() => onDelete(fileIndex)}
                    data-testid="delete-button"
                  />
                )}
                {inPresentation && (
                  <div className={classes.onClickWrapper} onClick={onClick}>
                    <InlineEditableMedia
                      mediaOptionsProps={{
                        sectionPrefix: sectionPrefix,
                        fieldSuffix: `files.[${fileIndex}]`
                      }}
                      classes={{ image: classes.filePreview }}
                      file={file?.src ? file : null}
                      handleEdit={(file) => handleEdit(file)}
                      isEditable={isEditable}
                      isSettings={isSettings}
                      placeholderButtonText={'Add Media'}
                      presentationIndustry={presentationIndustry}
                      presentationLibrary={presentationLibrary}
                      square={true}
                      filePreviewProps={filePreviewProps}
                    />
                  </div>
                )}
                {!inPresentation && (
                  <FilePreview
                    classes={{ image: classes.filePreview }}
                    file={file}
                    videoThumbnail={true}
                    onClick={onClick}
                    {...transformationProps}
                    showAllPages={false}
                    inGallery={true}
                    inPresentation={false}
                    {...filePreviewProps}
                  />
                )}
                {onEdit && (
                  <Button
                    onClick={() => onEdit(fileIndex)}
                    prominence={3}
                    startIcon={<EditIcon />}
                    classes={{ root: classes.editButton }}
                  >
                    Replace
                  </Button>
                )}
                {showDetails && (
                  <Grid item xs={12} classes={{ root: classes.detailsBlock }}>
                    <Typography classes={{ root: classes.caption }} variant="subtitle2" gutterBottom={true}>
                      {file?.caption ? file.caption : <>&nbsp;</>}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      )
    }
  )
)

const SortableGridContainer = SortableContainer(
  ({
    files,
    disabled,
    showUploadDropzone,
    onUploadClick,
    inPresentation,
    columns,
    mobileColumns,
    showBorder,
    fullItemHeight,
    showDetails,
    isSettings,
    isEditable,
    handleEditFile,
    mediaOptionsProps,
    presentationIndustry,
    presentationLibrary,
    justify,
    onUpload,
    onClick,
    onDelete,
    onEdit,
    classes,
    filePreviewProps
  }: {
    files: QuoteFile[]
    disabled: boolean
    showUploadDropzone?: boolean
    onUploadClick?: (event: React.MouseEvent) => void
    columns: number
    mobileColumns: number
    showBorder: boolean
    fullItemHeight: boolean
    showDetails: boolean
    isSettings: boolean
    isEditable: boolean
    inPresentation: boolean
    handleEditFile: (field: string, content: QuoteFile) => void
    mediaOptionsProps?: {
      sectionPrefix: string
    }
    justify: 'space-around' | 'space-between' | 'center' | 'flex-start' | 'flex-end'
    classes: any
    filePreviewProps?: Partial<FilePreviewProps>
    presentationIndustry?: PresentationIndustry
    presentationLibrary?: {
      addFilesToLibrary: (args: { files: QuoteFile[] }) => void
      library: PresentationLibrary
      saveLibrary: (args: { library: PresentationLibrary }) => void
    }

    onUpload?: (files: File[]) => any
    onClick?: (index: number) => any
    onDelete?: (index: number) => any
    onEdit?: (index: number) => void
  }) => {
    const { online } = useConnection()
    const { quote } = useQuote()
    const { isTrial } = useUser()

    // calculate initial number of quote files so we know how many files are left without needing
    // to update the quote first
    const numQuoteFiles = useMemo(() => getFiles({ quote, attached: true }).length - files.length, [quote])

    const isMobile = useMediaQuery('sm')
    const responsiveSpacing = isMobile ? 3 : 6
    // the presence of mediaOptionsProps means this component is in a presentation
    const spacing = mediaOptionsProps ? responsiveSpacing : 3

    const { columnWidth } = getColumnWidth(columns, classes)
    const { columnWidth: mobileColumnWidth } = getColumnWidth(mobileColumns, classes)

    const canAddFiles = !isTrial || numQuoteFiles + files.length < TRIAL_QUOTE_FILES_LIMIT

    return (
      <Grid container spacing={spacing} alignItems={'stretch'} justifyContent={justify} className={classes.root}>
        {files.map((file, index) => (
          <SortableFilePreview
            key={file?.key ? file.key : file?.src ?? file?.tempKey}
            isSettings={isSettings}
            isEditable={isEditable}
            handleEditFile={handleEditFile}
            mediaOptionsProps={mediaOptionsProps}
            index={index}
            fileIndex={index}
            file={file}
            showBorder={showBorder}
            inPresentation={inPresentation}
            fullItemHeight={fullItemHeight}
            presentationIndustry={presentationIndustry}
            presentationLibrary={presentationLibrary}
            showDetails={showDetails}
            columns={columns}
            mobileColumns={mobileColumns}
            disabled={disabled}
            onDelete={onDelete}
            onEdit={onEdit}
            onClick={onClick ? () => onClick(index) : undefined}
            filePreviewProps={filePreviewProps}
          />
        ))}
        {onUploadClick && (
          <Grid item key={'upload'} xs={mobileColumns ? mobileColumnWidth : 12} sm={columnWidth} md={columnWidth}>
            <Button
              startIcon={<PublishIcon />}
              className={classes.addButton}
              fullWidth
              prominence={4}
              onClick={onUploadClick}
            >
              Add Media
            </Button>
          </Grid>
        )}
        {showUploadDropzone && (
          <Grid item key={'upload'} xs={12} sm={12} md={12} className={classes.grid6}>
            <Tooltip title={!canAddFiles ? 'You must upgrade your plan to add more media' : ''}>
              <div>
                <UploadDropzone onUpload={onUpload} disabled={!canAddFiles || !online} />
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    )
  }
)

function UploadDropzone(props: { onUpload?: (files: File[]) => any } & Partial<DropzoneProps>) {
  return (
    <Dropzone
      accept="image/*,video/*"
      text="Add Media"
      onDropAccepted={props.onUpload}
      style={{ height: 'initial', marginTop: 20 }}
      {...props}
    />
  )
}

function FilesGrid(props: FilesGridPropsWithInjections) {
  const {
    files,
    isSettings,
    isEditable,
    handleEditFile,
    mediaOptionsProps,
    editable,
    showUploadDropzone,
    onUploadClick,
    onUpload,
    columns = 4,
    mobileColumns,
    showBorder = true,
    showDetails = true,
    justify = 'flex-start',
    classes,
    fullItemHeight = false,
    onReorder,
    onFileClick,
    inPresentation,
    onDelete,
    onEdit,
    filePreviewProps,
    presentationIndustry,
    presentationLibrary
  } = props

  function handleSortEnd({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
    const reorderedFiles = arrayMove(files, oldIndex, newIndex)

    if (onReorder) {
      onReorder(reorderedFiles)
    }
  }

  return (
    <SortableGridContainer
      axis="xy"
      classes={classes}
      columns={columns}
      disabled={!editable}
      filePreviewProps={filePreviewProps}
      files={files}
      fullItemHeight={fullItemHeight}
      handleEditFile={handleEditFile}
      helperClass={classes.dragging}
      inPresentation={inPresentation}
      isEditable={isEditable}
      isSettings={isSettings}
      justify={justify}
      mediaOptionsProps={mediaOptionsProps}
      mobileColumns={mobileColumns}
      onClick={onFileClick}
      onDelete={onDelete}
      onEdit={onEdit}
      onSortEnd={handleSortEnd}
      onUpload={onUpload}
      onUploadClick={onUploadClick}
      presentationIndustry={presentationIndustry}
      presentationLibrary={presentationLibrary}
      pressDelay={300}
      showBorder={showBorder}
      showDetails={showDetails}
      showUploadDropzone={showUploadDropzone}
    />
  )
}

function getImageWidth(columns: number) {
  switch (columns) {
    case 1:
      return { width: 1200 }
    case 2:
      return { width: 1000 }
    case 3:
      return { width: 800 }
    default:
      return { width: 600 }
  }
}
function getColumnWidth(columns: number, classes: any) {
  switch (columns) {
    case 1:
      return { columnWidth: 12 as const, columnClass: classes.grid12 }
    case 2:
      return { columnWidth: 6 as const, columnClass: classes.grid6 }
    case 3:
      return { columnWidth: 4 as const, columnClass: classes.grid4 }
    default:
      return { columnWidth: 3 as const, columnClass: classes.grid3 }
  }
}

export default withStyles(styles)(FilesGrid)
