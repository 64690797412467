import type { PresentationSection, QuoteFile } from 'paintscout'

export function replaceSectionImages(args: { section: PresentationSection; images: QuoteFile[] }): {
  section: PresentationSection
  imageCount: number
} {
  let { section, images } = args

  if (['banner', 'image', 'logo'].includes(section.type)) {
    if (section.type === 'image' && section.fields.image?.format === 'youtube') {
      return {
        section,
        imageCount: 0
      }
    }
    return {
      section: { ...section, fields: { ...section.fields, image: { ...section.fields.image, ...images[0] } } },
      imageCount: 1
    }
  }

  if (['2-column', '3-column'].includes(section.type)) {
    let imageCount = 0
    if (section.fields.leftType === 'image') {
      section = {
        ...section,
        fields: { ...section.fields, leftImage: { ...section.fields.leftImage, ...images[imageCount] } }
      }
      imageCount++
    }
    if (section.fields.rightType === 'image') {
      section = {
        ...section,
        fields: { ...section.fields, rightImage: { ...section.fields.rightImage, ...images[imageCount] } }
      }
      imageCount++
    }
    if (section.fields.centerType === 'image') {
      section = {
        ...section,
        fields: { ...section.fields, centerImage: { ...section.fields.centerImage, ...images[imageCount] } }
      }
      imageCount++
    }
    return { section, imageCount }
  }

  if (['cards'].includes(section.type)) {
    let imageCount = 0
    section.fields.cards.forEach((card, index) => {
      if (card.file?.format === 'pdf') return
      if (card.file) {
        section.fields.cards[index].file = { ...card.file, ...images[imageCount] }
        imageCount++
      }
    })
    return { section, imageCount }
  }

  if (['gallery'].includes(section.type)) {
    let imageCount = 0
    section.fields.files.forEach((file: QuoteFile, index: number) => {
      section.fields.files[index] = { ...file, ...images[imageCount] }
      imageCount++
    })
    return { section, imageCount }
  }

  return { section, imageCount: 0 }
}
