import React, { useState } from 'react'
import type { QuoteDocument } from 'paintscout'
import type { DialogProps } from '@ui/stickybid'
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@ui/stickybid'
import type { DialogStackContextValue } from '@ui/stickybid'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Button } from '@ui/stickybid'
import { OverridableInputField, Grid } from '@ui/stickybid'
import { HtmlEditor, ConfirmationDialog } from '@ui/stickybid'
import { OverridableTextArea, useClientOptions } from '@ui/stickybid'
import type { QuoteDetails, OverridableValue } from '@paintscout/util/builder'
import { getDetails, setDetails } from '@paintscout/util/builder'
import { isHtml, makeHtml } from '@paintscout/util/util'

import Done from '@material-ui/icons/Done'

export interface EditCrewNoteDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  quote: QuoteDocument
  dialogStackContext: DialogStackContextValue
  onConfirm?: (quote: QuoteDocument) => void
  onCancel?: () => void
}

export interface EditCrewNoteDialogState {
  details?: QuoteDetails
  isDirty?: boolean
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    slate: {
      minHeight: '10em'
    },
    dialogContent: {}
  })
}

function EditCrewNoteDialog(props: EditCrewNoteDialogProps) {
  const { loading, onConfirm, onCancel, dialogStackContext, classes, ...otherProps } = props
  const { options } = useClientOptions()
  const [details, setDetailsState] = useState(getDetails({ quote: props.quote, options }))

  const [internalNotes, setInternalNotes] = useState(
    !isHtml(details?.notes?.internal) ? makeHtml(details.notes?.internal) : details?.notes?.internal
  )

  const [isDirty, setIsDirty] = useState(false)

  const leftButton = (
    <Button disabled={loading} onClick={handleCancel} variant={'text'}>
      Cancel
    </Button>
  )
  return (
    <Dialog onClose={handleCancel} fullWidth={true} maxWidth={'md'} {...otherProps}>
      <DialogTitle loading={loading}>{'Edit Work Order'}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OverridableInputField
              label="Total Hours"
              value={details.totals.hours}
              onChange={handleChangeHours}
              format={'hours'}
              resetBlank={true}
              autoSelect={true}
            />
          </Grid>
          <Grid item xs={12}>
            <HtmlEditor
              className={classes.slate}
              fullWidth={true}
              value={internalNotes}
              label={'Crew Note'}
              onChange={handleNoteChange}
            />
          </Grid>
          <Grid item xs={12}>
            <OverridableTextArea
              label={'Product Description'}
              value={details.notes.products}
              onChange={handleProductsChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button disabled={loading} onClick={handleConfirm} variant={'contained'} icon={Done}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleNoteChange(value: string) {
    setInternalNotes(value)
    setIsDirty(true)
  }

  function handleChangeHours(value: OverridableValue) {
    setDetailsState({
      ...details,
      totals: {
        ...details.totals,
        hours: value
      }
    })

    setIsDirty(true)
  }

  function handleProductsChange(value: OverridableValue) {
    setDetailsState({
      ...details,
      notes: {
        ...details.notes,
        products: value
      }
    })
  }

  function handleConfirm(ev: React.MouseEvent) {
    if (props.onConfirm) {
      const updatedQuote = setDetails({
        quote: props.quote,
        options,
        details: {
          ...details,
          notes: {
            ...details.notes,
            internal: internalNotes
          }
        }
      })
      props.onConfirm(updatedQuote)
    }
  }

  function handleCancel(ev: React.MouseEvent) {
    if (!isDirty && onCancel) {
      onCancel()
      return
    }

    dialogStackContext.openDialog(ConfirmationDialog, {
      message: 'Any unsaved changes will be lost.',
      onConfirm: (event: React.MouseEvent<HTMLElement>) => {
        if (onCancel) {
          onCancel()
        }
        dialogStackContext.dismissAllDialogs()
      },
      onCancel: (event: React.MouseEvent<HTMLElement>) => {
        dialogStackContext.dismissDialog()
        return
      }
    })
  }
}

export default withStyles(styles)(EditCrewNoteDialog)
