// this is a modified version of the Mention plugin https://github.com/ueberdosis/tiptap/blob/main/packages/extension-mention/src/mention.ts
// For the most part, you can refer to the mention documentation https://www.tiptap.dev/examples/suggestions and most of that
// should be applicable here

import React from 'react'
import type { Context } from '@paintscout/util/templater'
import type { SuggestionProps } from '@tiptap/suggestion'

import { ListItemText, Menu } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'

import { Node, mergeAttributes } from '@tiptap/core'
import { PluginKey } from 'prosemirror-state'
import Suggestion from '@tiptap/suggestion'
import { ReactRenderer } from '@tiptap/react'
import type { DOMOutputSpec } from '@tiptap/pm/model'

type Tag = {
  key: string
  label: string
  hideValue?: boolean
}

export interface MenuComponentProps extends SuggestionProps {
  items: Tag[]
  context: Context
  onClose: () => void
  onSelect: (tag: Tag) => void
}

export interface TemplateTagConfig {
  context?: Context
  tags?: Tag[]
  MenuComponent?: (props: MenuComponentProps) => JSX.Element
}

export const TemplatePluginKey = new PluginKey('template')

export default Node.create<TemplateTagConfig>({
  name: 'template',
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,
  MenuComponent: DefaultMenuComponent,

  // this stuff is boiler plate
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-id': attributes.id
          }
        }
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'span[data-template]'
      }
    ]
  },

  // older template content will have <p>{{name}}</p>,
  // we need to update it so that it is <p><span ...>{{name}}</span></p>
  onCreate() {
    const html = this.editor.getHTML()
    const BAD_TEMPLATES = /\{\{([^}]*)\}\}(?!<\/span)/g

    this.editor.commands.setContent(
      html.replace(BAD_TEMPLATES, `<span data-template="" data-id="$1">{{$1}}</span>`),
      true
    )
  },

  renderHTML({ node, HTMLAttributes }) {
    const value = this.options.context?.[node.attrs.id] ?? `{{${node.attrs.id}}}`
    const valueString = typeof value === 'function' ? value().toString() : value.toString()

    const lines = valueString.toString().split('\n')

    if (lines.length === 1) {
      return ['span', mergeAttributes({ 'data-template': '', class: 'template-tag' }, HTMLAttributes), valueString]
    } else {
      const html = ['span', mergeAttributes({ 'data-template': '', class: 'template-tag' }, HTMLAttributes)]

      lines.forEach((line, index) => {
        if (index > 0) html.push(['br'])
        html.push(['span', line])
      })

      return html as unknown as DOMOutputSpec
    }
  },

  renderText({ node }) {
    const value = this.options.context?.[node.attrs.id] ?? `{{${node.attrs.id}}}`
    if (typeof value === 'function') {
      return value().toString()
    } else {
      return value.toString()
    }
  },

  addProseMirrorPlugins() {
    const options = this.options
    return [
      Suggestion({
        editor: this.editor,
        char: '{{',
        pluginKey: TemplatePluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')

          if (overrideSpace) {
            range.to += 1
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: 'template',
                attrs: props
              },
              {
                type: 'text',
                text: ' '
              }
            ])
            .run()
        },
        allow: ({ editor, range }) => {
          return options.context && editor.can().insertContentAt(range, { type: 'template' })
        },
        items() {
          return options.tags
        },
        render() {
          const Component = options.MenuComponent
          let reactRenderer

          const getProps = (props: SuggestionProps) => ({
            ...props,
            context: options.context,
            onClose: () => reactRenderer.destroy(),
            onSelect: (tag) => props.command({ id: tag.key })
          })

          return {
            onStart: (props) => {
              reactRenderer = new ReactRenderer(
                // @ts-ignore
                Component,
                {
                  props: getProps(props),
                  // @ts-ignore
                  editor: props.editor
                }
              )

              document.body.appendChild(reactRenderer.element)
            },
            onUpdate(props) {
              reactRenderer.updateProps(getProps(props))
            },
            onExit() {
              reactRenderer.destroy()
            }
          }
        }
      })
    ]
  }
})

function DefaultMenuComponent(props: MenuComponentProps) {
  return (
    <Menu
      open={props.items.length > 0}
      anchorPosition={{
        left: props.clientRect().left,
        top: props.clientRect().top + 24
      }}
      anchorReference="anchorPosition"
      onClose={props.onClose}
      disableAutoFocusItem
    >
      {props.items.map((tag) => (
        <MenuItem
          key={tag.key}
          onMouseDown={(ev) => {
            props.onSelect(tag)
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' || ev.key === ' ' || ev.key === 'Tab') {
              props.onSelect(tag)
            }
          }}
        >
          <ListItemText primary={!tag.hideValue && props.context[tag.key]} secondary={tag.label} />
        </MenuItem>
      ))}
    </Menu>
  )
}
