/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import type { QuoteItemSection } from '../../index'
import { moveArea, moveGroup, moveSubstrate } from '../../index'
import { getEntityType, moveTo } from '../../util'
import arrayMove from 'array-move'

export function moveItem(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  section: QuoteItemSection
  previousSection: QuoteItemSection
  calculate?: boolean
  force?: boolean
}): QuoteDocument {
  const { key, options, section, previousSection, calculate, force } = args
  let quote = { ...args.quote }

  const entityType = getEntityType({ quote, key })
  if (entityType === null) {
    return {
      ...quote
    }
  }

  if (entityType === 'areas') {
    return moveArea({ quote, options, key, section, previousSection, calculate, moveSubstrates: true })
  } else if (entityType === 'substrates') {
    return moveSubstrate({ quote, key, options, section, previousSection, calculate, force })
  } else if (entityType === 'groups') {
    return moveGroup({ quote, key, section, calculate, options })
  }

  const existing = quote[entityType]
  const movedItem = moveTo(existing[key], section)

  // if moving from options to bid, we need to reorder quote.order.area to make sure they are added to the end
  if (section === 'bid' && previousSection === 'options') {
    const order = quote.order?.area // all line items, bid and optional, mixed up
    const startIndex = order.findIndex((item) => item.key === key)
    const endIndex = order.length - 1
    const reorderedOrder = arrayMove(order, startIndex, endIndex)

    quote = {
      ...quote,
      order: { ...quote.order, area: reorderedOrder, substrate: reorderedOrder }
    }
  }

  return {
    ...quote,
    [entityType]: {
      ...existing,
      [key]: movedItem
    }
  }
}
