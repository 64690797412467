import React from 'react'
import type { Theme } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core'
import CoreDropdownSelect from '@ui/core/DropdownSelect'
import type {
  DropdownSelectProps as CoreDropdownSelectProps,
  SingleSelectOption,
  GroupedSelectOption,
  DropdownSelectOption
} from '@ui/core/DropdownSelect'
import InputLabel from '../InputLabel'

export type { SingleSelectOption, GroupedSelectOption, DropdownSelectOption }

export interface DropdownSelectProps extends Omit<CoreDropdownSelectProps, 'InputLabel'> {
  classes?: CoreDropdownSelectProps['classes']
  hideDropdown?: boolean
  minimalStyle?: boolean
}

const useStyles = makeStyles<Theme, DropdownSelectProps>(
  (theme) => ({
    root: {
      ...theme.typography.body1,

      transition: 'border 0.1s, box-shadow 0.1s',
      '& .select__value-container': {
        minHeight: 32,
        '& input': {
          fontFamily: theme.typography.fontFamily
        }
      },
      '& .select__control': {
        minHeight: 39
      },
      '& .select__control, .select__control:hover': {
        borderRadius: theme.borderRadius['sm'],
        border: `1px solid ${theme.palette.grey[500]}`,

        '&:focus-within, &:active': {
          borderColor: theme.palette.primary.dark
        }
      },
      '& .select__control--is-focused, .select__control--is-focused:hover': {
        boxShadow: 'none'
      },
      '& .select__multi-value--is-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.disabled
      },
      '& .select__multi-value--is-disabled .select__multi-value__label': {
        color: theme.palette.text.disabled
      }
    },
    hasLabel: {
      '& .select__control': {
        marginTop: theme.spacing(0.25)
      }
    },

    hasSublabel: {},
    title: {
      marginBottom: theme.typography.pxToRem(8)
    },
    select: {
      borderRadius: theme.typography.pxToRem(0),
      borderColor: theme.palette.primary.main,
      '&$hasLabel': {
        marginTop: theme.typography.pxToRem(20)
      },
      '& svg': {
        width: theme.typography.pxToRem(16),
        height: theme.typography.pxToRem(16)
      }
    },
    disabled: {
      color: theme.palette.text.disabled,
      marginBottom: theme.typography.pxToRem(8)
    }
  }),
  { name: 'DropdownSelect' }
)

function DropdownSelect(props: DropdownSelectProps) {
  const classes = useStyles(props)
  const { selectStyles: _selectStyles, ...rest } = props
  const theme = useTheme()
  const openUp = props.openUp

  const selectStyles = {
    control: (styles) => {
      return {
        ...styles,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        position: 'relative'
      }
    },

    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: theme.palette.grey[200],
        border: 'none',
        boxShadow: 'none',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.size['3.5'],
        display: props.hideDropdown ? 'none' : 'inherit'
      }
    },
    option: (styles, { data, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused || isSelected ? theme.palette.grey[300] : '',
        color: theme.palette.primary.main,
        fontWeight: isSelected ? 600 : 400,
        ':active': {
          ...styles[':active'],
          backgroundColor: theme.palette.grey[400]
        },
        ..._selectStyles?.option(styles, { data, isFocused, isSelected })
      }
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        ..._selectStyles?.singleValue(styles, { data })
      }
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.borderRadius.sm,
        color: theme.palette.common.white
      }
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: theme.palette.common.white
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: theme.palette.grey[700],
        color: 'white'
      }
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.palette.primary.main,
      fontWeight: props.minimalStyle ? 700 : 200
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: theme.zIndex.tooltip,
      '.select__menu': {
        borderRadius: `0 0 ${theme.borderRadius['md']} ${theme.borderRadius['md']}`,
        padding: `${theme.spacing(1)}px 0`,
        marginTop: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          backgroundColor: 'transparent',
          top: '-20px',
          height: '20px',
          width: '10px',
          borderBottomLeftRadius: theme.borderRadius['sm'],
          boxShadow: `0 10px 0 0 ${theme.palette.grey[200]}`
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          backgroundColor: 'transparent',
          right: 0,
          top: '-20px',
          height: '20px',
          width: '10px',
          borderBottomRightRadius: theme.borderRadius['sm'],
          boxShadow: `0 10px 0 0 ${theme.palette.grey[200]}`
        },
        ...(openUp
          ? {
              bottom: '100%',
              top: 'auto',
              margin: 0,
              borderRadius: `${theme.borderRadius['md']} ${theme.borderRadius['md']} 0 0`,
              marginBottom: `46px`,
              padding: `${theme.spacing(1)}px 0`,
              '&::before': {
                content: '""',
                position: 'absolute',
                backgroundColor: 'transparent',
                bottom: '-20px',
                height: '20px',
                width: '10px',
                borderTopLeftRadius: theme.borderRadius['sm'],
                boxShadow: `0 -10px 0 0 ${theme.palette.grey[200]}`
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                right: 0,
                backgroundColor: 'transparent',
                bottom: '-20px',
                height: '20px',
                width: '10px',
                borderTopRightRadius: theme.borderRadius['sm'],
                boxShadow: `0 -10px 0 0 ${theme.palette.grey[200]}`
              }
            }
          : {})
      }
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      ...(openUp ? { transform: 'rotate(180deg)' } : ''),
      display: props.hideDropdown ? 'none' : 'inherit'
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: props.hideDropdown ? 'none' : 'inherit'
    })
  }

  return <CoreDropdownSelect InputLabel={InputLabel} selectStyles={selectStyles} {...rest} classes={classes} />
}

export default DropdownSelect
