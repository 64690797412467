import type { OptionsDocument, QuoteDocument } from 'paintscout'
import clone from 'lodash/clone'
import { calculateAfterTax } from './calculate-after-tax'
import { calculateAmountPaid } from './calculate-amount-paid'
import { calculateBalanceDue } from './calculate-balance-due'
import { lineItems } from '../line-items'
import { groups } from '../groups'
import { subTotal } from './sub-total'
import { totalAdjustment } from './total-adjustment'
import { discount, discounts } from '../discounts'
import { roundPrice } from '../util/'
import { tax } from '../tax'
import { add } from '../util/add'

export function quoteTotals(quote: QuoteDocument, options: OptionsDocument, selectedOptionalItems?: string[]) {
  if (!quote) {
    return null
  }

  const updatedQuote = clone(quote)

  let totals = {
    ...updatedQuote.totals,
    groups: {},
    additionalWork: {
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0
    },
    pending: {
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0
    },
    after_adjustment: 0,
    after_discount: 0,
    after_tax: 0,
    amount_paid: 0,
    balance_due: 0,
    groupedItems: {
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0
    },
    hours: 0,
    line_items: {
      hours: 0,
      prep: 0,
      price: 0,
      materials: 0
    },
    prep: 0,
    price: 0,
    total_discount: 0,
    total_tax: 0,
    materials: 0
  } as QuoteDocument['totals']

  updatedQuote.totals = totals

  const lineItemTotals = lineItems(updatedQuote, selectedOptionalItems)

  totals = { ...totals, ...lineItemTotals }

  updatedQuote.totals = totals

  const groupTotals = groups(updatedQuote, options)

  totals.groupedItems = {
    price: groupTotals.price,
    hours: groupTotals.hours,
    prep: groupTotals.prep,
    materials: groupTotals.materials
  }

  totals.additionalWork = add(totals.additionalWork, groupTotals.additionalWork, quote)

  updatedQuote.totals = totals

  totals.show_total = quote.totals.show_total
  totals = { ...totals, ...subTotal(updatedQuote) }

  totals = add(totals, totals.line_items, quote)

  totals = add(totals, totals.groupedItems, quote)

  updatedQuote.totals = totals

  updatedQuote.totals.price += updatedQuote.totals.materials

  totals = {
    ...totals,
    ...totalAdjustment(updatedQuote)
  }
  updatedQuote.totals = totals

  totals = {
    ...totals,
    ...discount(updatedQuote)
  }
  updatedQuote.totals = totals

  totals = {
    ...totals,
    ...discounts(updatedQuote)
  }
  updatedQuote.totals = totals

  totals.total_tax = tax(updatedQuote)

  updatedQuote.totals = totals
  totals.after_tax = calculateAfterTax(updatedQuote)

  updatedQuote.totals = totals
  totals.amount_paid = calculateAmountPaid(updatedQuote)

  updatedQuote.totals = totals
  totals.balance_due = calculateBalanceDue(totals)

  return {
    ...totals,
    price: roundPrice(totals.price),
    after_adjustment: roundPrice(totals.after_adjustment),
    after_discount: roundPrice(totals.after_discount),
    after_tax: roundPrice(totals.after_tax),
    amount_paid: roundPrice(totals.amount_paid),
    balance_due: roundPrice(totals.balance_due),
    total_discount: roundPrice(totals.total_discount),
    total_tax: roundPrice(totals.total_tax),
    materials: roundPrice(totals.materials)
  }
}
