import type { AreaSubstrate, QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'
import { parseNumber } from '../util/parse-number'
import { productVolume } from './product-volume'

export function overridePriceSubstrate(substrate: AreaSubstrate, quote: QuoteDocument) {
  const quantity = parseNumber(substrate.quantity, 1)
  const coats = parseNumber(substrate.coats)
  const product = substrate.product

  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}

  productVolume(
    product,
    'quantity',
    1,
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  const basePrice = parseNumber(substrate.rate_value)

  const calculatedPrice = substrate.default_price
  const overridePriceValue =
    substrate.override_price &&
    typeof substrate.override_price_value !== 'undefined' &&
    substrate.override_price_value !== null
      ? substrate.override_price_value
      : basePrice

  return {
    ...substrate,
    price: overridePriceValue,
    default_price: calculatedPrice
  }
}
