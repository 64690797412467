// import React from 'react'
import type { PresentationSection } from 'paintscout'

// import type { Theme } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core'
// import { ButtonSelectOption } from '@ui/stickybid'

// const useStyles = makeStyles<Theme, DividerOptionsProps>((theme: Theme) => {
// return {
// root: {}
// }
// })

interface DividerOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
}

function DividerOptions(props: DividerOptionsProps) {
  // const classes = useStyles(props)
  // const { section, handleOptionFieldChange } = props

  const options = []

  return options
}

export default DividerOptions
