import React from 'react'
import type { PresentationSection } from 'paintscout'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import { UTurnRightIcon } from '@ui/core/icons'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'
import CodeIcon from '@material-ui/icons/Code'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import { DescriptionIcon, ImageSmallIcon } from '@ui/core/icons'
import { ButtonSelectOption, ButtonOption } from '@ui/stickybid'

const useStyles = makeStyles<Theme, ThreeColumnOptionsProps>((theme: Theme) => {
  return {
    root: {}
  }
})

interface ThreeColumnOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
  handleOptionSectionChange: (section: PresentationSection) => void
}

function ThreeColumnOptions(props: ThreeColumnOptionsProps) {
  const classes = useStyles(props)
  const { section, handleOptionSectionChange, handleOptionFieldChange } = props
  const fields = section?.fields ?? ({} as any)
  const { animation, leftType, rightType, centerType, columnAlign } = fields

  const handleSwapClick = (a: string, b: string) => {
    handleOptionFieldChange('fields.swap', `${a}-${b}`)
  }

  const getIcon = (type: string) => {
    if (type === 'text') {
      return <DescriptionIcon />
    } else if (type === 'image') {
      return <ImageSmallIcon />
    } else if (type === 'logo') {
      return <AccountCircleOutlinedIcon />
    } else if (type === 'custom') {
      return <CodeIcon />
    }
  }

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon style={{ transform: 'rotate(180deg)' }} />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Column Alignment'}
          value={columnAlign}
          buttons={[
            { icon: <VerticalAlignTopIcon />, value: 'top' },
            { icon: <VerticalAlignCenterIcon />, value: 'center' },
            { icon: <VerticalAlignBottomIcon />, value: 'bottom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.columnAlign', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonOption
          label={'Swap Columns'}
          buttons={[
            { icon: getIcon(leftType) },
            { icon: <SwapHorizIcon />, onClick: () => handleSwapClick('left', 'center') },
            { icon: getIcon(centerType) },
            { icon: <SwapHorizIcon />, onClick: () => handleSwapClick('center', 'right') },
            { icon: getIcon(rightType) }
          ]}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Left Content Type'}
          value={leftType}
          buttons={[
            { icon: <AccountCircleOutlinedIcon />, value: 'logo' },
            { icon: <ImageSmallIcon />, value: 'image' },
            { icon: <DescriptionIcon />, value: 'text' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.leftType', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Center Content Type'}
          value={centerType}
          buttons={[
            { icon: <AccountCircleOutlinedIcon />, value: 'logo' },
            { icon: <ImageSmallIcon />, value: 'image' },
            { icon: <DescriptionIcon />, value: 'text' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.centerType', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Right Content Type'}
          value={rightType}
          buttons={[
            { icon: <AccountCircleOutlinedIcon />, value: 'logo' },
            { icon: <ImageSmallIcon />, value: 'image' },
            { icon: <DescriptionIcon />, value: 'text' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.rightType', value)}
        />
      ),
      tab: 'content'
    }
  ]

  return options
}

export default ThreeColumnOptions
