import React from 'react'
import type { FieldProps } from 'formik'
import type { CheckboxProps } from '../Checkbox'
import Checkbox from '../Checkbox'
import FormControlLabel from '../FormControlLabel'
import type { FormControlLabelProps } from '../FormControlLabel'

export interface FormikCheckboxProps
  extends FieldProps<CheckboxProps>,
    Omit<FormControlLabelProps, 'form' | 'classes'> {
  checkboxProps?: CheckboxProps
}

/**
 * A Checkbox configured to use with Formik. It is wrapped in a FormControlLabel, so any props
 * are passed to that. If you want to pass props to the checkbox, you can use the `checkboxProps` prop
 */
const FormikCheckbox = ({ field, form, ...props }: FormikCheckboxProps) => {
  const { value, ...checkboxField } = field
  const { checkboxProps, ...labelProps } = props
  return (
    <FormControlLabel
      {...labelProps}
      control={<Checkbox checked={!!field.value} {...checkboxField} {...checkboxProps} />}
    />
  )
}

export default FormikCheckbox
