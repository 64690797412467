import { useState } from 'react'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { compressFile, uploadFile } from '../../util'
import { useCloudinary } from '../../../CloudinaryProvider'

import { useUser } from '../../../UserProvider'
import type { FileDropAreaProps } from '../../FileDropArea'
import { useSnackbar } from 'notistack'

const PERMITTED_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  'image/webp',
  'application/pdf',
  'video/mp4'
]

export default function useFileDropArea({
  onUpload,
  onUploadStart,
  onPartialUpload,
  onPartialUploadError,
  docId,
  ...otherProps
}: Omit<FileDropAreaProps, 'children'>) {
  const cloudinaryContext = useCloudinary()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const [loading, setLoading] = useState(false)
  const onDrop = useCallback(
    async (uploadFiles: File[]) => {
      if (uploadFiles.find((file) => !PERMITTED_TYPES.includes(file.type))) {
        enqueueSnackbar('Unable to upload file type.', { variant: 'error' })
        return false
      }
      if (onUploadStart) {
        onUploadStart()
      }
      setLoading(true)

      const { maxHeight, maxWidth, quality } = otherProps

      const allUploadRes = []
      for (let i = 0; i < uploadFiles.length; i++) {
        try {
          const file = uploadFiles[i]
          const compressedFile = await compressFile({
            file,
            ...(maxHeight && { maxHeight }),
            ...(maxWidth && { maxWidth }),
            ...(quality && { quality })
          })

          const uploadedFile = await uploadFile({
            file: compressedFile,
            cloudName: cloudinaryContext.cloudName,
            uploadPreset: cloudinaryContext.uploadPreset,
            companyId: user?.app_metadata?.companyId,
            docId
          })

          if (onPartialUpload) {
            onPartialUpload({ totalFiles: uploadFiles.length, index: i })
          }

          allUploadRes.push(uploadedFile)
        } catch (err) {
          if (onPartialUploadError) {
            onPartialUploadError({ totalFiles: uploadFiles.length, index: i })
          }
          allUploadRes.push(err)
        }
      }

      setLoading(false)

      onUpload(allUploadRes)
    },
    [onUpload, onUploadStart, onPartialUpload, onPartialUploadError]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: PERMITTED_TYPES
  })

  return { getRootProps, getInputProps, isDragActive, open, loading }
}
