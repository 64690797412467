import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Typography } from '../'
import { StickyBidLettermarkIcon } from '@ui/core'
import { getFeature } from '@paintscout/util/builder'
import { useClientOptions } from '../'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    '@media print': {
      display: 'none'
    },
    textAlign: 'center',
    padding: theme.spacing(2, 2, 3),
    '& a': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.palette.grey[600],
      transition: '0.5s all',
      lineHeight: 0,
      '& p': {
        color: theme.palette.grey[600],
        transition: '0.5s all',
        fontWeight: 500
      },
      '& svg': {
        width: 79,
        height: 'auto',
        display: 'inline-block',
        fill: theme.palette.grey[600],
        stroke: theme.palette.grey[600],
        transition: '0.5s all',
        marginLeft: 3,
        marginTop: 3,
        '& svg': {
          '& line': {
            transition: '0.5s all',
            stroke: `${theme.palette.grey[600]} !important`
          }
        }
      }
    },
    '& a:hover': {
      '& p': {
        color: 'black'
      },
      '& svg': {
        fill: '#000000',
        '& svg': {
          '& line': {
            stroke: '#30b6a0 !important'
          }
        }
      }
    }
  }
}))

function Footer() {
  const classes = useStyles({})
  const { options } = useClientOptions()
  const hasWhiteLabelling = getFeature({ options, path: 'whiteLabelling.enabled' })

  if (hasWhiteLabelling) {
    return <div style={{ height: 28 }}></div>
  } else
    return (
      <footer className={classes.footer}>
        <a href="https://www.stickybid.com" target={'_blank'} rel={'noreferrer noopener'}>
          <Typography variant={'body2'}>Powered by</Typography>
          <StickyBidLettermarkIcon />
        </a>
      </footer>
    )
}

export default Footer
