import React from 'react'
import Alert from '../Alert'
import { makeStyles } from '@material-ui/core'
import type { EmergencyMessage } from '@paintscout/api/src'
import { EmergencyMessageSeverity } from '@paintscout/api/src'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export interface EmergencyBannerProps {
  severity: EmergencyMessage['severity']
  message: EmergencyMessage['message']
  onClose?: () => any
}

const useStyles = makeStyles({
  root: {},
  content: {
    maxWidth: '97%',
    whiteSpace: 'pre-wrap'
  },
  close: {
    position: 'absolute',
    top: '0.3rem',
    right: '0.5rem'
  }
})

export default function EmergencyBanner({ severity, message, onClose }: EmergencyBannerProps) {
  const classes = useStyles()

  function getSeverity() {
    switch (severity) {
      case EmergencyMessageSeverity.Low:
        return 'warning'
      case EmergencyMessageSeverity.Medium:
        return 'error'
      case EmergencyMessageSeverity.High:
        return 'error'
    }
  }

  return (
    <Alert
      classes={classes}
      severity={getSeverity()}
      content={
        <>
          {message}
          <div className={classes.close}>
            <IconButton data-testid="close-emergency" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </>
      }
    />
  )
}
