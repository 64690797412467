import type { QuoteDocument, QuoteFile } from 'paintscout'

export function updateGalleryFiles(args: {
  quote: QuoteDocument
  files: QuoteFile[]
  append?: boolean
}): QuoteDocument {
  const { quote, files, append } = args

  const newFileKeys = files.map((file) => file.key)
  const galleryFiles = append ? [...(quote.galleryFiles ?? []), ...newFileKeys] : newFileKeys

  return {
    ...quote,
    files: {
      ...(quote.files ?? {}),
      ...files.reduce((acc, file) => {
        return {
          ...acc,
          [file.key]: file
        }
      }, {})
    },
    galleryFiles
  }
}
