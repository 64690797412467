import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import type { Theme } from '@material-ui/core'
import { makeStyles, FormControl, RadioGroup } from '@material-ui/core'
import type { RadioGroupProps } from '@material-ui/core'
import Typography from '../Typography'
import FormControlLabel from '../FormControlLabel'
import Radio from '../Radio'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, RadioButtonsProps>((theme) => ({
  root: {},
  label: {},
  selectedLabel: {
    color: theme.palette.primary.dark
  },
  sublabel: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '0.8em'
  },
  selectedSublabel: {
    color: theme.palette.grey[700]
  },
  radioGroup: {
    marginTop: theme.spacing(2)
  },
  radio: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`
  },
  customRadio: {
    textDecoration: 'underline'
  }
}))

export interface RadioButtonsProps {
  classes?: RadioGroupProps['classes'] & StyleClasses<typeof useStyles>
  options: Array<{ label: string; name: string; sublabel?: string; key?: string }>
  label?: string
  sublabel?: string
  value?: string
  name?: string
  disabled?: boolean
  color?: 'primary' | 'secondary' | 'default'
  row?: boolean
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end'
  onChange: (value: string) => void
  customSelected?: boolean
  onCustomSelect?: () => void
}

function RadioButtons(props: RadioButtonsProps) {
  const classes = useStyles(props)
  const {
    labelPlacement,
    row,
    options,
    label,
    sublabel,
    name,
    value,
    disabled,
    color,
    onChange,
    customSelected,
    onCustomSelect
  } = props

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value)
  }

  return (
    <FormControl classes={{ root: classes.root }}>
      {label && <Typography variant={'overline'}>{label}</Typography>}
      {sublabel && <Typography variant={'subtitle2'}>{sublabel}</Typography>}
      <RadioGroup
        row={row}
        aria-label={label}
        name={name}
        value={value}
        onChange={handleChange}
        classes={{ root: classes.radioGroup }}
      >
        {options.map((option, i) => {
          const selectedOption = option.name === value
          const isEqual = option.key === value
          return (
            <FormControlLabel
              key={i}
              value={option.name}
              disabled={disabled}
              labelPlacement={labelPlacement}
              control={<Radio className={classes.radio} color={color ?? 'primary'} />}
              label={
                <>
                  <Typography
                    variant={'subtitle2'}
                    className={classnames({
                      [classes.selectedLabel]: selectedOption,
                      [classes.customRadio]: isEqual && customSelected
                    })}
                    onClick={!isEqual ? null : onCustomSelect}
                  >
                    {option.label}
                  </Typography>
                  {option.sublabel && (
                    <Typography
                      variant={'subtitle2'}
                      className={classnames({
                        [classes.sublabel]: true,
                        [classes.selectedSublabel]: selectedOption
                      })}
                    >
                      {option.sublabel}
                    </Typography>
                  )}
                </>
              }
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButtons
