/**
 * @module builder
 */
import type { OptionsDocument, PresentationSectionOption, QuoteFile } from 'paintscout'
import { getObjectLabels } from '../../../util/get-object-labels'
import sortBy from 'lodash/sortBy'
import { uuid } from '../../../'
import identity from 'lodash/identity'

const defaultSrc = 'https://res.cloudinary.com/stickybid/image/upload/v1675117938/_static/placeholders/grey.png'
const defaultPublicId = '_static/placeholders/grey'

export const sbPlaceholderImage: QuoteFile = {
  // key: uuid(),
  src: defaultSrc,
  format: 'png',
  cloudinaryPublicId: defaultPublicId,
  width: 1920,
  height: 1280,
  type: 'upload',
  visibility: 'visible'
}

export function getPresentationSectionOptions(args: {
  options?: OptionsDocument
  type?: string
}): PresentationSectionOption[] {
  const { type, options } = args
  const objectLabels = getObjectLabels({ options })
  const isPS = process.env.PRODUCT === 'ps'

  let sectionOptions = [
    {
      type: 'about-estimator',
      label: 'About Your Estimator',
      title: 'About Your Estimator',
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: true
      }
    },

    {
      type: 'cover',
      label: 'Cover Page',
      title: 'Cover',
      background: { noPadding: false, color: '#fff' },
      fields: {
        subtitle: 'Estimate\nDescription',
        alignment: 'justify',
        verticalAlign: 'middle',
        backgroundOpacity: 1,
        overlayImage: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        }
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },

    {
      stickybidOnly: true,
      type: 'button',
      label: 'Button',
      title: 'Button',
      background: { noPadding: false, color: '' },
      fields: {
        animation: 'none',
        label: 'New Button',
        href: '',
        color: '',
        horizontalAlignInContainer: 'center'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },

    {
      type: 'custom',
      label: 'Custom HTML/CSS',
      title: 'New Custom Section',
      fields: {
        html: '<blockquote class="blockquote">Pellentesque habitant morbi tristique senectus<br />et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, <br />feugiat vitae, ultricies eget, tempor sit amet, ante.</blockquote><pre><code>Morbi in\nsem quis\ndui placerat ornare.\nPellentesque odio nisi, \neuismod in</code></pre>',
        css: '.blockquote {border-left: 5px solid #ddd; margin-left: 0; padding-left: 20px;margin-bottom:20px;} pre {background: #ddd; padding: 20px; color: #000; display: inline-block; border-radius: 3px;}'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },

    {
      stickybidOnly: true,
      type: 'social-links',
      label: 'Social Links',
      background: { noPadding: false, color: '' },
      title: 'Social Links',
      fields: {
        links: [
          {
            label: 'Facebook',
            href: 'https://example.com'
          },
          {
            label: 'Instagram',
            href: 'https://example.com'
          },
          {
            label: 'LinkedIn',
            href: ''
          },
          {
            label: 'Pinterest',
            href: ''
          },
          {
            label: 'Twitter',
            href: ''
          },
          {
            label: 'YouTube',
            href: ''
          },
          {
            label: 'Custom',
            href: ''
          }
        ],
        fontSize: '3rem',
        alignment: 'center'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },

    {
      type: 'divider',
      label: 'Divider',
      title: 'Divider',
      background: { noPadding: process.env.PRODUCT === 'ps' ? false : true, color: isPS ? '#fff' : '' },
      fields: {
        marginTop: 40,
        marginBottom: 40,
        marginTopResponsive: 'md',
        marginBottomResponsive: 'md'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'documents',
      label: 'Documents',
      title: 'Important Documents',
      background: { noPadding: false, color: '#fff' },
      fields: {
        rows: [
          {
            title: 'Document Title',
            content:
              '<p>Describe the details and/or the importance of the associated document in the box provided here.</p>',
            image: {
              src: '',
              format: 'pdf',
              cloudinaryPublicId: '',
              width: 612,
              height: 792,
              type: 'upload',
              visibility: 'visible'
            }
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'image',
      label: process.env.PRODUCT === 'ps' ? 'Image' : 'Media',
      title: '',
      background: { noPadding: false, color: isPS ? '#fff' : '' },
      fields: {
        image: null,
        placeholder: ''
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      stickybidOnly: true,
      type: 'video',
      label: 'Video',
      title: '',
      background: { noPadding: false, color: '' },
      fields: {
        video: null
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      stickybidOnly: true,
      type: 'cards',
      label: 'Cards',
      title: '',
      background: { noPadding: false, color: '' },
      fields: {
        animation: 'none',
        columns: 3,
        cards: [
          {
            file: { ...sbPlaceholderImage, presentationOptions: { aspectRatio: '3/2' } },
            content: `<h3><span>Quisque aliquet</span></h3><p></p><p><span>Quisque et vehicula velit. Nam blandit sem in nisi pretium, ut bibendum ipsum ultrices.</span></p>`,
            key: uuid()
          },
          {
            file: { ...sbPlaceholderImage, presentationOptions: { aspectRatio: '3/2' } },
            content: `<h3><span>Morbi sollicitudin</span></h3><p></p><p><span>Nibh eget libero cursus, quis fermentum neque laoreet. Vestibulum et massa volutpat, rhoncus ipsum et, placerat est.</span></p>`,
            key: uuid()
          },
          {
            file: { ...sbPlaceholderImage, presentationOptions: { aspectRatio: '3/2' } },
            content: `<h3><span>Mauris efficitur massa ac</span></h3><p></p><p><span>Vivamus neque magna, elementum a sollicitudin eget, dictum at enim.</span></p>`,
            key: uuid()
          }
        ],
        useCarousel: false
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'gallery',
      label: 'Gallery',
      title: '',
      background: { noPadding: false, color: isPS ? '#fff' : '' },
      fields: {
        columns: 3,
        showBorder: false,
        ...(process.env.PRODUCT === 'sb' && {
          files: [
            { ...sbPlaceholderImage, presentationOptions: { aspectRatio: '3/2' } },
            { ...sbPlaceholderImage, presentationOptions: { aspectRatio: '3/2' } },
            { ...sbPlaceholderImage, presentationOptions: { aspectRatio: '3/2' } }
          ]
        })
      },
      active: true,
      advanced: {
        useForNav: isPS,
        allowCustom: false
      }
    },
    {
      type: 'awards',
      label: 'Images Row',
      title: 'Images Row',
      background: { noPadding: false, color: '#fff' },
      fields: {
        alignment: 'middle',
        rows: [
          {
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/award-placeholder.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/award-placeholder',
              width: 272,
              height: 326,
              type: 'upload',
              visibility: 'visible'
            }
          },
          {
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/award-placeholder.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/award-placeholder',
              width: 272,
              height: 326,
              type: 'upload',
              visibility: 'visible'
            }
          },
          {
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/award-placeholder.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/award-placeholder',
              width: 272,
              height: 326,
              type: 'upload',
              visibility: 'visible'
            }
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'introduction',
      label: 'Introduction',
      background: { noPadding: false, color: '#fff' },
      title: '',
      fields: {
        image: null as any,
        content: ''
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: true
      }
    },
    {
      type: 'mission',
      label: 'Centered Text',
      title: 'Centered Text',
      background: { noPadding: false, color: '#fff' },
      fields: {
        content:
          '<p>Nulla ornare nulla luctus magna elementum, et ullamcorper odio feugiat.</p><p><strong>Maecenas efficitur quis massa ac pretium.</strong></p>',
        image: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        }
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },

    // {
    //   type: 'our-guarantee',
    //   label: 'Our Guarantee',
    //   title: 'Our Guarantee',
    //   background: { noPadding: false, color: '#fff' },
    //   fields: {
    //     verticalAlign: 'top',
    //     content:
    //       '<p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>. Nullam dapibus neque id libero gravida accumsan. Praesent quis tellus consectetur, pellentesque nisi ut, lobortis mi.</p><p></p><p>Cras porta, est ac lobortis cursus, eros nibh ultrices mi, ac ultricies mi mauris quis est. Phasellus commodo diam in ante vulputate sagittis. <em>Morbi neque dui</em>, sagittis nec purus eu, aliquam congue est.</p>',
    //     image: {
    //       src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
    //       format: 'png',
    //       cloudinaryPublicId: 'placeholders/image',
    //       width: 100,
    //       height: 100,
    //       type: 'upload',
    //       visibility: 'visible'
    //     }
    //   },
    //   advanced: {
    //     useForNav: false,
    //     allowCustom: false,
    //     active: true
    //   }
    // },
    // {
    //   type: 'our-team',
    //   label: 'Our Team',
    //   title: 'Our Team',
    //   background: { noPadding: false, color: '#fff' },
    //   fields: {
    //     verticalAlign: 'top',
    //     centerContent:
    //       '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis <strong>risus sem</strong> in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. </p>',
    //     rightContent:
    //       '<p>Nulla ornare nulla luctus magna elementum, et ullamcorper odio feugiat. Maecenas efficitur quis massa ac pretium.</p><p></p><p>Quisque eleifend maximus feugiat.</p>'
    //   },
    //   advanced: {
    //     useForNav: false,
    //     allowCustom: false,
    //     active: true
    //   }
    // },
    {
      type: 'pictures',
      label: `${objectLabels.quote.value} Pictures`,
      title: `${objectLabels.quote.value} Pictures`,

      fields: {},
      background: {
        style: 'contained',
        color: '#fff'
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'quote',
      label: objectLabels.quote.value,
      title: objectLabels.quote.value,
      fields: {
        // the below are for the button component that triggers the cart
        asCartLink: false,
        label: `View ${objectLabels.quote.value}`,
        color: '',
        buttonSize: 'sm',
        horizontalAlignInContainer: 'center'
      },
      background: {
        style: 'contained',
        color: isPS ? '#fff' : ''
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'services',
      label: 'Two Column Rows',
      title: 'Two Column Rows',
      background: { noPadding: false, color: '#fff' },
      fields: {
        rows: [
          {
            title: 'Title',
            content:
              '<p>Fusce eget ipsum felis. Aenean elit leo, interdum at aliquam ac, blandit quis tellus. Sed accumsan urna in pretium dignissim. Suspendisse imperdiet libero vel purus mattis auctor. Morbi imperdiet enim ac nulla pulvinar, vitae tempus eros fermentum. Duis id neque diam.</p>',
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/image',
              width: 100,
              height: 100,
              type: 'upload',
              visibility: 'visible'
            }
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'terms',
      label: 'Terms',
      title: 'Terms',
      useForNav: true,
      allowCustom: false,
      active: true,
      fields: {},
      background: {
        style: 'contained',
        color: isPS ? '#fff' : ''
      },
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    ...(isPS
      ? [
          {
            type: 'testimonials',
            label: 'Testimonials',
            title: 'Testimonials',
            background: { noPadding: false, color: '#fff' },
            fields: {
              rows: [
                {
                  content: '<p>Nullam eget magna non sapien fermentum maximus id in erat.</p>',
                  credit: 'Bob Johnson'
                },
                {
                  content: '<p>Sed gravida magna sed sapien efficitur rhoncus.</p>',
                  credit: 'Steve Williams'
                },
                {
                  content: '<p>Nulla euismod tempor vehicula.</p>',
                  credit: 'Gary Jones'
                }
              ]
            },
            active: true,
            advanced: {
              useForNav: false,
              allowCustom: false
            }
          }
        ]
      : [
          {
            stickybidOnly: true,
            type: 'testimonials',
            label: 'Testimonials',
            title: '',
            background: { noPadding: false, color: '', useTheme: true },
            fields: {
              animation: 'none',
              columns: 3,
              cards: [
                {
                  content:
                    '<p>Add testimonials from your clients that underscore the quality your company brings. Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions. The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
                  credit: '<h3>Jessie, company inc.</h3>',
                  key: uuid()
                },
                {
                  content:
                    '<p>The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
                  credit: '<h3>Carey B.</h3>',
                  key: uuid()
                },
                {
                  content:
                    '<p>Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions. The same study shows that customers are <strong>18%</strong> more likely to buy from a website with customer reviews over on that does not!</p>',
                  credit: '<h3>Taylor C.</h3>',
                  key: uuid()
                }
              ],
              sectionHeight: 'md'
            },
            active: true,
            advanced: {
              useForNav: false,
              allowCustom: false
            }
          }
        ]),
    {
      stickybidOnly: true,
      type: 'text',
      label: 'Text',
      title: 'New Text',
      background: { noPadding: false, color: '' },
      fields: {
        content: '<h2>Etiam ante sapien, pharetra blandit commodo ut, malesuada vel augue.</h2>',
        color: ''
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      stickybidOnly: true,
      type: 'banner',
      label: 'Banner',
      title: 'Banner',
      background: { noPadding: false, color: '' },
      fields: {
        animation: 'none',
        content: `<h1 style="text-align: center">Lorem Ipsum</h1><p style="text-align: center"></p><h2 style="text-align: center">Fusce ut odio suscipit dolor elementum ultrices at vel quam</h2>`,
        fullWidthImage: false,
        image: sbPlaceholderImage,
        layout: 'poster',
        layoutOptions: {
          poster: {
            color: '#ffffff'
          },
          collage: {
            color: '',
            textBackgroundColor: '',
            useGlassEffect: false,
            verticalAlign: 'start',
            imagePosition: 'left'
          }
        }
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },

    {
      stickybidOnly: true,
      type: 'logo',
      label: 'Logo',
      title: 'Logo',
      background: { noPadding: false, color: '' },
      fields: {
        animation: 'none',
        color: '#ffffff',
        content: '',
        contentBackgroundColor: '',
        contentColor: '',
        contentTop: '',
        image: sbPlaceholderImage,
        logoAlignment: 'center',
        logoSize: 'md',
        useContentBackground: false
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },

    {
      stickybidOnly: true,
      type: 'spacer',
      label: 'Spacer',
      title: 'Spacer',
      background: { noPadding: true, color: '' },
      fields: {
        heightResponsive: 'md'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },

    {
      type: '2-column',
      label: '2 Column',
      title: 'My New Section',
      background: { noPadding: false, color: isPS ? '#fff' : '', useTheme: true },
      fields: {
        animation: 'none',
        columnAlign: 'center',
        fullWidthImage: true,
        verticalAlign: process.env.PRODUCT === 'ps' ? 'middle' : 'center',
        leftType: 'image',
        leftOptions: { color: '', backgroundColor: '' },
        leftImage:
          process.env.PRODUCT === 'ps'
            ? {
                src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
                format: 'png',
                cloudinaryPublicId: 'placeholders/image',
                width: 100,
                height: 100,
                type: 'upload',
                visibility: 'visible'
              }
            : null,
        leftWidth: 6,
        rightType: 'text',
        rightOptions: { color: '', backgroundColor: '' },
        rightText:
          '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis risus sem in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. Nulla euismod tempor vehicula.</p><p></p><p>Phasellus efficitur turpis tempus, viverra velit ut, fringilla magna.</p>',
        rightWidth: 6
      },
      active: true,
      advanced: {
        useForNav: isPS,
        allowCustom: false
      }
    },

    {
      type: 'text',
      label: 'Text Block',
      title: 'New Text Section',
      background: { noPadding: false, color: isPS ? '#fff' : '' },
      fields: {
        content:
          '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis risus sem in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. Nulla euismod tempor vehicula.</p><p></p><p>Phasellus efficitur turpis tempus, viverra velit ut, fringilla magna.</p>'
      },
      active: true,
      advanced: {
        useForNav: isPS,
        allowCustom: false
      }
    },
    {
      type: '3-column',
      label: '3 Column',
      title: 'My New Section',
      background: { noPadding: false, color: isPS ? '#fff' : '' },
      fields: {
        animation: 'none',
        columnAlign: 'center',
        verticalAlign: process.env.PRODUCT === 'ps' ? 'middle' : 'center',
        leftType: 'image',
        leftOptions: { color: '', backgroundColor: '' },
        leftImage:
          process.env.PRODUCT === 'ps'
            ? {
                src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
                format: 'png',
                cloudinaryPublicId: 'placeholders/image',
                width: 100,
                height: 100,
                type: 'upload',
                visibility: 'visible'
              }
            : null,
        centerType: 'text',
        centerOptions: { color: '', backgroundColor: '' },
        centerText:
          '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis risus sem in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. Nulla euismod tempor vehicula.</p>',
        rightType: 'image',
        rightOptions: { color: '', backgroundColor: '' },
        rightImage:
          process.env.PRODUCT === 'ps'
            ? {
                src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
                format: 'png',
                cloudinaryPublicId: 'placeholders/image',
                width: 100,
                height: 100,
                type: 'upload',
                visibility: 'visible'
              }
            : null
      },
      active: true,
      advanced: {
        useForNav: isPS,
        allowCustom: false
      }
    },

    {
      type: 'work-order',
      label: objectLabels.workOrder.value,
      title: objectLabels.workOrder.value,

      fields: {},
      background: {
        style: 'contained',
        color: '#fff'
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    options.options.features.showDataRowsSection && {
      type: 'data-rows',
      label: 'Data Rows',
      title: 'Data Rows',
      fields: {
        description:
          '<p>Cras molestie, magna ac imperdiet tincidunt, diam elit porttitor leo, in luctus nisl nulla a odio.</p>',
        rows: []
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: true
      },
      background: {
        style: 'contained'
      }
    }
  ]

  sectionOptions = sectionOptions.filter(identity)
  sectionOptions = sortBy(sectionOptions, ['label'])

  if (type) {
    return sectionOptions.filter((option) => option.type === type)
  }

  if (process.env.PRODUCT === 'ps') {
    return sectionOptions.filter((option) => option.stickybidOnly !== true)
  }

  return sectionOptions
}
