import type { OptionsDocument, QuoteDocument } from 'paintscout'

import get from 'lodash/get'

export function getQuoteType(args: { options?: OptionsDocument; quoteType?: string; quote?: QuoteDocument }): string {
  const { options, quoteType: quoteTypeArg, quote } = args

  if (quoteTypeArg) {
    return quoteTypeArg
  } else if (quote) {
    return get(quote, 'int_ext.value')
  } else {
    return null
  }
}
