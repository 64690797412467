import { gql } from 'apollo-boost'
import type { Auth0User } from 'paintscout'

export const SEARCH_USERS = gql`
  query searchUsers($query: String!, $bookmark: String, $limit: Int) {
    searchUsers(query: $query, bookmark: $bookmark, limit: $limit) {
      bookmark
      total_rows
      rows {
        user_metadata {
          phoneNumber
          lastName
          firstName
        }
        user_id
        name
        email
        last_login
        app_metadata {
          active
          companyId
          roles
        }
      }
    }
  }
`

export interface SearchUsersResponse {
  searchUsers: {
    total_rows: number
    rows: Auth0User[]
    bookmark?: string
  }
}
