import React, { useEffect, useState } from 'react'
import type { FieldProps } from 'formik'
import type { RTEProps as HtmlEditorProps } from '../RTE'
import HtmlEditor from '../RTE'
import { useDebounce } from 'shared/hooks/useDebounce'

export interface FormikHtmlEditorProps extends FieldProps<any>, Omit<HtmlEditorProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikHtmlEditor = ({ form, ...props }: FormikHtmlEditorProps) => {
  // onBlur causes weird issues with Formik ... so Formik's handleBlur does not get called for this component
  const { onBlur, ...field } = props.field
  const value = field.value

  const { setFieldValue } = form
  const [localValue, setLocalValue] = useState(field?.value ?? '')

  useEffect(() => {
    value && setLocalValue(value)
  }, [value])

  const debouncedValue = useDebounce(localValue, 200)

  useEffect(() => {
    setFieldValue(field.name, localValue)
  }, [debouncedValue])

  return <HtmlEditor {...field} {...props} value={localValue} onChange={(value) => setLocalValue(value)} />
}

export default FormikHtmlEditor
