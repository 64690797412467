/**
 * @module builder
 */
import uniq from 'lodash/uniq'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getArea, getAreaDescription, isSplitArea } from '../../areas'
import { getFileKeysForItem } from '../../files'
import type { QuoteItemSection, QuoteItemView } from '../../index'
import { getItem } from '../../items/get-items'
import { getLineItem } from '../../line-items'
import { getSubstrate } from '../../substrates'
import { makeHtml } from '../../../../util'
import { getGroup } from '../get-group/get-group'

export function getGroupDetails(args: {
  quote: QuoteDocument
  options: OptionsDocument
  keys: string[]
  section?: QuoteItemSection
  hideDescriptionKeys?: string[]
}): {
  label: string
  description: string
  price: number
  files: string[]
  quoteType: string
  view: QuoteItemView
  hours: number
  section: QuoteItemSection
} {
  const { quote, options, keys, section, hideDescriptionKeys } = args

  const fullLines: string[] = []
  const labelComponents: string[] = []
  let price = 0
  let hours = 0
  const files: string[] = []
  let view // NOTE: LineItem only groups are left with no view
  let derivedSection: QuoteItemSection

  const quoteTypes: any[] = []

  keys.forEach((key) => {
    const item = getItem({ quote, options, key })
    if (!item) {
      return
    }
    derivedSection = item.section
    if (item.type === 'lineItem') {
      const lineItem = getLineItem({ quote, key })
      const lineItemPrice =
        lineItem.totals && lineItem.totals.afterMaterials ? lineItem.totals.afterMaterials : lineItem.price
      price += lineItemPrice
      hours += lineItem.hours
      const label = makeHtml(lineItem.label, { wrap: ['p', 'strong'] })
      const description = makeHtml(lineItem.description)

      if (!hideDescriptionKeys?.includes(key)) {
        fullLines.push(`${label}${description}`)
      }
    } else if (item.type === 'area') {
      const split = section && isSplitArea({ quote, options, areaKey: key, section: derivedSection, showGroups: true })
      const area = getArea({ quote, options, key, trimBySubstrateSection: split ? section : undefined })
      const areaDescription = getAreaDescription({
        quote,
        options,
        areaKey: key,
        plainText: false,
        section,
        inGroup: true
      }) ?? {
        useCustom: false,
        default: ''
      }

      const label = makeHtml(area.label, { wrap: ['p', 'strong'] })
      const description = makeHtml(areaDescription.useCustom ? areaDescription.custom : areaDescription.default)

      if (!hideDescriptionKeys?.includes(key)) {
        fullLines.push(`${label}${description}`)
      }
      price += area.totals.price.useCustom ? area.totals.price.custom : area.totals.price.default
      price += area.totals.materials.useCustom ? area.totals.materials.custom : area.totals.materials.default

      hours += area.totals.hours.useCustom ? area.totals.hours.custom : area.totals.hours.default
      hours += area.totals.prep.useCustom ? area.totals.prep.custom : area.totals.prep.default

      view = 'area'
      quoteTypes.push(area.quoteType)
    } else if (item.type === 'substrate') {
      const substrate = getSubstrate({ quote, key, showHiddenTotals: true, section })
      let clientLabel: any = substrate.label
      if (substrate.clientLabel) {
        clientLabel = substrate.clientLabel.useCustom ? substrate.clientLabel.custom : substrate.clientLabel.default
      }

      const substrateDescription = substrate.substrateDescription

      const label = makeHtml(clientLabel, { wrap: ['p', 'strong'] })

      const description = makeHtml(
        substrateDescription.useCustom ? substrateDescription.custom : substrateDescription.default
      )

      if (!hideDescriptionKeys?.includes(key)) {
        fullLines.push(`${label}${description}`)
      }

      price += substrate.totals.price.useCustom
        ? (substrate.totals.price.custom as number)
        : (substrate.totals.price.default as number)

      hours += substrate.totals.hours.useCustom
        ? (substrate.totals.hours.custom as number)
        : (substrate.totals.hours.default as number)
      hours += substrate.totals.prep.useCustom
        ? (substrate.totals.prep.custom as number)
        : (substrate.totals.prep.default as number)

      view = 'substrate'
    } else if (item.type === 'group') {
      const group = getGroup({ quote, key })
      const groupDescription = group.description

      const label = makeHtml(group.label, { wrap: ['p', 'strong'] })
      const description = makeHtml(
        groupDescription.useCustom ? groupDescription.custom.toString() : groupDescription.default.toString()
      )

      if (!hideDescriptionKeys?.includes(key)) {
        fullLines.push(`${label}${description}`)
      }
      hours += getGroupDetails({ quote, options, keys: group.children }).hours
      price += group.price.useCustom ? (group.price.custom as number) : (group.price.default as number)
    }

    files.push(...getFileKeysForItem({ item }))
  })

  const label = uniq(labelComponents).join(', ')
  const description = uniq(fullLines).join('')

  const uniqueQuoteTypes = uniq(quoteTypes)
  const quoteType = uniqueQuoteTypes.length === 1 ? uniqueQuoteTypes[0] : null

  return { label, description, price, files, quoteType, view, hours, section: section ?? derivedSection ?? 'bid' }
}
