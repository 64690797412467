/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'

// Clean Options Document of PK and SK fields
export function cleanOptions(args: { options: OptionsDocument }): OptionsDocument {
  const { options: dirtyOptions } = args

  const cleanOptions = {
    ...dirtyOptions
  }

  // holdover from dynamodb
  // @ts-ignore
  delete cleanOptions.PK
  // @ts-ignore
  delete cleanOptions.SK

  return cleanOptions
}
