import React, { useState } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, useClientOptions } from '@ui/stickybid'
import { useQuote } from '../../../context'
import type { PresentationOption, PresentationPage } from 'paintscout'
import Scrollspy from 'react-scrollspy'
import { getQuotePresentation, filterActivePresentationPages } from '@paintscout/util/builder'
import { usePresentationNav } from '../PresentationNavContext'
import find from 'lodash/find'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, PresentationNavTextProps>(
  (theme: Theme) => {
    return {
      textRoot: {
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: 'none'
        },
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        '& button': {
          marginTop: theme.spacing(1)
        }
      },
      buttonWrapper: {
        cursor: 'pointer',
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        paddingLeft: 0,
        lineHeight: 1,
        wordWrap: 'break-word',
        '&:first-child button': {
          marginTop: 0
        }
      },
      title: {
        '& h3': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.3em',
          marginBottom: theme.spacing(1),

          paddingBottom: theme.spacing(1),
          // borderBottom: `1px solid ${theme.palette.grey[500]}`
          '& svg': {
            fontSize: '1em',
            marginRight: theme.spacing(0.5)
          }
        }
      },
      button: {
        border: '1px solid transparent',
        background: 'transparent',
        color: theme.palette.grey[700],
        // fontWeight: 'bold',
        // fontSize: '18px',
        whiteSpace: 'normal',
        '$:click': {
          border: '1px solid transparent'
        },
        '&:hover': {
          color: '#000000',
          borderRadius: 0
        }
      },
      buttonSpan: {
        whiteSpace: 'normal',
        textAlign: 'left',
        hyphens: 'auto'
      },
      currentPage: {
        border: '1px solid transparent',
        background: 'transparent',
        color: '#000000',
        textDecoration: 'underline',
        borderRadius: 0
      },
      currentSection: {
        background: 'transparent',
        color: '#000000',
        borderRadius: 0
      },
      highlight: {},
      textDiv: {
        overflow: 'hidden',
        width: '200px'
      }
    }
  },
  { name: 'PresentationNavText' }
)

export interface PresentationNavTextProps {
  presentation?: PresentationOption
}

const PresentationNavText = ({ ...props }: PresentationNavTextProps) => {
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const { quote } = useQuote()
  const { cartOpen, currentPage, isQuoteEmpty, onOpenCart, onPageClick, view } = usePresentationNav()
  const presentation = props?.presentation ? props?.presentation : getQuotePresentation({ quote, options, view })
  const [currentScrollPage, setCurrentScrollPage] = useState<string>()
  const useCart = presentation?.advanced?.useCart

  let quotePageIndex: number
  const activeItems = presentation.pages.filter(filterActivePresentationPages)
  const filteredItems = !isQuoteEmpty
    ? activeItems
    : activeItems.filter((page) => !page.sections.some((section) => section.type === 'quote'))

  const highlight = filteredItems.reduce((acc: string, page: PresentationPage, currentIndex) => {
    const estimateSection = find(page.sections, { type: 'quote' })
    if (estimateSection) {
      quotePageIndex = currentIndex
    }
    return acc || (estimateSection ? page.key : null)
  }, null)

  return (
    <div className={classes.textRoot}>
      <Scrollspy
        componentTag={'div'}
        items={filteredItems.map((item) => item.key)}
        offset={-100}
        onUpdate={(element) => {
          setCurrentScrollPage(element?.id)
        }}
      >
        {filteredItems.map((item, index) => {
          return (
            <div
              className={classes.buttonWrapper}
              key={item.key}
              onClick={(ev) => {
                if (index === quotePageIndex) {
                  if (useCart && !cartOpen) {
                    onOpenCart()
                  }
                }
                if (onPageClick) {
                  onPageClick(ev, item.key)
                  setCurrentScrollPage(item.key)
                }
              }}
            >
              <div className={classes.textDiv}>
                <Typography
                  classes={{
                    root: classnames({
                      [classes.button]: true,
                      [classes.currentPage]: isCurrentItem(item, index, currentPage),
                      [classes.highlight]: highlight === item.key,
                      [classes.buttonSpan]: true
                    })
                  }}
                  variant="overline"
                  noWrap
                >
                  {item.title ?? item.label}
                </Typography>
              </div>
            </div>
          )
        })}
      </Scrollspy>
    </div>
  )

  function isCurrentItem(item, index, currentKey) {
    if (currentScrollPage) {
      return item.key === currentScrollPage
    }
    return (currentKey === null && index === 0) || item.key === currentKey
  }
}

export default PresentationNavText
