import React from 'react'

import type { PresentationNavContextValue } from './PresentationNavContext'
import PresentationNavContext from './PresentationNavContext'

export interface PresentationNavContextProviderProps extends PresentationNavContextValue {}

export default function PresentationNavContextProvider({ children, ...props }) {
  const childrenFn = children as (context: PresentationNavContextValue) => React.ReactNode

  const value = {
    ...props,
    isSinglePage: true
  }

  return (
    <PresentationNavContext.Provider value={value}>
      {typeof children === 'function' ? childrenFn(value) : children}
    </PresentationNavContext.Provider>
  )
}
