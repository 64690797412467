import React, { useContext } from 'react'
import type { QuoteItemSection, RenderableItem } from '@paintscout/util/builder'
import { CLOSED_STATUSES, getFilesForItem, formatCurrency } from '@paintscout/util/builder'
import { isEmpty } from '@paintscout/util/util'
import {
  DialogStackContext,
  FilePreview,
  HtmlContent,
  Typography,
  useClientOptions,
  useMediaQuery,
  ViewMediaDialog
} from '@ui/stickybid'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import NameColumn from './NameColumn'
import { QuoteContext } from '../../context/QuoteContext'
import numeral from 'numeral'
import { usePresentationNav } from '../../presentation'

import DragHandleIcon from '@material-ui/icons/DragHandle'
import classnames from 'classnames'
// import { DragHandleYIcon } from '@ui/core/icons'

const useStyles = makeStyles<Theme, ItemColumnProps>(
  (theme: Theme) => ({
    root: (props) => ({
      display: 'grid',
      gridTemplateColumns: props.hasCheckbox ? 'minmax(auto, 460px) 1fr 85px' : 'minmax(auto, 500px) 1fr 85px',
      gridTemplateRows: 'auto 1fr',
      ...(props.isEditable && {
        minHeight: theme.spacing(7)
      }),
      '@media screen': {
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'minmax(auto, 500px) minmax(90px, 1fr)',
          gridTemplateRows: 'auto auto auto auto'
        }
      }
    }),
    value: {
      fontWeight: theme.typography.fontWeightMedium,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      },
      '& span': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    title: (props) => ({
      marginBottom: theme.spacing(0.5),
      '@media screen': {
        [theme.breakpoints.down('sm')]: {
          gridColumn: '1 / 3',
          marginRight: theme.spacing(4),
          marginBottom: 0
        }
      }
    }),
    mobileQuantity: {
      gridRow: 2,
      gridColumn: '1 / 3',
      color: theme.palette.grey[700]
    },
    description: (props: ItemColumnProps) => ({
      gridRow: 2,
      gridColumn: '1 / 2',
      justifyContent: 'space-between',
      wordBreak: 'break-word',
      '@media screen': {
        [theme.breakpoints.down('sm')]: {
          gridRow: 3,
          gridColumn: '1 / 3',
          marginRight: theme.spacing(4)
        }
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    }),
    filePreviewDiv: {
      cursor: 'pointer',
      gridRow: '1 / 3',
      gridColumn: '2 / 3',
      justifySelf: 'center',
      margin: theme.spacing(0, 2),
      '@media screen': {
        [theme.breakpoints.down('sm')]: {
          gridRow: 4,
          gridColumn: '1 / 2',
          justifySelf: 'start',
          marginLeft: 0,
          marginTop: theme.spacing(1)
        }
      }
    },
    filePreview: {
      height: '72px',
      width: '72px',
      objectFit: 'cover',
      flexGrow: 1,
      borderRadius: 3,
      margin: 0,
      marginLeft: 0
    },
    filePreviewSize: {
      display: 'flex',
      height: '72px',
      width: '72px'
    },
    filePreviewPaper: {
      minHeight: '72px !important',
      maxHeight: '72px !important'
    },
    filePreviewImage: {
      objectFit: 'cover',
      width: '100%'
    },
    filePreviewPageIcon: {
      padding: theme.spacing(1)
    },
    thumbnailPdfPaper: {
      '& > img': {
        height: 72,
        width: 72
      },
      objectFit: 'contain'
    },
    totals: {
      textAlign: 'right',
      gridRow: '1 / -1',
      display: 'flex',
      flexDirection: 'column',
      '@media screen': {
        [theme.breakpoints.down('sm')]: {
          gridRow: 4,
          gridColumn: '2 / -1',
          alignSelf: 'end',
          marginBottom: 0
        }
      }
    },
    customerSelected: {}, // need to define this rule to be accessed below
    optionText: {
      '&$optionText': {
        color: theme.palette.grey[700],
        marginTop: theme.spacing(1),
        '@media screen': {
          [theme.breakpoints.down('sm')]: {
            marginTop: 0
          }
        }
      },
      '&$customerSelected': {
        color: theme.palette.success.main,
        fontWeight: 500
      }
    },
    dragHandle: {
      position: 'absolute',
      left: -42,
      color: theme.palette.secondary.main,
      cursor: 'grab',
      [theme.breakpoints.down('sm')]: {
        right: -3,
        top: 33,
        left: 'auto'
      },
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    newImagePlaceholder: {
      display: 'flex',
      flexDirecton: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '72px',
      width: '72px',
      borderRadius: theme.borderRadius.md,
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  }),
  { name: 'ItemColumn' }
)

export interface ItemColumnProps {
  item: RenderableItem
  ActionButton?: React.ReactNode
  consumer: string
  hasCheckbox?: boolean
  inPresentation?: boolean
  isEditable: boolean
  section?: QuoteItemSection
  selectedOption?: boolean
  showAmount?: boolean
}

export default function ItemColumn(props: ItemColumnProps) {
  const classes = useStyles(props)
  const { item, isEditable, showAmount, section, selectedOption, ActionButton, inPresentation } = props
  const { quote } = useContext(QuoteContext)
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { value, name, additionalFields } = item
  const { options } = useClientOptions()
  const hasValue = value !== 0
  const { presentation, isCustomerView } = usePresentationNav()
  const status = quote.status.value
  const quoteIsOpen = !CLOSED_STATUSES.includes(status) || ['partial', 'paid'].includes(quote.status.value)

  const hasName = !!name

  const description =
    item?.description?.useCustom && item.description.custom ? item.description.custom : item.description.default
  const hasDescription = !isEmpty(description)

  const hasSubItems = item.subItems && item.subItems.length > 0

  const showPrice = additionalFields?.showPrice ?? false
  const hideQuantity = additionalFields?.hideQuantity ?? false
  const acceptedOption = additionalFields?.acceptedOption ?? false
  const files = getFilesForItem({ quote, options, itemKey: item.key })
  const smDown = useMediaQuery('sm')

  let quantity = ''
  const formattedQuantity = numeral(additionalFields.quantity).format('0,0[.]00')
  if (!hideQuantity) quantity = `${formattedQuantity} unit${additionalFields.quantity !== 1 ? 's' : ''}`
  if (showAmount && showPrice && !hideQuantity)
    quantity += ` @ ${formatCurrency({ value: additionalFields.pricePerUnit, options })}`
  if (quantity) quantity = `(${quantity})`

  const handleMediaViewClick = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation()
    openDialog(ViewMediaDialog, {
      title: (name as string) || 'Feature Image',
      index: 0,
      files: [files[0]],
      enableDownload: isCustomerView,
      onClose: dismissDialog
    })
  }

  const getOptionText = () => {
    if (inPresentation) {
      if (quoteIsOpen) {
        return selectedOption ? 'Selected' : 'Not included'
      } else {
        return 'Not included'
      }
    } else {
      return quoteIsOpen ? 'Optional' : 'Not included'
    }
  }

  return (
    <div className={classes.root}>
      {isEditable && !presentation && <DragHandleIcon className={classes.dragHandle} />}
      <NameColumn classes={{ root: classes.title }} item={item} additional={!smDown ? ` ${quantity}` : null} />
      {smDown && <div className={classes.mobileQuantity}>{quantity}</div>}
      {hasDescription && (
        <div className={classes.description}>
          <HtmlContent content={description} />
        </div>
      )}
      {files.length > 0 && (
        <div className={classes.filePreviewDiv}>
          <div onClick={handleMediaViewClick}>
            <FilePreview
              classes={{
                root: classes.filePreview,
                size: classes.filePreviewSize,
                pdfPaper: classes.filePreviewPaper,
                image: classes.filePreviewImage,
                pageIcon: classes.filePreviewPageIcon,
                thumbnailPdfPaper: classes.thumbnailPdfPaper
              }}
              file={files[0]}
              showFirstPageOnly={true}
              showViewHover={true}
              videoThumbnail={true}
            />
          </div>
        </div>
      )}
      {/* Blank + as image holder holding off for now */}
      {/* Need the parent div at least to occupy the space */}
      {/* {files.length === 0 && isEditable && !presentation && ( */}
      {files.length === 0 && (
        <div className={classes.filePreviewDiv}>
          {/* <div style={{display: 'flex', flexDirection: 'column'}} onClick={() => onEditItem(item.key)}>
            <div className={classes.newImagePlaceholder}>
            +
            </div>
          </div> */}
        </div>
      )}
      <div className={classes.totals}>
        {hasValue && showAmount && showPrice && (
          <Typography
            value={item.value as unknown as number}
            component="span"
            showUnits={true}
            format={'price'}
            variant="body1"
            showZeros={true}
            classes={{ root: classes.value }}
          />
        )}
        {section === 'bid' && acceptedOption && (
          <Typography variant={'body1'} className={classnames(classes.optionText, classes.customerSelected)}>
            Accepted
          </Typography>
        )}

        {section === 'options' && (
          <Typography
            variant={'body1'}
            className={classnames(classes.optionText, { [classes.customerSelected]: inPresentation && selectedOption })}
          >
            {getOptionText()}
          </Typography>
        )}
        {ActionButton && ActionButton}
      </div>
    </div>
  )
}
