import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { useClientOptions } from '@ui/stickybid'
import { Typography } from '@ui/stickybid'
import { getObjectLabels } from '@paintscout/util/builder'
import { QuoteContext } from '../../../context/QuoteContext'
import QuoteHeaderCard from '../QuoteHeaderCard'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop: theme.spacing(1),
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    title: {
      paddingRight: 0
    },
    text: {
      marginLeft: theme.spacing(1)
    }
  })

export interface QuoteIDCardProps extends WithStyles<typeof styles> {
  importedId?: boolean
}

function QuoteIDCard(props: QuoteIDCardProps) {
  const { classes, importedId } = props
  const { quote, isEditable } = useContext(QuoteContext)

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })

  if (importedId && !quote.job_identifier) return null

  return (
    <QuoteHeaderCard
      classes={{
        root: classes.root,
        infoWrapper: classes.infoWrapper,
        title: classes.title,
        text: classes.text
      }}
      hideBorder={true}
      title={importedId && quote.job_identifier ? 'Import ID' : `ID #`}
      text={
        importedId ? (
          quote.job_identifier || 'No ID'
        ) : quote.number && quote.number !== -1 ? (
          quote.number.toString()
        ) : (
          <Typography variant="subtitle1">No ID</Typography>
        )
      }
      clickable={false}
    />
  )
}

export default withStyles(styles)(QuoteIDCard)
