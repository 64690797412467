import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  Button,
  Checkbox,
  CloseButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DropdownSelect,
  FormControlLabel,
  FormSection,
  Grid,
  InputField,
  Typography
} from '@ui/stickybid'
import type { DialogProps } from '@ui/stickybid'
import { useSnackbar } from 'notistack'
import type { QuoteDocument } from 'paintscout'
import CodeEditor from '../CodeEditor'
import cloneDeep from 'lodash/cloneDeep'
import { usePullClientQuoteLinkLazyQuery } from '@paintscout/api'
import { capitalizeFirstLetter } from '@paintscout/util/util'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  input: { width: '100%', height: '100%' },
  buttonContainer: { marginBottom: theme.spacing() }
}))

export interface ImportOwner {
  value: string
  label: string
  email: string
  companyId: string
}

export interface ImportCSVDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  title: string
  open: boolean
  owners: ImportOwner[]
  onClose?: () => void
  onConfirm?: (owner: { value: string; label: string; email: string }, file: File, provider?: string) => void
  onConfirmJson?: (owner: { value: string; label: string; email: string }, jsonInput: string) => void
}

function ImportCSVDialog({ onConfirm, onConfirmJson, onClose, title, owners, ...props }: ImportCSVDialogProps) {
  const classes = useStyles(props)
  const { enqueueSnackbar } = useSnackbar()
  const label = capitalizeFirstLetter(title === 'contacts' ? 'customers' : title)
  const isQuoteUpload = title === 'quotes'
  const [showJsonField, setShowJsonField] = useState<boolean>(false)
  const [showUrlField, setShowUrlField] = useState<boolean>(false)
  const [jsonInput, setJsonInput] = useState<string>('')
  const [urlInput, setUrlInput] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [owner, setOwner] = useState<{ value: string; label: string; email: string } | null>(null)
  const [provider, setProvider] = useState<{ value: string; label: string } | null>(null)
  const [safeImport, setSafeImport] = useState<boolean>(true)
  const providers = [
    { value: 'default', label: 'Default' },
    { value: 'er-quotes', label: 'ER Quotes' }
  ]

  const [pullQuoteLink] = usePullClientQuoteLinkLazyQuery({
    onCompleted: (data) => {
      if (data.pullClientQuoteLink === '') {
        enqueueSnackbar(`Unable to load quoteLink data`, { variant: 'error' })
        onClose()
      } else {
        enqueueSnackbar(`Successfully loaded quoteLink data`, { variant: 'success' })
        let urlData = JSON.parse(data.pullClientQuoteLink)
        console.log({ urlData })
        urlData = safeQuote(urlData)
        onConfirmJson(owner, JSON.stringify(urlData))
      }
    },
    onError: () => {
      enqueueSnackbar(`Unable to load quote link`, { variant: 'error' })
    }
  })

  const handleImport = () => {
    onConfirm(owner, file, provider?.value)
  }
  const handleJsonImport = () => {
    onConfirmJson(owner, jsonInput)
  }

  const handleUrlImport = async () => {
    try {
      pullQuoteLink({ variables: { link: urlInput } })
    } catch (error) {
      enqueueSnackbar('Error importing quote from link', { variant: 'warning' })
    }
  }

  const handleSelectedFile = (e: any) => {
    setShowJsonField(false)
    const file = e.target.files[0]
    if (file) {
      const fileType = file.name.split('.').pop()
      const fileSize = file.size

      if (!['csv'].includes(fileType)) {
        enqueueSnackbar('Invalid file type', { variant: 'error' })
      } else if (fileSize / (1024 * 1024) > 3.75) {
        // Will keep us under 5MB once encoded
        enqueueSnackbar('File size must be less than 3.75MB', { variant: 'error' })
      } else {
        setFile(e.target.files[0])
      }
    }
  }

  return (
    <Dialog {...props}>
      <DialogTitle rightContent={<CloseButton onCancel={onClose} />}>Import {label}</DialogTitle>
      <DialogContent>
        <Grid className={classes.buttonContainer} container spacing={3}>
          <Grid item xs={12}>
            <Button prominence={2} component={'label'} onChange={handleSelectedFile}>
              Upload {label} File
              <input accept=".csv" type="file" hidden />
            </Button>
          </Grid>
          {isQuoteUpload && (
            <>
              <Grid item xs={12}>
                <Button
                  prominence={2}
                  component={'label'}
                  onClick={() => {
                    setShowJsonField(true)
                    setShowUrlField(false)
                    setUrlInput('')
                  }}
                >
                  Upload Single JSON Quote
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  prominence={2}
                  component={'label'}
                  onClick={() => {
                    setShowUrlField(true)
                    setShowJsonField(false)
                    setJsonInput('')
                  }}
                >
                  Upload from Quote Link
                </Button>
              </Grid>
            </>
          )}
          {file && (
            <Grid item xs={12}>
              <Typography variant="h5">{file?.name}</Typography>
              <Typography variant="h6">{(file?.size / (1024 * 1024)).toFixed(2)} MB / 5 MB</Typography>
            </Grid>
          )}
        </Grid>
        {(file || jsonInput || urlInput) && (
          <FormSection>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <DropdownSelect
                  label="Select Owner"
                  options={owners || []}
                  value={owner}
                  onChange={(owner: { value: string; label: string; email: string }) => {
                    setOwner(owner)
                  }}
                />
              </Grid>
              {isQuoteUpload && (
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant={'overline'}>Safe values?</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={safeImport}
                          defaultChecked={safeImport}
                          onChange={() => setSafeImport((safeImport) => !safeImport)}
                        />
                      }
                      label={'Change contact details to prevent accidental sends'}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </FormSection>
        )}
        {showJsonField && (
          <FormSection>
            <Typography variant={'h6'}>Json:</Typography>
            <CodeEditor value={jsonInput} onChange={setJsonInput} />
          </FormSection>
        )}
        {showUrlField && (
          <FormSection>
            <Grid item xs={12}>
              <InputField
                fullWidth
                label={'Quote Url'}
                value={urlInput}
                onChange={(ev) => setUrlInput(ev.currentTarget.value.replace(/"|'/g, ''))}
              />
            </Grid>
          </FormSection>
        )}
        {file && owner && isQuoteUpload && (
          <FormSection>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DropdownSelect
                  label="Select Quote Provider"
                  options={providers}
                  value={provider}
                  onChange={(provider: { label: string; value: string }) => {
                    setProvider(provider)
                  }}
                />
              </Grid>
            </Grid>
          </FormSection>
        )}
      </DialogContent>
      <DialogActions>
        {showJsonField ? (
          // Json upload confirm
          <Button prominence={1} onClick={handleJsonImport} disabled={!owner || !jsonInput}>
            Confirm
          </Button>
        ) : showUrlField ? (
          // Url upload confirm
          <Button
            prominence={1}
            onClick={async () => await handleUrlImport()}
            disabled={!owner || (isQuoteUpload && !urlInput)}
          >
            Confirm
          </Button>
        ) : (
          // File upload confirm
          <Button prominence={1} onClick={handleImport} disabled={!owner || !file || (isQuoteUpload && !provider)}>
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ImportCSVDialog

const safeQuote = (quote: QuoteDocument): QuoteDocument => {
  let newQuote = cloneDeep(quote)
  newQuote = {
    ...newQuote,
    contact: {
      ...newQuote.contact,
      email: newQuote?.contact?.email ? `test-${newQuote?.contact?.email}` : '',
      alternate_email: newQuote?.contact?.alternate_email ? `test-${newQuote?.contact?.alternate_email}` : '',
      phone_number: newQuote?.contact?.phone_number ? `test-${newQuote?.contact?.phone_number.slice(0, -1)}` : '',
      alternate_phone: newQuote?.contact?.alternate_phone
        ? `test-${newQuote?.contact?.alternate_phone.slice(0, -1)}`
        : ''
    },
    secondaryContact: {
      ...newQuote.secondaryContact,
      email: newQuote?.secondaryContact?.email ? `test-${newQuote?.secondaryContact?.email}` : '',
      alternate_email: newQuote?.secondaryContact?.alternate_email
        ? `test-${newQuote?.secondaryContact?.alternate_email}`
        : '',
      phone_number: newQuote?.secondaryContact?.phone_number
        ? `${newQuote?.secondaryContact?.phone_number.slice(0, -1)}`
        : '',
      alternate_phone: newQuote?.secondaryContact?.alternate_phone
        ? `test-${newQuote?.secondaryContact?.alternate_phone.slice(0, -1)}`
        : ''
    }
  }

  return newQuote
}
