/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument, QuoteOptions } from 'paintscout'
import type { RenderableItem, QuoteItemSection, QuoteItemConsumer } from '../../../index'
import { filterOrderItems, getItemOrder } from '../../get-items/util'
import { filterItem, getQuoteOptions } from '../../../util'
import { trimLineItem } from '../../../line-items'
import { trimArea } from '../../../areas'
import { filterCrewAreaItems, getCrewAreaSubstrateValues } from './util'
import { convertV1Quote } from '../util/convert-v1-quote'

/**
 * Get Bid, Options, or Archived list of areas for a quote.
 */
export function getCrewItemsByArea(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  showLineItems?: boolean
  showValues?: boolean
  hideDimensions?: boolean
}): RenderableItem[] {
  const { options, overrideOptions, section, consumer, keys, hideDimensions } = args
  const showLineItems = args.showLineItems === false ? false : true
  const showValues = args.showValues === false ? false : true
  let quote = convertV1Quote(args.quote)

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const areaOrder = getItemOrder('area', section, quote)

  const items = areaOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys, workOrder: true })) {
        return null
      }

      // Get the raw items from the areas/lineItems objects
      if (orderItem.type === 'area') {
        const area = quote.areas[orderItem.key]
        if (!area) return null

        // Get areaSubstrates for given section, and their total value
        const { areaSubstrates, value: subItemTotal } = getCrewAreaSubstrateValues({
          quote,
          options,
          quoteOptions,
          area,
          orderItem,
          section,
          consumer,
          showValues,
          hideDimensions
        })

        // Filter crew area items by section and areaSubstrates
        if (filterCrewAreaItems({ area, section, areaSubstrates })) {
          return null
        }

        // Clean area for display
        const trimmedArea = trimArea({
          item: { ...orderItem, ...area },
          quote,
          options,
          consumer,
          section,
          areaSubstratesLength: areaSubstrates.length
        })

        if (!filterItem(area, section)) {
          trimmedArea.value = subItemTotal
        }

        return {
          ...trimmedArea,
          subItems: areaSubstrates,
          subItemTotal
        }
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems[orderItem.key]

        // Exclude 'hideOnWorkOrder' Line Items
        if (lineItem && lineItem.hideOnWorkOrder && !overrideOptions?.showHiddenItems) {
          return null
        }

        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer) as RenderableItem
        }
      }
      return null
    })
    .filter((item) => item)

  return items
}
