import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { CheckboxProps as CoreCheckboxProps } from '@ui/core/Checkbox'
import CoreCheckbox from '@ui/core/Checkbox'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'

// Primary = light gray when unchecked, dark gray when checked
// Secondary = dark gray when unchecked, white when checked
const useStyles = makeStyles<Theme, CoreCheckboxProps>((theme) => {
  const primaryColors = {
    checked: theme.palette.grey[800],
    unchecked: theme.palette.grey[500],
    disabled: theme.palette.grey[300]
  }

  const secondaryColors = {
    checked: theme.palette.grey[100],
    unchecked: theme.palette.grey[700],
    disabled: theme.palette.grey[300]
  }

  return {
    root: {
      borderRadius: theme.borderRadius.sm
    },
    checked: {},
    disabled: {},
    colorPrimary: {
      color: primaryColors.unchecked,
      '&$checked': {
        color: primaryColors.checked
      },
      '& $uncheckedIcon': {
        color: primaryColors.checked
      },
      '&$disabled $uncheckedIcon': {
        background: primaryColors.disabled
      },
      '&$disabled': {
        color: primaryColors.disabled
      }
    },
    colorSecondary: {
      color: secondaryColors.unchecked,
      '&$checked': {
        color: secondaryColors.checked
      },
      '& $uncheckedIcon': {
        background: secondaryColors.unchecked
      },
      '&$disabled $uncheckedIcon': {
        background: secondaryColors.disabled
      },
      '&$disabled': {
        color: secondaryColors.disabled
      }
    },
    uncheckedIcon: {}
  }
})

export interface CheckboxProps extends CoreCheckboxProps {
  classes?: StyleClasses<typeof useStyles>
  /**
   * Icon to show when unchecked
   */
  icon?: React.ReactNode
  /**
   * Icon to show when checked
   */
  checkedIcon?: React.ReactNode
}

function Checkbox(props: CheckboxProps) {
  const classes = useStyles(props)

  return (
    <CoreCheckbox
      {...props}
      classes={classes}
      icon={<CheckBoxOutlineBlankOutlinedIcon />}
      checkedIcon={<AddBoxOutlinedIcon />}
    />
  )
}

export default Checkbox
