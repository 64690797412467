import type { AreaSubstrate, OrderItem, QuoteDocument, QuoteOptions } from 'paintscout'
import type { RenderableItemSubstrate } from '../..'
import type { UpdateableArea } from '../../../areas'
import { getAreaSubstrateKey } from '../../get-area-substrate-key'
import { isInAreaGroup } from '../../is-in-area-group'
import { isInSubstrateGroup } from '../../is-in-substrate-group'
import find from 'lodash/find'
import get from 'lodash/get'
import {
  convertCalculationType,
  convertDimensions,
  convertHours,
  convertMaterials,
  convertPrep,
  convertPrice,
  convertProduct,
  convertRateType
} from '../util'
import striptags from 'striptags'

/**
 * Trim an areaSubstrate to a RenderableItemSubstrate to be displayed
 */
export default function toRenderableItemSubstrate(args?: {
  areaSubstrate: AreaSubstrate
  options: QuoteOptions
  quote: QuoteDocument
  area: UpdateableArea
}): RenderableItemSubstrate {
  const { areaSubstrate, options, quote, area } = args

  const hours = convertHours(areaSubstrate)

  const prep = convertPrep(areaSubstrate)

  const totalHours = Number(prep) + Number(hours.useCustom ? hours.custom : hours.default)

  const price = convertPrice(areaSubstrate)

  const product = convertProduct(areaSubstrate)

  const materials = convertMaterials(product)

  const name = areaSubstrate.rate.label

  const substrateLabel = get(areaSubstrate, 'rate.client_label', get(areaSubstrate, 'rate.label', 'Custom'))
  const substrateOrderItem = find(quote.order.substrate, { key: substrateLabel }) as OrderItem
  const substrateGroup = !!(substrateOrderItem && substrateOrderItem.parent)

  const { length, width, height } = convertDimensions({ areaSubstrate, area })

  const rateKey = getAreaSubstrateKey(quote, areaSubstrate)

  const inSubstrateGroup = isInSubstrateGroup({ quote, rateKey })
  const inAreaGroup = isInAreaGroup({ quote, areaKey: area.key })
  const rateType = convertRateType(areaSubstrate.rate.rate_type)
  const calculationType = convertCalculationType(areaSubstrate.rate.calculation_type)
  const areaReportText =
    areaSubstrate.rate?.areaReportText && striptags(areaSubstrate.rate?.areaReportText).length > 0
      ? areaSubstrate.rate?.areaReportText
      : ''
  const substrateReportText =
    areaSubstrate.rate?.report_text && striptags(areaSubstrate.rate?.report_text).length > 0
      ? areaSubstrate.rate?.report_text
      : ''

  return {
    key: areaSubstrate.key,
    name: `${name}`,
    clientLabel: areaSubstrate.rate.client_label,
    rateKey,
    inSubstrateGroup,
    inAreaGroup,

    notes: areaSubstrate.notes,

    hours,

    prep: Number(prep),
    totalHours,

    price,

    materials,

    product,

    coats: Number(areaSubstrate.coats),

    length,
    height,
    width,

    quantity: Number(areaSubstrate.quantity),

    rateType,
    section: areaSubstrate.rate.section,
    calculationType,

    workOrderOnly: areaSubstrate.workOrderOnly,

    showPrice: areaSubstrate.showPrice,
    showProduct: areaSubstrate.showProduct,
    showCoats: areaSubstrate.showCoats,
    showPrep: areaSubstrate.showPrep,
    useCoats: areaSubstrate.rate.useCoats,

    hideQuantity: areaSubstrate.hideQuantity,
    hideQuantityOnWorkOrder: areaSubstrate.hideQuantityOnWorkOrder,

    areaReportText,
    substrateReportText,

    substrateGroup
  }
}
