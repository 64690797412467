import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { DeleteIcon } from '@ui/core/icons'
import EditIcon from '@material-ui/icons/EditOutlined'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import React, { useContext } from 'react'
import { useClientOptions, useUser, Typography, ActionButton } from '@ui/stickybid'
import type { RenderableItem, QuoteItemSection } from '@paintscout/util/builder'
import {
  getItems,
  getObjectLabels,
  getQuoteOptions,
  getTotals,
  getFeatures,
  setQuoteOptions
} from '@paintscout/util/builder'
import get from 'lodash/get'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import { AmountHeadColumn } from '../../../../ItemTable'
import ItemTable, { ItemColumn, ItemTableCell } from '../../../../ItemTable'
// import ActionColumn from '../../../../ItemTable/columns/ActionColumn'
import { usePresentation } from '../../../../context'
import { usePresentationNav } from '../../../../presentation'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    clientNote: {
      fontStyle: 'italic'
    },
    sectionTitle: {
      color: theme.palette.primary.main,
      width: '80%'
    },
    actionButton: {
      color: theme.palette.secondary.main,
      paddingTop: theme.spacing(0.5),
      marginTop: 'auto',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 0,
        right: -3
      },
      '&:hover': {
        color: theme.palette.primary.main
      },
      '@media print': {
        display: 'none'
      }
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      borderBottom: '1px solid #888',
      '& h2': {
        // 12px matches with payment and gallery titles
        marginBottom: `12px !important`
      },
      '& h4': {
        marginBottom: `${theme.spacing(1)}px !important`
      }
    },

    amountHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(),
      ...theme.typography.overline,
      color: theme.palette.secondary.main,
      '& svg': {
        color: theme.palette.secondary.main
      }
    },
    amountHeaderActive: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.main
        }
      }
    },
    actionSpacer: {
      marginLeft: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(3)
      }
    },
    toggleDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 0,
      '@media print': {
        display: 'none'
      }
    },
    itemCell: {
      width: '100%',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      }
    },
    dragHandle: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      }
    },
    tableRow: {
      // minHeight: '112px',
      '&:hover': {
        '& $dragHandle': {
          display: 'block'
        }
      }
    }
  })

export interface BidTableProps {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onClearItemSelection?: () => any
  inPresentation?: boolean
}

type BidTablePropsWithInjections = BidTableProps & WithStyles<typeof styles>

function BidTable({ classes, inPresentation, ...itemTableProps }: BidTablePropsWithInjections) {
  const { quote, tableView, isEditable, onReorder, onTableViewChange, onEditItem, onItemAction, updateQuote } =
    useContext(QuoteContext)
  const { options } = useClientOptions()
  const { isAdmin, isCrew } = useUser()
  const isInvoice = !!quote.is_invoice
  const isInvoiceFromImport = quote.fromPdfImport && isInvoice

  const presentation = usePresentation()
  const presentationNav = usePresentationNav()

  const canEditQuote =
    (!presentationNav?.isCustomerView && !presentationNav?.isPreviewView && isEditable && !presentation?.isEditable) ??
    false
  const features = getFeatures({ options })
  const hasAdditionalWork = get(features, 'quotes.additionalWork')
  const canFullyEdit = (!quote.is_invoice || isAdmin || !hasAdditionalWork) && !isInvoiceFromImport

  const { showPrices, showDetailedBreakdown, allowShowCustomizedTotalPrice, addIndex } = getQuoteOptions({
    quote,
    options
  })

  const objectLabels = getObjectLabels({ options, invoice: isInvoice })

  const totals = getTotals({
    quote,
    consumer: 'customer',
    options
  })
  const items: RenderableItem[] = getItems({
    quote,
    section: 'bid',
    view: tableView,
    consumer: 'customer',
    options
  }).map((item) => {
    if (showDetailedBreakdown && !item.description && !item.value && item.subItems && item.subItems.length > 0) {
      const firstSubItem = item.subItems.shift()
      return {
        ...item,
        description: {
          useCustom: false,
          default: firstSubItem.description,
          custom: ''
        },
        value: firstSubItem.value
      }
    }

    return item
  })

  if (items.length === 0 && !isEditable) {
    return null
  }

  const allowAmountToggle =
    (!quote.totalAdjustment ||
      allowShowCustomizedTotalPrice ||
      (quote.totalAdjustment && quote.totalAdjustment.rate === 0)) &&
    canEditQuote &&
    !isInvoiceFromImport

  function handleReorder({ items, addIndex }) {
    onReorder({ items, section: 'bid', view: tableView, addIndex })
  }

  function handleItemAction(action: string, section: QuoteItemSection, item: RenderableItem) {
    if (action === 'edit') {
      onEditItem(item.key)
    } else {
      onItemAction(action, section, [item.key])
    }
  }

  function handleToggleAmount(ev: any) {
    if (!allowAmountToggle) return null
    const quoteOptions = getQuoteOptions({ options, quote })
    quoteOptions.showPrices = !quoteOptions.showPrices

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <Typography variant={'h2'}>Items</Typography>
        <div
          onClick={items.length > 0 && canEditQuote ? handleToggleAmount : null}
          className={classnames({ [classes.amountHeader]: true, [classes.amountHeaderActive]: allowAmountToggle })}
        >
          {items.length > 0 && allowAmountToggle && (
            <AmountHeadColumn
              isEditable={isEditable}
              consumer="customer"
              disabled={!allowAmountToggle}
              onChange={handleToggleAmount}
              showAmount={showPrices}
            />
          )}
          Amount
        </div>
      </div>
      <ItemTable
        draggable={!!isEditable && !inPresentation && !isInvoiceFromImport}
        checkboxes={false}
        inPresentation={inPresentation}
        showAddButton={false}
        items={items}
        totals={totals}
        addIndex={addIndex.bid}
        addButtonLabel={'Add New Item'}
        onReorder={handleReorder}
        onItemClick={isEditable && !inPresentation ? (ev, item) => onEditItem(item.key) : null}
        renderHeadColumns={() => (
          <>
            {/* <ItemTableCell align="right">
              {items.length > 0 && canEditQuote && (
                <div className={classes.toggleDiv}>
                  <Typography variant={'subtitle2'}>$</Typography>
                  <AmountHeadColumn
                    isEditable={isEditable}
                    consumer="customer"
                    disabled={!allowAmountToggle}
                    onChange={handleToggleAmount}
                    showAmount={showPrices}
                  />
                </div>
              )}
            </ItemTableCell> */}
          </>
        )}
        renderRowColumns={(item: RenderableItem, hasCheckbox: boolean) => {
          const actions = []

          actions.push({
            key: 'edit',
            label: 'Edit',
            icon: EditIcon
          })

          if (canFullyEdit) {
            actions.push({
              key: 'copy',
              label: 'Copy',
              icon: CopyIcon
            })

            actions.push({
              key: 'delete',
              label: 'Delete',
              icon: DeleteIcon
            })
          }

          const showAcceptedCheckbox = item.additionalFields?.acceptedOption && inPresentation

          return (
            <ItemTableCell className={classes.itemCell} colSpan={showAcceptedCheckbox ? 1 : 2}>
              <ItemColumn
                item={item}
                consumer={'customer'}
                isEditable={isEditable}
                showAmount={showPrices}
                hasCheckbox={hasCheckbox}
                inPresentation={inPresentation}
                section={'bid'}
                ActionButton={
                  canEditQuote ? (
                    <ActionButton
                      actions={isCrew ? null : actions}
                      className={classes.actionButton}
                      onActionClick={(ev, action) => handleItemAction(action, 'bid', item)}
                      edge={'end'}
                      size={'small'}
                    />
                  ) : null
                }
              />
            </ItemTableCell>
          )
        }}
        {...itemTableProps}
      />
    </div>
  )
}

export default withStyles(styles)(BidTable)
