import React from 'react'
import type { PresentationSection } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { uuid } from '@paintscout/util/builder'

import { ButtonSelectOption, ButtonOption } from '@ui/stickybid'

import { UTurnRightIcon } from '@ui/core/icons'
import LooksOneIcon from '@material-ui/icons/LooksOne'
import LooksTwoIcon from '@material-ui/icons/LooksTwo'
import Looks3Icon from '@material-ui/icons/Looks3'
import Looks4Icon from '@material-ui/icons/Looks4'
import Looks6Icon from '@material-ui/icons/Looks6'
import AddIcon from '@material-ui/icons/Add'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import GradientIcon from '@material-ui/icons/Gradient'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import ImageIcon from '@material-ui/icons/Image'

const useStyles = makeStyles<Theme, CardsOptionsProps>((theme: Theme) => {
  return {
    root: {}
  }
})

interface CardsOptionsProps {
  section: PresentationSection
  handleOptionFieldChange: (field: string, value: any) => void
  type?: 'presentation' | 'testimonials'
}

function CardsOptions(props: CardsOptionsProps) {
  const classes = useStyles(props)
  const { section, handleOptionFieldChange, type } = props
  const { animation, columns, cards, useCarousel, imageOnly } = section.fields

  const handleNewCard = () => {
    const newCards = [...cards]
    if (type !== 'testimonials') newCards.push({ file: null, content: '', key: uuid() })
    else
      newCards.push({
        content:
          '<p>Add testimonials from your clients that underscore the quality your company brings. Research by Dixa showed that <strong>97%</strong> of participants factor customer reviews into their buying decisions.</p>',
        credit: '<h3>Carey B.</h3>',
        key: uuid()
      })
    handleOptionFieldChange('fields.cards', newCards)
  }

  const options = [
    {
      Component: (
        <ButtonSelectOption
          label={'Animation'}
          value={animation}
          buttons={[
            { icon: <NotInterestedIcon />, value: 'none' },
            { icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} />, value: 'slide' },
            { icon: <UTurnRightIcon style={{ transform: 'rotate(180deg)' }} />, value: 'spring' },
            { icon: <GradientIcon />, value: 'fade' },
            { icon: <ZoomOutMapIcon />, value: 'zoom' }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.animation', value)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonSelectOption
          label={'Columns'}
          value={columns}
          buttons={[
            { icon: <LooksOneIcon />, value: 1 },
            { icon: <LooksTwoIcon />, value: 2 },
            { icon: <Looks3Icon />, value: 3 },
            { icon: <Looks4Icon />, value: 4 },
            { icon: <Looks6Icon />, value: 6 }
          ]}
          onChange={(value) => handleOptionFieldChange('fields.columns', value)}
        />
      ),
      tab: 'content'
    },
    ...(type !== 'testimonials'
      ? [
          {
            Component: (
              <ButtonSelectOption
                label={'Image Only'}
                value={imageOnly}
                buttons={[{ icon: <ImageIcon />, value: true }]}
                onChange={(value) => handleOptionFieldChange('fields.imageOnly', !imageOnly)}
              />
            ),
            tab: 'content'
          }
        ]
      : []),
    {
      Component: (
        <ButtonSelectOption
          label={'Use Carousel (Mobile)'}
          value={useCarousel}
          buttons={[{ icon: <ViewCarouselIcon />, value: true }]}
          onChange={(value) => handleOptionFieldChange('fields.useCarousel', !useCarousel)}
        />
      ),
      tab: 'content'
    },
    {
      Component: (
        <ButtonOption
          label={type === 'testimonials' ? 'Add Testimonial' : 'Add Card'}
          buttons={[{ icon: <AddIcon />, onClick: handleNewCard }]}
        />
      ),
      tab: 'content'
    }
  ]

  return options
}

export default CardsOptions
