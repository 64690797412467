import type { Stripe } from 'stripe'

const getPaymentMethod = (charge: Stripe.Charge | null): string => {
  if (!charge) return 'Credit Card'
  const details = charge.payment_method_details
  if (!details) {
    return 'Credit Card'
  }
  if (details[details.type]) {
    const last4 = details[details.type].last4
    const brand: string = details[details.type].brand || ''
    const formattedBrand = brand === 'visa' ? brand.toUpperCase() : brand.charAt(0).toUpperCase() + brand.slice(1)

    if (!!formattedBrand && !!last4) {
      return `${formattedBrand} - ${last4}`
    } else if (formattedBrand) {
      return formattedBrand
    }
  }
  return details?.type || ''
}

export default getPaymentMethod
