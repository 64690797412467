import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Grid, Typography, useClientOptions } from '@ui/stickybid'

import StatusStamp from '../StatusStamp'
import { useQuote } from '../../../context/useQuote'

import { getObjectLabels } from '@paintscout/util/builder'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'row-reverse'
    },
    titleContainer: {
      alignItems: 'flex-end'
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
    // quoteId: {
    //   color: theme.palette.secondary.main,
    //   marginLeft: theme.spacing(1)
    // }
  })

export interface QuoteStatusCardProps extends WithStyles<typeof styles> {
  workOrder?: boolean
}

function QuoteStatusCard({ classes, workOrder, ...props }: QuoteStatusCardProps) {
  const { quote } = useQuote()
  const { options } = useClientOptions()
  const id = quote.number && quote.number !== -1 ? `#${quote.number.toString()}` : ''

  const status = quote?.status?.value ?? 'draft'
  const hasStamp = ['accepted', 'declined', 'paid', 'partial', 'invoiced'].includes(status)

  const title = getTitle()

  return (
    <Grid container justifyContent="space-between" alignItems="center" classes={{ container: classes.root }}>
      <Grid item>
        <Grid container direction="column" justifyContent="center" classes={{ container: classes.titleContainer }}>
          <Grid item>
            <Typography variant="h1" className={classes.title}>
              <span>{title}</span>
              {/* {id && <span className={classes.quoteId}>{id}</span>} */}
            </Typography>
          </Grid>
          <Grid item>{hasStamp && <StatusStamp status={status} isEditable={false} />}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  function getTitle() {
    const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
    if (workOrder) {
      return objectLabels.workOrder.value
    } else {
      return objectLabels.quote.value
    }
  }
}

export default withStyles(styles)(QuoteStatusCard)
