import React, { useCallback, useEffect, useState } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import type { DropzoneProps } from 'react-dropzone'
import Spinner from '../../Spinner'
import type { StyleClasses } from '../../styles'

import type { CompressFileOptions } from '../util/compressFile'
import compressFile from '../util/compressFile'
import classnames from 'classnames'
import type { QuoteFile } from 'paintscout'

import Typography from '@material-ui/core/Typography'
import Grid from '../../Grid'
import { useCloudinary } from '../../CloudinaryProvider'
import { useUser } from '../../UserProvider'
import { uploadFile } from '../util'

export interface FileDropAreaProps extends Omit<CompressFileOptions, 'file'>, Omit<DropzoneProps, 'children'> {
  onUpload: (files: QuoteFile[]) => void
  onUploadStart?: () => void
  onPartialUpload?: ({ totalFiles, index }: { totalFiles: number; index: number }) => void
  onPartialUploadError?: ({ totalFiles, index }: { totalFiles: number; index: number }) => void
  children: React.ReactNode
  classes?: StyleClasses<typeof useStyles>
  docId?: string
  forceLoading?: boolean
  label?: string
  maxHeight?: CompressFileOptions['maxHeight']
  maxWidth?: CompressFileOptions['maxWidth']
  quality?: CompressFileOptions['quality']
}

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      root: {
        position: 'relative'
      },
      draggingFileOverlay: {
        display: 'none',

        border: '2px dashed #aaa',
        content: "' '",
        height: '100%',

        alignItems: 'center',
        justifyContent: 'center',

        width: '100%',
        position: 'absolute',
        borderRadius: '3px',
        zIndex: 50,
        background: '#ddd'
      },
      draggingFile: {
        position: 'relative',
        '& $draggingFileOverlay': {
          display: 'flex'
        },
        // the below is so FilesGrid deleteIcons dont overflow
        '& svg': {
          display: 'none'
        }
      },
      loadingFile: {
        position: 'relative',
        '& $draggingFileOverlay': {
          display: 'flex'
        },
        // the below is so FilesGrid deleteIcons dont overflow
        '& svg': {
          display: 'none'
        }
      }
    }
  },
  { name: 'FileDropArea' }
)

export default function FileDropArea({
  onUpload,
  onUploadStart,
  onPartialUpload,
  onPartialUploadError,
  children,
  docId,
  forceLoading,
  label,
  maxHeight,
  maxWidth,
  quality,
  ...otherProps
}: FileDropAreaProps) {
  const cloudinaryContext = useCloudinary()
  const { user } = useUser()
  const [loading, setLoading] = useState(false)
  const [filesComplete, setFilesComplete] = useState(0)
  const [filesErrored, setFilesErrored] = useState(0)
  const [totalFiles, setTotalFiles] = useState(0)

  useEffect(() => {
    if (forceLoading === true) setLoading(true)
    else setLoading(false)
  }, [forceLoading])

  const onDrop = useCallback(async (uploadFiles: File[]) => {
    if (onUploadStart) {
      onUploadStart()
    }

    setLoading(true)
    setTotalFiles(uploadFiles.length)

    const allUploadRes = []
    for (let i = 0; i < uploadFiles.length; i++) {
      try {
        const file = uploadFiles[i]
        const compressedFile = await compressFile({
          file,
          ...(maxHeight && { maxHeight }),
          ...(maxWidth && { maxWidth }),
          ...(quality && { quality })
        })

        const uploadedFile = await uploadFile({
          file: compressedFile,
          cloudName: cloudinaryContext.cloudName,
          uploadPreset: cloudinaryContext.uploadPreset,
          companyId: user?.app_metadata?.companyId,
          docId
        })

        if (onPartialUpload) {
          onPartialUpload({ totalFiles, index: i })
        }
        setFilesComplete(filesComplete + 1)

        allUploadRes.push(uploadedFile)
      } catch (err) {
        if (onPartialUploadError) {
          onPartialUploadError({ totalFiles, index: i })
        }
        setFilesErrored(filesErrored + 1)
        allUploadRes.push(err)
      }
    }

    setLoading(false)

    onUpload(allUploadRes)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    ...otherProps
  })

  const classes = useStyles(otherProps)

  return (
    <div
      className={classnames(
        classes.root,
        isDragActive ? classes.draggingFile : null,
        loading ? classes.loadingFile : null
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={classes.draggingFileOverlay}>
        <Grid container alignItems="center" justifyContent="center">
          {loading && (
            <Grid item container spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <Spinner size={10} />
              </Grid>
              <Grid item>
                <Typography variant="overline">
                  {!forceLoading ? `Uploading ${filesComplete}/${totalFiles}...` : 'Uploading...'}
                </Typography>
              </Grid>
            </Grid>
          )}
          {!loading && (
            <Grid item>
              <Typography variant="overline">{label ?? 'Drop Files to Add'}</Typography>
            </Grid>
          )}
        </Grid>
      </div>
      {children}
    </div>
  )
}
