import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { FormLabelProps as CoreFormLabelProps } from '@ui/core/FormLabel'
import CoreFormLabel from '@ui/core/FormLabel'

const useStyles = makeStyles<Theme, FormLabelProps>((theme) => ({
  root: {
    ...theme.typography.overline,
    color: theme.palette.common.black
  }
}))

export interface FormLabelProps extends CoreFormLabelProps {
  classes?: CoreFormLabelProps['classes'] & StyleClasses<typeof useStyles>
}

function FormLabel(props: FormLabelProps) {
  const classes = useStyles(props)
  return (
    <CoreFormLabel {...props} className={classes.root}>
      {props.children}
    </CoreFormLabel>
  )
}

export default FormLabel
