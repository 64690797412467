import React from 'react'

import Visibility from '@material-ui/icons/Visibility'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import classnames from 'classnames'

import { Select, MenuItem } from '@ui/stickybid'

import find from 'lodash/find'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.grey.A400,
      '& $disabled': {
        color: theme.palette.primary.main
      },
      '@media print': {
        display: 'none'
      }
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    menuItemText: {
      marginTop: 2
    },
    selectedItem: {
      '& svg': {
        marginRight: '0.5em',

        fontSize: '1.5em'
      },
      textTransform: 'capitalize',
      ...theme.typography.overline
    },
    disabled: {},
    select: {
      ...theme.typography.overline,
      color: theme.palette.grey[500],
      '&:focus': {
        background: 'none'
      }
    }
  })

export interface AreaSubstrateViewProps extends WithStyles<typeof styles> {
  view?: string
  disabled?: boolean
  onChange?: (event: any, view: string) => void
  options?: Array<{ value: string; label: string }>
}

class AreaSubstrateView extends React.PureComponent<AreaSubstrateViewProps> {
  public render() {
    const { view, disabled, classes } = this.props
    const options = this.props.options
      ? this.props.options
      : [
          {
            label: 'Area View',
            value: 'area'
          },
          {
            label: 'Substrate View',
            value: 'substrate'
          }
        ]

    const selectedOption = find(options, { value: view })
    const selectedLabel = selectedOption && selectedOption.label ? selectedOption.label : options[0].value

    return (
      <Select
        data-testid="area-substrate-view"
        classes={{ root: classes.root, select: classes.select }}
        value={view}
        onChange={this.handleChange}
        renderValue={(value: string) => (
          <div className={classnames(classes.menuItem, classes.selectedItem)}>
            <Visibility />
            <span className={classes.menuItemText}>{selectedLabel}</span>
          </div>
        )}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} classes={{ root: classes.menuItem }} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    )
  }

  public handleChange = (event: any) => {
    const { value: view } = event.target

    if (this.props.onChange) {
      this.props.onChange(event, view)
    }
  }
}

export default withStyles(styles)(AreaSubstrateView)
