/**
 * @module builder
 */
import type { UpdateableLineItem, QuoteItemSection } from '../../index'
import { uuid } from '../../../util'

export function createLineItem(args?: {
  quoteType?: string
  section?: QuoteItemSection
  quantity?: number
  calculate?: boolean
  calculateBy?: 'none' | 'hourlyRate' | 'quantity'
}): UpdateableLineItem {
  const section = args?.section ?? 'bid'
  const quoteType = args?.quoteType ?? ''
  const quantity = args?.quantity ?? 1
  const calculate = args?.calculate ?? false
  const calculateBy = args?.calculateBy ?? null

  return {
    key: uuid(),
    name: '',
    type: 'lineItem',
    label: '',
    description: '',
    crewNote: '',
    clientNote: '',
    hours: 0,
    price: 0,
    pricePerUnit: null,
    calculate: calculate,
    customHourlyRate: false,
    materials: [],
    files: [],
    quoteFiles: {},
    hourlyRate: 0,
    quoteType,
    section,
    totals: {
      materials: 0,
      afterMaterials: 0
    },
    // stickybid
    taxRate: '',
    showPrice: true,
    hideQuantity: false,
    active: true,
    ...(quantity && { quantity }),
    ...(calculateBy && { calculateBy })
  }
}
