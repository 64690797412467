import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { CardProps as CoreCardProps } from '@ui/core/Card'
import CoreCard from '@ui/core/Card'

const useStyles = makeStyles<Theme, CoreCardProps>((theme) => {
  return {
    root: {
      borderRadius: theme.borderRadius.md,
      boxShadow: 'none'
    }
  }
})

export interface CardProps extends CoreCardProps {}

function Card(props: CardProps) {
  const classes = useStyles(props)

  return <CoreCard {...props} classes={classes} />
}

export default Card
