import React from 'react'
import type { Theme } from '@material-ui/core'
import type { IconButtonProps as CoreIconButtonProps } from '@ui/core/IconButton'
import { default as CoreIconButton } from '@ui/core/IconButton'
import { makeStyles } from '@material-ui/core'
import { alpha } from '@material-ui/core'

export interface IconButtonProps extends CoreIconButtonProps {
  variant?: 'default' | 'contained' | 'outlined'
}

const useStyles = makeStyles<Theme, IconButtonProps>((theme) => ({
  root: (props) => ({
    ...(props.variant === 'outlined' && { border: `1px solid ${theme.palette.primary.main}` }),
    ...(props.variant === 'contained' && {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    }),
    '&:hover': {
      ...(props.variant === 'contained' && {
        backgroundColor: alpha(theme.palette.primary.main, 0.8)
      }),
      ...(props.variant !== 'contained' && {
        backgroundColor: alpha(theme.palette.primary.main, 0.04)
      })
    }
  })
}))

export default function IconButton(props: IconButtonProps) {
  const classes = useStyles(props)
  const color = props.variant === 'default' ? 'default' : 'primary'
  return <CoreIconButton color={color} {...props} classes={classes} />
}
