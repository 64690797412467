import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { WithClientOptions } from '@ui/stickybid'
import { FormSectionTitle, withClientOptions } from '@ui/stickybid'
import type { RenderableItem } from '@paintscout/util/builder'
import { getObjectLabels } from '@paintscout/util/builder'
import React from 'react'
import AreaSubstrateView from '../../../AreaSubstrateView'
import type { WithQuoteContext } from '../../../context/withQuoteContext'
import { withQuoteContext } from '../../../context/withQuoteContext'
import BulkActions from '../../BulkActions'
import ArchivedTable from './ArchivedTable'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    headerWrapper: {},
    archivedTableWrapper: {}
  })

export interface ArchivedItemsViewProps extends WithStyles<typeof styles>, WithQuoteContext, WithClientOptions {}

export interface ArchivedItemsViewState {
  selectedItems: {
    bid: RenderableItem[]
    options: RenderableItem[]
    additional: RenderableItem[]
    pending: RenderableItem[]
    archived: RenderableItem[]
  }
}

class ArchivedItemsView extends React.Component<ArchivedItemsViewProps, ArchivedItemsViewState> {
  public state: ArchivedItemsViewState = {
    selectedItems: {
      bid: [],
      options: [],
      additional: [],
      pending: [],
      archived: []
    }
  }

  public render() {
    const { classes, quoteContext } = this.props
    const { options } = this.props.clientOptions
    const { selectedItems } = this.state
    const { isEditable, tableView, quote } = quoteContext
    const objectLabels = getObjectLabels({ options })

    return (
      <>
        <div className={classes.root}>
          <div className={classes.archivedTableWrapper}>
            <FormSectionTitle
              title={'Archived'}
              variant="h2"
              subTitle={'These items will not show anywhere except here.'}
              rightContent={isEditable && <AreaSubstrateView view={tableView} onChange={this.handleTableViewChange} />}
            />
            <ArchivedTable
              selectedItems={this.getSelectedItems({ section: 'archived' })}
              onItemSelection={this.handleItemSelection({ section: 'archived' })}
            />
          </div>
        </div>
        {isEditable && (
          <BulkActions
            options={options}
            quote={quote}
            tableView={tableView}
            getSelectedItems={this.getSelectedItems}
            getSelectedItemsByType={this.getSelectedItemsByType}
            onBulkActionClick={this.handleBulkActionClick}
            onDeselectAll={this.handleDeselectAll}
          />
        )}
      </>
    )
  }

  public getSelectedItems = (args?: { section?: 'bid' | 'options' | 'additional' | 'pending' | 'archived' }) => {
    const section = args && args.section ? args.section : null
    const { selectedItems } = this.state
    const { bid, options, additional, pending, archived } = selectedItems

    if (section) {
      return selectedItems[section]
    }

    return [...bid, ...options, ...additional, ...pending, ...archived]
  }

  /**
   * Gets the selected items by type
   */
  public getSelectedItemsByType = (type: string) => {
    const { selectedItems } = this.state
    const { bid, options, additional, pending, archived } = selectedItems
    const items = [...bid, ...options, ...additional, ...pending, ...archived]

    return items.filter((item) => item.type === type)
  }

  public handleItemActionClick = (ev: any, action: string, key: string) => {
    const { onEditItem, onItemAction } = this.props.quoteContext
    if (action === 'edit') {
      onEditItem(key)
    } else {
      onItemAction(action, 'archived', [key])
    }
  }

  public handleBulkActionClick = (action: string) => (selectedItems: RenderableItem[]) => {
    this.props.quoteContext.onItemAction(
      action,
      'archived',
      selectedItems.map((item) => item.key)
    )
    this.setState({
      selectedItems: {
        bid: [],
        options: [],
        additional: [],
        pending: [],
        archived: []
      }
    })
  }

  public handleItemSelection =
    (args: { section: 'bid' | 'options' | 'additional' | 'pending' | 'archived' }) =>
    (event: React.ChangeEvent<HTMLElement>, selectedItems: RenderableItem[]) => {
      this.setState({
        selectedItems: {
          ...this.state.selectedItems,
          [args.section]: selectedItems
        }
      })
    }

  public handleDeselectAll = () => {
    this.setState({
      selectedItems: {
        bid: [],
        options: [],
        additional: [],
        pending: [],
        archived: []
      }
    })
  }

  public handleTableViewChange = (event: any, view: string) => {
    if (this.props.quoteContext.onTableViewChange) {
      this.props.quoteContext.onTableViewChange(view)
    }
  }
}

export default withStyles(styles)(withQuoteContext(withClientOptions(ArchivedItemsView)))
