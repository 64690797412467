/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument, QuoteOptions } from 'paintscout'
import type { QuoteItemSection, QuoteItemConsumer, RenderableItem, RenderableSubItem } from '../../../index'

import { getQuoteOptions, filterItem } from '../../../util'
import { trimGroup } from '../../../groups'
import { trimLineItem } from '../../../line-items'
import { trimSubstrate } from '../../../substrates'
import { getItemOrder, filterOrderItems } from '../util'
import getSubstrateAreas from '../util/get-substrate-areas'

/**
 * Get Bid, Options, or Archived list of substrates for a Quote.
 */
export function getItemsBySubstrate(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  showLineItems?: boolean
  showValues?: boolean
}): RenderableItem[] {
  const { quote, options, overrideOptions, section, consumer, keys } = args
  const showLineItems = args.showLineItems === false ? false : true
  const showValues = args.showValues === false ? false : true

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const substrateOrder = getItemOrder('substrate', section, quote)

  const items = substrateOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys })) {
        return null
      }

      if (orderItem.type === 'substrate') {
        const substrate = quote.substrates[orderItem.key]

        // Get areas substrate is in
        const substrateAreas = getSubstrateAreas({
          quote,
          options: quoteOptions,
          clientOptions: options,
          substrateKey: orderItem.key,
          consumer,
          section,
          showValues
        })

        if (!substrate || !substrateAreas.length) {
          return null
        }

        // Get total of substrateAreas total
        const subItemTotal = substrateAreas.reduce((acc: number, substrateArea: RenderableSubItem) => {
          return acc + Number(substrateArea.value)
        }, 0)

        // Clean substrate for display
        const trimmedSubstrate = trimSubstrate({
          quote,
          item: { ...orderItem, ...substrate },
          subItems: [],
          options: quoteOptions,
          consumer,
          clientOptions: options,
          section
        })

        // Return correct substrate value for section
        if (filterItem(substrate, section) && section === 'bid') {
          return {
            ...trimmedSubstrate,
            subItems: [],
            value: substrate.totals ? substrate.totals.price : null
          }
        } else {
          return {
            ...trimmedSubstrate,
            subItems: [],
            value: subItemTotal
          }
        }
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems[orderItem.key]
        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer) as RenderableItem
        }
      } else if (orderItem.type === 'group') {
        const group = quote.groups[orderItem.key]
        if (group && filterItem(group, section)) {
          return trimGroup({ ...orderItem, ...group }, options, quote)
        }
      }

      return null
    })
    .filter((item) => item)

  return items
}
