import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const ButtonIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 16 8'} {...props}>
    <svg>
      <path d="M4.101 1.14893C2.56013 1.14893 1.311 2.39805 1.311 3.93893C1.311 5.4798 2.56013 6.72893 4.101 6.72893H11.254C12.7949 6.72893 14.044 5.4798 14.044 3.93893C14.044 2.39805 12.7949 1.14893 11.254 1.14893H4.101ZM0.311005 3.93893C0.311005 1.84577 2.00785 0.148926 4.101 0.148926H11.254C13.3472 0.148926 15.044 1.84577 15.044 3.93893C15.044 6.03208 13.3472 7.72893 11.254 7.72893H4.101C2.00785 7.72893 0.311005 6.03208 0.311005 3.93893Z" />
      <path d="M4.04904 3.93213C4.04904 3.65599 4.2729 3.43213 4.54904 3.43213H10.807C11.0832 3.43213 11.307 3.65599 11.307 3.93213C11.307 4.20827 11.0832 4.43213 10.807 4.43213H4.54904C4.2729 4.43213 4.04904 4.20827 4.04904 3.93213Z" />
    </svg>
  </SvgIcon>
)
export default ButtonIcon
