import * as React from 'react'
import type { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup'
import { default as MuiRadioGroup } from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Radio from '@material-ui/core/Radio'
// import Typography from '@material-ui/core/Typography'
import Radio from '../../../Radio'
import Typography from '../../../Typography'

export interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'classes'> {
  children?: React.ReactNode
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

/**
 * Renders a Input component from material-ui but provides extra props for adding an icon
 */
class DateRadio extends React.PureComponent<RadioGroupProps> {
  public dateOptions = [{ dateValue: 'MM/DD/YYYY' }, { dateValue: 'DD/MM/YYYY' }, { dateValue: 'YYYY-MM-DD' }]

  /**
   * Render the material-ui Input with the added icon
   */
  public render() {
    const { value, onChange, ...other } = this.props
    const radioLabel = (value: string) => {
      return <Typography variant={'h3'}>{value}</Typography>
    }

    return (
      <MuiRadioGroup onChange={onChange} value={value} {...other}>
        {this.dateOptions.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option.dateValue}
              control={<Radio />}
              label={radioLabel(option.dateValue)}
            />
          )
        })}
      </MuiRadioGroup>
    )
  }
}

export default DateRadio
