import React, { useContext } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { ButtonPopupMenu, DialogStackContext } from '@ui/stickybid'
import { AddButton } from '@ui/stickybid/src/ButtonPopupMenu'
import CropPortraitIcon from '@material-ui/icons/CropPortrait'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined'
import PhotoAlbumOutlinedIcon from '@material-ui/icons/PhotoAlbumOutlined'
import {
  ButtonIcon,
  DescriptionIcon,
  DividerIcon,
  ImageSmallIcon,
  // SocialLinksIcon,
  SpacerIcon,
  ThreeColIcon,
  TwoColIcon,
  QuoteIcon
} from '@ui/core/icons'
import FormatQuoteIcon from '@material-ui/icons/FormatQuoteOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import { PresentationPageSelectDialog } from '../dialogs'
import type { PresentationIndustry, PresentationPage, PresentationStyle } from 'paintscout'
import type { LabelsOption } from '@paintscout/util/builder'
import isEqual from 'lodash/isEqual'

interface AddSectionButtonProps {
  addPages: boolean
  classes?: StyleClasses<typeof useStyles>
  focused?: boolean
  // little sketch using id here instead of ref
  // TODO over to ref if need more than one of these with an id on a page
  id?: string
  objectLabels: LabelsOption
  onActionClick: any
  onAddPage: (newPage: PresentationPage) => void
  presentationIndustry: PresentationIndustry
  presentationStyle: PresentationStyle
}

const useStyles = makeStyles<Theme, AddSectionButtonProps>(
  (theme: Theme) => {
    return {
      root: (props) => ({
        textAlign: 'center',
        position: 'relative',
        height: 0,
        display: 'flex',
        zIndex: props.focused ? 3 : 2,
        alignItems: 'center',
        justifyContent: 'center'
      }),
      menu: {
        boxShadow: theme.boxShadow[1]
      },
      button: (props) => ({
        color: theme.palette.presentation.sectionBorder.hover,
        borderColor: theme.palette.presentation.sectionBorder.hover,
        borderWidth: '2px',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#f5f5f5'
        },
        ...(props.focused && {
          color: theme.palette.presentation.sectionBorder.focus,
          borderColor: theme.palette.presentation.sectionBorder.focus
        })
      }),
      rule: {}
    }
  },
  { name: 'AddSectionButton' }
)

function AddSectionButton(props: AddSectionButtonProps) {
  const classes = useStyles(props)
  const { addPages, id, objectLabels, onActionClick, onAddPage, presentationIndustry, presentationStyle } = props
  const actions = [
    {
      key: 'logo',
      label: 'Logo',
      icon: AccountCircleOutlinedIcon
    },
    {
      key: 'banner',
      label: 'Banner',
      icon: PhotoAlbumOutlinedIcon
    },
    {
      key: 'text',
      label: 'Text',
      icon: DescriptionIcon
    },
    {
      key: 'image',
      label: 'Media',
      icon: ImageSmallIcon
    },
    {
      key: '2-column',
      label: '2 Column',
      icon: TwoColIcon
    },
    {
      key: '3-column',
      label: '3 Column',
      icon: ThreeColIcon
    },
    {
      key: 'cards',
      label: 'Cards',
      icon: CropPortraitIcon
    },
    {
      key: 'gallery',
      label: 'Gallery',
      icon: PhotoLibraryOutlinedIcon
    },
    {
      key: 'testimonials',
      label: 'Testimonials',
      icon: FormatQuoteIcon
    },
    {
      key: 'button',
      label: 'Button',
      icon: ButtonIcon
    },
    // {
    //   key: 'social-links',
    //   label: 'Social Links',
    //   icon: SocialLinksIcon
    // },
    {
      key: 'divider',
      label: 'Divider',
      icon: DividerIcon
    },
    {
      key: 'spacer',
      label: 'Spacer',
      icon: SpacerIcon
    },
    {
      key: 'quote',
      label: objectLabels.quote.value,
      icon: QuoteIcon
    },
    {
      key: 'terms',
      label: 'Terms',
      icon: MenuIcon
    }
  ]

  const { openDialog, dismissDialog } = useContext(DialogStackContext)

  const handleAddPage = (ev) => {
    ev.stopPropagation()
    openDialog(PresentationPageSelectDialog, {
      onConfirm: (page: PresentationPage) => {
        onAddPage(page)
        dismissDialog()
      },
      presentationStyle: presentationStyle,
      presentationIndustry: presentationIndustry,
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  if (addPages) {
    return (
      <div className={classes.root}>
        <div className={classes.rule}></div>
        <AddButton
          buttonSize={'small'}
          id={id}
          onClick={handleAddPage}
          className={classes.button}
          buttonVariant={'outlined'}
        />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.rule}></div>
      <ButtonPopupMenu
        actions={actions}
        buttonSize={'small'}
        buttonVariant={'outlined'}
        classes={{ button: classes.button, menu: classes.menu }}
        columnCount={3}
        id={id}
        onActionClick={onActionClick}
        tooltipTitle={'Add Section'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      />
    </div>
  )
}

export default React.memo(AddSectionButton, (prevProps, nextProps) => {
  // keep this up to date with any new props
  if (prevProps.focused !== nextProps.focused) return false
  if (prevProps.presentationIndustry !== nextProps.presentationIndustry) return false
  if (!isEqual(prevProps.presentationStyle, nextProps.presentationStyle)) return false
  if (!isEqual(prevProps.classes, nextProps.classes)) return false
  if (!isEqual(prevProps.objectLabels, nextProps.objectLabels)) return false

  return true
})
