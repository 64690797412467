import React from 'react'
import Color from 'color'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import compareSectionProps from '../../util/compareSectionProps'
import { usePresentationNav } from '../../PresentationNav/PresentationNavContext'
import AnimationWrapper from '../../util/AnimationWrapper'
import classnames from 'classnames'
import { Button } from '@ui/stickybid'

export interface ButtonSectionProps extends PresentationSectionProps {
  cartLink?: boolean
}

const useStyles = makeStyles<Theme, ButtonSectionProps>(
  (theme: Theme) => {
    return {
      root: (props) => ({
        textAlign: props.section?.fields?.horizontalAlignInContainer
      }),
      button: (props) => {
        let padding = theme.spacing('10px', 3)
        let fontSize = '0.8em'
        switch (props.section?.fields?.buttonSize) {
          case 'sm':
            break
          case 'md':
            padding = theme.spacing('14px', 4)
            fontSize = '0.85em'
            break
          case 'lg':
            padding = theme.spacing(2, 5)
            fontSize = '0.9em'
            break
          case 'xl':
            padding = theme.spacing(2.5, 6.5)
            fontSize = '1em'
            break
          default:
            break
        }

        return {
          fontSize: fontSize,
          padding: padding,
          textDecoration: 'none !important'
        }
      },
      buttonCustom: (props) => {
        return {
          '&$buttonCustom': {
            color: props.section?.fields?.color
              ? Color(props.section?.fields?.color).isLight()
                ? '#000'
                : '#FFF'
              : null,
            backgroundColor: `${props.section?.fields?.color} !important` ?? null,
            '&:hover': {
              backgroundColor: `${props.section?.fields?.color} !important` ?? null,
              opacity: 0.8
            }
          }
        }
      },
      buttonWrapper: {
        textDecoration: 'none !important' as any
      }
    }
  },
  { name: 'ButtonSection' }
)

function ButtonSection(props: ButtonSectionProps) {
  const { cartLink } = props

  const classes = useStyles(props)
  const { section, WrapperComponent, isEditable, isSettings } = props
  const { fields = {} } = section
  const { animation } = fields
  const { onOpenCart } = usePresentationNav()
  const href = fields?.href?.search(/^http[s]?\:\/\//) == -1 ? `//${fields.href}` : fields.href

  return (
    <WrapperComponent {...props}>
      <AnimationWrapper animation={animation} index={0} direction={'top'}>
        <div className={classes.root}>
          {(isEditable || !isSettings) && !cartLink && (
            <a href={href} className={classes.buttonWrapper} target={'_blank'} rel="noreferrer">
              <Button
                prominence={1}
                className={classnames(classes.button, 'themeButton', {
                  [classes.buttonCustom]: props.section?.fields?.color
                })}
              >
                {fields.label}
              </Button>
            </a>
          )}
          {!isEditable && isSettings && !cartLink && (
            <Button
              prominence={1}
              className={classnames(classes.button, 'themeButton', {
                [classes.buttonCustom]: props.section?.fields?.color
              })}
            >
              {fields.label}
            </Button>
          )}
          {cartLink && (
            <Button
              prominence={1}
              onClick={isSettings ? null : onOpenCart}
              className={classnames(classes.button, 'themeButton', {
                [classes.buttonCustom]: props.section?.fields?.color
              })}
            >
              {fields.label}
            </Button>
          )}
        </div>
      </AnimationWrapper>
    </WrapperComponent>
  )
}

export default React.memo(ButtonSection, (prevProps, nextProps) => {
  return compareSectionProps(prevProps, nextProps)
})
