import type { AreaSubstrate, QuoteDocument, QuoteOptions, QuoteArea, OptionsDocument, OrderItem } from 'paintscout'
import type { RenderableSubItem } from '../..'
import { formatAreaSubstrate } from './format-area-substrate'
import type { QuoteItemSection, QuoteItemConsumer, QuoteItemView } from '../../..'
import { getAreaSubstrate } from '../../../area-substrates'
import type { UpdateableAreaSubstrate } from '../../../area-substrates'
import { toRenderableItemSubstrate } from '../../../area-substrates/util'
import type { UpdateableArea } from '../../../areas'
import { toRenderableCrewAreaSubstrate } from '../get-crew-items-by-area/util'
import toRenderableCrewSubstrateArea from '../get-crew-items-by-substrate/util/to-renderable-crew-substrate-area'

// Correctly format a substrate area based on consumer and view
export const formatSubstrateArea = ({
  areaSubstrate,
  quote,
  areaItem,
  options,
  area,
  clientOptions,
  substrateOrderItem,
  key,
  section,
  showValues,
  consumer,
  view
}: {
  areaSubstrate: AreaSubstrate
  quote: QuoteDocument
  areaItem: UpdateableArea
  options: QuoteOptions
  area: QuoteArea
  clientOptions: OptionsDocument
  substrateOrderItem: OrderItem
  key: string
  section: QuoteItemSection
  showValues: boolean
  consumer?: QuoteItemConsumer
  view?: QuoteItemView
}): RenderableSubItem => {
  const renderableItemSubstrate = toRenderableItemSubstrate({
    areaSubstrate,
    options: quote.options,
    quote,
    area: areaItem
  })

  if (consumer === 'crew') {
    if (view == 'substrate') {
      const updateableAreaSubstrate = getAreaSubstrate({
        quote,
        options: clientOptions,
        areaKey: key,
        key: substrateOrderItem.key,
        full: false
      }) as UpdateableAreaSubstrate

      if (!updateableAreaSubstrate) {
        return null
      }

      return toRenderableCrewSubstrateArea({
        key: areaSubstrate.key,
        area,
        item: updateableAreaSubstrate,
        showValues,
        options
      })
    } else {
      return toRenderableCrewAreaSubstrate({
        key: areaSubstrate.key,
        quote,
        areaSubstrate: renderableItemSubstrate,
        area,
        showValues
      })
    }
  } else {
    if (renderableItemSubstrate.inAreaGroup || renderableItemSubstrate.workOrderOnly) {
      return null
    }
    return formatAreaSubstrate({
      key: areaSubstrate.key,
      areaKey: area.key,
      item: renderableItemSubstrate,
      options,
      section
    })
  }
}
