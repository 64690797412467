import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Button } from '@ui/stickybid'
import { usePresentationNav } from '../PresentationNav'

const useStyles = makeStyles<Theme, AcceptInvoiceOptionsButtonProps>(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.status.accepted,
      color: 'black',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
        color: 'white'
      }
    }
  }),
  { name: 'AcceptInvoiceOptionsButton' }
)

export interface AcceptInvoiceOptionsButtonProps {
  classes?: StyleClasses<typeof useStyles>
  selectedOptionalItems: string[]
}

export function AcceptInvoiceOptionsButton(props: AcceptInvoiceOptionsButtonProps) {
  const classes = useStyles(props)
  const { selectedOptionalItems } = props
  const { onAcceptClick } = usePresentationNav()

  const labelCount = selectedOptionalItems.length || 1 // default to 1 for the label so it doesn't snap to 0 on fade out
  const labelSuffix = labelCount === 1 ? 'Option' : 'Options'

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onAcceptClick(ev, selectedOptionalItems)
  }

  return (
    <Button className={classes.root} prominence={1} onClick={handleClick}>
      {`Accept ${labelCount} ${labelSuffix}`}
    </Button>
  )
}
