import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { ValueFilter } from 'json-to-lucene'
import useRouter from 'use-react-router'
import isEqual from 'lodash/isEqual'
import { SEARCH_CLIENTS } from '../../graphql/queries/SEARCH_CLIENTS'
import type { Location } from 'history'
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback'
import { getUrlQuery } from '../../util/getUrlQuery'
import { SearchBar } from '@ui/stickybid'
import ClientSearchFilters from './ClientSearchFilters'
import { useQuery } from '@apollo/react-hooks'
import { setUrlQuery } from 'src/util/setUrlQuery'
import ClientFilterChip from './ClientFilterChip'

export interface UrlQuery {
  search?: string
  page?: number
  filters: ValueFilter[]
}

export interface ClientSearchBarProps {
  classes?: StyleClasses<typeof useStyles>
  loading?: boolean
  filters?: ValueFilter[]
  fullWidth?: boolean
  onSearch?: (query: string, filters: ValueFilter[], isInitialSearch?: boolean) => any
  showChips?: boolean
}

const useStyles = makeStyles<Theme>({
  root: {},
  clientSearchRoot: {}
})

function ClientSearchBar({ loading, onSearch, showChips, ...props }: ClientSearchBarProps) {
  const classes = useStyles(props)
  const { location } = useRouter()
  const [queryText, setQueryText] = useState(getUrlQuery<UrlQuery>({ location }).search)
  const placeholder = `Search...`

  const { data: clientsData } = useQuery(SEARCH_CLIENTS, {
    variables: {
      query: '*:*'
    }
  })

  const clients = clientsData?.searchClients?.rows ?? []

  // the committed/current filters for the results
  const [filters, setFilters] = useState(props.filters ?? (getFiltersFromLocation(location) as any))

  // filters state before the user commits them by hitting search
  const [uncommittedFilters, setUncommittedFilters] = useState(filters)

  const filtersActive = filters.length > 0

  // trigger search when props.filters changes
  useEffect(() => {
    if (!isEqual(filters, props.filters)) {
      handleSearch({ filters: props.filters })
    }
    // eslint-disable-next-line
  }, [props.filters])

  // trigger search on mount
  useEffect(() => {
    handleSearch({ filters, query: queryText, isInitialSearch: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (args: { query?: string; filters?: ValueFilter[]; isInitialSearch?: boolean }) => {
    const { query = queryText, isInitialSearch } = args

    // the filter values (`filters` variable from state is in scope, so we'll call it values)
    const values = [...(args.filters ?? uncommittedFilters)]

    // update filter states
    setFilters(values)
    setUncommittedFilters(values)

    // update the typed-in user text
    setQueryText(query)
    // remove filters with null or undefined values
    const sanitizedValues = values.filter((filter) => {
      // != operator rather than !== is intentional - it checks for null or undefined - update to catch empty arrays
      return (
        filter.value != null && (!Array.isArray(filter.value) || (Array.isArray(filter.value) && filter.value.length))
      )
    })

    setUrlQuery({
      location,
      params: { search: query || null, filters: sanitizedValues },
      replaceParams: true
    })

    onSearch(query, sanitizedValues, isInitialSearch)
  }

  const debouncedSearch = useDebouncedCallback(handleSearch, 200)

  const handleFilterClear = () => {
    setUncommittedFilters([])
  }

  function deleteFilter(id: string, valueIndex?: number) {
    // Remove one of many status filers
    if (valueIndex >= 0 && id === 'plan') {
      handleSearch({
        filters: filters.map((filter) => {
          if (filter.id === id) {
            const newValues = (filter?.value as any).filter((value, index) => index !== valueIndex)
            return {
              ...filter,
              value: newValues
            }
          } else {
            return filter
          }
        })
      })
    } else {
      // Remove the only filter of type
      handleSearch({
        filters: filters.filter((filter) => {
          return filter.id !== id
        })
      })
    }
  }

  return (
    <>
      <div className={classes.clientSearchRoot}>
        <SearchBar
          inputProps={{
            id: 'client-search',
            'aria-label': 'Search Clients',
            // recommended aria for loading indicator (also used in e2e tests)
            'aria-busy': loading,
            'aria-live': loading ? 'polite' : undefined
          }}
          defaultValue={queryText}
          placeholder={placeholder}
          onChange={(q) => debouncedSearch({ query: q })}
          onClear={handleFilterClear}
          onSearch={(q) => handleSearch({ query: q })}
          filterContent={<ClientSearchFilters filters={uncommittedFilters} onChange={setUncommittedFilters} />}
          filtersActive={filtersActive}
          {...props}
        />
      </div>
      {showChips && !!filters.length && (
        <div className={classes.filterChips}>
          {filters.map((filter) => {
            return (
              <ClientFilterChip
                key={filter.id}
                filter={filter}
                clients={clients}
                onDelete={(filter, valueIndex) => deleteFilter(filter.id, valueIndex)}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export function getFiltersFromLocation(location: Location): ValueFilter[] {
  const values = getUrlQuery<UrlQuery>({ location })

  if ((values as UrlQuery).filters) {
    return (values as UrlQuery).filters
  }

  return []
}

export default ClientSearchBar
