import React from 'react'
import Typography from '../../Typography'
import type { Photo as PexelsPhoto } from 'pexels'
import type { RenderPhotoProps } from 'react-photo-album'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles<Theme, PhotoOptionProps>(
  (theme) => ({
    root: {
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        '& $attribution': {
          opacity: 1
        }
      }
    },
    thumbnail: {
      verticalAlign: 'middle',
      width: '100%'
    },
    thumbnailWrapper: {
      position: 'relative',
      '&::after': {
        opacity: 0,
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 50%)',
        transition: 'opacity 0.3s ease-in'
      },
      '&:hover::after': {
        opacity: 1
      }
    },
    attribution: {
      opacity: 0,
      transition: 'opacity 0.3s ease-in',
      lineHeight: 1,
      position: 'absolute',
      bottom: theme.spacing(1.5),
      left: theme.spacing(1.5),
      zIndex: 2,
      '& a': {
        color: theme.palette.common.white,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    }
  }),
  { name: 'PhotoOption' }
)

export interface AlbumPhoto extends Omit<PexelsPhoto, 'src'> {
  altSrc: PexelsPhoto['src']
  src: string
  onClick: () => void
}
export interface PhotoOptionProps {
  classes?: StyleClasses<typeof useStyles>
  photo: AlbumPhoto
  wrapperStyle: RenderPhotoProps['wrapperStyle']
  renderDefaultPhoto: RenderPhotoProps['renderDefaultPhoto']
}

function PhotoOption(props: PhotoOptionProps) {
  const classes = useStyles(props)
  const { photo, renderDefaultPhoto, wrapperStyle } = props
  const { onClick, photographer, photographer_url } = photo

  return (
    <div className={classes.root} style={wrapperStyle} onClick={onClick}>
      <div className={classes.thumbnailWrapper}>
        {renderDefaultPhoto({ wrapped: true })}
        <Typography className={classes.attribution} variant={'body1'}>
          <a onClick={(ev) => ev.stopPropagation()} href={photographer_url} target="_blank" rel="noopener noreferrer">
            {photographer}
          </a>
        </Typography>
      </div>
    </div>
  )
}

export default PhotoOption
