import type { QuoteFile, PresentationLibrary } from 'paintscout'

export const removeFileFromLibrary = (args: { file: QuoteFile; library: PresentationLibrary }) => {
  const { file, library } = args
  const key = file.cloudinaryPublicId // We use cloudinaryPublicId as the key since we don't want the same picture in the library twice. The actual key is unique to each instance of the picture
  if (!key) {
    console.log('Missing Key: Library is for cloudinary items only')
    return null
  }

  let updatedValues = { ...library.values }
  delete updatedValues[key]

  const updatedLibrary: PresentationLibrary = {
    values: updatedValues,
    order: library.order.filter((orderKey) => orderKey !== key)
  }
  return updatedLibrary
}
