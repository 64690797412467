/**
 * @module builder
 */
import type { MoveableItem, QuoteItemSection } from '../../index'
import { moveToBid, moveToOptions, moveToArchived, moveToAdditional, moveToPending } from './index'

export function moveTo(item: MoveableItem, section: QuoteItemSection): any {
  if (section === 'bid') {
    return moveToBid(item)
  } else if (section === 'options') {
    return moveToOptions(item)
  } else if (section === 'archived') {
    return moveToArchived(item)
  } else if (section === 'additional') {
    return moveToAdditional(item)
  } else if (section === 'pending') {
    return moveToPending(item)
  } else {
    return item
  }
}
