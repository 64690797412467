/**
 * @module builder
 */
import type { QuoteDocument, AreaSubstrate, OptionsDocument } from 'paintscout'
import type { UpdateableAreaSubstrate } from '../index'

import findIndex from 'lodash/findIndex'
import { toUpdateableAreaSubstrate } from './util'

// Return the 'full' AreaSubstrate, right from quote,
// or translate to updateableAreaSubstrate, if exists
export function getAreaSubstrate(args: {
  quote?: QuoteDocument
  options: OptionsDocument
  areaKey?: string
  key?: string
  full?: boolean
}): UpdateableAreaSubstrate | AreaSubstrate {
  const { quote, areaKey, key, full, options } = args
  let r: UpdateableAreaSubstrate | AreaSubstrate

  Object.keys(quote.areas[areaKey].substrates).forEach((section) => {
    const index = findIndex(quote.areas[areaKey].substrates[section], { key })

    if (index !== -1) {
      const areaSubstrate = quote.areas[areaKey].substrates[section][index]
      if (areaSubstrate) {
        r = full
          ? (areaSubstrate as AreaSubstrate)
          : (toUpdateableAreaSubstrate({
              areaSubstrate,
              options,
              areaKey,
              substrateKey: key,
              quote
            }) as UpdateableAreaSubstrate)
      }
      // return either full areaSubstrate, or convert to UpdateableAreaSubstrate
    }
  })

  return r
}
