import React from 'react'
import { makeStyles, Drawer } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Button, IconButton, Typography, useClientOptions } from '@ui/stickybid'
import CloseIcon from '@material-ui/icons/Close'
import PresentationAcceptButton from '../PresentationAcceptButton'
import Quote from '../../../Quote/Quote'
import { usePresentationNav } from '../PresentationNavContext'
import { getObjectLabels, formatCurrency } from '@paintscout/util/builder'

import { useQuote } from '../../../context/useQuote'

const useStyles = makeStyles<Theme, PresentationQuoteDrawerProps>(
  (theme) => ({
    paper: {
      boxSizing: 'border-box',
      width: '90%',
      boxShadow: 'none',
      maxWidth: 1014,
      [theme.breakpoints.down('sm')]: {
        width: '95%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      ['@media print']: {
        display: 'none'
      }
    },
    modal: {
      ['@media print']: {
        display: 'none'
      }
    },
    drawerHeader: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      boxSizing: 'inherit',
      display: 'flex',
      height: 61,
      justifyContent: 'space-between',
      padding: theme.spacing(1, 4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1.5)
      }
    },
    drawerContent: {
      background: theme.palette.grey[100],
      flexGrow: 1,
      padding: theme.spacing(4),
      overflowY: 'auto',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5)
      }
    },
    drawerFooter: {
      padding: theme.spacing(2, 4),
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(1.25, 1.5, 1)
      }
    },
    totalBlock: {
      marginLeft: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1)
      }
    },
    totalLabel: {
      marginBottom: theme.spacing(1)
    },
    total: {
      lineHeight: 1,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.1rem'
      }
    },
    acceptGridRoot: {
      justifyContent: 'flex-start',
      alignSelf: 'flex-end',
      padding: 0,
      flexGrow: 1
    },
    cartButtonMobile: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    cartButtonTablet: {
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  }),
  { name: 'PresentationQuoteDrawer' }
)

export interface PresentationQuoteDrawerProps {
  classes?: StyleClasses<typeof useStyles>
}

function PresentationQuoteDrawer(props: PresentationQuoteDrawerProps) {
  const classes = useStyles(props)
  const { quote } = useQuote()
  const { cartOpen, onOpenCart, onCloseCart, isPreviewAccept } = usePresentationNav()
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const isImported = !!quote.importedContent?.primary

  // this helps react-pdf (imported quotes only) unmount cleaner when the drawer is closed
  const renderQuoteInDrawer = cartOpen || !isImported

  return (
    <>
      <Button className={classes.cartButtonTablet} onClick={onOpenCart} edge={'end'} prominence={2}>
        {`View ${objectLabels.quote.value}`}
      </Button>
      <Button className={classes.cartButtonMobile} onClick={onOpenCart} edge={'end'} prominence={3}>
        {objectLabels.quote.value}
      </Button>
      <Drawer
        classes={{ paper: classes.paper, modal: classes.modal }}
        open={cartOpen}
        onClose={onCloseCart}
        anchor={'right'}
        disablePortal
      >
        <div className={classes.drawerHeader}>
          <Typography variant={'h2'}>{objectLabels.quote.value}</Typography>
          <IconButton onClick={onCloseCart} edge={'end'}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.drawerContent}>{renderQuoteInDrawer && <Quote inPresentation />}</div>
        <div className={classes.drawerFooter}>
          <PresentationAcceptButton classes={{ acceptGridRoot: classes.acceptGridRoot }} />
          <div className={classes.totalBlock}>
            <Typography variant={'h3'} className={classes.totalLabel}>
              Total
            </Typography>
            <Typography className={classes.total} variant={'h2'}>{`${formatCurrency({
              options,
              value: quote.totals.after_tax
            })}`}</Typography>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default PresentationQuoteDrawer
