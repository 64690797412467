import React from 'react'
import { QuoteContext } from '../../context/QuoteContext'
import { ClientOptionsContext, Collapse, ViewMediaDialog } from '@ui/stickybid'
import type { QuoteFile } from 'paintscout'
import type { QuoteItemSection, QuoteItemConsumer } from '@paintscout/util/builder'
import { getItem, getItems, getFilesForItem, updateFileOrderForItem, getQuoteOptions } from '@paintscout/util/builder'
import map from 'lodash/map'
import { Grid } from '@material-ui/core'
import FileSection from './FileSection'
import { DialogStackContext } from '@ui/stickybid'

export interface FilesByItemProps {
  /**
   * Renders if there are any files or if the quote is being edited
   */
  FormSectionTitle?: React.ReactNode

  sections: QuoteItemSection[]
  consumer: QuoteItemConsumer

  WrapperComponent?: React.StatelessComponent
}

/**
 * Shows the files for section grouped by item if there are any
 */
export default function FilesByItem(props: FilesByItemProps) {
  const { quote, isEditable, updateQuote, tableView, onEditItemFiles } = React.useContext(QuoteContext)
  const { options } = React.useContext(ClientOptionsContext)
  const { openDialog, dismissDialog } = React.useContext(DialogStackContext)
  const { consumer, sections, WrapperComponent } = props

  const quoteOptions = getQuoteOptions({
    quote,
    options
  })

  function handleReorder(files: QuoteFile[], itemKey: string) {
    updateQuote({
      quote: updateFileOrderForItem({ quote, options, itemKey, order: files.map((file) => file.key) }),
      autosave: true
    })
  }

  function handleFileClick(itemKey: string, index: number) {
    if (isEditable && onEditItemFiles) {
      onEditItemFiles({
        itemKey,
        index
      })
    } else {
      openDialog(ViewMediaDialog, {
        files: getFilesForItem({ quote, options, itemKey }).filter(filterVisibleFiles),
        index,
        onClose: dismissDialog
      })
    }
  }

  function filterVisibleFiles(item) {
    const { visibility } = item

    if (visibility === 'visible') {
      return true
    }

    if (visibility === 'hidden') {
      return false
    }

    return visibility === consumer
  }

  // const view = tableView
  const view = 'area' // we're always going to show the area-view of files for now...
  const allItems = sections.reduce(
    (items, section) => [...items, ...getItems({ quote, consumer: 'customer', section, view })],
    []
  )

  const filesByItem = allItems.reduce((files, item) => {
    const itemFiles = getFilesForItem({ quote, options, itemKey: item.key }).filter(filterVisibleFiles)

    if (itemFiles.length > 0) {
      return {
        ...files,
        [item.key]: itemFiles
      }
    }

    return files
  }, {} as Record<string, QuoteFile[]>)

  const hasFiles = Object.keys(filesByItem).length > 0
  const showFiles = consumer === 'customer' ? quoteOptions.showQuoteFiles : quoteOptions.showWorkOrderFiles
  const showTitle = isEditable || (!isEditable && showFiles)

  if (!hasFiles) {
    return null
  }

  if (!hasFiles) {
    return null
  }

  const content = (
    <>
      {showTitle && props.FormSectionTitle}
      <Collapse show={showFiles}>
        <Grid container spacing={4}>
          {map(filesByItem, (files, key) => {
            const item = getItem({ quote, options, key })

            return (
              <Grid item key={key} xs={12}>
                <FileSection
                  justify="flex-start"
                  files={files}
                  title={item.label as string}
                  editable={isEditable}
                  onReorder={(files) => handleReorder(files, key)}
                  onFileClick={(index) => handleFileClick(item.key, index)}
                />
              </Grid>
            )
          })}
        </Grid>
      </Collapse>
    </>
  )

  return content
}
