import React, { useRef } from 'react'
import type { RTEProps as CoreRTEProps } from '@ui/core/RTE'
import CoreRTE from '@ui/core/RTE'
import type { Theme } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import type { MenuComponentProps } from '@ui/core/RTE/extensions/TemplateTags'
import type { Context } from '@paintscout/util/templater'
import { InlineColorPicker } from '../ColorPicker'
import MenuItem from '../MenuItem'
import InputLabel from '../InputLabel'
import PopupMenu from '../PopupMenu'
import Menu from '../Menu'
import { makeStyles } from '@material-ui/core/styles'
import { getPresentationContentStyles } from '../styles'
import TemplateTags from '@ui/core/RTE/extensions/TemplateTags'
import TemplateIcon from '@material-ui/icons/More'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import FormatColorTextIcon from '@material-ui/icons/FormatColorText'
import FormatClearIcon from '@material-ui/icons/FormatClear'
import { useSnackbar } from 'notistack'

import classnames from 'classnames'

const useStyles = makeStyles<Theme, RTEProps>(
  (theme) => {
    return {
      root: (props) => ({
        ...getPresentationContentStyles(theme, { noMargins: ['li'] }),
        borderRadius: theme.borderRadius.sm,
        border: `1px solid ${theme.palette.grey[500]}`,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        '& input': {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.pxToRem(16),
          '&:focus-visible': {
            outline: 'none !important'
          }
        },
        ...(props.multiline !== false && {
          minHeight: 86 // three lines of body text
        }),
        '& .ProseMirror': {
          opacity: (props: RTEProps) => (props.disabled ? 0.5 : 1),
          outline: 'none',
          padding: '10px 16px',
          fontSize: theme.typography.fontSize,
          display: 'block',
          flexGrow: 1,
          maxWidth: '100%',
          overflowWrap: 'break-word'
        },
        '& .ProseMirror p': {
          whiteSpace: '-moz-pre-space',
          '&:after': {
            display: 'none'
          }
          // marginTop: theme.size['1'],
          // marginBottom: theme.size['1']
        },
        '& .template-tag': {
          paddingLeft: theme.size[1],
          paddingRight: theme.size[1],
          paddingTop: theme.size['0.5'],
          paddingBottom: theme.size['0.5'],
          fontWeight: theme.typography.fontWeightMedium,
          backgroundColor: theme.palette.grey[300],
          borderRadius: theme.borderRadius.sm
        },
        // this and all the flexGrow stuff is so the ProseMirror (clickable/editable area) is the full height of root (adjustable by props)
        // hacky solution as there is an aria inserted div by tiptap that needs to be set to flex/flexGrow 1 but can't be targeted
        '& div': {
          display: 'flex',
          flexGrow: 1
        },
        '&:focus-within': {
          borderColor: (props: RTEProps) => (!props.disabled ? theme.palette.primary.dark : theme.palette.grey[500]),
          '& #menu-bar': {
            visibility: (props: RTEProps) => (!props.disabled ? 'visible' : 'hidden')
          }
        }
      }),
      tippyMenu: (props) => ({
        backgroundColor: theme.palette.grey[300],
        padding: 0,
        borderRadius: theme.borderRadius.sm,
        overflow: 'hidden',
        display: 'flex',
        maxWidth: props.hasColor ? 210 : 180,
        flexWrap: 'wrap',
        '& button': {
          background: 'none',
          border: `none`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.pxToRem(16),
          minWidth: theme.typography.pxToRem(30),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(0.5),
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.primary.main,
          cursor: 'pointer',
          '& svg': {
            fontSize: '1rem'
          },
          '&:hover': {
            color: theme.palette.grey[800]
          },
          '&.is-active': {
            backgroundColor: theme.palette.grey[400]
          },
          '&.bold': {
            fontWeight: theme.typography.fontWeightBold
          },
          '&.italic': {
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'italic'
          },
          '&.underline': {
            fontWeight: theme.typography.fontWeightBold,
            textDecoration: 'underline'
          },
          '&:disabled': {
            color: theme.palette.grey[500],
            cursor: 'default'
          }
        }
      }),
      tippyMenuBar: {
        '&$tippyMenuBar': {
          maxWidth: '100%',
          alignSelf: 'flex-start',
          flexGrow: 0,
          margin: '0 16px 8px 16px',
          boxShadow: theme.boxShadow[1],
          visibility: 'hidden'
        },
        '& $linkInput': {
          width: 'auto'
        }
      },
      menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: `${theme.size['2']} ${theme.size['4']}`
      },
      linkInput: {
        width: '100%',
        borderRadius: theme.borderRadius.sm,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderTop: 0
      },
      linkInputWrapper: {
        '&$linkInputWrapper': {
          display: 'flex',
          alignContent: 'flex-start',
          flexGrow: 0
        }
      },
      tagMenuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      templateTagMenu: {
        height: 'clamp(200px, 30vh, 400px)'
      }
    }
  },
  { name: 'RTEStickyBid' }
)

export interface RTEProps extends CoreRTEProps {
  noHeaders?: boolean
  header?: boolean
  template?: {
    context: Context
    tags?: Array<{
      key: string
      label: string
      hideValue?: boolean
    }>
  }
  templateTagsOnly?: boolean
  required?: boolean
  hasColor?: boolean
  presetColors?: string[]
}

export default function RTE({
  template,
  label,
  noHeaders = false,
  header = false,
  multiline = true,
  templateTagsOnly,
  required,
  hasColor,
  presetColors,
  ...props
}: RTEProps) {
  const classes = useStyles({ multiline, hasColor, ...props })
  const hrefInput = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <CoreRTE
      multiline={header ? false : multiline}
      label={label ? <InputLabel>{required ? `${label} *` : label}</InputLabel> : null}
      BubbleMenu={({ editor }) => {
        const handleUnsetLink = () => {
          editor.chain().focus().unsetLink().run()
          enqueueSnackbar('Link removed', { variant: 'success' })
        }
        // Maintain current text align status to pass in on font change
        const alignmentOptions = {
          left: editor.isActive({ textAlign: 'left' }),
          center: editor.isActive({ textAlign: 'center' }),
          right: editor.isActive({ textAlign: 'right' }),
          justify: editor.isActive({ textAlign: 'justify' })
        }
        const currentTextAlign = Object.keys(alignmentOptions).filter((key) => alignmentOptions[key] === true)[0]
        return (
          <div id={'bubble-menu'} className={classes.tippyMenu}>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().toggleBold().run()
              }}
              className={editor.isActive('bold') ? 'is-active bold' : 'bold'}
            >
              B
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().toggleItalic().run()
              }}
              className={editor.isActive('italic') ? 'is-active italic' : 'italic'}
            >
              I
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().toggleUnderline().run()
              }}
              className={editor.isActive('underline') ? 'is-active underline' : 'underline'}
            >
              U
            </button>
            {!noHeaders && (
              <>
                <button
                  type={'button'}
                  onMouseDown={handleMouseDown}
                  onClick={(ev) => {
                    ev.preventDefault()
                    editor.chain().focus().toggleHeading({ level: 1 }).setTextAlign(currentTextAlign).run()
                  }}
                  className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                  H₁
                </button>
                <button
                  type={'button'}
                  onMouseDown={handleMouseDown}
                  onClick={(ev) => {
                    ev.preventDefault()
                    editor.chain().focus().toggleHeading({ level: 2 }).setTextAlign(currentTextAlign).run()
                  }}
                  className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                  H₂
                </button>
                <button
                  type={'button'}
                  onMouseDown={handleMouseDown}
                  onClick={(ev) => {
                    ev.preventDefault()
                    editor.chain().focus().toggleHeading({ level: 3 }).setTextAlign(currentTextAlign).run()
                  }}
                  className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                  H₃
                </button>
              </>
            )}
            {hasColor && (
              <ColorPicker
                onClose={(hex) => editor.chain().focus().setColor(hex).run()}
                value={editor.getAttributes('textStyle').color}
                presetColors={presetColors}
              />
            )}
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().setTextAlign('left').run()
              }}
              className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
            >
              <FormatAlignLeftIcon />
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().setTextAlign('center').run()
              }}
              className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
            >
              <FormatAlignCenterIcon />
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().setTextAlign('right').run()
              }}
              className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
            >
              <FormatAlignRightIcon />
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().setTextAlign('justify').run()
              }}
              className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
            >
              <FormatAlignJustifyIcon />
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.chain().focus().unsetAllMarks().run()
              }}
            >
              <FormatClearIcon />
            </button>
            <button
              type={'button'}
              onMouseDown={handleMouseDown}
              onClick={(ev) => {
                ev.preventDefault()
                editor.isActive('link')
                  ? handleUnsetLink()
                  : editor.chain().focus().toggleLink({ href: 'https://example.com' }).run()
              }}
              id={'link-toggle-button'}
              className={editor.isActive('link') ? 'is-active' : ''}
            >
              {editor.isActive('link') ? <LinkOffIcon /> : <LinkIcon />}
            </button>
            {editor.isActive('link') && (
              <>
                <input
                  ref={hrefInput}
                  className={classes.linkInput}
                  type={'text'}
                  onFocus={() => {
                    if (!hrefInput?.current?.value) hrefInput.current.value = editor.getAttributes('link').href
                  }}
                  onBlur={(ev) => {
                    const blurTarget = ev.relatedTarget as Element
                    if (blurTarget?.id === 'link-toggle-button') {
                      return null
                    }
                    const href = hrefInput.current.value || 'https://example.com'
                    editor.chain().focus().setLink({ href: href }).run()
                    enqueueSnackbar(`Link to ${href} set`, { variant: 'success' })
                  }}
                  placeholder={editor.getAttributes('link').href}
                />
              </>
            )}
          </div>
        )
      }}
      FloatingMenu={
        header
          ? null
          : ({ editor }) => (
              <div id={'floating-menu'} className={classes.tippyMenu}>
                <button
                  type={'button'}
                  onMouseDown={handleMouseDown}
                  onClick={(ev) => {
                    ev.preventDefault()
                    editor.chain().focus().toggleBulletList().run()
                  }}
                  className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                  <FormatListBulletedIcon />
                </button>
                <button
                  type={'button'}
                  onMouseDown={handleMouseDown}
                  onClick={(ev) => {
                    ev.preventDefault()
                    editor.chain().focus().toggleOrderedList().run()
                  }}
                  className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                  <FormatListNumberedIcon />
                </button>
              </div>
            )
      }
      MenuBar={({ editor }) => {
        // Maintain current text align status to pass in on font change
        const handleUnsetLink = () => {
          editor.chain().focus().unsetLink().run()
          enqueueSnackbar('Link removed', { variant: 'success' })
        }
        const alignmentOptions = {
          left: editor.isActive({ textAlign: 'left' }),
          center: editor.isActive({ textAlign: 'center' }),
          right: editor.isActive({ textAlign: 'right' }),
          justify: editor.isActive({ textAlign: 'justify' })
        }
        const currentTextAlign = Object.keys(alignmentOptions).filter((key) => alignmentOptions[key] === true)[0]
        const renderTemplateTags = (
          <>
            {template?.tags && (
              <PopupMenu
                classes={{ menu: classes.templateTagMenu }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                autoFocus={false}
                disableAutoFocus
                disableAutoFocusItem
                disableEnforceFocus
                component={
                  <button type={'button'} onMouseDown={handleMouseDown} onClick={(ev) => ev.preventDefault()}>
                    <TemplateIcon />
                  </button>
                }
              >
                {template?.tags.map((tag) => (
                  <MenuItem
                    className={classes.tagMenuItem}
                    key={tag.key}
                    onMouseDown={(ev) => {
                      ev.preventDefault()
                      const range = editor.state.selection.ranges[0].$from.pos
                      editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                          {
                            type: 'template',
                            attrs: { id: tag.key }
                          },
                          {
                            type: 'text',
                            text: ' '
                          }
                        ])
                        .run()
                    }}
                  >
                    <Typography variant="body1">{!tag.hideValue && template.context[tag.key]}</Typography>
                    <Typography variant="subtitle2">{tag.label}</Typography>
                  </MenuItem>
                ))}
              </PopupMenu>
            )}
          </>
        )
        if (templateTagsOnly)
          return (
            <div id={'menu-bar'} className={classnames([classes.tippyMenu, classes.tippyMenuBar])}>
              {renderTemplateTags}
            </div>
          )
        else
          return (
            <div id={'menu-bar'} className={classnames([classes.tippyMenu, classes.tippyMenuBar])}>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().toggleBold().run()
                }}
                className={editor.isActive('bold') ? 'is-active bold' : 'bold'}
              >
                B
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().toggleItalic().run()
                }}
                className={editor.isActive('italic') ? 'is-active italic' : 'italic'}
              >
                I
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().toggleUnderline().run()
                }}
                className={editor.isActive('underline') ? 'is-active underline' : 'underline'}
              >
                U
              </button>
              {!noHeaders && (
                <>
                  <button
                    type={'button'}
                    onMouseDown={handleMouseDown}
                    onClick={(ev) => {
                      ev.preventDefault()
                      editor.chain().focus().toggleHeading({ level: 1 }).setTextAlign(currentTextAlign).run()
                    }}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                  >
                    H₁
                  </button>
                  <button
                    type={'button'}
                    onMouseDown={handleMouseDown}
                    onClick={(ev) => {
                      ev.preventDefault()
                      editor.chain().focus().toggleHeading({ level: 2 }).setTextAlign(currentTextAlign).run()
                    }}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                  >
                    H₂
                  </button>
                  <button
                    type={'button'}
                    onMouseDown={handleMouseDown}
                    onClick={(ev) => {
                      ev.preventDefault()
                      editor.chain().focus().toggleHeading({ level: 3 }).setTextAlign(currentTextAlign).run()
                    }}
                    className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                  >
                    H₃
                  </button>
                </>
              )}
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().setTextAlign('left').run()
                }}
                className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
              >
                <FormatAlignLeftIcon />
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().setTextAlign('center').run()
                }}
                className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
              >
                <FormatAlignCenterIcon />
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().setTextAlign('right').run()
                }}
                className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
              >
                <FormatAlignRightIcon />
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().setTextAlign('justify').run()
                }}
                className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
              >
                <FormatAlignJustifyIcon />
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().toggleBulletList().run()
                }}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
              >
                <FormatListBulletedIcon />
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().toggleOrderedList().run()
                }}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
              >
                <FormatListNumberedIcon />
              </button>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.isActive('link')
                    ? handleUnsetLink()
                    : editor.chain().focus().toggleLink({ href: 'https://example.com' }).run()
                }}
                id={'link-toggle-button'}
                className={editor.isActive('link') ? 'is-active' : ''}
              >
                {editor.isActive('link') ? <LinkOffIcon /> : <LinkIcon />}
              </button>
              <div className={classes.linkInputWrapper}>
                {editor.isActive('link') && (
                  <>
                    <input
                      ref={hrefInput}
                      className={classes.linkInput}
                      type={'text'}
                      onFocus={() => {
                        if (!hrefInput?.current?.value) hrefInput.current.value = editor.getAttributes('link').href
                      }}
                      onBlur={(ev) => {
                        const blurTarget = ev.relatedTarget as Element
                        if (blurTarget?.id === 'link-toggle-button') {
                          return null
                        }
                        const href = hrefInput.current.value || 'https://example.com'
                        editor.chain().focus().setLink({ href: href }).run()
                        enqueueSnackbar(`Link to ${href} set`, { variant: 'success' })
                      }}
                      placeholder={editor.getAttributes('link').href}
                    />
                  </>
                )}
              </div>
              <button
                type={'button'}
                onMouseDown={handleMouseDown}
                onClick={(ev) => {
                  ev.preventDefault()
                  editor.chain().focus().unsetAllMarks().run()
                }}
              >
                <FormatClearIcon />
              </button>
              {renderTemplateTags}
            </div>
          )
      }}
      extensions={[TemplateTags.configure({ ...template, MenuComponent }), ...(hasColor ? [Color, TextStyle] : [])]}
      {...props}
      classes={classes}
    />
  )
}

function MenuComponent(props: MenuComponentProps) {
  const classes = useStyles({})

  return (
    <Menu
      open={props.items.length > 0}
      anchorPosition={{
        left: props.clientRect().left,
        top: props.clientRect().top + 24
      }}
      anchorReference="anchorPosition"
      onClose={props.onClose}
      disableAutoFocusItem
    >
      {props.items.map((tag) => (
        <MenuItem
          key={tag.key}
          className={classes.menuItem}
          dense
          onMouseDown={(ev) => {
            ev.preventDefault()
            props.onSelect(tag)
          }}
          onKeyDownCapture={(ev) => {
            if (ev.key === 'Enter' || ev.key === ' ' || ev.key === 'Tab') {
              ev.preventDefault()
              props.onSelect(tag)
            }
          }}
        >
          <Typography variant="body1">{!tag.hideValue && props.context[tag.key]}</Typography>
          <Typography variant="subtitle2">{tag.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  )
}

// Fixes disappearing menu on click w/ Safari Browser
const handleMouseDown = (ev: React.MouseEvent<HTMLButtonElement>) => {
  ev.preventDefault()
}

interface ColorPickerProps {
  value: string
  onClose: (hex: string) => void
  presetColors?: string[]
}

function ColorPicker(props: ColorPickerProps) {
  const { value, onClose, presetColors } = props
  return (
    <button type={'button'} onClick={(ev) => ev.preventDefault()} onMouseDown={handleMouseDown}>
      <InlineColorPicker
        swatchOnly={true}
        value={value}
        onClose={onClose}
        swatchIcon={<FormatColorTextIcon style={{ position: 'relative', top: 3 }} />}
        presetColors={presetColors}
      />
    </button>
  )
}
