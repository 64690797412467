import React from 'react'
import type { DialogProps } from '../Dialog'
import Dialog from '../Dialog'
import { DialogTitle, DialogActions, DialogContent } from '../Dialog'
import type { ButtonProps } from '../Button'
import Button from '../Button'
import { Grid, makeStyles } from '@material-ui/core'
import Typography from '../Typography'

export interface AlertDialogProps extends DialogProps {
  title?: string
  message: React.ReactNode
  icon?: React.ReactNode
  color?: 'default' | 'warning' | 'success' | 'error'
  actions?: Array<
    {
      label: string
    } & Partial<ButtonProps>
  >
  onConfirm?: (event: React.MouseEvent<HTMLElement>) => void
}

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  }
}))

function AlertDialog(props: AlertDialogProps) {
  const { loading, onConfirm, title = 'Alert', actions, message, icon, color, ...otherProps } = props

  const classes = useStyles(props)

  return (
    <Dialog {...otherProps}>
      <DialogTitle loading={loading} icon={icon} color={color}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1" component="div" align="center">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        {actions ? (
          <div>
            <Grid container spacing={1}>
              {actions.map(({ label, ...btnProps }) => (
                <Grid key={label} item>
                  <Button prominence={1} disabled={loading} {...btnProps}>
                    {label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <Button prominence={1} disabled={loading} onClick={onConfirm}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default AlertDialog
