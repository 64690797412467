import type { QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'

export function roundHours(value: number, quote: QuoteDocument, rateType?: string) {
  switch (getRoundType(quote, rateType)) {
    case 'none':
      return value
    case 'hour':
      return Math.ceil(value)
    case 'quarter':
      return Math.ceil(value * 4) / 4
    default:
      // half
      return Math.ceil(value * 2) / 2
  }
}

function getRoundType(quote: QuoteDocument, rateType?: string): string {
  const quoteOptions = getQuoteOptions({ quote })
  const quoteVersion = quote.version ?? 1 // This would have to be *really* old

  let roundHours
  if (quoteVersion < 2 && rateType === 'quantity') {
    return quoteOptions?.roundQuantityHours ? 'half' : 'none'
  } else if (quoteVersion < 2) {
    return 'half'
  } else if (quote && quote.options && quote.options.roundHours) {
    return quoteOptions?.roundHours
  } else if (rateType === 'quantity') {
    // roundHours = 'half' // people who *started* with v2 were getting quantity rates rounded
    // roundHours = 'none'

    return quoteOptions?.roundQuantityHours ? 'half' : 'none'
  } else {
    return 'half'
  }
}
