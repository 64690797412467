import React, { useContext } from 'react'
import { Radio, RadioGroup, makeStyles } from '@material-ui/core'
import type { NotificationTypeConfig, OptionsDocument, UserPreferencesDocument } from 'paintscout'
import { UserContext } from '../../UserProvider'
import FormSection from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import { getFeature, getObjectLabels } from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import FormControlLabel from '../../FormControlLabel'
import type { WithUseStyles } from '../../styles'
import { PageHelpMessage, LearnMoreLink } from '../../PageHelpMessage'
import Typography from '../../Typography'
import Switch from '../../Switch'

export interface NotificationsFormProps extends WithUseStyles<typeof useStyles> {
  showAdmin?: boolean
}

// KEEP THIS IN SYNC with views/notification-subscribers.ts, indexUser.ts (elastic), preferences-template.json, and set-missing-notifications-to-defaults.ts
// (ideally we keep this in a @paintscout/util function, but we can't use @paintscout/util functions in couchdb views)
// so it's best to be explicit both here and in the view
const defaultNotificationTypes: Record<string, NotificationTypeConfig> = {
  'quote-accepted': {
    enabled: true,
    triggeredBy: 'self'
  },
  'quote-declined': {
    enabled: true,
    triggeredBy: 'self'
  },
  'quote-bounced': {
    enabled: true,
    triggeredBy: 'self'
  },
  'quote-message': {
    enabled: true,
    triggeredBy: 'self'
  },
  'quote-additional-work-accepted': {
    enabled: true,
    triggeredBy: 'self'
  },
  'quote-assigned': {
    enabled: true,
    triggeredBy: 'self'
  },
  'owner-changed': {
    enabled: true,
    triggeredBy: 'self'
  },
  'quote-viewed': {
    enabled: false,
    triggeredBy: 'self',
    frequency: 'firstView'
  },
  'integration-quote-error': {
    enabled: true,
    triggeredBy: 'self'
  },
  'integration-contact-error': {
    enabled: true,
    triggeredBy: 'self'
  },
  'payment-received': {
    enabled: true,
    triggeredBy: 'self'
  },
  'option-accepted': {
    enabled: true,
    triggeredBy: 'self'
  }
}

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 625,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%'
    }
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginTop: -10
    }
  }
}))

function NotificationsForm(props: NotificationsFormProps) {
  const classes = useStyles(props)
  const { showAdmin } = props
  const { values } = useFormikContext<{
    options: OptionsDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })
  const { highestRole } = useContext(UserContext)
  const featureQuoteViewed = getFeature({ options, path: 'notifications.quoteViewed' })
  const featureNotifications = getFeature({ options, path: 'notifications.enabled' })
  const hasInvoices = getFeature({ options, path: 'invoices.enabled' })
  const showSalesAdminFeature = (feature: boolean) =>
    featureNotifications && feature && ['superadmin', 'admin', 'sales'].includes(highestRole)

  return (
    <div>
      <PageHelpMessage
        path="profile-notifications"
        openContent={
          <>
            <FormSectionTitle title={'Notifications'} subTitle="Sent to you by email" variant="h2" />
            <Typography>
              Toggle notifications to get email alerts when customers have responded to your quote or have sent you a
              new message.
              <LearnMoreLink link="http://help.stickybid.com/en/articles/3206825-updating-profile-estimator-information" />
            </Typography>
          </>
        }
        closedContent={<FormSectionTitle title={'Notifications'} subTitle="Sent to you by email" variant="h2" />}
      />

      <FormSection className={classes.section}>
        <FormSectionTitle title={`Responses to sent ${objectLabels.quote.plural}`} variant={'h3'} />
        <NotificationOption
          type="quote-accepted"
          showAdmin={showAdmin}
          label={`${objectLabels.quote.value} Accepted`}
        />
        <NotificationOption
          type="quote-declined"
          showAdmin={showAdmin}
          label={`${objectLabels.quote.value} Declined`}
        />
        {showSalesAdminFeature(featureQuoteViewed) && (
          <NotificationOption
            type="quote-viewed"
            showFrequency
            trigger="self"
            label={`${objectLabels.quote.value} Viewed`}
          />
        )}
        <NotificationOption
          type="quote-bounced"
          showAdmin={showAdmin}
          label={`${objectLabels.quote.value} Could Not Be Delivered`}
        />
      </FormSection>
      {hasInvoices && (
        <FormSection className={classes.section}>
          <FormSectionTitle title={`Responses to sent Invoices`} variant={'h3'} />
          <NotificationOption type="option-accepted" showAdmin={showAdmin} label={`Option(s) Accepted`} />
          <NotificationOption type="payment-received" showAdmin={showAdmin} label={`Payment Received`} />
        </FormSection>
      )}
    </div>
  )
}

interface NotificationOptionProps {
  type: string
  label: string

  showAdmin?: boolean
  showFrequency?: boolean
  /**
   * Forces the notification to a trigger (all/self)
   *
   * if not defined, then it will show the radio group to let the user select
   */
  trigger?: 'all' | 'self'
}
function NotificationOption(props: NotificationOptionProps) {
  const classes = useStyles(props)
  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    preferences: UserPreferencesDocument
  }>()
  const { options, preferences } = values

  const objectLabels = getObjectLabels({ options })
  const { isAdmin } = useContext(UserContext)

  const showTriggeredBy = (isAdmin || props.showAdmin) && !props.trigger

  // get notification from options by type
  const notif =
    preferences.notifications && preferences.notifications.types && preferences.notifications.types[props.type]
      ? preferences.notifications.types[props.type]
      : defaultNotificationTypes[props.type]

  interface RadioSelectProps {
    target: 'triggeredBy' | 'frequency'
    label0: string
    label1: string
    val0: string
    val1: string
  }
  const RadioSelect = ({ target, label0, label1, val0, val1 }: RadioSelectProps) => {
    return (
      <RadioGroup
        className={classes.radioGroup}
        value={notif[target]}
        onChange={(ev, value: string) => {
          setFieldValue(`preferences.notifications.types.${props.type}.${target}` as any, value)
          setFieldValue(`preferences.notifications.types.${props.type}.enabled` as any, true)
        }}
      >
        <FormControlLabel label={label0} value={val0} disabled={!notif.enabled} control={<Radio color={'primary'} />} />
        <FormControlLabel label={label1} value={val1} disabled={!notif.enabled} control={<Radio color={'primary'} />} />
      </RadioGroup>
    )
  }

  return (
    <div className={classes.option}>
      <FormControlLabel
        control={
          <Switch
            checked={!!notif.enabled}
            onChange={(ev, value) => {
              if (
                // if props.trigger is set
                props.trigger ||
                // if preferences.notifications is undefined, default the triggeredBy value
                !preferences.notifications ||
                !preferences.notifications.types ||
                !preferences.notifications.types[props.type]
              ) {
                setFieldValue(
                  `preferences.notifications.types.${props.type}.triggeredBy` as any,
                  props.trigger || 'self'
                )
              }
              if (props.type === 'quote-viewed' && !preferences.notifications?.types?.['quote-viewed']) {
                // need to reset the default for this type's extra field else it is lost when notif switches from defaultNotificationTypes
                setFieldValue(`preferences.notifications.types.quote-viewed.frequency` as any, 'firstView')
              }

              setFieldValue(`preferences.notifications.types.${props.type}.enabled` as any, value)
            }}
          />
        }
        label={props.label}
      />
      {showTriggeredBy && (
        <RadioSelect
          target={'triggeredBy'}
          label0={`My ${objectLabels.quote.plural}`}
          label1={`All ${objectLabels.quote.plural}`}
          val0={'self'}
          val1={'all'}
        />
      )}
      {props.showFrequency && (
        <RadioSelect
          target={'frequency'}
          label0={'First View Only'}
          label1={'Each View'}
          val0={'firstView'}
          val1={'eachView'}
        />
      )}
    </div>
  )
}

export default NotificationsForm
