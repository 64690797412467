import get from 'lodash/get'
import moment from 'moment-timezone'

import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { Context } from '../template'
import { createOptionsContext } from './create-options-context'
import { getObjectLabels } from '../../builder/util/get-object-labels'
import type { QuoteTypeOption } from '../../builder'
import { getQuoteTypeOptions } from '../../builder'
import find from 'lodash/find'

export interface QuoteContext extends Context {
  quoteNumber: number
  clientFirstName: string
  clientLastName: string
  clientName: string
  jobIdentifier: string
  owner: string
}

/**
 * Creates a context for a template. Context includes result of `createOptionsContext` as well.
 */
export function createQuoteContext(args: {
  quote?: QuoteDocument
  options?: OptionsDocument
  override?: Partial<QuoteContext>
}): QuoteContext {
  const { quote, options, override = {} } = args
  const quoteTypeOptions = options ? getQuoteTypeOptions({ options }) : null
  const quoteType = quoteTypeOptions
    ? (find(quoteTypeOptions, { key: quote.int_ext.value as any }) as QuoteTypeOption)
    : null
  const quoteTypeOverride = quoteType?.overrideCompanyOptions ?? false

  // If quoteType overrides company, we must override context creation
  // To use correct company variables in template
  const overriddenCompany = {
    companyName: quoteType?.companyOptions?.name ?? '',
    companyCity: quoteType?.companyOptions?.city ?? '',
    companyProv: quoteType?.companyOptions?.province ?? '',
    companyAddress: quoteType?.companyOptions?.address ?? '',
    companyPostal: quoteType?.companyOptions?.postal ?? '',
    companyPhone: quoteType?.companyOptions?.phoneNumber ?? '',
    companyAdditional: quoteType?.companyOptions?.additional ?? '',
    width: quoteType?.companyOptions?.logo?.width ?? 1,
    height: quoteType?.companyOptions?.logo?.height ?? 1,
    logoOrientation: quoteType?.companyOptions?.logo?.orientation ?? 'auto'
  }

  const dateFormat = get(options, 'options.dateFormat.momentValue', 'YYYY/MM/DD')

  const clientFirstName = get(quote, 'contact.first_name', null)
  const clientLastName = get(quote, 'contact.last_name', null)

  // if either first or last name, create the full name
  // (if only first name, omit the space between the first and last name)
  const clientName =
    clientFirstName || clientLastName ? `${clientFirstName}${clientLastName ? ' ' + clientLastName : ''}` : null

  // use jobAddress for address if available... use contact address if not
  const jobAddress = quote?.jobAddress ?? { address: null, city: null, province: null, postal: null }
  const contactAddress = quote?.contact ?? { address: null, city: null, province: null, postal: null }
  const address = jobAddress?.address ? jobAddress : contactAddress

  const jobIdentifier = quote?.job_identifier ?? ''

  const objectLabels = getObjectLabels({ options, invoice: quote?.is_invoice })
  const owner = quote?.owner?.firstName ?? (options.options.companyName.value as string)

  const quoteAcceptedDate =
    quote?.dates && quote?.dates.client
      ? moment(quote?.dates?.client?.accepted)
          .tz((options && options.options.timezone) || 'America/Edmonton')
          .format('lll')
      : ''

  return {
    quoteLabel: objectLabels.quote.value,
    quoteLabelPlural: objectLabels.quote.plural,
    quoteNumber: quote?.number ?? null,
    quoteDate: moment(quote.quote_date).format(dateFormat),
    owner,
    jobIdentifier,
    clientName,
    clientFirstName,
    clientLastName,
    address: address?.address ?? '',
    city: address?.city ?? '',
    state: address?.province ?? '',
    zip: address?.postal ?? '',
    intExt: get(options, `options.intExtOptions.values[${quote?.int_ext.value}].label`, ''),
    quoteAcceptedDate,
    ...(options ? createOptionsContext({ options, override }) : {}),
    ...(quoteTypeOverride ? overriddenCompany : {}),
    ...override
  }
}
