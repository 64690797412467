import type { AreaSubstrate, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { productVolume } from './product-volume'
import { roundHours } from '../util/round-hours'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'

export function overrideHoursSubstrate(substrate: AreaSubstrate, quote: QuoteDocument) {
  const quantity = parseNumber(substrate.quantity, 1)
  const coats = parseNumber(substrate.coats)
  const product = substrate.product

  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}

  productVolume(
    product,
    'quantity',
    1,
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  let baseHours = 0
  if (substrate.hours) {
    baseHours = parseNumber(substrate.hours)
  } else if (substrate.rate_value) {
    baseHours = parseNumber(substrate.rate_value)
  }

  if (substrate.override_hours) {
    if (typeof substrate.override_hours_value !== 'undefined') {
      baseHours = parseNumber(substrate.override_hours_value)
    } else {
      baseHours = parseNumber(substrate.hours)
    }
  }

  const hourlyRate = parseNumber(
    substrate.overrideHourlyRate && typeof substrate.hourlyRate === 'number' ? substrate.hourlyRate : quote.hourly_rate
  )
  const prep = parseNumber(substrate.prep)
  let roundedHours = baseHours
  if (quote.options && quote.options.roundHours) {
    roundedHours = roundHours(baseHours, quote, substrate.rate.calculation_type)
  }

  return {
    ...substrate,
    hours: roundedHours,
    price: hourlyRate * (roundedHours + prep),
    default_hours: roundedHours,
    default_price: hourlyRate * (roundedHours + prep)
  }
}
