import React, { useState, useEffect, useRef } from 'react'
import type { PresentationIndustry, QuoteFile, PresentationLibrary } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles, ClickAwayListener } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { getFeature } from '@paintscout/util/builder'
import {
  EmbedMedia,
  FilePreview,
  Placeholder,
  PopupMenuItem,
  PresEditorTooltip,
  StockPhotoDialog,
  useClientOptions,
  useDialogs,
  useUser,
  ViewMediaDialog
} from '@ui/stickybid'
import type { FilePreviewProps } from '@ui/stickybid'
import { SectionOptionsPopup } from '../../'
import { ImageSmallIcon } from '@ui/core/icons'
import EditOutlineIcon from '@material-ui/icons/EditOutlined'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined'
import YouTubeIcon from '@material-ui/icons/YouTube'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined'
import { PresentationLibraryDialog } from '../../dialogs'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'

interface InlineEditableMediaProps {
  accept?: string
  presentationLibrary?: {
    addFilesToLibrary: (args: { files: QuoteFile[] }) => void
    library: PresentationLibrary
    saveLibrary: (args: { library: PresentationLibrary }) => void
  }
  classes?: StyleClasses<typeof useStyles>
  file: QuoteFile
  getFocusFromSection?: boolean
  handleEdit: (content: QuoteFile) => void
  hasRightSideMenus?: boolean
  isAnimated?: boolean
  isEditable?: boolean
  isSettings?: boolean
  mediaOptionsProps?: {
    sectionPrefix: string
    fieldSuffix: string
    hideAspectOptions?: boolean
  }
  placeholderButtonText?: string
  presentationIndustry: PresentationIndustry
  showAllPages?: boolean
  square?: boolean
  youtube?: boolean
  filePreviewProps?: Partial<FilePreviewProps>
}

const useStyles = makeStyles<Theme, InlineEditableMediaProps>(
  (theme: Theme) => {
    return {
      root: (props) => ({
        display: 'flex',
        justifyContent: props.file?.presentationOptions?.horizontalAlign || 'center',
        minHeight: 50
      }),
      size: {
        position: 'relative'
      },
      hovered: {
        cursor: 'pointer',
        '&:hover': {
          outline: `2px solid ${theme.palette.presentation.sectionBorder.hover}`,
          zIndex: 1,
          '& $iconMenuRoot': {
            display: 'flex'
          }
        }
      },
      focused: {
        outline: `2px solid ${theme.palette.presentation.sectionBorder.focus}`,
        zIndex: 1
      },
      image: (
        props,
        clipPath = props.file?.presentationOptions?.clipPath || 'none',
        clipPathOrientation = props.file?.presentationOptions?.clipPathOrientation || 'top'
      ) => ({
        '&$image': {
          width: '100%',
          aspectRatio: getAspectRatio({ file: props.file, maxSquare: false }),
          objectPosition: getObjectPosition(props.file),
          clipPath: getClipPath(clipPath, clipPathOrientation),
          objectFit: props.file?.presentationOptions?.aspectRatio !== 'unset' ? 'cover' : 'contain',
          [theme.breakpoints.down('sm')]: {
            aspectRatio: getAspectRatio({ file: props.file, maxSquare: true })
          },
          borderRadius: 0
        }
      }),
      youtubeWrapper: {
        pointerEvents: 'auto'
      },
      dropzone: (props) => ({
        height: props.file ? 'auto' : 150
      }),
      editable: {
        cursor: 'pointer',
        height: 'inherit'
      },
      mediaPlaceholder: {
        aspectRatio: '1/1'
      },
      iconMenuRoot: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.borderRadius.md,
        bottom: theme.spacing(2),
        boxShadow: theme.boxShadow[1],
        display: 'none',
        left: theme.spacing(2),
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        zIndex: 3
      },
      iconMenuRightSide: {
        right: theme.spacing(2),
        left: 'auto'
      },
      iconMenuRootFocused: {
        display: 'flex'
      }
    }
  },
  { name: 'InlineEditableMedia' }
)

export default function InlineEditableMedia(props: InlineEditableMediaProps) {
  const classes = useStyles(props)
  const {
    accept,
    presentationLibrary,
    getFocusFromSection,
    handleEdit,
    hasRightSideMenus,
    isAnimated,
    isEditable,
    isSettings,
    mediaOptionsProps,
    placeholderButtonText,
    presentationIndustry,
    showAllPages,
    square,
    youtube: hasYoutube = true,
    filePreviewProps
  } = props

  const { sectionPrefix, fieldSuffix, hideAspectOptions } = mediaOptionsProps || {}
  const { options } = useClientOptions()
  const { enqueueSnackbar } = useSnackbar()
  const { isSuperadmin } = useUser()
  const hasStockPhotos = getFeature({ options, path: 'presentation.stockPhotos' }) || isSuperadmin

  const [file, setFile] = useState(props.file)
  const [isFocused, setIsFocused] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const format = file?.format
  const isVideo = (file?.type ?? '').startsWith('video') || ['mp4', 'mov', 'youtube'].includes(format)
  const isYoutube = format === 'youtube'
  const isPdf = format === 'pdf'
  const { openDialog, dismissDialog, stack: dialogStack } = useDialogs()
  const { addFilesToLibrary, library } = presentationLibrary || {}
  const isInLibrary = library?.order.includes(file?.cloudinaryPublicId)

  const handlePdfPreview = (page) => {
    openDialog(ViewMediaDialog, {
      files: [file],
      enableDownload: !isSettings,
      onClose: dismissDialog
    })
  }

  const handleStockPhotoDialog = () => {
    openDialog(StockPhotoDialog, {
      defaultQuery: presentationIndustry,
      specificQuery: file?.pexelsQuery,
      onUpload: handleChange,
      onCancel: () => dismissDialog()
    })
  }

  const handleMediaLibraryDialog = () => {
    openDialog(PresentationLibraryDialog, {
      handleSelect: handleChange,
      presentationLibrary,
      onCancel: () => dismissDialog()
    })
  }

  const handleAddToLibrary = () => {
    addFilesToLibrary({ files: [file] })
    enqueueSnackbar('Adding item to Library...', { variant: 'success' })
  }

  // update file in case of prop.file change (e.g., image to image swap from ThreeColumnOptions)
  // note: this is also triggered by handleEdit() in this component
  useEffect(() => {
    setFile(props.file)
  }, [props.file])

  useEffect(() => {
    if (getFocusFromSection && isEditable) {
      setIsFocused(true)
    }
    if (!isEditable) {
      // remove focus if parent section loses focus
      // instead of listening for a blur on the editor, we listen for a click outside of the section
      if (!isEqual(props.file, file)) {
        handleEdit(file)
      }
      if (file.format === 'youtube' && !file.youtubeId) {
        handleChangeFormat('upload')
      }
      setIsFocused(false)
      setMenuAnchorEl(null)
    }
  }, [isEditable])

  useEffect(() => {
    // draw focus if getFocusFromSection becomes true while parent section is focused
    if (isEditable && getFocusFromSection) {
      setIsFocused(true)
    }
  }, [getFocusFromSection])

  const handleChange = (newFile: QuoteFile) => {
    const comboFile = file
      ? {
          ...newFile,
          presentationOptions: file.presentationOptions,
          youtubeId: newFile.youtubeId ?? file.youtubeId
        }
      : newFile
    setFile(comboFile)
    handleEdit(comboFile)
  }

  const handleChangeFormat = (format: QuoteFile['format']) => {
    setFile({ ...file, format })
    handleEdit({ ...file, format }) // this is necessary for video banner sections to update their section options (not all layouts are available for video) without having to blur the section
  }

  const handleOpenOptions = (ev) => {
    if (!isFocused) setIsFocused(true)
    setMenuAnchorEl(wrapperRef.current)
  }

  if (!isSettings)
    return (
      <>
        {file && (
          <FilePreview
            classes={{
              image: classes.image,
              youtubeWrapper: classes.youtubeWrapper,
              size: classes.size
            }}
            onPageSelect={isPdf && !showAllPages ? handlePdfPreview : null}
            showAllPages={showAllPages}
            square={square}
            className={classes.root}
            file={file}
            isAnimated={isAnimated}
            inPresentation
            inGallery
            {...filePreviewProps}
          />
        )}
        {!file && !isSettings && <></>}
      </>
    )
  else
    return (
      <ClickAwayListener
        onClickAway={() => {
          if (isFocused && !getFocusFromSection) {
            setIsFocused(false)
            setMenuAnchorEl(null)
          }
        }}
        {...(dialogStack.length && { mouseEvent: false, touchEvent: false })}
      >
        <div
          className={classnames({
            [classes.size]: true,
            [classes.hovered]: !isFocused && !!file,
            [classes.focused]: isFocused
          })}
          onClick={() => {
            if (!isFocused) {
              setIsFocused(true)
            }
          }}
          ref={wrapperRef}
        >
          {(file || hasYoutube) && (
            <div
              className={classnames(classes.iconMenuRoot, {
                [classes.iconMenuRootFocused]: isFocused && !menuAnchorEl,
                [classes.iconMenuRightSide]: hasRightSideMenus
              })}
            >
              {file && !isVideo && (
                <PresEditorTooltip title={'Image Options'}>
                  <PopupMenuItem icon={EditOutlineIcon} iconOnly onClick={(ev) => handleOpenOptions(ev)} />
                </PresEditorTooltip>
              )}
              {hasYoutube && (
                <>
                  <PresEditorTooltip title={'Toggle YouTube'}>
                    <PopupMenuItem
                      icon={isYoutube ? ImageOutlinedIcon : YouTubeIcon}
                      iconOnly
                      selected={isYoutube}
                      onClick={() => handleChangeFormat(isYoutube ? 'upload' : 'youtube')}
                    />
                  </PresEditorTooltip>
                </>
              )}
              {!isYoutube && (
                <>
                  <PresEditorTooltip title={'Library'}>
                    <PopupMenuItem icon={PhotoLibraryOutlinedIcon} iconOnly onClick={handleMediaLibraryDialog} />
                  </PresEditorTooltip>
                  {file && addFilesToLibrary && !isInLibrary && (
                    <PresEditorTooltip title={'Add to Library'}>
                      <PopupMenuItem icon={AddPhotoAlternateOutlinedIcon} iconOnly onClick={handleAddToLibrary} />
                    </PresEditorTooltip>
                  )}
                </>
              )}
              {!isVideo && hasStockPhotos && (
                <>
                  <PresEditorTooltip title={'Stock Photos'}>
                    <PopupMenuItem icon={PhotoCameraOutlinedIcon} iconOnly onClick={handleStockPhotoDialog} />
                  </PresEditorTooltip>
                </>
              )}
            </div>
          )}
          {menuAnchorEl && (
            <SectionOptionsPopup
              hasRightSideMenus={hasRightSideMenus}
              menuAnchorEl={menuAnchorEl}
              onClose={() => setMenuAnchorEl(null)}
              open
              mediaOptionsProps={{
                file: file,
                sectionPrefix: sectionPrefix,
                fieldSuffix: fieldSuffix,
                hideAspectOptions: hideAspectOptions
              }}
            />
          )}
          {(file || isFocused) && (
            <div className={classes.root}>
              <EmbedMedia
                classes={classes}
                value={file ?? { visibility: 'visible', format: format }}
                noClearButton
                showSwapOnHover
                square={square}
                accept={accept ?? 'video/*,image/*,application/pdf'}
                maxWidth={1280}
                maxHeight={1600}
                disabled={!isFocused}
                onChange={handleChange}
                youtube={false}
                format={format}
                showAllPages={showAllPages}
                filePreviewProps={filePreviewProps}
              />
            </div>
          )}
          {!file && !isFocused && (
            <Placeholder
              classes={{ placeholder: classnames(classes.image, classes.mediaPlaceholder) }}
              buttonText={placeholderButtonText}
              Icon={ImageSmallIcon}
            />
          )}
        </div>
      </ClickAwayListener>
    )
}

function getAspectRatio(args: { file: QuoteFile; maxSquare: boolean }) {
  const { file, maxSquare } = args
  if (!file) return 'unset'
  const aspectRatio = (file.presentationOptions?.aspectRatio || 'unset').toString()
  const defaultImageOrientation = file.width < file.height ? 'portrait' : 'landscape'
  const imageOrientation = file.presentationOptions?.imageOrientation || defaultImageOrientation
  if (imageOrientation === 'landscape') return aspectRatio
  else {
    if (maxSquare) return '1 / 1'
    const slashIndex = aspectRatio.indexOf('/')
    if (slashIndex === -1) return aspectRatio
    else return aspectRatio.substring(slashIndex + 1) + '/' + aspectRatio.substring(0, slashIndex)
  }
}

function getObjectPosition(file: QuoteFile) {
  if (!file) return '50% 50%'
  if (file.format === 'pdf') return '50% 0%'
  const objectPositionX = file.presentationOptions?.objectPositionX ?? 50
  const objectPositionY = file.presentationOptions?.objectPositionY ?? 50
  return `${objectPositionX}% ${objectPositionY}%`
}

function getClipPath(clipPath: string, clipPathOrientation: 'top' | 'bottom' | 'left' | 'right') {
  // switch
  switch (clipPath) {
    case 'circle':
      return 'circle()'
    case 'parallelogram':
      if (clipPathOrientation === 'top') return 'polygon(0 25%, 100% 0, 100% 75%, 0 100%)'
      else if (clipPathOrientation === 'right') return 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)'
      else if (clipPathOrientation === 'bottom') return 'polygon(0 0, 100% 25%, 100% 100%, 0 75%)'
      else if (clipPathOrientation === 'left') return 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)'
    case 'chevron':
      if (clipPathOrientation === 'top') return 'polygon(100% 20%, 100% 100%, 50% 80%, 0 100%, 0 20%, 50% 0)'
      else if (clipPathOrientation === 'right') return 'polygon(80% 0%, 100% 50%, 80% 100%, 0% 100%, 20% 50%, 0% 0%)'
      else if (clipPathOrientation === 'bottom') return 'polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0, 50% 20%)'
      else if (clipPathOrientation === 'left') return 'polygon(100% 0%, 80% 50%, 100% 100%, 20% 100%, 0% 50%, 20% 0%)'
    case 'scoop':
      if (clipPathOrientation === 'top') return 'ellipse(190% 100% at 100% 100%)'
      else if (clipPathOrientation === 'right') return 'ellipse(100% 190% at 0 100%)'
      else if (clipPathOrientation === 'bottom') return 'ellipse(190% 100% at 0 0)'
      else if (clipPathOrientation === 'left') return 'ellipse(100% 190% at 100% 0)'
    case 'arch':
      if (clipPathOrientation === 'top') return 'inset(0 round 9999px 9999px 0% 0%)'
      else if (clipPathOrientation === 'right') return 'inset(0 round 0 9999px 9999px 0)'
      else if (clipPathOrientation === 'bottom') return 'inset(0 round 0 0 9999px 9999px)'
      else if (clipPathOrientation === 'left') return 'inset(0 round 9999px 0 0 9999px)'
    case 'leaf':
      if (clipPathOrientation === 'right' || clipPathOrientation === 'top') return 'inset(0 round 9999px 0 9999px 0)'
      else if (clipPathOrientation === 'left' || clipPathOrientation === 'bottom')
        return 'inset(0 round 0 9999px 0 9999px)'
    case 'drop':
      if (clipPathOrientation === 'top') return 'inset(0 round 0 9999px 9999px 9999px)'
      else if (clipPathOrientation === 'right') return 'inset(0 round 9999px 0 9999px 9999px)'
      else if (clipPathOrientation === 'bottom') return 'inset(0 round 9999px 9999px 0 9999px)'
      else if (clipPathOrientation === 'left') return 'inset(0 round 9999px 9999px 9999px 0)'
    case 'none':
      return 'none'
  }
}
