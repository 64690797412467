import type { OptionsDocument } from 'paintscout'
// Combines the default (taxRates[0]), with any other isDefault taxRates to generate
// taxRate value, description, and details
export function calculateTaxRate(options: OptionsDocument) {
  if (!options.options.taxRates?.[0]) {
    return options
  }
  const defaultRate = options.options.taxRates[0]
  let taxValue = parseFloat(defaultRate.rate as unknown as string)
  let taxDescription = defaultRate.description
  const taxDetails = [
    { key: defaultRate.key, rate: taxValue, description: taxDescription, isDefault: defaultRate.isDefault }
  ]
  options.options.taxRates.forEach((rate, i) => {
    if (rate.isDefault && i > 0) {
      taxValue += parseFloat(rate.rate as unknown as string)
      taxDescription += `, ${rate.description}`
      taxDetails.push({ key: rate.key, rate: rate.rate, description: rate.description, isDefault: rate.isDefault })
    }
  })

  const taxRate = {
    value: taxValue,
    description: taxDescription,
    details: taxDetails,
    allow_custom: options.options.taxRate.allow_custom
  }

  const updatedOptions = {
    ...options,
    options: {
      ...options.options,
      taxRate: {
        ...taxRate
      }
    }
  }

  return updatedOptions
}
