import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import Button from '@ui/stickybid/src/Button'

export interface PlaceholderProps {
  Icon: React.ComponentType<React.SVGAttributes<SVGElement>>
  buttonText?: string
  classes?: StyleClasses<typeof useStyles>
  onClick?: () => void
}

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      placeholder: {
        cursor: 'pointer',
        position: 'relative',
        zIndex: 2,
        height: 150,
        backgroundColor: theme.palette.grey[100],
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:hover': {
          '& svg': {
            fill: theme.palette.grey[800]
          },
          '& button': {
            color: theme.palette.grey[800]
          }
        },
        '&$placeholder': {
          '&$placeholder': {
            borderRadius: theme.borderRadius.lg
          }
        }
      },
      placeholderIcon: {
        color: theme.palette.common.black,
        fontSize: '1.5rem'
      }
    }
  },
  { name: 'Placeholder' }
)

export default function Placeholder(props: PlaceholderProps) {
  const classes = useStyles(props)
  const { Icon, buttonText, onClick } = props

  return (
    <div className={classes.placeholder} onClick={onClick}>
      <Icon className={classes.placeholderIcon} />
      {buttonText && <Button prominence={3}>{buttonText}</Button>}
    </div>
  )
}
