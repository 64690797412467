import type { QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { getActivePayments } from '../../builder/quote/payments'

export function calculateAmountPaid(quote: QuoteDocument) {
  if (!quote.payments) {
    quote.totals.amount_paid = 0
    return null
  }

  const activePayments = getActivePayments({ quote })
  return activePayments.reduce((totalPayments, payment) => {
    const paymentAmount = parseNumber(payment.amount)
    return totalPayments + paymentAmount
  }, 0)
}
