import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { useClientOptions, useMediaQuery } from '@ui/stickybid'
import { formatCurrency } from '@paintscout/util/builder'
import { QuoteContext } from '../../../context/QuoteContext'
import QuoteHeaderCard from '../QuoteHeaderCard'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop: theme.spacing(1),
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    title: {
      paddingRight: 0
    },
    text: {
      marginLeft: theme.spacing(1)
    }
  })

export interface QuoteTotalCardProps extends WithStyles<typeof styles> {}

function QuoteTotalCard(props: QuoteTotalCardProps) {
  const { classes } = props
  const { quote, isEditable } = useContext(QuoteContext)

  const { options } = useClientOptions()
  const isMd = useMediaQuery('md')

  if (!quote.totals?.after_tax) {
    return null
  }

  const showSubtotalDetails = quote.options.importedQuoteOptions?.totalStyle === 'subtotal' && !!quote.tax_rate && !isMd

  return (
    <>
      {showSubtotalDetails && (
        <>
          <QuoteHeaderCard
            classes={{
              root: classes.root,
              infoWrapper: classes.infoWrapper,
              title: classes.title,
              text: classes.text
            }}
            hideBorder={true}
            title={`Subtotal`}
            text={formatCurrency({ options, value: quote.totals.after_discount })}
            clickable={false}
          />
          <QuoteHeaderCard
            classes={{
              root: classes.root,
              infoWrapper: classes.infoWrapper,
              title: classes.title,
              text: classes.text
            }}
            hideBorder={true}
            title={`Tax Rate`}
            text={`${quote.tax_rate}%`}
            clickable={false}
          />
        </>
      )}
      <QuoteHeaderCard
        classes={{
          root: classes.root,
          infoWrapper: classes.infoWrapper,
          title: classes.title,
          text: classes.text
        }}
        hideBorder={true}
        title={`Total Price`}
        text={formatCurrency({ options, value: quote.totals.after_tax })}
        clickable={false}
      />
    </>
  )
}

export default withStyles(styles)(QuoteTotalCard)
