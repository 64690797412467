import React from 'react'
import type { GridSize, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Dialog as CoreDialog } from '@ui/core'
import type { DialogProps as CoreDialogProps, StyleClasses } from '@ui/core'
import Grid from '../Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '../IconButton'

const useStyles = makeStyles<Theme, SplitDialogProps>(
  (theme) => ({
    root: {
      // we're gonna try letting the dialog go in the center of the whole screen
      // [theme.breakpoints.up('md')]: {
      //   paddingLeft: '275px'
      // },
      // [theme.breakpoints.down('md')]: {
      //   paddingLeft: '0px'
      // }
    },
    paper: {
      borderRadius: theme.borderRadius.md
    },
    gridContainer: {
      flex: 1
    },
    leftContent: (props) => ({
      maxHeight: 'calc(100vh - 96px)',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100vh'
      },
      ...(props.fullScreen && {
        maxHeight: '100vh'
      })
    }),
    rightContent: (props) => ({
      alignItems: 'stretch',
      display: 'flex',
      maxHeight: 'calc(100vh - 96px)',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      ...(props.fullScreen && {
        maxHeight: '100vh'
      })
    }),
    closeButton: {
      position: 'absolute',
      zIndex: 3,
      right: theme.spacing(2),
      top: theme.spacing(0.5),
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      }
    }
  }),
  { name: 'SplitDialog' }
)

export interface SplitDialogProps extends CoreDialogProps {
  classes?: StyleClasses<typeof useStyles>
  children?: React.ReactNode
  rightContent?: React.ReactNode
  mdSizeRight?: number
  onCancel?: () => void
  closeButtonDisabled?: boolean
  closeButtonLoading?: boolean
}

function SplitDialog(props: SplitDialogProps) {
  const classes = useStyles(props)
  const { children, closeButtonDisabled, closeButtonLoading, maxWidth, mdSizeRight, onCancel, rightContent } = props

  return (
    <CoreDialog {...props} classes={classes} maxWidth={maxWidth || 'md'} fullWidth={true}>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          sm={!rightContent ? 12 : 8}
          md={!rightContent ? 12 : ((12 - mdSizeRight) as GridSize) || 6}
          lg={!rightContent ? 12 : 6}
          className={classes.leftContent}
        >
          {children}
        </Grid>
        {rightContent && (
          <Grid item sm={4} md={(mdSizeRight as GridSize) || 6} lg={6} className={classes.rightContent}>
            {onCancel && (
              <IconButton
                className={classes.closeButton}
                disabled={closeButtonDisabled}
                edge={'end'}
                loading={closeButtonLoading}
                onClick={onCancel}
              >
                <CloseIcon />
              </IconButton>
            )}
            {rightContent}
          </Grid>
        )}
      </Grid>
    </CoreDialog>
  )
}

export default SplitDialog
