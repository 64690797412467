/**
 * @module builder
 */
import type { QuoteDocument, QuoteArea } from 'paintscout'
import { getCheckboxes } from '../../util'
import type { UpdateableArea } from '../../index'
import { convertDimensions } from './convert-area-dimensions'

// Convert UpdateableArea to QuoteArea
export function convertArea(quote: QuoteDocument, area: UpdateableArea): QuoteArea {
  const originalQuoteArea = quote.areas[area.key]
  const checkboxes = getCheckboxes(area)

  const r = {
    ...originalQuoteArea,
    area_label: {
      use_custom: originalQuoteArea.area_label.use_custom,
      value: area.label
    },
    files: area.files,
    client_note: area.clientNote,
    internal_note: area.internalNote,
    quantity: area.quantity,
    area_type: { value: area.areaType },
    int_ext: { value: area.quoteType },
    dimensions: convertDimensions(area.dimensions),
    substrate_string: area.description
      ? {
          use_custom: area.description.useCustom,
          custom: area.description.custom,
          default: area.description.default
        }
      : originalQuoteArea.substrate_string,
    totals: {
      ...originalQuoteArea.totals,
      hours: area.totals.hours ? area.totals.hours.default : 0,
      override_hours: area.totals.hours ? area.totals.hours.useCustom : false,
      override_hours_value: area.totals.hours ? area.totals.hours.custom : 0,

      prep: area.totals.prep ? area.totals.prep.default : 0,
      override_prep: area.totals.prep ? area.totals.prep.useCustom : false,
      override_prep_value: area.totals.prep ? area.totals.prep.custom : 0,

      materials: area.totals.materials ? area.totals.materials.default : 0,
      override_materials: area.totals.materials ? area.totals.materials.useCustom : false,
      override_materials_value: area.totals.materials ? area.totals.materials.custom : 0,

      price: area.totals.prep ? area.totals.price.default : 0,
      override_price: area.totals.prep ? area.totals.price.useCustom : false,
      override_price_value: area.totals.prep ? area.totals.price.custom : 0
    },
    ...checkboxes
  }
  return r
}
