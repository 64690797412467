import 'react-day-picker/lib/style.css'
import { ApolloProvider } from '@apollo/react-hooks'
import { CssBaseline } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@ui/stickybid'
import { CloudinaryProvider, DialogStackProvider, SnackbarProvider } from '@ui/stickybid'
import React from 'react'
import { Authorizer, AuthProvider } from 'react-auth0'
import { Switch } from 'react-router'
import { Redirect, Route, Router } from 'react-router-dom'
import AppLoading from './components/AppLoading'
import NavDrawer from './components/NavDrawer'
import { client } from './graphql/client'
import Clients from './pages/Clients/Index'
import Emergency from './pages/Emergency/Index'
import LoginCallback from './pages/LoginCallback'
import Logout from './pages/Logout'
import Users from './pages/Users/Index'
import { audience, auth, scope } from './util/auth0'
import history from './util/history'
import LogRocket from './components/LogRocket'

const theme = createTheme({
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 2147483001,
    snackbar: 2147483002,
    tooltip: 2147483002
  }
})

function App() {
  return (
    <CloudinaryProvider
      cloudName={process.env.CLOUDINARY_CLOUD_NAME}
      uploadPreset={process.env.CLOUDINARY_UPLOAD_PRESET}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider auth0Client={auth} scope={scope} audience={audience} appUrl={process.env.PS_ADMIN_URL}>
            <ApolloProvider client={client}>
              <CssBaseline />
              <Router history={history}>
                <DialogStackProvider>
                  <LogRocket />
                  <Switch>
                    {/* Unauthenticated Routes */}
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/login-callback" exact component={LoginCallback} />

                    {/* Authenticated Routes */}
                    <Route
                      path="/"
                      render={() => (
                        <Authorizer>
                          {({ isAuthenticating }) => {
                            return isAuthenticating ? (
                              <AppLoading />
                            ) : (
                              <NavDrawer>
                                <Route path="/" exact>
                                  <Redirect to="/clients" />
                                </Route>
                                <Route path={'/clients'} component={Clients} />
                                <Route path="/users" component={Users} />
                                <Route path="/emergency" component={Emergency} />
                              </NavDrawer>
                            )
                          }}
                        </Authorizer>
                      )}
                    />
                  </Switch>
                </DialogStackProvider>
              </Router>
            </ApolloProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </CloudinaryProvider>
  )
}
export default App
