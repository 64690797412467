import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { DialogProps } from '@ui/stickybid'
import type { StyleClasses } from '@ui/core/theme'
import { Dialog, DialogContent, DialogTitle, HtmlContent, Image } from '@ui/stickybid'
import { CloseButton } from '../../dialogs/common'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'

const useStyles = makeStyles<Theme, AcceptQuoteThankYouDialogProps>(
  (theme) => ({
    dialogPaper: {
      display: 'flex',
      flexDirection: 'row'
    },
    dialogTitle: {
      marginBottom: theme.spacing(2)
    },
    dialogContent: (props) => ({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingBottom: theme.spacing(3),
      '& a': {
        color: props.linkColor,
        '&:hover': {
          textDecoration: 'none'
        }
      }
    }),
    rightContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    },
    leftContentBig: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(4),
      paddingRight: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    leftContentSmall: {
      display: 'none',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        textAlign: 'center'
      }
    },
    icon: {
      fontSize: '4rem'
    },
    logo: {
      maxHeight: 200,
      maxWidth: 120
    }
  }),
  { name: 'AcceptQuoteThankYouDialog' }
)

export interface AcceptQuoteThankYouDialogProps extends DialogProps {
  body: string
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  linkColor?: string
  logoSrc?: string
  onCancel: (event: React.MouseEvent<HTMLElement>) => any
  title: string
}

function AcceptQuoteThankYouDialog(props: AcceptQuoteThankYouDialogProps) {
  const { body, linkColor = '#000000', logoSrc, onCancel, title, ...baseDialogProps } = props
  const classes = useStyles({ linkColor, ...props })

  const media = (
    <>
      {logoSrc ? (
        <Image className={classes.logo} publicId={logoSrc} />
      ) : (
        <CheckCircleOutlineOutlinedIcon className={classes.icon} />
      )}
    </>
  )

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} {...baseDialogProps}>
      <div className={classes.leftContentBig}>{media}</div>
      <div className={classes.rightContent}>
        <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>{title}</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.leftContentSmall}>{media}</div>
          <HtmlContent content={body} />
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default AcceptQuoteThankYouDialog
