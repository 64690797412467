import React, { useState, useCallback } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { Input, Tooltip, Typography } from '../'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

interface StringOptionProps {
  classes?: StyleClasses<typeof useStyles>
  errorMessage?: string
  label: string
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  value: string
}

const useStyles = makeStyles<Theme, StringOptionProps>(
  (theme: Theme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      label: {
        fontSize: '.9rem'
      },
      inputRoot: {
        marginLeft: theme.spacing(3),
        width: 180
      },
      input: {
        padding: '2px 6px'
      },
      invalidInput: {
        borderColor: theme.palette.status.declined
      },
      tooltipRoot: {
        marginLeft: theme.spacing(2),
        marginRight: 'auto'
      },
      tooltipBadge: {
        cursor: 'pointer',
        top: 0,
        right: 0,
        '& svg': {
          color: theme.palette.status.declined,
          fontSize: '1rem'
        }
      }
    }
  },
  { name: 'StringOption' }
)

export default function StringOption(props: StringOptionProps) {
  const classes = useStyles(props)
  const { errorMessage, label, onChange, value: propsValue } = props
  const [value, setValue] = useState(propsValue)

  const handleOnChange = useCallback(
    debounce((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(ev)
    }, 400),
    []
  )

  const batchOnChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(ev.target.value)
    handleOnChange(ev)
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant={'h5'}>
        {label}
      </Typography>
      {errorMessage && (
        <div className={classes.tooltipRoot}>
          <Tooltip
            placement={'left'}
            content={errorMessage}
            icon={<ErrorIcon />}
            classes={{ badge: classes.tooltipBadge }}
          />
        </div>
      )}
      <Input
        classes={{
          root: classnames(classes.inputRoot, { [classes.invalidInput]: errorMessage }),
          input: classes.input
        }}
        value={value}
        onChange={(ev) => batchOnChange(ev)}
      />
    </div>
  )
}
