import React, { useRef, useState, useEffect } from 'react'
import type { HTMLProps } from 'react'
import { makeStyles, Chip, ClickAwayListener } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import Input from '../Input'
import classnames from 'classnames'
import FilterMenu from './FilterMenu'
import { SearchIcon, useIsMobile } from '@ui/core'
import Typography from '../Typography'

const useStyles = makeStyles<Theme, SearchBarProps>(
  (theme) => ({
    root: {},
    inputRoot: {
      ['@media (max-width: 325px)']: {
        maxWidth: '310px'
        // marginLeft: '-3px'
      }
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      }
    },
    inputInput: (props) => ({
      minWidth: '400px',
      borderColor: theme.palette.primary.light,
      [theme.breakpoints.down('md')]: {
        minWidth: '300px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: '250px'
      },
      [theme.breakpoints.down('xs')]: {
        ...(!props.fullWidth && { maxWidth: '310px' })
      }
    }),
    inputFocused: {
      boxShadow: `0 0 0 1px ${theme.palette.primary.main[700]}`,
      borderColor: theme.palette.primary.dark,
      '& $adornment': {
        color: theme.palette.primary.main
      }
    },
    icon: {
      width: '37px',
      height: '37px',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      padding: '6px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      },
      '& svg': {
        height: '18px'
      }
    },
    iconOnly: {
      margin: '1px'
    },
    adornment: {},
    hidden: {}
  }),
  { name: 'SearchBar' }
)

export interface SearchBarProps {
  grow?: 'left' | 'right'
  value?: string
  defaultValue?: string
  filtersActive?: boolean
  filterContent?: React.ReactNode
  filterLabels?: Array<{
    title: string
    label: string
  }>
  showExport?: boolean
  isExporting?: boolean
  placeholder?: string
  inputProps?: Partial<HTMLProps<HTMLInputElement>>
  style?: React.CSSProperties
  className?: string
  fullWidth?: boolean

  onChange?: (query: string) => any
  onExport?: (query: string) => any
  onSearch?: (query: string) => any
  onClear?: () => any
}

// Searchbar grows from icon either left or right
function SearchBar(props: SearchBarProps) {
  const classes = useStyles(props)

  const {
    value,
    grow,
    filterLabels,
    filterContent,
    filtersActive,
    showExport,
    isExporting,
    inputProps,
    defaultValue,
    onClear,
    onChange,
    onSearch,
    onExport,
    fullWidth,
    ...otherProps
  } = props

  const inputRef = useRef<any>(null)
  const hasValue = value?.length >= 1 || defaultValue?.length >= 1
  const [isOpen, setIsOpen] = useState<boolean>(hasValue)
  const isMobile = useIsMobile()
  const handleOnChange = (ev: any) => {
    onChange(ev.target.value)
  }

  const handleIconClick = (ev: any) => {
    setIsOpen((currIsOpen) => !currIsOpen)
  }

  useEffect(() => {
    if (inputRef.current && !isMobile) {
      inputRef.current.select()
    }
  }, [isOpen])

  // Helper to choose correct end adornment or filter
  const getEndAdornment = () => {
    if (props.filterContent) {
      return (
        <FilterMenu
          showExport={showExport}
          isExporting={isExporting}
          filterContent={filterContent}
          filtersActive={filtersActive}
          onClear={onClear}
          onSearch={() => onSearch(inputRef.current.value)}
          onExport={() => onExport(inputRef.current.value)}
        />
      )
    } else {
      return null
    }
  }

  return (
    <div
      className={classnames({
        [classes.container]: true
      })}
    >
      {(isOpen || true) && (
        <ClickAwayListener
          onClickAway={() => {
            // close if input is empty
            if (!defaultValue) setIsOpen(false)
          }}
        >
          <Input
            {...otherProps}
            fullWidth={fullWidth}
            inputRef={inputRef}
            inputProps={inputProps}
            autoComplete={'off'}
            spellCheck={false}
            defaultValue={defaultValue}
            value={value}
            onChange={handleOnChange}
            endAdornment={getEndAdornment()}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
              focused: classes.inputFocused
            }}
          />
        </ClickAwayListener>
      )}
      {!(isOpen || true) && (
        <div
          onClick={handleIconClick}
          className={classnames({
            [classes.icon]: true,
            [classes.iconOnly]: true
          })}
        >
          <SearchIcon />
        </div>
      )}
      {filtersActive && filterLabels && filterLabels.length > 0 && (
        <div className={classes.filterLabels}>
          {filterLabels.map((filter, index) => (
            <div key={index} className={classes.filterLabel}>
              <Typography variant="overline">{filter.title}</Typography>
              <Chip label={filter.label} className={classes.filterChip} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchBar
