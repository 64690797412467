import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import type { OptionsDocument } from 'paintscout'
import { getFeature, getObjectLabels } from '@paintscout/util/builder'
import { FastField, useFormikContext } from 'formik'
import { FormikHtmlEditor } from '../../formik'
import React from 'react'
import FormSection from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import Typography from '../../Typography'
import { PageHelpMessage, LearnMoreLink } from '../../PageHelpMessage'
import { getTemplateTags, getTemplateContext } from '@paintscout/util/util'

const useStyles = makeStyles((theme) => ({
  root: {},
  addressbookSection: {
    width: '100%',
    maxWidth: 600
  },
  templates: {
    marginTop: theme.typography.pxToRem(15)
  },
  divider: {
    borderTop: `1px dashed ${theme.palette.secondary.main}`,
    margin: theme.spacing(1, 0)
  }
}))

export interface EmailFormProps {}

function EmailForm() {
  const { values } = useFormikContext<{ options: OptionsDocument }>()
  const { options } = values
  const classes = useStyles()

  const objectLabels = getObjectLabels({ options })
  const hasInvoices = getFeature({ options, path: 'invoices.enabled' })
  const hasSMS = getFeature({ options, path: 'sms.enabled' })

  const templateContext = getTemplateContext()
  const quoteTemplateTags = getTemplateTags({ options, invoice: false })
  const invoiceTemplateTags = getTemplateTags({ options, invoice: true })

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path="profile-emails"
        openContent={
          <>
            <Typography variant={'h2'} color="inherit">
              {hasSMS ? 'Email & SMS' : 'Email'}
            </Typography>
            <Typography>
              Add in commonly used email addresses to your address book. Then, make sure to add an email template for
              your quote - it can always be adjusted on the quote before sending it!
              <LearnMoreLink link="http://help.stickybid.com/en/articles/3206825-updating-profile-estimator-information" />
            </Typography>
          </>
        }
        closedContent={<FormSectionTitle title={hasSMS ? 'Email & SMS' : 'Email'} variant={'h2'} />}
      />
      <FormSection>
        <FormSectionTitle
          title={`Default ${objectLabels.quote.value} Message`}
          variant={'h4'}
          subTitle={`Customize default email and SMS text when sending ${objectLabels.quote.plural.toLowerCase()}.`}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FastField
              component={FormikHtmlEditor}
              name="preferences.emails.templates.values[send-quote-client].subject"
              margin="dense"
              multiline={false}
              label={'Subject'}
              templateContext={templateContext}
              template={{ context: templateContext, tags: quoteTemplateTags }}
              fullWidth
              bubbleMenuDisabled
              floatingMenuDisabled
              menuBarEnabled
              templateTagsOnly
            />
          </Grid>
          <Grid item xs={12}>
            <FastField
              component={FormikHtmlEditor}
              name="preferences.emails.templates.values[send-quote-client].body"
              margin="dense"
              label={'Body'}
              templateContext={templateContext}
              template={{ context: templateContext, tags: quoteTemplateTags }}
              fullWidth
              bubbleMenuDisabled
              floatingMenuDisabled
              menuBarEnabled
            />
          </Grid>
          {hasSMS && (
            <>
              <Grid item xs={12}>
                <div className={classes.divider} />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={FormikHtmlEditor}
                  characterCount={60}
                  name="preferences.emails.templates.values[send-quote-client].smsBody"
                  margin="dense"
                  multiline={false}
                  label={'Body (SMS) - Max 60 Char'}
                  templateContext={templateContext}
                  template={{ context: templateContext, tags: quoteTemplateTags }}
                  fullWidth
                  bubbleMenuDisabled
                  floatingMenuDisabled
                  menuBarEnabled
                  templateTagsOnly
                />
              </Grid>
            </>
          )}
        </Grid>
      </FormSection>
      {hasInvoices && (
        <FormSection>
          <FormSectionTitle
            title={`Default Invoice Message`}
            variant={'h4'}
            subTitle={`Customize default email and SMS text when sending invoices.`}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FastField
                component={FormikHtmlEditor}
                name="preferences.emails.templates.values[send-invoice-client].subject"
                margin="dense"
                multiline={false}
                label={'Subject'}
                templateContext={templateContext}
                template={{ context: templateContext, tags: invoiceTemplateTags }}
                fullWidth
                bubbleMenuDisabled
                floatingMenuDisabled
                menuBarEnabled
                templateTagsOnly
              />
            </Grid>
            <Grid item xs={12}>
              <FastField
                component={FormikHtmlEditor}
                name="preferences.emails.templates.values[send-invoice-client].body"
                margin="dense"
                label={'Body'}
                templateContext={templateContext}
                template={{ context: templateContext, tags: invoiceTemplateTags }}
                fullWidth
                bubbleMenuDisabled
                floatingMenuDisabled
                menuBarEnabled
              />
            </Grid>
            {hasSMS && (
              <>
                <Grid item xs={12}>
                  <div className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <FastField
                    component={FormikHtmlEditor}
                    characterCount={60}
                    name="preferences.emails.templates.values[send-invoice-client].smsBody"
                    margin="dense"
                    multiline={false}
                    label={'Body (SMS) - Max 60 Char'}
                    templateContext={templateContext}
                    template={{ context: templateContext, tags: invoiceTemplateTags }}
                    fullWidth
                    bubbleMenuDisabled
                    floatingMenuDisabled
                    menuBarEnabled
                    templateTagsOnly
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormSection>
      )}
      <FormSection>
        <FormSectionTitle
          title={`Thank You Message`}
          variant={'h4'}
          subTitle={`Customize email sent when ${objectLabels.quote.plural.toLowerCase()} are accepted.`}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FastField
              component={FormikHtmlEditor}
              name="preferences.emails.templates.values[accept-thank-you].subject"
              margin="dense"
              multiline={false}
              label={'Subject'}
              templateContext={templateContext}
              template={{ context: templateContext, tags: quoteTemplateTags }}
              fullWidth
              bubbleMenuDisabled
              floatingMenuDisabled
              menuBarEnabled
              templateTagsOnly
            />
          </Grid>
          <Grid item xs={12}>
            <FastField
              component={FormikHtmlEditor}
              name="preferences.emails.templates.values[accept-thank-you].body"
              margin="dense"
              label={'Body'}
              templateContext={templateContext}
              template={{ context: templateContext, tags: quoteTemplateTags }}
              fullWidth
              bubbleMenuDisabled
              floatingMenuDisabled
              menuBarEnabled
            />
          </Grid>
        </Grid>
      </FormSection>
    </div>
  )
}

export default EmailForm
