import React, { useContext, useEffect, useState } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles, Tooltip } from '@material-ui/core'

import type { PopupMenuProps, StyleClasses } from '@ui/stickybid'
import { Button, Grid, MenuItem, PopupMenu, useClientOptions, useMediaQuery } from '@ui/stickybid'

import { CLOSED_STATUSES, getObjectLabels, getQuoteOptions, hasIntegrationInterface } from '@paintscout/util/builder'

import PrintIcon from '@material-ui/icons/PrintOutlined'
import DownloadIcon from '@material-ui/icons/PictureAsPdfOutlined'
import DeclineIcon from '@material-ui/icons/CancelOutlined'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'

import { usePresentationNav } from '../PresentationNavContext'

import { QuoteContext } from '../../../context/QuoteContext'
import striptags from 'striptags'

const useStyles = makeStyles<Theme, PresentationAcceptButtonProps>(
  (theme: Theme) => {
    return {
      root: {},
      acceptGridRoot: {
        height: '100%',
        padding: theme.spacing(1, 0)
      },
      button: {
        color: 'black',
        backgroundColor: theme.palette.success.main,

        [theme.breakpoints.up('lg')]: {
          textAlign: 'center'
        },
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
          color: 'white'
        }
      },
      oversizeSplitButton: {
        padding: theme.spacing(2)
      },
      moreButton: {
        padding: 0,
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1)
        }
      },
      moreMenu: {
        ['@media print']: {
          display: 'none' // for printing from PresentationQuoteDrawer
        }
      },
      tooltip: {
        '& div': {
          fontSize: '.875rem'
        }
      }
    }
  },
  { name: 'PresentationAcceptButton' }
)

export interface PresentationAcceptButtonProps {
  classes?: StyleClasses<typeof useStyles>
  anchorOrigin?: PopupMenuProps['anchorOrigin']
  transformOrigin?: PopupMenuProps['transformOrigin']
}

export default function PresentationAcceptButton(props: PresentationAcceptButtonProps) {
  const classes = useStyles(props)
  const { anchorOrigin, transformOrigin } = props

  const { options } = useClientOptions()

  const { view, isResponding, allowResponse, onAcceptClick, onPayClick, onMoreClick, isPreview, onCloseCart } =
    usePresentationNav()
  const { quote, handleOptionalItems } = useContext(QuoteContext)
  const { selectedOptionalItems } = handleOptionalItems || {}

  const { allowPaymentsOnInvoices } = getQuoteOptions({ quote, options })

  const hasPayments = hasIntegrationInterface({ options, integration: 'stripe' }) && !!onPayClick

  const allowPayment =
    hasPayments && allowPaymentsOnInvoices && quote.is_invoice && view !== 'work-order' && view !== 'product-order-form'

  const showAcceptButton =
    view !== 'work-order' && (allowResponse || isPreview) && !!onAcceptClick && !quote.is_invoice && !allowPayment

  const hasOpenStatus = !CLOSED_STATUSES.includes(quote?.status?.value)

  const objectLabels = getObjectLabels({ options, quote })

  const smDown = useMediaQuery('sm')
  const xsDown = useMediaQuery('xs')

  const buttonLabel = getButtonLabel({ quote, objectLabels, smDown })

  const hasMoreButton = !!onMoreClick

  const allowDecline = allowResponse && options?.options?.allowDecline && hasOpenStatus && !quote.is_invoice

  const declineLabel = getDeclineLabel({ quote, objectLabels })
  const printLabel = getPrintLabel()
  const downloadLabel = getDownloadLabel()
  const moreButtonLabel = getMoreButtonLabel()
  const hasTerms = !!striptags(quote.terms?.content)

  const handleAcceptClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>, usePaymentCallback?: boolean) => {
    onAcceptClick(ev, selectedOptionalItems, usePaymentCallback)
  }

  const [initStatus, setInitStatus] = useState(quote?.status?.value)

  useEffect(() => {
    if (!CLOSED_STATUSES.includes(initStatus) && !hasOpenStatus) {
      onCloseCart()
    }
  }, [quote?.status?.value])

  const moreButton = (
    <Grid item>
      <PopupMenu
        className={classes.moreMenu}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'top',
            horizontal: 'left'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }
        component={
          <Button prominence={3} className={classes.moreButton}>
            {moreButtonLabel}
          </Button>
        }
      >
        <>
          {hasTerms && (
            <MenuItem
              icon={ListAltOutlinedIcon}
              onClick={(ev) => {
                onMoreClick(ev, 'view-terms')
              }}
            >
              View Terms
            </MenuItem>
          )}
          <MenuItem
            icon={PrintIcon}
            onClick={(ev) => {
              onMoreClick(ev, 'print')
            }}
          >
            {printLabel}
          </MenuItem>
          <MenuItem
            icon={DownloadIcon}
            onClick={(ev) => {
              onMoreClick(ev, 'download')
            }}
          >
            {downloadLabel}
          </MenuItem>
          {allowDecline && (
            <MenuItem
              icon={DeclineIcon}
              onClick={(ev) => {
                onMoreClick(ev, 'decline')
              }}
            >
              {declineLabel}
            </MenuItem>
          )}
        </>
      </PopupMenu>
    </Grid>
  )

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        wrap={'nowrap'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        classes={{ container: classes.acceptGridRoot }}
      >
        {allowPayment && (
          <>
            <Grid item>
              <Tooltip
                classes={{ popper: classes.tooltip }}
                title={
                  quote.totals.balance_due > 0 && quote.totals.balance_due < 0.5
                    ? 'Payments are only available if the remaining balance is at least $0.50 '
                    : ''
                }
              >
                <div>
                  <Button
                    classes={{ root: classes.button }}
                    disabled={quote.totals.balance_due < 0.5}
                    loading={isResponding}
                    onClick={
                      selectedOptionalItems.length > 0 ? (ev) => handleAcceptClick(ev, true) : () => onPayClick()
                    }
                  >
                    {getPaymentButtonLabel()}
                  </Button>
                </div>
              </Tooltip>
            </Grid>
            {hasMoreButton && moreButton}
          </>
        )}
        {showAcceptButton && (
          <>
            <Grid item>
              <Button
                loading={isResponding}
                disabled={!allowResponse || !hasOpenStatus}
                classes={{ root: classes.button }}
                onClick={(ev) => handleAcceptClick(ev)}
              >
                {buttonLabel}
              </Button>
            </Grid>
            {hasMoreButton && moreButton}
          </>
        )}
      </Grid>
    </div>
  )

  function getMoreButtonLabel() {
    if (xsDown) return 'More...'
    return 'More Actions...'
  }

  function getPrintLabel() {
    if (view === 'work-order') {
      return `Print ${objectLabels.workOrder.value}`
    } else if (quote?.is_invoice) {
      return `Print Invoice`
    } else {
      return `Print ${objectLabels.quote.value}`
    }
  }

  function getDownloadLabel() {
    if (view === 'work-order') {
      return `Download ${objectLabels.workOrder.value}`
    } else if (quote?.is_invoice) {
      return `Download Invoice`
    } else {
      return `Download ${objectLabels.quote.value}`
    }
  }

  function getDeclineLabel({ quote, objectLabels }) {
    if (objectLabels.declineButton) {
      return objectLabels.declineButton.value
    } else {
      const item =
        quote?.is_invoice && quote?.totals?.pending ? objectLabels.additionalWork.value : objectLabels.quote.value
      return `Decline ${item}`
    }
  }

  function getPaymentButtonLabel() {
    if (xsDown) return quote.totals.balance_due === 0 && quote.payments.length > 0 ? 'Paid' : 'Pay'
    return quote.totals.balance_due === 0
      ? quote.payments.length === 0
        ? 'No Balance Due'
        : 'Payment Received'
      : quote.totals.balance_due < 200
      ? 'Pay Balance'
      : 'Make Payment'
  }

  function getButtonLabel({ quote, objectLabels, smDown }) {
    if (objectLabels.acceptButton) {
      return objectLabels.acceptButton.value
    } else if (smDown) {
      return 'Accept'
    } else {
      const item = objectLabels.quote.value
      return `Accept ${item}`
    }
  }
}
